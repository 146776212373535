import React, { forwardRef, useState } from 'react';

import {
	Input as InputComponent,
	InputGroup,
	InputGroupText,
} from 'reactstrap';
import classNames from 'classnames';

import { ReactComponent as EyeSvg } from '../../assets/img/eye.svg';
import { ReactComponent as EyeClosedSvg } from '../../assets/img/eyeClosed.svg';

const Input = forwardRef(
	(
		{ value, onChange, password, type, error, label, isInline, ...rest },
		ref
	) => {
		const [inputType, setInputType] = useState(type);

		const inputClassnames = classNames('input-group', {
			'input-invalid': !!error?.trim(),
			'input-valid': !error?.trim() && !!value?.trim(),
			'input-group_inline': isInline,
		});

		return (
			<label
				className={classNames('cloud-form__label', {
					'cloud-form__label_error': !!error,
				})}>
				{label}
				<InputGroup className={inputClassnames}>
					<InputComponent
						className="input"
						value={value}
						bsSize="lg"
						onChange={onChange}
						type={inputType}
						valid={!error}
						innerRef={ref}
						invalid={!!error}
						{...rest}
					/>
					{password && (
						<InputGroupText className="input-group-text">
							<button
								type="button"
								className="input-password-button"
								onClick={() =>
									setInputType(prev =>
										prev === 'password' ? 'text' : 'password'
									)
								}>
								{password && inputType === 'password' ? (
									<EyeSvg />
								) : (
									<EyeClosedSvg />
								)}
							</button>
						</InputGroupText>
					)}
				</InputGroup>
				{error && <p className="m-0 cloud-form__error">{error}</p>}
			</label>
		);
	}
);

export default Input;
