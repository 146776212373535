import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export default function ({ text }) {
	return (
		<div className="row align-items-center d-flex justify-content-center m-5">
			<FontAwesomeIcon icon={faExclamationTriangle} className="me-1 text-danger" />
			<span className="ms-2">{text}</span>
		</div>
	);
}