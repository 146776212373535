
import React from 'react';


import { useReactTable, getCoreRowModel, getPaginationRowModel, getSortedRowModel, getExpandedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { COLUMNS, TABS, fileName } from './constants';
import TableHeader from './TableHeader';
import { BTable } from '../../../components/BTable';
import ExpandedRow from '../../tests/AutomatedTests/ExpandedRow';
import { MinusCircle, PlusCircle } from 'react-feather';

const renderer = expands =>
    expands ? (
        <MinusCircle width={16} height={16} />
    ) : (
        <PlusCircle width={16} height={16} />
    );

const IssuesTable = ({ data, headerProps, remediation, showModal }) => {
    const HeaderComponent = TableHeader.issues;

    const table = useReactTable({
        data: data || [], // Ensure data is defined
        columns: COLUMNS[TABS.ISSUES].map(col => ({
            ...col,
            enableSorting: true // Enable sorting for each column
        })), // Ensure columns are defined
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getExpandedRowModel: getExpandedRowModel(), // Add expanded row model
        getFilteredRowModel: getFilteredRowModel(),

        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },

            expanded: {} // Initial expanded state
        }
    });

    const expandRow = {
        renderer: row => (
            <ExpandedRow row={row} remediation={remediation[row.SecurityTestRule]} />
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => renderer(isAnyExpands),
        expandColumnRenderer: props => renderer(props.expanded),
    };

    return (
        <div id="resultTableContainer">
            <HeaderComponent
                {...headerProps}
                baseProps={{ data, table }} // Pass data to baseProps
                columns={COLUMNS[TABS.ISSUES]} // Pass columns
                rowEvents={{
                    onClick: (e, row) => {
                        showModal(row);
                    },
                }}
                activeTab={TABS.ISSUES}
                csvFileName={fileName[TABS.ISSUES]?.(headerProps)}

            />
            <BTable table={table} pageSizes={[10, 20, 50]} />
        </div>
    );
};

export default IssuesTable;

