import {
	Col,
	Row
} from "reactstrap";

import Categories from "../Categories";
import Headers from "./Headers";
import React from "react";
import useChildTestCases from "./useChildTestCases";

export default function ({ refData, parentId, navState, children }) {

	const vm = useChildTestCases(refData, parentId, navState);

	return <>
		<Row>
			<Col sm={9}>
				<Categories
					nested={true}
					testCases={vm.testCases}
					categories={vm.categories}
					navState={navState}
					refData={refData}
					parentId={parentId}
					testCase={vm.selected}>
					{children}
				</Categories>
			</Col>
			<Col sm={3}>
				<Headers testCases={vm.testCases} selected={vm.selected} onSelect={vm.onSelect} />
			</Col>
		</Row>
	</>;

}