import * as types from '../constants';

const initialState = {
	selectedItem: null,
};

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.SET_SELECTED_ITEM:
			return {
				...state,
				selectedItem: actions.payload,
			};

		default:
			return state;
	}
}
