import { Container, Row } from 'reactstrap';
import React, { useRef } from 'react';

import Contents from './Controls/Contents';
import Error from './Controls/Error';
import Metrics from './Controls/Metrics';
import RansomwareTopology from '../Controls/RansomwareTopology';
import ReportAbout from './Controls/ReportAbout';
import ReportButtons from './Controls/ReportButtons';
import ReportHeader from './Controls/Header';
import ReportLoading from './Controls/ReportLoading';
import VulnerabilityDetails from './Controls/VulnerabilityDetails';
import { useReactToPrint } from 'react-to-print';
import useReport from './Hooks/useReport';
import { useParams } from 'react-router-dom';

const PageBreak = () => <Row className="page-break" />;

const PrintReport = React.forwardRef((props, ref) => {
	const { reportId, report, onPrint, vulnerabilities, issues } = props;

	return (
		<div ref={ref}>
			<ReportButtons onPrint={onPrint} />
			<ReportHeader reportId={reportId} />
			<Contents />
			<ReportAbout />
			<PageBreak />
			<Metrics report={report} />
			<PageBreak />
			<RansomwareTopology
				reportId={reportId}
				title="RANSOMWARE TOPOLOGY"
				chartDim={{ h: 700, w: 830 }}
				hideRules={true}
				issues={issues}
			/>
			<PageBreak />
			<VulnerabilityDetails data={vulnerabilities} issues={issues} />
		</div>
	);
});

const Report = () => {
	const params = useParams();
	const reportId = params.reportid;
	console.log("params:", params);
	console.log("reportId:", reportId);
	const reportRef = useRef();

	const { busy, report, error, vulnerabilities, issues } = useReport(reportId);

	console.log("useReport:", { busy, report, error, vulnerabilities, issues });

	const onPrint = useReactToPrint({
		content: () => reportRef.current,
		documentTitle: `Ransomware ${reportId}`,
	});

	return (
		<>
			<Container fluid>
				{busy && <ReportLoading />}
				{error && (
					<>
						<ReportButtons />
						<Error />
					</>
				)}
				{!busy && !error && (
					<PrintReport
						ref={reportRef}
						reportId={reportId}
						report={report}
						onPrint={onPrint}
						vulnerabilities={vulnerabilities}
						issues={issues}
					/>
				)}
			</Container>
		</>
	);
};

export default Report;
