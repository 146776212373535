import React from 'react';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import icon6 from '../../../assets/img/6.svg';
import icon7 from '../../../assets/img/7.svg';
import icon8 from '../../../assets/img/8.svg';
import { useTranslation } from 'react-i18next';

function OverviewOneTimeSetup() {
	const { t } = useTranslation();
	return (
		<div className="mt-5" id="overview">
			<h3 className="mb-2">{t('oneTimeSetup.overview')}</h3>
			<div className="setup-list">
				<div className="d-flex ">
					<img src={icon1} alt="icon1" className="img" />
					{t('oneTimeSetup.IAMpolicy')}
				</div>

				<div className="d-flex ">
					<img src={icon2} alt="icon2" className="img" />
					{t('oneTimeSetup.IAMsetup')}
				</div>
				<div className="d-flex ">
					<img src={icon3} alt="icon3" className="img" />
					{t('oneTimeSetup.SecurityAudit')}
				</div>
				<div className="d-flex ">
					<img src={icon4} alt="icon4" className="img" />
					{t('oneTimeSetup.IAMuser')}
				</div>
				<div className="d-flex ">
					<img src={icon5} alt="icon5" className="img" />
					{t('oneTimeSetup.setupGroup')}
				</div>
				<div className="d-flex ">
					<img src={icon6} alt="icon6" className="img" />
					{t('oneTimeSetup.setupAccessKey')}
				</div>
				<div className="d-flex ">
					<img src={icon7} alt="icon7" className="img" />
					{t('oneTimeSetup.through')}
				</div>
				<div className="list">
					<ul>
						<li>{t('oneTimeSetup.SSO')}</li>
						<li>{t('oneTimeSetup.prompt')}</li>
						<li>{t('oneTimeSetup.addAccount')}</li>
						<li>{t('oneTimeSetup.saveAccount')}</li>
						<li>
							<h4>{t('oneTimeSetup.triggerTheSetup')}</h4>
						</li>
						<li>
							<h4>{t('oneTimeSetup.companyProfile')}</h4>
						</li>
						<li>
							<h4>{t('oneTimeSetup.ransomwareRisk')}</h4>
						</li>
					</ul>
				</div>
				<div className="d-flex ">
					<img src={icon8} alt="icon8" className="img" />
					{t('oneTimeSetup.removeSetup')}
				</div>
			</div>
		</div>
	);
}

export default OverviewOneTimeSetup;
