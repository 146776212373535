import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles.css';
import { Progress } from 'reactstrap';
import ProgressCircleForGuide from './ProgressCircleForGuide';
import emptyCircle from '../../../assets/img/emptyCircle.svg';
import activeCircle from '../../../assets/img/activeCircle.svg';

function ProgressBarForGuide({
	scroll,
	scroll2,
	id1,
	id2,
	id3,
	descp1,
	descp2,
	descp3,
}) {
	const { t } = useTranslation();

	return (
		<div className="vertical-progress">
			<div className="d-flex flex-column ">
				<ProgressCircleForGuide cirlce={activeCircle} descp={descp1} id={id1} />
				<Progress
					value={scroll}
					style={{
						width: '60px',
						transform: 'rotate(90deg)',
						marginTop: 40,
					}}
				/>
			</div>
			<div className="d-flex flex-column ">
				<ProgressCircleForGuide
					cirlce={scroll === 100 ? activeCircle : emptyCircle}
					descp={descp2}
					id={id2}
				/>
				<Progress
					value={scroll2}
					style={{
						width: 60, // 60px
						transform: 'rotate(90deg)',
						marginTop: 30,
					}}
				/>
			</div>
			<div className="d-flex flex-column ">
				<ProgressCircleForGuide
					cirlce={scroll2 === 100 ? activeCircle : emptyCircle}
					descp={descp3}
					id={id3}
				/>
			</div>
		</div>
	);
}

export default ProgressBarForGuide;
