import '../styles.css';

import { Card, CardBody } from 'reactstrap';
import { Frown, Meh, Smile } from 'react-feather';

import React from 'react';
import { useTranslation } from 'react-i18next';

const RiskChange = ({ previous, current }) => {
	const { t } = useTranslation();
	const delta = previous - current;

	let result = {
		textClass:
			'text-center font-weight-bold align-middle p-0 m-0 risk-change-text text-white',
		bgClass: 'p-2 mt-2 ',
		text: '',
		color: '',
	};

	if (delta > 0) {
		result.text = t('assessmentResults.riskScoreReduced', { delta, previous });
		result.bgClass = '#59A919';
		result.color += '#59A919';
	} else if (delta < 0) {
		result.text = t('assessmentResults.riskScoreIncreased', {
			delta: -1 * delta,
			previous,
		});
		result.bgClass = '#E72538';

		result.color += '#E72538';
	} else {
		result.text = t('assessmentResults.noChange');
		result.bgClass = '#E09E1E';
		result.color += '#E09E1E';
	}

	const emoji = () => {
		if (delta > 0) {
			return <Smile />;
		} else if (delta < 0) {
			return <Frown />;
		} else {
			return <Meh />;
		}
	};

	return (
		<div title={result.title} className={`message-container `} style={{backgroundColor: `${result.bgClass}`}}>
			<div
				className={`message-container-triangle`}
				style={{ borderTop: `16px solid ${result.color}` }}>

				</div>
			<div title={result.title} className="info-container">
				<p className={result.textClass}>
					<span className="me-2">{emoji()}</span>
					{result.text}
				</p>
			</div>
		</div>
	);
};

export default RiskChange;
