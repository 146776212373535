import React, { useState } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import { Trans } from 'react-i18next';
import { Card, CardBody, Button, Table, Row, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import Select from '../../../components/Form/Select';
import { ACCESS_OPTIONS } from './constants';
import { ReactComponent as ChevronSvg } from '../../../assets/img/chevron.svg';

const formatter = value => value || '-';

const accessFormatter = (value, onChange) => {
  return (
    <Select inline value={value} options={ACCESS_OPTIONS} onChange={onChange} />
  );
};

const headerStyle = {
  textAlign: 'left',
  fontSize: '0.9375em',
};

const getColumns = (onChange = () => {}) => [
  {
    accessorKey: 'select',
    header: ({ table }) => (
      <input
        type="checkbox"
        checked={table.getIsAllPageRowsSelected()}
        onChange={table.getToggleAllPageRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  },
  {
    accessorKey: 'name',
    header: <Trans i18nKey="table.userName" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'email',
    header: <Trans i18nKey="table.emailAddress" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'friendlyName',
    header: <Trans i18nKey="table.accountAlias" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'access',
    header: <Trans i18nKey="table.access" />,
    cell: info => accessFormatter(info.getValue(), onChange),
    headerStyle,
  },
];

const PageButtons = ({ table }) => {
  const curPage = table.getState().pagination.pageIndex + 1;
  const maxPage = table.getPageCount();
  return (
    <>
      <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
        {'<<'}
      </Button>
      <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
        {'<'}
      </Button>
      <span>
        {' '}
        Page: {curPage} / {maxPage}{' '}
      </span>
      <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
        {'>'}
      </Button>
      <Button onClick={() => table.setPageIndex(maxPage - 1)} disabled={!table.getCanNextPage()}>
        {'>>'}
      </Button>
    </>
  );
};

const UsersTable = ({ data, onClick, pageSizes = [7, 14, 21] }) => {
  const columns = React.useMemo(() => getColumns(), []);
  const [pageLen, setPageLen] = useState(pageSizes[0]);
  const [pageLenOpen, setPageLenOpen] = useState(false);
  const togglePageLen = () => setPageLenOpen(!pageLenOpen);

  const setPageSize = val => {
    setPageLen(val);
    table.setPageSize(Number(val));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: pageLen,
      },
    },
  });

  return (
    <Card className="dashboard-card">
      <CardBody>
        <Table className="evaluation-table evaluation-table-wrapper">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col xs="4">
            <Dropdown
              isOpen={pageLenOpen}
              toggle={togglePageLen}
              className="position-relative"
            >
              <DropdownToggle size="sm">
                <span style={{ margin: '5px' }}>
                  {pageSizes.find(option => option === pageLen) ? pageLen : '-'}
                </span>
                <ChevronSvg className="dropdown-arrow" />
              </DropdownToggle>
              <DropdownMenu>
                {pageSizes.map(val => (
                  <DropdownItem key={val} onClick={() => setPageSize(val)}>
                    {val}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs="4"></Col>
          <Col xs="4">
            <PageButtons table={table} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default UsersTable;

