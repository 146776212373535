import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SuccessSvg } from '../../assets/img/success.svg';
import { ReactComponent as DangerSvg } from '../../assets/img/danger.svg';

export const regex = {
	number: /\d/,
	lowercase: /[a-z]/,
	uppercase: /[A-Z]/,
	special: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
};

const PasswordBar = ({ value }) => {
	const { t } = useTranslation();

	const validators = useMemo(
		() => [
			{
				label: 'form.passwordRuleMin',
				checked: value ? value?.length >= 8 && value?.length <= 255 : false,
			},
			{
				label: 'form.passwordRuleUppercase',
				checked: regex.uppercase.test(value),
			},
			{
				label: 'form.passwordRuleLowercase',
				checked: regex.lowercase.test(value),
			},
			{
				label: 'form.passwordRuleSpecialCharacter',
				checked: regex.special.test(value),
			},
			{
				label: 'form.passwordRuleNumber',
				checked: regex.number.test(value),
			},
		],
		[value]
	);

	const progress = useMemo(() => {
		const checked = validators.filter(item => item.checked);

		return Math.floor((checked?.length / validators?.length) * 100);
	}, [validators]);

	const className = useMemo(() => {
		if (progress === 100) {
			return 'bg-success';
		}
		if (progress < 100 && progress >= 40) {
			return 'bg-warning';
		}

		return 'bg-danger';
	}, [progress]);

	return (
		<div className="password-bar">
			<div className="password-bar__wrap">
				<div className="password-bar__progress">
					<div
						className={className}
						style={{
							width: `${progress}%`,
							height: '100%',
							borderRadius: '4px',
						}}
					/>
				</div>
				<p>{t('form.strength')}</p>
			</div>
			<ul className="password-bar__list">
				{validators.map(item => {
					return (
						<li key={"password-bar-" + item.label} className="password-bar__list-item">
							<span>
								{value?.trim() ? (
									item.checked ? (
										<SuccessSvg className="password-bar__list-image" />
									) : (
										<DangerSvg className="password-bar__list-image" />
									)
								) : null}
							</span>
							{t(item.label)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default PasswordBar;
