import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation, Trans } from 'react-i18next';

import { ReactComponent as MessageSvg } from '../../assets/img/message.svg';
import { resendEmail } from '../../apiclients/EmailLogin';

const EmailConfirmationPopup = ({ isOpen, onToggle, email }) => {
	const { t } = useTranslation();

	const onClick = () => {
		resendEmail({ email }).then(() => {
			onToggle();
		});
	};

	return (
		<Modal
			backdrop
			isOpen={isOpen}
			toggle={onToggle}
			unmountOnClose={true}
			centered>
			<ModalBody className="d-flex justify-content-center align-items-center flex-column px-5 pt-3 pb-4 text-center">
				<MessageSvg />
				<h2 className="my-3">{t('signUp.popupTitle')}</h2>
				<p>
					<Trans
						i18nKey="signUp.popupDescription"
						values={{ email }}
						components={[<a href={`mailto:${email}`} />]}
					/>
				</p>
			</ModalBody>
			<ModalFooter>
				<p style={{ color: '#7F8992' }}>{t('signUp.popupDontReceive')}</p>
				<button
					onClick={onClick}
					className="d-block mx-auto bg-transparent border-0 p-0"
					style={{ color: '#1E69E0' }}>
					{t('signUp.popupResendEmail')}
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default EmailConfirmationPopup;
