import React from 'react';
import { Search } from 'react-feather';

const SearchInput = props => {
	return (
		<div className="search-input">
			<Search />
			<input {...props} />
		</div>
	);
};

export default SearchInput;
