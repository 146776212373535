import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import useTrendLoss from '../../Hooks/useTrendLoss';

const cardHeaderStyles = { paddingTop: '1.875rem' };

export default ({ data }) => {
	const { series, options } = useTrendLoss(data);
	const { t } = useTranslation();
	return (
		<Card className="dashboard-card">
			<CardHeader style={cardHeaderStyles}>
				<CardTitle>
					<h5 className="dashboard-chart-title">
						{t('ransomwareDashboard.exposureTrend')}
					</h5>
				</CardTitle>
			</CardHeader>
			<CardBody>
				<ReactApexChart series={series} options={options} />
			</CardBody>
		</Card>
	);
};
