import React from 'react';
import { useTranslation } from 'react-i18next';

const RedirectReason = ({ reason }) => {
	const { t } = useTranslation();
	// TODO handle other types of reasons here
	return 'reporterror' === reason ? (
		<>
			<p className="risk-assessment-warning">
				<span role="img" aria-label="Notice">
					❗
				</span>
				{t('riskAssessment.redirectReasonNoData')}
			</p>
		</>
	) : (
		<></>
	);
};

export default RedirectReason;
