import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import ChartDataLabels from 'chartjs-plugin-datalabels';
// import { Colours } from '../../../../constants/Colours';
import { Doughnut } from 'react-chartjs-2';
import Error from './Error';
import LoadingSpinner from './LoadingSpinner';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { ArcElement, Chart } from 'chart.js';
import { PackKeys } from '../../../../constants/PackKeys';
import { Colours } from '../Common/Constants';
import { ChevronRight } from 'react-feather';
Chart.register(ArcElement);


const getData = (packKey, chartData) => {
	const keys = Object.keys(chartData);
	const defaultColor = 'grey';
	const backgroundColors = keys.map(key => Colours[packKey]); // Використовуємо кольори згідно з назвою PackKey
	return {
		labels: [
			t('topology.compliant'),
			
		],
		datasets: [
			{
				data: chartData,
				backgroundColor: [backgroundColors[0], defaultColor],
				borderColor: 'transparent',
				borderWidth: 1,
				datalabels: {
					anchor: 'middle',
				},
			},
		],
	};
};

const options = {
	responsive: false,
	maintainAspectRatio: false,
	cutoutPercentage: 0,
	legend: {
		display: false,
	},
	elements: {
		arc: {
			weight: 2, // Встановлюємо товщину контуру
			borderColor: 'black', // Колір контуру
		},
	},
	plugins: {
		datalabels: {
			color: 'white',
			padding: 8,
			formatter: Math.round,
		},
	},
};

const ComplianceCharts = ({
	theme,
	error,
	results,
	packName,
	packKey,
	onInfoClick = () => {},
}) => {
	const [loading, setLoading] = useState(true);
	const [systems, setSystems] = useState({});
	const [rules, setRules] = useState([]);

	useEffect(() => {
		let timeout = setTimeout(() => {
			setSystems(getData(packKey, results.systems));
			setRules(getData(packKey, results.rules));
			setLoading(false);
		}, 400);

		return () => {
			clearTimeout(timeout);
		};
	}, [results]);
	const handleInfoClick = () => {
		// Викликаємо функцію onInfoClick з параметром 'Critical', якщо вона передана
		if (onInfoClick) {
			onInfoClick('Critical');
		}
	};

	return (
		<Card style={{ marginBottom: '0.5rem' }}>
			<CardHeader>
				<CardTitle className="CardTitleWrap">
					<h4 className="TitleCard">{packName}</h4>
					<button onClick={handleInfoClick} className="BntInfo my-4-step">
						More info <ChevronRight />
					</button>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{error && <Error text={t('complianceDashboard.trendError')} />}
				{!error && loading && <LoadingSpinner />}
				{!error && !loading && (
					<div className="chart" style={{ minHeight: '100px' }}>
						<Row>
							<Col>
								<Doughnut
									data={systems}
									options={options}
									plugins={[ChartDataLabels]}
									width={'100%'}
								/>
								<h5 className="pl-2">
									{t('complianceDashboard.systemCompliance')}
								</h5>
							</Col>
							<Col>
								<Doughnut
									data={rules}
									options={options}
									plugins={[ChartDataLabels]}
									width={'100%'}
								/>
								<h5 className="">{t('complianceDashboard.rulesCompliance')}</h5>
							</Col>
						</Row>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default connect(store => ({
	theme: store.theme.currentTheme,
}))(ComplianceCharts);
