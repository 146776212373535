import React from 'react';
import TalasmartResource from '../TalasmartResource';
import TalasmartK8SLogIssue from '../TalasmartK8SLogIssue';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

export const TYPES = {
	resource: 'resource',
	k8sLogIssue: 'k8s-log-issue',
};

export const TYPES_PAGES_ENUM = {
	[TYPES.resource]: <TalasmartResource />,
	[TYPES.k8sLogIssue]: <TalasmartK8SLogIssue />,
};

export const REMEDIATION_STEPS = {
	manual: 'manual',
	console: 'AWSConsole',
	cdk: 'cdk',
	terraform: 'Terraform',
	resolvedManifest: 'ResolvedManifest',
};

export const REMEDIATION_TABS = [
	// {
	// 	value: REMEDIATION_STEPS.manual,
	// 	label: <Trans i18nKey="talasmart.resource.manual" />,
	// },
	{
		value: REMEDIATION_STEPS.console,
		label: <Trans i18nKey="talasmart.resource.awsConsole" />,
	},
	// {
	// 	value: REMEDIATION_STEPS.cdk,
	// 	label: <Trans i18nKey="talasmart.resource.cdk" />,
	// },
	{
		value: REMEDIATION_STEPS.terraform,
		label: <Trans i18nKey="talasmart.resource.terraform" />,
	},
];
export const REMEDIATION_TABS_K8S = [
	{
		value: REMEDIATION_STEPS.resolvedManifest,
		label: <Trans i18nKey="talasmart.resource.resolvedManifest" />,
	},
];

export const SEVERITY_TYPES = {
	high: 'High',
	medium: 'Medium',
	low: 'Low',
	ignore: 'Ignore',
	critical: 'Critical',
};

export const SEVERITY_OPTIONS = [
	{
		value: SEVERITY_TYPES.high,
		label: <Trans i18nKey="ransomwareDashboard.high" />,
	},
	{
		value: SEVERITY_TYPES.medium,
		label: <Trans i18nKey="ransomwareDashboard.medium" />,
	},
	{
		value: SEVERITY_TYPES.critical,
		label: <Trans i18nKey="ransomwareDashboard.critical" />,
	},
	{
		value: SEVERITY_TYPES.low,
		label: <Trans i18nKey="ransomwareDashboard.low" />,
	},
	{
		value: SEVERITY_TYPES.ignore,
		label: <Trans i18nKey="ransomwareDashboard.ignore" />,
	},
];

export const COMPLIANCE_TYPES = {
	nonCompliant: 'NON-COMPLIANT',
	compliant: 'COMPLIANT',
};

export const formatPageData = info => {
	return [
		{
			title: t('talasmart.resource.resourceId'),
			value: info?.ResourceId,
		},
		{
			title: t('talasmart.resource.resourceName'),
			value: info?.ResourceName || info?.ResourceId,
		},
		{
			title: t('talasmart.resource.resourceType'),
			value: info?.ResourceType,
		},
		{
			title: t('talasmart.resource.issue'),
			value: info?.SecurityTestRule,
		},
		{
			title: t('talasmart.resource.description'),
			value: info?.Description,
		},
		{
			title: t('talasmart.resource.recommendedSeverity'),
			value: info?.Severity,
		},
		{
			title: t('talasmart.resource.severityOverride'),
			value: info?.SeverityOverride,
		},

		{
			title: t('talasmart.resource.justification'),
			value: info?.Justification,
		},
		{
			title: t('talasmart.resource.potentialRisk'),
			value: `${t('talasmart.resource.risk')}: \n ${t(
				'talasmart.resource.loss'
			)}:`,
		},
		{
			title: t('talasmart.resource.systemImpact'),
			value: info?.SystemImpact,
		},
		{
			title: t('talasmart.resource.technicalImpact'),
			value: info?.TechnicalImpact,
		},
		{
			title: t('talasmart.resource.notes'),
			value: info?.Notes,
		},
	]
		.map(v => `${v.title}: ${v.value || ''}`)
		.join('\r\n');
};

export const TALASMART_QUERY_TYPES = {
	description: 'Description',
	technicalImpact: 'TechnicalImpact',
	systemImpact: 'SystemImpact',
	awsConsole: 'AWSConsole',
	terraform: 'Terraform',
	resolvedManifest: 'ResolvedManifest',
	title: 'Title',
	summary: 'Summary',
};

export const TALASMART_WAIT_TEXT =
	'Please wait for TalaSmart to create the proper solution for you.';

export const TALASMART_RATE_LIMIT =
	'TalaSmart has queued your request. The request will be processed as soon as resources are freed up.';
