import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopologyConfig from '../Config';
import { Trans } from 'react-i18next';
import { getCurrentAccountId } from '../../../services/AuthService';
import moment from 'moment';
import {
    getChangedValues,
    setChangedValues,
} from '../../tests/AutomatedTests/ChangedValues';

const severities = ['Critical', 'High', 'Medium', 'Low'];
const compliance = ['COMPLIANT', 'NON-COMPLIANT'];

const TABS = {
    ISSUES: 'issues',
    RESOURCES: 'resources',
};

const config = new TopologyConfig(),
    leftEllipses = {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        direction: 'rtl',
        textAlign: 'left',
    },
    largeHeaderStyle = {
        width: '30%',
        textAlign: 'left',
    },
    smallHeaderStyle = {
        width: '10%',
        textAlign: 'left',
    },
    largeColumnStyle = {
        textAlign: 'left',
    },
    smallColumnStyle = {
        textAlign: 'justify',
    },
    hiddenHeader = { display: 'none' };

const onSeverityChange = (selected, data) => {
    const values = getChangedValues();
    const key = `${data.key}_${data.fragmentId}`;
    const change = {
        key: data.key,
        fragmentId: data.fragmentId,
        Severity: selected,
    };

    values[key] = change;
    setChangedValues(values);
};

const SeverityDropdown = ({ defaultValue, data }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const toggle = () => setDropdownOpen(!dropdownOpen);

    const handleSelect = severity => {
        setSelectedValue(severity);
        onSeverityChange(severity, data);
        toggle();
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className="position-relative">
            <DropdownToggle caret>
                {selectedValue}
            </DropdownToggle>
            <DropdownMenu >
                {severities.map(severity => (
                    <DropdownItem
                        key={severity}
						
                        onClick={() => handleSelect(severity)}
                    >
                        {severity}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};

const severityDdl = (defaultValue, data) => {
    if (data.Result === 'NON-COMPLIANT') {
        return (
            <SeverityDropdown
                defaultValue={defaultValue}
                data={data}
            />
        );
    } else {
        return '';
    }
};

const COLUMNS = {
    [TABS.ISSUES]: [
        {
            accessorKey: 'SecurityTestRule',
            header: () => <Trans i18nKey="table.securityTestRule" />,
        },
        {
            accessorKey: 'ResourceType',
            header: () => <Trans i18nKey="table.resourceType" />,
            cell: info => {
                const text = config.getLabel(info.getValue());
                return text && text.length > 0 ? text : info.getValue();
            },
        },
        {
            accessorKey: 'ResourceId',
            header: () => <Trans i18nKey="table.resourceId" />,
            cell: info => (
                <div title={info.getValue()} style={leftEllipses}>
                    {info.getValue()}
                </div>
            ),
        },
        {
            accessorKey: 'Result',
            header: () => <Trans i18nKey="table.result" />,
            sortingFn: (a, b, order) => {
                if (a.Result === 'NON-COMPLIANT' && b.Result === 'NON-COMPLIANT') {
                    const getSeverity = severity =>
                        severities.length - severities.indexOf(severity);

                    const aSeverity = getSeverity(a.Severity);
                    const bSeverity = getSeverity(b.Severity);

                    return bSeverity - aSeverity;
                } else {
                    if (order === 'asc') {
                        return compliance.indexOf(a.Result) - compliance.indexOf(b.Result);
                    } else {
                        return compliance.indexOf(b.Result) - compliance.indexOf(a.Result);
                    }
                }
            },
			cell: info => {
                const isCompliant = info.getValue() === 'COMPLIANT';
                const style = {
                    color: isCompliant ? 'green' : 'red',
                };
                return <span style={style}>{info.getValue()}</span>;
            },
        },
        {
            accessorKey: 'Severity',
            header: () => <Trans i18nKey="table.severity" />,
            sortingFn: (a, b, order) => {
                const getSeverity = (result, severity) =>
                    result === 'NON-COMPLIANT'
                        ? severities.length - severities.indexOf(severity)
                        : 0;

                const aSeverity = getSeverity(a.Result, a.Severity);
                const bSeverity = getSeverity(b.Result, b.Severity);

                return order === 'desc' ? bSeverity - aSeverity : aSeverity - bSeverity;
            },
            cell: info => severityDdl(info.getValue(), info.row.original),
        },
        {
            accessorKey: 'ResourceName',
            header: () => <Trans i18nKey="table.resourceName" />,
            headerStyle: hiddenHeader,
            cellStyle: hiddenHeader,
        },
        // {
        //     accessorKey: 'ResourceArn',
        //     header: () => <Trans i18nKey="table.resourceArn" />,
        //     headerStyle: hiddenHeader,
        //     cellStyle: hiddenHeader,
        // },
    ],
    [TABS.RESOURCES]: [
        {
            accessorKey: 'resourceId',
            header: () => <Trans i18nKey="table.resourceId" />,
            headerStyle: largeHeaderStyle,
            cellStyle: largeColumnStyle,
            cell: info => (
                <div title={info.getValue()} style={leftEllipses}>
                    {info.getValue()}
                </div>
            ),
        },
        {
            accessorKey: 'name',
            header: () => <Trans i18nKey="table.resourceName" />,
            headerStyle: largeHeaderStyle,
            cellStyle: largeColumnStyle,
        },
        {
            accessorKey: 'resourceType',
            header: () => <Trans i18nKey="table.resourceType" />,
            headerStyle: largeHeaderStyle,
            cellStyle: largeColumnStyle,
            cell: info => {
                return (
                    <div>
                        <img
                            src={config.getImage(info.getValue())}
                            alt={info.getValue()}
                            className="me-2"
                            style={{ height: '10%', width: '10%' }}
                        />
                        <span>{config.getLabel(info.getValue())}</span>
                    </div>
                );
            },
        },
        {
            accessorKey: 'parent',
            header: () => <Trans i18nKey="table.parentId" />,
            headerStyle: largeHeaderStyle,
            cellStyle: largeColumnStyle,
            cell: info => (
                <div title={info.getValue()} style={leftEllipses}>
                    {info.getValue()}
                </div>
            ),
        },
        {
            accessorKey: 'level',
            header: () => <Trans i18nKey="table.level" />,
            headerStyle: smallHeaderStyle,
            cellStyle: smallColumnStyle,
        },
    ],
};

const DEFAULT_SORTED = {
    [TABS.RESOURCES]: [
        {
            id: 'parent',
            desc: false,
        },
        {
            id: 'resourceId',
            desc: false,
        },
    ],
    [TABS.ISSUES]: [
        {
            id: 'Severity',
            desc: true,
        },
    ],
};

const fileName = {
    [TABS.RESOURCES]: () => {
        const date = moment().format('YYYYMMDDHHmmss');
        const accountId = getCurrentAccountId();
        return `Resources_${accountId}_${date}`;
    },
    [TABS.ISSUES]: props => {
        const date = moment().format('YYYYMMDDHHmmss');
        return `${props.report.PackName}_${date}_${props.report.ReportId}`;
    },
};

const sortRowsByCriticality = rows => {
    const nonCompliantRows = rows.filter(r => r.Result === 'NON-COMPLIANT');
    const compliantRows = rows.filter(r => r.Result === 'COMPLIANT');

    const getSeverity = severity =>
        severities.length - severities.indexOf(severity);
    const criticalityCompare = (rowA, rowB) => {
        const aSeverity = getSeverity(rowA.Severity);
        const bSeverity = getSeverity(rowB.Severity);

        return bSeverity - aSeverity;
    };

    const sortedRows = nonCompliantRows.sort(criticalityCompare);
    sortedRows.push(...compliantRows.sort(criticalityCompare));
    return sortedRows;
};

export { COLUMNS, DEFAULT_SORTED, fileName, sortRowsByCriticality, TABS };