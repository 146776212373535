import React, { useEffect, useMemo, useState } from 'react';
import Vulnerabilities from '../../assessment/Controls/ReviewScore/Vulnerabilities';
import { Button, Card, CardHeader, Row, Input } from 'reactstrap';
import { ReactComponent as ArrowUpRight } from '../../../../assets/img/arrowUpRight.svg';
import { ReactComponent as CloseSvg } from '../../../../assets/img/close.svg';
import InfoModal from '../../../../components/InfoModal';
import useReportInfo from '../../../../hooks/useReportInfo';

const cardBackgroundStyle = {
	background: '#F4F7FC',
	height: '3.5rem',
	display: 'flex',
	justifyContent: 'flex-end',
};

const VulnerabilityPopup = ({ data, onClose, onIssueNewTab }) => {
	const { vulnerabilities, issues, lastReport } = data;
	const [isOpen, setIsOpen] = useState(false);
	const [openedItem, setOpenedItem] = useState(null);
	const { formatReport, data: resultData, fetchData } = useReportInfo();
	

	useEffect(() => {
		fetchData(lastReport, n => n, issues);
	}, [fetchData, issues, lastReport]);

	const formattedReport = useMemo(() => {
		if (!openedItem) return { data: {}, info: {} };

		const resource = resultData?.nodes?.find(
			item => item?.data?.id === openedItem?.ResourceName
		);
		const formatData = formatReport(resource?.data, lastReport);

		return formatData || { data: {}, info: {} };
	}, [formatReport, lastReport, openedItem, resultData]);

	const onReportClick = item => {
		setIsOpen(true);
		setOpenedItem(item);
	};



	return (
		<Card className="dashboard-card vulnerability-wrap ">
			<CardHeader style={cardBackgroundStyle}>
				<Row className="gap-1">
				
					<Button
						onClick={onIssueNewTab}
						size="sm"
						className="legend-button close-button">
						<ArrowUpRight />
					</Button>
					<Button
						size="sm"
						onClick={onClose}
						className="legend-button close-button ">
						<CloseSvg />
					</Button>
				</Row>
			</CardHeader>
			<Vulnerabilities
				isInline
				data={{ vulnerabilities, issues }}
				onReportClick={onReportClick}
				
			/>
			{isOpen && !!Object.keys(formattedReport?.data || {}).length && (
				<div style={{ position: 'absolute', right: 0, height: '100%' }}>
					<InfoModal
						data={formattedReport}
						onClose={() => setIsOpen(false)}
						report={lastReport}
					/>
				</div>
			)}
		</Card>
	);
};

export default VulnerabilityPopup;
