import React, { useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, Col, NavLink, NavItem, Row , Button, Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,} from 'reactstrap';
import { useReactTable, getCoreRowModel, flexRender,getPaginationRowModel } from '@tanstack/react-table';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as ChevronSvg } from '../../../../../assets/img/chevron.svg';
import { getCurrentAccountId } from '../../../../../services/AuthService';
import { getTruncatedResourceName as resourceName } from '../../../Common/RansomwareUtilites';
const PAGE_SIZES = [5, 10, 20];
const ReviewResources = ({ hook, mode }) => {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
	const [pageLen, setPageLen] = useState(PAGE_SIZES[0]);
	const [pageLenOpen, setPageLenOpen] = useState(false);
	const togglePageLen = () => setPageLenOpen(!pageLenOpen);

	const setPageSize = val => {
		/*
        setPagination({
            pageSize: Number(val),
            pageIndex: 0
        })
        */
		setPageLen(val);
		tableInstance.setPageSize(Number(val));
	};

    const resourceKeyMap = {
        S3: <Trans i18nKey="reviewScan.s3Buckets" />,
        'EKS-Cluster': <Trans i18nKey="reviewScan.eks" />,
        'Cache-Cluster': <Trans i18nKey="reviewScan.cache" />,
        'CloudFront-Distribution': <Trans i18nKey="reviewScan.cloudFront" />,
    };
	const PageButtons = ({ table }) => {
		const curPage = table.getState().pagination.pageIndex + 1;
		const maxPage = table.getPageCount();
		return (
			<>
				<Button
					onClick={() => table.firstPage()}
					disabled={!table.getCanPreviousPage()}>
					{'<<'}
				</Button>
				<Button
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}>
					{'<'}
				</Button>
				<span>
					{' '}
					Page: {curPage} / {maxPage}{' '}
				</span>
				<Button
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}>
					{'>'}
				</Button>
				<Button
					onClick={() => table.lastPage()}
					disabled={!table.getCanNextPage()}>
					{'>>'}
				</Button>
			</>
		);
	};
    const columns = useMemo(() => [
        {
            accessorKey: 'id',
            header: () => <Trans i18nKey="table.resourceId" />,
            cell: info => (
                <div style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
                    {info.row.original.name === 'Account' && (
                        <FontAwesomeIcon icon={faQuestionCircle} className="me-2" title={t('reviewScan.defaultIncluded')} />
                    )}
                    <span title={info.getValue()} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {resourceName(info.getValue())}
                    </span>
                </div>
            ),
        },
        {
            accessorKey: 'name',
            header: () => <Trans i18nKey="table.resourceName" />,
            cell: info => info.getValue(),
        },
    ], [t]);

    const tableInstance = useReactTable({
        data: hook.filteredResources,
        columns,
        getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		initialState: {
			pagination: {
				pageSize: PAGE_SIZES[0],
			},
		
		},
    });

    const toggleAllRowsSelected = (isSelected) => {
        const newSelectedRows = isSelected ? hook.filteredResources.map(r => r.id) : [];
        setSelectedRows(newSelectedRows);
    };

    const toggleRowSelected = (rowId) => {
        setSelectedRows(prev => prev.includes(rowId) ? prev.filter(id => id !== rowId) : [...prev, rowId]);
    };

    return (

        <Row className="mt-4">
            <Col sm={2}>
                <Card>
                    <CardHeader>
                        <h4>{t('reviewScan.resourceTypes')}</h4>
                    </CardHeader>
                    <CardBody>
                        <ul className="nav flex-column">
                            {hook.resourceTypes.map(type => (
                                <NavItem key={type}>
                                    <NavLink href="#" onClick={() => hook.filterResources(type)} active={type === hook.filter}>
                                        {resourceKeyMap[type] ?? type}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </ul>
                    </CardBody>
                </Card>
            </Col>
            <Col>
                <Card>
                    <CardHeader>
                        <h4>{t('reviewScan.ransomwareResources')}</h4>
                    </CardHeader>
                    <CardBody>
                        {mode === 0 && <p>{t('reviewScan.selectedByDefault')}</p>}
                        {mode === 1 && <p>{t('reviewScan.notSelectedByDefault')}</p>}
                        <div>
                            <table className="table">
                                <thead>
                                    {tableInstance.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(column => (
                                                <th key={column.id} style={column.column.columnDef.headerStyle}>
                                                    {flexRender(column.column.columnDef.header, column.getContext())}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    {tableInstance.getRowModel().rows.map((row, index) => (
                                        <tr key={row.id} onClick={() => toggleRowSelected(row.original.id)}>
                                            {row.getVisibleCells().map(cell => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
			<Row>
				<Col xs="4">
					<Dropdown
						isOpen={pageLenOpen}
						toggle={togglePageLen}
						className="position-relative">
						<DropdownToggle size="sm">
							<span style={{ margin: '5px' }}>
								{PAGE_SIZES.find(option => option === pageLen) ? pageLen : '-'}
							</span>
							<ChevronSvg className="dropdown-arrow" />
						</DropdownToggle>
						<DropdownMenu>
							{PAGE_SIZES.map(val => {
								return (
									<DropdownItem key={val} onClick={() => setPageSize(val)}>
										{val}
									</DropdownItem>
								);
							})}
						</DropdownMenu>
					</Dropdown>
				</Col>
				<Col xs="4"></Col>
				<Col xs="4">
					<PageButtons table={tableInstance} />
				</Col>
			</Row>
        </Row>
    );
};

export default ReviewResources;
