import React, { useState } from 'react';
import '../styles.css';
import cross from './../../../../assets/img/Button (Close).svg';

import QuickStartAws from './QuickStart/QuickStartAws';
import QuickStartAzure from './QuickStart/QuickStartAzure';
import QuickStartOnprem from './QuickStart/QuickStartOnprem';
import QuickStartK8S from './QuickStart/QuickStartK8S';

function QuickStartPanel({ close, cloudType, cloudSystem, addAccount }) {
	const [activeTab, setActiveTab] = useState('1');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	console.log('qs cloud system:', cloudSystem);
	const tabs = {
		aws: <QuickStartAws></QuickStartAws>,
		azure: <QuickStartAzure></QuickStartAzure>,
		onprem: <QuickStartOnprem></QuickStartOnprem>,
		k8s: <QuickStartK8S></QuickStartK8S>,
	};

	return (
		<div className="quickStartPanel">
			<img
				src={cross}
				alt="cross"
				className="cross"
				onClick={() => close(false)}
			/>
			{addAccount ? tabs[cloudSystem] : tabs[cloudType]}
		</div>
	);
}

export default QuickStartPanel;
