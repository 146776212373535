import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSyncResults } from '../../tests/AutomatedTests/ResultService';
import {
	COMPLIANCE_TYPES,
	TALASMART_QUERY_TYPES,
	formatPageData,
} from '../constants';
import { getCurrentAccountId } from '../../../services/AuthService';
import {
	talaSmartGetResourceInfo,
	talaSmartQuery,
	talaSmartSetResourceInfo,
} from '../../../apiclients/SmartQueries';
import {
	getCompliantResourceList,
	getIssueList,
	results as getRRAResults,
} from '../../../apiclients/Ransomware';

import { getUserType } from '../../../services/AuthService';
import { CloudTypes } from '../../../constants/CloudInfo';

const useTalasmartResource = activeRemediation => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const navigate = useNavigate();

	const [pack, setPack] = useState(null);
	const [reportName, setReportName] = useState(null);
	const [resourceId, setResourceId] = useState(null);
	const [rawResourceId, setRawResourceId] = useState(null);
	const [resourceType, setResourceType] = useState('');
	const [securityTestRule, setSecurityTestRule] = useState('');
	const [rootCause, setRootCause] = useState('');
	const [info, setInfo] = useState(null);
	const [evalId, setEvalId] = useState(null);
	const [numFragments, setNumFragments] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [resultLoaded, setResultLoaded] = useState(false);
	const [results, setResults] = useState({ counts: { total: 0 }, rows: [] });
	const [error] = useState(false);
	const [pageData, setPageData] = useState({});
	const [resourceInfo, setResourceInfo] = useState({
		SeverityOverride: '',
		Justification: '',
		Notes: '',
	});
	const [remediationInfo, setRemediationInfo] = useState('');
	const [isRemediationLoading, setIsRemediationLoading] = useState(false);
	const [remediationCache, setRemediationCache] = useState({});

	const report = useMemo(() => {
		const reportInfo = {
			PackName: pack,
			ConformancePack: pack,
			EvalId: evalId,
			Fragments: numFragments,
			ReportId: reportName,
		};
		return reportInfo;
	}, [evalId, numFragments, pack, reportName]);

	const fetchPageData = useCallback(async () => {
		var severity = 'Unknown';
		if ('RRA' === report.ConformancePack) {
			// extract the severity from the RRA results
			const filteredIssues = results.rra?.Vulnerabilities?.filter(
				item => item.SecurityTestRule === securityTestRule
			);
			if (filteredIssues && 0 !== filteredIssues.length) {
				severity = filteredIssues[0].Severity;
			}
		} else {
			// extract the severity from the issues
			const filteredIssues = results.rows?.filter(
				item =>
					item.SecurityTestRule === securityTestRule &&
					item.ResourceId === resourceId
			);
			if (filteredIssues && 0 !== filteredIssues.length) {
				severity = filteredIssues[0].Severity;
			}
		}
		setInfo({
			SecurityTestRule: securityTestRule,
			ResourceType: resourceType,
			ResourceId: resourceId,
			ResourceName: resourceId,
			RootCause: rootCause,
			Severity: severity,
			Notes: resourceInfo.Notes,
			TechnicalImpact: pageData.technicalImpact,
			SystemImpact: pageData.systemImpact,
			Justification: resourceInfo.Justification,
			Description: pageData.description,
			SeverityOverride: resourceInfo.SeverityOverride,
		});

		const body = {
			reportInfo: report,
			AccountID: getCurrentAccountId(),
			ResourceID: resourceId,
			ResourceType: resourceType,
			SecurityTestRule: securityTestRule,
			RootCause: rootCause,
			RawResourceId: rawResourceId,
		};

		await talaSmartQuery(
			{
				...body,
				Query: TALASMART_QUERY_TYPES.description,
			},
			v => onSuccess('description', v)
		);

		await talaSmartQuery(
			{
				...body,
				Query: TALASMART_QUERY_TYPES.technicalImpact,
			},
			v => onSuccess('technicalImpact', v)
		);

		await talaSmartQuery(
			{
				...body,
				Query: TALASMART_QUERY_TYPES.systemImpact,
			},
			v => onSuccess('systemImpact', v)
		);

		setIsRemediationLoading(true);
		await onChangeRemediationTab(activeRemediation);
	}, [
		report,
		resourceId,
		rawResourceId,
		resourceType,
		rootCause,
		securityTestRule,
		results,
	]);

	const fetchResults = useCallback(async report => {
		const res = await getSyncResults(report);
		const formatResRows = res.rows?.filter(
			item => item.Result === COMPLIANCE_TYPES.nonCompliant
		);
		var RRAResults = {};
		var RRAIssueList = {};
		if ('RRA' === report.PackName) {
			const rraResults = await getRRAResults(report.ReportId);
			RRAResults = rraResults.data;
			const rraIssues = await getIssueList(report.ReportId);
			RRAIssueList = rraIssues.data;
		}

		setResults({
			rows: formatResRows,
			counts: res.counts.nonCompliantCount,
			rra: RRAResults,
			rraIssues: RRAIssueList,
		});
	}, []);

	useEffect(() => {
		setPack(queryParams.get('pack'));
		setReportName(queryParams.get('reportName'));
		setResourceId(queryParams.get('resourceId'));
		setResourceType(queryParams.get('resourceType'));
		setSecurityTestRule(queryParams.get('securityTestRule'));
		setRootCause(queryParams.get('rootCause'));
		setNumFragments(queryParams.get('numFragments'));
		setEvalId(queryParams.get('evalId'));
		setRawResourceId(queryParams.get('rawResourceId', ''));
	}, [queryParams]);

	useEffect(() => {
		(async () => {
			if (!report.ConformancePack || !report.ReportId || !report.PackName)
				return;
			setResultLoaded(false);
			await fetchResults(report);
			setResultLoaded(true);
		})();
	}, [fetchResults, report]);

	useEffect(() => {
		(async () => {
			if (!resultLoaded) return;

			setLoaded(false);
			await fetchPageData();
			setLoaded(true);
		})();
	}, [fetchPageData, resultLoaded]);

	useEffect(() => {
		if (pageData && resourceInfo && results) {
			var severity = 'Unknown';
			if ('RRA' === report.ConformancePack) {
				// extract the severity from the RRA results
				const filteredIssues = results.rra?.Vulnerabilities?.filter(
					item => item.SecurityTestRule === securityTestRule
				);
				if (filteredIssues && filteredIssues.length !== 0) {
					severity = filteredIssues[0].Severity;
				}
			} else {
				// extract the severity from the issues
				const filteredIssues = results.rows?.filter(
					item =>
						item.SecurityTestRule === securityTestRule &&
						item.ResourceId === resourceId
				);
				if (filteredIssues && filteredIssues.length !== 0) {
					severity = filteredIssues[0].Severity;
				}
			}
			setInfo({
				SecurityTestRule: securityTestRule,
				ResourceType: resourceType,
				ResourceId: resourceId,
				ResourceName: resourceId,
				RootCause: rootCause,
				Severity: severity,
				Notes: resourceInfo.Notes,
				TechnicalImpact: pageData.technicalImpact,
				SystemImpact: pageData.systemImpact,
				Justification: resourceInfo.Justification,
				Description: pageData.description,
				SeverityOverride: resourceInfo.SeverityOverride,
			});
		}
	}, [
		pageData,
		resourceInfo,
		results,
		report,
		resourceId,
		securityTestRule,
		resourceType,
		rootCause,
	]);

	const onChangeResource = async resource => {
		const queryParams = new URLSearchParams();

		// report info
		queryParams.set('pack', pack);
		queryParams.set('reportName', reportName);
		queryParams.set('evalId', evalId);
		queryParams.set('numFragments', numFragments);
		// resource info
		queryParams.set('resourceId', resource.ResourceId);
		queryParams.set('resourceType', resource.ResourceType);
		queryParams.set('securityTestRule', resource.SecurityTestRule);
		queryParams.set('rootCause', resource.RootCause);

		// clear caches
		setRemediationCache({});
		setPageData({});

		navigate({ search: queryParams.toString() });

		setResourceId(resource.ResourceId);
	};

	const onCopy = () => {
		const data = formatPageData(info);
		navigator.clipboard.writeText(data);
		console.log(info, 'info><>><><');
	};

	const onDownload = () => {
		const data = formatPageData(info);
		const filename = `Resource_info_${resourceId}`;
		const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveBlob(blob, filename);
		} else {
			const elem = window.document.createElement('a');
			elem.href = window.URL.createObjectURL(blob);
			elem.download = filename;
			document.body.appendChild(elem);
			elem.click();
			document.body.removeChild(elem);
		}
	};

	const onSuccess = (field, value) => {
		setPageData(v => ({ ...v, [field]: value }));
	};

	const onBlurField = async () => {
		const formData = new FormData();
		Object.keys(resourceInfo).forEach(key => {
			formData.append(key, resourceInfo[key]);
		});
		formData.append('AccountID', getCurrentAccountId());
		formData.append('ResourceID', resourceId);

		await talaSmartSetResourceInfo(formData);
	};

	const onChangeField = (field, value) => {
		setResourceInfo(v => ({ ...v, [field]: value }));
	};

	const onChangeRemediationTab = async tab => {
		if (remediationCache[tab]) {
			setRemediationInfo(remediationCache[tab]);
			setIsRemediationLoading(false);
			return;
		}
		const body = {
			reportInfo: report,
			AccountID: getCurrentAccountId(),
			ResourceID: resourceId,
			ResourceType: info?.ResourceType,
			SecurityTestRule: info?.SecurityTestRule,
			RootCause: info?.RootCause,
			RawResourceId: rawResourceId,
		};

		setIsRemediationLoading(true);
		return await talaSmartQuery(
			{
				...body,
				Query: tab,
			},
			response => {
				setRemediationCache({
					[tab]: response,
					...remediationCache,
				});
				setRemediationInfo(response);
				setIsRemediationLoading(false);
			},
			() => setIsRemediationLoading(false)
		);
	};

	return {
		info,
		results,
		loaded,
		error,
		pageData,
		resourceInfo,
		remediationInfo,
		resourceId,
		report,
		setRemediationInfo,
		onChangeResource,
		onCopy,
		onDownload,
		onChangeField,
		onBlurField,
		onChangeRemediationTab,
		isRemediationLoading,
	};
};

export default useTalasmartResource;
