import { Button, Row } from 'reactstrap';
import React, { useState } from 'react';

import { Mode } from '../../../../constants/PackKeys';
import StartTest from '../../StartTest';
import { t } from 'i18next';
import { isDemoAccount } from '../../../../services/accountTypeService';
import DemoPopup from '../../../../components/DemoPopup';

export default ({ packName, enableAudit }) => {
	const [open, setOpen] = useState(false);
	const [mode, setMode] = useState('');
	const [demoPopup, setDemoPopup] = useState(false);


	const onClick = testMode => {
		setOpen(true);
		setMode(testMode);
	};
	const onClose = () => setOpen(false);
	const auditTest = () => onClick(Mode.audit);
	const awsTest = () => onClick(Mode.automated);

	const startFullTest = () => {
		isDemoAccount() ? setDemoPopup(true) : auditTest();
	};

	const startAutoTest = () => {
		isDemoAccount() ? setDemoPopup(true) : awsTest();
	};

	return (
		<Row>
			<DemoPopup
				isOpen={demoPopup}
				setDemoPopup={setDemoPopup}
				title={t('liveDemo.noTests')}
			/>
			<div className="d-flex align-items-center justify-content-center gap-6">
	
					<Button
						size="lg"
						color="primary"
						onClick={startFullTest}
						disabled={!enableAudit}>
						{t('tests.startFullCompliance')}
					</Button>
				
				
					<Button size="lg" color="primary" onClick={startAutoTest}>
						{t('tests.startAutomatedCompliance')}
					</Button>
				
			</div>
			{open && <StartTest mode={mode} pack={packName} onClose={onClose} />}
		</Row>
	);
};
