export const ACCESS_TYPES = {
	admin: 'admin',
	engineer: 'engineer',
	audit: 'audit',
};

export const ACCESS_OPTIONS = [
	{
		value: ACCESS_TYPES.admin,
		label: 'Admin',
	},
	{
		value: ACCESS_TYPES.engineer,
		label: 'Engineer',
	},
	{
		value: ACCESS_TYPES.audit,
		label: 'Audit',
	},
];

export const MOCK_USERS = [
	{
		id: '1',
		name: 'user 1',
		email: 'test@gmail.com',
		access: ACCESS_TYPES.admin,
		friendlyName: 'user 1',
	},
	{
		id: '2',
		name: 'user 2',
		email: 'test@gmail.com',
		access: ACCESS_TYPES.admin,
		friendlyName: 'user 2',
	},
];
