import {
	getAwsComplianceResults,
	getAwsComplianceResultsSync,
	getRemediation,
} from '../../../apiclients/AutomatedResults';
import { getItem, setItem } from '../../../services/browserStorageService';

import { EvaluationApiClient } from '../../../apiclients/EvaluationApiClient';

let ncCount = 0;
let cCount = 0;
let total = 0;

const resetCounts = () => {
	ncCount = 0;
	cCount = 0;
	total = 0;
};

const mapper = (item, fragmentId) => {
	if (item.Result === 'NON-COMPLIANT') {
		ncCount++;
	}

	if (item.Result === 'COMPLIANT') {
		cCount++;
	}

	return Object.assign({}, item, { fragmentId: fragmentId, key: total++ });
};

const getResults = async (report, updateReportProgress) => {
	resetCounts();
	const results = await getAwsComplianceResults(report, e =>
		{console.error('Could not get results', e)},
		updateReportProgress
	);
	const mappedResults = results.flatMap((fragmentResult, fragmentId) =>
		fragmentResult.map(item => mapper(item, fragmentId))
	);

	return {
		rows: mappedResults,
		counts: {
			total: total,
			nonCompliantCount: ncCount,
			compliantCount: cCount,
		},
	};
};

const getSyncResults = async report => {
	resetCounts();
	const results = await getAwsComplianceResultsSync(report, e =>
		console.error('Could not get results', e)
	);

	const mappedResults = results.flatMap((fragmentResult, fragmentId) =>
		fragmentResult?.map(item => mapper(item, fragmentId))
	);

	return {
		rows: mappedResults,
		counts: {
			total: total,
			nonCompliantCount: ncCount,
			compliantCount: cCount,
		},
	};
};

const saveResults = async (reportId, packName, results, dirtyFragments) => {
	let errors = 0;

	const client = new EvaluationApiClient(),
		result = i => ({ packName, reportId, fragmentId: i, body: results[i] }),
		getResponse = async i => await client.saveAwsResults(result(i)),
		isSaved = response => response.success,
		save = async i => {
			const response = await getResponse(i);
			const saved = await isSaved(response);
			if (!saved) {
				console.error('An error occurred for fragment: ', i);
				errors++;
			}

			return saved;
		};

	const saveResponses = [];

	dirtyFragments.forEach(async (isDirty, i) => {
		if (isDirty) {
			saveResponses.push(save(i));
		}
	});

	await Promise.all(saveResponses);

	return errors === 0;
};

const getRemediationData = async () => {
	let data = getItem('awsRemediationData');

	const getData = async () => {
		const data = await getRemediation();
		setItem('awsRemediationData', JSON.stringify(data));
		return data;
	};

	return data ? JSON.parse(data) : await getData();
};

export { getResults, saveResults, getRemediationData, getSyncResults };
