import React, { useEffect, useState } from 'react';

import { Progress } from 'reactstrap';
import { progressState } from '../../../../apiclients/AutomatedTests';
import { t } from 'i18next';

const state = {
	[progressState.none]: { total: 25, report: 10, topology: 0 },
	[progressState.evalError]: { total: 100, report: 0, topology: 0 },
	[progressState.topologyError]: { total: 100, report: 0, topology: 0 },
	[progressState.evalStart]: { total: 50, report: 40, topology: 0 },
	[progressState.evalSuccess]: { total: 55, report: 100, topology: 10 },
	[progressState.topologyStart]: { total: 75, report: 100, topology: 40 },
	[progressState.topologySuccess]: { total: 95, report: 100, topology: 95 },
};

const colour = progress => {
	const isError =
		progress === progressState.evalError ||
		progress === progressState.topologyError;
	return isError ? 'danger' : 'primary';
};

export default function ({ testProgress }) {
	const [progress, setProgress] = useState(testProgress);
	const [totalColour, setTotalColour] = useState(colour(testProgress));

	useEffect(() => {
		const delay = 500;
		setTotalColour(colour(testProgress));
		setTimeout(() => setProgress(state[testProgress]), delay);
	}, [testProgress, progress]);

	return (
		<>
			<div id="progress-container" className="w-50 m-3 me-5 ms-5">
				<div className="mt-2">
					<h4>{t('tests.evaluationInProgress')}</h4>
					<Progress
						color={totalColour}
						animated={progress.total !== 100}
						value={progress.total}
					/>
				</div>
				<div className="mt-2">
					<h5>{t('tests.testEvaluation')}</h5>
					<Progress
						color="info"
						animated={progress.report !== 100}
						value={progress.report}
					/>
				</div>
				<div className="mt-2">
					<h5>{t('tests.topologyEvaluation')}</h5>
					<Progress
						color="success"
						animated={progress.topology !== 100}
						value={progress.topology}
					/>
				</div>
			</div>
		</>
	);
}
