import React from 'react';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import icon6 from '../../../assets/img/6.svg';
import figure32 from '../../../assets/img/figure8.1.png';
import figure33 from '../../../assets/img/figure8.2.png';
import figure34 from '../../../assets/img/figure8.3.png';
import figure35 from '../../../assets/img/figure8.4.png';
import figure36 from '../../../assets/img/figure8.5.png';
import figure37 from '../../../assets/img/figure8.6.png';
import figure38 from '../../../assets/img/figure8.7.png';

function Step8() {
	const { t } = useTranslation();
	return (
		<div id="step8" className="instructions-steps">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 8</h5>

				<div>
					<h5 className="steps-title">
						{t('detailedInstructions.runRansomware')}
					</h5>
					<div className="d-flex ">
						<img src={icon1} alt="icon1" className="img" />
						{t('detailedInstructions.assessmentWizard')}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure32} alt="figure8.1" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8.1')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon2} alt="icon2" className="img" />
				{t('detailedInstructions.startRansomware')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure33} alt="figure8.2" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8.2')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon3} alt="icon3" className="img" />
				{t('detailedInstructions.detailsOrganization')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure34} alt="figure8.3" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8.3')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.firstAssessment')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure35} alt="figure8.4" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8.4')}
					</p>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure36} alt="figure8.5" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8.5')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.information')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure37} alt="figure8.6" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8.6')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon6} alt="icon6" className="img" />
				{t('detailedInstructions.end')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure38} alt="figure8.7" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8.7')}
					</p>
				</div>
			</div>
			<div className="d-flex mt-4">
				<span role="img" aria-label="Notice">
					❗
				</span>

				<h5 className="steps-title">{t('detailedInstructions.complete')}</h5>
			</div>
		</div>
	);
}

export default Step8;
