import { Mode } from "../../../constants/PackKeys";

const getReportName = (report) => {

	if (report.reportId && report.reportId.length > 0) return report.reportId;

	const
		d = new Date(),
		zeroPad = text => ('0' + text).slice(-2),
		datePart = `${d.getFullYear()}${zeroPad(d.getMonth() + 1)}${zeroPad(d.getDate())}`,
		timePart = `${zeroPad(d.getHours())}${zeroPad(d.getMinutes())}${zeroPad(d.getSeconds())}`;

	return `Report_${datePart}_${timePart}`;
}

const getReport = (location, params) => {

	const
		sp = new URLSearchParams(location.search),
		isAws = sp && sp.has("mode") && sp.get("mode") === "aws",
		pp = params,
		reportId = pp.name ?? getReportName({}),
		report = {
			mode: isAws ? Mode.automated : Mode.audit,
			reportId: reportId,
			ReportId: reportId, //for compatibility with getAwsResultUrl
			Fragments: sp.get("fragments"),
			EvalId: sp.get("evalId"),
			pack: pp.pack,
			auditor: params.auditor,
			reportUrl: `/reports/${pp.pack}/audit/${reportId}${location.search}`,
			exists: sp.has("evalId") && parseInt(sp.get("evalId") > 0)
		};

	return report;
}

const getAwsResultUrl = (report, packName, mode) => {
	//return `/tests/results/${packName}/${report.ReportId}?startId=${1}&fragments=${report.Fragments}&evalId=${report.EvalId}&mode=${mode}`;
	return `/topology?pack=${packName}&reportname=${report.ReportId}&evalid=${report.EvalId}&numfragments=${report.Fragments}&mode=${mode}`;
}


export { getReport, getReportName, getAwsResultUrl };