import React from 'react';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import icon6 from '../../../assets/img/6.svg';
import icon7 from '../../../assets/img/7.svg';
import icon8 from '../../../assets/img/8.svg';
import figure18 from '../../../assets/img/figure18.png';
import figure19 from '../../../assets/img/figure19.png';
import figure20 from '../../../assets/img/figure20.png';
import figure21 from '../../../assets/img/figure21.png';
import figure22 from '../../../assets/img/figure22.png';
import figure23 from '../../../assets/img/figure23.png';
import figure24 from '../../../assets/img/figure24.png';

function Step6() {
	const { t } = useTranslation();

	return (
		<div id="step6" className="instructions-steps">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 6</h5>
				<div>
					<h5 className="steps-title">
						{t('detailedInstructions.newAccessKey')}
					</h5>
					<div className="d-flex ">
						<img src={icon1} alt="icon1" className="img" />
						{t('detailedInstructions.navigationPane')}
					</div>
					<div className="d-flex ">
						<img src={icon2} alt="icon2" className="img" />
						{t('detailedInstructions.talaSecureUser')}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure18} alt="figure13" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure18')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon3} alt="icon3" className="img" />
				{t('detailedInstructions.userDetails')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure19} alt="figure19" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure19')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.securityCredentials')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure20} alt="figure20" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure20')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.accessKeysPanel')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure21} alt="figure21" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure21')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon6} alt="icon6" className="img" />
				{t('detailedInstructions.practicesAlternatives')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure22} alt="figure22" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure22')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon7} alt="icon7" className="img" />
				{t('detailedInstructions.keyOptional')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure23} alt="figure23" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure23')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon8} alt="icon8" className="img" />
				{t('detailedInstructions.keyOptional')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure24} alt="figure24" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure24')}
					</p>
				</div>
			</div>
			<div className="d-flex mt-2">
				<span role="img" aria-label="Notice">
					❗
				</span>

				<h5 className="steps-title">
					{t('detailedInstructions.theSecretAccess')}
				</h5>
			</div>
		</div>
	);
}

export default Step6;
