import React, { useEffect, useState } from 'react';

import { Progress } from 'reactstrap';
import { progressState } from '../../../../apiclients/AutomatedTests';
import { t } from 'i18next';

const state = {
	[progressState.none]: { total: 10, report: 10, topology: 0 },
	[progressState.auditStart]: { total: 25, report: 20, topology: 0 },
	[progressState.auditSaved]: { total: 30, report: 30, topology: 0 },
	[progressState.evalStart]: { total: 50, report: 40, topology: 0 },
	[progressState.evalSuccess]: { total: 55, report: 100, topology: 10 },
	[progressState.topologyStart]: { total: 75, report: 100, topology: 40 },
	[progressState.topologySuccess]: { total: 95, report: 100, topology: 95 },
	[progressState.auditError]: { total: 100, report: 30, topology: 0 },
	[progressState.evalError]: { total: 100, report: 0, topology: 0 },
	[progressState.topologyError]: { total: 100, report: 0, topology: 0 },
};

const colour = reportProgress => {
	const isError =
		reportProgress === progressState.evalError ||
		reportProgress === progressState.topologyError ||
		reportProgress === progressState.auditError;

	return isError ? 'danger' : 'primary';
};

export default function ({ isDraft, reportProgress }) {
	const [progress, setProgress] = useState(reportProgress);
	const [totalColour, setTotalColour] = useState(colour(reportProgress));

	useEffect(() => {
		const delay = 500;
		setTotalColour(colour(reportProgress));
		setTimeout(() => setProgress(state[reportProgress]), delay);
	}, [reportProgress, progress]);

	return (
		<>
			<div id="progress-container" className="w-50 m-3 me-5 ms-5">
				<div className="mt-2">
					<h4>{t('tests.savingReport')}</h4>
					<Progress
						color={totalColour}
						animated={progress.total !== 100}
						value={progress.total}
					/>
				</div>
				{!isDraft && (
					<>
						<div className="mt-2">
							<h5>{t('tests.testEvaluation')}</h5>
							<Progress
								color="info"
								animated={progress.report !== 100}
								value={progress.report}
							/>
						</div>
						<div className="mt-2">
							<h5>{t('tests.topologyEvaluation')}</h5>
							<Progress
								color="success"
								animated={progress.topology !== 100}
								value={progress.topology}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}
