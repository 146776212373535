import React, { useMemo, useState } from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useReactTable, getCoreRowModel, flexRender, getSortedRowModel, getFilteredRowModel } from '@tanstack/react-table';
import { Trans, useTranslation } from 'react-i18next';
import { convertToInternationalCurrencySystem } from '../../../Common/Formatter';
import ResourceDetails from '../../../Controls/ResourceDetails';
import { ReactComponent as PlusSquare } from '../../../../../assets/img/plus-square.svg';
import { ReactComponent as MinusSquare } from '../../../../../assets/img/minus-square.svg';

const colors = (severity) => {
	if (severity === 'Critical') {
		return 'danger';
	} else if (severity === 'High') {
		return 'warning';
	} else {
		return 'secondary';  // assuming 'Low' or undefined severity
	}
};

const Vulnerabilities = ({ data, isInline, onReportClick }) => {
	const { t } = useTranslation();
	const [expandedRows, setExpandedRows] = useState([]);
	

	const toggleRowExpansion = index => {
		setExpandedRows(rows => rows.includes(index) ? rows.filter(x => x !== index) : [...rows, index]);
	};

	const columns = useMemo(() => [
		{
			accessorKey: 'SecurityTestRule',
			header: () => <Trans i18nKey="vulnerabilities.identified" />,
			cell: info => (
				<div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
					<span className='my-34-step' onClick={() => toggleRowExpansion(info.row.id)} style={{ marginRight: '10px' }}>
						{expandedRows.includes(info.row.id) ? <MinusSquare /> : <PlusSquare />}
					</span>
					{'     '}
					{info.getValue() || null}
				</div>
			),
			headerStyle: { width: '40%', textAlign: 'justify' },
		},
		{
			accessorKey: 'VulnerabilityCount',
			header: () => <Trans i18nKey="vulnerabilities.count" />,
			cell: info => info.getValue() || null,
			headerStyle: { width: '30%', textAlign: 'left' },
		},
		{
			accessorKey: 'Severity',
			header: () => <Trans i18nKey="vulnerabilities.severity" />,
			cell: info => {
				const value = info.getValue();
				return value ? (
					<span className={`text-${colors(value)}`}>{t(value)}</span>
				) : null;
			},
			headerStyle: { width: '20%', textAlign: 'left' },
		},
		{
			accessorKey: 'PotentialLoss',
			header: () => <Trans i18nKey="vulnerabilities.loss" />,
			cell: info => {
				const value = info.getValue();
				return value ? `$${convertToInternationalCurrencySystem(value)}` : null;
			},
			headerStyle: { width: '20%', textAlign: 'left' },
		},
		{
			accessorKey: 'Risk Percentage',
			header: () => <Trans i18nKey="vulnerabilities.percentage" />,
			cell: info => {
				const value = info.getValue();
				return value ? `${value}%` : "-";
			},
			headerStyle: { width: '20%', textAlign: 'left' },
		},
	], [t, expandedRows, toggleRowExpansion]);

	const table = useReactTable({
		data: data.vulnerabilities || [],
		columns,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		initialState: {
			sorting: [
				{ id: 'PotentialLoss', desc: true }
			]
		},
	});



	

	return (
		<Card>
		
			<CardBody style={{ maxHeight: '40vh', overflowY: 'auto' }}>
				<Table>
					<thead>
						{table.getHeaderGroups().map(headerGroup => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map(column => (
									<th key={column.id} style={column.column.columnDef.headerStyle}>
										{flexRender(column.column.columnDef.header, column.getContext())}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row, index) => (
							<React.Fragment key={row.id}>
								<tr>
									{row.getVisibleCells().map(cell => (
										<td key={cell.id}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
								{expandedRows.includes(row.id) && (
									<tr className=''>
										<td colSpan={row.getVisibleCells().length}>
											<ResourceDetails issues={data.issues[row.original.SecurityTestRule]} onReportClick={onReportClick} />
										</td>
									</tr>
								)}
							</React.Fragment>
						))}
					</tbody>
				</Table>
			</CardBody>
		</Card>
	);
};

export default Vulnerabilities;
