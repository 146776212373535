import React from 'react';
import { useTranslation } from 'react-i18next';

const btnStyles = {
	border: '1px solid #141414',
	borderRadius: '4px',
	background: '#FFFFFF',
	width: '300px',
	height: '50px',
};

function PurchaseBtn({ purchaseLink = false }) {
	const { t } = useTranslation();
	const websiteLink = purchaseLink
		? purchaseLink
		: process.env.REACT_APP_PURCHASE_LINK;

	const onClick = () => {
		window.open(websiteLink, '_blank');
	};

	return (
		<div className="d-flex align-items-center justify-content-center mt-6">
			<button onClick={onClick} style={btnStyles}>
				{t('button.purchase')}
			</button>
		</div>
	);
}

export default PurchaseBtn;
