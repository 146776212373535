import React from 'react';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import icon6 from '../../../assets/img/6.svg';
import icon7 from '../../../assets/img/7.svg';
import icon8 from '../../../assets/img/8.svg';
import icon9 from '../../../assets/img/9.svg';
import figure25 from '../../../assets/img/figure7.1.png';
import figure26 from '../../../assets/img/figure7.2.png';

import figure27 from '../../../assets/img/figure7.3.png';
import figure28 from '../../../assets/img/figure7.4.png';
import figure29 from '../../../assets/img/figure7.5.png';
import figure30 from '../../../assets/img/figure7.6.png';
import figure31 from '../../../assets/img/figure7.7.png';

function Step7() {
	const { t } = useTranslation();
	return (
		<div id="step7" className="instructions-steps mt-4">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 7</h5>
				<div>
					<h5 className="steps-title">{t('detailedInstructions.signIn')}</h5>
					<div className="d-flex ">
						<img src={icon1} alt="icon1" className="img" />
						{t('detailedInstructions.go')}
						<a
							className="ms-1"
							target="_blank"
							rel="noopener noreferrer"
							href="/">
							{t('detailedInstructions.goLink')}
						</a>
					</div>
					<div className="d-flex ">
						<img src={icon2} alt="icon2" className="img" />
						{t('detailedInstructions.logIn')}{' '}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure25} alt="figure7.1" />
					<p className="text-center font-italic m-0 p-0">
						{t('detailedInstructions.figure7.1')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon3} alt="icon3" className="img" />
				{t('detailedInstructions.administrationPage')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure26} alt="figure7.2" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure7.2')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.aliasAccount')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure27} alt="figure7.3" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure7.3')}
					</p>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure28} alt="figure7.4" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure7.4')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.IDcorresponds')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure29} alt="figure7.5" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure7.5')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon6} alt="icon6" className="img" />
				{t('detailedInstructions.saveAccount')}
			</div>
			<div className="d-flex ">
				<img src={icon7} alt="icon7" className="img" />
				{t('detailedInstructions.useKey')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure30} alt="figure7.6" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure7.6')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon8} alt="icon8" className="img" />
				{t('detailedInstructions.setupProcess')}
			</div>
			<div className="d-flex ">
				<img src={icon9} alt="icon9" className="img" />
				{t('detailedInstructions.riskDashboard')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure31} alt="figure7.7" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure7.7')}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Step7;
