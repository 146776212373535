import {
	Card,
	CardBody,
} from "reactstrap";

import ProgressFilter from './ProgressFilter';
import React from "react";
import TestCases from "./TestCases";
import useAuditRefData from "../Common/useAuditRefData";
import useNavState from "../Common/useNavState";

export default function ({ report, reportId, auditor, data, onSave }) {
	const
		refData = useAuditRefData(report),
		navState = useNavState({ refData, data, onSave });

	return <>
		<Card>
			<CardBody className="bg-primary">
				<div className="row mb-2">
					<div className="col-sm-8">
						<h3 className="d-inline text-white">{reportId}</h3>
						<h3 className="d-inline text-white"> by {auditor}</h3>
					</div>
					<div className="col-sm-4">
						<div className="float-end">
							<ProgressFilter navState={navState} />
						</div>
					</div>
				</div>
			</CardBody>
		</Card>

		<TestCases refData={refData} navState={navState} data={data} />
	</>
}