import { Collapse, Nav, Navbar, UncontrolledAlert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { logout as logoutUser } from '../../services/AuthService';
import { toggleSidebar } from '../../redux/actions/sidebarActions';
import UserDropdown from './UserDropdown/UserDropdown';



const idleWarning = parseInt(process.env.REACT_APP_IDLE_WARNING_SECONDS);

let navigate;

const logout = () => {
	logoutUser(() => {
		navigate('/auth/sign-in');
	});
};

const NavbarToggle = connect(store => ({
	app: store.app,
}))(({ dispatch }) => {
	return (
		<span
			className="sidebar-toggle d-flex me-2"
			onClick={() => {
				dispatch(toggleSidebar());
			}}>
			<i className="hamburger align-self-center" />
		</span>
	);
});

const LogoutAlert = connect(store => ({
	idle: store.idle,
}))(({ idle }) => {
	let timerInterval;

	if (!navigate) {
		navigate = useNavigate();
	}

	const timeoutValue = idleWarning - 1,
		[timeLeftSeconds, setTimeLeftSeconds] = useState(timeoutValue),
		reset = () => {
			setTimeLeftSeconds(timeoutValue);
			clearInterval(timerInterval);
		},
		redirectUser = () => {
			console.log('reduceTimeLeft:', timeLeftSeconds);

			if (!idle.logout) {
				reset();
				return;
			}

			if (timeLeftSeconds <= 2) {
				console.log(
					'reduceTimeLeft: going to redirect',
					timeLeftSeconds,
					idle.redirect
				);
				clearInterval(timerInterval);
				logout();
			}
		},
		decrementTimeLeft = () => setTimeLeftSeconds(prev => prev - 1);

	useEffect(redirectUser, [timeLeftSeconds, idle.logout]);

	useEffect(() => {
		const startCountdown = () => {
			console.log('startCountdown:should clear timer');
			if (idle.logout) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				timerInterval = setInterval(decrementTimeLeft, 1000);
			} else {
				reset();
			}
		};

		startCountdown();

		return () => clearInterval(timerInterval);
	}, [idle.logout, timerInterval]);

	return (
		<>
			{idle.logout && (
				<UncontrolledAlert color="warning">
					<div className="alert-icon">
						<FontAwesomeIcon icon={faBell} fixedWidth />
					</div>
					<div className="alert-message">
						<strong>
							You will be logged out in {timeLeftSeconds} seconds.
						</strong>
					</div>
				</UncontrolledAlert>
			)}
		</>
	);
});

const NavbarComponent = ({ sidebar }) => {
	navigate = useNavigate();
	
	return (
		<Navbar expand className="navbar-theme">
			{!sidebar.isOnRight ? (
				<React.Fragment>
					<NavbarToggle />
					
					<Collapse navbar>
						<Nav className={!sidebar.isOnRight ? 'ms-auto' : 'me-auto'} navbar>
							<UserDropdown />
						</Nav>
					</Collapse>{' '}
					<LogoutAlert />
				</React.Fragment>
			) : (
				<React.Fragment>
					<Collapse navbar>
						<Nav className={!sidebar.isOnRight ? 'ms-auto' : 'me-auto'} navbar>
							<UserDropdown />
						</Nav>
					</Collapse>{' '}
				</React.Fragment>
			)}
		</Navbar>
	);
};

export default connect(store => ({
	sidebar: store.sidebar,
	idle: store.idle,
}))(NavbarComponent);
