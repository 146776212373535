import { Col, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import React from 'react';
import ReviewScanInput from '../../Components/ReviewScanInput';

const BringYourOwnDevice = ({ hook }) => {
	const { t } = useTranslation();
	return (
		<>
			<Row className="mt-4 ms-3">
				<h4>{t('reviewScan.bringYourOwnDevice')}</h4>
			</Row>
			<Row className="mt-4 ms-2">
				<Col xs="6">
					<Label>{t('reviewScan.byodPolicy')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="byod.enabled.yes"
							checked={hook.options.bringOwnDevice.enabled}
							value="true"
							onChange={() => hook.setInput('bringOwnDevice', 'enabled', true)}
							name="byod.enabled"></input>
						<label className="me-6" htmlFor="byod.enabled.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="byod.enabled.no"
							checked={!hook.options.bringOwnDevice.enabled}
							value="false"
							onChange={() => hook.setInput('bringOwnDevice', 'enabled', false)}
							name="byod.enabled"></input>
						<label htmlFor="byod.enabled.no">{t('button.no')}</label>
					</div>
				</Col>
				{hook.options.bringOwnDevice.endpointSecurity && (
					<Col xs="4">
						<div className="p-2 me-1">
							<ReviewScanInput
								labelfor="mdmSoftwareName"
								value={hook.options.bringOwnDevice.mdmSoftwareName}
								onChange={(n, v) => hook.setInput('bringOwnDevice', n, v)}>
								{t('reviewScan.softwareName')}
							</ReviewScanInput>
						</div>
					</Col>
				)}
			</Row>
			{hook.options.bringOwnDevice.enabled && (
				<>
					<Row className="mt-4 ms-2">
						<Col xs="6">
							<Label>{t('reviewScan.implementEndpointSecurity')}</Label>
							<div className="mb-3">
								<input
									className="pl-5 me-4"
									type="radio"
									id="byod.endpoint.yes"
									checked={hook.options.bringOwnDevice.endpointSecurity}
									value="true"
									onChange={() =>
										hook.setInput('bringOwnDevice', 'endpointSecurity', true)
									}
									name="byod.endpoint"></input>
								<label className="me-6" htmlFor="byod.endpoint.yes">
									{t('button.yes')}
								</label>
								<input
									className="pl-5 me-4"
									type="radio"
									id="byod.endpoint.no"
									checked={!hook.options.bringOwnDevice.endpointSecurity}
									value="false"
									onChange={() =>
										hook.setInput('bringOwnDevice', 'endpointSecurity', false)
									}
									name="byod.endpoint"></input>
								<label htmlFor="byod.endpoint.no">{t('button.no')}</label>
							</div>
						</Col>
						{hook.options.bringOwnDevice.endpointSecurity && (
							<Col xs="4">
								<div className="p-2 me-1">
									<ReviewScanInput
										labelfor="endPointLicenseARN"
										value={hook.options.bringOwnDevice.endPointLicenseARN}
										onChange={(n, v) => hook.setInput('bringOwnDevice', n, v)}>
										{t('reviewScan.licenseArn')}
									</ReviewScanInput>
								</div>
							</Col>
						)}
					</Row>
					<Row className="mt-4 ms-2">
						<Col>
							<Label>{t('reviewScan.mobileDeviceManagement')}</Label>
							<div className="mb-3">
								<input
									className="pl-5 me-4"
									type="radio"
									id="byod.mdm.yes"
									checked={hook.options.bringOwnDevice.mobileDatamanagement}
									value="true"
									onChange={() =>
										hook.setInput(
											'bringOwnDevice',
											'mobileDatamanagement',
											true
										)
									}
									name="byod.mdm"></input>
								<label className="me-6" htmlFor="byod.mdm.yes">
									{t('button.yes')}
								</label>
								<input
									className="pl-5 me-4"
									type="radio"
									id="byod.mdm.no"
									checked={!hook.options.bringOwnDevice.mobileDatamanagement}
									value="false"
									onChange={() =>
										hook.setInput(
											'bringOwnDevice',
											'mobileDatamanagement',
											false
										)
									}
									name="byod.mdm"></input>
								<label htmlFor="byod.mdm.no">{t('button.no')}</label>
							</div>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default BringYourOwnDevice;
