import React from 'react';
import { Trans } from 'react-i18next';

const key = 'testReports.testMetaInfo.fedRamp.';

export default [
	{
		title: <Trans i18nKey={key + 'document.title'} />,
		titleBackground: '#66d9ff',
		text: [<Trans i18nKey={key + 'document.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'assess.title'} />,
		titleBackground: ' #00ace6',
		text: [<Trans i18nKey={key + 'assess.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'authorize.title'} />,
		titleBackground: '#ff6633',
		text: [<Trans i18nKey={key + 'authorize.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'monitor.title'} />,
		titleBackground: '#ff8000',
		text: [<Trans i18nKey={key + 'monitor.text'} />],
	},
];
