import { useNavigate } from 'react-router-dom';
import { loginAws, loginK8s, loginOnprem } from '../../../services/AuthService';

const useLoginWithKey = () => {
	const navigate = useNavigate();

	const deploy = () => {
		navigate('/setup');
	};

	const login = async (
		credentials,
		onError = () => {},
		redirect = true,
		onSuccess = () => {}
	) => {
		const data = {
			token: credentials.token,
			keyid: credentials.keyId,
			domain: credentials.domain,
			Region: credentials.region,
			Regions: credentials.regions,
		};
		var loginProvider = null;
		if ('aws' === credentials.provider) {
			loginProvider = loginAws;
		} else if ('onprem' === credentials.provider) {
			loginProvider = loginOnprem;
		} else if ('k8s' === credentials.provider) {
			loginProvider = loginK8s;
		}
		if (!loginProvider) {
			console.log('No loginProvider in credentials, assuming AWS');
			loginProvider = loginAws;
		}

		if (redirect) {
			loginProvider(
				data,
				res => {
					navigate('/ransomware/dashboard');
					onSuccess(res);
				},
				onError,
				deploy
			);
		} else {
			loginProvider(data, onSuccess, onError, () => {});
		}
	};

	return login;
};

export default useLoginWithKey;
