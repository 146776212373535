const EventType = {
	TestCaseChanged: 0,
	ChildTestCaseChanged: 1,
	CategoryChanged: 2,
	SubCategoryChanged: 3
}

const EventTypeToProp = {
	[EventType.TestCaseChanged]: 'testCaseId',
	[EventType.ChildTestCaseChanged]: 'childTestCaseId',
	[EventType.CategoryChanged]: 'categoryId',
	[EventType.SubCategoryChanged]: 'subCategoryId'
}

export { EventType, EventTypeToProp };