import React from 'react';
import { Tooltip } from 'react-joyride';
import { X, ArrowLeft, ArrowRight } from 'react-feather';
const CustomTooltip = ({
    continuous,
    index,
    isLastStep,
    size,
    step,
    backProps = {},
    closeProps = {},
    primaryProps = {},
    tooltipProps = {},
    skipProps = {},
    locale = { back: 'Back', next: 'Next', last: 'Last' },
}) => (
    <div {...tooltipProps} className="custom-tooltip tooltipWrap">

        <button {...skipProps} className="custom-tooltip__button custom-tooltip__button--skip tooltipSkip">
            <X />
        </button>
        <div className="custom-tooltip__content">
            {step.title && <h3 className="custom-tooltip__title">{step.title}</h3>}
            <div className="custom-tooltip__body">{step.content}</div>
        </div>
        <div className="custom-tooltip__footer">
            <button {...backProps} className="custom-tooltip__button custom-tooltip__button--back">
                <ArrowLeft className='arrowBntLeft' />
                {locale.back}
            </button>
            <button {...primaryProps} className="custom-tooltip__button custom-tooltip__button--primary">
                {continuous ? <>{locale.next}<ArrowRight className='arrowBntLeft' /> </> : locale.last}
            </button>
        </div>
    </div>
);

export default CustomTooltip;
