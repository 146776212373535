import { Button } from 'reactstrap';
import React from 'react';
import { t } from 'i18next';

export default function ({ readOnly, loading, onClick }) {
	return (
		<Button
			color="primary"
			size="xs"
			className="ms-3"
			onClick={onClick}
			disabled={readOnly}>
			{/* {loading &&
				<div role="status" className="spinner-border spinner-border-sm mb-1">
					<span className="sr-only"></span>
				</div>
			} */}
			<span className="ms-1">{t('button.scan')}</span>
		</Button>
	);
}
