import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as GoogleSvg } from '../../assets/img/google.svg';
import VerifyResult from './VerifyResult';

import { Alert, Form, FormFeedback, FormGroup } from 'reactstrap';
import Input from '../../components/Form/Input';
import {
	getNewauthLandingUri,
	requestDemoLink,
} from '../../apiclients/EmailLogin';
import PurchaseBtn from './PurchaseBtn';

const validators = {
	email: /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/,
};

const INITIAL_VALUES = {
	email: '',
	agreeTerms: false,
	agreeMarketing: false,
};

const INITIAL_ERRORS = {
	email: false,
};

function LiveDemo() {
	const { t } = useTranslation();
	const [values, setValues] = useState(INITIAL_VALUES);
	const [errors, setErrors] = useState(INITIAL_ERRORS);
	const [requestSent, setRequestSent] = useState(false);
	const [requestFailed, setRequestFailed] = useState(false);

	const handleAgreeTermsChange = () => {
		setValues(prev => ({ ...prev, agreeTerms: !prev.agreeTerms }));
	};

	const handleAgreeMarketingChange = () => {
		setValues(prev => ({
			...prev,
			agreeMarketing: !prev.agreeMarketing,
		}));
	};

	const onChange = e => {
		e.persist();
		if (!e?.target) return;
		const value = e.target?.value;
		const name = e.target?.name;
		setValues(prev => ({ ...prev, [name]: value }));
	};

	const validateField = useCallback((name, value) => {
		const isNotEmpty = typeof value === 'string' && value.trim() !== '';

		const validator = validators[name];
		const isValid = validator ? validator.test(value) : false;

		return isNotEmpty && isValid;
	}, []);

	// const validateForm = useCallback(
	// 	() => Object.keys(values).every(key => validateField(key, values[key])),
	// 	[values, validateField]
	// );
	const validateForm = useCallback(() => {
		return (
			validateField('email', values.email) &&
			values.agreeTerms &&
			values.agreeMarketing
		);
	}, [values, validateField]);

	const isValidForm = useMemo(
		() => validateForm(),
		[validateForm, values, values.agreeTerms, values.agreeMarketing]
	);

	const onBlur = e => {
		e.persist();

		const isValid = validateField(e.target.name, e.target.value);
		setErrors(prev => ({ ...prev, [e.target.name]: !isValid }));
	};

	const success = () => {
		console.log('request succeeded');
		setRequestSent(true);
		setRequestFailed(false);
		setValues(INITIAL_VALUES);
		setErrors(INITIAL_ERRORS);
	};

	const failure = () => {
		console.log('request failed');
		setRequestSent(false);
		setRequestFailed(true);
		setValues(INITIAL_VALUES);
		setErrors(INITIAL_ERRORS);
	};

	const submitForm = async e => {
		e.preventDefault();

		const isValidForm = validateForm();

		if (!isValidForm) {
			return;
		}

		await requestDemoLink(
			{
				email: values.email,
				agreeTerms: values.agreeTerms,
				agreeMarketing: values.agreeMarketing,
			},
			success,
			failure
		);
	};
	return (
		<div className="sign-in">
			<div className="text-center mt-4">
				<h2 className="sign-in__title">{t('liveDemo.title')}</h2>
				<p className="w-75 mx-auto sign-in__subtitle">
					{t('liveDemo.description')}
				</p>
			</div>

			<Form onSubmit={submitForm} className="form mx-auto reset-form">
				<VerifyResult
					color="#59A919"
					isOpen={requestSent}
					title={t('form.demoRequestSent')}
					subTitle={t('form.checkEmail')}
				/>
				<VerifyResult
					color="#E72538"
					isOpen={requestFailed}
					title={t('form.demoRequestFailed')}
					subTitle={t('form.tryAgain')}
				/>
				<FormGroup>
					<Input
						type="email"
						name="email"
						placeholder={t('form.email')}
						onChange={onChange}
						value={values.email}
						valid={!errors.email && !!values.email}
						invalid={errors.email}
						onBlur={onBlur}
					/>
					{errors.email && (
						<FormFeedback className="auth-feedback">
							{t('form.emailRequired')}
						</FormFeedback>
					)}
				</FormGroup>

				<FormGroup check>
					<label className="form-check-label">
						<input
							type="checkbox"
							name="agreeTerms"
							checked={values.agreeTerms}
							onChange={handleAgreeTermsChange}
						/>{' '}
						{t('form.termsLink')}{' '}
						<Link to="/terms">{t('form.termsLinkAdd')}</Link>
					</label>
				</FormGroup>
				<FormGroup check>
					<label className="form-check-label">
						<input
							type="checkbox"
							name="agreeMarketing"
							checked={values.agreeMarketing}
							onChange={handleAgreeMarketingChange}
						/>{' '}
						{t('form.agreeMarketing')}
					</label>
				</FormGroup>
				<div className="text-center mt-4">
					<input
						type="submit"
						disabled={!isValidForm}
						className="btn btn-primary btn-lg auth-button"
						value={t('button.demoLink')}
					/>
				</div>
				<div class="divider">
  <span class="divider-text">{t('button.or')}</span>
</div>
				{/* <p className="my-3 mx-auto text-center">{t('button.or')}</p> */}
				<div className="mx-auto">
					<a
						className={`google-button ${
							!values.agreeTerms || !values.agreeMarketing
								? 'google-disabled'
								: ''
						}`}
						href={getNewauthLandingUri('Google', {
							demo: true,
							agreeMarketing: values.agreeMarketing,
							agreeTerms: values.agreeTerms,
						})}>
						<GoogleSvg /> <span>{t('signIn.google')}</span>
					</a>
				</div>
				<div className="d-flex justify-content-between mt-3 text-center">
					<Link to="/auth/sign-in">{t('button.signIn')}</Link>
					<Link to="/auth/free-trial/sign-up">{t('button.trialTala')}</Link>
				</div>
				<PurchaseBtn />
			</Form>
		</div>
	);
}

export default LiveDemo;
