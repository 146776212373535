import { Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRemediationData, getResults } from './ResultService';

import AutomatedTestsHeader from './Header';
import ComplianceChart from './ComplianceCharts';
import ComplianceTopology from './ComplianceTopology';
import Loader from '../../../components/Loader';

const getReport = location => {
		const sp = new URLSearchParams(location);

		return {
			PackName: sp.get('pack'),
			ReportId: sp.get('reportId'),
			Fragments: sp.get('fragments'),
			FragmentId: sp.get('startId'),
			EvalId: sp.get('evalId'),
			Mode: sp.get('mode'),
		};
	},
	AutomatedTests = () => {
		const location = useLocation();
		const report = getReport(location),
			[data, setData] = useState({
				loaded: false,
				counts: {},
				rows: [],
				remediation: {},
			});

		useEffect(() => {
			document.title = `${report.PackName} - ${report.ReportId}`;
			const fetchResult = async () => {
				const results = await getResults(report);
				const data = await getRemediationData();
				setData({
					loaded: true,
					counts: results.counts,
					rows: results.rows,
					remediation: data,
				});
			};
			fetchResult();
		}, [report]);

		return (
			<Container fluid>
				<AutomatedTestsHeader report={report} />
				{!data.loaded && <Loader />}
				{data.loaded && (
					<>
						<Row className="h-50">
							<ComplianceChart data={data.counts} name={report.PackName} />
							<ComplianceTopology report={report} name={report.PackName} />
						</Row>
						{/* <Row className="h-50">
							{
								<AutomatedTestResults
									rows={data.rows}
									reviewUrl={reviewUrl}
									report={report}
									remediation={data.remediation}
								/>
							}
						</Row> */}
					</>
				)}
			</Container>
		);
	};

export default AutomatedTests;
