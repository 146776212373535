import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Trans } from 'react-i18next';
import useClickOutside from '../../../hooks/useClickOutside';

const TicketDropdown = ({ options }) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef();

	useClickOutside(ref, () => setIsOpen(false));

	return (
		<div ref={ref} className="ticket-dropdown">
			<button
				className={classNames('ticket-dropdown__trigger', {
					'ticket-dropdown__trigger_opened': isOpen,
				})}
				onClick={() => setIsOpen(p => !p)}>
				<p>Ticket #</p>
				{/* <ChevronDown /> */}
			</button>
			{isOpen && (
				<div className="ticket-dropdown__menu">
					<table className="ticket-dropdown__table">
						<thead>
							<th>
								<Trans i18nKey="talasmart.resource.ticket" />
							</th>
							<th>
								<Trans i18nKey="talasmart.resource.description" />
							</th>
						</thead>
						<tbody>
							{options.map(item => (
								<tr onClick={() => setIsOpen(false)}>
									<td>
										<Trans i18nKey="talasmart.resource.ticket" /> {item.id}
									</td>
									<td>{item.description}</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default TicketDropdown;
