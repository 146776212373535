/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	setIsAuthenticated,
	setUser,
} from '../../../redux/actions/applicationActions';

import {
	deleteKey,
	getUserDefaults,
	getUserKeyList,
} from '../../../apiclients/EmailLogin';

import './styles.css';

import {
	CloudKeysTypes,
	CloudTypes,
} from '../../auth/MultiCloudSignIn/Constants';
import { Plus, Trash } from 'react-feather';
import Button from '../../../components/Form/Button';
import SearchInput from '../../../components/Form/SearchInput';
import CloudTabs from './components/CloudTabs';
import KeyTable from './components/KeyTable';
import Arrow from '../../../assets/img/pseudoArrowRight.svg';
import ArrowBack from '../../../assets/img/ArrowBack.svg';
import { getCurrentUserKey, getUserToken } from '../../../services/AuthService';
import { getItem } from '../../../services/browserStorageService';
import useLoginWithKey from '../../auth/hooks/useLoginWithKey';
import { useTranslation } from 'react-i18next';
import { setUserToken } from '../../../services/AuthService';
import Loader from '../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import QuickStartPanel from './components/QuickStartPanel';
import AddAccount from './components/AddAccount';
import { setAccountLimits } from '../../../services/AuthService';
import { isDemoAccount } from '../../../services/accountTypeService';
import DemoPopup from '../../../components/DemoPopup';

const getToken = () => {
	const userKey = getCurrentUserKey();
	const user = getItem(userKey);
	const token = getUserToken();

	if (user) {
		return user?.authHeaders?.token || null;
	} else {
		return token || null;
	}
};

const RemoteAuth = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [cloudSystem, setCloudSystem] = useState('aws');
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const queryToken = queryParams.get('token');
	const failedKeyId = queryParams.get('failedKeyId');
	const ssoLogin = queryParams.get('sso');
	const { t } = useTranslation();
	const [dataLoaded, setDataLoaded] = useState(false);

	const [showContent, setShowContent] = useState(false);
	const showUserTypeSelect = process.env.REACT_APP_FEAT_SHOW_USERTYPE === '1';

	const cloudTypes = useMemo(
		() => Object.keys(CloudTypes).map(key => CloudTypes[key]),
		[]
	);

	const [cloudType, setCloudType] = useState(cloudTypes[0]);
	const [showQuickStartPanel, setShowQuickStartPanel] = useState(false);
	const [userKeys, setUserKeys] = useState({});
	const [demoPopup, setDemoPopup] = useState(false);
	const [isDeleteClicked, setIsDeleteClicked] = useState(false);
	const [token, setToken] = useState('');
	const [keyDomain, setKeyDomain] = useState('');
	const [search, setSearch] = useState('');
	const [addAccount, setAddAccount] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const [defaultKey, setDefaultKey] = useState(null);
	const tableData = useMemo(() => {
		if (!userKeys[keyDomain]) return [];
		return userKeys[keyDomain].filter(
			item => item.provider === CloudKeysTypes[cloudType - 1]
		);
	}, [keyDomain, userKeys, cloudType]);
	const loginWithKey = useLoginWithKey();

	const showDemoPopup = () => {
		setDemoPopup(true);
	};

	const keyListError = () => {
		// TODO log this error
		console.log('keyListError');
		// redirect to login
		navigate('/');
	};

	const showKeyList = response => {
		dispatch(setIsAuthenticated(true));
		if (response.domains) setKeyDomain(Object.keys(response.domains)[0]);
		setUserKeys(response.domainKeys);
	};

	const onAddAccountSuccess = newKey => {
		var newKeyList = { ...userKeys };

		if (!newKeyList[keyDomain]) {
			newKeyList[keyDomain] = [];
		}
		newKeyList[keyDomain].push(newKey);

		setUserKeys(newKeyList);
		setAddAccount(false);
	};

	const onGetUserKey = useCallback(
		token =>
			getUserKeyList(
				token,
				response => {
					showKeyList(response);
					setDataLoaded(true);
				},
				keyListError
			),
		[]
	);

	const gotDefaultKey = res => {
		if (res && res.accountInfo) {
			setAccountLimits(res.accountInfo);
		}
		if (res && res.userInfo && res.userInfo.defaultKey) {
			setDefaultKey(res.userInfo.defaultKey);
		} else {
			dispatch(setUser('no-key'));
		}

		// If the user logged in with SSO ("sso=1") and has a default key set, use it.
		if (
			queryToken &&
			res.userInfo?.defaultKey &&
			res.userInfo?.defaultKey?.domain &&
			res.userInfo?.defaultKey?.region &&
			res.userInfo?.defaultKey?.keyId &&
			ssoLogin &&
			!failedKeyId
		) {
			const info = {
				token: queryToken,
				keyId: res.userInfo.defaultKey.keyId,
				domain: res.userInfo.defaultKey.domain,
				region: res.userInfo.defaultKey.region,
				regions: [],
			};
			dispatch(setIsAuthenticated(true));
			loginWithKey(info, () => {}, true);
			dispatch(setUser(queryToken));
		} else {
			setShowContent(true);
		}
	};

	useEffect(() => {
		if (failedKeyId) {
			console.log('Failed key:', failedKeyId);
			// TODO show an error message to the user
		}
		const token = queryToken || getToken();

		onGetUserKey(token);
		setToken(token);
		setUserToken(token);
		getUserDefaults(token, gotDefaultKey, () => setShowContent(true));
	}, [queryToken]);

	const filteredData = useMemo(() => {
		if (!tableData?.length) return [];
		if (!search) return tableData;

		return tableData.filter(item =>
			item.name?.toLowerCase()?.includes(search?.toLowerCase())
		);
	}, [search, tableData]);

	const onClick = value => {
		let newList = [];
		if (selectedItems.some(id => id === value.id)) {
			newList = [...selectedItems].filter(id => id !== value.id);
		} else {
			newList = [...selectedItems, value.id];
		}
		setSelectedItems([...new Set(newList)]);
	};

	const onDeleteKey = () => {
		deleteKey(
			selectedItems,
			token,
			res => {
				onGetUserKey(token);
			},
			console.log
		);
	};

	if (!showContent) {
		return <Loader />;
	}

	const handleClick = () => {
		setShowQuickStartPanel(!showQuickStartPanel);
	};

	const onDeleteAccount = () => {
		setIsDeleteClicked(true);
		isDemoAccount() ? showDemoPopup() : onDeleteKey();
	};

	const onAddAccount = () => {
		setIsDeleteClicked(false);
		isDemoAccount() ? showDemoPopup() : setAddAccount(true);
	};

	return (
		<section className="container-fluid mb-4">
			<DemoPopup
				isOpen={demoPopup}
				setDemoPopup={setDemoPopup}
				title={
					isDeleteClicked ? t('liveDemo.deleteAws') : t('liveDemo.addAccont')
				}
			/>
			<div className="mb-4">
				<div className="d-flex d-flex justify-content-between">
					<div className="d-flex justify-content-between w-100 flex-wrap">
						<div className="d-flex">
							<h1 className="mb-1">{t('adminPanel.administration')}</h1>
							<div
								className={
									showQuickStartPanel ? 'clickedBtn' : 'quickStartButton'
								}
								onClick={handleClick}>
								<p
									className={
										showQuickStartPanel ? 'clickedText' : 'quickStartText'
									}>
									{t('adminPanel.quickStartGuide')}
								</p>
								<FontAwesomeIcon
									icon={faChevronDown}
									color={showQuickStartPanel ? 'white' : '#1E69E0'}
									className={showQuickStartPanel ? 'rotate' : ''}
								/>
							</div>
						</div>

						{addAccount && (
							<div
								onClick={() => setAddAccount(false)}
								className="cursor-pointer">
								<p className="back-btn">
									<img src={ArrowBack} className="me-2" />
									{t('adminPanel.back')}
								</p>
							</div>
						)}
					</div>
					{showUserTypeSelect && (
						<div>
							<input
								className="m-3"
								type="radio"
								id="express"
								value="0"
								name="mode"></input>
							<label className="font-weight-bold" htmlFor="express">
								{t('adminPanel.executive')}
							</label>
							<input
								className="m-3 "
								type="radio"
								id="express"
								value="0"
								name="mode"></input>
							<label className="font-weight-bold" htmlFor="express">
								{t('adminPanel.manager')}
							</label>
							<input
								className="m-3"
								type="radio"
								id="express"
								value="0"
								name="mode"></input>
							<label className="font-weight-bold" htmlFor="express">
								{t('adminPanel.engineer')}
							</label>
						</div>
					)}
				</div>
				<div className="d-flex">
					<p
						onClick={() => setAddAccount(false)}
						className={
							'admin-subtitle' + (addAccount ? ' cursor-pointer' : '')
						}>
						{t('adminPanel.accounts')}
					</p>
					{addAccount && (
						<p className="admin-subtitle" style={{ color: 'black' }}>
							<img src={Arrow} alt="arrow" className="m-2" />
							{t('adminPanel.addAccount')}
						</p>
					)}
				</div>
			</div>
			{showQuickStartPanel && (
				<QuickStartPanel
					cloudType={CloudKeysTypes[cloudType - 1]}
					cloudSystem={cloudSystem}
					close={setShowQuickStartPanel}
					addAccount={addAccount}
				/>
			)}
			{addAccount ? (
				<AddAccount
					cloudSystem={cloudSystem}
					setCloudSystem={setCloudSystem}
					data={{
						setDefaultKey,
						token,
						keyDomain,
						onSuccess: onAddAccountSuccess,
					}}
				/>
			) : (
				<>
					<CloudTabs
						onTabChange={setCloudType}
						activeTab={cloudType}
						options={cloudTypes}
					/>
					<div className="d-flex justify-content-between mb-5">
						<SearchInput
							placeholder={t('adminPanel.searchUser')}
							value={search}
							onChange={e => setSearch(e.target.value)}
						/>
						<div className="d-flex" style={{ gap: '30px' }}>
							<Button onClick={onDeleteAccount} variant="outlined">
								<div className="admin-button">
									<Trash />
									{t('button.delete')}
								</div>
							</Button>
							<Button onClick={onAddAccount} variant="outlined">
								<div className="admin-button">
									<Plus />
									{t('button.addAccount')}{' '}
								</div>
							</Button>
						</div>
					</div>
					<KeyTable
						info={{ defaultKey, setDefaultKey }}
						token={token}
						data={filteredData || []}
						onClick={onClick}
					/>

					{dataLoaded && filteredData.length === 0 && (
						<div className="d-flex align-items-center justify-content-center mt-5">
							<p className="admin-panel-warning">
								<span role="img" aria-label="Notice">
									❗
								</span>
								{t('adminPanel.pleaseAddAccount')}
							</p>
						</div>
					)}
				</>
			)}
		</section>
	);
};

export default RemoteAuth;
