import {
	evaluate,
	getEvaluationHistory,
	getIssueList,
	results,
} from '../../../apiclients/Ransomware';
import { getItem, setItem } from '../../../services/browserStorageService';
import { useEffect, useState } from 'react';

import { getReportName } from './Helpers';
import { useNavigate } from 'react-router-dom';

let navigate;

const vulnerabilityMapFn = (value, index) => ({
	id: index,
	...value,
	fixed: 0,
	notFixed: 0,
});

const useAutoRemediation = () => {
	navigate = useNavigate();
	const [report, setReport] = useState([]);
	const [reportId, setReportId] = useState([]);
	const [loading, setLoading] = useState(true);
	const [noData, setNoData] = useState(true);
	const [issues, setIssues] = useState([]);
	const [evaluating, setEvaluating] = useState(false);
	const [vulnerabilitySelected, onVulnerabilitySelected] = useState(false);

	const onRedirectClick = () => {
		if (!getItem('remediation_issues') && !vulnerabilitySelected) {
			return false;
		}

		return true;
	};

	const onRemediate = async () => {
		setEvaluating(true);

		const reportName = getReportName();
		const result = await evaluate(reportName, '');
		if (result.success) {
			console.log('eval completed', result);
			setItem(`ransomware_${reportName}`, result.data);
			setTimeout(navigate('/ransomware/assessment/ReviewScore'), 500);
		} else {
			console.error(
				'an error occurred when re-running the ransomware evaluation'
			);
		}
		setEvaluating(false);
	};

	const onFix = item => {
		if (report.noData) {
			return;
		}

		const index = report.findIndex(row => row.id === item.id);

		report[index].fixed = item.fixed;
		report[index].notFixed = item.notFixed;

		setReport(report);
	};

	useEffect(() => {
		const fetchIssues = async reportId => {
			const response = await getIssueList(reportId);
			if (!response.success) {
				return;
			}

			setIssues(response.data);
		};

		const fetchReport = async reportId => {
			const report = await results(reportId);
			if (!report.success) {
				setNoData(true);
				setLoading(false);
				return;
			}

			const reportData = report.data.Vulnerabilities.map(vulnerabilityMapFn);
			setNoData(false);
			setReport(reportData);
		};

		const fetchHistory = async () => {
			const history = await getEvaluationHistory();

			if (history.success && history.data && history.data.length > 0) {
				const reportId = history.data[0].ReportId;
				await fetchReport(reportId);
				await fetchIssues(reportId);
				setReportId(reportId);
			} else {
				setNoData(true);
			}
			setLoading(false);
		};

		fetchHistory();
	}, []);

	return {
		report,
		reportId,
		loading,
		noData,
		issues,
		evaluating,
		vulnerabilitySelected,
		onFix,
		onRemediate,
		onRedirectClick,
		onVulnerabilitySelected,
	};
};

export default useAutoRemediation;
