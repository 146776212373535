import {
	Badge
} from "reactstrap";
import React from "react";

const colours = {
	"Critical": "danger",
	"High": "danger",
	"Medium": "warning",
	"Low": "success"
}

const SeverityBadge = ({ severity }) => <Badge color={colours[severity]}>{severity}</Badge>

export default SeverityBadge;