import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import React, { useState } from 'react';

import Header from '../Controls/PageHeader';
import History from './Controls/History';
import ReportId from './Controls/ReportId';
import ScanName from './Controls/ScanName';
import StartScanButton from './Controls/StartScanButton';
import { scan } from '../../../../apiclients/PenTesting';
import { t } from 'i18next';

const scanErrors = {
	1: t('tests.success'),
	2: t('tests.reportUUIDMissing'),
	3: t('tests.unknownReportName'),
	4: t('tests.multipleReports'),
	5: t('tests.noStorageObject'),
	6: t('tests.notValidUrl'),
	7: t('tests.notValidReportName'),
	8: t('tests.authenticationFailure'),
	9: t('tests.couldNotScan'),
	10: t('tests.noReportInfo'),
	11: t('tests.noDatabaseConfig'),
};

export default function () {
	const [readOnly, setReadOnly] = useState(false);
	const [loading, setLoading] = useState(false);
	const [scanUrl, setScanUrl] = useState('');
	const [refresh, setRefresh] = useState(false);
	const [scanError, setScanError] = useState(false);
	const [errorCode, setErrorCode] = useState('');
	const [reportId, setReportId] = useState('');

	const disableControls = value => {
		setLoading(value);
		setReadOnly(value);
	};

	const onUrlChanged = (url, valid) => {
		setReadOnly(!valid);
		if (valid) setScanUrl(url);
	};

	const onReportIdChanged = value => setReportId(value);

	const onClick = async () => {
		setScanError(false);
		disableControls(true);
		setRefresh(false);

		const response = await scan(scanUrl, reportId);
		if (response.error) {
			console.error('An error occurred', response, scanErrors[response.code]);
			setErrorCode(response.code);
			setScanError(true);
		} else {
			setRefresh(true);
		}

		disableControls(false);
	};

	const feedbackStyle = {
		width: '100%',
		marginTop: '0.25rem',
		fontweight: 'bold',
		fontSize: '80%',
		color: '#dc3545',
	};

	return (
		<Container fluid>
			<Header title={t('tests.runOWASP')} />

			<Card>
				<CardBody>
					<Row>
						<Col>
							<ReportId
								onChange={onReportIdChanged}
								readOnly={readOnly}
								loading={loading}
							/>
							<ScanName onUrlChanged={onUrlChanged} loading={loading} />
							<StartScanButton
								readOnly={readOnly}
								loading={loading}
								onClick={onClick}
							/>
							{scanError && (
								<div className="form-group">
									<div className="ms-3" style={feedbackStyle}>
										{scanErrors[errorCode]}
									</div>
								</div>
							)}
						</Col>
					</Row>
				</CardBody>
			</Card>

			<History refresh={refresh} />
		</Container>
	);
}
