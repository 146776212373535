import React from 'react';
import { withIdleTimer } from 'react-idle-timer';

class IdleTimerComponent extends React.Component {
	render() {
		return this.props.children || null;
	}
}

export const IdleTimer = withIdleTimer(IdleTimerComponent);
