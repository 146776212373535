import React from "react";

const AlertItem = ({ reason }) => {

  return (
    <div className="w-100 bg-danger text-white my-1">
      <strong className="mx-2">{reason}</strong>
    </div>
  );
}

export default function ({ reasons }) {
  return <>
    {reasons.map((item, index) => <AlertItem reason={item} key={index} />)}
  </>
}