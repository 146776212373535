import { t } from 'i18next';
import React from 'react';

export default function () {
	return (
		<div>
			<div>
				<h4>{t('testReports.hipaa')}</h4>
				<p className="font-weight-normal">
					{t('testReports.hipaaDescription')}
				</p>
				<p className="font-weight-normal">
					{t('testReports.hipaaSecurityRuleTitle')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.techicalSafeguardsTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.techicalSafeguardsDescription')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.physicalSafeguardsTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.physicalSafeguardsDescription')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.administrativeSafeguardsTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.administrativeSafeguardsDescription1')}
				</p>
				<p className="font-weight-normal">
					{t('testReports.administrativeSafeguardsDescription2')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.breachNotificationTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.breachNotificationDescription1')}
				</p>
				<p className="font-weight-normal">
					{t('testReports.breachNotificationDescription2')}
				</p>
			</div>
		</div>
	);
}
