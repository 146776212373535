import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import { Colours } from '../Common/Constants';
import Error from './Error';
import LoadingSpinner from './LoadingSpinner';
import React from 'react';
import { t } from 'i18next';
import { ChevronRight } from 'react-feather';

const CountText = ({ color, text, count }) => (
	<div className="d-flex align-items-center gap-1">
	  <span className="issue-title align-middle mb-2">{text}</span>
	  <h3 style={{ color }} className="d-inline mb-2 align-middle">
		{count}
	  </h3>
	</div>
  );

const ComplianceCounts = ({
	loading,
	error,
	info = false,
	// key,
	data,
	packName,
	packKey,
	onInfoClick = () => {},
}) => {
	const handleInfoClick = () => {
		// Викликаємо функцію onInfoClick з параметром 'Critical', якщо вона передана
		if (onInfoClick) {
			onInfoClick('Critical');
		}
	};


	return (
		<Card className="secureDashboardCard">
			<CardHeader>
				<CardTitle className="CardTitleWrap">
					<h4 className="TitleCard">{packName}</h4>
					<button onClick={handleInfoClick} className="BntInfo my-3-step">
						More info <ChevronRight />
					</button>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{error && <Error text={t('complianceDashboard.trendError')} />}
				{!error && loading && <LoadingSpinner />}
				{/*undo the height after you're done for the demo*/}
				{!error && !loading && (
					<div>
						<CountText
							color={Colours[packKey]}
							text={t('complianceDashboard.nonCompliant')}
							count={data.systems}
						/>
						<CountText
							color={Colours[packKey]}
							text={t('complianceDashboard.criticalIssues')}
							count={data.issues}
						/>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default ComplianceCounts;
