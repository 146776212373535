import { Card, CardBody } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const NoData = () => (
	<Card>
		<CardBody>
			<h5>{t('table.noResourcesAvailable')}</h5>
		</CardBody>
	</Card>
);

export default NoData;
