import { getAuthHeaders, httpMethods, resolveUri } from './Common';

import { CloudTypes } from '../constants/CloudInfo';
import { getUserType } from '../services/AuthService';

const getAwsComplianceResults = async (report, error, updateReportProgress) => {
	let results = [];

	const uri = resolveUri('evaluation-results');

	const fragments = parseInt(report.Fragments ?? '1'); // '1' For non-Fragmented reports (e.g. azure)

	const getRequestOptions = fragmentId => ({
		method: httpMethods.get,
		headers: getAuthHeaders({
			ConformancePackName: report.PackName,
			ReportId: report.ReportId,
			FragmentId: fragmentId,
		}),
	});

	for (var i = 1; i <= fragments; i++) {
		const response = await fetch(uri, getRequestOptions(i));
		if(updateReportProgress)
		{
				updateReportProgress((i / fragments));
		}
		if (response.ok) {
			results[i] = await response.json();
			console.log(`Fragment ${i} data`, results[i].length);
		} else {
			error(response);
		}
	}

	return results;
};

const getAwsComplianceResultsSync = async (report, error) => {
	const uri = resolveUri('evaluation-results');
	const fragments = parseInt(report.Fragments ?? '1', 10); // Ensure to specify the radix (10)
	const promises = [];

	const getRequestOptions = fragmentId => ({
		method: httpMethods.get,
		headers: getAuthHeaders({
			ConformancePackName: report.PackName,
			ReportId: report.ReportId,
			FragmentId: fragmentId,
		}),
	});

	const fetchFragment = async id => {
		const response = await fetch(uri, getRequestOptions(id));

		if (response.ok) {
			return response.json();
		} else {
			error(response);
		}
	};

	for (let i = 1; i <= fragments; i++) {
		promises.push(fetchFragment(i));
	}

	try {
		const results = await Promise.all(promises);
		return results;
	} catch (err) {
		error(err);
	}
};

const getRemediation = async () => {
	const isAzure = getUserType() === CloudTypes.Azure;
	if (isAzure) return {};

	const uri = resolveUri('remediation');

	const requestOptions = {
		method: httpMethods.get,
		headers: getAuthHeaders({}),
	};

	const response = await fetch(uri, requestOptions);
	let data = {};
	if (response.ok) {
		data = await response.json();
	} else {
		console.error('Could not load data', response.status);
	}

	return data;
};

export { getAwsComplianceResults, getRemediation, getAwsComplianceResultsSync };
