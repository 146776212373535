import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody } from 'reactstrap';

function ModalWindow({
	isOpen,
	toggle,
	isButton,
	title,
	titleStyles,
	subtitleStyle,
	subtile,
	Icon,
}) {
	const { t } = useTranslation();
	return (
		<Modal isOpen={isOpen} toggle={() => toggle(false)} centered={true}>
			<ModalBody className="d-flex flex-column align-items-center justify-content-center mt-3 pb-0">
				{!!Icon && <Icon />}
				<h4 className={titleStyles}>{title}</h4>
				{!!subtile && <p className={subtitleStyle}>{subtile}</p>}
			</ModalBody>
			{isButton && (
				<div className="d-flex flex-column align-items-center justify-content-center mb-3">
					<Button
						color="secondary"
						onClick={() => toggle(false)}
						className="change-pass-btn mb-2"
						style={{ width: 120 }}
					>
						{t('forgotPassword.ok')}
					</Button>
				</div>
			)}
		</Modal>
	);
}

export default ModalWindow;
