import {
  asyncRequest,
  httpMethods,
  resolveUri,
} from "./Common";


const login = async (isMasterAccount, keys, success, failure) => {
  if (!isMasterAccount) {
    console.error("single account login not implemented");
  }

  await masterAccountLogin(keys, success, failure);
}

const createMemberAccounts = (memberAccounts, secrets, rootAccessKey, rootSecretKey, rootAccount) => {
  memberAccounts = memberAccounts.map(member => {
    const accessKey = secrets[`${member.Id}-A`] ?? rootAccessKey;
    const secretKey = secrets[`${member.Id}-S`] ?? rootSecretKey;
    return {
      accessKey,
      secretKey, 
      account: member.Id,
      userName: member.Name,
      masterAccount: member.Id === rootAccount,
      ready: member.Ready }
  });
  memberAccounts = [
      { account: rootAccount, userName: 'All Member Accounts', masterAccount: true },
      ...memberAccounts
    ]; //update the original property
  return memberAccounts;
}

const masterAccountLogin = async (keys, success, failure) => {

  const uri = resolveUri('user-authorizationOrg','conformance-pack');

  const options = {
    method: httpMethods.get,
    headers: keys
  };

  const result = await asyncRequest(uri, options);
  if (!result.success && result.statusCode !== 404) {
    failure();
    return;
  }

  const response = result.response;

  response.MemberAccounts = createMemberAccounts(response.MemberAccounts,
    {}, keys.AccessKey, keys.SecretKey, response.Account);
  success(response);
  // @Saurav informed that we do not need to call secrets api anymore
  // const secrets = await asyncRequest(`${apiHostUri}${masterAccountSecretsUri}`, options);
  // if (secrets.success) {
  //   const rootAccessKey = secrets.response.A ?? keys.AccessKey;
  //   const rootSecretKey = secrets.response.S ?? keys.SecretKey;
  //   response.MemberAccounts = createMemberAccounts(response.MemberAccounts,
  //       secrets.response, rootAccessKey, rootSecretKey, response.Account);
  //   success(response);
  // } else {
  //   // failure(); // ignore '/secrets' failure as AWS might throw incase secrets are not set
  //   response.MemberAccounts = createMemberAccounts(response.MemberAccounts,
  //     {}, keys.AccessKey, keys.SecretKey, response.Account);
  //   success(response);
  // }
}

export {
  login,
  masterAccountLogin
};