import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getLogIssueList } from '../../../../apiclients/Onprem';
import { BTable } from '../../../../components/BTable';
import { useReactTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel } from '@tanstack/react-table';

const cardHeaderStyles = { paddingTop: '1.875rem' };

const createLink = cell => {
    const linkStr = `/talasmart/k8s-log-issue?id=${cell}`;
    return <a href={linkStr}>View Issue</a>;
};

const logIssueColumns = [
    {
        accessorKey: 'resourceid',
        header: 'Resource ID',
        cell: info => info.getValue(),
    },
    {
        accessorKey: 'title',
        header: 'Issue',
        cell: info => info.getValue(),
    },
    {
        accessorKey: 'id',
        header: 'More Info',
        cell: info => createLink(info.getValue()),
    },
];

const defaultSorted = [
    {
        id: 'RunDateTime',
        desc: true,
    },
];

export default ({ data }) => {
    const { t } = useTranslation();
    const [logIssues, setLogIssues] = useState([]);

    const handleLogIssueList = res => {
        const issues = res.issues;
        setLogIssues(issues);
    };

    useEffect(() => {
        getLogIssueList(handleLogIssueList);
    }, []);

    const table = useReactTable({
        data: logIssues,
        columns: useMemo(() => logIssueColumns, []),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            sorting: useMemo(() => defaultSorted, []),
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
        }
    });

    return (
        <Card className="dashboard-card">
            <CardHeader style={cardHeaderStyles}>
                <h5 className="dashboard-chart-title">
                    {t('ransomwareDashboard.logIssues')}
                </h5>
            </CardHeader>
            <CardBody>
                <BTable table={table} pageSizes={[5, 10, 15]} />
            </CardBody>
        </Card>
    );
};
