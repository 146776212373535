import classNames from 'classnames';
import React from 'react';
import Input from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { InputGroup } from 'reactstrap';

const PhoneInput = ({ value, onChange, label, error }) => {
	const inputClassnames = classNames('input-group', {
		'input-invalid': !!error?.trim(),
		'input-valid': !error?.trim() && !!value?.trim(),
	});
	return (
		<label
			className={classNames('cloud-form__label', {
				'cloud-form__label_error': !!error,
			})}>
			{label}
			<InputGroup className={inputClassnames}>
				<Input
					excludeCountries={['ru']}
					country={'us'}
					inputClass="custom-phone-input"
					dropdownClass="custom-phone-dropdown"
					value={value}
					onChange={onChange}
				/>
			</InputGroup>
			{error && <p className="m-0 cloud-form__error">{error}</p>}
		</label>
	);
};

export default PhoneInput;
