const PackKeys = {
	NIST: 'NISTCSF',
	SOC2: 'SOC2',
	HIPAASECURITY: 'HIPAA',
	PCIDSS: 'PCI',
	NIST80053REV4: 'NIST80053REV4',
	NIST800171: 'NIST800171',
	FEDRAMP: 'FEDRAMP',
	NERCCIP: 'NERCCIP',
	CMMCL3: 'CMMCL3',
};

const PackNames = {
	[PackKeys.NIST]: 'NIST-CSF',
	[PackKeys.SOC2]: 'SOC2',
	[PackKeys.HIPAASECURITY]: 'HIPAA Security',
	[PackKeys.PCIDSS]: 'PCI DSS',
	[PackKeys.CMMCL3]: 'CMMC',
};

const packType = pack => ({
	isNist: pack === PackKeys.NIST,
	isSoC2: pack === PackKeys.SOC2,
	isHipaa: pack === PackKeys.HIPAASECURITY,
	isPci: pack === PackKeys.PCIDSS,
	isCmmcl3: pack === PackKeys.CMMCL3,
});

const Mode = {
	automated: 'aws',
	audit: 'audit',
};

export { PackKeys, Mode, PackNames, packType };
