import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import Checkbox from './CheckBox';
import { t } from 'i18next';

const LegendItem = ({ index, item, itemUpdate, hidden }) => {
	const [show, setShow] = useState(true);
	const onCheckItem = () => {
		setShow(!show);
		itemUpdate(null, 'compliant', item);
		itemUpdate(null, 'noncompliant', item);
	};

	const marginCheckboxes = {
		marginLeft: 2,
	};

	return (
		<div className="d-flex align-items-center">
			<span className="item-checkbox">
				<input type="checkbox" checked={show} onChange={() => onCheckItem()} />
			</span>
			<span className="m-2">
				{typeof item.image === 'string' ? (
					<img
						src={item.image}
						alt={item.label}
						width={60}
						height={60}
						className="rounded"
					/>
				) : (
					<item.image />
				)}
			</span>
			<Col>
				<Row>
					<span className="align-middle mt-1 text-body font-weight-bold">
						{item.label}
					</span>
				</Row>
				{!hidden && (
					<>
						<Row style={marginCheckboxes}>
							<Checkbox
								id={`${index}-compliant-checkbox`}
								className=""
								label={t('topology.compliant')}
								checked={item.showCompliant}
								onChange={() => itemUpdate(null, 'compliant', item)}
								disabled={!show}
							/>
						</Row>
						<Row style={marginCheckboxes}>
							<Checkbox
								id={`${index}-non-compliant-checkbox`}
								className="non-compliant-checkbox"
								label={t('topology.nonCompliant')}
								checked={item.showNonCompliant}
								onChange={() => itemUpdate(null, 'noncompliant', item)}
								disabled={!show}
							/>
						</Row>
					</>
				)}
			</Col>
		</div>
	);
};

export default LegendItem;
