import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ErrorModal = ({ text, onClose }) => {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(true);

	const onClick = () => {
		setIsOpen(false);
		if (onClose) {
			onClose(false);
		}
	};

	return (
		<Modal isOpen={isOpen}>
			<ModalHeader>{t('button.error')}</ModalHeader>
			<ModalBody>
				<span className="text-danger">{text}</span>
			</ModalBody>
			<ModalFooter>
				<Button size="lg" color="danger" onClick={onClick}>
					{t('button.close')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ErrorModal;
