import React from 'react';
import { Trans } from 'react-i18next';

const key = 'testReports.testMetaInfo.soc2.';

export default [
	{
		title: <Trans i18nKey={key + 'privacy.title'} />,
		titleBackground: '#66d9ff',
		text: [
			<Trans i18nKey={key + 'privacy.text1'} />,
			<Trans i18nKey={key + 'privacy.text2'} />,
			<Trans i18nKey={key + 'privacy.text3'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'security.title'} />,
		titleBackground: ' #00ace6',
		text: [
			<Trans i18nKey={key + 'security.text1'} />,
			<Trans i18nKey={key + 'security.text2'} />,
			<Trans i18nKey={key + 'security.text3'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'availability.title'} />,
		titleBackground: '#ff6633',
		text: [
			<Trans i18nKey={key + 'availability.text1'} />,
			<Trans i18nKey={key + 'availability.text2'} />,
			<Trans i18nKey={key + 'availability.text3'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'processing.title'} />,
		titleBackground: '#ff8000',
		text: [
			<Trans i18nKey={key + 'processing.text1'} />,
			<Trans i18nKey={key + 'processing.text2'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'confidentiality.title'} />,
		titleBackground: '#66cc00',
		text: [
			<Trans i18nKey={key + 'confidentiality.text1'} />,
			<Trans i18nKey={key + 'confidentiality.text2'} />,
			<Trans i18nKey={key + 'confidentiality.text3'} />,
		],
	},
];
