import React from 'react';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';

function Step1() {
	const { t } = useTranslation();
	return (
		<div id="step1" className="instructions-steps">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 1</h5>
				<div>
					<h5 className="steps-title">
						{t('detailedInstructions.AWSManagement')}
					</h5>
					<div className="d-flex ">
						<img src={icon1} alt="icon1" className="img" />
						{t('detailedInstructions.VisitAWS')}
					</div>
					<div className="d-flex ">
						<img src={icon2} alt="icon1" className="img" />
						{t('detailedInstructions.loginAWS')}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Step1;
