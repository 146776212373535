import React, { useEffect } from 'react';

import { EvaluationApiClient } from '../../../apiclients/EvaluationApiClient';
import Loader from '../../../components/Loader';
import { useNavigate } from 'react-router-dom';

const LatestReport = () => {
	const navigate = useNavigate(),
		client = new EvaluationApiClient(),
		success = r => {
			const url = `/reports/${r.ConformancePack}/audit/${r.ReportId}?evalId=${r.EvalId}&fragments=${r.Fragments}`;
			navigate(url);
		},
		error = e => console.error('Could not fetch evaluation history', e),
		loadReport = () => client.evaluationHistory(success, error);

	useEffect(loadReport, []);

	return <Loader />;
};

export default LatestReport;
