import '../styles.css';

import { Frown, Meh, Smile } from 'react-feather';

import React from 'react';
import { convertToInternationalCurrencySystem } from '../../../Common/Formatter';
import { useTranslation } from 'react-i18next';

const LossChange = ({ previous, current }) => {
	const { t } = useTranslation();
	const deltaMax = previous.max - current.max;

	const titleProps = {
		previousMax: previous.max,
		previousMin: previous.min,
		currentMax: current.max,
		currentMin: current.min,
		deltaMax: deltaMax,
	};

	let result = {
		textClass:
			'text-center font-weight-bold align-middle p-0 m-0 risk-change-text text-white',
		bgClass: 'p-2 mt-2 ',
		title:
			t('assessmentResults.lossTitle', titleProps) +
			(deltaMax > 0 ? 'down' : deltaMax === 0 ? 'none' : 'up'),
		text: '',
		color: '',
	};

	if (deltaMax > 0) {
		result.text = t('assessmentResults.lossReduced', {
			delta: convertToInternationalCurrencySystem(deltaMax),
			previous: convertToInternationalCurrencySystem(previous.max),
		});
		result.bgClass = '#59A919';
		result.color += '#59A919';
	} else if (deltaMax < 0) {
		result.text = t('assessmentResults.lossIncreased', {
			delta: convertToInternationalCurrencySystem(-1 * deltaMax),
			previous: convertToInternationalCurrencySystem(previous.max),
		});

		result.bgClass = '#E72538';

		result.color += '#E72538';
	} else {
		result.text = t('assessmentResults.lossNoChange');
		result.bgClass = '#E09E1E';
		result.color += '#E09E1E';
	}

	const emoji = () => {
		if (deltaMax > 0) {
			return <Smile />;
		} else if (deltaMax < 0) {
			return <Frown />;
		} else {
			return <Meh />;
		}
	};

	return (
		<div
			title={result.title}
			className={`message-container `} style={{backgroundColor: `${result.bgClass}`}}>
			<div
				className={`message-container-triangle`}
				style={{ borderTop: `16px solid ${result.color}` }}></div>
			<div title={result.title} className="info-container">
				<p className={result.textClass}>
					<span className="me-2">{emoji()}</span>
					{result.text}
				</p>
			</div>
		</div>
	);
};

export default LossChange;
