const
	compliantRegex = new RegExp(/^compliant$/gi),
	nonCompliantRegex = new RegExp(/^non-compliant$/gi),
	ComplianceTypes = {
		Compliant: "Compliant",
		NonCompliant: "Non-Compliant"
	};
class ComplianceType {
	isCompliant(value) {
		//https://stackoverflow.com/questions/4724701/regexp-exec-returns-null-sporadically
		compliantRegex.lastIndex = 0;
		return compliantRegex.test(value);
	}

	isNonCompliant(value) {
		//https://stackoverflow.com/questions/4724701/regexp-exec-returns-null-sporadically
		nonCompliantRegex.lastIndex = 0;
		return nonCompliantRegex.test(value);
	}

	isValid(value) {
		return this.isCompliant(value) || this.isNonCompliant(value);
	}
}

export { ComplianceTypes, ComplianceType };