import React, { useState } from 'react';
import ComplianceInfo from './ComplianceInfo';
import { ReactComponent as ChevronSvg } from '../assets/img/chevron.svg';

const NonComplianceDropdown = ({
	item,
	data,
	onRedirect,
	onOpen,
	openedItem,
}) => {
	const [isShown, setIsShown] = useState(false);

	return !!item.length ? (
		<div className="row my-2">
			<button
				type="button"
				onClick={() => {
					setIsShown(v => !v);
				}}
				className={`col-sm-12 p-0 pb-1 compliance-list-heading text-danger ${
					isShown ? 'compliance-list-heading_opened' : ''
				}`}>
				<span>Non-Compliant Rules [{item.length}]</span> <ChevronSvg />
			</button>
			<div className="col-sm-12 compliance-list-container p-0 ">
				{isShown && (
					<ul className="compliance-list">
						{item.map(keyItem => {
							return (
								<li
									key={'ncr-' + keyItem}
									className="border-bottom border-secondary my-9-step">
									<ComplianceInfo
										info={
											data.info?.find(
												item => item?.SecurityTestRule === keyItem
											) || {}
										}
										onClick={onRedirect}
										onOpen={() => onOpen(keyItem)}
										isOpen={openedItem === keyItem}
									/>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	) : null;
};

export default NonComplianceDropdown;
