import {
	ComingSoonButton,
	DisabledButton,
} from './Controls/DisabledTestButton';
import { Container, Row } from 'reactstrap';
import React, { useEffect } from 'react';

import Description from './Controls/Description';
import Info from './Controls/Info';
import TestButton from './Controls/TestButton';
import TestHeader from './Controls/TestHeader';
import allPacks from '../Constants/TestInfo';
import useRunTest from './Hooks/useRunTest';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default () => {
	const location = useLocation();

	const locparts = location.pathname.split('/');
	const locpack = locparts.slice(-1)[0];

	const { t } = useTranslation();

	const packName = locpack.toUpperCase();

	const pack = allPacks[packName];
	const setTitle = () => {
			document.title = t(pack.titleText);
		};

	const { disabled, comingSoon, enableAudit, style } = useRunTest(pack);

	useEffect(setTitle, [pack.titleText]);

	return (
		<Container fluid>
			<TestHeader title={pack.title} />

			<Description description={pack.description} />

			<Row style={style}>
				{pack.meta.map((item, index) => (
					<Info data={item} key={index} useList={pack.useList} />
				))}
			</Row>

			{comingSoon && <ComingSoonButton />}
			{!disabled && !comingSoon && (
				<TestButton packName={packName} enableAudit={enableAudit} />
			)}
			{disabled && !comingSoon && <DisabledButton title={pack.title} />}
		</Container>
	);
};
