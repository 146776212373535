import {
    failure,
    getAuthHeaders,
    httpMethods,
    isAws,
    isAzure,
    progressState,
    resolveUri,
} from './Common';

import { PackKeys } from '../constants/PackKeys';
import { isOrgChildAccount } from '../services/AuthService';

const Headers = {
    [PackKeys.NIST]: { ConformancePackName: 'NISTCSF' },
    [PackKeys.SOC2]: { ConformancePackName: 'SOC2' },
    [PackKeys.HIPAASECURITY]: { ConformancePackName: 'HIPAA' },
    [PackKeys.PCIDSS]: { ConformancePackName: 'PCI' },
    [PackKeys.CMMCL3]: { ConformancePackName: 'CMMCL3' },
};

const startEval = async (pack, reportId, isAsync) => {
    let headers = Headers[pack];
    headers.ReportId = reportId;

    var requestOptions = {
        method: httpMethods.post,
        headers: getAuthHeaders(headers),
        redirect: 'follow',
    };

    const path = isAsync === true ? 'evaluate-async' : 'evaluate';

    const uri = resolveUri(path + (isOrgChildAccount() ? 'Org' : ''));

    const response = await fetch(uri, requestOptions);

    const azureWrapper = result => {
        const Fragments = isAzure() ? 1 : result.Fragments;
        return { ...result, Fragments };
    };

    if (isAsync) {
        return response.ok ? await response.json() : failure(response);
    } else {
        return response.ok
            ? azureWrapper(await response.json())
            : failure(response);
    }
};

const startTopologyEval = async (pack, reportId, count) => {
    var requestOptions = {
        method: httpMethods.put,
        headers: getAuthHeaders({
            ConformancePackName: pack,
            ReportId: reportId,
            FragmentCount: count,
        }),
        redirect: 'follow',
    };

    const uri =
        'live-environment-compliance' + (isOrgChildAccount() ? 'Org' : '');
    const response = await fetch(resolveUri(uri), requestOptions);
    return response.ok ? await response.json() : failure(response);
};

const createNewAwsEval = async (pack, reportId, onProgress) => {
    onProgress(progressState.evalStart);

    let evalResult = await startEval(pack, reportId, isAws());
    if (evalResult.failed) {
        //error
        console.error('start eval failed to begin', evalResult);
        onProgress(progressState.evalError);
        return { failed: true, reason: 'eval' };
    }

    if (isAws()) {
        evalResult = await waitForEvalCompletion({
            pack,
            reportId,
            ackId: evalResult.ReqAckId,
            retryAfterSeconds: evalResult.RETRY_AFTER_SEC,
        });

        if (evalResult.error) {
            //error
            console.error('start eval failed', evalResult);
            onProgress(progressState.evalError);
            return { failed: true, reason: 'eval' };
        }
    }

    onProgress(progressState.evalSuccess);
    onProgress(progressState.topologyStart);

    const topologyResult = await startTopologyEval(
        pack,
        reportId,
        evalResult.Fragments
    );

    if (topologyResult.failed) {
        console.error('start topology eval failed', topologyResult);
        onProgress(progressState.topologyError);
        return { failed: true, reason: 'topology' };
    }

    onProgress(progressState.topologySuccess);

    return { success: true, results: evalResult, failed: false };
};

const finalizeReportUri = () => resolveUri('finalizeReport');

const finalizeReport = async report => {
	const headers = getAuthHeaders({
		ConformancePackName: report.PackName?report.PackName:report.ConformancePack,
		ReportId: report.ReportId,
		EvalId: report.EvalId,
		FragmentCount: report.Fragments,
		'Content-Type': 'application/json',
	});

    const requestOptions = {
        method: httpMethods.put,
        headers: headers,
        body: JSON.stringify([]),
        redirect: 'follow',
    };

    const response = await fetch(finalizeReportUri(), requestOptions);

    if (!response.ok) {
        console.error(
            'An error occurred while trying to finalize the report',
            response
        );
    }

    return response.ok;
};

const getFinalizedReport = async report => {
    const headers = getAuthHeaders({
        ConformancePackName: report.pack,
        ReportId: report.reportId,
        'Content-Type': 'application/json',
    });

    const requestOptions = {
        method: httpMethods.get,
        headers: headers,
        redirect: 'follow',
    };

    const response = await fetch(finalizeReportUri(), requestOptions);

    if (!response.ok) {
        console.error(
            'An error occurred while trying to get the finalized report',
            response
        );
    }

    return response.ok ? await response.json() : failure(response);
};

const startEvalStatus = async (pack, reportId, requestId) => {
    let headers = Headers[pack];
    headers.ReportId = reportId;
    headers.RequestId = requestId;

    var requestOptions = {
        method: httpMethods.get,
        headers: getAuthHeaders(headers),
        redirect: 'follow',
    };

    const uri = resolveUri('evaluate-async-status');

    const response = await fetch(uri, requestOptions);

    if (!response.ok) {
        console.error(
            `An error occurred when trying to get status of ${reportId}`,
            response
        );
        return {
            error: true,
        };
    }

    //200 will get full object. 202 will get just the retry after value "RETRY_AFTER_SEC": 150

    let result = {};
    if (response.status === 200) {
        result = await response.json();
    } else if (response.status === 202) {
        //todo: parse for 202
    }

    return {
        statusCode: response.status,
        ok: response.status === 200,
        accepted: response.status === 202,
        error: false,
        ...result,
    };
};

const waitForEvalCompletion = async ({
    retryAfterSeconds,
    pack,
    reportId,
    ackId,
}) => {
    let completed = false;
    let counter = 0;
    let result;

    while (!completed || counter < 10) {
        await delay(retryAfterSeconds * 1000);
        result = await startEvalStatus(pack, reportId, ackId);

        const stop = result.error === true || result.ok === true;

        if (stop) {
            completed = true;
            counter = 11;
        } else if (result.accepted) {
            counter++;
        }
    }

    return result;
};

const delay = async milliseonds =>
    new Promise(resolve => setTimeout(resolve, milliseonds));

export {
    startEval,
    startTopologyEval,
    createNewAwsEval,
    progressState,
    finalizeReport,
    getFinalizedReport,
    startEvalStatus,
};
