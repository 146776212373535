import {
	Button,
	Card,
	CardBody,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from 'reactstrap';
import React, { useState } from 'react';
import { t } from 'i18next';

const ResetStatus = ({
	sucessfull,
	unsuccessfull,
	inProgress,
	onClosed,
	total,
}) => {
	const [isOpen, setIsOpen] = useState(true);

	const onCancel = () => {
		setIsOpen(false);
		onClosed();
	};

	return (
		<Modal isOpen={isOpen}>
			<ModalHeader className="bg-primary">
				<h3 className="text-white">
					{inProgress
						? t('autoRemediation.resetInProgress')
						: t('autoRemediation.resetCompleted')}
				</h3>
			</ModalHeader>
			<ModalBody>
				<Card>
					<CardBody>
						{sucessfull === 0 && unsuccessfull === 0 && (
							<Row>
								<Col>
									<div className="w-100">
										<strong className="mx-2">
											{t('autoRemediation.fetchingResources')}
										</strong>
									</div>
								</Col>
							</Row>
						)}
						{sucessfull > 0 && (
							<Row>
								<Col>
									<div className="w-100 bg-success text-white my-1">
										<strong className="mx-2">
											{t('autoRemediation.successfullyReset')}
											<b>
												{sucessfull} / {total}
											</b>
										</strong>
									</div>
								</Col>
							</Row>
						)}
						{unsuccessfull > 0 && (
							<Row>
								<Col>
									<div className="w-100 bg-danger text-white my-1">
										<strong className="mx-2">
											{t('autoRemediation.failedToReset')}
											<b>
												{unsuccessfull} / {total}
											</b>
										</strong>
									</div>
								</Col>
							</Row>
						)}
					</CardBody>
				</Card>
			</ModalBody>
			<ModalFooter>
				<div className="container-fluid d-block clearfix">
					<div className=" float-end">
						<Button
							disabled={inProgress}
							size="md"
							color="primary"
							onClick={onCancel}>
							<span className="ms-1">{t('button.close')}</span>
						</Button>
					</div>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default ResetStatus;
