let counter = 0;

export default {
	grossValueOfAssets: [
		{ id: '1', label: '< $100,000', value: '100000' },
		{ id: '2', label: '< $250,000', value: '250000' },
		{ id: '3', label: '< $500,000', value: '500000' },
		{ id: '4', label: '< $1 Million', value: '1000000' },
		{ id: '5', label: '< $2 Million', value: '2000000' },
		{ id: '6', label: '< $5 Million', value: '5000000' },
		{ id: '7', label: '< $10 Million', value: '10000000' },
		{ id: '8', label: '< $15 Million', value: '15000000' },
		{ id: '9', label: '< $20 Million', value: '20000000' },
		{ id: '10', label: '< $25 Million', value: '25000000' },
	],
	cashflow: [
		{ id: '1', label: '< $100,000', value: '100000' },
		{ id: '2', label: '< $250,000', value: '250000' },
		{ id: '3', label: '< $500,000', value: '500000' },
		{ id: '4', label: '< $1 Million', value: '1000000' },
		{ id: '5', label: '< $2 Million', value: '2000000' },
		{ id: '6', label: '< $5 Million', value: '5000000' },
		{ id: '7', label: '< $10 Million', value: '10000000' },
		{ id: '8', label: '< $15 Million', value: '15000000' },
		{ id: '9', label: '< $20 Million', value: '20000000' },
		{ id: '10', label: '< $25 Million', value: '25000000' },
	],
	numberOfEmployees: [
		{ id: '1', value: '<= 50' },
		{ id: '50', value: '51-100' },
		{ id: '100', value: '101-200' },
		{ id: '200', value: '> 200' },
	],
	buNames: [
		{ id: 1, value: 'Information Technology' },
		{ id: 2, value: 'Software Development' },
		{ id: 3, value: 'Dev Ops' },
		{ id: 4, value: 'Sales & Marketing' }
	],
	sectors: [
		{ id: ++counter, value: 'Chemical' },
		{ id: ++counter, value: 'Commercial Facilities' },
		{ id: ++counter, value: 'Communications' },
		{ id: ++counter, value: 'Manufacturing' },
		{ id: ++counter, value: 'Dams' },
		{ id: ++counter, value: 'Defence' },
		{ id: ++counter, value: 'Emergency Services' },
		{ id: ++counter, value: 'Energy' },
		{ id: ++counter, value: 'Financial Services' },
		{ id: ++counter, value: 'Food & Agriculture' },
		{ id: ++counter, value: 'Government Services' },
		{ id: ++counter, value: 'Healthcare & Public Health' },
		{ id: ++counter, value: 'Information Technology' },
		{ id: ++counter, value: 'Nuclear Reactors, Materials & Waste' },
		{ id: ++counter, value: 'Transportation Systems' },
		{ id: ++counter, value: 'Waste & Waste Water Systems' }
	],
	industry: [
		{ id: ++counter, parent: 'Chemical', value: 'Agricultural Products' },
		{ id: ++counter, parent: 'Chemical', value: 'Basic Chemicals' },
		{ id: ++counter, parent: 'Chemical', value: 'Consumer Products' },
		{ id: ++counter, parent: 'Chemical', value: 'Pharmaceutical Products' },
		{ id: ++counter, parent: 'Chemical', value: 'Others' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Entertainment & Media' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Gaming' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Lodging' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Events' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Real Estate' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Retail' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Sport Leagues' },
		{ id: ++counter, parent: 'Commercial Facilities', value: 'Others' },
		{ id: ++counter, parent: 'Communications', value: 'Broadcasting' },
		{ id: ++counter, parent: 'Communications', value: 'Cable' },
		{ id: ++counter, parent: 'Communications', value: 'Satellite' },
		{ id: ++counter, parent: 'Communications', value: 'Telecommunications' },
		{ id: ++counter, parent: 'Communications', value: 'Wireless Communications' },
		{ id: ++counter, parent: 'Communications', value: 'Wireline' },
		{ id: ++counter, parent: 'Communications', value: 'Others' },
		{ id: ++counter, parent: 'Manufacturing', value: 'Electrical Equipment, Appliance & Components' },
		{ id: ++counter, parent: 'Manufacturing', value: 'Heavy Machinery' },
		{ id: ++counter, parent: 'Manufacturing', value: 'Machinery' },
		{ id: ++counter, parent: 'Manufacturing', value: 'Metals' },
		{ id: ++counter, parent: 'Manufacturing', value: 'Transport & Heavy Equipment' },
		{ id: ++counter, parent: 'Manufacturing', value: 'Others' },
		{ id: ++counter, parent: 'Dams', value: 'Dams' },
		{ id: ++counter, parent: 'Dams', value: 'Levees' },
		{ id: ++counter, parent: 'Dams', value: 'Navigation Locks' },
		{ id: ++counter, parent: 'Dams', value: 'Hydropower' },
		{ id: ++counter, parent: 'Dams', value: 'Tailings & Waste Impoundments' },
		{ id: ++counter, parent: 'Dams', value: 'Others' },
		{ id: ++counter, parent: 'Defence', value: 'Aircraft' },
		{ id: ++counter, parent: 'Defence', value: 'Ammunition' },
		{ id: ++counter, parent: 'Defence', value: 'Combat Vehicle' },
		{ id: ++counter, parent: 'Defence', value: 'Electrical Industry' },
		{ id: ++counter, parent: 'Defence', value: 'Electronics' },
		{ id: ++counter, parent: 'Defence', value: 'Mechanical Components' },
		{ id: ++counter, parent: 'Defence', value: 'Missile' },
		{ id: ++counter, parent: 'Defence', value: 'RnD' },
		{ id: ++counter, parent: 'Defence', value: 'Ship building' },
		{ id: ++counter, parent: 'Defence', value: 'Space' },
		{ id: ++counter, parent: 'Defence', value: 'Weapons' },
		{ id: ++counter, parent: 'Defence', value: 'Others' },
		{ id: ++counter, parent: 'Emergency Services', value: 'Emergency Management' },
		{ id: ++counter, parent: 'Emergency Services', value: 'Emergency Medical Services' },
		{ id: ++counter, parent: 'Emergency Services', value: 'Fire and Rescue' },
		{ id: ++counter, parent: 'Emergency Services', value: 'Law Enforcement' },
		{ id: ++counter, parent: 'Emergency Services', value: 'Public Works' },
		{ id: ++counter, parent: 'Emergency Services', value: 'Others' },
		{ id: ++counter, parent: 'Energy', value: 'Electric Power Generation, Transmission & Distribution' },
		{ id: ++counter, parent: 'Energy', value: 'Natural Gas' },
		{ id: ++counter, parent: 'Energy', value: 'Oil' },
		{ id: ++counter, parent: 'Energy', value: 'Petroleum Refineries' },
		{ id: ++counter, parent: 'Energy', value: 'Others' },
		{ id: ++counter, parent: 'Financial Services', value: 'Consumer Services' },
		{ id: ++counter, parent: 'Financial Services', value: 'Credit and Liquidity Products' },
		{ id: ++counter, parent: 'Financial Services', value: 'Investment Products' },
		{ id: ++counter, parent: 'Financial Services', value: 'Banks' },
		{ id: ++counter, parent: 'Financial Services', value: 'Risk Transfer Products' },
		{ id: ++counter, parent: 'Financial Services', value: 'Unions' },
		{ id: ++counter, parent: 'Financial Services', value: 'Others' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Beverage Manufacturing Plants' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Food Manufacturing Plants' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Food Services' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Processing, Packaging & Distribution' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Product Distribution' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Product Storage' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Product Transportation' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Supply' },
		{ id: ++counter, parent: 'Food & Agriculture', value: 'Others' },
		{ id: ++counter, parent: 'Government Services', value: 'Local Government' },
		{ id: ++counter, parent: 'Government Services', value: 'Public Facilities' },
		{ id: ++counter, parent: 'Government Services', value: 'Non-Public Facilities' },
		{ id: ++counter, parent: 'Government Services', value: 'State Government' },
		{ id: ++counter, parent: 'Government Services', value: 'Territorial Government' },
		{ id: ++counter, parent: 'Government Services', value: 'Tribal Government' },
		{ id: ++counter, parent: 'Government Services', value: 'Others' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Direct Patient Care' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Fatality Management Services' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Health IT' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Health Plans and Payers' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Hospitals' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Medical Materials' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Residential Care Facilities' },
		{ id: ++counter, parent: 'Healthcare & Public Health', value: 'Others' },
		{ id: ++counter, parent: 'Information Technology', value: 'DNS Services' },
		{ id: ++counter, parent: 'Information Technology', value: 'Identity & Trust Management' },
		{ id: ++counter, parent: 'Information Technology', value: 'Incident Management' },
		{ id: ++counter, parent: 'Information Technology', value: 'Internet Content & Service Providers' },
		{ id: ++counter, parent: 'Information Technology', value: 'Internet Routing & Connection' },
		{ id: ++counter, parent: 'Information Technology', value: 'IT Production' },
		{ id: ++counter, parent: 'Information Technology', value: 'Others' },
		{ id: ++counter, parent: 'Nuclear Reactors, Materials & Waste', value: 'Fuel Cycle Facilities' },
		{ id: ++counter, parent: 'Nuclear Reactors, Materials & Waste', value: 'Nuclear Materials Transport' },
		{ id: ++counter, parent: 'Nuclear Reactors, Materials & Waste', value: 'Operating Nuclear Power Plants' },
		{ id: ++counter, parent: 'Nuclear Reactors, Materials & Waste', value: 'Radioactive Materials' },
		{ id: ++counter, parent: 'Nuclear Reactors, Materials & Waste', value: 'Radioactive Waste' },
		{ id: ++counter, parent: 'Nuclear Reactors, Materials & Waste', value: 'Others' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Aviation' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Freight Rail' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Truck Transportation' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Maritime' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Mass Transit & Railways' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Municipalities with Traffic Control Systems' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Pipelines' },
		{ id: ++counter, parent: 'Transportation Systems', value: 'Others' },
		{ id: ++counter, parent: 'Waste & Waste Water Systems', value: 'Public Water Systems' },
		{ id: ++counter, parent: 'Waste & Waste Water Systems', value: 'Publicly Owned Treatment Works' },
		{ id: ++counter, parent: 'Waste & Waste Water Systems', value: 'Others' }
	],
	disasterRecoveryLicenseStatus: [
		{id: 0, value: 'Active'},
		{id: 1, value: 'Invalid'}
	],
	frequency: [
		{id: "0", value: 'Annually'},
		{id: "1", value: 'Bi-Annually'},
		{id: "2", value: 'Quarterly'},
		{id: "3", value: 'Monthly'},
		{id: "4", value: 'Bi-Monthly'},
		{id: "5", value: 'Weekly'},
		{id: "6", value: 'Bi-Weekly'},
		{id: "7", value: 'Daily'},
	],
}