import { ChevronsLeft, ChevronsRight } from 'react-feather';

import { Button } from 'reactstrap';
import HrmsButton from './HrmsButton';
import React from 'react';
import { t } from 'i18next';

const navTextCss = 'text-center ms-2 me-2';
const icon = { css: 'align-middle', h: 8, w: 12 };

export default function ({ onSaveReport, onSaveDraft, testCase }) {
	return (
		<>
			<div className="row p-0 pt-2">
				<div className="col">
					<Button
						size="sm"
						color="secondary"
						onClick={onSaveDraft}
						title="Save this audit report for later and return to the dashboard"
						className="ms-2 float-start">
						<ChevronsLeft className={icon.css} height={icon.h} width={icon.w} />
						<span className={navTextCss}>{t('button.saveForLater')}</span>
					</Button>
				</div>

				<div className="col">
					{testCase.hrms && (
						<div className="row">
							<div className="mx-auto">
								<HrmsButton data={testCase.hrms} />
							</div>
						</div>
					)}
				</div>

				<div className="col">
					<Button
						size="sm"
						color="primary"
						onClick={onSaveReport}
						title="Begin the automated evaluation report"
						className="ms-2 float-end">
						<span className={navTextCss}>{t('button.runAutomatedTest')}</span>
						<ChevronsRight className={icon.css} height={12} width={icon.w} />
					</Button>
				</div>
			</div>
		</>
	);
}
