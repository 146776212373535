import auditData from "../../AuditData/PciDss";

const data = {
	"referenceData": {
		"result": {
			"defaultValue": 0,
			"values": [{
				"value": 0,
				"label": "Select"
			}, {
				"value": 1,
				"label": "Compliant"
			},
			{
				"value": 2,
				"label": "Non-Compliant"
			}
			]
		},
		"severity": {
			"defaultValue": 0,
			"values": [{
				"value": 0,
				"label": "Select"
			}, {
				"value": 1,
				"label": "Low"
			},
			{
				"value": 2,
				"label": "Medium"
			},
			{
				"value": 3,
				"label": "High"
			},
			{
				"value": "4",
				"label": "Critical"
			}
			]
		}
	},
	"headers": [{
		"id": 1,
		"value": "Requirement",
		"classList": "dont-print col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 2,
		"value": "Sub-Requirment",
		"classList": "col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 3,
		"value": "Test Case",
		"classList": "print-sub-cat-col col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 4,
		"value": "Result",
		"classList": "print-result-col col col-md-2 text-md-center",
		"style": {
			"marginLeft": "-1rem"
		}
	},
	{
		"id": 5,
		"value": "Severity",
		"classList": "col col-md-1 text-md-left",
		"style": {
			"marginLeft": "-2rem"
		}
	},
	{
		"id": 6,
		"value": "Evidence",
		"classList": "col col-md-1 text-md-center",
		"style": {
			"marginLeft": "-0.5rem"
		}
	},
	{
		"id": 7,
		"value": "Observation",
		"classList": "col col-md-1 text-md-center",
		"style": {
			"marginLeft": "1rem"
		}
	},
  {
    "id": 8,
    "value": "Organisation Controls",
    "classList": "col col-md-1 text-md-center",
    "style": { "marginLeft": "0rem" },
  }]
};

data.referenceData.categories = auditData.categories;
data.referenceData.subcategories = auditData.subcategories;
data.referenceData.defaultValues = auditData.defaultValues;
data.childTestCases = auditData.childTestCases;
data.testCases = auditData.testCases;

export default data;