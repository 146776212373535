import { evaluate } from "../../../apiclients/Ransomware";
import { setItem } from "../../../services/browserStorageService";
import { useState } from "react";

const useAnalysing = (exclusion, setExclusion) => {
	const [running, setRunning] = useState(true);
	const [evaluating, setEvaluating] = useState(false);

	const startEval = async (reportName, onComplete) => {
		setEvaluating(true);
		const result = await evaluate(reportName, exclusion);

		if (result.success) {
			console.log("eval completed", result);
			setItem(`ransomware_${reportName}`, result.data);
			onComplete();
		} else {
			console.error("an error occurred when running the ransomware evaluation");
		}
		setEvaluating(false);
		setExclusion('None');
	}

	return {
		running,
		evaluating,
		setRunning,
		startEval,
	}
};

export default useAnalysing;