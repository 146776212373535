import React from 'react';
import { ReactComponent as ImageSvg } from '../../assets/img/save.svg';
import { Modal, ModalBody } from 'reactstrap';
import { t } from 'i18next';
import Button from '../../components/Form/Button';

const SavePopup = ({ onToggle, isOpen, onSubmit }) => {
	return (
		<Modal
			backdrop
			isOpen={isOpen}
			toggle={onToggle}
			unmountOnClose={true}
			centered>
			<ModalBody className="d-flex justify-content-center align-items-center flex-column px-5 pt-3 pb-4 text-center">
				<ImageSvg />
				<h5 className="mt-2 font-weight-bold">{t('settings.approveSave')}</h5>
				<div className="d-flex gap-2 mt-4">
					<Button onClick={onSubmit} style={{ width: '120px' }}>
						{t('button.yes')}
					</Button>
					<Button
						onClick={onToggle}
						variant="outlined"
						style={{ width: '120px' }}>
						{t('button.no')}
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

export default SavePopup;
