import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBack from '../../../assets/img/ArrowBack.svg';
import { Card } from 'reactstrap';
import '../styles.css';

function HeaderIntroduction({ title, onClick, subTitle, subTitle2 }) {
	const { t } = useTranslation();
	return (
		<Card className="one-time-setup-header">
			<div className="d-flex justify-content-between p-4">
				<div className="one-time-setup">
					<h1>{title}</h1>
					<p className="m-0">{subTitle}</p>
					{subTitle2 && <p className="m-0 ">{subTitle2}</p>}
				</div>
				<div
					onClick={onClick}
					className="d-flex align-items-center cursor-pointer">
					<img src={ArrowBack} alt="arrowback" className="m-2" />
					<p className="m-0">{t('oneTimeSetup.goBack')}</p>
				</div>
			</div>
		</Card>
	);
}

export default HeaderIntroduction;
