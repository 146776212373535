import React from 'react';

function ProgressCircle({ cirlce, step, descp }) {
	return (
		<>
			<div>
				<img src={cirlce} alt="cirlce" />
				<div style={{ maxWidth: '40px' }}>
					<p className="steps">{`Step ${step}`}</p>
					<p className="descp">{descp}</p>
				</div>
			</div>
		</>
	);
}

export default ProgressCircle;
