/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import React from 'react';
import { ArrowUpRight, Download } from 'react-feather';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { convertToInternationalCurrencySystem } from '../../Common/Formatter';

const largeHeaderStyle = {
	width: '15%',
	textAlign: 'left',
};
const headerStyle = {
	textAlign: 'left',
};
const completedReportActions = ( row) => (
	<div className="dashboard-table-action">
		<Link
			to={`/reports/ransomware/${row.ReportId}`}
			title="View"
			className="dashboard-table-action__button">
			<ArrowUpRight height={12} width={12} />
		</Link>
		<a
			type="button"
			className="dashboard-table-action__button"
			title="Download"
			href="#">
			<Download height={12} width={12} />
		</a>
	</div>
);

// export const evaluationHistoryColumns = [
// 	{
// 		dataField: 'ReportId',
// 		text: <Trans i18nKey="table.reportName" />,
// 		headerStyle: largeHeaderStyle,
// 		sort: true,
// 	},
// 	{
// 		dataField: 'RunDateTime',
// 		text: <Trans i18nKey="table.dateTime" />,
// 		sort: true,
// 		formatter: cell => moment.utc(cell).local().format('YYYY-MM-DD HH:mm:ss'),
// 	},
// 	{
// 		dataField: 'PotentialLoss',
// 		text: <Trans i18nKey="table.potentialLoss" />,
// 		headerStyle,
// 		sort: true,
// 		align: 'left',
// 		formatter: cell => (
// 			<div title={cell}>${convertToInternationalCurrencySystem(cell)}</div>
// 		),
// 	},
// 	{
// 		dataField: 'RiskScore',
// 		text: <Trans i18nKey="table.riskScore" />,
// 		headerStyle,
// 		sort: true,
// 		align: 'left',
// 	},
// 	{
// 		dataField: 'VulnerableIssueCount',
// 		text: <Trans i18nKey="table.vulnerableIssues" />,
// 		headerStyle,
// 		sort: true,
// 		align: 'left',
// 	},
// 	{
// 		dataField: 'VulnerableResourceCount',
// 		text: <Trans i18nKey="table.resources" />,
// 		headerStyle,
// 		sort: true,
// 		align: 'left',
// 	},
// 	{
// 		dataField: 'actions',
// 		text: <Trans i18nKey="table.actions" />,
// 		headerStyle: {
// 			textAlign: 'right',
// 		},
// 		formatter: completedReportActions,
// 	},
// ];
export const evaluationHistoryColumns = [
	{
		accessorKey: 'ReportId',
		header: <Trans i18nKey="table.reportName" />,
		headerStyle: largeHeaderStyle,
		sort: true,
	},
	{
		accessorKey: 'RunDateTime',
		header: <Trans i18nKey="table.dateTime" />,
		sort: true,
		cell: info => moment.utc(info.getValue()).local().format('YYYY-MM-DD HH:mm:ss'),
	},
	{
		accessorKey: 'PotentialLoss',
		header: <Trans i18nKey="table.potentialLoss" />,
		headerStyle,
		sort: true,
		align: 'left',
		cell: info => (
			<div title={info.getValue()}>${convertToInternationalCurrencySystem(info.getValue())}</div>
		),
	},
	{
		accessorKey: 'RiskScore',
		header: <Trans i18nKey="table.riskScore" />,
		headerStyle,
		sort: true,
		align: 'left',
	},
	{
		accessorKey: 'VulnerableIssueCount',
		header: <Trans i18nKey="table.vulnerableIssues" />,
		headerStyle,
		sort: true,
		align: 'left',
	},
	{
		accessorKey: 'VulnerableResourceCount',
		header: <Trans i18nKey="table.resources" />,
		headerStyle,
		sort: true,
		align: 'left',
	},
	{
        id: 'actions', // Змінено на 'id' для однозначності визначення колонки
        accessorFn: (row) => row, // Використовуємо весь рядок для функції дій
        header: <Trans i18nKey="table.actions" />,
        headerStyle: {
            textAlign: 'center',
        },
        cell: info => completedReportActions( info.row.original), // Використовуємо original для доступу до даних рядка
    },
];