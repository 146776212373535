import React from 'react';
import { Modal } from 'reactstrap';
import { ReactComponent as FailedSvg } from '../images/failed.svg';
import { t } from 'i18next';
import Button from '../../../components/Form/Button';

const NotAvailablePopup = ({ isOpen, onClose }) => {
	return (
		<Modal
			backdrop
			isOpen={isOpen}
			toggle={onClose}
			unmountOnClose
			contentClassName="px-5"
			centered>
			<div className="d-flex flex-column align-items-center p-4 gap-2">
				<FailedSvg />
				<h3 className="mb-0 font-weight-black text-center">
					{t('talasmart.resource.notConfiguredTitle')}
				</h3>
				<p>{t('talasmart.resource.notConfiguredDescription')}</p>
				<Button onClick={onClose}>{t('button.ok')}</Button>
			</div>
		</Modal>
	);
};

export default NotAvailablePopup;
