const getItem = key => {
	const storedItem = window.sessionStorage.getItem(key);

	if (!storedItem) {
		// console.log(`item with key ${key} is null`);
		return null;
	}

	return JSON.parse(storedItem === 'null' ? null : storedItem);
};

const setItem = (key, value) => {
	window.sessionStorage.setItem(key, JSON.stringify(value));
};

const removeItem = key => {
	window.sessionStorage.removeItem(key);
};

const getAllItems = () => {
	const keys = Object.keys(window.sessionStorage);
	const mapper = key => ({ key, value: getItem(key) });
	return keys.map(mapper);
};

const clearItemsWithKeyStartingWith = keys => {
	getAllItems().forEach(item => {
		const found = item.key.startsWith(keys);
		if (found) {
			removeItem(item.key);
		}
	});
};

const clearAllItems = () => {
	getAllItems().forEach(item => {
		removeItem(item.key);
	});
};

export {
	getItem,
	setItem,
	removeItem,
	getAllItems,
	clearItemsWithKeyStartingWith,
	clearAllItems,
};
