import Analysing from './Controls/Analysing';
import DemographicAssessment from './Controls/DemographicAssessment';
import Info from './Controls/Info';
import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ReviewScan from './Controls/ReviewScan/index';
import ReviewScore from './Controls/ReviewScore/index';
import { Stages } from '../Common/AssessmentStage';
import useAssessmentWizard from '../Hooks/useAssessmentWizard';
import RedirectReason from './Controls/RedirectReason';
import { useTranslation } from 'react-i18next';

import { isDemoAccount } from '../../../services/accountTypeService';

import DemoPopup from '../../../components/DemoPopup';

export default () => {
	const params = useParams();
	const mode = params.mode || 'Info'; // special case: there is a specific route for Info that doesn't pass the param.
	const hook = useAssessmentWizard(`${mode}`);
	const [userQuestionnaire, setUserQuestionnaire] = useState({});
	const [demoPopup, setDemoPopup] = useState(false);
	const queryParams = new URLSearchParams(useLocation().search);
	const reason = queryParams.get('reason');

	const { t } = useTranslation();

	const showDemoPopup = () => {
		setDemoPopup(true);
	};

	return (
		<>
			<RedirectReason reason={reason} />
			<DemoPopup
				isOpen={demoPopup}
				setDemoPopup={setDemoPopup}
				title={t('liveDemo.unableStart')}
				subtitle={t('liveDemo.purchaseLicenseRiskAssessment')}
			/>

			<Info
				stage={hook.stage}
				onNext={isDemoAccount() ? showDemoPopup : hook.nextStage}
			/>
			<DemographicAssessment
				stage={hook.stage}
				onNext={hook.nextStage}
				setMode={hook.setAssessmentMode}
				mode={hook.assessmentMode}
				reportName={hook.reportName}
				setReportName={hook.setReportName}
				setUserQuestionnaire={setUserQuestionnaire}
			/>
			<ReviewScan
				stage={hook.stage}
				onNext={hook.nextStage}
				setExclusion={hook.setExclusion}
				mode={hook.assessmentMode}
				reportName={hook.reportName}
				userQuestionnaire={userQuestionnaire}
			/>
			<Analysing
				stage={hook.stage}
				onNext={hook.nextStage}
				exclusion={hook.exclusion}
				reportName={hook.reportName}
				setExclusion={hook.setExclusion}
				mode={hook.assessmentMode}
			/>
			{hook.stage === Stages.ReviewScore && (
				<ReviewScore
					stage={hook.stage}
					onNext={hook.nextStage}
					reportName={hook.reportName}
					setMode={hook.setAssessmentMode}
				/>
			)}
		</>
	);
};
