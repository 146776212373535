import { is, map } from './AuditItemHelper';

import { EventTypeToProp } from './EventType';
import { useState } from 'react';

const getValidItems = (keys, data) => {
	const valid = new Set(),
		invalid = new Set();

	keys.forEach(key => {
		if (is.valid(data[key])) {
			valid.add(key);
		}

		if (is.invalid(data[key])) {
			valid.add(key);
		}
	});

	return { valid, invalid };
};

export default function ({ refData, data, onSave }) {
	const [state, setState] = useState({});
	const [auditData] = useState(data);
	const pack = refData.pack;

	const allSubCategories = Object.keys(data);

	const { valid, invalid } = getValidItems(allSubCategories, data),
		[validData] = useState(valid),
		[invalidData] = useState(invalid),
		allCount = allSubCategories.length,
		[totalCount] = useState(allCount),
		[validCount, setValidCount] = useState(validData.size),
		[invalidCount, setInvalidCount] = useState(invalidData.size);

	const onNavEvent = (eventType, value) => {
		const prop = EventTypeToProp[eventType];

		if (!prop) {
			console.error('unknown event type', eventType);
			return;
		}

		setState(v => ({ ...v, [prop]: value }));
	};

	const onFilterChange = filter => {};

	const onItemChange = (item, id) => {
		auditData[id] = map.toArray(item);

		let count = 0;

		const update = (first, second) => {
			if (first.has(id)) {
				first.delete(id);
				count++;
			}
			if (!second.has(id)) {
				second.add(id);
				count++;
			}
		};

		if (item.error) {
			update(validData, invalidData);
		} else {
			update(invalidData, validData);
		}

		if (count > 0) {
			setValidCount(validData.size);
			setInvalidCount(invalidData.size);
		}
	};

	const onSaveDraft = async () => await onSave(true, auditData);
	const onSaveReport = async () => await onSave(false, auditData);

	return {
		pack,
		state,
		auditData,
		validData,
		totalCount,
		validCount,
		invalidData,
		invalidCount,
		onNavEvent,
		onFilterChange,
		onItemChange,
		onSaveDraft,
		onSaveReport,
	};
}
