
import React from 'react';
import { Navigate } from 'react-router-dom';


export default ({reason}) => {
	const params = new URLSearchParams();
	if(reason)
	{
		params.set('reason', reason);
	}
	const msg = reason ? '?' + params.toString() : '';
	console.log("reason:", reason, "msg:", msg);
	return (<Navigate to={'/ransomware/assessment/Info' + msg} />)
};
