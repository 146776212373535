import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ExportCsv from '../Controls/ExportCsvButton';
import ReviewButton from '../../tests/AutomatedTests/ReviewButton';
import Tabs from '../../../components/Tabs';
import { TABS } from './constants';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';

const tabs = [
    {
        value: TABS.ISSUES,
        label: <Trans i18nKey="topology.showIssues" />,
    },
    {
        value: TABS.RESOURCES,
        label: <Trans i18nKey="topology.showResources" />,
    },
];


const handleSearch = (table, searchval) => {
    table.setGlobalFilter(searchval);
};

const TableResourceHeader = (props) => {
	const { t } = useTranslation();

    return (
        <>
            <div className="mt-3 d-flex flex-wrap align-items-center gap-2 justify-content-between">
                {props.report?.ReportId && (
                    <div>
                        <Tabs
                            onChange={props.setActiveTab}
                            activeTab={props.activeTab}
                            tabs={tabs}
                            small
                        />
                    </div>
                )}
                <div className="d-flex align-items-center topology-actions gap-2">
                    {props.report?.ReportId && (
                        <ReviewButton
                            className="flex-shrink-0"
                            data={props.baseProps.data}
                            reviewUrl={props.reviewUrl}
                            report={props.report}
                        />
                    )}
                    <ExportCsv
                        data={props.baseProps.data}
                        csvFileName={props.csvFileName}
                        className="flex-shrink-0">
                        <Trans i18nKey="button.exportResourceList" />
                    </ExportCsv>
                    <input
                        type="text"
                        placeholder={t('topology.search')}
                        onChange={(e) => { handleSearch(props.baseProps.table, e.target.value) }}
                        style={{ marginBottom: '0' }}
                        className="mb-0"
                    />
                </div>
            </div>
            <h5 className="mt-5 mb-3 text-md">
                <Trans i18nKey="topology.topologyDetails" />
            </h5>
        </>
    );
};

const TableIssuesHeader = (props) => {

    return (
        <>
            <div className="mt-3 d-flex flex-wrap align-items-center justify-content-between gap-2">
                {props.report?.ReportId && (
                    <div>
                        <Tabs
                            onChange={props.setActiveTab}
                            activeTab={props.activeTab}
                            tabs={tabs}
                            small
                        />
                    </div>
                )}
                <div className="d-flex align-items-center justify-content-between topology-actions gap-2">
                    {props.report?.ReportId && (
                        <ReviewButton
                            className="flex-shrink-0"
                            data={props.baseProps.data}
                            reviewUrl={props.reviewUrl}
                            report={props.report}
                        />
                    )}
                    <ExportCsv
                        data={props.baseProps.data}
                        csvFileName={props.csvFileName}
                        className="flex-shrink-0">
                        <Trans i18nKey="button.exportTestResults" />
                    </ExportCsv>
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => { handleSearch(props.baseProps.table, e.target.value) }}
                        style={{ marginBottom: '0' }}
                        className="mb-0"
                    />
                </div>
            </div>
            <h5 className="mt-5 mb-3 text-md">
                <Trans i18nKey="topology.topologyDetails" />
            </h5>
        </>
    );
};

export default {
    issues: TableIssuesHeader,
    resources: TableResourceHeader,
};
