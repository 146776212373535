import Form from "./Form";
import Pagination from "./Pagination";
import React from "react";
import useSubCategories from "./useSubCategories";

export default function (props) {
	
	const vm = useSubCategories(props);
	
	return <>
		<Pagination
			ids={vm.ids}
			selected={vm.selectedId}
			error={vm.error}
			onClick={vm.onClick}
			pack={props.refData.pack}
			subCategory={vm.subCategory} />
		<Form {...props} selectedId={vm.ids[vm.selectedId]} onSubCatError={vm.onError}  />
	</>
}