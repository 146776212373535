import React from 'react';
import { Button, Card, CardBody, Container, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { BTable } from '../../../../components/BTable';
import DashBoardError from './DashBoardError';
import Header from '../../../../components/Header';
import HeaderTitle from '../../../../components/HeaderTitle';
import { getItem } from '../../../../services/browserStorageService';
import { columns } from '../../Common/VulnerabilityDetailColumns';
import { t } from 'i18next';
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    getSortedRowModel,
} from '@tanstack/react-table';

const headerTitleStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
};

const RansomwareDashboard = () => {
    const result = getItem('dashboard-vulnerabilities');
    const error = !result || !result.length;

    const handleSearch = (table, searchval) => {
        table.setGlobalFilter(searchval);
    };

    const table = useReactTable({
        data: result,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
            sorting: [
                {
                    id: 'PotentialLoss',
                    desc: true,
                },
            ],
        },
        manualPagination: false,  
        autoResetPageIndex: false, 
    });

    return (
        <Container fluid>
            <Header>
                <HeaderTitle style={headerTitleStyles}>
                    <span>{t('ransomwareDashboard.ransomwareVulnerabilities')}</span>
                    <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => { handleSearch(table, e.target.value) }}
                        style={{ width: '200px' }}
                    />
                </HeaderTitle>
            </Header>
            <Card>
                <CardBody>
                    {error && <DashBoardError />}
                    {!error && (
                        <>
                            <div>
                                <BTable table={table} pageSizes={[10, 20, 50]} />
                            </div>
                            <div className="clearfix mb-2 mt-2">
                                <Link to="/ransomware/dashboard">
                                    <Button size="md" color="primary">
                                        <FontAwesomeIcon
                                            icon={faArrowAltCircleLeft}
                                            className="me-1"
                                        />
                                        {t('button.backToDashboard')}
                                    </Button>
                                </Link>
                            </div>
                        </>
                    )}
                </CardBody>
            </Card>
        </Container>
    );
};

export default RansomwareDashboard;
