import React from "react";
import {
    Card,
	CardBody,
	CardHeader,
    Row
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorInfo = props => {
    return (
        <Card className="d-flex">
            <CardHeader></CardHeader>
            <CardBody>
                <Row>
                    <div className="mt-1 ms-3 me-3">
                        <h1 className="text-danger">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />
                        </h1>
                    </div>
                    <div className="ms-2 w-50">
                        <p>
                            An error occurred while trying to load this information. Please
                            reload the page to try again. If the problem recurs, it is possible
                            that your account lacks the necessary permissions to access this
                            information.
                        </p>
                    </div>
                </Row>
            </CardBody>
        </Card>
    )
}

export default ErrorInfo;