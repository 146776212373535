import React from 'react';
import Next from '../../../assets/img/Next.svg';
import { useTranslation } from 'react-i18next';

function CustomBtn({ onClick }) {
	const { t } = useTranslation();
	const btnStyles = {
		backgroundColor: '#2474F3',
		borderRadius: '24px',
		border: 'none',
		color: 'white',
		padding: '5px',
		width: '120px',
		textAlign: 'center',
	};
	return (
		<>
			<button style={btnStyles} onClick={onClick}>
				{t('bigGuide.read')}
				<img src={Next} className="ms-2" alt="arrowNext" />
			</button>
		</>
	);
}

export default CustomBtn;
