import React from "react";

export default function ({ categoryIds, categories, selected, onSelect }) {

	const getClass = id => `me-1 ms-1 btn ${selected === id ? "btn-primary" : "btn-outline-primary"}`;

	return <>
		<div className="btn-group" role="group">
			{categoryIds.map(id =>
				<button
					key={id}
					className={getClass(id)}
					data-toggle="pill"
					href={`#-${id}`}
					onClick={() => onSelect(id)}>
					{categories[id].name}
				</button>
			)}
		</div>
	</>
}