import { getFixLogs, saveFixLogs } from '../../../apiclients/Ransomware';
import { useEffect, useState } from 'react';

import { isAzure } from '../../../apiclients/Common';

//taken from : https://stackoverflow.com/a/38327540
const groupBy = (list, keyGetter) => {
	const map = new Map();
	list.forEach(item => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
};

const azureItemMap = v => ({ ...v, ResourceName: v.ResourceId });
const awsItemMap = v => v;

const azureItemFilter = (item, x) => x.ResourceId === item.ResourceId;
const awsItemFilter = (item, x) => x.ResourceName === item.ResourceName;

const azureItemCompare = (a, b) => a.ResourceId.localeCompare(b.ResourceId);
const awsItemCompare = (a, b) => a.ResourceName.localeCompare(b.ResourceName);

const useRemediationIssues = (issues, reportId, parentIssue) => {
	const [data, setData] = useState(new Map());
	const [showAll, setShowAll] = useState(false);
	const [busy, setBusy] = useState(false);
	const [logs, setLogs] = useState([]);

	const itemMap = isAzure() ? azureItemMap : awsItemMap;
	const itemFilter = isAzure() ? azureItemFilter : awsItemFilter;
	const itemSort = isAzure() ? azureItemCompare : awsItemCompare;

	useEffect(() => {
		setBusy(true);

		const filteredissues = issues.filter(
			i =>
				i.SecurityTestRule !== 's3-bucket-server-side-encryption-enabled' &&
				i.SecurityTestRule !== 's3-data-encryption-is-enabled'
		);
		// firing these rules will only overwrite some fixes.
		// So Saurav decided to fire only s3-default-encryption-kms for s3 resources

		const groupedResources = groupBy(filteredissues, x => x.ResourceType);

		const resourceKeys = [...groupedResources.keys()];

		resourceKeys.forEach(key => {
			const value = groupedResources.get(key);
			let resources = [];

			value.forEach(v => {
				const found = resources.find(
					r =>
						r.ResourceArn === v.ResourceArn &&
						r.ResourceName === v.ResourceName &&
						r.ResourceType === v.ResourceType
				);
				if (!found) {
					var item = itemMap(v);

					const indexFound = resources.findIndex(x => itemFilter(item, x));

					if (indexFound < 0) {
						resources.push({
							...item,
							SecurityTestRule: [item.SecurityTestRule],
						});
					}
				} else {
					found.SecurityTestRule.push(v.SecurityTestRule);
				}
			});

			console.log('before sort', resources);
			resources = resources.sort(itemSort);
			console.log('after sort', resources);
			groupedResources.set(key, resources);
		});

		setData(groupedResources);

		getFixLogs(reportId).then(logs => {
			setLogs(logs.data);
			setBusy(false);
		});
	}, []);

	const saveLogs = (logEntry, key) => {
		let logBucket = logs.find(l => l.key === key && l.parent === parentIssue);
		if (!logBucket) {
			logBucket = { key, parent: parentIssue, logs: [] };
			logs.push(logBucket);
		}
		logBucket.logs.push(logEntry);
		saveFixLogs(reportId, logs).then(logs => {
			setLogs(logs.data);
		});
	};

	return {
		data,
		empty: data.size === 0,
		showAll,
		setShowAll,
		saveLogs,
		logs,
		busy,
	};
};

export { useRemediationIssues };
