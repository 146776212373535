import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { convertToInternationalCurrencySystem } from '../Common/Formatter';
import { getDataPoint } from './Helpers';
import { Trans } from 'react-i18next';

const severityIndex = ['Low', 'Medium', 'High', 'Critical'];

//options : https://apexcharts.com/docs/options/legend/
//https://apexcharts.com/react-chart-demos/treemap-charts/treemap-multiple-series/
//https://apexcharts.com/docs/options/plotoptions/treemap/
//https://github.com/apexcharts/apexcharts.js

const getBubbleData = data => {
	if (!data) return false;

	const potentialLoss = data.map(v => Number(v['PotentialLoss']));
	const sorted = potentialLoss.sort((a, b) => a - b);
	const lowMiddle = Math.floor((sorted.length - 1) / 2);
	const highMiddle = Math.ceil((sorted.length - 1) / 2);
	const median = (sorted[lowMiddle] + sorted[highMiddle]) / 2;
	const maxLoss = Math.max(...potentialLoss);
	const minLoss = Math.min(...potentialLoss);

	const sortedData = data.sort((a, b) => a.Severity < b.Severity);

	const mapFn = v => {
		return {
			x: severityIndex.indexOf(v['Severity']),
			y: Number(v['PotentialLoss']),
			rule: v['SecurityTestRule'],
			severity: v['Severity'],
			percentage: v['Risk Percentage'],
			potentialLoss: `$ ${convertToInternationalCurrencySystem(
				Number(v['PotentialLoss'])
			)}`,
			count: v.VulnerabilityCount,
			maxLoss,
			minLoss,
			median,
			visited: false,
		};
	};

	//filter out potential loss values over 0
	const chartData = sortedData.map(mapFn).filter(v => v.y > 0);
	chartData.forEach(data => {
		if (!data.visited) {
			data.visited = true;
			const nearPoints = chartData.filter(
				c =>
					!c.visited &&
					c.severity === data.severity &&
					Math.abs(data.y - c.y) < 25000
			);
			let lastX = 0.05;
			nearPoints.forEach(p => {
				p.x = p.x + lastX;
				lastX = lastX + 0.05;
				p.visited = true;
			});
		}
	});
	return chartData;
};

const tooltipFormatter = ({ seriesIndex, dataPointIndex, w }) => {
	const dp = getDataPoint({ seriesIndex, dataPointIndex, w });

	const elem = (
		<div className="arrow_box">
			<div>
				<div>
					<Trans i18nKey="ransomwareDashboard.rule" />: {dp.rule}
				</div>
				<div>
					<Trans i18nKey="ransomwareDashboard.severity" />: {dp.severity}
				</div>
				<div>
					<Trans i18nKey="ransomwareDashboard.riskPercentage" />:{' '}
					{dp.percentage}%
				</div>
				<div>
					<Trans i18nKey="ransomwareDashboard.potentialLoss" />:{' '}
					{dp.potentialLoss}
				</div>
			</div>
		</div>
	);

	return ReactDOMServer.renderToString(elem);
};

const useScatter = ({ data, onClick }) => {
	const dataLabels = {
		enabled: false,
	};

	const tooltip = {
		custom: tooltipFormatter,
	};

	const formatYAxisLabel = value => `$ ${value}`;

	// https://apexcharts.com/docs/options/xaxis/
	const options = {
		chart: {
			toolbar: {
				show: false,
			},
			events: {
				markerClick: (_e, _ctx, { seriesIndex, dataPointIndex, w }) => {
					const dp = getDataPoint({ seriesIndex, dataPointIndex, w });

					onClick(dp);
				},
			},
			zoom: {
				enabled: false
			}
		},
		colors: ['#070707'],
		dataLabels,
		tooltip,
		xaxis: {
			type: 'numeric',
			labels: {
				show: true,

				formatter: value => {
					return value > 0 ? severityIndex[value] : '';
				},
				style: {
					fontSize: '0.9375em',
					fontWeight: 500,
					color: '#081554',
				},
				offsetY: -5,
			},
			max: 4,
			min: 0,
			tickAmount: 4,
			title: {
				text: 'Severity', 
				style: {
					color: [],
					fontSize: '1.1em',
					fontWeight: 500,
				},
				offsetX: 0,
				offsetY: 20,
				position: 'bottom',
				
			},
			// title: {
			// 	text: '.',
			// 	floating: true,
			// 	style: {
			// 		color: 'transparent',
			// 	},
			// },
			tooltip: {
				enabled: false,
			},
			axisTicks: {
				show: false,
			},
		},
		yaxis: {
			labels: {
				show: true,
				formatter: formatYAxisLabel,
				style: {
					colors: [],
					fontSize: '0.9375em',
					fontWeight: 500,
					color: '#081554',
				},
			},
			title: {
				text: 'Potential Loss ',
				rotate: -90,
				style: {
					color: [],
					fontSize: '1em',
					fontWeight: 500,
				},
			},
		},
		markers: {
			size: 5,
			strokeColors: '#fff',
			hover: {
				sizeOffset: 3,
			},
		},
		legend: {
			show: true,
			position: 'bottom',
			horizontalAlign: 'center',
		},
	};

	return {
		type: 'scatter',
		options,
		series: [
			{
				name: 'Account Risk Exposure',
				data: getBubbleData(data),
			},
		],
	};
};

export { useScatter };
