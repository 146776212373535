import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

export default () => {
	return (
		<Card className="dashboard-card">
			<CardHeader>
				<CardTitle>
					<h5>{t('ransomwareDashboard.runAssessment')}</h5>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{t('ransomwareDashboard.noData')}
				{''}
				<a href="/ransomware/assessment/info">{t('riskAssessment.title')}</a>.
			</CardBody>
		</Card>
	);
};
