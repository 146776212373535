import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSyncResults } from '../../tests/AutomatedTests/ResultService';
import {
	COMPLIANCE_TYPES,
	TALASMART_QUERY_TYPES,
	formatPageData,
} from '../constants';
import { getCurrentAccountId } from '../../../services/AuthService';
import {
	talaSmartGetResourceInfo,
	talaSmartQuery,
	talaSmartSetResourceInfo,
} from '../../../apiclients/SmartQueries';
import {
	getCompliantResourceList,
	getIssueList,
} from '../../../apiclients/Ransomware';

import { getUserType } from '../../../services/AuthService';
import { CloudTypes } from '../../../constants/CloudInfo';

import { getLogIssueDetails } from '../../../apiclients/Onprem';

const useTalasmartK8SLogIssue = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const navigate = useNavigate();

	const [issueId, setIssueId] = useState(null);

	const [pack, setPack] = useState(null);
	const [reportName, setReportName] = useState(null);
	const [resourceId, setResourceId] = useState(null);
	const [rawResourceId, setRawResourceId] = useState(null);
	const [resourceType, setResourceType] = useState('');
	const [securityTestRule, setSecurityTestRule] = useState('');
	const [rootCause, setRootCause] = useState('');
	const [info, setInfo] = useState(null);
	const [evalId, setEvalId] = useState(null);
	const [numFragments, setNumFragments] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [resultLoaded, setResultLoaded] = useState(false);
	const [results, setResults] = useState({ counts: { total: 0 }, rows: [] });
	const [error] = useState(false);
	const [pageData, setPageData] = useState({});
	const [resourceInfo, setResourceInfo] = useState({
		SeverityOverride: '',
		Justification: '',
		Notes: '',
	});
	const [remediationInfo, setRemediationInfo] = useState('');
	const [isRemediationLoading, setIsRemediationLoading] = useState(false);

	const report = useMemo(() => {
		const reportInfo = {
			PackName: pack,
			ConformancePack: pack,
			EvalId: evalId,
			Fragments: numFragments,
			ReportId: reportName,
		};
		return reportInfo;
	}, [evalId, numFragments, pack, reportName]);

	const fetchPageData = useCallback(async () => {
		
			const body = {
				// dummy info for headers
				reportInfo: {
					ReportId: "none",
					PackName: "none",
					Fragments: 0
				},
				K8SLogQuery: "k8slog",
				LogID: issueId
			};

			console.log("***body:", body);

			await talaSmartQuery(
				{
					...body,
					Query: TALASMART_QUERY_TYPES.title,
				},
				v => onSuccess('title', v)
			);

			await talaSmartQuery(
				{
					...body,
					Query: TALASMART_QUERY_TYPES.description,
				},
				v => onSuccess('description', v)
			);

			await talaSmartQuery(
				{
					...body,
					Query: TALASMART_QUERY_TYPES.summary,
				},
				v => onSuccess('summary', v)
			);

			setIsRemediationLoading(true);

			await talaSmartQuery(
				{
					...body,
					Query: TALASMART_QUERY_TYPES.resolvedManifest
				},
				(response) => {
					setRemediationInfo(response);
					setIsRemediationLoading(false);
				},
				() => setIsRemediationLoading(false)
			);
	}, [securityTestRule, resourceType, resourceId, rootCause, issueId]);

	const issueDetailsSuccess = res => {
		console.log('Issue Details:', res);
		setResourceId(res.data.resource_id);
		const newInfo = {
			SecurityTestRule: null,
			ResourceType: res.data.resource_info.ResourceType,
			ResourceId: res.data.resource_id,
			ResourceName: res.data.resource_info.ResourceID,
			ResourceNamespace: res.data.resource_info.Metadata.metadata.namespace,
			Labels: res.data.resource_info.Metadata.metadata.labels,
			LogLine: res.data.logline
		};
		console.log("New Info:", newInfo);
		setInfo(newInfo);
		
		setResultLoaded(true);

	};
	
	useEffect(() => {
		setIssueId(queryParams.get('id'));
		/*
		setPack(queryParams.get('pack'));
		setReportName(queryParams.get('reportName'));
		setResourceId(queryParams.get('resourceId'));
		setResourceType(queryParams.get('resourceType'))
		setSecurityTestRule(queryParams.get('securityTestRule'))
		setRootCause(queryParams.get('rootCause'))
		setNumFragments(queryParams.get('numFragments'));
		setEvalId(queryParams.get('evalId'));
		setRawResourceId(queryParams.get('rawResourceId', ""));
		*/
	}, [queryParams]);

	useEffect(() => {
		(async () => {
			console.log('Getting info for issue id:', issueId);
			if (!issueId) {
				return;
			}
			getLogIssueDetails(issueId, issueDetailsSuccess);
		})();
	}, [issueId]);

	useEffect(() => {
		(async () => {
			if (!resultLoaded) return;

			setLoaded(false);
			await fetchPageData();
			setLoaded(true);
		})();
	}, [fetchPageData, resultLoaded]);

	const onChangeResource = async resource => {
		const queryParams = new URLSearchParams();

		// report info
		queryParams.set('pack', pack);
		queryParams.set('reportName', reportName);
		queryParams.set('evalId', evalId);
		queryParams.set('numFragments', numFragments);
		// resource info
		queryParams.set('resourceId', resource.ResourceId);
		queryParams.set('resourceType', resource.ResourceType);
		queryParams.set('securityTestRule', resource.SecurityTestRule);
		queryParams.set('rootCause', resource.RootCause);

		const queryString = queryParams.toString();
		navigate(`?${queryString}`);

		setResourceId(resource.ResourceId);
	};

	const onCopy = () => {
		const data = formatPageData(info);
		navigator.clipboard.writeText(data);
	};

	const onDownload = () => {
		const data = formatPageData(info);
		const filename = `Resource_info_${resourceId}`;
		const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
		if (window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveBlob(blob, filename);
		} else {
			const elem = window.document.createElement('a');
			elem.href = window.URL.createObjectURL(blob);
			elem.download = filename;
			document.body.appendChild(elem);
			elem.click();
			document.body.removeChild(elem);
		}
	};

	const onSuccess = (field, value) => {
		setPageData(v => ({ ...v, [field]: value }));
	};

	const onBlurField = async () => {
		const formData = new FormData();
		Object.keys(resourceInfo).forEach(key => {
			formData.append(key, resourceInfo[key]);
		});
		formData.append('AccountID', getCurrentAccountId());
		formData.append('ResourceID', resourceId);

		await talaSmartSetResourceInfo(formData);
	};

	const onChangeField = (field, value) => {
		setResourceInfo(v => ({ ...v, [field]: value }));
	};

	const onChangeRemediationTab = async tab => {
		const body = {
			reportInfo: report,
			AccountID: getCurrentAccountId(),
			ResourceID: resourceId,
			ResourceType: info?.ResourceType,
			SecurityTestRule: info?.SecurityTestRule,
			RootCause: info?.RootCause,
			RawResourceId: rawResourceId,
		};

		setIsRemediationLoading(true);
		return await talaSmartQuery(
			{
				...body,
				Query: tab,
			},
			response => {
				setRemediationInfo(response);
				setIsRemediationLoading(false);
			},
			() => setIsRemediationLoading(false)
		);
	};

	return {
		info,
		results,
		loaded,
		error,
		pageData,
		resourceInfo,
		remediationInfo,
		resourceId,
		setRemediationInfo,
		onChangeResource,
		onCopy,
		onDownload,
		onChangeField,
		onBlurField,
		onChangeRemediationTab,
		isRemediationLoading,
	};
};

export default useTalasmartK8SLogIssue;
