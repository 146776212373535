import React from 'react';
import {
    useReactTable, getCoreRowModel, getPaginationRowModel, getFilteredRowModel, getSortedRowModel,
} from '@tanstack/react-table';
import { COLUMNS, DEFAULT_SORTED, TABS, fileName } from './constants';
import TableHeader from './TableHeader';
import { BTable } from '../../../components/BTable';

const ResourcesTable = ({ showModal, data, headerProps }) => {
    const HeaderComponent = TableHeader.resources;

    const table = useReactTable({
        data: data || [], // Ensure data is defined
        columns: COLUMNS[TABS.RESOURCES] || [], // Ensure columns are defined
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },

        }
    });

    return (
        <div id="resultTableContainer">
            <HeaderComponent
                {...headerProps}
                baseProps={{ data, table }} // Pass data to baseProps
                columns={COLUMNS[TABS.RESOURCES]} // Pass columns
                rowEvents={{
                    onClick: (e, row) => {
                        showModal(row);
                    },
                }}
                activeTab={TABS.RESOURCES}
                csvFileName={fileName[TABS.RESOURCES]?.(headerProps)}
            />
            <BTable table={table} pageSizes={[10, 20, 50]} />
        </div>
    );
};

export default ResourcesTable;