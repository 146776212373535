
import React from 'react';
import { Trans } from 'react-i18next';
import { convertToInternationalCurrencySystem } from './Formatter';

import SeverityBadge from '../../tests/Common/Controls/SeverityBadge';

const textClass = info => {
    const val = info.Severity;
    if (val === 'Critical' || val === 'High') {
        return 'text-danger';
    } else if (val === 'Medium') {
        return 'text-warning';
    } else if (val === 'Low') {
        return 'text-success';
    } else return '';
};

const largeHeaderStyle = {
    width: '30%',
    textAlign: 'left',
};

const headerStyle = {
    textAlign: 'center',
};

const columns = [
    {
        accessorKey: 'SecurityTestRule',
        header: <Trans i18nKey="vulnerabilities.identified" />,
        sort: true,
    },
    {
        accessorKey: 'VulnerabilityCount',
        header: <Trans i18nKey="vulnerabilities.count" />,
        sort: true,
        align: 'center',
    },
    {
        accessorKey: 'Severity',
        header: <Trans i18nKey="vulnerabilities.severity" />,
        sort: true,
        align: 'center',
		cell: info => (
            <SeverityBadge severity={info.getValue()} />
        ),
    },
    {
        accessorKey: 'PotentialLoss',
        header: <Trans i18nKey="vulnerabilities.loss" />,
        sort: true,
        align: 'center',
		cell: info => (
            <div>$ {convertToInternationalCurrencySystem(info.getValue())}</div>
        ),
    },
    {
        accessorKey: 'RiskPercentage',
        header: <Trans i18nKey="vulnerabilities.percentage" />,
        sort: true,
        align: 'center',
        cell: info => {
            const riskPercentage = info.row.original["Risk Percentage"] ?? ''; 
        
            let textColorClass = ''; 
        
           
            if (riskPercentage >= 0 && riskPercentage <= 33) {
                textColorClass = 'text-success';
            } else if (riskPercentage >= 34 && riskPercentage <= 66) {
                textColorClass = 'text-warning';
            } else if (riskPercentage >= 67 && riskPercentage <= 100) {
                textColorClass = 'text-danger';
            }
        
            
            return <h5 className={textColorClass}>{riskPercentage} %</h5>;
        }
       
    },
];

const defaultSorted = [
    {
        field: 'Severity',
        order: 'asc',
    },
];

export { columns, defaultSorted };