import {
	Card,
	CardBody,
	CardHeader,
	CardTitle
} from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";

import Loader from "../../../../components/Loader";
import PrintContext from "../Common/PrintContext";
import TopologyControl from "../../../Topology/Controls/TopologyControl";

//http://localhost:17080/print/NISTCSF/Report_NISTCSF_1608610607870
//https://js.cytoscape.org/#core/export

const imgId = "compliance-image",
	chartDim = { h: 650, w: 800 },
	hideLoader = () => {
		document.getElementById("loader").style.display = "none";
		document.getElementById(imgId).style.display = "";
	};

const TopologyCompliance = () => {

	const { client, report, counter, topology } = useContext(PrintContext)
	const [nodes, setNodes] = topology;

	const [loaded, setLoaded] = useState(false);
	const [failed, setFailed] = useState(false);

	const ref = useRef();

	const generated = () => {
		ref.current.setImage();
		counter.increment();
		hideLoader();
	};
	const success = nodes => {
		setNodes(nodes);
		setLoaded(true);
		setTimeout(generated, 1000);
	};

	const error = e => { setFailed(true); setLoaded(true); counter.increment(); console.error("An error occurred?", e); };

	const loadTopology = () => client.getliveEnvironmentCompliance(report, success, error);

	useEffect(loadTopology, []);

	return (
		<Card className="d-flex">
			<CardHeader>
				<CardTitle><h3>NETWORK TOPOLOGY DIAGRAM</h3></CardTitle>
			</CardHeader>
			<CardBody>
				{failed && <h2 className="text-danger">An error occurred while loading the topology. </h2>}
				{!loaded && <Loader />}
				{loaded && !failed &&
					<>
						<div className="d-none" id="print-topology-wrapper" style={{ overflow: 'hidden', height: '0' }}>
							<TopologyControl ref={ref} chartDim={chartDim} nodes={nodes} imageSelector={imgId} />
						</div>
						<div className="spinner-border text-primary mx-auto" role="status" id="loader">
							<span className="sr-only">Loading...</span>
						</div>
						<img id={imgId} alt="Network Compliance" style={{ display: 'none' }} />
						<div className="mt-6">
							<strong className="text-danger"><em>Note: Services/components marked in RED are Non Compliant</em></strong>
						</div>
					</>
				}
			</CardBody>
		</Card>
	);
}

export default TopologyCompliance;