import Expander from './Expander';
import NoData from './NoData';
import React from 'react';
import ResourceRow from './ResourceRow';
import { useRemediationIssues } from '../../../Hooks/useRemediationIssues';
import { t } from 'i18next';

const IssuesGrid = ({
	issues,
	fixStatus,
	onFix,
	onRollback,
	reportId,
	parentIssue,
}) => {
	const { data, empty, showAll, setShowAll, logs, saveLogs, busy } =
		useRemediationIssues(issues, reportId, parentIssue);

	const resourceKeys = [...data.keys()];

	return (
		<>
			{empty && !busy && <NoData />}
			{!empty && !busy && (
				<table className="table table-striped">
					<thead className="">
						<tr>
							<th scope="col">
								<Expander expanded={showAll} onExpand={setShowAll} />
							</th>
							<th scope="col" className="text-end">
								{t('table.resourceType')}
							</th>
							<th scope="col" style={{ width: '10%' }}>
								{t('table.action')}
							</th>
							<th scope="col" className="text-center" style={{ width: '15%' }}>
								{t('table.fixedUnfixed')}
							</th>
							<th scope="col" className="text-center" style={{ width: '35%' }}>
								{t('table.resolutionHistory')}{' '}
							</th>
						</tr>
					</thead>
					<tbody>
						{resourceKeys.map((v, index) => (
							<ResourceRow
								logs={
									(
										logs.find(l => l.key === v && l.parent === parentIssue) ?? {
											logs: [],
										}
									).logs
								}
								saveLogs={saveLogs}
								index={index}
								key={index}
								item={data.get(v)}
								fixStatus={fixStatus}
								type={v}
								showAll={showAll}
								saveFix={onFix}
								saveRollback={onRollback}
							/>
						))}
					</tbody>
				</table>
			)}
		</>
	);
};

export default IssuesGrid;
