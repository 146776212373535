import { NavItem, NavLink } from 'reactstrap';

import React from "react";

export default function ({ testCase, isActive, onClick }) {

	return <>
		<NavItem>
			<NavLink
				onClick={() => onClick(testCase.id)}
				active={isActive}
				href="#">
				{testCase.value}
			</NavLink>
		</NavItem>
	</>
}