import classNames from 'classnames';
import React from 'react';
import { Container, Row, Spinner } from 'reactstrap';

const Loader = ({ className, isFull = true }) => (
	<Container
		fluid
		className={classNames('d-flex', {
			[className]: className,
			'vh-75': isFull,
		})}>
		<Row className="d-flex justify-content-center align-self-center w-100 text-center">
			<Spinner color="primary" />
			<h1></h1>
		</Row>
	</Container>
);

Loader.defaultProps = {
	className: '',
};

export default Loader;
