import React, { useState } from "react";

import Input from "reactstrap/lib/Input";
import { Label } from "reactstrap";

const useValues = (values, onValueSelect) => {
  const [value, setValue] = useState(values[0].value);

  const onChange = e => {

    const index = e.target.selectedIndex;
    if (index > 0) {
      const id = Number(e.target.options[index].value);
      setValue(id);
      onValueSelect(values[index - 1]);
    }
  };

  return { value, onChange };
}

const ReviewScanDropdown = ({ name, label, values, onSelect, value }) => {

  const nameDdl = `${name}Ddl`;

  const hook = useValues(values, onSelect);

  return <>
    <Label>
      {label}
    </Label>
    <Input
      type="select"
      name={nameDdl}
      onChange={hook.onChange}
      value={value}>
      {values.map(v =>
        <option key={v.id} value={v.id}>{v.value}</option>
      )};
    </Input>
  </>;
}

export default ReviewScanDropdown;