import { t } from 'i18next';
import React from 'react';

export default function () {
	return (
		<div>
			<div>
				<p className="font-weight-normal">{t('testReports.pci')}</p>
				<p className="font-weight-normal">{t('testReports.pciDescription')}</p>
			</div>

			<div>
				<h4 className="text-uppercase">
					{t('testReports.pciRequirementsTitle')}
				</h4>
				<p className="font-weight-normal">
					{t('testReports.pciRequirementsDescription')}
				</p>
			</div>

			<div>
				<p className="font-weight-normal">{t('testReports.pciListTitle')}</p>
			</div>
			<ol start="1">
				<li>{t('testReports.pciReq1')}</li>
				<li>{t('testReports.pciReq2')}</li>
				<li>{t('testReports.pciReq3')}</li>
				<li>{t('testReports.pciReq4')}</li>
				<li>{t('testReports.pciReq5')}</li>
				<li>{t('testReports.pciReq6')}</li>
				<li>{t('testReports.pciReq7')}</li>
				<li>{t('testReports.pciReq8')}</li>
				<li>{t('testReports.pciReq9')}</li>
				<li>{t('testReports.pciReq10')}</li>
				<li>{t('testReports.pciReq11')}</li>
				<li>{t('testReports.pciReq12')}</li>
			</ol>
		</div>
	);
}
