import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const items = [
	{ text: 'reportsDashboard.aboutRansomware', page: 3 },
	{ text: 'reportsDashboard.lossAndRisk', page: 4 },
	{ text: 'reportsDashboard.networkTopology', page: 6 },
	{ text: 'reportsDashboard.ransomwareResults', page: 7 },
];

const Contents = () => (
	<Card>
		<CardHeader>
			<CardTitle>
				<h3 className="text-uppercase">
					{t('reportsDashboard.tableOfContents')}
				</h3>
			</CardTitle>
		</CardHeader>
		<CardBody>
			<Container fluid>
				<table cellPadding="30">
					<tbody>
						{items.map(item => (
							<tr key={item.page}>
								<td>{t(item.text)}</td>
								<td>{item.page}</td>
							</tr>
						))}
					</tbody>
				</table>
			</Container>
		</CardBody>
	</Card>
);

export default Contents;
