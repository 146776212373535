import {
	Col,
	Container,
	Row
} from "reactstrap"
import { ComplianceDropdown, SeverityDropdown } from "../Dropdown";
import { Observation, OrgControls } from "../TextInput";

import CategoryFooter from "../CategoryFooter";
import ErrorAlert from "./ErrorAlert";
import Evidence from "../Evidence";
import React from "react";
import useForm from "./useForm";

export default function (props) {

	const vm = useForm(props);

	return <>
		<Container className="ms-2">

			<Row>
				<Col>
					{vm.error && <ErrorAlert reasons={vm.errorReason} />}
				</Col>
			</Row>

			<Row>
				<Col>
					<OrgControls
						value={vm.orgControls}
						onChange={vm.onChange}
						onError={vm.onError}
						isModified={vm.modified}
					/>
				</Col>

				<Col className="border-left border-light">
					<Row className="mb-2 ms-1">
						<Col>
							<ComplianceDropdown
								onChange={vm.onChange}
								value={vm.result.value}
								onError={vm.onError}
								isModified={vm.modified}
							/>
						</Col>
						<Col>
							<SeverityDropdown
								disabled={vm.disableSeverity}
								onChange={vm.onChange}
								required={vm.severityRequired}
								value={vm.severityValue}
								onError={vm.onError}
								isModified={vm.modified}
							/>
						</Col>
					</Row>

					<Row className="ms-1 mt-4">
						<Evidence
							value={vm.evidence}
							onChange={vm.onChange}
							onError={vm.onError}
							isModified={vm.modified}
						/>
					</Row>
				</Col>
			</Row>

			<Row className="mt-3">
				<Col>
					<Observation
						value={vm.observation}
						onChange={vm.onChange}
						onError={vm.onError}
						error={vm.error}
						isModified={vm.modified} />
				</Col>
			</Row>
		</Container>
		<Container>
			<CategoryFooter
				testCase={vm.selectedTestCase}
				onSaveDraft={props.navState.onSaveDraft}
				onSaveReport={props.navState.onSaveReport}
			/>
		</Container>
	</>;
}