import { Card, CardBody, CardHeader, Input, Row } from 'reactstrap';
import React, { useState, useEffect, useMemo } from 'react';
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	getPaginationRowModel,
	getFilteredRowModel,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { evaluationHistoryColumns } from '../constants';
import Loader from '../../../../components/Loader'; // Враховуйте правильний шлях до вашого компонента Loader
import { BTable } from '../../../../components/BTable';

const cardHeaderStyles = {
	paddingTop: '1.875rem',
	marginLeft: '10px',
	marginRight: '10px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
};

const PAGE_SIZES = [5, 10, 15];

const EvaluationHistory = ({ data }) => {
	const { t } = useTranslation();
	const [tableData, setTableData] = useState([]);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (data) {
			setTableData(data);
			setLoaded(true);
		}
	}, [data]);

	const columns = evaluationHistoryColumns.map(column => ({
		...column,
	}));

	const handleSearch = (table, searchval) => {
		table.setGlobalFilter(searchval);
	};

	const table = useReactTable({
		data: tableData,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		initialState: {
			pagination: { pageIndex: 0, pageSize: PAGE_SIZES[0] },
			sorting: [{ id: 'RunDateTime', desc: true }],
		},
	});

	return (
		<Card className="dashboard-card">
			<CardHeader style={cardHeaderStyles}>
				<h5 className="dashboard-chart-title">
					{t('ransomwareDashboard.evaluationHistory')}
				</h5>
				<Row className="gap-1">
					<Input
						type="text"
						placeholder={t('ransomwareDashboard.searchByReportId')}
						onChange={e => {
							handleSearch(table, e.target.value);
						}}
						style={{ width: '200px', marginLeft: '1rem' }}
					/>
				</Row>
			</CardHeader>
			<CardBody>
				{!loaded && <Loader />}
				{loaded && <BTable table={table} pageSizes={PAGE_SIZES} />}
			</CardBody>
		</Card>
	);
};

export default EvaluationHistory;
