import { PackKeys } from "../../../../../constants/PackKeys";
import React from "react";

const packLabels = {
	[PackKeys.NIST]: () => "",
	[PackKeys.SOC2]: () => "Point of Focus : ",
	[PackKeys.HIPAASECURITY]: () => '',
	[PackKeys.PCIDSS]: sc => `${sc.name} : `,
	[PackKeys.CMMCL3]: sc => `${sc.name} : `
}

const PageButton = ({ id, index, active, error, onClick }) => {


	const isActive = index === active;

	let btnClass = '';

	if (isActive && error) {
		btnClass = 'btn-danger';
	} else if (isActive) {
		btnClass = 'btn-primary';
	} else if (error) {
		btnClass = 'btn-outline-danger';
	} else {
		btnClass = 'btn-outline-primary';
	}

	const className = `me-1 btn btn-sm ${btnClass}`;

	return <button className={className} onClick={() => onClick(id, index)}>{id}</button>
}

export default function ({ ids, selected, error, subCategory, pack, onClick }) {

	return <>
		<div className="container">
			<div className="border-top border-light my-2 mb-2"></div>
			<div className="btn-toolbar mt-3" role="toolbar">
				{ids.map((id, index) =>
					<PageButton
						key={id}
						id={id}
						index={index}
						active={selected}
						error={error[id]}
						onClick={onClick} />
				)}
			</div>
			<div className="row mb-2 mt-3 ms-1">
				<h5>{packLabels[pack](subCategory)}{subCategory.description}</h5>
			</div>
			<div className="border-top border-light my-2"></div>
		</div>
	</>
}