import React, { useState, useMemo } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Trans } from 'react-i18next';
import { Card, CardBody, Button, Table, Row, Col, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import RadioButton from '../../../../components/Form/RadioButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsAuthenticated, setUser } from '../../../../redux/actions/applicationActions';
import useLoginWithKey from '../../../auth/hooks/useLoginWithKey';
import { setUserDefaults } from '../../../../apiclients/EmailLogin';
import { ReactComponent as ChevronSvg } from '../../../../assets/img/chevron.svg';

const formatter = value => value || '-';

const defaultKeyFormatter = (login, onSetDefault, item) => {
  const data = {
    keyId: item.id,
    domain: item.domain,
    region: item.providerInfo?.regions?.[0],
    regions: [],
    provider: item.provider,
  };

  const onUse = e => {
    e.stopPropagation();
    login(data);
  };

  const onDefaultChange = e => {
    e.stopPropagation();
    const data = {
      keyId: item?.id,
      region: item.providerInfo?.regions?.[0],
      domain: item.domain,
      provider: item.provider,
    };
    onSetDefault(data);
  };

  return (
    <div className="admin-table__actions">
      <RadioButton
        onChange={onDefaultChange}
        isSelected={item.isDefault}
        variant="dark"
      />
      <Button onClick={onUse}>
        <Trans i18nKey="button.useKey" />
      </Button>
    </div>
  );
};

const headerStyle = {
  textAlign: 'left',
  fontSize: '0.9375em',
};

const getColumns = (onLogin, onSetDefault) => [
  {
    accessorKey: 'select',
    header: ({ table }) => (
      <input
        type="checkbox"
        checked={table.getIsAllPageRowsSelected()}
        onChange={table.getToggleAllPageRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <input
        type="checkbox"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  },
  {
    accessorKey: 'name',
    header: <Trans i18nKey="table.accountAlias" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'providerInfo.accountName',
    header: <Trans i18nKey="table.accountName" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'providerInfo.accessKey',
    header: <Trans i18nKey="table.accessKey" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'providerInfo.regions[0]',
    header: <Trans i18nKey="table.region" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'providerInfo.accountNumber',
    header: <Trans i18nKey="table.awsAccount" />,
    cell: info => formatter(info.getValue()),
    headerStyle,
  },
  {
    accessorKey: 'id',
    header: <Trans i18nKey="table.defaultKey" />,
    cell: info => defaultKeyFormatter(onLogin, onSetDefault, info.row.original),
    headerStyle,
  },
];

const PageButtons = ({ table }) => {
  const curPage = table.getState().pagination.pageIndex + 1;
  const maxPage = table.getPageCount();
  return (
    <>
      <Button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
        {'<<'}
      </Button>
      <Button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
        {'<'}
      </Button>
      <span>
        {' '}
        Page: {curPage} / {maxPage}{' '}
      </span>
      <Button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
        {'>'}
      </Button>
      <Button onClick={() => table.setPageIndex(maxPage - 1)} disabled={!table.getCanNextPage()}>
        {'>>'}
      </Button>
    </>
  );
};

const KeyTable = ({ data, token, onClick, info, pageSizes = [7, 14, 21] }) => {
  const login = useLoginWithKey();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageLen, setPageLen] = useState(pageSizes[0]);
  const [pageLenOpen, setPageLenOpen] = useState(false);
  const togglePageLen = () => setPageLenOpen(!pageLenOpen);

  const setPageSize = val => {
    setPageLen(val);
    table.setPageSize(Number(val));
  };

  const loginError = (res, token, failedKeyId) => {
    navigate(`/admin/remote-landing?token=${token}&failedKeyId=${failedKeyId}`);
  };

  const onLogin = data => {
    const formatData = { ...data, token };
    dispatch(setIsAuthenticated(true));
    dispatch(setUser(token));
    login(formatData, res => {
      loginError(res, token, formatData.keyId);
    });
  };

  const formatData = useMemo(() => {
    return data?.map(item => ({
      ...item,
      isDefault: info?.defaultKey?.keyId === item.id,
    }));
  }, [data, info.defaultKey]);

  const onSetDefault = data => {
    setUserDefaults(
      {
        defaultKey: data,
      },
      token,
      () => info.setDefaultKey(data),
      console.log
    );
  };

  const columns = useMemo(() => getColumns(onLogin, onSetDefault), [onLogin, onSetDefault]);

  const table = useReactTable({
    data: formatData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: pageLen,
      },
    },
  });

  return (
    <Card className="dashboard-card">
      <CardBody>
        <Table className="evaluation-table evaluation-table-wrapper">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        <Row>
          <Col xs="4">
            <Dropdown
              isOpen={pageLenOpen}
              toggle={togglePageLen}
              className="position-relative"
            >
              <DropdownToggle size="sm">
                <span style={{ margin: '5px' }}>
                  {pageSizes.find(option => option === pageLen) ? pageLen : '-'}
                </span>
                <ChevronSvg className="dropdown-arrow" />
              </DropdownToggle>
              <DropdownMenu>
                {pageSizes.map(val => (
                  <DropdownItem key={val} onClick={() => setPageSize(val)}>
                    {val}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col xs="4"></Col>
          <Col xs="4">
            <PageButtons table={table} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default KeyTable;
