import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';

import PrintContext from '../Common/PrintContext';
import { useTranslation } from 'react-i18next';

const formattedDate = () => {
	const date = new Date();

	const zeroPad = text => ('0' + text).slice(-2),
		dt = zeroPad(date.getDate()),
		month = zeroPad(date.getMonth() + 1),
		seconds = zeroPad(date.getSeconds()),
		minutes = zeroPad(date.getMinutes()),
		hours = zeroPad(date.getHours());

	return `${date.getFullYear()}/${month}/${dt} - (${hours}:${minutes}:${seconds})`;
};

export default function ({ details }) {
	const { t } = useTranslation();
	//since the control re-renders when the data fetch is complete, the date can change.
	//the useEffect ensures that the date is set only once.
	const [date, setDate] = useState('');
	const { auditor } = useContext(PrintContext);
	const auditorSet = auditor.name.length > 0;

	useEffect(() => {
		setDate(formattedDate());
	}, []);

	return (
		<Card className="text-center">
			<CardHeader style={{ marginTop: '10rem' }}>
				<CardTitle>
					<h1>{details.pageTitle}</h1>
				</CardTitle>
				<h3>{details.subTitle}</h3>
			</CardHeader>
			<CardBody style={{ marginTop: '3rem', marginBottom: '10rem' }}>
				<div>
					<h3>{date}</h3>
					{auditorSet && (
						<h4> {t('testReports.auditedBy', { name: auditor.name })}</h4>
					)}
				</div>
			</CardBody>
		</Card>
	);
}