const defaultOptions = {
	disasterRecovery: {
		enabled: true,
		config: 0,
		softwareName: '',
		licenseARN: '',
		seller: '',
		recoveryTimeUnit: '4',
		recoveryPointUnit: '4',
		drill: true,
		drillFrequency: '1',
		objective: true,
		regions: '',
	},
	businessContinuity: {
		enabled: true,
		employeeAware: true,
		backupEnabled: true,
		backupFrequencyId: '3',
		retentionUnit: '4',
	},
	bringOwnDevice: {
		enabled: true,
		endpointSecurity: true,
		endPointSoftwareName: '',
		endPointLicenseARN: '',
		endPointSeller: '',
		mobileDatamanagement: true,
		mdmSoftwareName: '',
		mdmLicenseARN: '',
		mdmSeller: '',
	},
	browserManagement: {
		security: true,
		dns: true,
		phishing: true,
		email: true,
		drill: true,
	},
	incidenceMonitoring: {
		enabled: true,
		softwareName: '',
		licenseARN: '',
		seller: '',
		vulnerability: true,
		vulnerabilityFrequency: '0',
		penetration: true,
		penetrationFrequency: '0',
		compliance: true,
		complianceFrequency: '0',
	},
	riskManagement: {
		businessImpact: true,
		criteria: true,
		inheritance: true,
		remediation: true,
	},
};

const azureKnownTypes = {
	Location: 'Location',
	'Microsoft.Compute/virtualMachines': 'VirtualMachines',
	'Microsoft.DBforMySQL/servers': 'MySQL Servers',
	'Microsoft.Network/virtualNetworks': 'VirtualNetworks',
	'Microsoft.Storage/storageAccounts': 'StorageAccounts',
	ResourceGroup: 'ResourceGroup',
	Subscription: 'Subscription',
	Tenant: 'Tenant',
	subnet: 'Subnet',
};

export { azureKnownTypes, defaultOptions };
