import React from 'react';
import classNames from 'classnames';
import { ReactComponent as ChevronSvg } from '../assets/img/chevron.svg';
import { Trans, useTranslation } from 'react-i18next';

const COMPLIANCE_TYPES = [
	{
		name: <Trans i18nKey="table.resourceId" />,
		field: 'ResourceId',
	},
	{
		name: <Trans i18nKey="table.complianceViolations" />,
		field: 'RootCause',
	},
	{
		name: <Trans i18nKey="table.resourceName" />,
		field: 'ResourceName',
	},
	{
		name: <Trans i18nKey="table.resourceType" />,
		field: 'ResourceType',
	},
];

const ComplianceInfo = ({ info, onOpen, isOpen, onClick }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="compliance-wrapper">
				<button
					className={classNames('text-danger compliance-trigger', {
						'compliance-trigger-open': isOpen,
					})}
					onClick={onOpen}>
					<div className="d-flex justify-content-between align-items-center gap-1">
						<span>{info?.SecurityTestRule}</span>
						<ChevronSvg className="compliance-arrow" />
					</div>
					<span className="compliance-trigger_span">
						{t(isOpen ? 'button.closeInfo' : 'button.seeInfo')}
					</span>
				</button>
			</div>
			{isOpen && (
				<div className="compliance-content mb-3">
					<h3 className="text-blue mt-0">{t('table.information')}</h3>
					<ul>
						{COMPLIANCE_TYPES.map(type =>
							info?.[type.field] ? (
								<li key={type.field}>
									<h3>{type.name}</h3>
									<p>{info?.[type.field]}</p>
								</li>
							) : null
						)}
					</ul>
					<button
						className="bg-transparent border-0 p-0 d-block mx-auto mt-3 text-blue my-10-step"
						onClick={() =>
							onClick({
								id: info.ResourceId || info.ResourceName,
								type: info.ResourceType,
								rule: info.SecurityTestRule,
								rcause: info.RootCause,
								info: info,
							})
						}>
						{t('button.showFullInfo')}
					</button>
				</div>
			)}
		</>
	);
};

export default ComplianceInfo;
