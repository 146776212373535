import { dropDownData } from "./Constants";

const
	change = {
		isSeverity: source => source === 'severity',
		isResult: source => source === 'result',
		isEvidence: source => source === 'evidence',
		isOrg: source => source === 'orgControls',
		isObservation: source => source === 'auditObservation',
		isSeverityRequired: (source, value, severityValue) => {
			const isNcResult = change.isResult(source) && is.nonCompliant(value.label);
			const isZero = isNcResult && severityValue === 0;
			const isSeveritySelect = change.isSeverity(source) && is.select(value.label);
			return isZero || isSeveritySelect;
		},
	},
	is = {
		compliant: text => text === 'Compliant',
		nonCompliant: text => text === 'Non-Compliant',
		compliantResult: x => x.value === 1,
		nonCompliantResult: (result, severity) => result.value === 2 && severity.value < 1,
		select: text => text === 'Select',
		hasModifiedProperty: item => item.length === 7,
		valid: item => is.hasModifiedProperty(item) && (item[6] && item[5]),
		invalid: item => is.hasModifiedProperty(item) && (item[6] && !item[5])
	},
	findItem = (data, item) => data.values.find(current => current.label === item) ?? data.values[0],
	map = {
		toSeverity: item => findItem(dropDownData.severity, item),
		toResult: item => findItem(dropDownData.compliance, item),
		toArray: item => [item.result, item.severity, item.evidence, item.observation, item.orgControls, item.error, item.modified],
		fromArray: item => {
			
			const isValid = item && item.length > 3;

			if(!isValid){
				item = [];
			}
			
			const mapped = {
				result: item[0] ?? "Select",
				severity: item[1] ?? "Select",
				evidence: item[2] ?? "",
				observation: item[3] ?? "",
				orgControls: item[4] ?? "",
				error: item[5] ?? false,
				modified: item[6] ?? false
			};

			return mapped;
		}
	}

export { change, is, map };