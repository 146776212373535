let index = 0;

const getIndex = () => index++;

const AwsTestsNIST =
{
	"referenceData": {
		"result": {
			"defaultValue": 2,
			"values": [{
					"value": 1,
					"label": "Compliant"
				},
				{
					"value": 2,
					"label": "Non-Compliant"
				}
			]
		},
		"severity": {
			"defaultValue": 3,
			"values": [{
					"value": 1,
					"label": "Low"
				},
				{
					"value": 2,
					"label": "Medium"
				},
				{
					"value": 3,
					"label": "High"
				},
				{
					"value": "4",
					"label": "Critical"
				}
			]
		},
		"categories": {
			"id.am": {
				"description": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization's risk strategy.",
				"name": "Asset Management (ID.AM)",
				"subcategories": ["id.am-2", "id.am-3", "id.am-6"]
			},
			"id.be": {
				"description": "The organization's mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
				"name": "Business Environment (ID.BE)",
				"subcategories": ["id.be-5"]
			},
			"id.ra": {
				"description": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
				"name": "Risk Assessment (ID.RA)",
				"subcategories": ["id.ra-1", "id.ra-2", "id.ra-3"]
			},
			"pr.ac": {
				"description": "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
				"name": "Identity Management, Authentication and Access Control (PR.AC)",
				"subcategories": ["pr.ac-1", "pr.ac-3", "pr.ac-4", "pr.ac-5", "pr.ac-6", "pr.ac-7"]
			},
			"pr.ds": {
				"description": "Information and records (data) are managed consistent with the organization's risk strategy to protect the confidentiality, integrity, and availability of information.",
				"name": "Data Security (PR.DS)",
				"subcategories": ["pr.ds-1", "pr.ds-2", "pr.ds-3", "pr.ds-4", "pr.ds-5","pr.ds-6"]
			},
			"pr.ip": {
				"description": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
				"name": "Information Protection Processes and Procedures (PR.IP)",
				"subcategories": ["pr.ip-1","pr.ip-3","pr.ip-4","pr.ip-7"]
			},
			"pr.ma": {
				"description": "Maintenance and repairs of industrial control and information system components are performed consistent with policies and procedures.",
				"name": "Maintenance (PR.MA)",
				"subcategories": ["pr.ma-2"]
			},
			"pr.pt": {
				"description": "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
				"name": "Protective Technology (PR.PT)",
				"subcategories": ["pr.pt-1","pr.pt-3","pr.pt-4","pr.pt-5"]
			},
			"de.ae": {
				"description": "Anomalous activity is detected and the potential impact of events is understood.",
				"name": "Anomalies and Events (DE.AE)",
				"subcategories": ["de.ae-1","de.ae-2","de.ae-3","de.ae-4","de.ae-5"]
			},
			"de.cm": {
				"description": "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
				"name": "Security Continuous Monitoring (DE.CM)",
				"subcategories": ["de.cm-1","de.cm-3","de.cm-4","de.cm-6","de.cm-7"]
			},
			"de.dp": {
				"description": "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
				"name": "Detection Processes (DE.DP)",
				"subcategories": ["de.dp-4","de.dp-5"]
			},
			"rs.an": {
				"description": "Analysis is conducted to ensure effective response and support recovery activities.",
				"name": "Analysis (RS.AN)",
				"subcategories": ["rs.an-2"]
			}
		},
		"subcategories": {
			"id.am-2": {
				"name": "ID.AM-2",
				"description": "Software platforms and applications within the organization are inventoried",
				index: getIndex()
			},
			"id.am-3": {
				"name": "ID.AM-3",
				"description": "Organizational communication and data flows are mapped",
				index: getIndex()
			},
			"id.am-6": {
				"name": "ID.AM-6",
				"description": "Cybersecurity roles and responsibilities for the entire workforce and third-party stakeholders (e.g., suppliers, customers, partners) are established",
				index: getIndex()
			},
			"id.ra-1": {
				"name": "ID.RA-1",
				"description": "Asset vulnerabilities are identified and documented",
				index: getIndex()
			},
			"id.ra-2": {
				"name": "ID.RA-2",
				"description": "Cyber threat intelligence is received from information sharing forums and sources",
				index: getIndex()
			},
			"id.ra-3": {
				"name": "ID.RA-3",
				"description": "Threats, both internal and external, are identified and documented",
				index: getIndex()
			},
			"pr.ac-1": {
				"name": "PR.AC-1",
				"description": "Identities and credentials are issued, managed, verified, revoked, and audited for authorized devices, users and processes",
				index: getIndex()
			},
			"pr.ac-3": {
				"name": "PR.AC-3",
				"description": "Remote access is managed",
				index: getIndex()
			},
			"pr.ac-4": {
				"name": "PR.AC-4",
				"description": "Access permissions and authorizations are managed, incorporating the principles of least privilege and separation of duties",
				index: getIndex()
			},
			"pr.ac-5": {
				"name": "PR.AC-5",
				"description": "Network integrity is protected (e.g., network segregation, network segmentation)",
				index: getIndex()
			},
			"pr.ac-6": {
				"name": "PR.AC-6",
				"description": "Identities are proofed and bound to credentials and asserted in interactions",
				index: getIndex()
			},
			"pr.ac-7": {
				"name": "PR.AC-7",
				"description": "Users, devices, and other assets are authenticated (e.g., single-factor, multi-factor) commensurate with the risk of the transaction (e.g., individuals' security and privacy risks and other organizational risks)",
				index: getIndex()
			},
			"pr.ds-1": {
				"name": "PR.DS-1",
				"description": "Data-at-rest is protected",
				index: getIndex()
			},
			"pr.ds-2": {
				"name": "PR.DS-2",
				"description": "Data-in-transit is protected",
				index: getIndex()
			},
			"pr.ds-3": {
				"name": "PR.DS-3",
				"description": "Assets are formally managed throughout removal, transfers, and disposition",
				index: getIndex()
			},
			"pr.ds-4": {
				"name": "PR.DS-4",
				"description": "Adequate capacity to ensure availability is maintained",
				index: getIndex()
			},
			"pr.ds-5": {
				"name": "PR.DS-5",
				"description": "Protections against data leaks are implemented",
				index: getIndex()
			},
			"pr.ds-6": {
				"name": "PR.DS-6",
				"description": "Integrity checking mechanisms are used to verify software, firmware, and information integrity",
				index: getIndex()
			},
			"pr.ip-1": {
				"name": "PR.IP-1",
				"description": "A baseline configuration of information technology/industrial control systems is created and maintained incorporating security principles (e.g. concept of least functionality)",
				index: getIndex()
			},
			"pr.ip-3": {
				"name": "PR.IP-3",
				"description": "Configuration change control processes are in place",
				index: getIndex()
			},
			"pr.ip-4": {
				"name": "PR.IP-4",
				"description": "Backups of information are conducted, maintained, and tested",
				index: getIndex()
			},
			"pr.ip-7": {
				"name": "PR.IP-7",
				"description": "Protection processes are improved",
				index: getIndex()
			},
			"pr.ma-2": {
				"name": "PR.MA-2",
				"description": "Remote maintenance of organizational assets is approved, logged, and performed in a manner that prevents unauthorized access",
				index: getIndex()
			},
			"pr.pt-1": {
				"name": "PR.PT-1",
				"description": "Audit/log records are determined, documented, implemented, and reviewed in accordance with policy",
				index: getIndex()
			},
			"pr.pt-3": {
				"name": "PR.PT-3",
				"description": "The principle of least functionality is incorporated by configuring systems to provide only essential capabilities",
				index: getIndex()        
			},
			"pr.pt-4": {
				"name": "PR.PT-4",
				"description": "Communications and control networks are protected",
				index: getIndex()
			},
			"pr.pt-5": {
				"name": "PR.PT-5",
				"description": "Mechanisms (e.g., failsafe, load balancing, hot swap) are implemented to achieve resilience requirements in normal and adverse situations",
				index: getIndex()
			},
			"de.ae-1": {
				"name": "DE.AE-1",
				"description": "A baseline of network operations and expected data flows for users and systems is established and managed",
				index: getIndex()
			},
			"de.ae-2": {
				"name": "DE.AE-2",
				"description": "Detected events are analyzed to understand attack targets and methods",
				index: getIndex()
			},
			"de.ae-3": {
				"name": "DE.AE-3",
				"description": "Event data are collected and correlated from multiple sources and sensors",
				index: getIndex()
			},
			"de.ae-4": {
				"name": "DE.AE-4",
				"description": "Impact of events is determined",
				index: getIndex()
			},
			"de.ae-5": {
				"name": "DE.AE-5",
				"description": "Incident alert thresholds are established",
				index: getIndex()
			},
			"de.cm-1": {
				"name": "DE.CM-1",
				"description": "The network is monitored to detect potential cybersecurity events",
				index: getIndex()
			},
			"de.cm-3": {
				"name": "DE.CM-3",
				"description": "Personnel activity is monitored to detect potential cybersecurity events",
				index: getIndex()
			},
			"de.cm-4": {
				"name": "DE.CM-4",
				"description": "Malicious code is detected",
				index: getIndex()
			},
			"de.cm-6": {
				"name": "DE.CM-6",
				"description": "External service provider activity is monitored to detect potential cybersecurity events",
				index: getIndex()
			},
			"de.cm-7": {
				"name": "DE.CM-7",
				"description": "Monitoring for unauthorized personnel, connections, devices, and software is performed",
				index: getIndex()
			},
			"de.dp-4": {
				"name": "DE.DP-4",
				"description": "Event detection information is communicated",
				index: getIndex()
			},
			"de.dp-5": {
				"name": "DE.DP-5",
				"description": "Detection processes are continuously improved",
				index: getIndex()
			},
			"rs.an-2": {
				"name": "RS.AN-2",
				"description": "The impact of the incident is understood",
				index: getIndex()
			}
		}
	},
	"headers": [{
			"id": 1,
			"value": "Function",
			"classList": "dont-print col col-md-2 text-md-center",
			"style": null
		},
		{
			"id": 2,
			"value": "Category",
			"classList": "print-sub-cat-col col col-md-2 text-md-center",
			"style": null
		},
		{
			"id": 3,
			"value": "Subcategory",
			"classList": "col col-md-2 text-md-center",
			"style": null
		},
		{
			"id": 4,
			"value": "Result",
			"classList": "print-result-col col col-md-2 text-md-center",
			"style": {
				"marginLeft": "-1rem"
			}
		},
		{
			"id": 5,
			"value": "Severity",
			"classList": "col col-md-1 text-md-left",
			"style": {
				"marginLeft": "3rem"
			}
		},
		{
			"id": 6,
			"value": "Evidence",
			"classList": "col col-md-1 text-md-center",
			"style": {
				"marginLeft": "6rem"
			}
		}
	],
	"testCases": [{
			"id": "fn-identify",
			"value": "Identify",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"IDENTIFY (ID)",
				"Asset Management",
				"Business Environment",
				"Risk Assessment",
				"Risk Management Strategy"
			],
			"categories": ["id.am", "id.ra"]
		},
		{
			"id": "fn-protect",
			"value": "Protect",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				"PROTECT (PR)",
				"Awareness Control",
				"Awareness and Training",
				"Data Security",
				"Info Protection and Procedures",
				"Maintenance",
				"Protective Technology"
			],
			"categories": ["pr.ac", "pr.ds", "pr.ip", "pr.ma", "pr.pt"]
		},
		{
			"id": "fn-detect",
			"value": "Detect",
			"fgColor": "#4d4d4d",
			"bgColor": "#ffff33",
			"description": [
				"DETECT (DE)",
				"Anomalies and Events",
				"Security Continuous Monitoring",
				"Detection Process"
			],
			"categories": ["de.ae", "de.cm", "de.dp"]
		},
		{
			"id": "fn-Respond",
			"value": "Respond",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": [
				"RESPOND (RS)",
				"Response Planning",
				"Communications",
				"Analysis",
				"Mitigation",
				"Improvements"
			],
			"categories": ["rs.an"]
		}
	]
};

export default AwsTestsNIST;