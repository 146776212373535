import { Col, Container, Row } from 'reactstrap';

import { Link } from 'react-router-dom';
import Logo from '../assets/img/brands/Tala_Secure_new_logo.png';
import React from 'react';
import { t } from 'i18next';

const Footer = () => (
	<footer className="footer">
		<Container fluid>
			<Row className="text-muted">
				<Col xs={8} className="text-start mt-5">
					<ul className="list-inline">
						<li className="list-inline-item" style={{ textDecoration: 'none' }}>
							&copy; {new Date().getFullYear()} -{' '}
							<Link
								to="/"
								className="footer-bottom"
								style={{ textDecoration: 'none' }}>
								TalaSecure, Inc.
							</Link>
						</li>

						<li className="list-inline-item">
							<a
								href="mailto:contact@talasecure.com"
								className="footer-bottom"
								style={{ textDecoration: 'none' }}>
								{t('footer.contactUs')}
							</a>
						</li>
					</ul>
				</Col>
				<Col xs={4} className="text-end">
					<p>
						<Link to="dashboard" className="text-muted">
							<img
								src={Logo}
								alt="TALA PATH Compliance Tool"
								style={{ height: '15%', width: '30%' }}
							/>
						</Link>
					</p>
				</Col>
			</Row>
		</Container>
	</footer>
);

export default Footer;
