import { Card, CardBody, Table } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';

import { Colours } from '../../../../constants/Colours';
import { Doughnut } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../components/Loader';
import PrintContext from '../Common/PrintContext';
import TopologyConfig from '../../../Topology/Config';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { getResults } from '../../AutomatedTests/ResultService';

export default function () {
	const { topology, report } = useContext(PrintContext),
		[nodes] = topology,
		[loaded, setLoaded] = useState(false),
		[compliantChart, setCompliantChart] = useState({}),
		[nonCompliantChart, setNonCompliantChart] = useState({}),
		[nonCompliantLevels, setNonCompliantLevels] = useState({});

	const options = {
		responsive: false,
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
	};

	const randomColors = () => {
		return [
			'#C8D8E4',
			'#AFD275',
			'#F5CAC2',
			'#E7717D',
			'#83677B',
			'#B1A296',
			'#F6C435',
			'#E27D60',
			'#85DCB0',
			'#E8A87C',
			'#C38D9E',
			'#A1B3A3',
			'#F9E45B',
		]
			.map(a => ({ sort: Math.random(), value: a }))
			.sort((a, b) => a.sort - b.sort)
			.map(a => a.value);
	};

	const compliantSummary = nodes => {
		const compLength = nodes.filter(
			n => n.data['non-compliant-rules'].length === 0
		).length;
		return {
			labels: ['Compliant', 'Non-Compliant'],
			datasets: [
				{
					data: [compLength, nodes.length - compLength],
					backgroundColor: [Colours.Green, Colours.Red],
					borderColor: 'transparent',
					datalabels: {
						anchor: 'middle',
					},
				},
			],
		};
	};

	const nonCompliantSummary = nodes => {
		const noncomp = nodes.filter(n => n.data['non-compliant-rules'].length > 0);
		const resourceTypes = [];
		const resourceColors = [];
		const config = new TopologyConfig().config();
		noncomp.forEach(n => {
			if (resourceTypes.indexOf(config[n.data.resourcetype].label) === -1) {
				resourceTypes.push(config[n.data.resourcetype].label);
				resourceColors.push(
					config[n.data.resourcetype].color ?? randomColors()[0]
				);
			}
		});
		const data = [];
		resourceTypes.forEach(resourceType => {
			data.push(
				nodes.filter(n => config[n.data.resourcetype].label === resourceType)
					.length
			);
		});
		return {
			labels: resourceTypes,
			datasets: [
				{
					data,
					backgroundColor: resourceColors,
					borderColor: 'transparent',
					datalabels: {
						anchor: 'middle',
					},
				},
			],
		};
	};

	const nonCompliantLevelsSummary = (nodes, results) => {
		const noncomp = nodes.filter(n => n.data['non-compliant-rules'].length > 0);
		let severities = ['Critical', 'High', 'Medium', 'Low', 'Info'];
		let colors = [
			Colours.Red,
			Colours.Orange,
			Colours.Yellow,
			Colours.Green,
			Colours.Blue,
		];
		let data = [0, 0, 0, 0, 0];
		noncomp.forEach(n => {
			const resourceSeverities = [
				...new Set(
					results.rows
						.filter(r => r.ResourceId === n.data.id)
						.map(r => r.Severity)
				),
			];
			for (let index = 0; index < severities.length; index++) {
				if (resourceSeverities.indexOf(severities[index]) >= 0) {
					data[index]++;
					break;
				}
			}
		});
		for (let index = 0; index < data.length; index++) {
			if (data[index] === 0) {
				severities[index] = '';
				colors[index] = '';
			}
		}
		severities = severities.filter(s => s.length > 0);
		colors = colors.filter(c => c.length > 0);
		data = data.filter(d => d > 0);
		return {
			labels: severities,
			datasets: [
				{
					data,
					backgroundColor: colors,
					borderColor: 'transparent',
					datalabels: {
						anchor: 'middle',
					},
				},
			],
		};
	};

	useEffect(() => {
		getResults({ ...report, Fragments: report.fragments }).then(results => {
			if (nodes.length > 0) {
				setCompliantChart(compliantSummary(nodes));
				setNonCompliantChart(nonCompliantSummary(nodes));
				setNonCompliantLevels(nonCompliantLevelsSummary(nodes, results));
			}
			setLoaded(nodes.length > 0);
		});
	}, [nodes]);

	const generateDoughnut = (chart, heading) => {
		const legends = new TopologyConfig().legend();
		return (
			<div style={{ width: 325, paddingRight: 40, float: 'left' }}>
				{chart.datasets[0].data.length > 0 && (
					<h5 style={{ paddingBottom: 10 }}>{heading}</h5>
				)}
				<Doughnut data={chart} options={options} plugins={[]} />
				<Table className="mb-0">
					<tbody>
						{chart.datasets[0].data.map((data, index) => {
							const item = legends.find(c => c.label === chart.labels[index]);
							return (
								<tr key={index}>
									<td>
										{!item && (
											<FontAwesomeIcon
												icon={faCircle}
												style={{
													color: chart.datasets[0].backgroundColor[index],
												}}
											/>
										)}
										{item && (
											<img
												src={item.image}
												alt={item.label}
												style={{
													width: 25,
													height: 25,
													borderRadius: '50%',
													marginRight: 10,
												}}
											/>
										)}{' '}
										{chart.labels[index]} #
									</td>
									<td className="text-end">{data}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>
		);
	};

	return (
		<Card className="page-break d-flex">
			<CardBody>
				{!loaded && <Loader />}
				{loaded && (
					<div style={{ overflow: 'hidden' }}>
						{generateDoughnut(compliantChart, 'Resource compliance')}
						{generateDoughnut(nonCompliantChart, 'Non-Compliant Resources')}
						{generateDoughnut(nonCompliantLevels, 'Non-Compliant Severity')}
					</div>
				)}
			</CardBody>
		</Card>
	);
}
