import React from 'react';
import { CardBody, CardHeader, Container, Row, Card } from 'reactstrap';
import '../ransomware/assessment/Controls/styles.css';
import quickGuideIcon from '../../assets/img/quickGuideIcon.svg';
import securityIcon from '../../assets/img/securityIcon.svg';
import troubleshootingIcon from '../../assets/img/troubleshootingIcon.svg';
import detailedInstructionsIcons from '../../assets/img/detailedInstructionsIcons.svg';
import deleteIcon from '../../assets/img/deleteIcon.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import overviewIcon from '../../assets/img/overviewIcon.svg';
import CustomBtn from './components/CustomBtn';

function UserGuide() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Container fluid>
			<div className="guide-header">
				<h1>{t('bigGuide.userGuides')}</h1>
				<p>{t('bigGuide.guidesForSetting')}</p>
			</div>
			<CardBody>
				<Container fluid>
					<Row className="d-flex justify-content-between">
						{false && (<>
						<Card className="card-item">
							<CardHeader className="d-flex gap-3 align-items-center">
								<img src={quickGuideIcon} alt="quickGuideIcon" />
								<h5>{t('bigGuide.quickstartGuide')}</h5>
							</CardHeader>
							<CardBody>
								<p>{t('bigGuide.talaDefenderQuickstart')}</p>
							</CardBody>
							<div className="d-flex justify-content-center m-2">
								<CustomBtn />
							</div>
						</Card>
						<Card className="card-item">
							<CardHeader className="d-flex gap-3 align-items-center">
								<img src={overviewIcon} alt="overviewIcon" />
								<h5>{t('bigGuide.overviewOneTimeSetup')}</h5>
							</CardHeader>
							<CardBody>
								<p>{t('bigGuide.firstCover')}</p>
							</CardBody>
							<div className="d-flex justify-content-center m-2">
								<CustomBtn
									onClick={() => navigate('/user-guide/one-time-setup')}
								/>
							</div>
						</Card>
						</>)}
						<Card className="card-item">
							<CardHeader className="d-flex gap-3 align-items-center">
								<img
									src={detailedInstructionsIcons}
									alt="detailedInstructionsIcons"
								/>
								<h5>{t('bigGuide.detailedInstructions')}</h5>
							</CardHeader>
							<CardBody>
								<p>{t('bigGuide.easilySetUp')}</p>
							</CardBody>
							<div className="d-flex justify-content-center m-2">
								<CustomBtn
									onClick={() =>
										navigate('/user-guide/detailed-instructions')
									}
								/>
							</div>
						</Card>
					</Row>
					{false && (
					<Row className="d-flex justify-content-between mt-4">
						<Card className="card-item">
							<CardHeader className="d-flex gap-3 align-items-center">
								<img src={securityIcon} alt="securityIcon" />
								<h5>{t('bigGuide.security')}</h5>
							</CardHeader>
							<CardBody>
								<p>{t('bigGuide.regularlyRotate')}</p>
							</CardBody>
							<div className="d-flex justify-content-center m-2">
								<CustomBtn />
							</div>
						</Card>
						<Card className="card-item">
							<CardHeader className="d-flex gap-3 align-items-center">
								<img src={troubleshootingIcon} alt="troubleshootingIcon" />
								<h5>{t('bigGuide.troubleshooting')}</h5>
							</CardHeader>
							<CardBody>
								<p>{t('bigGuide.regularlyRotate')}</p>
							</CardBody>
							<div className="d-flex justify-content-center m-2">
								<CustomBtn />
							</div>
						</Card>
						<Card className="card-item">
							<CardHeader className="d-flex gap-3 align-items-center">
								<img src={deleteIcon} alt="deleteIcon" />
								<h5>{t('bigGuide.deleteAWS')}</h5>
							</CardHeader>
							<CardBody>
								<p>{t('bigGuide.deleteSecretKey')}</p>
							</CardBody>
							<div className="d-flex justify-content-center m-2">
								<CustomBtn />
							</div>
						</Card>
					</Row>
					)}
				</Container>
			</CardBody>
		</Container>
	);
}

export default UserGuide;
