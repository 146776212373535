import { getFixResults, saveFixResults } from '../../../apiclients/Ransomware';
import { getItem, removeItem } from '../../../services/browserStorageService';
import { useEffect, useState } from 'react';

const useFixIssues = () => {
	const key = 'remediation_issues';

	const [reportFixData, setReportFixData] = useState([]);
	const [fixStatus, setFixStatus] = useState([]);
	const [data, setData] = useState(getItem(key));
	const [busy, setBusy] = useState(true);

	const clear = () => {
		removeItem(key);
		setData([]);
	};

	const onRollback = item => {
		const rule = reportFixData.find(d => d.key === data.rule);
		if (rule) {
			let changed = false;
			const existsRollback = rule.resources.rollback.find(
				i => i.ResourceName === item.ResourceName
			);
			if (!existsRollback) {
				rule.resources.rollback.push(item);
				changed = true;
			}

			const existsFixed = rule.resources.fixed.find(
				i => i.ResourceName === item.ResourceName
			);
			if (existsFixed) {
				const index = rule.resources.fixed.indexOf(existsFixed);
				if (index > -1) {
					rule.resources.fixed.splice(index, 1);
				}
				changed = true;
			}

			if (changed) {
				saveFixResults(data.reportId, reportFixData).then(response => {
					setFixStatus(response.data.find(d => d.key === data.rule) ?? {});
					setReportFixData(response.data);
				});
			}
		}
	};

	const onFix = item => {
		const rule = reportFixData.find(d => d.key === data.rule);
		if (rule) {
			let changed = false;
			const existsFixed = rule.resources.fixed.find(
				i => i.ResourceName === item.ResourceName
			);
			if (!existsFixed) {
				rule.resources.fixed.push(item);
				changed = true;
			}

			const existsRollback = rule.resources.rollback.find(
				i => i.ResourceName === item.ResourceName
			);
			if (existsRollback) {
				const index = rule.resources.rollback.indexOf(existsRollback);
				if (index > -1) {
					rule.resources.rollback.splice(index, 1);
				}
				changed = true;
			}

			if (changed) {
				saveFixResults(data.reportId, reportFixData).then(response => {
					setFixStatus(response.data.find(d => d.key === data.rule) ?? {});
					setReportFixData(response.data);
				});
			}
		}
	};

	useEffect(() => {
		setBusy(true);
		if (!data) {
			setBusy(false);
			return;
		}
		getFixResults(data.reportId, data.reportItems).then(response => {
			setFixStatus(response.data.find(d => d.key === data.rule) ?? {});
			setReportFixData(response.data);
			setBusy(false);
		});
	}, [data]);

	return {
		busy,
		error: !data,
		issues: data && data.issues ? data.issues : [],
		title: data && data.rule ? data.rule : '',
		reportId: data ? data.reportId : '',
		fixStatus,
		clear,
		onFix,
		onRollback,
	};
};

export default useFixIssues;
