import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { Trans } from 'react-i18next';
import useClickOutside from '../../../hooks/useClickOutside';

const options = [
	{
		value: 'jira',
		label: 'Jira',
	},
	{
		value: 'serviceNow',
		label: 'ServiceNow',
	},
];

const ServiceDropdown = ({ onChange = () => {} }) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef();

	useClickOutside(ref, () => setIsOpen(false));

	return (
		<div ref={ref} className="service-dropdown">
			<button
				className={classNames('service-dropdown__trigger', {
					'service-dropdown__trigger_opened': isOpen,
				})}
				onClick={() => setIsOpen(p => !p)}>
				<p>
					<Trans i18nKey="talasmart.resource.create" />
				</p>
				<ChevronDown />
			</button>
			{isOpen && (
				<div className="service-dropdown__menu">
					<ul className="service-dropdown__list">
						{options.map(item => (
							<li key={item.value}>
								<button
									onClick={() => {
										setIsOpen(false);
										onChange(item.value);
									}}>
									{item.label}
								</button>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ServiceDropdown;
