import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';

import Loader from '../../../../components/Loader';
import { PackKeys } from '../../../../constants/PackKeys';
import PrintContext from '../Common/PrintContext';
import ReportContext from '../Common/ReportContext';
import ReportFunction from '../Controls/ReportFunction';
import auditDataNist from '../Constants/AuditTestsNIST';
import auditDataSoC2 from '../Constants/AuditTestsSoC2';
import auditTestsCmmCl3 from '../Constants/AuditTestsCmmCl3';
import auditTestsHipaa from '../Constants/AuditTestsHipaa';
import auditTestsPciDss from '../Constants/AuditTestsPciDss';
import automatedTestsCmmCl3 from '../Constants/AutomatedTestsCmmCl3';
import awsDataNist from '../Constants/AwsTestsNIST';
import awsDataSoC2 from '../Constants/AwsTestsSoC2';
import awsHipaaData from '../Constants/AwsTestsHipaa';
import awsTestsPciDss from '../Constants/AwsTestsPciDss';
import azureAutomatedTestsHipaa from '../Constants/AzureAutomatedTestsHipaa';
import azureAutomatedTestsPciDss from '../Constants/AzureAutomatedTestsPciDss';
import { isAzure } from '../../../../apiclients/Common';
import { mapAwsData } from '../Common/DataMapper';

const awsAutomatedData = {
	[PackKeys.NIST]: awsDataNist,
	[PackKeys.SOC2]: awsDataSoC2,
	[PackKeys.HIPAASECURITY]: awsHipaaData,
	[PackKeys.PCIDSS]: awsTestsPciDss,
	[PackKeys.CMMCL3]: automatedTestsCmmCl3,
};

const azureAutomatedData = {
	...awsAutomatedData,
	[PackKeys.HIPAASECURITY]: azureAutomatedTestsHipaa,
	[PackKeys.PCIDSS]: azureAutomatedTestsPciDss,
};

const getAutomatedData = packName => {
	const data = isAzure() ? azureAutomatedData : awsAutomatedData;
	return data[packName];
};

const auditData = {
	[PackKeys.NIST]: auditDataNist,
	[PackKeys.SOC2]: auditDataSoC2,
	[PackKeys.HIPAASECURITY]: auditTestsHipaa,
	[PackKeys.PCIDSS]: auditTestsPciDss,
	[PackKeys.CMMCL3]: auditTestsCmmCl3,
};

const getData = ({ audit, packName }) => {
	const data = audit ? auditData[packName] : getAutomatedData(packName);

	if (!data) {
		const msg =
			'unknown packname specified to display the report for. audit = ';
		console.error(msg, audit);
	}

	return data;
};

export default function (props) {
	const { client, report, counter, auditor } = useContext(PrintContext),
		[values, setValues] = useState({}),
		[loaded, setLoaded] = useState(false),
		[error, setError] = useState(false),
		printMode = true,
		data = getData(props),
		fetchError = (msg, e) => {
			console.error(msg, e);
			setError(true);
			props.onFail(true);
		},
		auditError = e =>
			fetchError('An error occurred while loading audit data', e),
		awsError = e => fetchError('An error occurred while loading aws data', e),
		setData = (d, mapper) => {
			counter.increment();
			setValues(mapper(d));
			setLoaded(true);
		},
		setAuditData = d => {
			auditor.setName(d.auditorName ? d.auditorName : '');
			setData(d, d => (d.data ? d.data : d));
		},
		setAwsData = d => setData(d, mapAwsData),
		getValues = () => {
			setLoaded(false);
			if (props.audit) {
				client.getAuditResults(report, setAuditData, auditError);
			} else {
				client.getFinalizedReport(report, setAwsData, awsError);
			}
		};

	useEffect(getValues, []);

	return (
		<Card className="page-break d-flex">
			<CardHeader className="page-break d-flex">
				<CardTitle>
					<h3>{props.title}</h3>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{error && (
					<h5 className="text-danger">
						An error occurred while loading this section for the report. Please
						reload the page and try again.
					</h5>
				)}
				{!error && (
					<ReportContext.Provider
						value={{ client, report, values, data, printMode }}>
						{!loaded && <Loader />}
						{loaded &&
							data.testCases.map(test => (
								<ReportFunction
									key={test.id}
									test={test}
									headers={data.headers}
									referenceData={data.referenceData}
								/>
							))}
					</ReportContext.Provider>
				)}
			</CardBody>
		</Card>
	);
}
