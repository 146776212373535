import { useEffect, useState } from "react";

export default function (originalValue, onValueChange, regex) {
	
	const
		[value, setValue] = useState(originalValue),
		[invalid, setInvalid] = useState(true),
		onFocus = e => e.target.select(),
		onChange = e => setValue(e.target.value),
		onBlur = () => {
			const valid = regex.test(value);
			setInvalid(!valid);
			onValueChange(value);
		};

	useEffect(() => {
		const valid = regex.test(originalValue);
		setInvalid(!valid);
		setValue(originalValue);
	}, [originalValue, regex]);

	return { value, invalid, onFocus, onChange, onBlur };
}