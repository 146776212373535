import { azureKnownTypes, defaultOptions } from '../Common/ReviewScanOptions';
import {
	getDrSoftwareList,
	getMdmSoftwareList,
	getQuestionare,
	getResourceList,
	getSiemSoftwareList,
	getUemSoftwareList,
	saveQuestionare,
} from '../../../apiclients/Ransomware';
import { useEffect, useState } from 'react';

import data from './DropdownData';
import { evaluate } from '../../../apiclients/Ransomware';
import { getCurrentAccountId } from '../../../services/AuthService';
import { isAzure } from '../../../apiclients/Common';
import { setItem } from '../../../services/browserStorageService';

const getAzureType = rt => {
	const knownType = azureKnownTypes[rt];
	if (!!knownType) {
		return azureKnownTypes[rt];
	} else if (rt.indexOf('subscription') > -1) {
		return 'Subscription';
	} else {
		return rt;
	}
};

const addResourceToBucket = (resources, resource, resourceType) => {
	const bucket = resources.find(x => x.key === resourceType);
	if (bucket) {
		if (!bucket.value) {
			bucket.value = [];
		}
		bucket.value.push(resource);
	} else {
		const item = {
			key: resourceType,
			value: [resource],
		};
		resources.push(item);
	}
};

const useReviewScan = (defaultSelected, reportName, userQuestionnaire) => {
	const [resourceBucket, setResourceBucket] = useState([]);
	const [filteredResources, setFilteredResources] = useState([]);
	const [filter, setFilter] = useState('');
	const [resourceTypes, setResourceTypes] = useState([]);
	const [loading, setLoading] = useState(true);
	const [options, setOptions] = useState(defaultOptions);
	const [questionare, setQuestionare] = useState({});

	const initSwList = (review, drSwList, siemSwList, uemSwList, mdmSwList) => {
		if (drSwList.length > 0) {
			review.disasterRecovery = {
				...review.disasterRecovery,
				softwareName: drSwList[0].ProductName,
				licenseARN: drSwList[0].LicenseArn,
				seller: drSwList[0].Seller,
			};
		} else {
			review.disasterRecovery = {
				...review.disasterRecovery,
			};
		}

		if (siemSwList.length > 0) {
			review.incidenceMonitoring = {
				...review.incidenceMonitoring,
				enabled: true,
				softwareName: siemSwList[0].ProductName,
				licenseARN: siemSwList[0].LicenseArn,
				seller: siemSwList[0].Seller,
			};
		} else {
			review.incidenceMonitoring = {
				...review.incidenceMonitoring,
				enabled: false,
			};
		}

		if (uemSwList.length > 0) {
			review.bringOwnDevice = {
				...review.bringOwnDevice,
				endpointSecurity: true,
				endPointSoftwareName: uemSwList[0].ProductName,
				endPointLicenseARN: uemSwList[0].LicenseArn,
				endPointSeller: uemSwList[0].Seller,
			};
		} else {
			review.bringOwnDevice = {
				...review.bringOwnDevice,
				endpointSecurity: false,
			};
		}

		if (review.length > 0) {
			review.bringOwnDevice = {
				...options.bringOwnDevice,
				mobileDatamanagement: true,
				mdmSoftwareName: mdmSwList.ProductName,
				mdmLicenseARN: mdmSwList.LicenseArn,
				mdmSeller: mdmSwList.Seller,
			};
		} else {
			review.bringOwnDevice = {
				...review.bringOwnDevice,
				mobileDatamanagement: false,
			};
		}
		const newOptions = { ...review };
		setOptions(newOptions);
	};

	const initResourceList = resourceList => {
		let resourceTypes = ['Account'];
		let resources = [
			{
				key: 'Account',
				value: [{ id: getCurrentAccountId(), name: 'Account', selected: true }],
			},
		];

		const azureMapper = res => {
			const resource = res.data;
			const resourceType = getAzureType(resource.resourcetype);
			if (resourceTypes.indexOf(resourceType) < 0) {
				resourceTypes.push(resourceType);
			}

			resource.resourcetype = resourceType;

			const newResource = {
				...resource,
				selected: defaultSelected,
			};

			addResourceToBucket(resources, newResource, resourceType);
		};

		const awsMapper = res => {
			const resource = res.data;
			let rt = resource.resourcetype;

			if (rt === 'RDS-Cluster') {
				resource.resourcetype = 'RDS';
			}
			if (rt === 'EKS-NodeGroup') {
				resource.resourcetype = 'EKS-Cluster';
			}

			const isRegion = rt !== 'Region' && rt !== 'Availability-Zone';
			if (isRegion) {
				if (resourceTypes.indexOf(rt) === -1) {
					resourceTypes.push(rt);
					resources.push({ key: rt, value: [] });
				}
				const bucket = resources.find(
					r => r.key === resource.resourcetype
				).value;

				if (!bucket.find(r => r.id === resource.id)) {
					if (!resource.name || resource.name.length === 0) {
						resource.name = resource.id;
					}
					bucket.push({ ...resource, selected: defaultSelected });
				}
			}
		};

		const mapper = isAzure() ? azureMapper : awsMapper;
		resourceList.forEach(mapper);

		setResourceBucket(resources);
		setResourceTypes(resourceTypes);
		filterResources(resourceTypes[0]);
		setFilteredResources(resources.find(r => r.key === resourceTypes[0]).value);
	};

	const save = async onComplete => {
		var localQuestionaire = {...questionare};
		setLoading(true);
		if(!localQuestionaire.organization && userQuestionnaire.organization)
		{
			localQuestionaire = {...userQuestionnaire};
		}
		saveQuestionare({ ...localQuestionaire, review: options }).then(() => {
			setLoading(false);
			onComplete();
		});
	};

	const refreshResources = () => {
		setLoading(true);
		getResourceList(true).then(resourceList => {
			if (resourceList.data) {
				console.log('calling initResourceList from refresh');
				initResourceList(resourceList.data);
			}
			setLoading(false);
		});
	};

	useEffect(() => {
		setLoading(true);

		Promise.all([
			getQuestionare(),
			getResourceList(),
			getDrSoftwareList(),
			getSiemSoftwareList(),
			getUemSoftwareList(),
			getMdmSoftwareList(),
		]).then(
			([
				questionaire,
				resourceList,
				drSwList,
				siemSwList,
				uemSwList,
				mdmSwList,
			]) => {
				if (!questionaire.data) {
					questionaire.data = {};
				}
				if (!questionaire.data.review) {
					questionaire.data.review = defaultOptions;
				}
				setQuestionare(questionaire.data);
				setOptions(questionaire.data.review);

				const allDataFound =
					drSwList.data && siemSwList.data && uemSwList.data && mdmSwList.data;

				if (allDataFound) {
					initSwList(
						questionaire.data.review,
						drSwList.data,
						siemSwList.data,
						uemSwList.data,
						mdmSwList
					);
				}

				if (resourceList.data) {
					console.log('calling initResourceList from load');
					initResourceList(resourceList.data);
				}
				setLoading(false);
			}
		);
	}, []);

	const setInput = (parent, key, value) => {
		const parentObj = options[parent] ?? {};
		if (value.id !== undefined) {
			parentObj[key + 'Id'] = value.id;
			parentObj[key] = value.value;
		} else {
			parentObj[key] = value;
		}
		options[parent] = { ...parentObj };
		const newOptions = { ...options };
		setOptions(newOptions);
	};

	const evaluateResources = async (exclusion, onComplete) => {
		setLoading(true);
		const result = await evaluate(reportName, exclusion);
		if (result.success) {
			setItem(`ransomware_${reportName}`, result.data);
			setLoading(false);
			onComplete();
		} else {
			console.error('an error occurred when running the ransomware evaluation');
			setLoading(false);
		}
	};

	const filterResources = filterName => {
		setFilter(filterName);
		const filteredResources = (
			resourceBucket.find(b => b.key === filterName) ?? { value: [] }
		).value;
		setFilteredResources(filteredResources);
	};

	const resetSelection = selected => {
		resourceTypes.forEach(type => {
			const resources = (
				resourceBucket.find(r => r.key === type) ?? { value: [] }
			).value;
			resources.forEach(r => (r.selected = selected));
		});
		setResourceBucket(resourceBucket);
		filterResources(resourceTypes[0]);
	};

	return {
		filter,
		filterResources,
		filteredResources,
		refreshResources,
		resourceBucket,
		resourceTypes,
		resetSelection,
		loading,
		setLoading,
		evaluateResources,
		setInput,
		options,
		save,
		licenseStatus: data.disasterRecoveryLicenseStatus,
		frequency: data.frequency,
		isAzure: isAzure(),
	};
};

export default useReviewScan;
