import * as types from '../constants';

export function setUser(payload) {
	return {
		payload,
		type: types.SET_USER,
	};
}

export function setIsAuthenticated(payload) {
	return {
		payload,
		type: types.SET_AUTHENTICATED,
	};
}
