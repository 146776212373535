import Header from "../../../../components/Header"
import HeaderTitle from "../../../../components/HeaderTitle";
import React from "react";

export default function ({ title }) {
	return (
		<Header>
			<HeaderTitle>
				{title}
			</HeaderTitle>
		</Header>
	);
}