export const INITIAL_VALUES = {
	name: '',
	phoneNumber: '',
	email: '',
	alternateEmail: '',
	photo: null,
};

const required = value => value && value?.trim()?.length > 0;

const email = value =>
	value ? /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) : true;

export const VALIDATORS = {
	email: [
		{ method: required, error: 'form.emailRequired' },
		{ method: email, error: 'form.emailInvalid' },
	],
	alternateEmail: [{ method: email, error: 'form.alternateEmailInvalid' }],
	phoneNumber: [{ method: required, error: 'form.phoneRequired' }],
	name: [{ method: required, error: 'form.userNameRequired' }],
};
