import '../styles.css';

import { Card, CardBody, CardHeader, CardTitle, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import React from 'react';
import { Info } from 'react-feather';

const Issues = ({
	color,
	title,
	system,
	issues,
	info = false,
	onInfoClick = () => { },
}) => {
	const textClass = `text-${color} `;
	const { t } = useTranslation();

	return (
		<Card className="dashboard-card">
			<div className='d-flex flex-column justify-content-between'>
				<CardHeader>
					<CardTitle className="d-flex justify-content-between align-items-center mb-0">
						<h5 className="text-uppercase mb-0">{title}</h5>
						{info && (
							<button
								className="bg-transparent p-0 border-0 text-gray"
								onClick={onInfoClick}>
								<Info />
							</button>
						)}
					</CardTitle>
				</CardHeader>
				<CardBody>
					<div className='d-flex align-items-center gap-1'>
						<span className="issue-title">
							{t('table.vulnerableResources')}
						</span>
						<h3 className={`mb-0 ${textClass}`}>{system}</h3>

					</div>
					<div className='d-flex align-items-center gap-1 mt-1' >
						<span className=" issue-title">
							{' '}
							{t('ransomwareDashboard.issuesDetected')}
						</span>
						<h3 className={`mb-0 ${textClass}`}>{` ${issues}`}</h3>
					</div>
				</CardBody>
				<CardBody>
					<p style={{ marginTop: '2.5rem' }}> {t('ransomwareDashboard.basedOnVulnerabilities')}</p>

				</CardBody>

			</div>
		</Card>
	);
};

export default Issues;
