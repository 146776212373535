import React from 'react';
import { Card } from 'reactstrap';
import { Trans } from 'react-i18next';

import Button from './Form/Button';

import { ReactComponent as CopySvg } from '../assets/img/copy.svg';
import { useNavigate } from 'react-router-dom';
import { getUserToken } from '../services/AuthService';

const NoKeyPopup = () => {
	const navigate = useNavigate();
	const userToken = getUserToken();

	return (
		<section className="d-flex flex-column align-items-center justify-content-center h-100">
			<Card className="dashboard-card no-key-card mx-auto w-100 d-flex justify-content-center flex-column align-items-center py-5">
				<CopySvg />
				<h3>
					<Trans i18nKey="signIn.addAccountToViewDashboard" />
				</h3>
				<p className="text-center w-80">
					<Trans i18nKey="signIn.addAccountInstructions1" />
					<b>
						"<Trans i18nKey="sidebar.adminPanel" />"
					</b>
					<Trans i18nKey="signIn.addAccountInstructions2" />
					<b>
						"+ <Trans i18nKey="button.addAccount" />"
					</b>
					<Trans i18nKey="signIn.addAccountInstructions3" />
					<b>
						<Trans i18nKey="signIn.addAccountDialogBox" />
					</b>
					<Trans i18nKey="signIn.addAccountInstructions4" />
				</p>
				<Button
					onClick={() => {
						navigate('/admin/remote-landing?token=' + userToken);
					}}>
					Go to admin
				</Button>
			</Card>
		</section>
	);
};

export default NoKeyPopup;
