import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	FormGroup,
	Label,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import AssessmentDropdown from '../Components/AssessmentDropdown';
import AssessmentInput from '../Components/AssessmentInput';
import Header from '../../../../components/Header';
import HeaderTitle from '../../../../components/HeaderTitle';
import Loader from '../../../../components/Loader';
import { Stages } from '../../Common/AssessmentStage';
import classnames from 'classnames';
import { getReportName } from '../../Hooks/Helpers';
import useAssessment from '../../Hooks/useAssessment';
import useRender from '../../Hooks/useRender';
import MagicSvg from '../../../../assets/img/fe_magic.svg';
import Next from '../../../../assets/img/Next.svg';
import { useTranslation } from 'react-i18next';
import Steps from './Steps';
import ArrowBack from '../../../../assets/img/ArrowBack.svg';
import ArrowBackDisable from '../../../../assets/img/ArrowBackDisable.svg';

const disableButton = (activeTab, hook) => {
	if (activeTab === '3') {
		const bothFormsValid = hook.orgFormValid && hook.contactFormValid;
		//both tabs must be valid, disable the button otherwise
		return !bothFormsValid;
	} else if (activeTab === '2') {
		return !hook.contactFormValid;
	} else if (activeTab === '1') {
		return !hook.orgFormValid;
	}

	return false; //keep the button enabled by returning false
};

const Column1Control = props => {
	return (
		<Col xs={4}>
			<div className="me-4">
				<FormGroup>{props.children}</FormGroup>
			</div>
		</Col>
	);
};

const DemographicAssessment = ({
	stage,
	onNext,
	setMode,
	mode,
	reportName,
	setReportName,
	setUserQuestionnaire,
}) => {
	const { t } = useTranslation();
	const render = useRender(stage, Stages.DemographicAssessment);
	const hook = useAssessment(setUserQuestionnaire);

	const [activeTab, setActiveTab] = useState('1');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
		checkReviewValid();
	};

	const cursor = {
		cursor: 'pointer',
		color: 'black',
	};

	const activeTabColor = {
		...cursor,
		color: '#3B7DDD',
	};

	const checkReviewValid = () => {
		if (!hook.reviewValid) {
			setMode(1);
		}
	};

	useEffect(() => {
		setActiveTab('1');
		if (render) {
			setReportName(getReportName());
		}
	}, [render]);

	const nextClicked = () => {
		if (activeTab === '3') {
			setActiveTab('1');
			hook.save(() => onNext(mode));
		} else {
			setActiveTab((Number(activeTab) + 1).toString());
		}
		checkReviewValid();
	};

	return (
		render && (
			<Container fluid>
				<Header>
					<HeaderTitle>
						{t('demographicAssessment.title')}
						<img src={MagicSvg} alt="magic" />
					</HeaderTitle>
					<p>{t('demographicAssessment.collectData')}</p>
				</Header>
				<Steps activeTab={activeTab} />

				<Card>
					<CardBody>
						{hook.loading && <Loader />}
						{!hook.loading && (
							<>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '1' })}
											onClick={() => {
												toggle('1');
											}}>
											<b style={activeTab === '1' ? activeTabColor : cursor}>
												{t('demographicAssessment.organizationDetails')}
											</b>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '2' })}
											onClick={() => {
												toggle('2');
											}}>
											<b style={activeTab === '2' ? activeTabColor : cursor}>
												{t('demographicAssessment.contactDetails')}
											</b>
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '3' })}
											onClick={() => {
												toggle('3');
											}}>
											<b style={activeTab === '3' ? activeTabColor : cursor}>
												{t('demographicAssessment.assessmentMode')}
											</b>
										</NavLink>
									</NavItem>
									<div className="arrows">
										<img
											src={ArrowBack}
											onClick={() => {}}
											className="cursor-pointer"
										/>
										<img src={ArrowBackDisable} className="cursor-pointer" />
									</div>
								</Nav>
								<TabContent activeTab={activeTab}>
									<TabPane tabId="1">
										{/* Row 1 */}
										<Row className="mt-4">
											<Col xs="4">
												<AssessmentInput
													labelfor="orgName"
													placeholder={'Type here'}
													value={hook.options.demographics.orgName}
													onChange={(n, v) =>
														hook.setInput('demographics', n, v)
													}>
													{t('demographicAssessment.whatName')}
												</AssessmentInput>
											</Col>
											<Column1Control>
												<AssessmentDropdown
													name="grossValue"
													label={t('demographicAssessment.grossValueLabel')}
													value={hook.options.demographics.grossValueId}
													values={hook.grossValueRange}
													onSelect={v =>
														hook.setInput('demographics', 'grossValue', v)
													}
												/>
											</Column1Control>
											{/* <AssessmentDropdown
                                                    name="sector"
                                                    label={t('demographicAssessment.selectSectorLabel')}
                                                    value={hook.options.demographics.sectorId}
                                                    values={hook.sectors}
                                                    onSelect={v =>
                                                        hook.setInput('demographics', 'sector', v)
                                                    }
                                                /> */}
											{/* <Col xs="4">
                                                <div className="p-2 me-1">
                                                    <AssessmentInput
                                                        labelfor="orgName"
                                                        value={hook.options.demographics.orgName}
                                                        onChange={(n, v) =>
                                                            hook.setInput('demographics', n, v)
                                                        }>
                                                        {t('demographicAssessment.whatName')}
                                                    </AssessmentInput>
                                                </div>
                                            </Col> */}
										</Row>
										{/* End Row 1 */}
										{/* Row 2 */}
										<Row>
											<Col xs="4">
												<AssessmentInput
													name="sector"
													placeholder={'Type here'}
													value={hook.options.demographics.sector}
													onChange={(n, v) =>
														hook.setInput('demographics', 'sector', v)
													}>
													{t('demographicAssessment.selectSectorLabel')}
												</AssessmentInput>
											</Col>
											<Column1Control>
												<AssessmentDropdown
													name="cashflow"
													label={t('demographicAssessment.monthlyCashLabel')}
													value={hook.options.demographics.cashflowId}
													values={hook.cashflow}
													onSelect={v =>
														hook.setInput('demographics', 'cashflow', v)
													}
												/>
											</Column1Control>
										</Row>
										{/* End Row 2 */}

										{/* Row 3 */}
										<Row>
											<Col xs="4">
												<AssessmentInput
													labelfor="deptname"
													placeholder={'Type here'}
													value={hook.options.demographics.deptname}
													onChange={(n, v) => {
														hook.setInput('demographics', n, v);
													}}>
													{t('demographicAssessment.nameDepartmentLabel')}
												</AssessmentInput>
											</Col>
											<Column1Control>
												<AssessmentDropdown
													name="empNum"
													label={t(
														'demographicAssessment.numberEmployeesLabel'
													)}
													value={hook.options.demographics.empNumId}
													values={hook.numberOfEmployees}
													onSelect={v =>
														hook.setInput('demographics', 'empNum', v)
													}
												/>
											</Column1Control>
										</Row>
										{/* End Row 3 */}

										{/* Row 4 */}
										<Row>
											<Col xs="4">
												<div className="p-2">
													<Label>
														{t('demographicAssessment.areYouRunning')}
													</Label>
													<div className="mb-3">
														<input
															className="pl-5 me-4"
															type="radio"
															id="demographics.isdept.yes"
															checked={hook.options.demographics.isdept}
															value="true"
															onChange={() =>
																hook.setInput('demographics', 'isdept', true)
															}
															name="demographics.isdept"></input>
														<label
															className="me-6"
															htmlFor="demographics.isdept.yes">
															{t('button.yes')}
														</label>
														<input
															className="pl-5 me-4"
															type="radio"
															id="demographics.isdept.no"
															checked={!hook.options.demographics.isdept}
															value="false"
															onChange={() =>
																hook.setInput('demographics', 'isdept', false)
															}
															name="demographics.isdept"></input>
														<label htmlFor="demographics.isdept.no">
															{t('button.no')}
														</label>
													</div>
												</div>
											</Col>
											<Col xs="8">
												<div className="p-2 me-1"></div>
											</Col>
										</Row>
										{/* End Row 4 */}
									</TabPane>
									<TabPane tabId="2">
										{/* Row 1 */}
										<Row className="mt-4">
											<Col xs="4">
												<div className="p-2 me-1">
													<AssessmentInput
														labelfor="assessorName"
														placeholder={'Type here'}
														value={hook.options.demographics.assessorName}
														onChange={(n, v) =>
															hook.setInput('demographics', n, v)
														}>
														{t('demographicAssessment.nameOfAccessor')}
													</AssessmentInput>
												</div>
											</Col>
											<Col xs="4">
												<div className="p-2 me-1">
													<AssessmentInput
														type="email"
														placeholder={'Type here'}
														labelfor="assessorEmail"
														value={hook.options.demographics.assessorEmail}
														onChange={(n, v) =>
															hook.setInput('demographics', n, v)
														}>
														{t('demographicAssessment.emailOfAccessor')}{' '}
													</AssessmentInput>
												</div>
											</Col>
										</Row>
										{/* End Row 1 */}
										{/* Row 2 */}
										<Row>
											<Col xs="4">
												<div className="p-2 me-1">
													<AssessmentInput
														labelfor="pocName"
														placeholder={'Type here'}
														value={hook.options.demographics.pocName}
														onChange={(n, v) =>
															hook.setInput('demographics', n, v)
														}>
														{t('demographicAssessment.namePointOfContact')}
													</AssessmentInput>
												</div>
											</Col>
											<Col xs="4">
												<div className="p-2 me-1">
													<AssessmentInput
														type="email"
														labelfor="pocEmail"
														placeholder={'Type here'}
														value={hook.options.demographics.pocEmail}
														onChange={(n, v) =>
															hook.setInput('demographics', n, v)
														}>
														{t('demographicAssessment.emailPointOfContact')}
													</AssessmentInput>
												</div>
											</Col>
										</Row>
										{/* End Row 2 */}
									</TabPane>
									<TabPane tabId="3">
										<Card>
											<CardBody>
												<Row>
													<Col xs="8">
														<h5 className="mb-4">
															{t('demographicAssessment.modeRiskAssessment')}
														</h5>
														<div className="mb-3">
															<input
																className="pl-5 me-4"
																type="radio"
																id="express"
																value="0"
																disabled={false == hook.reviewValid}
																checked={mode === 0}
																onClick={() => setMode(0)}
																name="mode"></input>
															<label
																className={
																	hook.reviewValid ? 'me-6' : 'me-6 text-muted'
																}
																htmlFor="express">
																{t('demographicAssessment.expressMode')}
															</label>
															<input
																className="pl-5 me-4"
																type="radio"
																id="guided"
																value="1"
																checked={mode === 1}
																onClick={() => setMode(1)}
																name="mode"></input>
															<label htmlFor="guided">
																{t('demographicAssessment.guidedMode')}
															</label>
														</div>
														{mode === 0 && (
															<div>
																<p>
																	{t(
																		'demographicAssessment.modeExpressdescription'
																	)}
																</p>
															</div>
														)}
														{mode === 1 && (
															<div>
																<p>
																	{t('demographicAssessment.modeDescription')}
																</p>
																{!hook.reviewValid && (
																	<p>
																		{t(
																			'demographicAssessment.modeReviewInvalid'
																		)}
																	</p>
																)}
															</div>
														)}
													</Col>
													<Col xs="4">
														<h5 className="mb-1">{t('table.reportName')}</h5>
														<div>
															<AssessmentInput
																labelfor="reportName"
																placeholder={'Type here'}
																value={reportName}
																onChange={(n, v) => setReportName(v)}
																validator={e => e.length > 0 && e.length < 31}
															/>
														</div>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</TabPane>
								</TabContent>
							</>
						)}

						<div className="d-flex flex-row-reverse">
							<div className="p-2">
								<Button
									disabled={disableButton(activeTab, hook)}
									className="p-2"
									color="primary"
									style={{ borderRadius: '25px' }}
									size="md"
									onClick={() => nextClicked()}>
									{activeTab === '3'
										? mode === 0
											? t('button.startResourceAnalysis')
											: t('button.proceed')
										: t('button.next')}
									<img
										src={Next}
										style={{ marginLeft: '10px' }}
										alt="arrowNext"
									/>
								</Button>
							</div>
						</div>
					</CardBody>
				</Card>
			</Container>
		)
	);
};

export default DemographicAssessment;
