import React, { useState } from 'react';
import {
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from 'reactstrap';

import { ReactComponent as ChevronSvg } from '../../../assets/img/chevron.svg';
import { useTranslation } from 'react-i18next';

const defaultName = 'Conformance Pack';

const SelectPack = props => {
	const packs = Object.keys(props.packs);
	const { t } = useTranslation();
	const [packName, setPackName] = useState(t('topology.conformancePack'));
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen(prevState => !prevState);
	const itemSelect = itemname => {
		setPackName(itemname);
		props.changepack(itemname);
	};

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle
				size="sm"
				className={`my-2-step legend-button legend-button_dropdown ${
					packName !== defaultName ? 'legend-button_dropdown_selected' : ''
				}`}>
				{packName}
				<ChevronSvg className="dropdown-arrow" />
			</DropdownToggle>
			<DropdownMenu style={{ top: 'unset' }}>
				{packs.map(item => (
					<DropdownItem key={item} onClick={() => itemSelect(item)}>
						{item}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

export default SelectPack;
