import { Col, Container, Row } from 'reactstrap';

import { ComplianceType } from '../../../constants/ComplianceType';
import FixComplianceButton from './FixComplianceButton';
import React from 'react';
import { t } from 'i18next';

const complianceType = new ComplianceType();

export default function ({ row, remediation }) {
	const isCompliant = complianceType.isCompliant(row.Result);

	if (isCompliant) {
		return <></>;
	}

	return (
		<Container className="no-gutters">
			<Row>
				<Col xs="10">
					<h5>{t('tests.whatIsProblem')}</h5>
					<p>{row.RootCause}</p>

					<h5>{t('tests.howFix')}</h5>
					{remediation && (
						<ul>
							{remediation.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					)}
				</Col>
				<Col xs="2">
					<FixComplianceButton data={row} />
				</Col>
			</Row>
		</Container>
	);
}
