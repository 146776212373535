import { Button } from 'reactstrap';
import ErrorModal from './ErrorModal';
import React from 'react';

const ActionButton = ({
	onBtnClick,
	isBusy,
	error,
	text,
	errorText,
	colour,
}) => {
	return (
		<>
			<Button
				color={colour}
				size="md"
				className="me-1"
				onClick={onBtnClick}
				disabled={isBusy}>
				{isBusy && (
					<div role="status" className="spinner-border spinner-border-sm mb-1">
						<span className="sr-only"></span>
					</div>
				)}
				<span className="ms-1">{text}</span>
			</Button>
			{error && <ErrorModal text={errorText} />}
		</>
	);
};

export default ActionButton;
