import React, { useState } from 'react';
import {
	Dropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from 'reactstrap';

import { ReactComponent as ChevronSvg } from '../../../assets/img/chevron.svg';
import { useTranslation } from 'react-i18next';

const defaultReport = 'Report';

const SelectReport = props => {
	const { t } = useTranslation();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [reportName, setReportName] = useState(t('topology.report'));

	const toggle = () => setDropdownOpen(prevState => !prevState);
	const itemSelect = item => {
		setReportName(item.ReportId);
		props.changeReport(item);
	};
	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle
				size="sm"
				className={`legend-button legend-button_dropdown ${
					reportName !== defaultReport ? 'legend-button_dropdown_selected' : ''
				}`}>
				{reportName}
				<ChevronSvg className="dropdown-arrow" />
			</DropdownToggle>
			<DropdownMenu>
				{props.reports.map(item => (
					<DropdownItem key={item.ReportId} onClick={() => itemSelect(item)}>
						{item.ReportId}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	);
};

export default SelectReport;
