import {
  Col,
} from "reactstrap";
import React from "react";

export default (props) => {

  const
    text = props.data.text,
    multiple = props.useList || (text && text.length > 1);

  return (
    <Col>
      <div className="card shadow-sm p-3 mb-5 bg-white rounded">
        <div className="card-header card-title " style={{ backgroundColor: props.data.titleBackground }}>
          <h4
            className="text-capitalize"
            style={{ backgroundColor: props.data.titleBackground }}>
            {props.data.title}
          </h4>
        </div>
        <div className="card-body">
          <div className="card-text">
            {multiple &&
              <ul style={{ marginLeft: '-2rem' }}>
                {props.data.text.map((item, index) => <li className="mt-3" key={index}>{item}</li>)}
              </ul>
            }
            {!multiple && <p>{text[0]}</p>}
          </div>
        </div>
      </div>
    </Col>
  );
};