import { getAuditRefData } from "./Constants";

export default function (report) {
	
	const refData = getAuditRefData(report.pack),
		testCases = refData.testCases,
		childTestCases = refData.childTestCases,
		categories = refData.categories,
		subCategories = refData.subcategories,
		subCategoryIds = Object.keys(subCategories),
		firstSubCategoryId = subCategoryIds[0];

	return {
		testCases,
		childTestCases,
		pack: report.pack,
		categories,
		subCategories,
		subCategoryIds,
		firstSubCategoryId
	};
}