import { Card, CardBody } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const Error = () => {
	const centeredContent =
		'row align-items-center d-flex justify-content-center m-5';

	return (
		<Card>
			<CardBody>
				<div className={centeredContent}>
					<h4 className="text-danger">{t('report.error')}</h4>
				</div>
			</CardBody>
		</Card>
	);
};

export default Error;
