let index = 0;

const getIndex = () => index++;

const data = {
	"categories": {
		"cc1.1": {
		 "name": "CC1.1",
		 "description": "COSO Principle 1 -The entity demonstrates a commitment to integrity and ethical values.",
		 "subcategories": [
			"cc1.1.1",
			"cc1.1.2",
			"cc1.1.3",
			"cc1.1.4",
			"cc1.1.5"
		 ]
		},
		"cc1.2": {
		 "name": "CC1.2",
		 "description": "COSO Principle 2 -The board of directors demonstrates independence from management and exercises oversight of the development and performance of internal control.",
		 "subcategories": [
			"cc1.2.1",
			"cc1.2.2",
			"cc1.2.3",
			"cc1.2.4"
		 ]
		},
		"cc1.3": {
		 "name": "CC1.3",
		 "description": "COSO Principle 3 -Management establishes, with board oversight, structures, reporting lines, and appropriate authorities and responsibilities in the pursuit of objectives.",
		 "subcategories": [
			"cc1.3.1",
			"cc1.3.2",
			"cc1.3.3",
			"cc1.3.4"
		 ]
		},
		"cc1.4": {
		 "name": "CC1.4",
		 "description": "COSO Principle 4 -The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.",
		 "subcategories": [
			"cc1.4.1",
			"cc1.4.2",
			"cc1.4.3",
			"cc1.4.4",
			"cc1.4.5",
			"cc1.4.6",
			"cc1.4.7"
		 ]
		},
		"cc1.5": {
		 "name": "CC1.5",
		 "description": "COSO Principle 5 -The entity holds individuals accountable for their internal control responsibilities in the pursuit of objectives.",
		 "subcategories": [
			"cc1.5.1",
			"cc1.5.2",
			"cc1.5.3",
			"cc1.5.4",
			"cc1.5.5"
		 ]
		},
		"cc2.1": {
		 "name": "CC2.1",
		 "description": "COSO Principle 13 -The entity obtains or generates and uses relevant, quality information to support the functioning of internal control.",
		 "subcategories": [
			"cc2.1.1",
			"cc2.1.2",
			"cc2.1.3",
			"cc2.1.4"
		 ]
		},
		"cc2.2": {
		 "name": "CC2.2",
		 "description": "COSO Principle 14 -The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.",
		 "subcategories": [
			"cc2.2.1",
			"cc2.2.2",
			"cc2.2.3",
			"cc2.2.4",
			"cc2.2.5",
			"cc2.2.6",
			"cc2.2.7",
			"cc2.2.8",
			"cc2.2.9",
			"cc2.2.10",
			"cc2.2.11"
		 ]
		},
		"cc2.3": {
		 "name": "CC2.3",
		 "description": "COSO Principle 15 -The entity communicates with external parties regarding matters affecting the functioning of internal control.",
		 "subcategories": [
			"cc2.3.1",
			"cc2.3.2",
			"cc2.3.3",
			"cc2.3.4",
			"cc2.3.5",
			"cc2.3.6",
			"cc2.3.7",
			"cc2.3.8",
			"cc2.3.9",
			"cc2.3.10",
			"cc2.3.11"
		 ]
		},
		"cc3.1": {
		 "name": "CC 3.1",
		 "description": "COSO Principle 6 -The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.",
		 "subcategories": [
			"cc3.1.1",
			"cc3.1.2",
			"cc3.1.3",
			"cc3.1.4",
			"cc3.1.5",
			"cc3.1.6",
			"cc3.1.7",
			"cc3.1.8",
			"cc3.1.9",
			"cc3.1.10",
			"cc3.1.11",
			"cc3.1.12",
			"cc3.1.13",
			"cc3.1.14",
			"cc3.1.15",
			"cc3.1.16"
		 ]
		},
		"cc3.2": {
		 "name": "CC 3.2",
		 "description": "COSO Principle 7 -The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.",
		 "subcategories": [
			"cc3.2.1",
			"cc3.2.2",
			"cc3.2.3",
			"cc3.2.4",
			"cc3.2.5",
			"cc3.2.6",
			"cc3.2.7",
			"cc3.2.8"
		 ]
		},
		"cc3.3": {
		 "name": "CC 3.3",
		 "description": "COSO Principle 8 -The entity considers the potential for fraud in assessing risks to the achievement of objectives.",
		 "subcategories": [
			"cc3.3.1",
			"cc3.3.2",
			"cc3.3.3",
			"cc3.3.4",
			"cc3.3.5"
		 ]
		},
		"cc3.4": {
		 "name": "CC 3.4",
		 "description": "COSO Principle 9 -The entity identifies and assesses changes that could significantly impact the system of internal control.",
		 "subcategories": [
			"cc3.4.1",
			"cc3.4.2",
			"cc3.4.3",
			"cc3.4.4",
			"cc3.4.5"
		 ]
		},
		"cc4.1": {
		 "name": "CC 4.1",
		 "description": "COSO Principle 16 -The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.",
		 "subcategories": [
			"cc4.1.1",
			"cc4.1.2",
			"cc4.1.3",
			"cc4.1.4",
			"cc4.1.5",
			"cc4.1.6",
			"cc4.1.7",
			"cc4.1.8"
		 ]
		},
		"cc4.2": {
		 "name": "CC 4.2",
		 "description": "COSO Principle 17 -The entity evaluates and communicates internal control deficiencies in a timely manner to those parties responsible for taking corrective action, including senior management and the board of directors, as appropriate.",
		 "subcategories": [
			"cc4.2.1",
			"cc4.2.2",
			"cc4.2.3"
		 ]
		},
		"cc5.1": {
		 "name": "CC 5.1",
		 "description": "COSO Principle 10 -The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.",
		 "subcategories": [
			"cc5.1.1",
			"cc5.1.2",
			"cc5.1.3",
			"cc5.1.4",
			"cc5.1.5",
			"cc5.1.6"
		 ]
		},
		"cc5.2": {
		 "name": "CC 5.2",
		 "description": "COSO Principle 11 -The entity also selects and develops general control activities over technology to support the achievement of objectives.",
		 "subcategories": [
			"cc5.2.1",
			"cc5.2.2",
			"cc5.2.3",
			"cc5.2.4"
		 ]
		},
		"cc5.3": {
		 "name": "CC 5.3",
		 "description": "COSO Principle 12 -The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.",
		 "subcategories": [
			"cc5.3.1",
			"cc5.3.2",
			"cc5.3.3",
			"cc5.3.4",
			"cc5.3.5",
			"cc5.3.6"
		 ]
		},
		"cc7.3": {
		 "name": "CC 7.3",
		 "description": "The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.",
		 "subcategories": [
			"cc7.3.1"
		 ]
		},
		"cc7.4": {
		 "name": "CC 7.4",
		 "description": "The entity responds to identified security incidents by executing a defined incident-response program to understand, contain, remediate, and communicate security incidents, as appropriate.",
		 "subcategories": [
			"cc7.4.1",
			"cc7.4.2",
			"cc7.4.3",
			"cc7.4.4",
			"cc7.4.5",
			"cc7.4.6",
			"cc7.4.7",
			"cc7.4.8",
			"cc7.4.9",
			"cc7.4.10",
			"cc7.4.11",
			"cc7.4.12",
			"cc7.4.13"
		 ]
		},
		"cc7.5": {
		 "name": "CC 7.5",
		 "description": "The entity identifies, develops, and implements activities to recover from identified security incidents.",
		 "subcategories": [
			"cc7.5.1",
			"cc7.5.2",
			"cc7.5.3",
			"cc7.5.4",
			"cc7.5.5",
			"cc7.5.6"
		 ]
		},
		"cc8.1": {
		 "name": "CC 8.1",
		 "description": "The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.",
		 "subcategories": [
			"cc8.1.3",
			"cc8.1.6"
		 ]
		},
		"cc9.1": {
		 "name": "CC 9.1",
		 "description": "The entity identifies, selects, and develops risk mitigation activities for risks arising from potential business disruptions.",
		 "subcategories": [
			"cc9.1.2"
		 ]
		},
		"cc9.2": {
		 "name": "CC 9.2",
		 "description": "The entity assesses and manages risks associated with vendors and business partners.",
		 "subcategories": [
			"cc9.2.8",
			"cc9.2.11",
			"cc9.2.12"
		 ]
		},
		"pi1.1": {
		 "name": "PI 1.1",
		 "description": "The entity obtains or generates, uses, and communicates relevant, quality information regarding the objectives related to processing, including definitions of data processed and product and service specifications, to support the use of products and services.",
		 "subcategories": [
			"pi1.1.1",
			"pi1.1.2",
			"pi1.1.3"
		 ]
		},
		"pi1.2": {
		 "name": "PI 1.2",
		 "description": "The entity implements policies and procedures over system inputs, including controls over completeness and accuracy, to result in products, services, and reporting to meet the entity’s objectives.",
		 "subcategories": [
			"pi1.2.1",
			"pi1.2.2"
		 ]
		},
		"pi1.3": {
		 "name": "PI 1.3",
		 "description": "The entity implements policies and procedures over system processing to result in products, services, and reporting to meet the entity’s objectives.",
		 "subcategories": [
			"pi1.3.1",
			"pi1.3.2",
			"pi1.3.3",
			"pi1.3.5"
		 ]
		},
		"pi1.4": {
		 "name": "PI 1.4",
		 "description": "The entity implements policies and procedures to make available or deliver output completely, accurately, and timely in accordance with specifications to meet the entity’s objectives.",
		 "subcategories": [
			"pi1.4.2",
			"pi1.4.3"
		 ]
		},
		"p1.1": {
		 "name": "P 1.1",
		 "description": "The entity provides notice to data subjects about its privacy practices to meet the entity’s objectives related to privacy. The notice is updated and communicated to data subjects in a timely manner for changes to the entity’s privacy practices, including changes in the use of personal information, to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p1.1.1",
			"p1.1.2",
			"p1.1.3",
			"p1.1.4"
		 ]
		},
		"p2.1": {
		 "name": "P 2.1",
		 "description": "The entity communicates choices available regarding the collection, use, retention, disclosure, and disposal of personal information to the data subjects and the consequences, if any, of each choice. Explicit consent for the collection, use, retention, disclosure, and disposal of personal information is obtained from data subjects or other authorized persons, if required. Such consent is obtained only for the intended purpose of the information to meet the entity’s objectives related to privacy. The entity’s basis for determining implicit consent for the collection, use, retention, disclosure, and disposal of personal information is documented.",
		 "subcategories": [
			"p2.1.1",
			"p2.1.2",
			"p2.1.3",
			"p2.1.4",
			"p2.1.5",
			"p2.1.6"
		 ]
		},
		"p3.1": {
		 "name": "P 3.1",
		 "description": "Personal information is collected consistent with the entity’s objectives related to privacy.",
		 "subcategories": [
			"p3.1.1",
			"p3.1.2",
			"p3.1.3",
			"p3.1.4"
		 ]
		},
		"p3.2": {
		 "name": "P 3.2",
		 "description": "For information requiring explicit consent, the entity communicates the need for such consent as well as the consequences of a failure to provide consent for the request for personal information and obtains the consent prior to the collection of the information to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p3.2.1",
			"p3.2.2"
		 ]
		},
		"p4.1": {
		 "name": "P 4.1",
		 "description": "The entity limits the use of personal information to the purposes identified in the entity’s objectives related to privacy.",
		 "subcategories": [
			"p4.1.1"
		 ]
		},
		"p4.2": {
		 "name": "P 4.2",
		 "description": "The entity retains personal information consistent with the entity’s objectives related to privacy.",
		 "subcategories": [
			"p4.2.1",
			"p4.2.2"
		 ]
		},
		"p4.3": {
		 "name": "P 4.3",
		 "description": "The entity securely disposes of personal information to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p4.3.1",
			"p4.3.2",
			"p4.3.3"
		 ]
		},
		"p5.1": {
		 "name": "P 5.1",
		 "description": "The entity grants identified and authenticated data subjects the ability to access their stored personal information for review and, upon request, provides physical or electronic copies of that information to data subjects to meet the entity’s objectives related to privacy. If access is denied, data subjects are informed of the denial and reason for such denial, as required, to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p5.1.3",
			"p5.1.4"
		 ]
		},
		"p5.2": {
		 "name": "P 5.2",
		 "description": "The entity corrects, amends, or appends personal information based on information provided by data subjects and communicates such information to third parties, as committed or required, to  meet the entity’s objectives related to privacy. If a request for correction is denied, data subjects are informed of the denial and reason for such denial to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p5.2.1",
			"p5.2.3"
		 ]
		},
		"p6.1": {
		 "name": "P 6.1",
		 "description": "The entity discloses personal information to third parties with the explicit consent of data subjects and such consent is obtained prior to disclosure to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p6.1.1",
			"p6.1.2",
			"p6.1.3",
			"p6.1.4"
		 ]
		},
		"p6.2": {
		 "name": "P 6.2",
		 "description": "The entity creates and retains a complete, accurate, and timely record of authorized disclosures of personal information to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p6.2.1"
		 ]
		},
		"p6.3": {
		 "name": "P 6.3",
		 "description": "The entity creates and retains a complete, accurate, and timely record of detected or reported unauthorized disclosures (including breaches) of personal information to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p6.3.1"
		 ]
		},
		"p6.4": {
		 "name": "P 6.4",
		 "description": "The entity obtains privacy commitments from vendors and other third parties who have access to personal information to meet the entity’s objectives related to privacy. The entity assesses those parties’ compliance on a periodic and as-needed basis and takes corrective action, if necessary.",
		 "subcategories": [
			"p6.4.1",
			"p6.4.2"
		 ]
		},
		"p6.5": {
		 "name": "P 6.5",
		 "description": "The entity obtains commitments from vendors and other third parties with access to personal information to notify the entity in the event of actual or suspected unauthorized disclosures of personal information. Such notifications are reported to appropriate personnel and acted on in accordance  with established incident-response procedures to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p6.5.1",
			"p6.5.2"
		 ]
		},
		"p6.6": {
		 "name": "P 6.6",
		 "description": "The entity provides notification of breaches and incidents to affected data subjects, regulators, and others to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p6.6.1",
			"p6.6.2"
		 ]
		},
		"p6.7": {
		 "name": "P 6.7",
		 "description": "The entity provides data subjects with an accounting of the personal information held and disclosure of the data subjects’ personal information, upon the data subjects’ request, to meet the entity’s  objectives related to privacy.",
		 "subcategories": [
			"p6.7.1",
			"p6.7.2"
		 ]
		},
		"p7.1": {
		 "name": "P 7.1",
		 "description": "The entity collects and maintains accurate, up-to-date, complete, and relevant personal information to meet the entity’s objectives related to privacy.",
		 "subcategories": [
			"p7.1.1",
			"p7.1.2"
		 ]
		},
		"p8.1": {
		 "name": "P 8.1",
		 "description": "The entity implements a process for receiving, addressing, resolving, and communicating the resolution of inquiries, complaints, and disputes from data subjects and others and periodically monitors compliance to meet the entity’s objectives related to privacy. Corrections and other necessary actions related to identified deficiencies are made or taken in a timely manner.",
		 "subcategories": [
			"p8.1.1",
			"p8.1.2",
			"p8.1.3",
			"p8.1.4"
		 ]
		}
	 },
	"subcategories": {
		"cc1.1.1": { "name": "", "description": "Sets the Tone at the Top", index: getIndex() },
		"cc1.1.2": { "name": "", "description": "Establishes Standards of Conduct", index: getIndex() },
		"cc1.1.3": { "name": "", "description": "Evaluates Adherence to Standards of Conduct", index: getIndex() },
		"cc1.1.4": { "name": "", "description": "Addresses Deviations in a Timely Manner", index: getIndex() },
		"cc1.1.5": { "name": "", "description": "Considers Contractors and Vendor Employees in Demonstrating Its Commitment", index: getIndex() },
		"cc1.2.1": { "name": "", "description": "Establishes Oversight Responsibilities", index: getIndex() },
		"cc1.2.2": { "name": "", "description": "Applies Relevant Expertise", index: getIndex() },
		"cc1.2.3": { "name": "", "description": "Operates Independently", index: getIndex() },
		"cc1.2.4": { "name": "", "description": "Supplements Board Expertise", index: getIndex() },
		"cc1.3.1": { "name": "", "description": "Considers All Structures of the Entity", index: getIndex() },
		"cc1.3.2": { "name": "", "description": "Establishes Reporting Lines", index: getIndex() },
		"cc1.3.3": { "name": "", "description": "Defines, Assigns, and Limits Authorities and Responsibilities", index: getIndex() },
		"cc1.3.4": { "name": "", "description": "Addresses Specific Requirements When Defining Authorities and Responsibilities", index: getIndex() },
		"cc1.4.1": { "name": "", "description": "Establishes Policies and Practices", index: getIndex() },
		"cc1.4.2": { "name": "", "description": "Evaluates Competence and Addresses Shortcomings", index: getIndex() },
		"cc1.4.3": { "name": "", "description": "Attracts, Develops, and Retains Individuals", index: getIndex() },
		"cc1.4.4": { "name": "", "description": "Plans and Prepares for Succession", index: getIndex() },
		"cc1.4.5": { "name": "", "description": "Considers the Background of Individuals", index: getIndex() },
		"cc1.4.6": { "name": "", "description": "Considers the Technical Competency of Individuals", index: getIndex() },
		"cc1.4.7": { "name": "", "description": "Provides Training to Maintain Technical Competencies", index: getIndex() },
		"cc1.5.1": { "name": "", "description": "Enforces Accountability Through Structures, Authorities, and Responsibilities", index: getIndex() },
		"cc1.5.2": { "name": "", "description": "Establishes Performance Measures, Incentives, and Rewards", index: getIndex() },
		"cc1.5.3": { "name": "", "description": "Evaluates Performance Measures, Incentives, and Rewards for Ongoing Relevance", index: getIndex() },
		"cc1.5.4": { "name": "", "description": "Considers Excessive Pressures", index: getIndex() },
		"cc1.5.5": { "name": "", "description": "Evaluates Performance and Rewards or Disciplines Individuals", index: getIndex() },
		"cc2.1.1": { "name": "", "description": "Identifies Information Requirements", index: getIndex() },
		"cc2.1.2": { "name": "", "description": "Captures Internal and External Sources of Data", index: getIndex() },
		"cc2.1.3": { "name": "", "description": "Processes Relevant Data Into Information", index: getIndex() },
		"cc2.1.4": { "name": "", "description": "Maintains Quality Throughout Processing", index: getIndex() },
		"cc2.2.1": { "name": "", "description": "Communicates Internal Control Information", index: getIndex() },
		"cc2.2.2": { "name": "", "description": "Communicates With the Board of Directors", index: getIndex() },
		"cc2.2.3": { "name": "", "description": "Provides Separate Communication Lines", index: getIndex() },
		"cc2.2.4": { "name": "", "description": "Selects Relevant Method of Communication", index: getIndex() },
		"cc2.2.5": { "name": "", "description": "Communicates Responsibilities", index: getIndex() },
		"cc2.2.6": { "name": "", "description": "Communicates Information on Reporting Failures, Incidents, Concerns, and Other Matters", index: getIndex() },
		"cc2.2.7": { "name": "", "description": "Communicates Objectives and Changes to Objectives", index: getIndex() },
		"cc2.2.8": { "name": "", "description": "Communicates Information to Improve Security Knowledge and Awareness", index: getIndex() },
		"cc2.2.9": { "name": "", "description": "Communicates Information About System Operation and Boundaries", index: getIndex() },
		"cc2.2.10": { "name": "", "description": "Communicates System Objectives", index: getIndex() },
		"cc2.2.11": { "name": "", "description": "Communicates System Changes", index: getIndex() },
		"cc2.3.1": { "name": "", "description": "Communicates to External Parties", index: getIndex() },
		"cc2.3.2": { "name": "", "description": "Enables Inbound Communications", index: getIndex() },
		"cc2.3.3": { "name": "", "description": "Communicates With the Board of Directors", index: getIndex() },
		"cc2.3.4": { "name": "", "description": "Provides Separate Communication Lines", index: getIndex() },
		"cc2.3.5": { "name": "", "description": "Selects Relevant Method of Communication", index: getIndex() },
		"cc2.3.6": { "name": "", "description": "Communicates Objectives Related to Confidentiality and Changes to Objectives", index: getIndex() },
		"cc2.3.7": { "name": "", "description": "Communicates Objectives Related to Privacy and Changes to Objectives", index: getIndex() },
		"cc2.3.8": { "name": "", "description": "Communicates Information About System Operation and Boundaries", index: getIndex() },
		"cc2.3.9": { "name": "", "description": "Communicates System Objectives", index: getIndex() },
		"cc2.3.10": { "name": "", "description": "Communicates System Responsibilities", index: getIndex() },
		"cc2.3.11": { "name": "", "description": "Communicates Information on Reporting System Failures, Incidents, Concerns, and Other Matters", index: getIndex() },
		"cc3.1.1": { "name": "", "description": "Reflects Management's Choices - Operations Objectives", index: getIndex() },
		"cc3.1.2": { "name": "", "description": "Considers Tolerances for Risk - Operations Objectives", index: getIndex() },
		"cc3.1.3": { "name": "", "description": "Includes Operations and Financial Performance Goals - Operations Objectives", index: getIndex() },
		"cc3.1.4": { "name": "", "description": "Forms a Basis for Committing of Resources - Operations Objectives", index: getIndex() },
		"cc3.1.5": { "name": "", "description": "Complies With Applicable Accounting Standards - External Financial Reporting Objectives", index: getIndex() },
		"cc3.1.6": { "name": "", "description": "Considers Materiality - External Financial Reporting Objectives", index: getIndex() },
		"cc3.1.7": { "name": "", "description": "Reflects Entity Activities - External Financial Reporting Objectives", index: getIndex() },
		"cc3.1.8": { "name": "", "description": "Complies With Externally Established Frameworks - External Nonfinancial Reporting Objectives", index: getIndex() },
		"cc3.1.9": { "name": "", "description": "Considers the Required Level of Precision - External Nonfinancial Reporting Objectives", index: getIndex() },
		"cc3.1.10": { "name": "", "description": "Reflects Entity Activities - External Nonfinancial Reporting Objectives", index: getIndex() },
		"cc3.1.11": { "name": "", "description": "Reflects Management's Choices - Internal Reporting Objectives", index: getIndex() },
		"cc3.1.12": { "name": "", "description": "Considers the Required Level of Precision - Internal Reporting Objectives", index: getIndex() },
		"cc3.1.13": { "name": "", "description": "Reflects Entity Activities - Internal Reporting Objectives", index: getIndex() },
		"cc3.1.14": { "name": "", "description": "Reflects External Laws and Regulations - Compliance Objectives", index: getIndex() },
		"cc3.1.15": { "name": "", "description": "Considers Tolerances for Risk Compliance Objectives", index: getIndex() },
		"cc3.1.16": { "name": "", "description": "Establishes Sub-objectives to Support Objectives", index: getIndex() },
		"cc3.2.1": { "name": "", "description": "Includes Entity, Subsidiary, Division, Operating Unit, and Functional Levels", index: getIndex() },
		"cc3.2.2": { "name": "", "description": "Analyzes Internal and External Factors", index: getIndex() },
		"cc3.2.3": { "name": "", "description": "Involves Appropriate Levels of Management", index: getIndex() },
		"cc3.2.4": { "name": "", "description": "Estimates Significance of Risks Identified", index: getIndex() },
		"cc3.2.5": { "name": "", "description": "Determines How to Respond to Risks", index: getIndex() },
		"cc3.2.6": { "name": "", "description": "Identifies and Assesses Criticality of Information Assets and Identifies Threats and Vulnerabilities", index: getIndex() },
		"cc3.2.7": { "name": "", "description": "Analyzes Threats and Vulnerabilities From Vendors, Business Partners, and Other Parties", index: getIndex() },
		"cc3.2.8": { "name": "", "description": "Considers the Significance of the Risk", index: getIndex() },
		"cc3.3.1": { "name": "", "description": "Considers Various Types of Fraud", index: getIndex() },
		"cc3.3.2": { "name": "", "description": "Assesses Incentives and Pressures", index: getIndex() },
		"cc3.3.3": { "name": "", "description": "Assesses Opportunities", index: getIndex() },
		"cc3.3.4": { "name": "", "description": "Assesses Attitudes and Rationalizations", index: getIndex() },
		"cc3.3.5": { "name": "", "description": "Considers the Risks Related to the Use of IT and Access to Information", index: getIndex() },
		"cc3.4.1": { "name": "", "description": "Assesses Changes in the External Environment", index: getIndex() },
		"cc3.4.2": { "name": "", "description": "Assesses Changes in the Business Model", index: getIndex() },
		"cc3.4.3": { "name": "", "description": "Assesses Changes in Leadership", index: getIndex() },
		"cc3.4.4": { "name": "", "description": "Assesses Changes in Systems and Technology", index: getIndex() },
		"cc3.4.5": { "name": "", "description": "Assesses Changes in Vendor and Business Partner Relationships", index: getIndex() },
		"cc4.1.1": { "name": "", "description": "Considers a Mix of Ongoing and Separate Evaluations", index: getIndex() },
		"cc4.1.2": { "name": "", "description": "Considers Rate of Change", index: getIndex() },
		"cc4.1.3": { "name": "", "description": "Establishes Baseline Understanding", index: getIndex() },
		"cc4.1.4": { "name": "", "description": "Uses edgeable Personnel", index: getIndex() },
		"cc4.1.5": { "name": "", "description": "Integrates With Business Processes", index: getIndex() },
		"cc4.1.6": { "name": "", "description": "Objectively Evaluates", index: getIndex() },
		"cc4.1.7": { "name": "", "description": "Considers Different Types of Ongoing and Separate Evaluations", index: getIndex() },
		"cc4.1.8": { "name": "", "description": "Adjusts Scope and Frequency", index: getIndex() },
		"cc4.2.1": { "name": "", "description": "Assesses Results", index: getIndex() },
		"cc4.2.2": { "name": "", "description": "Communicates Deficiencies", index: getIndex() },
		"cc4.2.3": { "name": "", "description": "Monitors Corrective Action", index: getIndex() },
		"cc5.1.1": { "name": "", "description": "Integrates With Risk Assessment", index: getIndex() },
		"cc5.1.2": { "name": "", "description": "Considers Entity-Specific Factors", index: getIndex() },
		"cc5.1.3": { "name": "", "description": "Determines Relevant Business Processes", index: getIndex() },
		"cc5.1.4": { "name": "", "description": "Evaluates a Mix of Control Activity Types", index: getIndex() },
		"cc5.1.5": { "name": "", "description": "Considers at What Level Activities Are Applied", index: getIndex() },
		"cc5.1.6": { "name": "", "description": "Addresses Segregation of Duties", index: getIndex() },
		"cc5.2.1": { "name": "", "description": "Determines Dependency Between the Use of Technology in Business Processes and Technology General Controls", index: getIndex() },
		"cc5.2.2": { "name": "", "description": "Establishes Relevant Technology Infrastructure Control Activities", index: getIndex() },
		"cc5.2.3": { "name": "", "description": "Establishes Relevant Security Management Process Controls Activities", index: getIndex() },
		"cc5.2.4": { "name": "", "description": "Establishes Relevant Technology Acquisition, Development, and Maintenance Process Control Activities", index: getIndex() },
		"cc5.3.1": { "name": "", "description": "Establishes Policies and Procedures to Support Deployment of Management’s Directives", index: getIndex() },
		"cc5.3.2": { "name": "", "description": "Establishes Responsibility and Accountability for Executing Policies and Procedures", index: getIndex() },
		"cc5.3.3": { "name": "", "description": "Performs in a Timely Manner", index: getIndex() },
		"cc5.3.4": { "name": "", "description": "Takes Corrective Action", index: getIndex() },
		"cc5.3.5": { "name": "", "description": "Performs Using Competent Personnel", index: getIndex() },
		"cc5.3.6": { "name": "", "description": "Reassesses Policies and Procedures", index: getIndex() },
		"cc7.3.1": { "name": "", "description": "Determines Personal Information Used or Disclosed", index: getIndex() },
		"cc7.4.1": { "name": "", "description": "Assigns Roles and Responsibilities", index: getIndex() },
		"cc7.4.2": { "name": "", "description": "Contains Security Incidents", index: getIndex() },
		"cc7.4.3": { "name": "", "description": "Mitigates Ongoing Security Incidents", index: getIndex() },
		"cc7.4.4": { "name": "", "description": "Ends Threats Posed by Security Incidents", index: getIndex() },
		"cc7.4.5": { "name": "", "description": "Restores Operations", index: getIndex() },
		"cc7.4.6": { "name": "", "description": "Develops and Implements Communication Protocols for Security Incidents", index: getIndex() },
		"cc7.4.7": { "name": "", "description": "Obtains Understanding of Nature of Incident and Determines Containment Strategy", index: getIndex() },
		"cc7.4.8": { "name": "", "description": "Remediates Identified Vulnerabilities", index: getIndex() },
		"cc7.4.9": { "name": "", "description": "Communicates Remediation Activities", index: getIndex() },
		"cc7.4.10": { "name": "", "description": "Evaluates the Effectiveness of Incident Response", index: getIndex() },
		"cc7.4.11": { "name": "", "description": "Periodically Evaluates Incidents", index: getIndex() },
		"cc7.4.12": { "name": "", "description": "Communicates Unauthorized Use and Disclosure", index: getIndex() },
		"cc7.4.13": { "name": "", "description": "Application of Sanctions", index: getIndex() },
		"cc7.5.1": { "name": "", "description": "Restores the Affected Environment", index: getIndex() },
		"cc7.5.2": { "name": "", "description": "Communicates Information About the Event", index: getIndex() },
		"cc7.5.3": { "name": "", "description": "Determines Root Cause of the Event", index: getIndex() },
		"cc7.5.4": { "name": "", "description": "Implements Changes to Prevent and Detect Recurrences", index: getIndex() },
		"cc7.5.5": { "name": "", "description": "Improves Response and Recovery Procedures", index: getIndex() },
		"cc7.5.6": { "name": "", "description": "Implements Incident-Recovery Plan Testing", index: getIndex() },
		"cc8.1.3": { "name": "", "description": "Designs and Develops Changes", index: getIndex() },
		"cc8.1.6": { "name": "", "description": "Configures Software", index: getIndex() },
		"cc9.1.2": { "name": "", "description": "Considers the Use of Insurance to Mitigate Financial Impact Risks", index: getIndex() },
		"cc9.2.8": { "name": "", "description": "Implements Procedures for Terminating Vendor and Business Partner Relationships", index: getIndex() },
		"cc9.2.11": { "name": "", "description": "Obtains Privacy Commitments from Vendors and Business Partners", index: getIndex() },
		"cc9.2.12": { "name": "", "description": "Assesses Compliance with Privacy Commitments of Vendors and Business Partners", index: getIndex() },
		"pi1.1.1": { "name": "", "description": "Identifies Information Specifications", index: getIndex() },
		"pi1.1.2": { "name": "", "description": "Defines Data Necessary to Support a Product or Service", index: getIndex() },
		"pi1.1.3": { "name": "", "description": "Defines Information Necessary to Support the Use of a Good or Product", index: getIndex() },
		"pi1.2.1": { "name": "", "description": "Defines Characteristics of Processing Inputs", index: getIndex() },
		"pi1.2.2": { "name": "", "description": "Evaluates Processing Inputs", index: getIndex() },
		"pi1.3.1": { "name": "", "description": "Defines Processing Specifications", index: getIndex() },
		"pi1.3.2": { "name": "", "description": "Defines Processing Activities", index: getIndex() },
		"pi1.3.3": { "name": "", "description": "Detects and Corrects Production Errors", index: getIndex() },
		"pi1.3.5": { "name": "", "description": "Processes Inputs", index: getIndex() },
		"pi1.4.2": { "name": "", "description": "Distributes Output Only to Intended Parties", index: getIndex() },
		"pi1.4.3": { "name": "", "description": "Distributes Output Completely and Accurately", index: getIndex() },
		"p1.1.1": { "name": "", "description": "Communicates to Data Subjects", index: getIndex() },
		"p1.1.2": { "name": "", "description": "Provides Notice to Data Subjects", index: getIndex() },
		"p1.1.3": { "name": "", "description": "Covers Entities and Activities in Notice", index: getIndex() },
		"p1.1.4": { "name": "", "description": "Uses Clear and Conspicuous Language", index: getIndex() },
		"p2.1.1": { "name": "", "description": "Communicates to Data Subjects", index: getIndex() },
		"p2.1.2": { "name": "", "description": "Communicates Consequences of Denying or Withdrawing Consent", index: getIndex() },
		"p2.1.3": { "name": "", "description": "Obtains Implicit or Explicit Consent", index: getIndex() },
		"p2.1.4": { "name": "", "description": "Documents and Obtains Consent for New Purposes and Uses", index: getIndex() },
		"p2.1.5": { "name": "", "description": "Obtains Explicit Consent for Sensitive Information", index: getIndex() },
		"p2.1.6": { "name": "", "description": "Obtains Consent for Data Transfers", index: getIndex() },
		"p3.1.1": { "name": "", "description": "Limits the Collection of Personal Information — The collection of personal information", index: getIndex() },
		"p3.1.2": { "name": "", "description": "Collects Information by Fair and Lawful Means — Methods of collecting personal", index: getIndex() },
		"p3.1.3": { "name": "", "description": "Collects Information From Reliable Sources — Management confirms that third", index: getIndex() },
		"p3.1.4": { "name": "", "description": "Informs Data Subjects When Additional Information Is Acquired", index: getIndex() },
		"p3.2.1": { "name": "", "description": "Obtains Explicit Consent for Sensitive Information", index: getIndex() },
		"p3.2.2": { "name": "", "description": "Documents Explicit Consent to Retain Information", index: getIndex() },
		"p4.1.1": { "name": "", "description": "Uses Personal Information for Intended Purposes", index: getIndex() },
		"p4.2.1": { "name": "", "description": "Retains Personal Information", index: getIndex() },
		"p4.2.2": { "name": "", "description": "Protects Personal Information", index: getIndex() },
		"p4.3.1": { "name": "", "description": "Captures, Identifies, and Flags Requests for Deletion", index: getIndex() },
		"p4.3.2": { "name": "", "description": "Disposes of, Destroys, and Redacts Personal Information", index: getIndex() },
		"p4.3.3": { "name": "", "description": "Destroys Personal Information", index: getIndex() },
		"p5.1.3": { "name": "", "description": "Provides Understandable Personal Information Within Reasonable Time", index: getIndex() },
		"p5.1.4": { "name": "", "description": "Informs Data Subjects If Access Is Denied", index: getIndex() },
		"p5.2.1": { "name": "", "description": "Communicates Denial of Access Requests", index: getIndex() },
		"p5.2.3": { "name": "", "description": "Communicates Denial of Correction Requests", index: getIndex() },
		"p6.1.1": { "name": "", "description": "Communicates Privacy Policies to Third Parties", index: getIndex() },
		"p6.1.2": { "name": "", "description": "Discloses Personal Information Only When Appropriate", index: getIndex() },
		"p6.1.3": { "name": "", "description": "Discloses Personal Information Only to Appropriate Third Parties", index: getIndex() },
		"p6.1.4": { "name": "", "description": "Discloses Information to Third Parties for New Purposes and Uses", index: getIndex() },
		"p6.2.1": { "name": "", "description": "Creates and Retains Record of Authorized Disclosures", index: getIndex() },
		"p6.3.1": { "name": "", "description": "Creates and Retains Record of Detected or Reported Unauthorized Disclosures", index: getIndex() },
		"p6.4.1": { "name": "", "description": "Discloses Personal Information Only to Appropriate Third Parties", index: getIndex() },
		"p6.4.2": { "name": "", "description": "Remediates Misuse of Personal Information by a Third Party", index: getIndex() },
		"p6.5.1": { "name": "", "description": "Remediates Misuse of Personal Information by a Third Party", index: getIndex() },
		"p6.5.2": { "name": "", "description": "Reports Actual or Suspected Unauthorized Disclosures", index: getIndex() },
		"p6.6.1": { "name": "", "description": "Remediates Misuse of Personal Information by a Third Party", index: getIndex() },
		"p6.6.2": { "name": "", "description": "Provides Notice of Breaches and Incidents", index: getIndex() },
		"p6.7.1": { "name": "", "description": "Identifies Types of Personal Information and Handling Process", index: getIndex() },
		"p6.7.2": { "name": "", "description": "Captures, Identifies, and Communicates Requests for Information", index: getIndex() },
		"p7.1.1": { "name": "", "description": "Ensures Accuracy and Completeness of Personal Information", index: getIndex() },
		"p7.1.2": { "name": "", "description": "Ensures Relevance of Personal Information", index: getIndex() },
		"p8.1.1": { "name": "", "description": "Communicates to Data Subjects", index: getIndex() },
		"p8.1.2": { "name": "", "description": "Addresses Inquiries, Complaints, and Disputes", index: getIndex() },
		"p8.1.3": { "name": "", "description": "Documents and Communicates Dispute Resolution and Recourse", index: getIndex() },
		"p8.1.4": { "name": "", "description": "Documents and Reports Compliance Review Results", index: getIndex() },
	},
	"childTestCases": {
		"fn-controlEnvironment": {
			"id": "fn-controlEnvironment",
			"value": "Control Environment",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": ["Control Environment"],
			"categories": ["cc1.1", "cc1.2", "cc1.3", "cc1.4", "cc1.5"],
			"hrms": {
				"title": "HRMS Software",
				"description": "Bitrix free tool for HRMS",
				"link": "https://www.bitrix24.net/oauth/authorize/?user_lang=en&client_id=site.5602b8e8a41202.94213120&redirect_uri=https%3A%2F%2Fwww.bitrix24.in%2Fauth%2F%3Fauth_service_id%3DBitrix24Net&scope=auth,client&response_type=code&mode=page&state=site_id%3Din%26backurl%3D%252Fauth%252F%253Fcheck_key%253D881500cbdbfded77f8114e0d5b3f9a7b%26mode%3Dpage"
			}
		},
		"fn-CommunicationAndInformation": {
			"id": "fn-CommunicationAndInformation",
			"value": "Communication & Information",
			"fgColor": "#e9ecef",
			"bgColor": "#00b33c",
			"description": ["Communication & Information"],
			"categories": ["cc2.1", "cc2.2", "cc2.3"],
			"hrms": {
				"title": "COMMUNICATION TOOLS",
				"description": "Policy and Procedures",
				"link": "https://drive.google.com/drive/folders/1k-LvEEaGcWh_patA7C9KvZdvblflJAJS?usp=sharing"
			}
		},
		"fn-RiskAssessment": {
			"id": "fn-RiskAssessment",
			"value": "Risk Assessment",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": ["Risk Assessment"],
			"categories": ["cc3.1", "cc3.2", "cc3.3", "cc3.4", "cc4.1", "cc4.2"],
			"hrms": {
				"title": "MANUAL USING DOCUMENTATION TOOLS",
				"description": "Risk Assessment",
				"link": "https://drive.google.com/drive/folders/1PxpbKdv8hE8efHfVptERUgfNYiC32oBi?usp=sharing"
			}
		},
		"fn-ControlActivities": {
			"id": "fn-ControlActivities",
			"value": "Control Activities",
			"fgColor": "#e9ecef",
			"bgColor": "#00b33c",
			"description": ["Control Activities"],
			"categories": ["cc5.1", "cc5.2", "cc5.3"],
			"hrms": {
				"title": "MANUAL USING SPECIFIC TOOLS",
				"description": "Risk Management",
				"link": "https://drive.google.com/drive/folders/1g1ZF-YyjiEM4cXCDipsNHhcG2SPTcNr_?usp=sharing"
			}
		},
		/*"fn-LogicalAndPhysicalAccessControls": {
			"id": "fn-LogicalAndPhysicalAccessControls",
			"value": "Logical & Physical Access Controls",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": ["Logical & Physical Access Controls"],
			"categories": ["cc6.1", "cc6.2", "cc6.3", "cc6.4", "cc6.5", "cc6.6", "cc6.7", "cc6.8"],
			"hrms": {
				"title": "MANUAL USING HR TOOLS",
				"description": "Bitrix free tool for HRMS",
				"link": "https://www.bitrix24.net/oauth/authorize/?user_lang=en&client_id=site.5602b8e8a41202.94213120&redirect_uri=https%3A%2F%2Fwww.bitrix24.in%2Fauth%2F%3Fauth_service_id%3DBitrix24Net&scope=auth,client&response_type=code&mode=page&state=site_id%3Din%26backurl%3D%252Fauth%252F%253Fcheck_key%253D881500cbdbfded77f8114e0d5b3f9a7b%26mode%3Dpage"
			}
		},*/
		"fn-SystemOperations": {
			"id": "fn-SystemOperations",
			"value": "System Operations",
			"fgColor": "#e9ecef",
			"bgColor": "#00b33c",
			"description": ["System Operations"],
			"categories": ["cc7.3", "cc7.4", "cc7.5"],
			"hrms": {
				"title": "AUTOMATION",
				"description": "Business Continuity and Disaster Recovery Plan and Incident Reporting",
				"link": "#"
			}
		},
		"fn-ChangeManagement": {
			"id": "fn-ChangeManagement",
			"value": "Change Management",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": ["Change Management"],
			"categories": ["cc8.1"]
		},
		"fn-RiskMitigation": {
			"id": "fn-RiskMitigation",
			"value": "Risk Mitigation",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": ["Risk Mitigation"],
			"categories": ["cc9.1", "cc9.2"],
			"hrms": {
				"title": "Goodfirms free tool Risk Mitigation",
				"description": "",
				"link": "https://www.goodfirms.co/users/login"
			}
		},
		"fn-ProcessingIntegrity": {
			"id": "fn-ProcessingIntegrity",
			"value": "Processing Integrity",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": ["Processing Integrity"],
			"categories": ["pi1.1", "pi1.2", "pi1.3", "pi1.4"]
		},
		"fn-Privacy": {
			"id": "fn-Privacy",
			"value": "Privacy",
			"fgColor": "#e9ecef",
			"bgColor": "#00b33c",
			"description": ["Privacy"],
			"categories": ["p1.1", "p2.1", "p3.1", "p3.2", "p4.1", "p4.2", "p4.3", "p5.1", "p5.2", "p6.1", "p6.2", "p6.3", "p6.4", "p6.5", "p6.6", "p6.7", "p7.1", "p8.1"]
		}
	},
	"testCases": [
		{
			"id": "fn-commoncriteria",
			"value": "Common Criteria",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"children": ["fn-controlEnvironment", "fn-CommunicationAndInformation", "fn-RiskAssessment", "fn-ControlActivities"]
		},
		{
			"id": "fn-supplementalCriteria",
			"value": "Supplemental Criteria",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"children": ["fn-SystemOperations", "fn-ChangeManagement", "fn-RiskMitigation"]
		},
		{
			"id": "fn-additionalCriteria",
			"value": "Additional Criteria",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"children": ["fn-ProcessingIntegrity", "fn-Privacy"]
		}
	]
}

export default data;