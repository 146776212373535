import { EventType, EventTypeToProp } from "../../Common/EventType";
import { useEffect, useState } from "react";

//todo: integrate save
const subCatProp = EventTypeToProp[EventType.SubCategoryChanged];

export default function ({ refData, nested, parentId, testCase, navState, categories, categoryId, data }) {

	const
		allSubcategories = refData.subCategories,
		[subcategories, setSubcategories] = useState({}),
		[subCategory, setSubCategory] = useState({}),
		[ids, setIds] = useState([]),
		[selected, setSelected] = useState(subcategories[0]),
		[selectedId, setSelectedId] = useState(0),
		[error, setError] = useState({});

	const init = mounted => {

		//console.log("called init");

		if (!mounted) return;

		let current = {}, currentIds = [], firstId = '';
		const category = refData.categories[categoryId];
		category.subcategories.forEach((id, index) => {
			if (index === 0) firstId = id;
			currentIds[index] = id;
			current[id] = allSubcategories[id];
		});
		setSubcategories(current);
		setIds(currentIds);
		setSelected(firstId);
		setSelectedId(0);
		setSubCategory(current[firstId]);
	};

	const onClick = (id, index) => {
		setSelected(id);
		setSelectedId(index);
		setSubCategory(subcategories[id]);
	};

	const onError = (id, value) => {
		console.log("subcat error", id, value, subcategories, selected, ids);

		if (!id) return;

		setError(v => ({ ...v, [id]: value }));
	}

	useEffect(() => {
		init(true);
		//disabled for init
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentId, testCase, categoryId])

	return {
		ids,
		subcategories,
		subCategory,
		selected,
		selectedId,
		error,
		onError,
		onClick
	}
}