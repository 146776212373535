import React, { useContext, useEffect, useState } from 'react';
import {
	faArrowAltCircleLeft,
	faPrint,
} from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PrintContext from '../Common/PrintContext';
import { t } from 'i18next';

const PrintButton = ({ handlePrint, failed }) => {
	const [disabled, setDisabled] = useState(false),
		[printed, setPrinted] = useState(false),
		[loaded, setLoaded] = useState(false),
		context = useContext(PrintContext),
		expectedCounter = context.report.hasAuditReport ? 3 : 2;

	useEffect(() => {
		let done = context.counter.value === expectedCounter;
		setDisabled(!done);
		setPrinted(context.counter.value > 2);
		setLoaded(done);

		/*if (loaded && !failed) {
			document.getElementById('printSpinner').style.display = 'none';
		}*/
	}, [context, expectedCounter]);

	return (
		<div className="clearfix mb-2 mt-2">
			<Button
				className="dont-print float-end"
				size="md"
				color="primary"
				disabled={disabled || failed}
				onClick={handlePrint}>
				<FontAwesomeIcon icon={faPrint} className="me-1" />
				{t('button.print')}
			</Button>

			{!loaded && !failed && (
				<div
					id="printSpinner"
					className="spinner-border dont-print float-end text-primary spinner-border-sm mt-2 me-2"
					role="status">
					<span className="sr-only">{t('table.loading')}</span>
				</div>
			)}

			<Button
				className="dont-print"
				size="md"
				color="primary"
				onClick={() => window.history.back()}>
				<FontAwesomeIcon icon={faArrowAltCircleLeft} className="me-1" />
				{t('button.back')}
			</Button>
		</div>
	);
};

export default PrintButton;
