import { asyncRequest, httpMethods, request, resolveUri } from "./Common";

import { isOrgAccount } from "../services/AuthService";

const getPackName = (packName) => packName === "ALL" ? "" : packName;
const createUri = (uri) => resolveUri(isOrgAccount() ? uri + 'Org' : uri);

const startPackDeployment = (packName, success, error) => {
    request(createUri('deploy'), {
        method: httpMethods.put,
        headers: {
            "ConformancePackName": getPackName(packName)
        },
        body: JSON.stringify([]),
        redirect: 'follow'
    }, success, error);
}

const deletePackDeployment = (packName, success, error) => {
    const isOrg = isOrgAccount();
    request(resolveUri(isOrg ? 'resetConfigOrg' : 'deploy'), {
        method: isOrg ? httpMethods.put : httpMethods.delete,
        headers: {
            "ConformancePackName": getPackName(packName)
        },
        body: JSON.stringify([]),
        redirect: 'follow'
    }, success, error);
}

const getDeploymentProgress = (packName, success, error) => {
    request(createUri('conformancePackState'), {
        method: httpMethods.get,
        headers: {
            "ConformancePackName": getPackName(packName)
        },
        redirect: 'follow'
    }, success, error);
}

const getDeploymentStatus = async (packName, region) => {
    return await asyncRequest(createUri('conformancePackState'), {
        method: httpMethods.get,
        headers: {
            "ConformancePackName": getPackName(packName),
            "Region": region
        },
        redirect: 'follow'
    });
}

const resetDeployment = async (packName, region) => {
    const isOrg = isOrgAccount();
    return await asyncRequest(resolveUri(isOrg ? 'resetConfigOrg' : 'deploy'), {
        method: isOrg ? httpMethods.put : httpMethods.delete,
        headers: {
            "ConformancePackName": getPackName(packName),
            "Region": region
        },
        body: JSON.stringify([]),
        redirect: 'follow'
    });
}

const startDeployment = async (packName, region) => {
    return await asyncRequest(createUri('deploy'), {
        method: httpMethods.put,
        headers: {
            "ConformancePackName": getPackName(packName),
            "Region": region
        },
        body: JSON.stringify([]),
        redirect: 'follow'
    });
}

export { getDeploymentProgress, deletePackDeployment, startPackDeployment, getDeploymentStatus, resetDeployment, startDeployment };