import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, Form, FormGroup } from 'reactstrap';
import RadioButton from '../../components/Form/RadioButton';
import Input from '../../components/Form/Input';
import { passwordResetCode, resendEmail } from '../../apiclients/EmailLogin';
import { useTranslation } from 'react-i18next';
import EmailConfirmationPopup from './EmailConfirmationPopup';

const RESET_METHODS = {
	phone: 'phone',
	email: 'email',
};

const INITIAL_VALUES = {
	phone: '',
	email: '',
};

const ResetPassword = () => {
	const [method, setMethod] = useState('email');
	const [values, setValues] = useState(INITIAL_VALUES);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const onSubmit = async e => {
		e.preventDefault();

		// TODO: add phone method
		const body = {
			method: 'request_code',
			identifier: values.email,
		};
		await passwordResetCode(body, onSuccess, onResetError);
	};

	const onResetError = result => {
		// handle unactivated user
		console.log("reset error:", result);
		if (
			401 === result.statusCode &&
			'User Not Confirmed' === result.responseError.error
		) {
			console.log(
				'User was not activated. Resending validation for:',
				values.email
			);
			resendEmail(
				{ email: values.email },
				() => {
					setConfirmationOpen(true);
				},
				() => {
					console.log('Error when resending activation email');
				}
			);
		} else {
			console.log('generic reset error:', result, result.response);
			// TODO handle error
			//setErrors({ email: true, password: true });
		}

	};

	const onSuccess = () => {
		const query =
			method === 'email'
				? `email=${encodeURIComponent(values.email)}`
				: `phone=${values.phone}`;
		setValues(INITIAL_VALUES);

		navigate(`/auth/reset-code?${query}`);
	};

	const onEmailChange = e => {
		e.persist();
		setValues(prev => ({ ...prev, email: e.target.value }));
		if (method !== RESET_METHODS.email) setMethod(RESET_METHODS.email);
	};

	return (
		<div>
			<div className="text-center mt-4">
				<h2 className="sign-in__title">{t('forgotPassword.title')}</h2>
				<p className="mx-auto sign-in__subtitle">
					{t('forgotPassword.description')}
				</p>
			</div>
			<div className="m-sm-4 reset-form">
				<Form className="form" onSubmit={onSubmit}>
					{/* <FormGroup>
						<RadioButton
							value={RESET_METHODS.phone}
							id={RESET_METHODS.phone}
							isSelected={method === RESET_METHODS.phone}
							label="Phone number"
							onChange={e => setMethod(e.target.value)}
						/>
						<PhoneInput value={values.phone} onChange={onPhoneChange} />
					</FormGroup> */}
					<FormGroup>
						<RadioButton
							value={RESET_METHODS.email}
							id={RESET_METHODS.email}
							isSelected={method === RESET_METHODS.email}
							label={t('form.email')}
							onChange={e => setMethod(e.target.value)}
						/>
						<Input
							type="email"
							name="email"
							value={values.email}
							onChange={onEmailChange}
						/>
					</FormGroup>
					<div className="text-center mt-3">
						<Button
							color="primary"
							size="lg"
							type="submit"
							className="auth-button">
							{t('button.getCode')}
						</Button>
					</div>
					<div className="mx-auto mt-3 text-center">
						<Link to="/auth/sign-in">{t('button.signIn')}?</Link>
					</div>
				</Form>
			</div>
			<EmailConfirmationPopup
				isOpen={confirmationOpen}
				email={values.email}
				onToggle={v => setConfirmationOpen(false)}
			/>
		</div>
	);
};

export default ResetPassword;
