import React, { useEffect, useState } from 'react';
import { dropDownData, errorClassName } from '../Common/Constants';
import { t } from 'i18next';

const AuditDdl = ({
	value,
	refData,
	source,
	disabled,
	onDataChange,
	labelText,
	required,
	onError,
	isModified,
}) => {
	const ddlvalue = value ?? refData.defaultValue;
	const isNotSet = ddlvalue === 0;
	const [error, setError] = useState(isNotSet);
	const [selValue, setSelValue] = useState(ddlvalue);

	useEffect(() => {
		const isSet = Number(value) !== 0;
		setSelValue(value);
		setError(isModified && required && !isSet);
	}, [required, value, isModified]);

	const onValueChange = e => {
		const option = e.currentTarget.selectedOptions[0];
		const isSet = Number(option.value) !== 0;
		onDataChange(source, { label: option.text, value: parseInt(option.value) });
		setSelValue(e.target.value);
		setError(required && !isSet);
		onError(required && !isSet);
	};

	return (
		<>
			<div className="row w-100">
				<label htmlFor="complianceDdl">{labelText}</label>
				{error && <span className={errorClassName}>*</span>}
			</div>
			<div className="row ">
				<select
					style={{ width: '95%' }}
					name="complianceDdl"
					multiple={false}
					onChange={onValueChange}
					disabled={disabled}
					value={selValue}>
					{refData.values.map(item => (
						<option key={item.value} value={item.value}>
							{item.label}
						</option>
					))}
				</select>
			</div>
		</>
	);
};

const ComplianceDropdown = ({
	value,
	onChange,
	disabled,
	onError,
	isModified,
}) => (
	<AuditDdl
		labelText={t('form.result')}
		refData={dropDownData.compliance}
		source="result"
		value={value}
		disabled={disabled}
		onDataChange={onChange}
		required={true}
		onError={onError}
		isModified={isModified}
	/>
);

const SeverityDropdown = ({
	value,
	onChange,
	disabled,
	required,
	onError,
	isModified,
}) => (
	<AuditDdl
		labelText={t('form.severity')}
		refData={dropDownData.severity}
		source="severity"
		disabled={disabled}
		value={value}
		onDataChange={onChange}
		required={required}
		onError={onError}
		isModified={isModified}
	/>
);

export { ComplianceDropdown, SeverityDropdown };
