import { EventType, EventTypeToProp } from "../../Common/EventType";
import { useEffect, useState } from "react";

const testCaseProp = EventTypeToProp[EventType.TestCaseChanged];

export default function (refData, navState) {
	const
		testCases = refData.testCases,
		allCategories = refData.categories,
		hasChildTests = !!refData.childTestCases,
		getCategories = id => {
			let updated = {};
			if (hasChildTests) {
				return updated;
			}

			const testCase = testCases.find(item => item.id === id);
			testCase.categories.forEach(id => {
				updated[id] = allCategories[id]
			});
			return updated;
		},
		selectedId = navState.state[testCaseProp] ?? testCases[0].id,
		[categories, setCategories] = useState(getCategories(selectedId)),
		[selected, setSelected] = useState(selectedId),
		isSelected = tabId => tabId === selected,
		onTestCaseClick = tabId => {
			navState.onNavEvent(EventType.TestCaseChanged, tabId);
			setSelected(tabId);
		};

	useEffect(() => {
		navState.onNavEvent(EventType.TestCaseChanged, selected);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	useEffect(() => {
		setCategories(getCategories(selected));
		//only selected changes, nothing else
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return {
		testCases,
		categories,
		selected,
		isSelected,
		hasChildTests,
		onClick: onTestCaseClick
	};
}