import React from 'react';
import { useTranslation } from 'react-i18next';

function Glossary() {
	const { t } = useTranslation();
	return (
		<div id="detailed-glossary" className="mt-6 ">
			<h4>{t('detailedInstructions.usage')}</h4>
			<p className="m-0 p-0">{t('detailedInstructions.inbuiltAWS')}</p>
			<p>{t('detailedInstructions.removingTalaSecurePolicy')}</p>
			<h4>{t('detailedInstructions.glossary')}</h4>
			<p className="m-0 p-0">{t('detailedInstructions.inbuiltAWS')}</p>
			<p>{t('detailedInstructions.removingTalaSecurePolicy')}</p>
			<ul className="glosary-list">
				<li>{t('detailedInstructions.AWSAccount')}</li>
				<li>{t('detailedInstructions.IAMuser')}</li>
				<li>{t('detailedInstructions.credentials')}</li>
				<li>{t('detailedInstructions.AWSPolicy')}</li>
				<li>{t('detailedInstructions.IAMPolicy')}</li>
				<li>{t('detailedInstructions.AWSRegion')}</li>
			</ul>
		</div>
	);
}

export default Glossary;
