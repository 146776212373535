import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Col, Container, Row } from 'reactstrap';
import './styles.css';
import Footer from '../../components/Footer';
import ProgressBarForGuide from './components/ProgressBarForGuide';
import HeaderIntroduction from './components/HeaderIntroduction';
import OverviewOneTimeSetup from './components/OverviewOneTimeSetup';

function OneTimeSetup() {
	const [scroll, setScroll] = useState(0);
	const [scroll2, setScroll2] = useState(0);

	const navigate = useNavigate();
	const { t } = useTranslation();

	const goBack = () => {
		navigate('/user-guide');
	};

	useEffect(() => {
		const handleScroll = () => {
			const introductionElement = document.getElementById('introduction');
			const goalsElement = document.getElementById('goals');
			const overviewElement = document.getElementById('overview');
			const glossaryElement = document.getElementById('glossary');

			if (introductionElement) {
				const { top } = introductionElement.getBoundingClientRect();
				if (top <= window.innerHeight / 2) {
					setScroll(25);
				}
			}

			if (goalsElement) {
				const { top } = goalsElement.getBoundingClientRect();
				if (top <= window.innerHeight / 2) {
					setScroll(50);
					setScroll2(0);
				}
			}

			if (overviewElement) {
				const { top } = overviewElement.getBoundingClientRect();
				if (top < window.innerHeight / 2) {
					setScroll(100);
					setScroll2(25);
				}
			}

			if (glossaryElement) {
				const { top } = glossaryElement.getBoundingClientRect();
				if (top <= window.innerHeight) {
					setScroll2(100);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<Container fluid>
			<HeaderIntroduction
				title={t('oneTimeSetup.title')}
				subTitle={t('oneTimeSetup.subTitle')}
				subTitle2={t('oneTimeSetup.section')}
				onClick={goBack}
			/>
			<Row className="introduction">
				<Col xs="9" className="mt-4">
					<div id="introduction">
						<h3>{t('oneTimeSetup.introduction')}</h3>
						<p>{t('oneTimeSetup.cybersecurityAssistant')}</p>
					</div>
					<div className="mt-5" id="goals">
						<h4>{t('oneTimeSetup.goals')}</h4>
						<p className="m-0 p-0">{t('oneTimeSetup.AWSconnector')} </p>
						<ol>
							<li>{t('oneTimeSetup.AWSconnector')}</li>
							<li>{t('oneTimeSetup.restrictedRight')}</li>
						</ol>
						<p>{t('oneTimeSetup.noSoftware')}</p>
					</div>

					<div className="mt-5" id="vs">
						<h4>{t('oneTimeSetup.vs')}</h4>
						<p className="m-0 p-0">{t('oneTimeSetup.talaAWSconnector')}</p>
						<p className="m-0 p-0">{t('oneTimeSetup.requiresTheUser')}</p>
					</div>

					<OverviewOneTimeSetup />

					<div className="mt-5" id="glossary">
						<h3>Glossary</h3>
						<p>Lorem</p>
					</div>
				</Col>
				<Col xs="2" className="fixed-col">
					<ProgressBarForGuide
						scroll={scroll}
						scroll2={scroll2}
						descp1={t('detailedInstructions.introduction')}
						descp2={t('bigGuide.overviewOneTimeSetup')}
						descp3={t('detailedInstructions.glossary')}
						id1={'#introduction'}
						id2={'#overview'}
						id3={'#glossary'}
					/>
				</Col>
				<div className="mt-7">
					<Footer />
				</div>
			</Row>
		</Container>
	);
}

export default OneTimeSetup;
