import {
	NavItem,
	NavLink
} from "reactstrap";

import React from "react";
import classnames from 'classnames';

const ReportHeader = ({ activeTabId, test, handleTabClick }) => (
	<NavItem>
		<NavLink className={classnames({ active: activeTabId === test.id })}
			onClick={() => { handleTabClick(test.id); }}>
			{test.value}
		</NavLink>
	</NavItem>
);

export default ReportHeader;