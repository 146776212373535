import {
	getDashboardData,
	getEvaluationHistory,
	getIssueList,
} from '../../../apiclients/Ransomware';
import { setItem } from '../../../services/browserStorageService';
import { useEffect, useState } from 'react';

const monthNames = [
	'',
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const setMonthlyData = (data, setLineChartData) => {
	let currentMonth = new Date().getMonth() + 1 - 5;
	let currentYear = new Date().getFullYear();

	if (currentMonth < 1) {
		currentMonth = currentMonth + 12;
		currentYear = currentYear - 1;
	}

	const potentialLoss = [];
	const potentialLossFull = [];
	const riskScore = [];
	const vulnerableIssueCount = [];
	const vulnerableResourceCount = [];
	const months = [];

	for (let count = 0; count < 6; count++) {
		const key = currentMonth.toString() + '-' + currentYear.toString();
		months.push(monthNames[currentMonth]);
		potentialLoss.push((data.MonthlyPotentialLoss[key] ?? 0) / 10000);
		potentialLossFull.push(data.MonthlyPotentialLoss[key] ?? 0);
		riskScore.push(data.MonthlyRiskScore[key] ?? 0);
		vulnerableIssueCount.push(data.MonthlyVulnerableIssueCount[key] ?? 0);
		vulnerableResourceCount.push(data.MonthlyVulnerableResourceCount[key] ?? 0);

		currentMonth = currentMonth + 1;
		if (currentMonth > 12) {
			currentMonth = 1;
			currentYear = currentYear + 1;
		}
	}

	setLineChartData({
		labels: months,
		potentialLossFull,
		datasets: [
			{
				label: 'Potential Loss (10k $)',
				data: potentialLoss,
				fill: false,
				borderColor: '#663399',
			},
			{
				label: 'Risk Score (%)',
				data: riskScore,
				fill: false,
				borderColor: '#4682B4',
			},
			{
				label: 'Vulnerable Issues (#) ',
				data: vulnerableIssueCount,
				fill: false,
				borderColor: '#8B4513',
			},
			{
				label: 'Vulnerable Resources (#)',
				data: vulnerableResourceCount,
				fill: false,
				borderColor: '#0000FF',
			},
		],
	});
};

const setMonthlyIssues = ({
	issues,
	setCurrentMonthIssues,
	setLastMonthIssues,
}) => {
	let currentMonth = new Date().getMonth() + 1;
	let lastMonth = currentMonth - 1;
	let currentYear = new Date().getFullYear();

	let key = currentMonth.toString() + '-' + currentYear.toString();
	setCurrentMonthIssues(issues[key] ?? 0);

	if (lastMonth < 1) {
		lastMonth = 1;
		currentYear = currentYear - 1;
	}

	key = lastMonth.toString() + '-' + currentYear.toString();
	setLastMonthIssues(issues[key] ?? 0);
};

const useDashboard = () => {
	const [potentialLoss, setPotentialLoss] = useState({ min: 0, max: 0 });
	const [riskScore, setRiskScore] = useState(0);
	const [previousRiskScore, setPreviousRiskScore] = useState(0);
	const [currentLoss, setCurrentLoss] = useState({ min: 0, max: 0 });
	const [previousLoss, setPreviousLoss] = useState({ min: 0, max: 0 });
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [lineChartData, setLineChartData] = useState({});
	const [evaluationHistory, setEvaluationHistory] = useState([]);
	const [currentMonthIssues, setCurrentMonthIssues] = useState(0);
	const [lastMonthIssues, setLastMonthIssues] = useState(0);
	const [vulnerabilities, setVulnerabilities] = useState([]);
	const [highCount, setHighCount] = useState({ resource: 0, issues: 0 });
	const [criticalCount, setCriticalCount] = useState({
		resource: 0,
		issues: 0,
	});
	const [lastReport, setLastReport] = useState(null);
	const [issues, setIssues] = useState({});

	useEffect(() => {
		setLoading(true);

		const setData = async ([dashboard, history]) => {
			const hasLoaded = dashboard && dashboard.data && history && history.data;
			if (hasLoaded) {
				const loss = dashboard.data.RRA.PotentialLoss;
				const rra = dashboard.data.RRA;

				if (loss !== undefined) {
					setPotentialLoss({ min: loss, max: loss });
				} else {
					setPotentialLoss({
						min: rra.PotentialLossMin,
						max: rra.PotentialLossMax,
					});
				}

				setHighCount({
					resource: rra['High Resources'] ?? 0,
					issues: rra['High Issues'] ?? 0,
				});

				setCriticalCount({
					resource: rra['Critical Resources'] ?? 0,
					issues: rra['Critical Issues'] ?? 0,
				});

				setRiskScore(rra.RiskScore);
				setMonthlyData(rra, setLineChartData);
				setVulnerabilities(rra.Vulnerabilities);
				setItem('dashboard-vulnerabilities', rra.Vulnerabilities);

				setMonthlyIssues({
					issues: rra.MonthlyVulnerableIssueCount,
					setCurrentMonthIssues,
					setLastMonthIssues,
				});

				if (history.data.length > 1) {
					const currentState = history.data[0];
					const previousState = history.data[1];
					const { success, data } = await getIssueList(currentState.ReportId);
					if (success) {
						setIssues(data);
					}

					setLastReport(currentState);
					setPreviousRiskScore(previousState.RiskScore);
					setPreviousLoss({
						min: previousState.PotentialLoss,
						max: previousState.PotentialLoss,
					});
					setCurrentLoss({
						min: currentState.PotentialLoss,
						max: currentState.PotentialLoss,
					});
				}
				else if (1 === history.data.length)
				{
					const currentState = history.data[0];
					const { success, data } = await getIssueList(currentState.ReportId);
					if (success) {
						setIssues(data);
					}

					setLastReport(currentState);
					setPreviousRiskScore(false);
					setPreviousLoss({
						min: 0,
						max: 0,
					});
					setCurrentLoss({
						min: currentState.PotentialLoss,
						max: currentState.PotentialLoss,
					});

				}
				else
				{
					// Dashboard data is valid but no history. 
					// This usually indicates an inconsistent backend state.
					console.log("No history");
					setError(true);
				}

				setEvaluationHistory(history.data);
			} else {
				setError(true);
			}

			setLoading(false);
		};

		Promise.all([getDashboardData(), getEvaluationHistory()]).then(setData);
	}, []);

	return {
		loading,
		error,
		potentialLoss,
		riskScore,
		previousRiskScore,
		previousLoss,
		currentLoss,
		lineChartData,
		currentMonthIssues,
		lastMonthIssues,
		vulnerabilities,
		evaluationHistory,
		criticalCount,
		highCount,
		lastReport,
		issues,
	};
};

export default useDashboard;
