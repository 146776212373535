import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Col } from 'reactstrap';
import '../../styles.css';
import { useTranslation } from 'react-i18next';

function QuickStartK8S() {
	const [activeTab, setActiveTab] = useState('1');
	const { t } = useTranslation();

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<>
			<Nav tabs>
				<NavItem>
					<NavLink
						className={activeTab === '1' ? 'activeLink font-weight-bold' : ''}
						onClick={() => {
							toggle('1');
						}}
						style={{ color: activeTab === '1' ? '#1E69E0' : 'black' }}>
						{t('quickStart.k8s.intro.title')}
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<Col
						sm="12"
						className="d-flex justify-content-space-between align-items-center"
						style={{ padding: '20px', gap: '64px' }}>
						<div className="main-text">
							<h4>{t('quickStart.k8s.intro.heading')}</h4>
							<p>{t('quickStart.k8s.intro.description')}</p>
						</div>
					</Col>
				</TabPane>
			</TabContent>
		</>
	);
}

export default QuickStartK8S;
