import React, { useEffect, useMemo, useState } from 'react';
import { Container, Input, Button } from 'reactstrap';
import useDashboard from '../Hooks/useDashboard';
import Loader from '../../../components/Loader';
import DashBoardError from '../dashboard/controls/DashBoardError';
import { Trans } from 'react-i18next';
import {
	getFullResourceIdOrName,
	getTruncatedResourceIdOrName,
} from '../Common/RansomwareUtilites';
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	getPaginationRowModel,
	getFilteredRowModel,
} from '@tanstack/react-table';
import useReportInfo from '../../../hooks/useReportInfo';
import InfoModal from '../../../components/InfoModal';
import { useLocation } from 'react-router-dom';
import { convertToInternationalCurrencySystem } from '../Common/Formatter';
import { BTable } from '../../../components/BTable';

const cardHeadStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
};

const getColumns = onClick => [
	{
		accessorKey: 'ResourceType',
		header: () => <Trans i18nKey="vulnerabilities.resourceType" />,
		sort: true,
		cell: ({ cell, row }) => (
			<p
				className="text-black"
				onClick={() => {
					onClick(row.original);
				}}>
				{cell.getValue()}
			</p>
		),
	},
	{
		accessorKey: 'SecurityTestRule',
		header: () => <Trans i18nKey="vulnerabilities.issue" />,
		sort: true,
		cell: ({ cell, row }) => (
			<p
				className="text-danger"
				onClick={() => {
					onClick(row.original);
				}}>
				{cell.getValue()}
			</p>
		),
	},
	{
		accessorKey: 'ResourceName',
		header: () => <Trans i18nKey="vulnerabilities.resourceName" />,
		sort: true,
		cell: ({ cell, row }) => (
			<div
				title={getFullResourceIdOrName(row.original)}
				onClick={() => {
					onClick(row.original);
				}}>
				{getTruncatedResourceIdOrName(row.original)}
			</div>
		),
	},
];

const PAGE_SIZES = [15, 50, 100, 200];

const SeverityButtons = ({ setSeverityFilter }) => {
	return (
		<div style={{ marginBottom: '10px' }}>
			<Button color="success" style={{ marginRight: '5px' }} onClick={() => setSeverityFilter(null)}>All</Button>
			<Button color="warning" style={{ marginRight: '5px' }} onClick={() => setSeverityFilter('Medium')}>Medium</Button>
			<Button color="danger" style={{ marginRight: '5px' }} onClick={() => setSeverityFilter('High')}>High</Button>
			<Button color="secondary" style={{ marginRight: '5px' }} onClick={() => setSeverityFilter('Critical')}>Critical</Button>
		</div>
	);
};

const RansomwareIssues = () => {
	const result = useDashboard();
	const { formatReport, data: resultData, fetchData } = useReportInfo();
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	const severity = queryParams.get('severity');

	const [severityFilter, setSeverityFilter] = useState(severity || null);
	const [searchQuery, setSearchQuery] = useState('');

	const list = useMemo(() => {
		let severityVulnerabilities;
		if (severityFilter === 'All' || severityFilter === '*' || !severityFilter) {
			severityVulnerabilities = result.vulnerabilities;
		} else {
			severityVulnerabilities = result.vulnerabilities.filter(
				item => item.Severity === severityFilter
			);
		}

		const formatIssues = severityVulnerabilities.reduce((acc, vulnerability) => {
			const issues = result.issues[vulnerability.SecurityTestRule];
			if (issues) {
				const formatIssues = issues.map(item => ({
					...item,
					SecurityTestRule: vulnerability.SecurityTestRule,
				}));

				acc.push(...formatIssues);
			}
			return acc;
		}, []);

		if (searchQuery) {
			return formatIssues.filter(item =>
				Object.values(item).some(value =>
					String(value).toLowerCase().includes(searchQuery.toLowerCase())
				)
			);
		}

		return formatIssues;
	}, [severityFilter, searchQuery, result]);

	const [isOpen, setIsOpen] = useState(false);
	const [openedItem, setOpenedItem] = useState(null);

	const onReportClick = item => {
		setIsOpen(true);
		setOpenedItem(item);
	};

	const columns = getColumns(onReportClick);

	const formattedReport = useMemo(() => {
		if (!openedItem) return { data: {}, info: {} };

		const resource = resultData?.nodes?.find(
			item => item?.data?.id === openedItem?.ResourceName
		);
		const formatData = formatReport(resource?.data, result.lastReport);

		return formatData || { data: {}, info: {} };
	}, [formatReport, openedItem, resultData.nodes, result.lastReport]);

	useEffect(() => {
		if (result.lastReport) {
			fetchData(result.lastReport, n => n, result.issues);
		}
	}, [fetchData, result.lastReport]);

	const tableOptions = {
		data: list,
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		initialState: {
			pagination: {
				pageSize: PAGE_SIZES[0],
			},
			sorting: [
				{
					id: 'ResourceType',
					desc: false,
				},
			],
		},
		autoResetPageIndex: false,
	};

	const table = useReactTable(tableOptions);

	return (
		<Container fluid>
			{result.loading && <Loader />}
			{!result.loading && result.error && <DashBoardError />}
			{!result.loading && !result.error && (
				<>
					<div>
						<SeverityButtons setSeverityFilter={setSeverityFilter} />
						<div style={cardHeadStyle}>
							<h2>
								{severityFilter || 'All'}{' '}
								{convertToInternationalCurrencySystem(result.lastReport?.PotentialLoss)}{' '}
								Loss Ransomware Report {result.lastReport?.RunDateTime}
							</h2>
							<Input
								type="text"
								placeholder="Search"
								value={searchQuery}
								onChange={e => setSearchQuery(e.target.value)}
								style={{ width: '200px', marginLeft: '1rem' }}
							/>
						</div>
						{!!list.length && <BTable table={table} pageSizes={PAGE_SIZES} />}
					</div>
					{isOpen && !!Object.keys(formattedReport?.data || {}).length && (
						<div style={{ position: 'fixed', top: 0, bottom: 0, right: 0, height: '100vh' }}>
							<InfoModal
								data={formattedReport}
								onClose={() => setIsOpen(false)}
								report={result.lastReport}
							/>
						</div>
					)}
				</>
			)}
		</Container>
	);
};

export default RansomwareIssues;
