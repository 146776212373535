import classNames from 'classnames';
import React, { useMemo, useRef, useState } from 'react';
import { ChevronDown } from 'react-feather';
import useClickOutside from '../../../../hooks/useClickOutside';

const getTextColor = severity => {
	switch (severity) {
		case 'Critical':
			return '-critical';
		case 'Medium':
			return '-medium';
		case 'High':
			return '-high';
		default:
			return '-all';
	}
};

const options = [
	{
		value: '*',
		label: 'All',
	},
	{
		value: 'Critical',
		label: 'Critical',
	},
	{
		value: 'High',
		label: 'High',
	},
	{
		value: 'Medium',
		label: 'Medium',
	},
];

const SeveritySelect = ({ value, onChange = () => {} }) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef();

	useClickOutside(ref, () => setIsOpen(false));

	const selectedValue = useMemo(
		() => options.find(item => item.value === value)?.label || '-',
		[value]
	);

	const handleChange = value => {
		onChange(value);
		setIsOpen(false);
	};

	return (
		<div ref={ref} className="severity-select">
			<h2>Issues:</h2>
			<div className="severity-select__select">
				<button
					className={classNames(
						'severity-select__trigger',
						'bg' + getTextColor(value),
						{
							'severity-select__trigger_opened': isOpen,
						}
					)}
					onClick={() => setIsOpen(v => !v)}>
					<p>{selectedValue}</p> <ChevronDown />
				</button>
				{isOpen && (
					<ul className="severity-select__menu">
						{options.map(item => {
							return (
								<li
									className={classNames(
										'severity-select__item',
										{
											'severity-select__item_selected': value === item.value,
										},
										'text' + getTextColor(item.value)
									)}>
									<button onClick={() => handleChange(item.value)}>
										{item.label}
									</button>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</div>
	);
};

export default SeveritySelect;
