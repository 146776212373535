import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { INITIAL_VALUES, VALIDATORS } from './constants';
import { useTranslation } from 'react-i18next';
import useForm from '../../auth/hooks/useForm';
import AvatarInput from '../../../components/AvatarInput/AvatarInput';
import Input from '../../../components/Form/Input';
import PhoneInput from '../../../components/Form/PhoneInput';
import Button from '../../../components/Form/Button';
import SavePopup from '../../../components/SavePopup/SavePopup';
import DeletePopup from '../../../components/DeletePopup/DeletePopup';

const AddUser = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [values, setValues] = useState(INITIAL_VALUES);
	const [savePopup, setSavePopup] = useState(false);
	const [deletePopupOpen, setDeletePopupOpen] = useState(false);
	const { validateField, validateForm, errors } = useForm(values, VALIDATORS);

	const onSavePopupClose = () => {
		setSavePopup(false);
	};

	const onSavePopupOpen = e => {
		e.preventDefault();
		const isValid = validateForm();

		if (!isValid) {
			return;
		}

		setSavePopup(true);
	};

	const handleChange = e => {
		e.persist();
		setValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
		validateField(e.target.name, e.target.value);
	};

	const onSubmit = () => {
		onSavePopupClose();

		const formData = new FormData();

		for (const key in values) {
			if (key === 'photo') {
				formData.append(key, values[key].file);
			} else {
				formData.append(key, values[key]);
			}
		}

		// todo: make api call

		navigate('/admin/users');
	};

	const onAvatarChange = photo => setValues(prev => ({ ...prev, photo }));

	const onPhoneChange = phoneNumber => {
		setValues(prev => ({ ...prev, phoneNumber }));
		validateField('phoneNumber', phoneNumber);
	};

	const onDeleteAvatar = () => {
		onAvatarChange(null);
		setDeletePopupOpen(false);
	};

	return (
		<>
			<div>
				<h1>{t('adminPanel.addUser')}</h1>
				<form onSubmit={onSavePopupOpen} className="d-flex flex-column gap-3">
					<AvatarInput
						value={values.photo ? values.photo.src : null}
						onChange={onAvatarChange}
						onDelete={() => setDeletePopupOpen(true)}
					/>
					<div
						className="my-4"
						style={{ height: '1px', background: '#CFCCCC' }}
					/>
					<fieldset className="w-50 d-flex flex-column gap-3">
						<Input
							onChange={handleChange}
							name="name"
							error={errors.name}
							label={t('form.userName')}
						/>
						<PhoneInput
							label={t('form.phoneNumber')}
							error={errors.phoneNumber}
							onChange={onPhoneChange}
						/>
						<Input
							onChange={handleChange}
							name="email"
							label={t('form.email')}
							error={errors.email}
						/>
						<Input
							onChange={handleChange}
							name="alternateEmail"
							error={errors.alternateEmail}
							label={t('form.alternateEmail')}
						/>
						<Button className="align-self-end">
							{t('button.saveChanges')}
						</Button>
					</fieldset>
				</form>
			</div>
			<SavePopup
				isOpen={savePopup}
				onToggle={onSavePopupClose}
				onSubmit={onSubmit}
			/>
			<DeletePopup
				isOpen={deletePopupOpen}
				onToggle={() => setDeletePopupOpen(false)}
				onSubmit={onDeleteAvatar}
			/>
		</>
	);
};

export default AddUser;
