import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'reactstrap';

const ResourceList = ({ list, total, onChangeResource }) => {
	const { t } = useTranslation();
	return (
		<Row className="flex-column">
			<ul className="talasmart-resource__resource-list resource-list">
				{list.map(item => (
					<li
						className="resource-list__item"
						onClick={() => onChangeResource(item)}>
						{item?.ResourceId || item?.ResourceName}
					</li>
				))}
			</ul>
			<p className="text-blue text-md">
				{t('talasmart.resource.totalIds', { count: total })}
			</p>
		</Row>
	);
};

export default ResourceList;
