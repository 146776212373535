import {
	faBezierCurve,
	faChessKnight,
	faClinicMedical,
	faFlask,
	faHome,
	faSignInAlt,
	faUser,
	faBook,
} from '@fortawesome/free-solid-svg-icons';

import AuditTest from '../pages/tests/AuditTests';
import AutomatedTests from '../pages/tests/AutomatedTests';
import LatestReport from '../pages/tests/Reports/LatestReport';
import OWASPResults from '../pages/tests/PenTests/OWASPResults';
import { PackKeys } from '../constants/PackKeys';
import Page404 from '../pages/auth/Page404';
import Page500 from '../pages/auth/Page500';
import PenTestScan from '../pages/tests/PenTests/OWASP';
import PrintReport from '../pages/tests/Reports/Print';
import RansomwareAssessment from '../pages/ransomware/assessment';
import RansomwareAutoRemediation from '../pages/ransomware/AutoRemediation';
import RansomwareDashboard from '../pages/ransomware/dashboard';
import RansomwareDashboardDetail from '../pages/ransomware/dashboard/controls/DashBoardDetail';
import RansomwareFixIssues from '../pages/ransomware/FixIssues';
import RansomwareReports from '../pages/ransomware/Reports';
import ResetPassword from '../pages/auth/ResetPassword';
import ResetCode from '../pages/auth/ResetCode';
import RunTest from '../pages/tests/RunTests';
import SecurityDashboard from '../pages/dashboards/SecurityCompliance';
import { SelectTest } from '../pages/tests/SelectTest';
import Setup from '../pages/setup';
import TestReport from '../pages/tests/Reports';
import Topology from '../pages/Topology';
import UserGuide from '../pages/UserGuide/UserGuide';
import async from '../components/Async';
import SignIn from '../pages/auth/SignIn';
import RemoteAuth from '../pages/admin/RemoteAuthLanding/RemoteAuthLanding';
import RemoteAuthRedirect from '../pages/auth/RemoteAuthRedirect';
import Settings from '../pages/auth/Settings/Settings';
import Talasmart from '../pages/talasmart';
import Users from '../pages/admin/Users';
import AddUser from '../pages/admin/AddUser';
import IssueTracking from '../pages/admin/IssueTracking';
import RansomwareIssues from '../pages/ransomware/issues';
import OneTimeSetup from '../pages/UserGuide/OneTimeSetup';
import DetailedInstructions from '../pages/UserGuide/DetailedInstructions';
import SignUpFreeTrial from '../pages/auth/SignUpFreeTrial';
import LiveDemo from '../pages/auth/LiveDemo';
import ChangePassword from '../pages/auth/Settings/ChangePassword';
import { Terms } from '../pages/terms/Terms';

// Dashboards
const Default = async(() => import('../pages/dashboards/Default'));

// Routes
const landingRoutes = {
	path: '/',
	name: 'Landing Page',
	component: SignIn,
	children: null,
};

const dashboardRoutes = {
	path: '/dashboard',
	header: 'Security Compliance',
	name: 'Dashboard',
	icon: faHome,
	children: [
		{
			path: '/dashboard',
			name: 'Security Compliance',
			component: SecurityDashboard,
		},
		{
			path: '/dashboard/reports',
			name: 'Compliance Reports',
			component: Default,
		},
	],
};

const authRoutes = {
	path: '/auth',
	name: 'Auth',
	icon: faSignInAlt,
	children: [
		{
			path: '/auth/sign-in',
			name: 'Sign In',
			component: SignIn,
		},
		{
			path: '/auth/free-trial/sign-up',
			name: 'Sign Up Free Trial',
			component: SignUpFreeTrial,
		},
		{
			path: '/auth/live-demo/sign-up',
			name: 'Sign Up Live Demo',
			component: LiveDemo,
		},
		{
			path: '/auth/reset-password',
			name: 'Reset Password',
			component: ResetPassword,
		},
		{
			path: '/auth/reset-code',
			name: 'Reset Code',
			component: ResetCode,
		},
		{
			path: '/auth/404',
			name: '404 Page',
			component: Page404,
		},
		{
			path: '/auth/500',
			name: '500 Page',
			component: Page500,
		},
		{
			path: '/setup',
			name: 'Setup',
			component: Setup,
		},
		{
			path: '/auth/remote-landing',
			name: 'RemoteAuthRedirect',
			component: RemoteAuthRedirect,
		},
	],
};

const topologyRoutes = {
	path: '/topology',
	name: 'View Topology',
	//header: "Main",
	children: null,
	component: Topology,
	icon: faBezierCurve,
};

const guideRoute = {
	path: '/user-guide',
	name: 'TalaSecure User Guides',
	component: UserGuide,
	icon: faBook,
};

const oneTimeSetup = {
	path: '/user-guide/one-time-setup',
	name: 'One time Setup',
	component: OneTimeSetup,
};

const detailedInstructions = {
	path: '/user-guide/detailed-instructions',
	name: 'Detailed Instructions',
	component: DetailedInstructions,
};

const termsRoute = {
	path: '/terms',
	name: 'Terms and Conditions',
	component: Terms,
}

const testRoutes = {
	path: '/tests',
	name: 'Compliance Framework',
	icon: faFlask,
	children: [
		{
			path: `/tests/run/${PackKeys.NIST}`,
			name: 'NIST-CSF',
			component: RunTest,
			isSeparateItem: false,
		},
		{
			path: `/tests/run/${PackKeys.SOC2}`,
			name: 'SOC2',
			component: RunTest,
			isSeparateItem: false,
		},
		{
			path: `/tests/run/${PackKeys.HIPAASECURITY}`,
			name: 'HIPAA',
			component: RunTest,
			isSeparateItem: false,
		},
		{
			path: `/tests/run/${PackKeys.PCIDSS}`,
			name: 'PCI DSS',
			component: RunTest,
			isSeparateItem: false,
		},
		{
			path: `/tests/run/${PackKeys.CMMCL3}`,
			name: 'CMMC',
			component: RunTest,
			isSeparateItem: true,
		},
		{
			path: `/tests/run/${PackKeys.NIST80053REV4}`,
			name: 'NIST 800-53 Rev 4',
			component: RunTest,
			isSeparateItem: false,
		},
		{
			path: `/tests/run/${PackKeys.NIST800171}`,
			name: 'NIST 800 171',
			component: RunTest,
			isSeparateItem: false,
		},
		{
			path: `/tests/run/${PackKeys.FEDRAMP}`,
			name: 'FedRAMP',
			component: RunTest,
			isSeparateItem: false,
		},
		{
			path: `/tests/run/${PackKeys.NERCCIP}`,
			name: 'NERC CIP Compliance',
			component: RunTest,
			isSeparateItem: false,
		},
	],
};

const resultsRoute = {
	path: '/tests/results',
	name: 'Compliance',
	children: [
		{
			path: '/tests/results/:pack/:reportId',
			name: 'Results',
			component: AutomatedTests,
		},
		{
			path: '/tests/audit/:pack/:name?/:auditor?',
			name: 'Audit Tests',
			component: AuditTest,
		},
		{
			path: '/tests/run/:pack',
			name: 'Run Tests',
			component: RunTest,
		},
		{
			path: '/tests/select',
			name: 'Select Compliance Test Framework',
			component: SelectTest,
		},
		{
			path: '/pentests/results/:reportId',
			name: 'OWASP Results',
			component: OWASPResults,
		},
	],
};

const reportRoutes = {
	children: [
		{
			path: '/reports/latest',
			name: 'LatestReport',
			component: LatestReport,
		},
		{
			path: '/reports/:pack/:type/:reportid',
			name: 'TestReport',
			component: TestReport,
		},
	],
};

const penTestingRoutes = {
	path: '/pentests',
	name: 'Penetration Testing',
	component: null,
	icon: faChessKnight,
	children: [
		/*{
			path: `/pentests/dashboard`,
			name: 'Dashboard',
			component: PenTestDashboard
		},*/
		{
			path: `/pentests/OWASP`,
			name: 'OWASP',
			component: PenTestScan,
		},
	],
};

const printRoutes = {
	children: [
		{
			path: '/print/:pack/:reportid',
			name: 'PrintReport',
			component: PrintReport,
		},
		{
			path: '/reports/ransomware/:reportid',
			name: 'RansomwareReport',
			component: RansomwareReports,
		},
	],
};

const ransomWareRoutes = {
	path: '/ransomware/assessment/Info',
	name: 'Risk Assessment',
	component: RansomwareAssessment,
	icon: faChessKnight,
};

const ransomwareAutoRemediationRoutes = {
	path: '/ransomware/remediation',
	name: 'Auto Remediation',
	component: RansomwareAutoRemediation,
	icon: faClinicMedical,
};

const ransomwareDashboardRoutes = {
	path: `/ransomware/dashboard`,
	name: 'Dashboard',
	component: RansomwareDashboard,
	icon: faHome,
	header: 'Ransomware Protection',
};

const authLanding = {
	path: `/admin/remote-landing`,
	name: 'Accounts',
	component: RemoteAuth,
	icon: faUser,
	isSeparate: true,
};
const adminUserRoutes = {
	path: '/admin/users',
	name: 'Users',
	component: Users,
	icon: faUser,
};

const adminAddUserRoutes = {
	path: '/admin/users/add',
	name: 'Add users',
	component: AddUser,
};

const adminIssueTracking = {
	path: '/admin/issue-tracking',
	name: 'Issue Tracking',
	component: IssueTracking,
};

const adminChildrenRoutes = {
	path: '/admin',
	name: 'Admin Panel',
	component: null,
	icon: faUser,
	isSeparate: true,
	children: [
		authLanding,
		...(process.env.REACT_APP_FEAT_SHOW_USERMGR === '1'
			? [adminUserRoutes]
			: []),
		...(process.env.REACT_APP_FEAT_SHOW_ISSUE_TRACKING !== '0'
			? [adminIssueTracking]
			: []),
	],
};

const talasmartRoutes = {
	path: '/talasmart/:type',
	name: 'Talasmart',
	component: Talasmart,
};

const settingsRoutes = {
	path: `/settings`,
	name: 'Settings',
	component: Settings,
};

const settingsChangePassword = {
	path: '/settings/change-password',
	name: 'Change Password',
	component: ChangePassword,
};

const ransomwareNonSidebarRoutes = {
	children: [
		{
			path: `/ransomware/dashboard/detail`,
			name: 'Ransomware Details',
			component: RansomwareDashboardDetail,
			header: 'Ransomware Details',
		},
		{
			path: `/ransomware/remediation/fix`,
			name: 'Ransomware Fix Issues',
			component: RansomwareFixIssues,
		},
	],
};

//these exports are used by ./Routes.js to register all routes with the React router.

// Dashboard specific routes
export const dashboard = [
	dashboardRoutes,
	resultsRoute,
	reportRoutes,
	penTestingRoutes,
	ransomwareDashboardRoutes,
	//settingsRoutes,
	talasmartRoutes,
	{
		path: `/ransomware/assessment/:mode`,
		name: 'RansomwareMode',
		component: RansomwareAssessment,
	},
	{
		path: `/ransomware/issues`,
		name: 'RansomwareIssues',
		component: RansomwareIssues,
	},
	ransomWareRoutes,
];

export const oneTimeSetupGuide = [oneTimeSetup];

export const detailedInstructionsPage = [detailedInstructions];

// Landing specific routes
export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

export const tests = [testRoutes];

export const guide = [guideRoute];

export const print = [printRoutes];

export const penTests = [penTestingRoutes];

export const topology = [topologyRoutes];
export const ransomware = [ransomWareRoutes, ransomwareAutoRemediationRoutes];

export const ransomwareAutoRemediation = [ransomwareAutoRemediationRoutes];

export const ransomwareDashboard = [ransomwareDashboardRoutes];

export const ransomwareDetail = [ransomwareNonSidebarRoutes];

export const admin = [
	authLanding,
	adminUserRoutes,
	adminAddUserRoutes,
	adminIssueTracking,
];

export const settings = [settingsRoutes, settingsChangePassword];

export const talasmart = [talasmartRoutes];
// All routes - this is used by Sidebar.js to render the routes

export const termsRoutes = [termsRoute];

export default [
	ransomwareDashboardRoutes,
	ransomWareRoutes,
	ransomwareAutoRemediationRoutes,
	dashboardRoutes,
	topologyRoutes,
	testRoutes,

	...(process.env.REACT_APP_FEAT_SHOW_PENTEST === '1'
		? [penTestingRoutes]
		: []),
	adminChildrenRoutes,
	guideRoute
];
