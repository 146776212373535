import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Container,
	Spinner,
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import IssuesGrid from './Controls/IssuesGrid';
import { Link } from 'react-router-dom';
import React from 'react';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import useFixIssues from '../Hooks/useFixIssues';
import { t } from 'i18next';

const centeredContent =
	'row align-items-center d-flex justify-content-center m-5';

const linkCss = 'btn btn-primary btn-outline btn-md';

const FixIssues = () => {
	const {
		error,
		issues,
		fixStatus,
		title,
		clear,
		busy,
		onFix,
		onRollback,
		reportId,
	} = useFixIssues();

	return (
		<Container>
			<Header>
				<HeaderTitle>{t('autoRemediation.autoRemediation')}</HeaderTitle>
			</Header>
			<Card>
				<CardHeader>
					<CardTitle>
						{error && <h4>{t('autoRemediation.noDataFound')}</h4>}
						{!error && <h4>{title}</h4>}
					</CardTitle>
				</CardHeader>
				<CardBody>
					{busy && (
						<div>
							<div className={centeredContent}>
								<h4>{t('autoRemediation.loadingDetails')}</h4>
							</div>
							<div className={centeredContent}>
								<div>
									<Spinner color="primary" />
								</div>
							</div>
						</div>
					)}
					{!busy && (
						<>
							<IssuesGrid
								parentIssue={title}
								reportId={reportId}
								issues={issues}
								fixStatus={fixStatus}
								onFix={onFix}
								onRollback={onRollback}
							/>
							<div className="clearfix mb-2 mt-2">
								<Link
									to="/ransomware/remediation"
									className={linkCss}
									onClick={clear}>
									<FontAwesomeIcon
										icon={faArrowAltCircleLeft}
										className="me-1"
									/>
									{t('autoRemediation.backTo')}
								</Link>
							</div>
						</>
					)}
				</CardBody>
			</Card>
		</Container>
	);
};

export default FixIssues;
