let index = 0;

const getIndex = () => index++;

export default {
  "referenceData": {
    "result": {
      "defaultValue": 2,
      "values": [{
        "value": 1,
        "label": "Compliant"
      },
      {
        "value": 2,
        "label": "Non-Compliant"
      }
      ]
    },
    "severity": {
      "defaultValue": 3,
      "values": [{
        "value": 1,
        "label": "Low"
      },
      {
        "value": 2,
        "label": "Medium"
      },
      {
        "value": 3,
        "label": "High"
      },
      {
        "value": "4",
        "label": "Critical"
      }
      ]
    },
    "categories": {
      "C001": {
        "parent": "fn-0",
        "description": "Establish system access requirements",
        "name": "C001",
        "subcategories": [
          "AC.1.001"
        ],
        "id": "C001"
      },
      "C002": {
        "parent": "fn-0",
        "description": "Control internal system access",
        "name": "C002",
        "subcategories": [
          "AC.1.002",
					"AC.2.007",
          "AC.2.008",
          "AC.3.017",
          "AC.3.018"
        ],
        "id": "C002"
      },
      "C003": {
        "parent": "fn-0",
        "description": "Control remote system access",
        "name": "C003",
        "subcategories": [
          "AC.2.013",
          "AC.2.015",
          "AC.3.021"
        ],
        "id": "C003"
      },
      "C004": {
        "parent": "fn-0",
        "description": "Limit data access to authorized users and processes",
        "name": "C004",
        "subcategories": [
          "AC.1.003",
          "AC.2.016"
        ],
        "id": "C004"
      },
      "C007": {
        "parent": "fn-1",
        "description": "Define audit requirements",
        "name": "C007",
        "subcategories": [
          "AU.2.041",
          "AU.3.046"
        ],
        "id": "C007"
      },
      "C008": {
        "parent": "fn-1",
        "description": "Perform auditing",
        "name": "C008",
        "subcategories": [
          "AU.2.042",
          "AU.3.048"
        ],
        "id": "C008"
      },
      "C009": {
        "parent": "fn-1",
        "description": "Identify and protect audit information",
        "name": "C009",
        "subcategories": [
          "AU.3.049"
        ],
        "id": "C009"
      },
      "C013": {
        "parent": "fn-2",
        "description": "Establish configuration baselines",
        "name": "C013",
        "subcategories": [
          "CM.2.061",
          "CM.2.062",
          "CM.2.063"
        ],
        "id": "C013"
      },
      "C014": {
        "parent": "fn-2",
        "description": "Perform configuration and change management",
        "name": "C014",
        "subcategories": [
          "CM.2.064",
          "CM.2.065",
          "CM.3.068",
          "CM.3.069"
        ],
        "id": "C014"
      },
      "C015": {
        "parent": "fn-3",
        "description": "Grant access to authenticated entities",
        "name": "C015",
        "subcategories": [
          "IA.1.077",
          "IA.2.078",
          "IA.2.079",
          "IA.2.081",
          "IA.3.083",
          "IA.3.084"
        ],
        "id": "C015"
      },
      "C016": {
        "parent": "fn-4",
        "description": "Plan incident response",
        "name": "C016",
        "subcategories": [
          "IR.2.092"
        ],
        "id": "C016"
      },
      "C017": {
        "parent": "fn-4",
        "description": "Detect and report events",
        "name": "C017",
        "subcategories": [
          "IR.2.093"
        ],
        "id": "C017"
      },
      "C029": {
        "parent": "fn-5",
        "description": "Manage back-ups",
        "name": "C029",
        "subcategories": [
          "RE.2.137"
        ],
        "id": "C029"
      },
      "C031": {
        "parent": "fn-6",
        "description": "Identify and evaluate risk",
        "name": "C031",
        "subcategories": [
          "RM.2.141",
          "RM.2.142",
          "RM.3.144"
        ],
        "id": "C031"
      },
      "C032": {
        "parent": "fn-6",
        "description": "Manage risk",
        "name": "C032",
        "subcategories": [
          "RM.2.143"
        ],
        "id": "C032"
      },
      "C035": {
        "parent": "fn-7",
        "description": "Define and manage controls",
        "name": "C035",
        "subcategories": [
          "CA.2.158",
          "CA.3.161"
        ],
        "id": "C035"
      },
      "C038": {
        "parent": "fn-8",
        "description": "Define security requirements for systems and communications",
        "name": "C038",
        "subcategories": [
          "SC.2.179",
          "SC.3.177",
          "SC.3.180",
          "SC.3.181",
          "SC.3.183",
          "SC.3.185",
          "SC.3.187",
          "SC.3.190",
          "SC.3.191"
        ],
        "id": "C038"
      },
      "C039": {
        "parent": "fn-8",
        "description": "Control communications at system boundaries",
        "name": "C039",
        "subcategories": [
          "SC.1.175",
          "SC.1.176"
        ],
        "id": "C039"
      },
      "C040": {
        "parent": "fn-9",
        "description": "Identify and manage information system flaws",
        "name": "C040",
        "subcategories": [
          "SI.1.210"
        ],
        "id": "C040"
      },
      "C041": {
        "parent": "fn-9",
        "description": "Identify malicious content",
        "name": "C041",
        "subcategories": [
          "SI.1.211",
          "SI.1.212",
          "SI.1.213"
        ],
        "id": "C041"
      },
      "C042": {
        "parent": "fn-9",
        "description": "Perform network and system monitoring",
        "name": "C042",
        "subcategories": [
          "SI.2.216",
          "SI.2.217"
        ],
        "id": "C042"
      }
    },
    "subcategories": {
      "AC.1.001": {
        "parent": "C001",
        "description": "Limit information system access to authorized users, processes acting on behalf of authorized users or devices (including other information systems).",
        "name": "AC.1.001",
        "index": getIndex()
      },
      "AC.1.002": {
        "parent": "C002",
        "description": "Limit information system access to the types of transactions and functions that authorized users are permitted to execute.",
        "name": "AC.1.002",
        "index": getIndex()
      },
      "AC.2.007": {
        "parent": "C002",
        "description": "Employ the principle of least privilege, including for specific security functions and privileged accounts.",
        "name": "AC.2.007",
        "index": getIndex()
      },
      "AC.2.008": {
        "parent": "C002",
        "description": "Use non-privileged accounts or roles when accessing nonsecurity functions.",
        "name": "AC.2.008",
        "index": getIndex()
      },
      "AC.3.017": {
        "parent": "C002",
        "description": "Separate the duties of individuals to reduce the risk of malevolent activity without collusion.",
        "name": "AC.3.017",
        "index": getIndex()
      },
      "AC.3.018": {
        "parent": "C002",
        "description": "Prevent non-privileged users from executing privileged functions and capture the execution of such functions in audit logs.",
        "name": "AC.3.018",
        "index": getIndex()
      },
      "AC.2.013": {
        "parent": "C003",
        "description": "Monitor and control remote access sessions.",
        "name": "AC.2.013",
        "index": getIndex()
      },
      "AC.2.015": {
        "parent": "C003",
        "description": "Route remote access via managed access control points.",
        "name": "AC.2.015",
        "index": getIndex()
      },
      "AC.3.021": {
        "parent": "C003",
        "description": "Authorize remote execution of privileged commands and remote access to security-relevant information.",
        "name": "AC.3.021",
        "index": getIndex()
      },
      "AC.1.003": {
        "parent": "C004",
        "description": "Verify and control/limit connections to and use of external information systems.",
        "name": "AC.1.003",
        "index": getIndex()
      },
      "AC.2.016": {
        "parent": "C004",
        "description": "Control the flow of CUI in accordance with approved authorizations.",
        "name": "AC.2.016",
        "index": getIndex()
      },
      "AU.2.041": {
        "parent": "C007",
        "description": "Ensure that the actions of individual system users can be uniquely traced to those users so they can be held accountable for their actions.",
        "name": "AU.2.041",
        "index": getIndex()
      },
      "AU.3.046": {
        "parent": "C007",
        "description": "Alert in the event of an audit logging process failure.",
        "name": "AU.3.046",
        "index": getIndex()
      },
      "AU.2.042": {
        "parent": "C008",
        "description": "Create and retain system audit logs and records to the extent needed to enable the monitoring, analysis, investigation and reporting of unlawful or unauthorized system activity.",
        "name": "AU.2.042",
        "index": getIndex()
      },
      "AU.3.048": {
        "parent": "C008",
        "description": "Collect audit information (e.g., logs) into one or more central repositories.",
        "name": "AU.3.048",
        "index": getIndex()
      },
      "AU.3.049": {
        "parent": "C009",
        "description": "Protect audit information and audit logging tools from unauthorized access, modification and deletion.",
        "name": "AU.3.049",
        "index": getIndex()
      },
      "CM.2.061": {
        "parent": "C013",
        "description": "Establish and maintain baseline configurations and inventories of organizational systems (including hardware, software, firmware and documentation) throughout the respective system development life cycles.",
        "name": "CM.2.061",
        "index": getIndex()
      },
      "CM.2.062": {
        "parent": "C013",
        "description": "Employ the principle of least functionality by configuring organizational systems to provide only essential capabilities.",
        "name": "CM.2.062",
        "index": getIndex()
      },
      "CM.2.063": {
        "parent": "C013",
        "description": "Control and monitor user-installed software.",
        "name": "CM.2.063",
        "index": getIndex()
      },
      "CM.2.064": {
        "parent": "C014",
        "description": "Establish and enforce security configuration settings for information technology products employed in organizational systems.",
        "name": "CM.2.064",
        "index": getIndex()
      },
      "CM.2.065": {
        "parent": "C014",
        "description": "Track, review, approve or disapprove and log changes to organizational systems.",
        "name": "CM.2.065",
        "index": getIndex()
      },
      "CM.3.068": {
        "parent": "C014",
        "description": "Restrict, disable or prevent the use of nonessential programs, functions, ports, protocols and services.",
        "name": "CM.3.068",
        "index": getIndex()
      },
      "CM.3.069": {
        "parent": "C014",
        "description": "Apply deny-by-exception (blacklisting) policy to prevent the use of unauthorized software or deny-all, permit-by-exception (whitelisting) policy to allow the execution of authorized software.",
        "name": "CM.3.069",
        "index": getIndex()
      },
      "IA.1.077": {
        "parent": "C015",
        "description": "Authenticate (or verify) the identities of those users, processes or devices, as a prerequisite to allowing access to organizational information systems.",
        "name": "IA.1.077",
        "index": getIndex()
      },
      "IA.2.078": {
        "parent": "C015",
        "description": "Enforce a minimum password complexity and change of characters when new passwords are created.",
        "name": "IA.2.078",
        "index": getIndex()
      },
      "IA.2.079": {
        "parent": "C015",
        "description": "Prohibit password reuse for a specified number of generations.",
        "name": "IA.2.079",
        "index": getIndex()
      },
      "IA.2.081": {
        "parent": "C015",
        "description": "Store and transmit only cryptographically- protected passwords.",
        "name": "IA.2.081",
        "index": getIndex()
      },
      "IA.3.083": {
        "parent": "C015",
        "description": "Use multi-factor authentication for local and network access to privileged accounts and for network access to non-privileged accounts.",
        "name": "IA.3.083",
        "index": getIndex()
      },
      "IA.3.084": {
        "parent": "C015",
        "description": "Employ replay-resistant authentication mechanisms for network access to privileged and non-privileged accounts.",
        "name": "IA.3.084",
        "index": getIndex()
      },
      "IR.2.092": {
        "parent": "C016",
        "description": "Establish an operational incident-handling capability for organizational systems that includes preparation, detection, analysis, containment, recovery and user response activities.",
        "name": "IR.2.092",
        "index": getIndex()
      },
      "IR.2.093": {
        "parent": "C017",
        "description": "Detect and report events.",
        "name": "IR.2.093",
        "index": getIndex()
      },
      "RE.2.137": {
        "parent": "C029",
        "description": "Regularly perform and test data back-ups.",
        "name": "RE.2.137",
        "index": getIndex()
      },
      "RE.3.139": {
        "parent": "C029",
        "description": "Regularly perform complete, comprehensive and resilient data backups as organizationally-defined.",
        "name": "RE.3.139",
        "index": getIndex()
      },
      "RM.2.141": {
        "parent": "C031",
        "description": "Periodically assess the risk to organizational operations (including mission, functions, image or reputation), organizational assets and individuals, resulting from the operation of organizational systems and the associated processing, storage or transmission of CUI.",
        "name": "RM.2.141",
        "index": getIndex()
      },
      "RM.2.142": {
        "parent": "C031",
        "description": "Scan for vulnerabilities in organizational systems and applications periodically and when new vulnerabilities affecting those systems and applications are identified.",
        "name": "RM.2.142",
        "index": getIndex()
      },
      "RM.3.144": {
        "parent": "C031",
        "description": "Periodically perform risk assessments to identify and prioritize risks according to the defined risk categories, risk sources and risk measurement criteria.",
        "name": "RM.3.144",
        "index": getIndex()
      },
      "RM.2.143": {
        "parent": "C032",
        "description": "Remediate vulnerabilities in accordance with risk assessments.",
        "name": "RM.2.143",
        "index": getIndex()
      },
      "CA.2.158": {
        "parent": "C035",
        "description": "Periodically assess the security controls in organizational systems to determine if the controls are effective in their application.",
        "name": "CA.2.158",
        "index": getIndex()
      },
      "CA.3.161": {
        "parent": "C035",
        "description": "Monitor security controls on an ongoing basis to ensure the continued effectiveness of the controls.",
        "name": "CA.3.161",
        "index": getIndex()
      },
      "SC.2.179": {
        "parent": "C038",
        "description": "Use encrypted sessions for the management of network devices.",
        "name": "SC.2.179",
        "index": getIndex()
      },
      "SC.3.177": {
        "parent": "C038",
        "description": "Employ FIPS-validated cryptography when used to protect the confidentiality of CUI.",
        "name": "SC.3.177",
        "index": getIndex()
      },
      "SC.3.180": {
        "parent": "C038",
        "description": "Employ architectural designs, software development techniques and systems engineering principles that promote effective information security within organizational systems.",
        "name": "SC.3.180",
        "index": getIndex()
      },
      "SC.3.181": {
        "parent": "C038",
        "description": "Separate user functionality from system management functionality.",
        "name": "SC.3.181",
        "index": getIndex()
      },
      "SC.3.183": {
        "parent": "C038",
        "description": "Deny network communications traffic by default and allow network communications traffic by exception (e.g., deny all, permit by exception).",
        "name": "SC.3.183",
        "index": getIndex()
      },
      "SC.3.185": {
        "parent": "C038",
        "description": "Implement cryptographic mechanisms to prevent unauthorized disclosure of CUI during transmission unless otherwise protected by alternative physical safeguards.",
        "name": "SC.3.185",
        "index": getIndex()
      },
      "SC.3.187": {
        "parent": "C038",
        "description": "Establish and manage cryptographic keys for cryptography employed in organizational systems.",
        "name": "SC.3.187",
        "index": getIndex()
      },
      "SC.3.190": {
        "parent": "C038",
        "description": "Protect the authenticity of communications sessions.",
        "name": "SC.3.190",
        "index": getIndex()
      },
      "SC.3.191": {
        "parent": "C038",
        "description": "Protect the confidentiality of CUI at rest.",
        "name": "SC.3.191",
        "index": getIndex()
      },
      "SC.1.175": {
        "parent": "C039",
        "description": "Monitor, control and protect organizational communications (e.g., information transmitted or received by organizational information systems) at the external boundaries and key internal boundaries of the information systems.",
        "name": "SC.1.175",
        "index": getIndex()
      },
      "SC.1.176": {
        "parent": "C039",
        "description": "Implement subnetworks for publicly accessible system components that are physically or logically separated from internal networks.",
        "name": "SC.1.176",
        "index": getIndex()
      },
      "SI.1.210": {
        "parent": "C040",
        "description": "Identify, report and correct information and information system flaws in a timely manner.",
        "name": "SI.1.210",
        "index": getIndex()
      },
      "SI.1.211": {
        "parent": "C041",
        "description": "Provide protection from malicious code at appropriate locations within organizational information systems.",
        "name": "SI.1.211",
        "index": getIndex()
      },
      "SI.1.212": {
        "parent": "C041",
        "description": "Update malicious code protection mechanisms when new releases are available.",
        "name": "SI.1.212",
        "index": getIndex()
      },
      "SI.1.213": {
        "parent": "C041",
        "description": "Perform periodic scans of the information system and real-time scans of files from external sources as files are downloaded, opened or executed.",
        "name": "SI.1.213",
        "index": getIndex()
      },
      "SI.2.216": {
        "parent": "C042",
        "description": "Monitor organizational systems, including inbound and outbound communications traffic, to detect attacks and indicators of potential attacks.",
        "name": "SI.2.216",
        "index": getIndex()
      },
      "SI.2.217": {
        "parent": "C042",
        "description": "Identify unauthorized use of organizational systems.",
        "name": "SI.2.217",
        "index": getIndex()
      }
    }
  },
  "headers": [{
    "id": 1,
    "value": "Requirement",
    "classList": "dont-print col col-md-2 text-md-center",
    "style": null
  },
  {
    "id": 2,
    "value": "Sub-Requirement",
    "classList": "col col-md-2 text-md-center",
    "style": null
  },
  {
    "id": 3,
    "value": "Test Case",
    "classList": "print-sub-cat-col col col-md-2 text-md-center",
    "style": null
  },
  {
    "id": 4,
    "value": "Result",
    "classList": "print-result-col col col-md-2 text-md-center",
    "style": {
      "marginLeft": "-1rem"
    }
  },
  {
    "id": 5,
    "value": "Severity",
    "classList": "col col-md-1 text-md-left",
    "style": {
      "marginLeft": "3rem"
    }
  },
  {
    "id": 6,
    "value": "Evidence",
    "classList": "col col-md-1 text-md-center",
    "style": {
      "marginLeft": "6rem"
    }
  }
  ],
  "testCases": [
    {
      "id": "fn-0",
      "value": "Access Control (AC)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "Access Control (AC)"
      ],
      "categories": [
        "C001",
        "C002",
        "C003",
        "C004"
      ]
    },
    {
      "id": "fn-1",
      "value": "Audit & Accountability (AU)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "Audit & Accountability (AU)"
      ],
      "categories": [
        "C007",
        "C008",
        "C009"
      ]
    },
    {
      "id": "fn-2",
      "value": "Configuration Management (CM)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "Configuration Management (CM)"
      ],
      "categories": [
        "C013",
        "C014"
      ]
    },
    {
      "id": "fn-3",
      "value": "Identification & Authentication (IA)",
      "fgColor": "#e9ecef",
      "bgColor": "#4da6ff",
      "description": [
        "Identification & Authentication (IA)"
      ],
      "categories": [
        "C015"
      ]
    },
    {
      "id": "fn-4",
      "value": "Incident Response (IR)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "Incident Response (IR)"
      ],
      "categories": [
        "C016",
        "C017"
      ]
    },
    {
      "id": "fn-5",
      "value": "Recovery (RE)",
      "fgColor": "#e9ecef",
      "bgColor": "#4da6ff",
      "description": [
        "Recovery (RE)"
      ],
      "categories": [
        "C029"
      ]
    },
    {
      "id": "fn-6",
      "value": "Risk Management (RM)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "Risk Management (RM)"
      ],
      "categories": [
        "C031",
        "C032"
      ]
    },
    {
      "id": "fn-7",
      "value": "Security Assessment (CA)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "Security Assessment (CA)"
      ],
      "categories": [
        "C035"
      ]
    },
    {
      "id": "fn-8",
      "value": "System & Communications Protection (SC)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "System & Communications Protection (SC)"
      ],
      "categories": [
        "C038",
        "C039"
      ]
    },
    {
      "id": "fn-9",
      "value": "System & Information Integrity (SI)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        "System & Information Integrity (SI)"
      ],
      "categories": [
        "C040",
        "C041",
        "C042"
      ]
    }
  ]
}