import { auditorNameRegex, reportNameRegex } from "./Constants";
import { useEffect, useState } from "react";

import { getReportName } from "../../Common/ReportService";
import { getUser } from "../../../../services/AuthService";

export default () => {
	const
		[name, setReportName] = useState(getReportName('')),
		[auditor, setAuditorName] = useState(getUser().UserName),
		[isInvalid, setIsInvalid] = useState(true),
		reportType = 'report',
		auditorType = 'auditor',
		setValue = (type, value) => {

			if (type === reportType) {
				setReportName(value);
			}

			if (type === auditorType) {
				setAuditorName(value);
			}

			const isValidReport = reportNameRegex.test(name);
			const isValidAuditor = auditorNameRegex.test(auditor);
			const isValid = isValidReport && isValidAuditor;

			setIsInvalid(!isValid);
		},
		setReport = (valid, value) => setValue(reportType, valid, value),
		setAuditor = (valid, value) => setValue(auditorType, valid, value);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { setReport(name) }, [name]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => { setAuditor(auditor) }, [auditor]);

	return { name, auditor, isInvalid, setReport, setAuditor };
};