import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Row } from 'reactstrap';

import Button from '../../../components/Form/Button';
import {
	getIntegrations,
	getJiraProviderLink,
} from '../../../apiclients/Integrations';
import IntegrationItem from './IntegrationItem';
import { getAuthHeaders } from '../../../apiclients/Common';

const MOCK_INTEGRATIONS = [
	{
		type: 'jira',
		id: '1',
	},
	{
		id: '2',
		type: 'serviceNow',
	},
];

const IssueTracking = () => {
	const [data, setData] = useState([]);

	const startJiraOAuthFlow = () => {
		const authHeaders = getAuthHeaders();
		if (authHeaders) {
			const body = {
				domain: authHeaders.domain,
				account: authHeaders.Account,
				oauth: 'yes',
			};
			getJiraProviderLink(body, ({ link }) => window.open(link, '_blank'));
		}
	}

	const processIntegrations = (integrations) => {
		//console.log("integrations:", integrations);
		var integrationList = [];
		// BRL TODO may be a better way to flatten this.
		Object.keys(integrations).forEach( domain => {
			integrations[domain].forEach(element => {
				integrationList.push({
					domain: domain,
					...element
				})
			});
		});
		//console.log("integrationList:", integrationList);

		setData(integrationList);

	}

	useEffect(() => {
		const authHeaders = getAuthHeaders();
		if (authHeaders) {
			getIntegrations(({ integrations }) => processIntegrations(integrations), console.log);
		}
	}, []);

	return process.env.REACT_APP_FEAT_SHOW_ISSUE_TRACKING === 'DEMO' ? (
		<section>
			<div className="mb-4">
				<h1 className="mb-1">
					<Trans i18nKey="adminPanel.administration" />
				</h1>
				<p className="admin-subtitle">
					<Trans i18nKey="adminPanel.issueTracking" />
				</p>
			</div>
			<div className="mb-4">
				<h1 className="mb-1">
					<Trans i18nKey="adminPanel.issueTrackingContactUs" />
				</h1>
			</div>

		</section>
	) :
	(
		<section>
			<div className="mb-4">
				<h1 className="mb-1">
					<Trans i18nKey="adminPanel.administration" />
				</h1>
				<p className="admin-subtitle">
					<Trans i18nKey="adminPanel.issueTracking" />
				</p>
			</div>
			<Row className="gap-2 justify-content-end">
				<Button
					onClick={startJiraOAuthFlow}>
					<Trans i18nKey="button.addJira" />
				</Button>
				<Button>
					<Trans i18nKey="button.addServiceNow" />
				</Button>
			</Row>

			<ul className="list-group list-integrations mt-3">
				{/* TODO: To be replaced with real data */}
				{data.map(integration => (
					<li className="list-group-item" key={integration.id}>
						<IntegrationItem integration={integration} />
					</li>
				))}
			</ul>
		</section>
	);
};

export default IssueTracking;
