import React, { useMemo, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useReactTable, getCoreRowModel, getSortedRowModel, getPaginationRowModel } from '@tanstack/react-table';
import SeverityBadge from '../../../tests/Common/Controls/SeverityBadge';
import { removeItem, setItem } from '../../../../services/browserStorageService';
import { BTable } from '../../../../components/BTable';

const PAGE_SIZES = [10, 20, 50];

const SelectRow = ({ row }) => {
    return (
        <input
            type="radio"
            checked={row.getIsSelected()}
            onChange={row.getToggleSelectedHandler()}
            name="issueGroup"
        />
    );
};

const DataGrid = ({ report, issues, reportId, onSelected }) => {
    const columns = useMemo(() => [
        {
            accessorKey: 'select',
            header: () => <Trans i18nKey="vulnerabilities.select" />,
            cell: info => <SelectRow row={info.row} />
        },
        {
            accessorKey: 'SecurityTestRule',
            header: () => <Trans i18nKey="vulnerabilities.identified" />,
            cell: info => <div title={info.getValue()}>{info.getValue()}</div>
        },
        {
            accessorKey: 'Risk Percentage',
            header: () => <Trans i18nKey="vulnerabilities.recommendation" />,
            cell: info => <span><Trans i18nKey="vulnerabilities.fixIssue" values={{ value: info.getValue() }} /></span>
        },
        {
            accessorKey: 'Severity',
            header: () => <Trans i18nKey="vulnerabilities.severity" />,
            cell: info => <SeverityBadge severity={info.getValue()} />
        }
    ], []);

    const data = useMemo(() => report.map(item => ({
        ...item,
        select: <SelectRow />, // Додаємо компонент вибору в дані
        severity: item.Severity
    })), [report]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: PAGE_SIZES[0],
            },
            // sorting: [
            //     {
            //         id: 'Severity',
            //         desc: true
            //     }
            // ],
        },
        autoResetPageIndex: false,
    });

    return <BTable table={table} pageSizes={PAGE_SIZES} c/>;
};

export default DataGrid;
