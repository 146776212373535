import React from 'react';
import classNames from 'classnames';

const LegendButton = ({ children, className, ...props }) => {
	return (
		<button className={classNames(className, 'legend-button')} {...props}>
			{children}
		</button>
	);
};

LegendButton.defaultProps = {
	className: '',
};

export default LegendButton;
