import {
	Col,
	Row
} from "reactstrap";
import React, { useContext } from "react";

import ReportContext from "../Common/ReportContext";
import ResultSubCategory from "./ResultSubCategory";
import classnames from 'classnames';

export default function ({ data }) {

	const context = useContext(ReportContext),
		rowClass = classnames(
			'border', 'border-top-0', 'border-bottom-1', 'border-left-0', 'border-right-0', 'me-0', 'ms-0',
		),
		colClass = classnames('p-1', 'pt-4', 'border border-right-1 border-left-0 border-top-0 border-bottom-0');

	return (
		<Row className={rowClass}>
			<Col xs={2} className={colClass}>
				{data.name &&
					<>
						<strong>{data.name}</strong>
						{": "}
					</>
				}
				<span>{data.description}</span>
			</Col>
			<Col xs={10}>
				<div className="h-100">
					<ResultSubCategory
						refSubCategories={context.data.referenceData.subcategories}
						subcategories={data.subcategories}
					/>
				</div>
			</Col>
		</Row >
	);
};