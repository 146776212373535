import { Card, CardBody, Container } from 'reactstrap';
import React, { useEffect } from 'react';

import AuditTest from './Controls/AuditTest';
import AwsTestProgress from './Controls/AwsTestProgress';
import ErrorModal from './Controls/ErrorModal';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import Loader from '../../../components/Loader';
import { getReport } from '../Common/ReportService';
import useAuditReport from './Common/useAuditReport';
import { useLocation, useParams } from 'react-router-dom';

//todo: add validation for report ID and auditor name if someone tries to modify them in the URL

export default function () {
	const location = useLocation();
	const params = useParams();
	const {
		report,
		data,
		dataLoaded,
		reportId,
		auditor,
		title,
		loading,
		error,
		saving,
		saveProgress,
		isDraft,
		onAuditSaved,
	} = useAuditReport(getReport(location, params));

	useEffect(() => {
		return () => {
			console.log('unloaded?');
		};
	}, []);

	return (
		<>
			<Container fluid>
				<Header>
					<HeaderTitle>{title}</HeaderTitle>
				</Header>
				<Card>
					<CardBody>
						{error && <ErrorModal />}
						{!error && loading && <Loader />}
						{saving && (
							<AwsTestProgress
								isDraft={isDraft}
								reportProgress={saveProgress}
							/>
						)}
						{!error && !loading && !saving && (
							<div>
								{dataLoaded && (
									<AuditTest
										report={report}
										auditor={auditor}
										reportId={reportId}
										data={data}
										readOnly={report.review}
										onSave={onAuditSaved}
									/>
								)}
							</div>
						)}
					</CardBody>
				</Card>
			</Container>
		</>
	);
}
