
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	getPaginationRowModel,
} from '@tanstack/react-table';
import Loader from '../../../../components/Loader';
import PrintContext from '../Common/PrintContext';
import TopologyConfig from '../../../Topology/Config';
import { BTable } from '../../../../components/BTable';
const PAGE_SIZES = [15, 50, 100, 200];
const config = new TopologyConfig(),
	largeHeaderStyle = {
		width: '30%',
		textAlign: 'left',
	},
	smallHeaderStyle = {
		width: '15%',
		textAlign: 'left',
	},
	largeColumnStyle = {
		textAlign: 'left',
	},
	smallColumnStyle = {
		textAlign: 'justify',
	},
	redStyle = {
		color: 'red',
	},
	greenStyle = {
		color: '#28a745',
	},
	leftEllipses = {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		direction: 'rtl',
		textAlign: 'left',
	};

const columns = [
	{
		accessorKey: 'resourceId',
		header: 'Resource Id',
		cell: info => (
			<div title={info.getValue()} style={leftEllipses}>
				{info.getValue()}
			</div>
		),
		size: 300,
	},
	{
		accessorKey: 'name',
		header: 'Resource Name',
		size: 300,
	},
	{
		accessorKey: 'resourceType',
		header: 'Resource Type',
		cell: info => (
			<div>
				<img
					src={config.getImage(info.getValue())}
					alt={info.getValue()}
					className="me-2"
					style={{ height: '10%', width: '10%' }}
				/>
				<span>{config.getLabel(info.getValue())}</span>
			</div>
		),
		size: 300,
	},
	{
		accessorKey: 'parent',
		header: 'Parent Id',
		cell: info => (
			<div title={info.getValue()} style={leftEllipses}>
				{info.getValue()}
			</div>
		),
		size: 300,
	},
	{
		accessorKey: 'level',
		header: 'Level',
		size: 150,
	},
	{
		accessorKey: 'nonCompliantRules',
		header: 'Compliant',
		cell: info => {
			const nonCompliantRules = info.getValue();
			const isCompliant = (nonCompliantRules ?? []).length === 0;
			let index = 0;
			const rules = nonCompliantRules.map(
				rule => (rule = `${++index}. ${rule}`)
			);
			const title = isCompliant ? undefined : rules.join('\n');
			return (
				<span title={title} style={isCompliant ? greenStyle : redStyle}>
					{isCompliant ? 'Compliant' : 'Non-Compliant'}
				</span>
			);
		},
		size: 300,
	},
];

// const defaultSorted = [
// 	{
// 		id: 'parent',
// 		desc: false,
// 	},
// 	{
// 		id: 'resourceId',
// 		desc: false,
// 	},
// ];

const nodeMapper = nodes => {
	let index = 0;
	return nodes.map(node => {
		const data = node.data;
		return {
			key: index++,
			name: data.name,
			resourceId: data.id,
			level: data.level,
			parent: data.parent,
			resourceType: data.resourcetype,
			nonCompliantRules: data['non-compliant-rules'],
		};
	});
};

export default function () {
	const { topology } = useContext(PrintContext),
		[nodes] = topology,
		[rows, setRows] = useState([]),
		[loaded, setLoaded] = useState(false);

	useEffect(() => {
		setRows(nodeMapper(nodes));
		setLoaded(nodes.length > 0);
	}, [nodes]);

	const table = useReactTable({
		data: rows,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		initialState: {
			pagination: {
				pageSize: PAGE_SIZES[0],
			},
			sorting: columns.map(col => ({
				id: col.accessorKey,
				desc: false,
			})),
			// sorting: [
			// 	{
			// 		id: 'resourceId',
			// 		desc: false,
			// 	},
			// ],
		},
		autoResetPageIndex: false,
	});

	return (
		<Card className="page-break d-flex">
			<CardHeader>
				<CardTitle>
					<h3>RESOURCE LIST</h3>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{!loaded && <Loader />}
				{loaded && <BTable table={table} pageSizes={PAGE_SIZES} />}
			</CardBody>
		</Card>
	);
}
