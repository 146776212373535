import { useCallback, useState } from 'react';
import { EvaluationApiClient } from '../apiclients/EvaluationApiClient';
import { getSyncResults } from '../pages/tests/AutomatedTests/ResultService';
import {
	getCompliantResourceList,
	getIssueList,
} from '../apiclients/Ransomware';

const defaultFormat = nodes => nodes;

const getDeviceIssueMap = (issues, nodes) => {
	if (!issues) {
		return new Map();
	}

	const mapped = new Map();

	const update = (issue, rules) => {
		rules.forEach(x => {
			const name = x.ResourceName;

			if (mapped.has(name)) {
				let r = mapped.get(name);
				if (r.indexOf(issue) < 0) {
					r.push(issue);
				}
			} else {
				mapped.set(x.ResourceName, [issue]);
			}
		});
	};

	for (const [name, rules] of Object.entries(issues)) {
		update(name, rules);
	}

	for (const index in nodes) {
		const node = nodes[index];
		const nodeId = node.data.id;
		if (mapped.has(nodeId)) {
			node.data.issues = mapped.get(nodeId);
		}
	}

	return mapped;
};

const useReportInfo = () => {
	const [results, setResults] = useState([]);
	const [deviceIssueMap, setDeviceIssueMap] = useState(new Map());
	const [data, setData] = useState({
		loaded: false,
		nodes: [],
		error: false,
		issues: {},
	});
	const [loading, setLoading] = useState(false);

	const fetchData = useCallback(
		async (report, format = defaultFormat, issues) => {
			setLoading(true);
			const client = new EvaluationApiClient();

			const { ReportId, ConformancePack, EvalId, Fragments } = report;

			if (
				ConformancePack &&
				ConformancePack !== '' &&
				ReportId &&
				ReportId !== ''
			) {
				const reportInfo = {
					PackName: ConformancePack,
					ConformancePack,
					EvalId,
					Fragments,
					ReportId,
				};

				const resultsResponse = await getSyncResults(reportInfo);
				setResults(resultsResponse.rows);

				if (ConformancePack !== 'RRA') {
					client.getliveEnvironmentCompliance(
						{
							PackName: ConformancePack,
							ReportId,
						},
						data => {
							setData({
								loaded: true,
								nodes: format(data),
								error: false,
								issues: {},
							});
							setLoading(false);
						},
						e => {
							console.error('There was an error fetching the topology', e);
							setData({
								loaded: false,
								nodes: [],
								error: true,
								issues: {},
							});
							setLoading(false);
						}
					);
				} else {
					const response = await getCompliantResourceList(ReportId);
					const iss = await getIssueList(ReportId);

					if (response.success && response.data) {
						setDeviceIssueMap(getDeviceIssueMap(issues, response.data));
						setData({
							loaded: true,
							nodes: format(response.data),
							error: false,
							issues: iss.data || {},
						});
						setLoading(false);
					} else {
						setData({
							loaded: false,
							nodes: [],
							error: true,
							issues: {},
						});
						setLoading(false);
					}
				}
			}
		},
		[]
	);

	const formatReport = (node, report) => {
		if (!node) {
			return { data: [], info: {} };
		}
		let rules = node?.['non-compliant-rules'] || [];
		let info = {};

		if (report.ConformancePack === 'RRA') {
			if (deviceIssueMap.has(node.id)) {
				const mapped = deviceIssueMap.get(node.id) || [];
				const combined = mapped.concat(rules);
				// filter to ensure the array of rules only has unique values
				node['non-compliant-rules'] = combined.filter((value, index, array) => array.indexOf(value) === index);;
				rules = combined;
			}

			info = rules.map(rule => {
				const issues = data.issues?.[rule];

				let infoObj = issues?.filter(
					item => item.ResourceName === node.id
				)?.[0];

				if (infoObj) {
					infoObj.RealSecurityTestRule = infoObj.SecurityTestRule;
					infoObj.SecurityTestRule = rule;
				} else {
					infoObj = results?.find(item => item.SecurityTestRule === rule);
				}

				return infoObj || {};
			});
		} else {
			info = rules?.map(rule => {
				const ruleObj = results?.find(item => item.SecurityTestRule === rule);
				return ruleObj;
			});
		}

		if (node.issue?.length || node['non-compliant-rules']?.length) {
			node.compliance = 'Non-Compliant'
		}

		const { issues, ...nodeInfo } = node;
		const formatInfo = info.filter(Boolean);
		return { data: nodeInfo, info: formatInfo || [] };
	};

	return {
		formatReport,
		data,
		setData,
		results,
		fetchData,
		loading,
	};
};

export default useReportInfo;
