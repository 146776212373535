import { EventType, EventTypeToProp } from "../../Common/EventType";
import { useEffect, useState } from "react";

const categoryProp = EventTypeToProp[EventType.CategoryChanged];

export default function (navState, testCategories, parentId, testCase) {

	const [categoryIds, setCategoryIds] = useState(Object.keys(testCategories));
	const [categories, setCategories] = useState(testCategories);

	const
		[selected, setSelected] = useState(categoryIds[0]),
		onSelect = id => {

			const updated = navState.state[categoryProp] ?? {};
			updated[testCase] = id;

			navState.onNavEvent(EventType.CategoryChanged, updated);
			setSelected(id);
		}

		useEffect(() => {
			const updated = navState.state[categoryProp] ?? {};
			updated[testCase] = selected;

			navState.onNavEvent(EventType.CategoryChanged, updated);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

	useEffect(() => {
		const updatedCategoryIds = Object.keys(testCategories);
		setCategoryIds(updatedCategoryIds);

		const findId = () => {
			const prev = navState.state[categoryProp] ?? {};
			return prev[testCase];
		};

		const lastCategoryId = findId();
		const matchingIndex = updatedCategoryIds.findIndex(id => id === lastCategoryId);
		const updatedId = matchingIndex > -1 ? lastCategoryId : updatedCategoryIds[0];
		setSelected(updatedId);

		setCategories(testCategories);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [testCategories, parentId, testCase]);

	return {
		categoryIds,
		categories,
		selected,
		onSelect
	};
}