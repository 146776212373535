import React from 'react';
import {
	Card,
	CardHeader,
	CardBody,
	Container,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	UncontrolledDropdown,
} from 'reactstrap';
import { MoreVertical } from 'react-feather';
import LegendItem from './item';
import SelectPack from './SelectPack';
import SelectReport from './SelectReport';
import { useTranslation } from 'react-i18next';

const cardBackgroundStyle = { background: '#F4F7FC' };
const cardBodyBackgroundStyle = {
	maxHeight: '720px',
	overflowY: 'auto',
	margin: '10px 10px 10px 0',
};

const Legend = props => {
	const { t } = useTranslation();
	return (
		<Card className="d-flex dashboard-card">
			<CardHeader style={cardBackgroundStyle}>
				<Row style={{ alignItems: 'center' }}>
					<Col xs="11">{props.reportComponent}</Col>
					<Col xs="1" className="pr-0">
						<h5 className="float-end mt-1">
							<div className="card-actions float-end">
								<UncontrolledDropdown className="d-inline-block">
									<DropdownToggle tag="a">
										<MoreVertical />
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem
											onClick={() => props.showHideAll('show', 'compliant')}
										>
											<span>{t('topology.showCompliant')}</span>
										</DropdownItem>
										<DropdownItem
											onClick={() => props.showHideAll('hide', 'compliant')}
										>
											<span>{t('topology.hideCompliant')}</span>
										</DropdownItem>
										<DropdownItem
											disabled={props.hidden}
											onClick={() => props.showHideAll('show', 'noncompliant')}
										>
											<span>{t('topology.showNonCompliant')}</span>
										</DropdownItem>
										<DropdownItem
											disabled={props.hidden}
											onClick={() => props.showHideAll('hide', 'noncompliant')}
										>
											<span>{t('topology.hideNonCompliant')}</span>
										</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						</h5>
					</Col>
				</Row>
			</CardHeader>
			<CardBody style={cardBodyBackgroundStyle}>
				<Container fluid className="pl-0">
					{props.legendItems.map((item, index) => (
						<LegendItem
							key={item.label}
							index={index}
							item={item}
							itemUpdate={props.itemUpdate}
							{...props}
						/>
					))}
				</Container>
			</CardBody>
		</Card>
	);
};

export default Legend;

export { SelectPack, SelectReport };
