const getReportName = () => {
	const d = new Date(),
		zeroPad = text => ('0' + text).slice(-2),
		datePart = `${d.getFullYear()}${zeroPad(d.getMonth() + 1)}${zeroPad(
			d.getDate()
		)}`,
		timePart = `${zeroPad(d.getHours())}${zeroPad(d.getMinutes())}${zeroPad(
			d.getSeconds()
		)}`;

	return `Report_${datePart}_${timePart}`;
};

const getDataPoint = ({ seriesIndex, dataPointIndex, w }) =>
	w.config.series[seriesIndex].data[dataPointIndex];

export { getReportName, getDataPoint };
