import React, { useState } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { t } from 'i18next';

import { ReactComponent as CloseSvg } from '../assets/img/close.svg';
import ComplianceInfo from './ComplianceInfo';

import INFO_KEYS from '../constants/InfoKeys';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedItem } from '../redux/actions/topologyActions';
import NonComplianceDropdown from './NonComplianceDropdown';
import { getUserType } from '../services/AuthService';
import { CloudTypes } from '../constants/CloudInfo';

const cardBackgroundStyle = {
	height: '3.5rem',
	display: 'flex',
	justifyContent: 'flex-end',
	paddingBottom: '0',
};
const cardBodyBackgroundStyle = {
	height: '100%',
	overflowY: 'auto',
};

const InfoModal = ({ data, onClose, style, report, handleRedirect }) => {
	console.log("infomodal data:", data);
	const { meta, ...rest } = data.data || data;
	const [openedItem, setOpenedItem] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const onOpen = newItem => {
		setOpenedItem(openedItem === newItem ? '' : newItem);
	};

	const onRedirect = ({ id, type, rule, rcause, info }) => {
		if (!report) return;

		dispatch(setSelectedItem({ data, report }));

		const queryParams = new URLSearchParams();
		const url = '/talasmart/resource';
		const pack = report.ConformancePack || 'RRA';
		const isK8S = getUserType() === CloudTypes.K8s;

		console.log(
			'redirecting, pack:',
			pack,
			'real pack:',
			report.ConformancePack,
			'isK8S:',
			isK8S
		);
		console.log('rule:', rule, 'rcause:', rcause, 'info:', info);
		if (true === isK8S) {
			queryParams.set('resourceId', info.ResourceName);
			queryParams.set('resourceType', type || '');
			// TODO fix this when we have a clear grouping.
			queryParams.set('securityTestRule', info.SecurityTestRule);
			queryParams.set('rootCause', info.SecurityTestRule || '');
			queryParams.set('reportName', report.ReportId);
			queryParams.set('evalId', report.EvalId);
			queryParams.set('numFragments', 1);
			queryParams.set('pack', pack);
			queryParams.set('rawResourceId', info.ResourceId);
		} else if ('RRA' === pack) {
			// TODO we can probably extract the other fields from info and remove them from the params.

			queryParams.set('resourceId', id);
			queryParams.set('resourceType', type || '');
			queryParams.set('securityTestRule', rule || '');
			queryParams.set('rootCause', info.RealSecurityTestRule || '');
			queryParams.set('reportName', report.ReportId);
			queryParams.set('evalId', report.EvalId);
			queryParams.set('numFragments', report.Fragments || 1);
			queryParams.set('pack', pack);
		} else {
			queryParams.set('resourceId', id);
			queryParams.set('resourceType', type || '');
			queryParams.set('securityTestRule', rule || '');
			queryParams.set('rootCause', rcause || '');
			queryParams.set('reportName', report.ReportId);
			queryParams.set('evalId', report.EvalId);
			queryParams.set('numFragments', report.Fragments || 1);
			queryParams.set('pack', pack);
		}
		handleRedirect && handleRedirect();
		navigate(`${url}?${queryParams.toString()}`);
	};

	return (
		<Card className="dashboard-card" style={style}>
			<CardHeader style={cardBackgroundStyle}>
				<Button
					size="sm"
					onClick={() => onClose(false)}
					className="legend-button close-button">
					<CloseSvg />
				</Button>
			</CardHeader>
			<CardBody style={cardBodyBackgroundStyle} className="my-7-step">
				<div className="w-150 my-8-step">
					{Object.keys(rest).map(key =>
						key === 'non-compliant-rules' ? (
							<NonComplianceDropdown
								data={data}
								item={rest[key]}
								onOpen={onOpen}
								openedItem={openedItem}
								onRedirect={onRedirect}
							/>
						) : (
							<div className="row my-2" key={'rest-' + key}>
								<div className="col-sm-12 text-body p-0 pb-1 compliance-list-heading">
									{INFO_KEYS[key] || key}
								</div>
								{Array.isArray(rest[key]) ? (
									<div className="col-sm-12 compliance-list-container p-0">
										<ul className="compliance-list  ">
											{rest[key].map(keyItem => (
												<li class="text-danger" key={'ncr-' + keyItem}>
													{keyItem}
												</li>
											))}
										</ul>
									</div>
								) : (
									<div className="col-sm-12 text-body p-0 pb-1 border-bottom border-secondary">
										{rest[key]}
									</div>
								)}
							</div>
						)
					)}
					{meta && (
						<div>
							<div className="text-body row my-2 compliance-list-heading">
								{t('table.metaData')}:
							</div>
							{Object.keys(meta).map(key => (
								<div className="row my-2">
									<div className="col-sm-12 p-0 pb-1 text-body compliance-list-heading">
										{key}
									</div>
									<div className="col-sm-12 p-0 pb-1 text-body border-bottom border-secondary">
										{meta[key]}
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

export default InfoModal;
