import React from 'react';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import figure13 from '../../../assets/img/figure13.png';
import figure14 from '../../../assets/img/figure14.png';
import figure15 from '../../../assets/img/figure15.png';
import figure16 from '../../../assets/img/figure16.png';
import figure17 from '../../../assets/img/figure17.png';

function Step5() {
	const { t } = useTranslation();
	return (
		<div id="step5" className="instructions-steps mt-4">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 5</h5>
				<div>
					<h5 className="steps-title">{t('detailedInstructions.IAMUser')}</h5>
				</div>
			</div>
			<p className="m-0 p-0">{t('detailedInstructions.groupCreated')}</p>
			<p className="m-0 p-0">{t('detailedInstructions.twoWays')}</p>
			<ul>
				<li>{t('detailedInstructions.newIAM')}</li>
				<li>{t('detailedInstructions.existingIAM')}</li>
			</ul>
			<div className="d-flex ms-3">
				<img src={icon1} alt="icon1" className="img" />
				{t('detailedInstructions.accessManagement')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure13} alt="figure13" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure13')}
					</p>
				</div>
			</div>
			<div className="d-flex">
				<img src={icon2} alt="icon2" className="img" />
				{t('detailedInstructions.createUser')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure14} alt="figure14" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure14')}
					</p>
				</div>
			</div>
			<div className="d-flex">
				<img src={icon3} alt="icon3" className="img" />
				{t('detailedInstructions.userName')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure15} alt="figure15" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure15')}
					</p>
				</div>
			</div>
			<div className="d-flex">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.talaSecureUsers')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure16} alt="figure16" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure16')}
					</p>
				</div>
			</div>
			<div className="d-flex">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.settings')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure17} alt="figure17" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure17')}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Step5;
