import React from 'react';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

import Loader from '../../components/Loader';

import NetworkTopology from './Controls/TopologyControl';
import LegendButton from './Legend/LegendButton';
import { Check } from 'react-feather';

import ReviewButton from '../tests/AutomatedTests/ReviewButton';
import LoaderTopology from '../../components/LoaderTopology';

const cardBackgroundStyle = { background: '#F4F7FC' };
const chartDim = { h: 650, w: 1200 };



export default class NetworkTopologyAndResource extends React.Component {
	constructor(props) {
		super(props);
		
		this.topologyRef = React.createRef();
		this.resetLayout = this.resetLayout.bind(this);
		this.refreshTopology = this.refreshTopology.bind(this);
		this.onRenderError = this.onRenderError.bind(this);
		this.state = {
			error: false,
			modal: false,
		};
	}

	resetLayout() {
		this.topologyRef.current.resetLayout();
	}

	refreshTopology() {
		this.props.refreshTopology(this.resetLayout);
	}

	onRenderError(error) {
		console.log(error);
		this.setState({ error: true });
	}

	onMouseOver = e => {
		const ele = e.target;
		const data = ele.data();

		this.props.showModal(data);
	};

	render() {
		if (this.topologyRef.current) {
			console.log('calling updateNodes');
			this.topologyRef.current.updateNodes(this.props.nodes);
		} else {
			console.log('no topo ref');
		}
		console.log(this.state.error);

		return (
			<Card className="w-100 dashboard-card">
				{/* {this.props.reloading && <Loader className="vh-100" />} */}
				{this.props.reloading && (
					<LoaderTopology
						className="vh-100"
						progressPct={this.props.reportProgress}
					/>
				)}
				{!this.props.reloading && (
					<CardHeader style={cardBackgroundStyle}>
						<Row className="no-gutters ">
							<Col>
								<LegendButton
									style={{ marginRight: '15px' }}
									onClick={this.resetLayout}>
									{t('topology.refreshLayout')}
								</LegendButton>
								<LegendButton
									onClick={this.refreshTopology}
									style={{ marginRight: '15px' }}>
									{t('topology.reloadData')}
								</LegendButton>

								{
									this.props.showReviewButton ? (
										<ReviewButton
											style={{
												background: '#fff',
											}}
											className="special-review-button"
											data={this.props.report.data}
											reviewUrl={this.props.report.reviewUrl}
											report={this.props.report.report}
										/>
									) : null
									// <LegendButton
									// 	style={
									// 		{
									// 			// background: '#cccccc',
									// 		}
									// 	}
									// 	disabled={true}>
									// 	{t('button.generateReport')}
									// </LegendButton>
								}
							</Col>
						</Row>
					</CardHeader>
				)}
				{this.props.title && (
					<h2 className="topology-chart-title">{this.props.title}</h2>
				)}
				<CardBody className="my-5-step my-6-step">
					<div className="mt-2">
						<h5 className="mb-0">{this.props.info?.title}</h5>
					
						
						<p>{this.props.info?.date}</p>
					</div>
					{this.props.reloading ? (
						<h3 className="topology-loading">
							{/* {t('topology.loading')}... */}
						</h3>
					) : (
					
						<h3 className="topology-loading topology-loading_finished">
							{t('topology.loadingComplete')} <Check />
						</h3>
					
					)}
					{this.state.error && (
						<div
							fluid="true"
							style={{ height: '37rem' }}
							className="row justify-content-center align-items-center text-danger ">
							<span>
								<FontAwesomeIcon
									icon={faExclamationTriangle}
									className="me-3"
								/>
								<h3 className="mx-auto text-danger d-inline">
									{t('topology.errorRendering')}
								</h3>
							</span>
						</div>
					)}
					{!this.state.error && !this.props.reloading && (
						<NetworkTopology
							ref={this.topologyRef}
							nodes={this.props.nodes}
							onMouseOver={this.onMouseOver}
							onHover={this.onHover}
							onRenderError={this.onRenderError}
							chartDim={chartDim}
						/>
					)}
				</CardBody>
			</Card>
		);
	}
}
