import switchImage from '../../assets/img/onprem-icons/switch.svg'
import networkSegmentImage from '../../assets/img/onprem-icons/networkSegment.svg'
import mobilePhoneImage from '../../assets/img/onprem-icons/mobilePhone.svg'
import desktopImage from '../../assets/img/onprem-icons/desktop.svg'
import accessPointImage from '../../assets/img/onprem-icons/accessPoint.svg'
import routerImage from '../../assets/img/onprem-icons/router.svg'

const defaultDim = {
	height: '48px',
	width: '48px',
};

const defaultColour = 'rgba(255, 255, 255, 0.4)';

const networkSegment = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(200, 200, 200, 1)',
	image: `${networkSegmentImage}`,
};

const networkSwitch = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${switchImage}`,
};

const accessPoint = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(180, 200, 180, 0.8)',
	image: `${accessPointImage}`,
};

const desktopPc = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${desktopImage}`,
};

const mobilePhone = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${mobilePhoneImage}`,
};

const router = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${routerImage}`,
};


const onpremConfig = {
	'network-segment': {
		label: 'Network Segment',
		image: networkSegment,
		color: '#0039a6',
	},
	'network-switch': {
		label: 'Switch',
		image: networkSwitch,
		color: '#7a49a5',
	},
	'network-access-point': {
		label: 'Access Point',
		image: accessPoint,
		color: '#ff7b12',
	},
	'desktop-pc': {
		label: 'Desktop PC',
		image: desktopPc,
		color: '#28a745',
	},
	'mobile-phone': {
		label: 'Mobile Phone',
		image: mobilePhone,
		color: '#ff4433',
	},
	'router': {
		label: 'Router',
		image: router,
		color: '#ff4433',
	}

};

export default onpremConfig;
