import React, { useState } from 'react';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import step3 from '../../../assets/img/step3.png';
import figure3 from '../../../assets/img/figure3.png';
import figure4 from '../../../assets/img/figure4.png';
import figure5 from '../../../assets/img/figure5.png';
import figure6 from '../../../assets/img/figure6.png';
import copyBtn from '../../../assets/img/copyBtn.svg';

function Step3() {
	const { t } = useTranslation();
	const [isClickedCopy, setIsClickedCopy] = useState(false);

	const copyToClipBoard = async text => {
		try {
			await navigator.clipboard.writeText(text);
			setIsClickedCopy(true);
		} catch (err) {
			setIsClickedCopy(false);
		}
	};

	const copyTextStyle = {
		color: '#2474F3',
		fontFamily: 'Inter',
		fontSize: 16,
	};

	const policyDocument = {
		Version: '2012-10-17',
		Statement: [
			{
				Sid: 'VisualEditor0',
				Effect: 'Allow',
				Action: 'config:PutDeliveryChannel',
				Resource: '*',
			},
			{
				Effect: 'Allow',
				Action: [
					'sns:AddPermission',
					'sns:CreateTopic',
					'sns:DeleteTopic',
					'sns:GetTopicAttributes',
					'sns:ListPlatformApplications',
					'sns:ListTopics',
					'sns:SetTopicAttributes',
				],
				Resource: '*',
			},
			{
				Effect: 'Allow',
				Action: [
					's3:CreateBucket',
					's3:GetBucketAcl',
					's3:GetBucketLocation',
					's3:GetBucketNotification',
					's3:GetBucketPolicy',
					's3:GetBucketRequestPayment',
					's3:GetBucketVersioning',
					's3:ListAllMyBuckets',
					's3:ListBucket',
					's3:ListBucketMultipartUploads',
					's3:ListBucketVersions',
					's3:PutBucketPolicy',
				],
				Resource: 'arn:aws:s3:::*',
			},
			{
				Effect: 'Allow',
				Action: [
					'iam:CreateRole',
					'iam:GetRole',
					'iam:GetRolePolicy',
					'iam:ListRolePolicies',
					'iam:ListRoles',
					'iam:PassRole',
					'iam:PutRolePolicy',
					'iam:AttachRolePolicy',
					'iam:CreatePolicy',
					'iam:CreatePolicyVersion',
					'iam:DeletePolicyVersion',
					'iam:CreateServiceLinkedRole',
					'iam:GetUser',
				],
				Resource: '*',
			},
			{
				Effect: 'Allow',
				Action: [
					'cloudtrail:DescribeTrails',
					'cloudtrail:GetTrailStatus',
					'cloudtrail:LookupEvents',
				],
				Resource: '*',
			},
			{
				Effect: 'Allow',
				Action: ['config:*', 'tag:Get*'],
				Resource: '*',
			},
			{
				Effect: 'Allow',
				Action: [
					'ssm:DescribeDocument',
					'ssm:GetDocument',
					'ssm:DescribeAutomationExecutions',
					'ssm:GetAutomationExecution',
					'ssm:ListDocuments',
					'ssm:StartAutomationExecution',
				],
				Resource: '*',
			},
			{
				Effect: 'Allow',
				Action: [
					'elasticloadbalancing:DescribeLoadBalancerAttributes',
					'elasticloadbalancing:DescribeSSLPolicies',
					'elasticloadbalancing:DescribeLoadBalancers',
					'elasticloadbalancing:DescribeTargetGroupAttributes',
					'elasticloadbalancing:DescribeListeners',
					'elasticloadbalancing:DescribeTags',
					'elasticloadbalancing:DescribeAccountLimits',
					'elasticloadbalancing:DescribeTargetHealth',
					'elasticloadbalancing:DescribeTargetGroups',
					'elasticloadbalancing:DescribeListenerCertificates',
					'elasticloadbalancing:DescribeRules',
				],
				Resource: '*',
			},
		],
	};
	return (
		<div id="step3" className="instructions-steps">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 3</h5>
				<div>
					<h5 className="steps-title">{t('detailedInstructions.accessIAM')}</h5>
					<p>{t('detailedInstructions.AWSsetupPolicy')}</p>
					<div className="d-flex ">
						<img src={icon1} alt="icon1" className="img" />
						{t('detailedInstructions.policies')}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={step3} />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure2')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon2} alt="icon1" className="img" />
				{t('detailedInstructions.createPolicy')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure3} />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure3')}
					</p>
				</div>
			</div>
			<div className="d-flex">
				<img src={icon3} alt="icon1" className="img" />
				{t('detailedInstructions.selectJSON')}
				<a href="#JSON" className="ms-1 me-1">
					{t('detailedInstructions.JSONpolicy')}
				</a>
				{t('detailedInstructions.editor')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure4} />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure4')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.namePolicy')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure5} />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure5')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.createPolicyBtn')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure6} />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure6')}
					</p>
				</div>
			</div>
			<div className="d-flex  gap-3">
				<h5 className="steps-number + mt-1">
					{t('detailedInstructions.JSONPolicy')}
				</h5>
				<div>
					<div
						onClick={() =>
							copyToClipBoard(JSON.stringify(policyDocument, null, 4))
						}
						className="d-flex gap-1 justify-content-center align-items-center p-1 mb-2 + copyBtn">
						<img src={copyBtn} alt="copyBtn" />
						<p style={copyTextStyle} className="m-0 p-0">
							{t('detailedInstructions.copyLink')}
						</p>
					</div>

					{isClickedCopy && (
						<p className="linkCopied + m-0 p-0">
							{t('detailedInstructions.clipboard')}
						</p>
					)}
				</div>
			</div>
			<div
				id="JSON"
				className="d-flex justify-content-center align-items-center">
				<pre>{JSON.stringify(policyDocument, null, 4)}</pre>
			</div>
		</div>
	);
}

export default Step3;
