import { Button } from 'reactstrap';
import React from 'react';
import { t } from 'i18next';

export default function ({ onClick, onCancel, disableAction }) {
	return (
		<div className="container-fluid d-block clearfix">
			<Button size="md" color="secondary" onClick={onCancel}>
				<span className="ms-1">{t('button.cancel')}</span>
			</Button>
			<div className=" float-end">
				<Button
					size="md"
					color="primary"
					onClick={onClick}
					disabled={disableAction}>
					<span className="ms-1">{t('button.start')}</span>
				</Button>
			</div>
		</div>
	);
}
