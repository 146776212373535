/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { Col, Row } from 'reactstrap';
import React, { useCallback, useEffect, useState } from 'react';
import TopologyConfig from '../../Topology/Config';

import Loader from '../../../components/Loader';
import NetworkTopologyAndResource from '../../Topology/TopologyAndResource';
import Legend, { SelectReport, SelectPack } from '../../Topology/Legend';
import ErrorInfo from '../../Topology/ErrorInfo';
import InfoModal from '../../../components/InfoModal';
import { EvaluationApiClient } from '../../../apiclients/EvaluationApiClient';
import useReportInfo from '../../../hooks/useReportInfo';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedItem } from '../../../redux/actions/topologyActions';
import { getReviewUrl } from '../../../services/reportService';

const useTopology = ({ report, nodeShow, issues }) => {
	const [selectedNode, setSelectedNode] = useState({});
	const [showPopup, setShowPopup] = useState(false);
	const { fetchData, data, formatReport, setData, loading } = useReportInfo();

	const filterNodes = useCallback(
		curNodes => {
			const newNodes = [];

			for (const n of curNodes) {
				if (nodeShow[n.data.resourcetype]) {
					if (!n.data.compliance) {
						if (nodeShow[n.data.resourcetype].showCompliant) newNodes.push(n);
					} else {
						if (
							('Compliant' === n.data.compliance &&
								nodeShow[n.data.resourcetype].showCompliant) ||
							('Non-Compliant' === n.data.compliance &&
								nodeShow[n.data.resourcetype].showNonCompliant)
						) {
							newNodes.push(n);
						}
					}
				} else newNodes.push(n);
			}
			return newNodes;
		},
		[nodeShow]
	);

	useEffect(() => {
		if (report) {
			fetchData(report, filterNodes, issues);
		} else {
			setData({ loaded: true, nodes: [], issues: [], error: true });
		}
	}, [report, nodeShow, fetchData, setData, filterNodes, issues]);

	const onShowPopup = node => {
		const formattedReport = formatReport(node, report);
		setShowPopup(true);
		setSelectedNode(formattedReport);
	};

	const onMouseOut = e => {
		setSelectedNode({});
		setShowPopup(false);
	};

	return {
		loading,
		showPopup,
		data,
		selectedNode,
		onShowPopup,
		onMouseOut,
		setShowPopup,
		setSelectedNode,
		fetchData: () => fetchData(report, filterNodes, issues),
	};
};

const DashboardTopology = ({ report, setReport, reports, issues }) => {
	const [nodeShow, setNodeShow] = useState({});
	const {
		showPopup,
		data,
		selectedNode,
		onShowPopup,
		onMouseOut,
		fetchData,
		setSelectedNode,
		setShowPopup,
		loading,
	} = useTopology({ report, nodeShow, issues });
	const selectedItem = useSelector(state => state.topology.selectedItem);

	const config = new TopologyConfig();
	const [legendItems, setLegendItems] = useState(config.legend());

	const dispatch = useDispatch();
	const [reportList, setReportList] = useState({});
	const [reportListLoaded, setReportListLoaded] = useState(false);
	const [packList, setPackList] = useState({});
	const [packListLoaded, setPackListLoaded] = useState(false);
	const [selectedPack, setSelectedPack] = useState(false);


	const isClickOnTalasmart = React.useRef(false);
	const onUnmount = React.useRef();

	onUnmount.current = () => {
		if (!isClickOnTalasmart) {
			dispatch(setSelectedItem(null));
		}
	};

	useEffect(() => {
		return () => onUnmount.current();
	}, []);

	const itemUpdate = (_el, compliance, item) => {
		if ('compliant' === compliance) item.showCompliant = !item.showCompliant;
		else if ('noncompliant' === compliance)
			item.showNonCompliant = !item.showNonCompliant;

		const newItems = [];
		const nTypes = {};
		for (const curItem of legendItems) {
			nTypes[curItem.label] = {};
			if (curItem.label === item.label) {
				newItems.push(item);
				nTypes[curItem.label].showCompliant = item.showCompliant;
				nTypes[curItem.label].showNonCompliant = item.showNonCompliant;
			} else {
				newItems.push(curItem);
				nTypes[curItem.label].showCompliant = curItem.showCompliant;
				nTypes[curItem.label].showNonCompliant = curItem.showNonCompliant;
			}
		}

		setLegendItems(newItems);
		setNodeShow(nTypes);
	};

	const showHideAll = (showHide, compliance) => {
		const newItems = [];
		const nTypes = {};

		for (const curItem of legendItems) {
			nTypes[curItem.label] = {};
			if ('compliant' === compliance)
				curItem.showCompliant = 'show' === showHide ? true : false;
			else if ('noncompliant' === compliance)
				curItem.showNonCompliant = 'show' === showHide ? true : false;

			newItems.push(curItem);
			nTypes[curItem.label].showCompliant = curItem.showCompliant;
			nTypes[curItem.label].showNonCompliant = curItem.showNonCompliant;
		}
		setLegendItems(newItems);
		setNodeShow(nTypes);
	};

	const changeReport = useCallback(
		reportInfo => {
			setReport(reportInfo);
		},
		[setReport]
	);

	const changePack = useCallback(
		packname => {
			setSelectedPack(packname);
			setReportList(packList[packname]);
			setReportListLoaded(true);
		},
		[packList]
	);

	const fetchReportList = useCallback(() => {
		const client = new EvaluationApiClient();

		client.history(results => {
			const packs = {};
			for (const reportitem of results) {
				if (!packs[reportitem['ConformancePack']]) {
					packs[reportitem['ConformancePack']] = [];
				}
				packs[reportitem['ConformancePack']].push(reportitem);
			}
			const formatPacks = {
				...packs,
				RRA: reports,
			};

			setPackList(formatPacks);
			setPackListLoaded(true);

			if (selectedItem) {
				setSelectedPack(selectedItem.report?.ConformancePack);
				setReportList(formatPacks[selectedItem.report?.ConformancePack]);
				setReportListLoaded(true);
				setReport(selectedItem.report);
				setSelectedNode(selectedItem.data);
				setShowPopup(true);
			}
		});
	}, [reports, selectedItem]);

	useEffect(() => {
		fetchReportList();
	}, [fetchReportList]);


	return (
		<>
			{!data.loaded && !data.error && <Loader className="h-25" />}
			{data.error && (
				<Row>
					<ErrorInfo />
				</Row>
			)}
			{data.loaded && (
				<Row
					style={{ position: 'relative', flexGrow: 1 }}
					className="p-0 m-0 w-100 topology-wrap ">
					<Col className="p-0 pr-md-2 ">
						<Legend
							legendItems={legendItems}
							itemUpdate={itemUpdate}
							showHideAll={showHideAll}
							reportComponent={
								<div className="d-flex flex-wrap mb-2">
									{packListLoaded && (
										<>
											<div className="my-3-step p-1 w-100">
												<SelectPack
													packs={packList}
													changepack={changePack}></SelectPack>
											</div>
											{reportListLoaded && (
												<div className="my-4-step p-1 w-100">
													<SelectReport
														packName={selectedPack}
														reports={reportList}
														changeReport={changeReport}
													/>
												</div>
											)}
										</>
									)}
								</div>
							}
						/>
					</Col>
					<Col className="position-relative">

						<NetworkTopologyAndResource
							nodes={data.nodes}
							loading={loading}
							info={{
								title: 'Network Topology - Cloud Environment',
								date: report?.RunDateTime,
							}}
							showModal={onShowPopup}
							refreshTopology={fetchData}
							showReviewButton={report ? true : false}
							report={{ report: report, reviewUrl: getReviewUrl(report) }}
						/>
					</Col>
					{showPopup && (
						<Col lg="4" className="p-lg-0 pt-lg-0 pt-4 topology-info-modal">
							<InfoModal
								data={selectedNode}
								onClose={onMouseOut}
								report={report}
								handleRedirect={() => (isClickOnTalasmart.current = true)}
							/>
						</Col>
					)}
				</Row>
			)}
		</>
	);
};

export default DashboardTopology;
