import { t } from 'i18next';
import React from 'react';

export default function () {
	return (
		<div>
			<div>
				<h4>{t('testReports.CMMS')}</h4>
			</div>

			<div>
				<p className="font-weight-normal">
					{t('testReports.CMMSdescription1')}
				</p>
				<p className="font-weight-normal">
					{t('testReports.CMMSdescription2')}
				</p>
				<p className="font-weight-normal">
					{t('testReports.CMMSdescription3')}
				</p>
				<p className="font-weight-normal">
					{t('testReports.briefDescription')}
					<br></br>
					{t('testReports.level1')}
					<a href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-171r1.pdf">
						NIST 800-171 rev1.
					</a>
				</p>
				<p className="font-weight-normal">{t('testReports.level2')}</p>
				<p className="font-weight-normal">{t('testReports.level3')}</p>
				<p className="font-weight-normal">
					{t('testReports.level4first')}
					<a href="https://nvlpubs.nist.gov/nistpubs/SpecialPublications/NIST.SP.800-171r2.pdf">
						NIST 800-171 Rev2
					</a>{' '}
					{t('testReports.level4second')}
				</p>
				<p className="font-weight-normal">{t('testReports.leven5')}</p>
				<p className="font-weight-normal">
					{t('testReports.achieveEachLevel')}{' '}
				</p>
			</div>

			<div>
				<p className="font-weight-normal">
					{t('testReports.capabilityDomains')}
				</p>
				<p>
					<ul>
						<li className="font-weight-normal">
							{t('testReports.accessControl')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.incidentResponse')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.riskManagement')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.assetManagement')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.maintenance')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.securityAssessment')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.awarenessAndTraining')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.mediaProtection')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.situationalAwareness')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.auditAndAccountability')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.personnelSecurity')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.systemAndCommunicationsProtection')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.configurationManagement')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.physicalProtection')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.systemAndInformationIntegrity')}
						</li>
						<li className="font-weight-normal">
							{t('testReports.identificationAndAuthentication')}
						</li>
						<li className="font-weight-normal">{t('testReports.recovery')}</li>
					</ul>
				</p>
			</div>
		</div>
	);
}
