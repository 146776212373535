import React, { useState } from 'react';
import {
	Table,
	Dropdown,
	DropdownItem,
	DropdownToggle,
	DropdownMenu,
	Row,
	Col,
	Button,
} from 'reactstrap';

import { flexRender } from '@tanstack/react-table';
import { ReactComponent as ChevronSvg } from '../assets/img/chevron.svg';

const PageButtons = ({ table }) => {
	const curPage = table.getState().pagination.pageIndex + 1;
	const maxPage = table.getPageCount();
	return (
		<>
			<Button
				onClick={() => table.firstPage()}
				disabled={!table.getCanPreviousPage()}>
				{'<<'}
			</Button>
			<Button
				onClick={() => table.previousPage()}
				disabled={!table.getCanPreviousPage()}>
				{'<'}
			</Button>
			<span>
				{' '}
				Page: {curPage} / {maxPage}{' '}
			</span>
			<Button
				onClick={() => table.nextPage()}
				disabled={!table.getCanNextPage()}>
				{'>'}
			</Button>
			<Button
				onClick={() => table.lastPage()}
				disabled={!table.getCanNextPage()}>
				{'>>'}
			</Button>
		</>
	);
};

const BTable = ({ table, pageSizes }) => {
	const [pageLen, setPageLen] = useState(pageSizes[0]);
	const [pageLenOpen, setPageLenOpen] = useState(false);
	const togglePageLen = () => setPageLenOpen(!pageLenOpen);

	const setPageSize = val => {
		/*
        setPagination({
            pageSize: Number(val),
            pageIndex: 0
        })
        */
		setPageLen(val);
		table.setPageSize(Number(val));
	};

	//console.log('table next:', table.nextPage);

	return (
		<>
			<Table>
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<th key={header.id} colSpan={header.colSpan}>
									{header.isPlaceholder ? null : (
										<div
											className={
												header.column.getCanSort()
													? 'cursor-pointer select-none'
													: ''
											}
											onClick={header.column.getToggleSortingHandler()}
											title={
												header.column.getCanSort()
													? header.column.getNextSortingOrder() === 'asc'
														? 'Sort ascending'
														: header.column.getNextSortingOrder() === 'desc'
														? 'Sort descending'
														: 'Clear sort'
													: undefined
											}>
											{flexRender(
												header.column.columnDef.header,
												header.getContext()
											)}
											{{
												asc: ' 🔼',
												desc: ' 🔽',
											}[header.column.getIsSorted()] ?? null}
										</div>
									)}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => (
						<tr key={row.id}>
							{row.getVisibleCells().map(cell => (
								<td key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</Table>
			<Row>
				<Col xs="4">
					<Dropdown
						isOpen={pageLenOpen}
						toggle={togglePageLen}
						className="position-relative">
						<DropdownToggle size="sm">
							<span style={{ margin: '5px' }}>
								{pageSizes.find(option => option === pageLen) ? pageLen : '-'}
							</span>
							<ChevronSvg className="dropdown-arrow" />
						</DropdownToggle>
						<DropdownMenu>
							{pageSizes.map(val => {
								return (
									<DropdownItem key={val} onClick={() => setPageSize(val)}>
										{val}
									</DropdownItem>
								);
							})}
						</DropdownMenu>
					</Dropdown>
				</Col>
				<Col xs="4"></Col>
				<Col xs="4">
					<PageButtons table={table} />
				</Col>
			</Row>
		</>
	);
};

export { BTable };
