import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentUserKey, getUserToken } from '../services/AuthService';
import {
	setIsAuthenticated,
	setUser,
} from '../redux/actions/applicationActions';
import { getUserDefaults } from '../apiclients/EmailLogin';
import { getIsAuthenticated } from '../redux/utils';
import { getItem } from '../services/browserStorageService';
import { setAccountLimits } from '../services/AuthService';

const useAuthenticated = () => {
	const isAuthenticated = useSelector(getIsAuthenticated);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onDefaultKeyFailure = useCallback(() => {
		dispatch(setUser('no-key'));
	}, [dispatch]);

	const onDefaultKeySuccess = res => {
		const defaultKey = res.userInfo?.defaultKey;
		if(res && res.accountInfo)
		{
			setAccountLimits(res.accountInfo);
		}
		if (defaultKey) {
			dispatch(setUser(defaultKey));
		} else {
			const userKey = getCurrentUserKey();
			const user = getItem(userKey);
			if(user && user.authHeaders && user.authHeaders.token && user.authHeaders.keyid && user.authHeaders.Region) {
				dispatch(setUser({
					"keyId" : user.authHeaders.keyid,
					"region" : user.authHeaders.Region
				}));
				return;
			}
			onDefaultKeyFailure();
		}
	};

	const onGetDefaults = async token => {
		dispatch(setIsAuthenticated(true));
		console.log('onGetDefaults');
		getUserDefaults(token, onDefaultKeySuccess, onDefaultKeyFailure);
	};

	const checkAuthenticated = () => {
		if (isAuthenticated !== null) return;

		const token = getUserToken();

		if (token) {
			onGetDefaults(token);
		} else {
			dispatch(setIsAuthenticated(false));
			navigate('/auth/sign-in');
		}
	};

	return { checkAuthenticated };
};

export default useAuthenticated;
