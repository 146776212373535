//todo: this needs to be removed

import 'react-confirm-alert/src/react-confirm-alert.css';

import { Card, CardBody, CardHeader, CardTitle, Progress } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import {
	deletePackDeployment,
	getDeploymentProgress,
	startPackDeployment,
} from '../../apiclients/Account';
import {
	getUser,
	getKeys,
	isOrgAccount,
	logout,
} from '../../services/AuthService';

import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

//todo: this needs to be removed

const BEGIN = 0,
	PRECHECK = 1,
	DEPLOY = 2,
	CHECK = 3,
	DONE = 4;

const defaultErrorMsg = t('setup.defaultErrorMsg');

const errorRedirect = navigate => {
	setTimeout(() => {
		console.log('going to redirect??');
		const userkeys = getKeys();
		navigate(
			'/admin/remote-landing?failedKeyId=' + (userkeys.keyid || 'unknown')
		);
	}, 5000);
};

const accountType = isOrgAccount() ? t('setup.organization') + ' ' : '';
const warningMsg = t('setup.warning');

const progressData = {
	[BEGIN]: {
		msg: t('setup.begin', { accountType, warningMsg }),
		delay: 10,
		errorMsg: '',
		percent: 5,
	},
	[PRECHECK]: {
		msg: t('setup.precheck', { accountType, warningMsg }),
		delay: 60,
		errorMsg: defaultErrorMsg,
		percent: 25,
	},
	[DEPLOY]: {
		msg: t('setup.deploy', { warningMsg }),
		delay: 30,
		errorMsg: defaultErrorMsg,
		percent: 50,
	},
	[CHECK]: {
		msg: t('setup.check', { warningMsg }),
		delay: 120,
		errorMsg: defaultErrorMsg,
		percent: 75,
	},
	[DONE]: { msg: t('setup.done'), delay: 10, errorMsg: '', percent: 95 },
};

const DeployProgress = ({ progress, error, missingPacks }) => {
	if (progress >= progressData.length) {
		progress = progressData.length - 1;
	}

	const [color, setColor] = useState('primary');

	useEffect(() => {
		setColor(error ? 'danger' : 'primary');
	}, [error]);

	return (
		<div>
			<p>
				<em title={missingPacks}>{error && progressData[progress].errorMsg}</em>
				<em>{!error && progressData[progress].msg}</em>
			</p>
			<div className="w-50">
				<Progress
					animated
					value={progressData[progress].percent}
					color={color}
				/>
			</div>
		</div>
	);
};

const deployStatus = {
	inProgress: 0,
	complete: 1,
	failed: 2,
};

const getDelay = progress => progressData[progress].delay * 1000;

const getDeployStatusOrg = r => {
	let total = 0;
	let complete = 0;
	let error = 0;

	for (let i = 0; i < r.length; i++) {
		const key = Object.keys(r[i])[0];
		const accounts = r[i][key];

		for (let i = 0; i < accounts.length; i++) {
			total++;
			const accountkey = Object.keys(accounts[i])[0];
			const value = accounts[i][accountkey];

			if (value === 'CREATE_SUCCESSFUL' || value === 'DELETE_SUCCESSFUL')
				complete++;
			if (value === 'CREATE_FAILED' || value === 'DELETE_FAILED') error++;
		}
	}

	return complete + error < total
		? deployStatus.inProgress
		: error === 0
		? deployStatus.complete
		: deployStatus.failed;
};

const getDeployStatus = r => {
	let complete = 0;
	let error = false;

	const keys = Object.keys(r);

	for (let i = 0; i < keys.length; i++) {
		const value = r[keys[i]];
		if (value === 'CREATE_COMPLETE' || value === 'DELETE_COMPLETE') complete++;
		if (value === 'CREATE_FAILED' || value === 'DELETE_FAILED') error = true;
	}

	if (error) return deployStatus.failed;

	return complete === keys.length
		? deployStatus.complete
		: deployStatus.inProgress;
};

let deploymentProgressTimeout;

const deploymentProgressError = (setters, e) => {
	console.log('checkDeploymentProgress error', e);
	clearTimeout(deploymentProgressTimeout);
	setters.setProgress(CHECK);
	setters.setError(true);
	errorRedirect(setters.navigate);
};

const redirectToDashboard = setters => {
	confirmAlert({
		customUI: ({ onClose }) => {
			return (
				<div class="modal-dialog" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">{t('setup.credentialCheck')}</h5>
							<button
								type="button"
								class="close"
								data-dismiss="modal"
								aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<p>{t('setup.successDeploy')}</p>
							<p>{t('setup.continueCurrentCredentials')}</p>
							<p></p>
							<small>{t('setup.credentialPrivileged')}</small>
						</div>
						<div class="modal-footer">
							<button
								title={'Proceed to dashboard with current credentials'}
								className="btn btn-secondary"
								onClick={() => {
									setters.navigate('/Dashboard');
									onClose();
								}}>
								{t('setup.currentCredentials')}
							</button>
							<button
								title={'Logout and login with typical auditor credentials'}
								className="btn btn-primary"
								data-dismiss="modal"
								onClick={() => {
									logout(() => {
										setters.navigate('/auth/sign-in');
										onClose();
									});
								}}>
								{t('setup.auditorCredentials')}
							</button>
						</div>
					</div>
				</div>
			);
		},
	});
};

const checkDeploymentProgress = (packName, progress, setters) =>
	getDeploymentProgress(
		packName,
		r => {
			console.log('checkDeploymentProgress success', r);

			if (r === 'No Conformance Pack found') {
				deploymentProgressError(setters, 'No Conformance Pack found');
			}

			const status = isOrgAccount()
				? getDeployStatusOrg(r)
				: getDeployStatus(r);

			if (status === deployStatus.inProgress) {
				deploymentProgressTimeout = setTimeout(
					() => checkDeploymentProgress(packName, progress, setters),
					getDelay(progress)
				);
			} else if (status === deployStatus.complete) {
				clearTimeout(deploymentProgressTimeout);
				setters.setProgress(DONE);
				setters.navigate('/Dashboard');
			} else if (status === deployStatus.failed) {
				if (isOrgAccount()) {
					clearTimeout(deploymentProgressTimeout);
					setters.setProgress(DONE);
					redirectToDashboard(setters);
				} else {
					deploymentProgressError(setters, 'Failed');
					errorRedirect(setters.navigate);
				}
			}
		},
		e => {
			deploymentProgressError(setters, 'checkDeploymentProgress error');
		}
	);

const checkPreviousDeploy = (next, setters) =>
	getDeploymentProgress(
		'ALL',
		r => {
			const status = isOrgAccount()
				? getDeployStatusOrg(r)
				: getDeployStatus(r);
			console.log('checkPreviousDeploy success:', status);
			if (status === deployStatus.inProgress) {
				setTimeout(
					() => checkPreviousDeploy(next, setters),
					getDelay(PRECHECK)
				);
			} else if (status === deployStatus.failed) {
				console.log('checkPreviousDeploy failed:', status);
				if (isOrgAccount()) {
					next(); // ignoring multi account failures before installations
				} else {
					setters.setError(true);
					errorRedirect(setters.navigate);
				}
			} else if (next) {
				next();
			}
		},
		e => {
			console.log('checkPreviousDeploy error:', e, next, setters);
			if (next) {
				next();
			} else {
				setters.setError(true);
				errorRedirect(setters.navigate);
			}
		}
	);

const deletePreviousDeploy = (next, setters) =>
	deletePackDeployment(
		'ALL',
		r => {
			console.log('deletePreviousDeploy success');
			setTimeout(() => checkPreviousDeploy(next, setters), getDelay(PRECHECK));
		},
		e => {
			console.log('deletePreviousDeploy error:', e);
			if (isOrgAccount()) {
				setTimeout(() => next(), getDelay(PRECHECK));
			} else {
				setters.setError(true);
			}
		}
	);

const startDeploy = (packName, progress, setters) =>
	startPackDeployment(
		packName,
		r => {
			console.log('startDeploy success', r, progress);
			setters.setProgress(CHECK);
			setTimeout(
				() => checkDeploymentProgress(packName, CHECK, setters),
				getDelay(DEPLOY)
			);
		},
		e => {
			console.log('startDeploy error', e, progress);
			setters.setProgress(DEPLOY);
			setters.setError(true);
			errorRedirect(setters.navigate);
		}
	);

const begin = (packName, progress, setters) => {
	const setUpWorkflow = () => {
		setters.setProgress(DEPLOY);
		startDeploy('ALL', progress, setters);
	};
	const cleanWorkflow = () => {
		deletePreviousDeploy(setUpWorkflow, setters);
	};

	setters.setProgress(PRECHECK);
	checkPreviousDeploy(cleanWorkflow, setters);
};

export default function (props) {
	const user = getUser(),
		packName = user.MissingPack, // "[HIPAA, PCI, NISTCSF, SOC2]"
		setup = user.Setup,
		[progress, setProgress] = useState(BEGIN),
		[error, setError] = useState(false),
		navigate = useNavigate();

	if (!setup) {
		console.log('account setup not needed');
		navigate('../../Dashboard');
	}

	useEffect(() => {
		if (setup) {
			deploymentProgressTimeout = setTimeout(
				() => begin(packName, progress, { setProgress, setError, navigate }),
				getDelay(BEGIN)
			);
		}

		return () => {
			clearTimeout(deploymentProgressTimeout);
		};
	}, []);

	return (
		<div>
			{setup && (
				<Card>
					<CardHeader>
						<CardTitle>
							<h3>{t('setup.welcome')}</h3>
						</CardTitle>
					</CardHeader>
					<CardBody>
						<p>{t('setup.wait')}</p>
						<DeployProgress
							missingPacks={packName}
							progress={progress}
							error={error}
						/>
					</CardBody>
				</Card>
			)}
		</div>
	);
}
