import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Trans } from 'react-i18next';

import { convertToInternationalCurrencySystem } from '../Common/Formatter';
import defaultOptions from '../Common/StackedAreaChartOptions';
import { getDataPoint } from './Helpers';

const options = { ...defaultOptions };

options.tooltip.custom = function ({ seriesIndex, dataPointIndex, w }) {
	const dp = getDataPoint({ seriesIndex, dataPointIndex, w });
	const loss = convertToInternationalCurrencySystem(Number(dp.potentialLoss));

	const elem = (
		<div className="arrow_box">
			<div>
				<div>
					<Trans i18nKey="table.potentialLoss" /> ($): {loss}
				</div>
				<div>
					<Trans i18nKey="table.riskScore" /> : {dp.risk}%
				</div>
				<div>
					<Trans i18nKey="table.vulnerableIssues" /> (#): {dp.issues}
				</div>
				<div>
					<Trans i18nKey="table.vulnerableResources" /> : {dp.resources}
				</div>
			</div>
		</div>
	);

	return ReactDOMServer.renderToString(elem);
};

options.markers = {
	stroke: 0,
	strokeOpacity: 0,
};

options.chart.zoom = {
	enabled: false,
};

const useTrendLoss = data => {
	const getOther = index => {
		let i = 0;

		const getData = di => data.datasets[di].data[index];

		return {
			loss: getData(i++),
			risk: getData(i++),
			issues: getData(i++),
			resources: getData(i++),
			potentialLoss: data.potentialLossFull[index],
		};
	};

	const pointFn = (value, index) => ({
		x: data.labels[index],
		y: value,
		...getOther(index),
	});

	const mapFn = item => {
		const points = item.data.map(pointFn);

		return {
			type: 'area',
			name: item.label,
			data: points,
		};
	};

	const series = [];

	data.datasets.forEach(item => {
		series.push(mapFn(item));
	});

	return {
		options,
		series,
	};
};

export default useTrendLoss;
