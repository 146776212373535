import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Info } from 'react-feather';
import useRiskResult from '../../../../src/pages/ransomware/Hooks/useRiskResult';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { convertToInternationalCurrencySystem } from '../Common/Formatter';
import LossChange from '../assessment/Controls/ReviewScore/LossChange';
import RiskChange from '../assessment/Controls/ReviewScore/RiskChange';

const useRiskScore = score => {
	const val = Number(score);
	if (val > 80) {
		return 'text-danger';
	} else if (val > 40) {
		return 'text-warning';
	} else return 'text-info';
};

const RiskScore = ({
	score,
	previous,
	showChangeBubbles,
	info,
	onInfoClick = () => { },
}) => {
	const textClass = useRiskScore(score);
	const { t } = useTranslation();
	const positive = previous && previous > score;
	const result = useRiskResult();

	const riskLabel =
		score < 40
			? 'ransomwareDashboard.low'
			: score < 70
				? 'ransomwareDashboard.medium'
				: 'ransomwareDashboard.high';

	return (
		<Card className="dashboard-card">
			{showChangeBubbles && (
				<RiskChange
					previous={result.previousScore}
					current={result.currentScore}
				/>
			)}
			<div className='d-flex flex-column justify-content-between'>

				<CardHeader>
					<CardTitle className="d-flex justify-content-between align-items-center">
						<h5 className="text-uppercase mb-0">
							{t('ransomwareDashboard.riskScore')}
						</h5>
						{info && (
							<button
								className="bg-transparent p-0 border-0 text-gray"
								onClick={onInfoClick}>
								<Info />
							</button>
						)}
					</CardTitle>
				</CardHeader>



				<CardBody>
					<h1 className={`${textClass}`}>
						{score}%{' '}
						{previous !== score ? (
							<FontAwesomeIcon
								className="pt-1"
								icon={positive ? faArrowDown : faArrowUp}
							/>
						) : (
							''
						)}
					</h1>
					<h1 className={`${textClass}`}>
						{t(riskLabel)} {t('ransomwareDashboard.risk')}
					</h1>

				</CardBody>


				<CardBody >
					<p >{t('ransomwareDashboard.basedOnVulnerabilities')}</p>
				</CardBody>
			</div>
		</Card>
	);
};

const PotentialLoss = ({
	value,
	previous,
	current,
	info,
	showChangeBubbles,
	onInfoClick = () => { },
}) => {
	const latest = current || value;
	const positive = previous && previous.max > latest.max;
	const { t } = useTranslation();

	const textClass = 'text-danger';

	const formattedMin = convertToInternationalCurrencySystem(value.min);
	const formattedMax = convertToInternationalCurrencySystem(value.max);
	const result = useRiskResult();

	return (
		<Card className="dashboard-card">
			{showChangeBubbles && (
				<LossChange previous={result.previousLoss} current={result.loss} />
			)}

			<div className='d-flex flex-column justify-content-between'>
				<CardHeader>
					<CardTitle className="d-flex justify-content-between align-items-center">
						<h5 className="text-uppercase mb-0">
							{t('ransomwareDashboard.potentialLoss')}
						</h5>
						{info && (
							<button
								className="bg-transparent p-0 border-0 text-gray"
								onClick={onInfoClick}>
								<Info />
							</button>
						)}
					</CardTitle>
				</CardHeader>
				<CardBody>
					<h1
						className={`${textClass}`}
						title={`[${value.min}] - [${value.max}]`}>
						${formattedMin} - ${formattedMax}
						{previous && previous.max !== latest.max && (
							<FontAwesomeIcon
								className="pt-1"
								icon={positive ? faArrowDown : faArrowUp}
							/>
						)}
					</h1>
					<h1 className={`${textClass}`}>
						<br></br>
					</h1>
				</CardBody>


				<CardBody className='my-1added-step'>
					<p >{t('ransomwareDashboard.basedOnVulnerabilities')}</p>
				</CardBody>

			</div>


		</Card>
	);
};

export { RiskScore, PotentialLoss };
