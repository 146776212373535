import React from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import Next from '../assets/img/Next.svg';
import FailIcon from '../assets/img/redFailIcon.svg';
import Close from '../assets/img/Button (Close).svg';

const DemoPopup = ({ isOpen, setDemoPopup, title, subtitle }) => {
	const { t } = useTranslation();

	const goToPurchasePlan = () => {
		window.location.href = process.env.REACT_APP_PURCHASE_LINK;
	};

	const closeModalPopup = () => {
		setDemoPopup(false);
	};

	return (
		<Modal isOpen={isOpen} toggle={closeModalPopup} centered={true}>
			<div className="d-flex flex-column align-items-center justify-content-center p-5">
				<img
					src={Close}
					onClick={closeModalPopup}
					className="auth-alert-popup-closeBtn"
				/>
				<img src={FailIcon} alt="failIcon" className="mb-3" />
				<h5 className="auth-alert-popup-title m-0">{title}</h5>
				{!!subtitle ? (
					<p className="auth-alert-popup-subtitle">{subtitle}</p>
				) : (
					<p className="auth-alert-popup-subtitle">
						{t('liveDemo.purchasePlan')}
					</p>
				)}
				<button onClick={goToPurchasePlan} className="auth-alert-popup-btn">
					{t('liveDemo.purchasePlanBtn')} <img src={Next} alt="nextArrow" />
				</button>
			</div>
		</Modal>
	);
};

export default DemoPopup;
