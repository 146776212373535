import { CloudTypes } from '../../../constants/CloudInfo';
import React from 'react';
import TopologyConfig from '../Config';
import cytoscape from 'cytoscape';
import fcose from 'cytoscape-fcose';
import cytoscapePopper from 'cytoscape-popper';
import nodeHtmlLabel from '@layer5/cytoscape-node-html-label';

import { getUserType } from '../../../services/AuthService';
import tippy from 'tippy.js';

nodeHtmlLabel(cytoscape);

//https://github.com/cytoscape/cytoscape.js/issues/1468

//layout options: https://www.npmjs.com/package/cytoscape-fcose

const config = new TopologyConfig();

const layout = {
	name: 'fcose',
	nodeRepulsion: 5500,
	nestingFactor: 0.8,
	fit: true,
	randomize: true,
	// quality: 'proof',
	animationDuration: 500,
	//padding: 30,
	// False for random, true for greedy sampling
	samplingType: true,
	// Sample size to construct distance matrix
	sampleSize: 125,
	// Separation amount between nodes
	nodeSeparation: 100,
	// Power iteration tolerance
	piTol: 0.0000001,
	// Gravity force (constant)
	gravity: 0.25,
	// Gravity range (constant) for compounds
	gravityRangeCompound: 1.5,
	// Gravity force (constant) for compounds
	gravityCompound: 1.0,
	// Gravity range (constant)
	gravityRange: 3.8,
	// Initial cooling factor for incremental layout
	initialEnergyOnIncremental: 0.3,
};

const style = [
	{
		selector: 'node',
		style: {
			'background-color': ele => config.get(ele).image.colour,
			'background-fill': 'solid',
			shape: ele => config.get(ele).image.shape,
			'background-image': ele => config.get(ele).image.image,
			'background-width': '100%',
			'background-height': '100%',
			height: ele => config.get(ele).image.dim.height,
			width: ele => config.get(ele).image.dim.width,
			'z-index': ele => config.zIndex(ele),
			padding: '2%',
		},
	},
];

const createElements = originalNodes => {
	let edges = [],
		nodes = [],
		index = 0;

	const findParent = id =>
		originalNodes.find(n => n.data.id === id || n.data.name === id);
	//console.log("createElements", originalNodes);
	originalNodes.forEach(originalNode => {
		if (originalNode.data.parent && originalNode.data.id) {
			const parent = findParent(originalNode.data.parent);
			if (parent) {
				var edge = {
					id: `edge_${index}`,
					source: parent.data.id,
					target: originalNode.data.id,
				};
				edges.push({ group: 'edges', data: edge });
			}
			index++;
		}

		/*
			this deep clone of the parent object and .data is needed since
			setting the .parent on the data property to undefined removes
			parent-child relationships.

			this change is needed to support the popup feature on the same page.
		 */
		let node = { ...originalNode };

		node.group = 'nodes';

		node.data = { ...node.data };

		//setting this to undefined ensures the outer shapes aren't drawn.
		//this is also why the nodes are being copied from originalNodes
		node.data.parent = undefined;

		nodes.push(node);
	});

	return { nodes, edges };
};

const makePopper = ele => {
	let ref = ele.popperRef();

	ele.tippy = tippy(document.createElement('div'), {
		getReferenceClientRect: ref.getBoundingClientRect,
		trigger: 'manual',
		duration: [0, 0],
		content: () => {
			let content = document.createElement('div');

			const data = ele.data();

			const innerHtml = `
			<p>
				Resource type: ${data?.resourcetype || ''}
			</p>
			<p>
				Resource id: ${data?.id || ''}
			</p>
			<p>
				Resource name: ${data?.name || ''}
			</p>
			<p>
				Issues: ${
					data?.['issues']?.length || data?.['non-compliant-rules']?.length || 0
				}
			</p>
			`;
			content.innerHTML = innerHtml;

			return content;
		},
	});
};

function popperFactory(ref, content, opts) {
	// TODO
}

class TopologyControl extends React.Component {
	constructor(props) {
		super(props);
		this.getChartDims = this.getChartDims.bind(this);
		this.resetLayout = this.resetLayout.bind(this);
		this.setImage = this.setImage.bind(this);
	}

	async componentDidMount() {
		const options = {
			wheelSensitivity: 0.1,
			container: document.getElementById('network-topology'),
			elements: createElements(this.props.nodes),
			layout,
			style,
		};

		cytoscape.use(fcose);
		cytoscape.use(cytoscapePopper(popperFactory));

		try {
			let cy = cytoscape(options);
			if (getUserType() === CloudTypes.Azure) {
				cy.maxZoom(1);
				// todo: hack to keep topology zoomed to a reasonable max
			}
			this.cy = cy;

			cy.nodeHtmlLabel([
				{
					query: 'node',
					halign: 'right',
					valign: 'top',
					cssClass: '',
					tpl(data) {
						const nonCompliant = config.isNonCompliant(data);

						if (nonCompliant) {
							return `<svg width="90" height="90" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<rect x="0.65" y="0.65" width="16.7" height="16.7" rx="8.35" fill="white"/>
							<rect x="0.65" y="0.65" width="16.7" height="16.7" rx="8.35" stroke="#FF7676" stroke-width="1.3"/>
							<path d="M10.1703 10.5645H7.87912V3.5H10.1703V10.5645ZM10.0549 12C10.3516 12.2796 10.5 12.6237 10.5 13.0323C10.5 13.4409 10.3516 13.7903 10.0549 14.0806C9.76923 14.3602 9.41758 14.5 9 14.5C8.58242 14.5 8.22527 14.3602 7.92857 14.0806C7.64286 13.7903 7.5 13.4409 7.5 13.0323C7.5 12.6237 7.64286 12.2796 7.92857 12C8.22527 11.7097 8.58242 11.5645 9 11.5645C9.41758 11.5645 9.76923 11.7097 10.0549 12Z" fill="#E72538"/>
							</svg>
							`;
						}
						return '<span />';
					},
				},
			]);

			//https://stackoverflow.com/questions/54547927/show-and-hide-node-info-on-mouseover-in-cytoscape
			let clickFn = this.props.onMouseOver;

			cy.elements().on('click', function (evt) {
				clickFn(evt);
			});

			cy.ready(function () {
				cy.elements().forEach(function (ele) {
					makePopper(ele);
				});
			});

			cy.elements().unbind('mouseover');
			cy.elements().bind('mouseover', event => event.target.tippy.show());

			cy.elements().unbind('mouseout');
			cy.elements().bind('mouseout', event => event.target.tippy.hide());
		} catch (error) {
			console.log(error);
			if (this.props.onRenderError) this.props.onRenderError(error);
		}
	}

	componentWillUnmount() {
		if (this.cy) {
			this.cy.destroy();
		}
	}

	setImage() {
		if (this.cy) {
			const imageElement = document.getElementById(this.props.imageSelector);
			const dims = this.getChartDims();

			const opts = {
				bg: false,
				full: true,
				maxWidth: dims.width,
				maxHeight: dims.height,
			};

			const png64 = this.cy.png(opts);

			imageElement.setAttribute('src', png64);
		}
	}

	resetLayout() {
		if (this.cy) {
			this.cy.layout(layout).run();
			this.cy.fit();
		}
	}
	updateNodes(newnodes) {
		if (this.cy) {
			// console.log('updateNodes', newnodes);
			this.cy.elements().remove();
			if (newnodes.length) {
				this.cy.add(createElements(newnodes));
				this.cy.maxZoom(1);

				this.cy.layout(layout).run();
				this.cy.fit();

				this.cy.elements().forEach(function (ele) {
					makePopper(ele);
				});

				let clickFn = this.props.onMouseOver;

				this.cy.elements().on('click', function (evt) {
					clickFn(evt);
				});

				this.cy.elements().unbind('mouseover');
				this.cy
					.elements()
					.bind('mouseover', event => event.target.tippy.show());

				this.cy.elements().unbind('mouseout');
				this.cy.elements().bind('mouseout', event => event.target.tippy.hide());
			} else {
				console.log('no new nodes');
			}
		} else {
			console.log('updateNodes but no this.cy');
		}
	}

	getChartDims() {
		return {
			height: this.props.chartDim.h,
			width: this.props.chartDim.w,
		};
	}

	render() {
		const chartStyle = {
			height: `${this.props.chartDim.h}px`,
			width: '100%',

			// width: `${this.props.chartDim.w}px`,
		};

		return (
			<div className="w-100 d-flex">
				<div
					id="network-topology"
					className="bg-white rounded"
					style={chartStyle}></div>
			</div>
		);
	}
}

const MemoizedTopologyControl = React.memo(TopologyControl);

export default MemoizedTopologyControl;
