import { failure, getAuthHeaders, getApiHostUri } from "./Common";

import { getUserType } from "../services/AuthService";

const getUrl = path => `${getApiHostUri()}/pentest/${path}`;

const getPenTestHeaders = (headers) => {
    const headerNames = [{},
    { AccessKey: 'AccessKey', SecretKey: 'SecretKey', Account: 'Account', SubscriptionId: 'Region' },
    { AccessKey: 'AppId', SecretKey: 'Password', Account: 'TenantId', SubscriptionId: 'SubscriptionId' },
    {},
    ];
    const cloudHeaderNames = headerNames[getUserType()];

    const requestHeaders = { ...headers };
    const currentAuthHeaders = getAuthHeaders();

    Object.keys(cloudHeaderNames).map(key => {
        requestHeaders[key] = currentAuthHeaders[cloudHeaderNames[key]];
    });

    return requestHeaders;
}

const scan = async (scanUrl, reportId) => {
    const requestOptions = {
        method: 'POST',
        headers: getPenTestHeaders({
            "Content-Type": "application/json",
            "ReportId": reportId
        }),
        body: JSON.stringify(scanUrl)
    };

    const url = getUrl("pentest-scan");
    let response = await fetch(url, requestOptions);
    return response.ok ? await response.json() : { error: true, code: await response.json() };
}

const results = async reportId => {
    const requestOptions = {
        method: 'GET',
        headers: getPenTestHeaders({
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            'ReportUUID': reportId,
            'FragmentId': 1
        }),
    };

    const retryAfter = response => {

        const
            headerName = 'retry-after',
            hasHeader = response.headers.has(headerName),
            value = () => parseInt(response.headers.get(headerName));

        return hasHeader ? value() : 30; //default value because retry-after seems inaccessible via code.
    }

    try {
        const
            response = await fetch(getUrl("scan-results"), requestOptions),
            completed = response.ok && response.status === 200,
            mustRetry = response.ok && response.status === 202,
            success = {
                success: true,
                retryAfterSeconds: mustRetry ? retryAfter(response) : 0,
                html: completed ? await response.text() : '',
                mustRetry: mustRetry
            };

        return response.ok ? success : null;

    } catch (error) {
        console.log("Error occurred while trying to fetch the results html", error);
        return false;
    }
}

const history = async () => {
    const url = getUrl("scan-history");
    const requestOptions = {
        method: 'GET',
        headers: getPenTestHeaders({
            "Content-Type": "application/json"
        }),
    };

    const response = await fetch(url, requestOptions);
    return response.ok
        ? await response.json()
        : failure(response);
};

const status = async reportIds => {

    const url = getUrl("scan-status");

    const hasIds = reportIds && reportIds.length > 0;

    if (!hasIds) {
        return [];
    }

    const evalIds = reportIds.join(',');

    const requestOptions = {
        method: 'GET',
        headers: getPenTestHeaders({
            'Access-Control-Allow-Origin': '*',
            "Content-Type": "application/json",
            'EvalIds': evalIds
        }),
    };

    const response = await fetch(url, requestOptions);
    return response.ok
        ? await response.json()
        : [];
}

export { results, scan, history, status };