import React, { useState } from "react";

import {
  FormGroup
} from "reactstrap";
import SortedRegions from "../Logic/SortedRegions";

const RegionCheckbox = ({ code, name, onSelected }) => {
  const [checked, set] = useState(false);

  const onChange = e => {
    const checked = e.currentTarget.checked
    set(checked);
    onSelected(checked, code)
  }

  return (<div className="p-1">
    <input
      id={code}
      name={code}
      type="checkbox"
      checked={checked}
      onChange={onChange} />
    <label htmlFor={code} className="ms-2">	{name} - {code}</label>
  </div>);
}

export default ({ onSelected }) =>
  <>
    <div className="border border-light mt-2 overflow-auto vh-25">
      <FormGroup>
        {
          SortedRegions.map(r => <RegionCheckbox key={r.code} code={r.code} name={r.name} onSelected={onSelected} />)
        }
      </FormGroup>
    </div>
  </>;