

const getReviewUrl = report => {
	if (report.ConformancePack === 'RRA') {
		return `/reports/ransomware/${report.ReportId}`;
	} else {
		return `/print/${report.ConformancePack}/${report.ReportId}?evalId=${report.EvalId}&fragments=${report.Fragments}`;
	}
};


export { getReviewUrl };
