import React from 'react';
import { Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import TicketDropdown from './TicketDropdown';
import ServiceDropdown from './ServiceDropdown';

const info =
	('DEMO' === process.env.REACT_APP_FEAT_SHOW_ISSUE_TRACKING) ?
		{
			status: 'nottracked',
			tickets: [],
		} : {
			status: 'progress',
			tickets: [
				{
					id: '#',
					description: 'Issue 1',
				},
				{
					id: '#',
					description: 'Issue 2',
				},
			],
		};

const labels = {
	progress: <Trans i18nKey="talasmart.resource.statusInProgress" />,
	nottracked: <Trans i18nKey="talasmart.resource.statusNotTracked" />,
};

const IssueTracking = ({ onCreate = () => { } }) => {
	return ('0' !== process.env.REACT_APP_FEAT_SHOW_ISSUE_TRACKING) ? (
		<Row className='my-3-step'>
			<Col>
				<button className="issue-tracking__status">
					{labels[info.status]}
				</button>
			</Col>
			<Col>
				<TicketDropdown options={info.tickets} />
			</Col>
			<Col>
				<ServiceDropdown onChange={onCreate} />
			</Col>
		</Row>
	) : (<></>);
};

export default IssueTracking;
