import React, { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Copy, Download } from 'react-feather';

import Textarea from '../../components/Form/Textarea';
import Select from '../../components/Form/Select';
import Loader from '../../components/Loader';
import Button from '../../components/Form/Button';
import { ReactComponent as ChevronSvg } from '../../assets/img/chevron.svg';
import DashBoardError from '../ransomware/dashboard/controls/DashBoardError';
import RemediationSteps from './components/RemediationSteps';
import useTalasmartK8SLogIssue from './hooks/useTalasmartK8SLogIssue';
import ResourceList from './components/ResourceList';

import {
	REMEDIATION_STEPS,
	SEVERITY_OPTIONS,
	TALASMART_WAIT_TEXT,
} from './constants';
import IssueTracking from './components/IssueTracking';
import NotAvailablePopup from './components/NotAvailablePopup';

const TalasmartK8SLogIssue = () => {
	const { t } = useTranslation();
	const [isPopupShown, setIsPopupShown] = useState(false);

	const {
		info,
		loaded,
		error,
		results,
		pageData,
		resourceInfo,
		remediationInfo,
		resourceId,
		setRemediationInfo,
		onChangeField,
		onBlurField,
		onChangeResource,
		onCopy,
		onDownload,
		onChangeRemediationTab,
		isRemediationLoading,
	} = useTalasmartK8SLogIssue();

	const [activeRemediation, setActiveRemediation] = useState(
		REMEDIATION_STEPS.console
	);
	const [isResourceListOpen, setIsResourceListOpen] = useState(false);

	const onChangeTab = async tab => {
		setActiveRemediation(tab);

		setTimeout(async () => {
			const res = await onChangeRemediationTab(tab);
			setRemediationInfo(res);
		}, 500);
	};

	const filteredResources = useMemo(() => {
		return results.rows.filter(item => item.ResourceId !== resourceId);
	}, [resourceId, results.rows]);

	return (
		<>
			{!loaded && <Loader />}
			{loaded && error && <DashBoardError />}
			{loaded && !error && (
				<section className="d-flex gap-2 talasmart-resource pb-3">
					<Col md="6" className="d-flex flex-column gap-4 border-right px-4">
						<Row className="talasmart-resource__item justify-content-between">
							<button
								className="talasmart-resource__back"
								onClick={() => {
									window.history.back();
								}}>
								<ChevronSvg />
								{t('button.back')}
							</button>
						</Row>
						<Row className="talasmart-resource__item justify-content-between">
							<div className="d-flex gap-2">
								<h2>{t('talasmart.k8slogissue.resourcetype')}:</h2>{' '}
								<span>{info?.ResourceType}</span>
							</div>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<div className="d-flex gap-2">
								<h2>{t('talasmart.k8slogissue.resourcename')}:</h2>{' '}
								<span>{info?.ResourceName}</span>
							</div>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<div className="d-flex gap-2">
								<h2>{t('talasmart.k8slogissue.resourcenamespace')}:</h2>{' '}
								<span>{info?.ResourceNamespace}</span>
							</div>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.k8slogissue.title')}:</h2>{' '}
							<pre>{pageData.title}</pre>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.issueStatus')}:</h2>
							<IssueTracking onCreate={() => setIsPopupShown(true)} />
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.k8slogissue.logline')}:</h2>{' '}
							<p>{info?.LogLine}</p>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.k8slogissue.summary')}:</h2>
							<pre>{pageData.summary}</pre>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.description')}:</h2>{' '}
							<pre>{pageData.description}</pre>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.recommendedSeverity')}:</h2>{' '}
							<p>{info?.Severity}</p>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.severityOverride')}:</h2>{' '}
							<Select
								options={SEVERITY_OPTIONS}
								value={resourceInfo.SeverityOverride || info?.Severity}
								className="talasmart-resource__severity"
								onChange={v => {
									onChangeField('SeverityOverride', v);
									onBlurField();
								}}
							/>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.justification')}:</h2>
							<Textarea
								rows="4"
								placeholder={t('form.rationalePlaceholder')}
								value={resourceInfo.Justification}
								onChange={e => onChangeField('Justification', e.target.value)}
								onBlur={onBlurField}
							/>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.potentialRisk')}:</h2>
							<h3>{t('talasmart.resource.risk')}:</h3>
							<h3>{t('talasmart.resource.loss')}:</h3>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.notes')}:</h2>
							<Textarea
								rows="5"
								placeholder={t('form.notesPlaceholder')}
								value={resourceInfo.Notes}
								onChange={e => onChangeField('Notes', e.target.value)}
								onBlur={onBlurField}
							/>
						</Row>
						<Row className="talasmart-resource__item justify-content-between">
							<Button
								className="d-flex justify-content-center gap-1 align-items-center"
								onClick={onCopy}>
								{t('button.copyToClipboard')}
								<Copy />
							</Button>
							<Button
								className="d-flex justify-content-center gap-1 align-items-center"
								onClick={onDownload}>
								{t('button.download')}
								<Download />
							</Button>
						</Row>
					</Col>
					<Col md="6">
						<div className="mb-3">
							<h2>{/*t('talasmart.resource.remediationDescription')*/}</h2>
							<p></p>
						</div>
						<RemediationSteps
							activeStep={activeRemediation}
							onChange={onChangeTab}
						/>
						{isRemediationLoading ? (
							<div>
								<Loader isFull={false} className="mt-5" />
								<span className="text-center pt-2 d-block">
									{TALASMART_WAIT_TEXT}
								</span>
							</div>
						) : (
							<pre>{remediationInfo}</pre>
						)}
					</Col>
				</section>
			)}
			<NotAvailablePopup isOpen={isPopupShown} onClose={() => setIsPopupShown(false)} />
		</>
	);
};

export default TalasmartK8SLogIssue;
