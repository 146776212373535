import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CloudTabs from '../RemoteAuthLanding/components/CloudTabs';
import { CloudTypes } from '../../auth/MultiCloudSignIn/Constants';
import SearchInput from '../../../components/Form/SearchInput';
import { Trans, useTranslation } from 'react-i18next';
import { Plus, Trash } from 'react-feather';
import Button from '../../../components/Form/Button';
import UsersTable from './UsersTable';
import { MOCK_USERS } from './constants';
// import { getUsers } from '../../../apiclients/EmailLogin';

const Users = () => {
	const navigate = useNavigate();
	const [users, setUsers] = useState([]);

	const [activeTab, setActiveTab] = useState();
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [search, setSearch] = useState('');
	const { t } = useTranslation();
	const cloudTypes = useMemo(
		() => Object.keys(CloudTypes).map(key => CloudTypes[key]),
		[]
	);

	// const onGetUsers = async () => {
	// 	await getUsers(console.log, console.log);
	// };

	useEffect(() => {
		// todo: make api call
		// onGetUsers();
		setUsers(MOCK_USERS);
	}, []);

	const onClick = ({ id }) => {
		const updatedSelectedUsers = selectedUsers.includes(id)
			? selectedUsers.filter(selectedUserId => selectedUserId !== id)
			: [...selectedUsers, id];

		setSelectedUsers(updatedSelectedUsers);
	};

	const onDelete = async () => {
		try {
			// Send a DELETE request to the backend to delete selected users
			// await axios.delete('/api/delete-users', { data: selectedUsers }); // Replace with your API endpoint
			// Clear the selected users
			setSelectedUsers([]);
			// Re-fetch the updated user list after deletion
			// const response = await axios.get('/api/users');
			// setUsers(response.data);
		} catch (error) {
			console.error('Error deleting users:', error);
		}
	};

	return (
		<section>
			<div className="mb-4">
				<h1 className="mb-1">
					<Trans i18nKey="adminPanel.administration" />
				</h1>
				<p className="admin-subtitle">
					<Trans i18nKey="adminPanel.users" />
				</p>
			</div>
			<CloudTabs
				onTabChange={setActiveTab}
				activeTab={activeTab}
				options={cloudTypes}
			/>
			<div className="d-flex justify-content-between mb-5">
				<SearchInput
					placeholder={t('adminPanel.searchUsers')}
					value={search}
					onChange={e => setSearch(e.target.value)}
				/>
				<div className="d-flex" style={{ gap: '20px' }}>
					<Button onClick={onDelete} variant="outlined">
						<div className="admin-button">
							<Trash />
							{t('button.delete')}
						</div>
					</Button>
					<Button onClick={() => navigate('/admin/users/add')}>
						<div className="admin-button">
							<Plus />
							{t('button.addUsers')}{' '}
						</div>
					</Button>
				</div>
			</div>
			<UsersTable data={users} onClick={onClick} />
		</section>
	);
};

export default Users;
