import "../../AuditTests.css"

import {
	Nav,
	NavItem,
	NavLink
} from "reactstrap";

import React from "react";

export default function ({ testCases, selected, onSelect }) {

	return <>
		<div className="vertical-gradient h-100">
			<Nav vertical fill pills>
				{Object.keys(testCases).map(id =>
					<NavItem key={testCases[id].id}>
						<NavLink href="#" active={selected === id} onClick={() => onSelect(id)}>
							{testCases[id].value}
						</NavLink>
					</NavItem>
				)}
			</Nav>
		</div>
	</>
}