import React, { useState } from 'react';

import { Input, Label } from 'reactstrap';
import SmallLabel from './SmallLabel';

const ValidatedInput = ({
	type,
	labelfor,
	onChange,
	inputValue,
	validator,
}) => {
	const [value, setValue] = useState(inputValue);
	const [valid, setValid] = useState(validator(inputValue));

	const onValueChange = e => {
		const currentValue = e.target.value;
		const isCurrentValueValid = validator(currentValue);

		setValue(currentValue);
		setValid(isCurrentValueValid);

		if (isCurrentValueValid) {
			onChange(labelfor, currentValue);
		}
	};

	return (
		<Input
			type={type ?? 'text'}
			value={value}
			name={labelfor}
			valid={valid}
			invalid={!valid}
			onBlur={onValueChange}
			onChange={onValueChange}
		/>
	);
};

const AssessmentInput = ({
	type,
	labelfor,
	onChange,
	children,
	value,
	validator,
	placeholder,
}) => {
	const noValidator = !!!validator;

	return (
		<>
			<Label for={onChange}>{children}</Label>
			{noValidator && (
				<Input
					type={type ?? 'text'}
					value={value}
					name={labelfor}
					onChange={e => onChange(labelfor, e.target.value)}
					placeholder={placeholder}
				/>
			)}
			{!noValidator && (
				<ValidatedInput
					type={type}
					inputValue={value}
					name={labelfor}
					onChange={onChange}
					validator={validator}
				/>
			)}
		</>
	);
};

export default AssessmentInput;

export { ValidatedInput };
