import React, { useState } from "react";

import ActionButton from "../../../../components/ActionButton";
import { EvaluationApiClient } from "../../../../apiclients/EvaluationApiClient";

const
	text = "Save Report as Template",
	errortext = "An error occurred while trying to save this report as a template. Please reload this page and try again.";

const SaveAuditTemplateButton = (props) => {

	const
		[isBusy, setIsBusy] = useState(false),
		[failed, setFailed] = useState(false),
		success = (succeeded, r) => {
			if (succeeded) { setIsBusy(false); }
			else { error(r); }
		},
		error = e => {
			console.error("Error while trying to save the template", e);
			setIsBusy(false);
			setFailed(true);
		},
		data = () => ({ packName: props.pack, data: props.values }),
		save = () => new EvaluationApiClient().saveAuditTemplate(data(), success, error),
		onClick = () => {
			setIsBusy(true);
			setTimeout(save, 300);
		};

	return <ActionButton
		text={text}
		isBusy={isBusy}
		error={failed}
		errorText={errortext}
		onBtnClick={onClick}
		colour="secondary"
	/>
}

export default SaveAuditTemplateButton;