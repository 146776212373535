import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

const useFilter = (savedCount, errorCount, onChange) => {
	const [saved, setSaved] = useState(savedCount);
	const [error, setError] = useState(errorCount);
	const [selected, setSelected] = useState('All');

	const onClick = label => {
		setSelected(label);
		onChange(label);
	};

	useEffect(() => {
		setSaved(savedCount);
	}, [savedCount]);

	useEffect(() => {
		setError(errorCount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorCount]);

	return { saved, error, selected, onClick };
};

const RadioButton = ({ label, selected, value, color, onClick }) => {
	const className = `filter-button btn btn-${color} btn-sm`;

	const [clicked, setClicked] = useState(selected === label);

	useEffect(() => {
		setClicked(selected === label);
	}, [selected, label]);

	return (
		<>
			<button className={className} onClick={() => onClick(label)}>
				{clicked && <FontAwesomeIcon icon={faFilter} />}
				<span className="ms-2 mb-2">
					{label}: {value}
				</span>
			</button>
		</>
	);
};

export default function ({ navState }) {
	const vm = useFilter(
		navState.validCount,
		navState.invalidCount,
		navState.onFilterChange
	);
	return (
		<>
			<RadioButton
				label={t('tests.saved')}
				selected={vm.selected}
				value={vm.saved}
				color="success"
				onClick={vm.onClick}
			/>
			<RadioButton
				label={t('tests.error')}
				selected={vm.selected}
				value={vm.error}
				color="warning"
				onClick={vm.onClick}
			/>
			<RadioButton
				label={t('tests.all')}
				selected={vm.selected}
				value={navState.totalCount}
				color="info"
				onClick={vm.onClick}
			/>
		</>
	);
}
