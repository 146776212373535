import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { Colours } from '../Common/Constants';
import Error from './Error';
import {
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Chart,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import LoadingSpinner from './LoadingSpinner';
import { t } from 'i18next';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Tooltip);
Chart.register(Legend);

const options = {
	maintainAspectRatio: false,
	legend: {
		display: true,
	},
	tooltips: {
		intersect: false,
	},
	hover: {
		intersect: true,
	},
	plugins: {
		filler: {
			propagate: false,
		},
	},
	elements: {
		point: {
			radius: 1,
		},
	},
	scales: {
		x: {
			type: 'category',
			gridLines: {
				color: 'rgba(0,0,0,0.0)',
			},
			labels: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
		},
		// x: {
		// 	reverse: false,
		// 	gridLines: {
		// 		color: 'rgba(0,0,0,0.0)',
		// 	},
		// },
		y: {
			ticks: {
				stepSize: 5,
			},
			display: true,
			gridLines: {
				color: '#e9ecef',
				fontColor: '#fff',
			},
		},
	},
};

const mapToSeriesData = results => {
	//taken from _app.css, keep in sync
	const toDataset = item => ({
		label: item.packName,
		fill: false,
		backgroundColor: Colours[item.packKey],
		borderColor: Colours[item.packKey],
		borderWidth: 2,
		data: item.trends.data,
	});
	const data = {
		labels: results[0].trends.labels,
		datasets: results.map(toDataset),
	};

	console.log('data:', data);

	return data;
};

const ComplianceTrend = ({ error, results }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	useEffect(() => {
		let timeout = setTimeout(() => {
			setData(mapToSeriesData(results));
			setLoading(false);
		}, 300);

		return () => {
			clearTimeout(timeout);
		};
	}, [results]);

	return (
		<Card>
			<CardHeader>
				<CardTitle >
					<h4>{t('complianceDashboard.trend')}</h4>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{error && <Error text={t('complianceDashboard.trendError')} />}
				{!error && loading && <LoadingSpinner />}
				{!error && !loading && (
					<div className="chart chart-xs my-2-step">
						<Line data={data} options={options} />
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default ComplianceTrend;
