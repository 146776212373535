import {
	clearAllUsers,
	clearCurrentUser,
	clearSessionItems,
} from '../services/AuthService';
import { asyncRequest, httpMethods, loginHostUri } from './Common';

const retrofitHostUri = process.env.REACT_APP_NEWAUTH_API_HOST;

const headers = {
	'Content-Type': 'application/json',
};

const login = async (body, success, failure) => {
	const uri = loginHostUri + '/login';
	const options = {
		method: httpMethods.post,
		headers,
		body: JSON.stringify(body),
	};

	const result = await asyncRequest(uri, options, false);
	if (!result.success && result.statusCode !== 404) {
		failure(result);
		return;
	}
	const response = result.response;
	success(response);
};

const getLoggedInUserToken = async (body, success, failure) => {
	const uri = retrofitHostUri + '/authUser';
	const options = {
		method: httpMethods.post,
		headers,
		body: JSON.stringify(body),
	};

	const result = await asyncRequest(uri, options);
	if (!result.success && result.statusCode !== 404) {
		failure(result);
		return;
	}
	const response = result.response;
	success(response);
};

const signup = async (body, success, failure) => {
	const uri = loginHostUri + '/register';

	const options = {
		method: httpMethods.post,
		body: JSON.stringify(body),
		headers,
	};

	const result = await asyncRequest(uri, options, false);
	if (!result.success && result.statusCode !== 404) {
		failure(result);
		return;
	}
	const response = result.response;
	success(response);
};

const logout = async (success, failure = console.log) => {
	const uri = loginHostUri + '/logout';
	const options = {
		method: httpMethods.post,
		headers,
	};

	const result = await asyncRequest(uri, options);

	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}
	const response = result.response;
	success(response);
	clearCurrentUser();
	clearAllUsers();
	clearSessionItems();
};

const verifyEmail = async (body, success, failure) => {
	const uri = loginHostUri + '/email-verification';
	const options = {
		method: httpMethods.post,
		headers,
		body: JSON.stringify(body),
	};

	const result = await asyncRequest(uri, options);
	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}

	const response = result.response;
	success(response);
};

const resendEmail = async (body, success, failure) => {
	const uri = loginHostUri + '/resend-email-verification';
	const options = {
		method: httpMethods.post,
		headers,
		body: JSON.stringify(body),
	};

	const result = await asyncRequest(uri, options);
	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}

	const response = result.response;
	success(response);
};

const passwordResetCode = async (body, success, failure) => {
	const uri = loginHostUri + '/password-reset-code';
	const options = {
		method: httpMethods.post,
		body: JSON.stringify(body),
	};

	const result = await asyncRequest(uri, options);
	if (!result.success && result.statusCode !== 404) {
		failure(result);
		return;
	}

	const response = result.response;
	success(response);
};

const getNewauthLandingUri = (provider, options) => {
	const params = new URLSearchParams();
	if (provider) {
		params.set('identity_provider', provider);
	}
	if (options && options.demo) {
		params.set('demo', '1');
	}
	if (options && options.code) {
		params.set('code', options.code);
	}
	if (options && options.agreeMarketing) {
		params.set('agreeMarketing', options.agreeMarketing);
	}
	if (options && options.agreeTerms) {
		params.set('agreeTerms', options.agreeTerms);
	}
	const suffix = params.toString();
	return `${retrofitHostUri}/login?${suffix}`;
};

const getUserKeyList = async (token, success, failure) => {
	const uri = `${retrofitHostUri}/listKeys`;

	const options = {
		method: httpMethods.get,
		headers: {
			token: token,
		},
	};

	const result = await asyncRequest(uri, options, false);
	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}
	success(result.response);
};

const createKey = async (token, keyData, success, failure) => {
	const uri = `${retrofitHostUri}/addKey`;

	const options = {
		method: httpMethods.post,
		headers: {
			token: token,
		},

		body: JSON.stringify(keyData),
	};

	const result = await asyncRequest(uri, options, false);
	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}
	success(result.response);
};

const getUserDefaults = async (token, success, failure) => {
	const uri = retrofitHostUri + '/getUserDefaults';
	const options = {
		method: httpMethods.get,
		headers: {
			token,
		},
	};
	const result = await asyncRequest(uri, options, false);
	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}
	success(result.response);
};

const setUserDefaults = async (body, token, success, failure) => {
	const uri = retrofitHostUri + '/setUserDefaults';
	const options = {
		method: httpMethods.post,
		body: JSON.stringify(body),
		headers: {
			token,
		},
	};

	const result = await asyncRequest(uri, options);
	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}
	success(result.response);
};

const deleteKey = async (ids, token, success, failure) => {
	const options = {
		method: httpMethods.delete,
		headers: {
			token,
		},
	};
	const baseUri = retrofitHostUri + '/deleteKey/';

	return await Promise.all(
		ids.map(async id => {
			const uri = baseUri + id;
			return await asyncRequest(uri, options);
		})
	)
		.then(success)
		.catch(failure);
};

const getUsers = async (success, failure) => {
	const options = {
		method: httpMethods.get,
	};
	const baseUri = retrofitHostUri + '/users';

	const result = await asyncRequest(baseUri, options);
	if (!result.success && result.statusCode !== 404) {
		failure();
		return;
	}
	success(result.response);
};

const requestDemoLink = async (body, success, failure) => {
	const uri = retrofitHostUri + '/requestDemoLink';
	const options = {
		method: httpMethods.post,
		headers,
		body: JSON.stringify(body),
	};

	const result = await asyncRequest(uri, options, false);
	if (!result.success && result.statusCode !== 404) {
		failure(result);
		return;
	}
	const response = result.response;
	success(response);
};

const changePassword = async (body, success, failure) => {
	const uri = loginHostUri + '/change-password';
	const options = {
		method: httpMethods.post,
		headers,
		body: JSON.stringify(body),
	};

	const result = await asyncRequest(uri, options, false);
	if (!result.success && result.statusCode !== 404) {
		failure(result);
		return;
	}
	const response = result.response;
	success(response);
};

export {
	login,
	signup,
	logout,
	resendEmail,
	verifyEmail,
	passwordResetCode,
	getNewauthLandingUri,
	getUserKeyList,
	createKey,
	getLoggedInUserToken,
	getUserDefaults,
	setUserDefaults,
	deleteKey,
	getUsers,
	requestDemoLink,
	changePassword,
};
