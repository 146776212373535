import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Wrapper from '../components/Wrapper';
import Sidebar from '../components/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/Navbar/Navbar';
import Content from '../components/Content';
import Footer from '../components/Footer';
import { getIsAuthenticated, getUser } from '../redux/utils';
import NoKeyPopup from '../components/NoKeyPopup';
import Loader from '../components/Loader';
import useAuthenticated from '../hooks/useAuthenticated';

const Dashboard = ({ sidebar, children }) => {
	const isAuthenticated = useSelector(getIsAuthenticated);
	const user = useSelector(getUser);
	const { checkAuthenticated } = useAuthenticated();

	useEffect(() => {
		checkAuthenticated();
	}, [checkAuthenticated]);

	if (isAuthenticated === false) {
		return null;
	}

	return (
		<React.Fragment>
			<Wrapper>
				{!sidebar.isOnRight && <Sidebar />}
				<Main>
					<Navbar />
					{!user ? (
						<Loader />
					) : user === 'no-key' ? (
						<NoKeyPopup />
					) : (
						<Content>{children}</Content>
					)}
					<Footer />
				</Main>
				{sidebar.isOnRight && <Sidebar />}
			</Wrapper>
		</React.Fragment>
	);
};

export default connect(store => ({
	sidebar: store.sidebar,
}))(Dashboard);
