import { PackKeys } from '../../../../constants/PackKeys';

export default {
	'NIST-CSF': { colour: '#3B7DDD' },
	SOC2: { colour: '#20c997' },
};

const Colours = {
	[PackKeys.NIST]: '#2474F3',
	[PackKeys.SOC2]: '#E72538',
	[PackKeys.HIPAASECURITY]: '#59A919',
	[PackKeys.PCIDSS]: '#cc1100',
	[PackKeys.CMMCL3]: '#cc1100',
};

export { Colours };
