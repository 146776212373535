import "./TopologyControl.css";

import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import React, { useRef, useState } from 'react';

import TopologyControl from './TopologyControl';

const useTopology = ({ onClose, onNodeSelect }) => {
	const [open, setOpen] = useState(true);

	const onClick = () => {
		setOpen(false);
		if (onClose) {
			onClose(false);
		}
	};

	const onMouseOver = e => {
		const ele = e.target,
			data = ele.data();
		data.nonCompliantRules = true;
		if (ele.isNode()) {
			onNodeSelect(data);
		}
	};

	const onMouseOut = e => {
		const ele = e.target;
		if (ele.isNode()) {
			onNodeSelect({});
		}
	};

	return { open, onClick, onMouseOver, onMouseOut };
}

export default ({ title, onClose, nodes, chartDim, children, onNodeSelect }) => {

	const topologyRef = useRef();
	const defaultChartDim = chartDim ?? { h: 800, w: 1200 };
	const { open, onClick, onMouseOver, onMouseOut } = useTopology({ onClose, onNodeSelect });

	return (
		<Modal isOpen={open} size="xl" unmountOnClose={true} modalClassName="topo-popup">
			<ModalHeader>{title}</ModalHeader>
			<ModalBody>
				<Container className="m-1" fluid>
					<Row>
						<Col xl={3}>
							{children}
						</Col>
						<Col xl={9}>
							<Card>
								<CardBody>
									<TopologyControl
										ref={topologyRef}
										nodes={nodes}
										chartDim={defaultChartDim}
										onMouseOut={onMouseOut}
										onMouseOver={onMouseOver}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</ModalBody>
			<ModalFooter>
				<Button size="md" color="danger" onClick={onClick}>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	);
};
