import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const frameStyle = {
	position: 'absolute',
	height: '100%',
	width: '100%',
	border: 'none',
};

function Terms() {
	const { t } = useTranslation();

	return (
		<>
			<Row>
				<Col xs="4">
					<Button
						className="dont-print"
						size="md"
						color="primary"
						onClick={() => window.history.back()}>
						<FontAwesomeIcon icon={faArrowAltCircleLeft} className="me-1" />
						{t('button.back')}
					</Button>
				</Col>
			</Row>
			<Row>
				<iframe style={frameStyle} title="Terms" src="/terms-en.html"></iframe>
			</Row>
		</>
	);
}

export { Terms };
