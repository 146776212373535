import { Col, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';

import ReviewScanFrequencyInput from '../../Components/ReviewScanFrequencyInput';
import ReviewScanInput from '../../Components/ReviewScanInput';

const IncidentMonitoring = ({ hook }) => {
	const { t } = useTranslation();

	return (
		<>
			<Row className="mt-4 ms-3">
				<h4>{t('reviewScan.incidentMonitoring')}</h4>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.manageIncidentMonitoring')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.enabled.yes"
							checked={hook.options.incidenceMonitoring.enabled}
							value="true"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'enabled', true)
							}
							name="siem.enabled"></input>
						<label className="me-6" htmlFor="siem.enabled.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.enabled.no"
							checked={!hook.options.incidenceMonitoring.enabled}
							value="false"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'enabled', false)
							}
							name="siem.enabled"></input>
						<label htmlFor="siem.enabled.no"> {t('button.no')}</label>
					</div>
				</Col>
			</Row>
			{hook.options.incidenceMonitoring.enabled && (
				<Row className="mt-4 ms-2">
					<Col xs="4">
						<div className=" me-1">
							<ReviewScanInput
								labelfor="softwareName"
								value={hook.options.incidenceMonitoring.softwareName}
								onChange={(n, v) => hook.setInput('incidenceMonitoring', n, v)}>
								{t('reviewScan.softwareName')}
							</ReviewScanInput>
						</div>
					</Col>
					<Col xs="4">
						<div className="me-1">
							<ReviewScanInput
								labelfor="licenseARN"
								value={hook.options.incidenceMonitoring.licenseARN}
								onChange={(n, v) => hook.setInput('incidenceMonitoring', n, v)}>
								{t('reviewScan.licenseArn')}
							</ReviewScanInput>
						</div>
					</Col>
					<Col xs="4">
						<div className="me-1">
							<ReviewScanInput
								labelfor="seller"
								value={hook.options.incidenceMonitoring.seller}
								onChange={(n, v) => hook.setInput('incidenceMonitoring', n, v)}>
								{t('reviewScan.seller')}
							</ReviewScanInput>
						</div>
					</Col>
				</Row>
			)}
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.regularVulnerabilityScans')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.vulnerability.yes"
							checked={hook.options.incidenceMonitoring.vulnerability}
							value="true"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'vulnerability', true)
							}
							name="siem.vulnerability"></input>
						<label className="me-6" htmlFor="siem.vulnerability.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.vulnerability.no"
							checked={!hook.options.incidenceMonitoring.vulnerability}
							value="false"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'vulnerability', false)
							}
							name="siem.vulnerability"></input>
						<label htmlFor="siem.vulnerability.no"> {t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="ms-2">
				<Col xs="4">
					<div className="me-1">
						<ReviewScanFrequencyInput
							style={{}}
							labelfor="vulnerabilityFrequency"
							onChange={(n, v) => hook.setInput('incidenceMonitoring', n, v)}
							disabled={!hook.options.incidenceMonitoring.vulnerability}
							value={hook.options.incidenceMonitoring.vulnerabilityFrequency}>
							{t('reviewScan.frequentlyScan')}
						</ReviewScanFrequencyInput>
					</div>
				</Col>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.regularPenetration')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.penetration.yes"
							checked={hook.options.incidenceMonitoring.penetration}
							value="true"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'penetration', true)
							}
							name="siem.penetration"
						/>
						<label className="me-6" htmlFor="siem.penetration.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.penetration.no"
							checked={!hook.options.incidenceMonitoring.penetration}
							value="false"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'penetration', false)
							}
							name="siem.penetration"
						/>
						<label htmlFor="siem.penetration.no"> {t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="ms-2">
				<Col xs="4">
					<div className="me-1">
						<ReviewScanFrequencyInput
							style={{}}
							labelfor="penetrationFrequency"
							onChange={(n, v) => hook.setInput('incidenceMonitoring', n, v)}
							disabled={!hook.options.incidenceMonitoring.penetration}
							value={hook.options.incidenceMonitoring.penetrationFrequency}>
							{t('reviewScan.frequentPenetrationTest')}
						</ReviewScanFrequencyInput>
					</div>
				</Col>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.adjudgeLevelOfCompliance')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.compliance.yes"
							checked={hook.options.incidenceMonitoring.compliance}
							value="true"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'compliance', true)
							}
							name="siem.compliance"></input>
						<label className="me-6" htmlFor="siem.compliance.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="siem.compliance.no"
							checked={!hook.options.incidenceMonitoring.compliance}
							value="false"
							onChange={() =>
								hook.setInput('incidenceMonitoring', 'compliance', false)
							}
							name="siem.compliance"></input>
						<label htmlFor="siem.compliance.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="ms-2">
				<Col xs="4">
					<div className="me-1">
						<ReviewScanFrequencyInput
							style={{}}
							labelfor="complianceFrequency"
							onChange={(n, v) => hook.setInput('incidenceMonitoring', n, v)}
							disabled={!hook.options.incidenceMonitoring.compliance}
							value={hook.options.incidenceMonitoring.complianceFrequency}>
							{t('reviewScan.frequentComplianceTests')}
						</ReviewScanFrequencyInput>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default IncidentMonitoring;
