import React, { useState } from 'react';

import Input from 'reactstrap/lib/Input';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const useValues = (values, onValueSelect) => {
	const [value, setValue] = useState(values[0].value);

	const onChange = e => {
		const index = e.target.selectedIndex;
		if (index > 0) {
			const id = Number(e.target.options[index].value);
			setValue(id);
			onValueSelect(values[index - 1]);
		}
	};

	return { value, onChange };
};

const AssessmentDropdown = ({ name, label, values, value, onSelect }) => {
	const nameDdl = `${name}Ddl`;
	const { t } = useTranslation();
	const hook = useValues(values, onSelect);

	return (
		<>
			<Label for="sector">{label}</Label>
			<Input
				type="select"
				name={nameDdl}
				value={value}
				onChange={hook.onChange}>
				<option value="0">{t('button.select')}</option>
				{values.map(v => (
					<option key={v.id} value={v.id}>
						{v.label ?? v.value}
					</option>
				))}
				;
			</Input>
		</>
	);
};

export default AssessmentDropdown;
