import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import './styles.css';
import Footer from '../../components/Footer';
import ProgressBarForGuide from './components/ProgressBarForGuide';
import HeaderIntroduction from './components/HeaderIntroduction';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import Step7 from './components/Step7';
import Step8 from './components/Step8';
import Step9 from './components/Step9';
import Glossary from './components/Glossary';

function DetailedInstructions() {
	const [scroll, setScroll] = useState(0);
	const [scroll2, setScroll2] = useState(0);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const goBack = () => {
		navigate('/user-guide');
	};

	useEffect(() => {
		const handleScroll = () => {
			const introductionElement = document.getElementById(
				'detailed-introduction'
			);
			const overviewElement = document.getElementById(
				'taladefender-instructions'
			);
			const glossaryElement = document.getElementById('detailed-glossary');
			const step1Element = document.getElementById('step1');
			const step2Element = document.getElementById('step2');
			const step3Element = document.getElementById('step3');
			const step4Element = document.getElementById('step4');
			const step5Element = document.getElementById('step5');
			const step6Element = document.getElementById('step6');
			const step7Element = document.getElementById('step7');
			const step8Element = document.getElementById('step8');
			const step9Element = document.getElementById('step9');

			const elements = [
				step1Element,
				step2Element,
				step3Element,
				step4Element,
				step5Element,
				step6Element,
				step7Element,
				step8Element,
				step9Element,
			];
			const values = [11, 22, 33, 44, 55, 66, 77, 88, 90];

			if (introductionElement) {
				const { top } = introductionElement.getBoundingClientRect();
				if (top < window.innerHeight / 2) {
					setScroll(25);
				}
			}

			if (overviewElement) {
				const { top } = overviewElement.getBoundingClientRect();
				if (top < window.innerHeight / 2) {
					setScroll(100);
				}
			}

			elements.forEach((element, index) => {
				if (element) {
					const { top } = element.getBoundingClientRect();
					if (top <= window.innerHeight / 2) {
						setScroll2(values[index]);
					}
				}
			});

			if (glossaryElement) {
				const { top } = glossaryElement.getBoundingClientRect();
				if (top < window.innerHeight) {
					setScroll2(100);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [scroll, scroll2]);
	return (
		<Container fluid>
			<HeaderIntroduction
				title={t('detailedInstructions.title')}
				subTitle={t('detailedInstructions.subTitle')}
				onClick={goBack}
			/>
			<Row className="introduction">
				<Col xs="9" className="mt-4">
					<div id="detailed-introduction">
						<h3>{t('oneTimeSetup.introduction')}</h3>
						<p>{t('detailedInstructions.globalCybersecurity')}</p>
						<p>{t('detailedInstructions.acomprehensiveManual')}</p>
					</div>

					{/* Detailed Instructions on Tala Defender Setup */}

					<div className="mt-4" id="taladefender-instructions">
						<h3>{t('detailedInstructions.title')}</h3>

						<Step1 />

						<Step2 />

						<Step3 />

						<Step4 />

						<Step5 />

						<Step6 />

						<Step7 />

						<Step8 />

						<Step9 />
					</div>

					<Glossary />
				</Col>
				<Col xs="2" className="fixed-col">
					<ProgressBarForGuide
						descp1={t('detailedInstructions.introduction')}
						descp2={t('detailedInstructions.title')}
						descp3={t('detailedInstructions.glossary')}
						id1={'#detailed-introduction'}
						id2={'#taladefender-instructions'}
						id3={'#detailed-glossary'}
						scroll={scroll}
						scroll2={scroll2}
					/>
				</Col>
				<div className="mt-7">
					<Footer />
				</div>
			</Row>
		</Container>
	);
}

export default DetailedInstructions;
