import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Container,
	Modal,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import Header from '../../components/Header';
import HeaderTitle from '../../components/HeaderTitle';
import { Link } from 'react-router-dom';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { PackKeys } from '../../constants/PackKeys';

const tests = [
	{
		title: 'NIST-CSF Compliance Framework',
		url: 'run/nistcsf',
		pack: PackKeys.NIST,
		titleBackground: '#33adff',
		disabled: false,
		text: "National Institute of Standards and Framework's Cybersecurity Framework (NIST CSF) is a set of best practices, standards, and recommendations that help an organisation improve its cybersecurity measures.",
	},
	{
		title: 'SOC2 Compliance Framework',
		url: 'run/soc2',
		disabled: false,
		pack: PackKeys.SOC2,
		titleBackground: '#33adff',
		text: 'Service Organisation Control (SOC) reports on various organisational controls related to security, availability, processing integrity, confidentiality or privacy.',
	},
	{
		title: 'HIPAA Security',
		url: '#',
		titleBackground: '#80ccff',
		disabled: true,
		text: 'The Health Insurance Portability and Accountability Act (HIPAA) sets the standard for sensitive patient data protection.',
	},
	{
		title: 'NIST 800-53 rev 4',
		titleBackground: '#80ccff',
		url: '#',
		disabled: true,
		text: "NIST SP 800-53 defines the standards and guidelines for federal agencies to architect and manage their information security systems. It was established to provide guidance for the protection of agency's and citizen's private data.",
	},
	{
		title: 'NIST 800 171',
		titleBackground: '#80ccff',
		url: '#',
		disabled: true,
		text: 'NIST SP800-171 or just 800-171 is a codification of the requirements that any non-Federal computer system must follow in order to store, process, or transmit Controlled Unclassified Information (CUI) or provide security protection for such systems.',
	},
	{
		title: 'FedRAMP',
		titleBackground: '#80ccff',
		url: '#',
		disabled: true,
		text: 'The Federal Risk and Authorization Management Program (FedRAMP) is a government-wide program that provides a standardized approach to security assessment, authorization, and continuous monitoring for cloud products and services.',
	},
];

const ComingSoonModal = ({ title, titleBgColor }) => {
	const [modal, setModal] = useState(false);

	const toggle = () => setModal(!modal);

	return (
		<div>
			<Button color="primary" onClick={toggle}>
				Select
			</Button>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader style={{ backgroundColor: titleBgColor }}>
					{title}
				</ModalHeader>
				<ModalBody>
					<h5>Coming soon!</h5>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={toggle}>
						Close
					</Button>{' '}
				</ModalFooter>
			</Modal>
		</div>
	);
};

const Test = props => {
	return (
		<div className="card shadow-sm p-3 m-5 bg-white rounded w-25">
			<div
				className="card-header card-title "
				style={{ backgroundColor: props.data.titleBackground }}>
				<h4
					className="text-capitalize"
					style={{ backgroundColor: props.data.titleBackground }}>
					{props.data.title}
				</h4>
			</div>
			<div className="card-body">
				<div className="card-text">
					<div className="row h-75">
						<span>{props.data.text}</span>
					</div>
					<div className="row h-25 mt-3">
						{!props.data.disabled && (
							<Link
								to={`/tests/${props.data.url}`}
								className="btn"
								style={{ backgroundColor: props.data.titleBackground }}>
								Select
							</Link>
						)}

						{props.data.disabled && (
							<ComingSoonModal
								title={props.data.title}
								titleBgColor={props.data.titleBackground}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

let testsKey = 1;

const SelectTest = () => {
	useEffect(() => {
		document.title = 'Select Compliance Test Framework';
	});

	return (
		<Container fluid>
			<Header>
				<HeaderTitle>Select Compliance Test Framework</HeaderTitle>

				<Breadcrumb>
					<BreadcrumbItem>
						<Link to="/">Home</Link>
					</BreadcrumbItem>
					<BreadcrumbItem>
						<Link to="#">Compliance</Link>
					</BreadcrumbItem>
					<BreadcrumbItem active>
						<Link to="./select">Select</Link>
					</BreadcrumbItem>
				</Breadcrumb>
			</Header>

			{/* <NetworkTopology /> */}

			<div className="row">
				{tests.map(test => (
					<Test data={test} key={testsKey++} />
				))}
			</div>
		</Container>
	);
};

export { SelectTest };
