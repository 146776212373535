import awsConfig from './awsConfig';
import azureConfig from './azureConfig';
import onpremConfig from './onpremConfig';
import k8sConfig from './k8sConfig';
import { getUserType } from '../../../src/services/AuthService';

const defaultDim = {
	height: '48px',
	width: '48px',
};

const defaultConfig = {
	label: '',
	image: {
		shape: 'round-rectangle',
		dim: defaultDim,
		colour: 'rgba(200, 200, 200, 0.8)',
	},
	color: 'rgba(200, 200, 200, 0.8)',
};

const config = () => [{}, awsConfig, azureConfig, onpremConfig, k8sConfig, {}][getUserType()];
class Config {
	get(ele) {
		const resourceType = ele.data('resourcetype');
		const configurationResourceType = config()[resourceType];

		return configurationResourceType ?? defaultConfig;
	}

	getImage(resourceType) {
		const configurationResourceType = config()[resourceType];
		const resource = configurationResourceType ?? defaultConfig;

		return resource.image.image;
	}

	getLabel(resourceType) {
		const configurationResourceType = config()[resourceType];
		const resource = configurationResourceType ?? defaultConfig;
		return resource.label;
	}

	border(ele) {
		const data = ele.data();
		const compliance = data.compliance;
		const issues = data.issues;
		const hasCompliance = compliance && compliance.length > 0;
		const isCompliant = hasCompliance && compliance === 'Compliant';
		const hasIssues = issues && issues.length > 0;

		if (hasCompliance) {
			//the hasCompliance check is needed for the main topology control
			return !isCompliant || hasIssues ? 5 : 0;
		}

		return 0;
	}

	isNonCompliant(data) {
		//console.log("isNonCompliant:", data);
		const compliance = data.compliance;
		const issues = data.issues;
		const ncrules = data["non-compliant-rules"];
		const hasCompliance = compliance && compliance.length > 0;
		const isCompliant = (hasCompliance && compliance === 'Compliant');
		const isNonCompliant = (hasCompliance && compliance === 'Non-Compliant');
		const hasIssues = (issues && issues.length > 0) || false;
		const hasNCRules = (ncrules && ncrules.length > 0) || false;
		if(hasNCRules)
		{
			return true;
		}
		if(((true === isCompliant) && (false === isNonCompliant)) && !hasIssues)
		{
			return false;
		}
		if(((false === isCompliant) && (true === isNonCompliant)) || hasIssues)
		{

			return true;
		}
		// Assume compliant
		return false;

	}

	zIndex(ele) {
		return ele.data('level');
	}

	legend() {
		const legend = [];
		for (const [, value] of Object.entries(config())) {
			const image = value.image.image;
			legend.push({
				label: value.label,
				image,
				showCompliant: true,
				showNonCompliant: true,
			});
		}
		return legend;
	}

	config() {
		return config();
	}
}

export default Config;
