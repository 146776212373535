import {
	Button,
	Card,
	CardBody,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Row,
	Spinner,
	UncontrolledDropdown,
} from 'reactstrap';

import ConfirmLink from '../../../components/ConfirmLink';
import DataGrid from './Controls/DataGrid';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import { MoreVertical } from 'react-feather';
import React, { useState } from 'react';
import ResetStatus from './Controls/ResetStatus';
import useAutoRemediation from '../Hooks/useAutoRemediation';
import useBulkRemediation from '../Hooks/useBulkRemediation';
import { t } from 'i18next';
import DemoPopup from '../../../components/DemoPopup';
import {
	isDemoAccount,
	isTrialAccount,
} from '../../../services/accountTypeService';

const fixLink = '/ransomware/remediation/fix';

const centeredContent =
	'flex-column align-items-center d-flex justify-content-center m-5';

const AutoRemediation = () => {
	const [demoPopup, setDemoPopup] = useState(false);
	const [demoPopupText, setDemoPopupText] = useState('');

	const {
		report,
		reportId,
		loading,
		issues,
		noData,
		evaluating,
		onFix,
		onRemediate,
		onRedirectClick,
		onVulnerabilitySelected,
	} = useAutoRemediation();

	const {
		onRollbackAll,
		resetStatus,
		setResetStatus,
		resetTotal,
		resetPassed,
		resetFailed,
	} = useBulkRemediation();

	const onClosed = () => setResetStatus(0);

	const showDemoPopupRemediate = () => {
		setDemoPopupText(t('liveDemo.noRemediation'));
		setDemoPopup(true);
		return false;
	};

	const showDemoPopupReset = () => {
		setDemoPopupText(t('liveDemo.noReset'));
		setDemoPopup(true);
	};

	const showDemoPopupRerun = () => {
		setDemoPopupText(t('liveDemo.rerun'));
		setDemoPopup(true);
	};

	const startRemediation = () => {
		return isDemoAccount() || isTrialAccount()
			? showDemoPopupRemediate()
			: onRedirectClick();
	};
	const startReset = reportId => {
		isDemoAccount() || isTrialAccount()
			? showDemoPopupReset()
			: onRollbackAll(reportId);
	};

	const rerunAssessment = () => {
		isDemoAccount() || isTrialAccount() ? showDemoPopupRerun() : onRemediate();
	};

	return (
		<Container fluid>
			<DemoPopup
				isOpen={demoPopup}
				setDemoPopup={setDemoPopup}
				title={demoPopupText}
			/>
			<Header>
				<HeaderTitle>{t('autoRemediation.title')}</HeaderTitle>
			</Header>
			<Card>
				<CardBody>
					<Row className="float-end me-3 mt-2">
						{!loading && (
							<>
								<ConfirmLink
									to={fixLink}
									title="View"
									onClick={startRemediation}
									confirmText={t('autoRemediation.selectVulnerability')}>
									<span>{t('autoRemediation.begin')}</span>
								</ConfirmLink>
								<UncontrolledDropdown className="d-inline-block float-end mt-1 ms-2">
									<DropdownToggle tag="a">
										<MoreVertical />
									</DropdownToggle>
									<DropdownMenu right>
										<DropdownItem onClick={() => startReset(reportId)}>
											<span>{t('autoRemediation.reset')}</span>
										</DropdownItem>
										{/* <DropdownItem onClick={() => onFixAll(reportId)}>
										<span>Fix All</span>
									</DropdownItem> */}
									</DropdownMenu>
								</UncontrolledDropdown>
							</>
						)}
					</Row>
					<Row
						className="mt-5"
						style={{ display: 'flex', justifyContent: 'center' }}>
						<div className="pt-3">
							{loading && (
								<div>
									<div className={centeredContent}>
										<h4>{t('autoRemediation.loadingLastAssessment')}</h4>
										<Spinner color="primary" />
									</div>
								</div>
							)}
							{!loading && noData && (
								<h3 className={`${centeredContent} text-muted`}>
									{t('autoRemediation.runAtLeastOne')}
								</h3>
							)}
							{!loading && !noData && (
								<DataGrid
									report={report}
									reportId={reportId}
									onFix={onFix}
									issues={issues}
									onSelected={onVulnerabilitySelected}
								/>
							)}
						</div>
					</Row>
					<Row>
						<div className="d-flex align-items-center justify-content-center margin-top-10" style={{ marginTop: '10px' }}>
							{!loading && (
								<Button
									disabled={noData || evaluating}
									color="primary"
									className="me-2"
									onClick={rerunAssessment}>
									{evaluating && (
										<div>
											<div
												role="status"
												className="spinner-border spinner-border-sm mb-1">
												<span className="sr-only"></span>
											</div>
											<span className="floatLeft ms-1">
												{t('autoRemediation.runningAssessment')}
											</span>
										</div>
									)}
									{!evaluating && (
										<span>{t('autoRemediation.rerunAssessment')}</span>
									)}
								</Button>
							)}
							{resetStatus !== -1 && (
								<ResetStatus
									onClosed={onClosed}
									sucessfull={resetPassed}
									unsuccessfull={resetFailed}
									total={resetTotal}
									inProgress={resetStatus === 0}
								/>
							)}
						</div>
					</Row>
				</CardBody>
			</Card>
		</Container>
	);
};

export default AutoRemediation;
