import { t } from 'i18next';
import { PackKeys } from '../../../../constants/PackKeys';
import cmmCl3RefData from '../../AuditData/CMMCL3';
import hipaaRefData from '../../AuditData/Hipaa';
import nistRefData from '../../AuditData/NistCsf';
import pciDss from '../../AuditData/PciDss';
import soc2RefData from '../../AuditData/Soc2';

const allPacks = {
	[PackKeys.NIST]: {
		title: t('tests.titleAuditTests', { pack: 'NIST-CSF' }),
		reviewTitle: t('tests.reviewTitle', { pack: 'NIST-CSF' }),
		breadCrumb: 'NIST-CSF',
	},
	[PackKeys.SOC2]: {
		title: t('tests.titleAuditTests', { pack: 'SOC2' }),
		reviewTitle: t('tests.reviewTitle', { pack: 'SOC2' }),
		breadCrumb: 'SOC2',
	},
	[PackKeys.HIPAASECURITY]: {
		title: t('tests.titleAuditTests', { pack: 'HIPAA Security' }),
		reviewTitle: t('tests.reviewTitle', { pack: 'HIPAA Security' }),
		breadCrumb: 'HIPAA Security',
	},
	[PackKeys.PCIDSS]: {
		title: t('tests.titleAuditTests', { pack: 'PCI DSS' }),
		reviewTitle: t('tests.reviewTitle', { pack: 'PCI DSS' }),
		breadCrumb: 'PCI DSS',
	},
	[PackKeys.CMMCL3]: {
		title: t('tests.titleAuditTests', { pack: 'CMMC Level 3' }),
		reviewTitle: t('tests.reviewTitle', { pack: 'CMMC Level 3' }),
		breadCrumb: 'CMMC Level 3',
	},
};

const getAuditRefData = pack => {
	if (pack === PackKeys.NIST) {
		return nistRefData;
	} else if (pack === PackKeys.SOC2) {
		return soc2RefData;
	} else if (pack === PackKeys.HIPAASECURITY) {
		return hipaaRefData;
	} else if (pack === PackKeys.PCIDSS) {
		return pciDss;
	} else if (pack === PackKeys.CMMCL3) {
		return cmmCl3RefData;
	} else {
		console.error('Unknown pack found');
		return {};
	}
};

const initialProgress = {
	index: 0,
	childIndex: 0,
	categoryIndex: 0,
	subCategoryIndex: 0,
	subCategoryId: '',
	categoryId: '',
	item: {},
	totalItemCount: 0,
	source: '',
};

const dropDownData = {
	compliance: {
		defaultValue: 1,
		values: [
			{
				value: 0,
				label: t('button.select'),
			},
			{
				value: 1,
				label: t('topology.compliant'),
			},
			{
				value: 2,
				label: t('topology.nonCompliant'),
			},
		],
	},
	severity: {
		defaultValue: 0,
		values: [
			{
				value: 0,
				label: t('button.select'),
			},
			{
				value: 1,
				label: t('ransomwareDashboard.low'),
			},
			{
				value: 2,
				label: t('ransomwareDashboard.medium'),
			},
			{
				value: 3,
				label: t('ransomwareDashboard.high'),
			},
			{
				value: '4',
				label: t('ransomwareDashboard.critical'),
			},
		],
	},
};

const errorReasons = {
	orgControlError: t('tests.orgControlError'),
	observationError: t('tests.observationError'),
	resultError: t('tests.resultError'),
	severityError: t('tests.severityError'),
	evidenceError: t('tests.evidenceError'),
};

const errorClassName = 'text-danger';

const requiredField = t('tests.requiredField');

export {
	allPacks,
	getAuditRefData,
	initialProgress,
	dropDownData,
	errorClassName,
	requiredField,
	errorReasons,
};
