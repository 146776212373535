let index = 0;

const getIndex = () => index++;

const AwsTestsNIST =
{
	"referenceData": {
		"result": {
			"defaultValue": 2,
			"values": [{
				"value": 1,
				"label": "Compliant"
			},
			{
				"value": 2,
				"label": "Non-Compliant"
			}
			]
		},
		"severity": {
			"defaultValue": 3,
			"values": [{
				"value": 1,
				"label": "Low"
			},
			{
				"value": 2,
				"label": "Medium"
			},
			{
				"value": 3,
				"label": "High"
			},
			{
				"value": "4",
				"label": "Critical"
			}
			]
		},
		"categories": {
			"cc6.1": {
				"name": "CC 6.1",
				"description": "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
				"subcategories": ["cc6.1.1", "cc6.1.2", "cc6.1.3", "cc6.1.4", "cc6.1.5", "cc6.1.6", "cc6.1.7", "cc6.1.8", "cc6.1.9", "cc6.1.10"]
			},
			"cc6.6": {
				"name": "CC 6.6",
				"description": "The entity implements logical access security measures to protect against threats from sources outside its system boundaries.",
				"subcategories": ["cc6.6.1", "cc6.6.2", "cc6.6.3", "cc6.6.4"]
			},
			"cc6.7": {
				"name": "CC 6.7",
				"description": "The entity restricts the transmission, movement, and removal of information to authorized internal and external users and processes, and protects it during transmission, movement, or removal to meet the entity’s objectives.",
				"subcategories": ["cc6.7.1", "cc6.7.2", "cc6.7.3", "cc6.7.4"]
			},
			"cc8.1": {
				"name": "CC 8.1",
				"description": "The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.",
				"subcategories": ["cc8.1.1", "cc8.1.2", "cc8.1.4", "cc8.1.5", "cc8.1.7", "cc8.1.8", "cc8.1.9", "cc8.1.10", "cc8.1.11", "cc8.1.12", "cc8.1.13", "cc8.1.14", "cc8.1.15"]
			},
			"a1.1": {
				"name": "A 1.1",
				"description": "The entity maintains, monitors, and evaluates current processing capacity and use of system components (infrastructure, data, and software) to manage capacity demand and to enable the implementation of additional capacity to help meet its objectives.",
				"subcategories": ["a1.1.1", "a1.1.2", "a1.1.3"]
			},
			"a1.2": {
				"name":
					"A 1.2", "description": "The entity authorizes, designs, develops or acquires, implements, operates, approves, maintains, and monitors environmental protections, software, data backup processes, and recovery infrastructure to meet its objectives.",
				"subcategories": ["a1.2.1", "a1.2.2", "a1.2.3", "a1.2.4", "a1.2.5", "a1.2.6", "a1.2.7", "a1.2.8", "a1.2.9", "a1.2.10"]
			},
			"a1.3": {
				"name":
					"A 1.3", "description": "The entity tests recovery plan procedures supporting system recovery to meet its objectives.",
				"subcategories": ["a1.3.1", "a1.3.2"]
			},
			"c1.1": {
				"name": "C 1.1",
				"description": "The entity identifies and maintains confidential information to meet the entity’s objectives related to confidentiality.",
				"subcategories": ["c1.1.1", "c1.1.2"]
			},
			"c1.2": {
				"name": "C 1.2",
				"description": "The entity disposes of confidential information to meet the entity’s objectives related to confidentiality.",
				"subcategories": ["c1.2.1", "c1.2.2"]
			},
			"pi1.2": { "name": "PI 1.2", "description": "The entity implements policies and procedures over system inputs, including controls over completeness and accuracy, to result in products, services, and reporting to meet the entity’s objectives.", "subcategories": ["pi1.2.3"] },
			"pi1.3": { "name": "PI 1.3", "description": "The entity implements policies and procedures over system processing to result in products, services, and reporting to meet the entity’s objectives.", "subcategories": ["pi1.3.4", "pi1.4.1"] },
			"pi1.4": { "name": "PI 1.4", "description": "The entity implements policies and procedures to make available or deliver output completely, accurately, and timely in accordance with specifications to meet the entity’s objectives.", "subcategories": ["pi1.4.4"] },
			"pi1.5": { "name": "PI 1.5", "description": "The entity implements policies and procedures to store inputs, items in processing, and outputs completely, accurately, and timely in accordance with system specifications to meet the entity’s objectives.", "subcategories": ["pi1.5.1", "pi1.5.2", "pi1.5.3", "pi1.5.4"] },
			"p5.1": { "name": "P 5.1", "description": "The entity grants identified and authenticated data subjects the ability to access their stored personal information for review and, upon request, provides physical or electronic copies of that information to data subjects to meet the entity’s objectives related to privacy. If access is denied, data subjects are informed of the denial and reason for such denial, as required, to meet the entity’s objectives related to privacy.", "subcategories": ["p5.1.1", "p5.1.2"] },
			"p5.2": { "name": "P 5.2", "description": "The entity corrects, amends, or appends personal information based on information provided by data subjects and communicates such information to third parties, as committed or required, to  meet the entity’s objectives related to privacy. If a request for correction is denied, data subjects are informed of the denial and reason for such denial to meet the entity’s objectives related to privacy.", "subcategories": ["p5.2.2"] },
			"p8.1": { "name": "P 8.1", "description": "The entity implements a process for receiving, addressing, resolving, and communicating the resolution of inquiries, complaints, and disputes from data subjects and others and periodically monitors compliance to meet the entity’s objectives related to privacy. Corrections and other necessary actions related to identified deficiencies are made or taken in a timely manner.", "subcategories": ["p8.1.4", "p8.1.5", "p8.1.6"] },
			"cc6.2": { "name": "CC 6.2", "description": "Prior to issuing system credentials and granting system access, the entity registers and authorizes new internal and external users whose access is administered by the entity. For those users whose access is administered by the entity, user system credentials are removed when user access is no longer authorized.", "subcategories": ["cc6.2.1", "cc6.2.2", "cc6.2.3"] },
			"cc6.3": { "name": "CC 6.3", "description": "The entity authorizes, modifies, or removes access to data, software, functions, and other protected information assets based on roles, responsibilities, or the system design and changes, giving consideration to the concepts of least privilege and segregation of duties, to meet the entity’s objectives.", "subcategories": ["cc6.3.1", "cc6.3.2", "cc6.3.3", "cc6.3.4"] },
			"cc6.4": { "name": "CC 6.4", "description": "The entity restricts physical access to facilities and protected information assets (for example, data center facilities, back-up media storage, and other sensitive locations) to authorized personnel to meet the entity’s objectives.", "subcategories": ["cc6.4.1", "cc6.4.2", "cc6.4.3"] },
			"cc6.5": { "name": "CC 6.5", "description": "The entity discontinues logical and physical protections over physical assets only after the ability to read or recover data and software from those assets has been diminished and is no longer required to meet the entity’s objectives.", "subcategories": ["cc6.5.1", "cc6.5.2"] },
			"cc6.8": { "name": "CC 6.8", "description": "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity’s objectives.", "subcategories": ["cc6.8.1", "cc6.8.2", "cc6.8.3", "cc6.8.4", "cc6.8.5"] },
			"cc7.1": { "name": "CC 7.1", "description": "To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.", "subcategories": ["cc7.1.1", "cc7.1.2", "cc7.1.3", "cc7.1.4", "cc7.1.5"] },
			"cc7.2": { "name": "CC 7.2", "description": "The entity monitors system components and the operation of those components for anomalies that are indicative of malicious acts, natural disasters, and errors affecting the entity's ability to meet its objectives; anomalies are analyzed to determine whether they represent security events.", "subcategories": ["cc7.2.1", "cc7.2.2", "cc7.2.3", "cc7.2.4"] },
			"cc7.3": { "name": "CC 7.3", "description": "The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.", "subcategories": ["cc7.3.1", "cc7.3.2", "cc7.3.3", "cc7.3.4"] },
			"cc7.4": { "name": "CC 7.4", "description": "The entity responds to identified security incidents by executing a defined incident-response program to understand, contain, remediate, and communicate security incidents, as appropriate.", "subcategories": ["cc7.4.1", "cc7.4.2", "cc7.4.6", "cc7.4.7", "cc7.4.8", "cc7.4.9", "cc7.4.10", "cc7.4.11"] },
			"cc7.5": { "name": "CC 7.5", "description": "The entity identifies, develops, and implements activities to recover from identified security incidents.", "subcategories": ["cc7.5.2", "cc7.5.3", "cc7.5.4", "cc7.5.5", "cc7.5.6"] },
			"cc9.1": { "name": "CC 9.1", "description": "The entity identifies, selects, and develops risk mitigation activities for risks arising from potential business disruptions.", "subcategories": ["cc9.1.1"] },
			"cc9.2": { "name": "CC 9.2", "description": "The entity assesses and manages risks associated with vendors and business partners.", "subcategories": ["cc9.2.1", "cc9.2.2", "cc9.2.3", "cc9.2.4", "cc9.2.5", "cc9.2.6", "cc9.2.7", "cc9.2.9", "cc9.2.10"] },
		},
		"subcategories": {
			"cc6.1.1": { "name": "", "description": "Identifies and Manages the Inventory of Information Assets", index: getIndex() },
			"cc6.1.2": { "name": "", "description": "Restricts Logical Access", index: getIndex() },
			"cc6.1.3": { "name": "", "description": "Identifies and Authenticates Users", index: getIndex() },
			"cc6.1.4": { "name": "", "description": "Considers Network Segmentation", index: getIndex() },
			"cc6.1.5": { "name": "", "description": "Manages Points of Access", index: getIndex() },
			"cc6.1.6": { "name": "", "description": "Restricts Access to Information Assets", index: getIndex() },
			"cc6.1.7": { "name": "", "description": "Manages Identification and Authentication", index: getIndex() },
			"cc6.1.8": { "name": "", "description": "Manages Credentials for Infrastructure and Software", index: getIndex() },
			"cc6.1.9": { "name": "", "description": "Uses Encryption to Protect Data", index: getIndex() },
			"cc6.1.10": { "name": "", "description": "Protects Encryption Keys", index: getIndex() },
			"cc6.6.1": { "name": "", "description": "Restricts Access", index: getIndex() },
			"cc6.6.2": { "name": "", "description": "Protects Identification and Authentication Credentials", index: getIndex() },
			"cc6.6.3": { "name": "", "description": "Requires Additional Authentication or Credentials", index: getIndex() },
			"cc6.6.4": { "name": "", "description": "Implements Boundary Protection Systems", index: getIndex() },
			"cc6.7.1": { "name": "", "description": "Restricts the Ability to Perform Transmission", index: getIndex() },
			"cc6.7.2": { "name": "", "description": "Uses Encryption Technologies or Secure Communication Channels to Protect Data", index: getIndex() },
			"cc6.7.3": { "name": "", "description": "Protects Removal Media", index: getIndex() },
			"cc6.7.4": { "name": "", "description": "Protects Mobile Devices", index: getIndex() },
			"cc8.1.1": { "name": "", "description": "Manages Changes Throughout the System Life Cycle", index: getIndex() },
			"cc8.1.2": { "name": "", "description": "Authorizes Changes", index: getIndex() },
			"cc8.1.4": { "name": "", "description": "Documents Changes", index: getIndex() },
			"cc8.1.5": { "name": "", "description": "Tracks System Changes", index: getIndex() },
			"cc8.1.7": { "name": "", "description": "Tests System Changes", index: getIndex() },
			"cc8.1.8": { "name": "", "description": "Approves System Changes", index: getIndex() },
			"cc8.1.9": { "name": "", "description": "Deploys System Changes", index: getIndex() },
			"cc8.1.10": { "name": "", "description": "Identifies and Evaluates System Changes", index: getIndex() },
			"cc8.1.11": { "name": "", "description": "Identifies Changes in Infrastructure, Data, Software, and Procedures Required to", index: getIndex() },
			"cc8.1.12": { "name": "", "description": "Creates Baseline Configuration of IT Technology", index: getIndex() },
			"cc8.1.13": { "name": "", "description": "Provides for Changes Necessary in Emergency Situations", index: getIndex() },
			"cc8.1.14": { "name": "", "description": "Protects Confidential Information", index: getIndex() },
			"cc8.1.15": { "name": "", "description": "Protects Personal Information", index: getIndex() },
			"a1.1.1": { "name": "", "description": "Measures Current Usage", index: getIndex() },
			"a1.1.2": { "name": "", "description": "Forecasts Capacity", index: getIndex() },
			"a1.1.3": { "name": "", "description": "Makes Changes Based on Forecasts", index: getIndex() },
			"a1.2.1": { "name": "", "description": "Identifies Environmental Threats", index: getIndex() },
			"a1.2.2": { "name": "", "description": "Designs Detection Measures", index: getIndex() },
			"a1.2.3": { "name": "", "description": "Implements and Maintains Environmental Protection Mechanisms", index: getIndex() },
			"a1.2.4": { "name": "", "description": "Implements Alerts to Analyze Anomalies", index: getIndex() },
			"a1.2.5": { "name": "", "description": "Responds to Environmental Threat Events", index: getIndex() },
			"a1.2.6": { "name": "", "description": "Communicates and Reviews Detected Environmental Threat Events", index: getIndex() },
			"a1.2.7": { "name": "", "description": "Determines Data Requiring Backup", index: getIndex() },
			"a1.2.8": { "name": "", "description": "Performs Data Backup", index: getIndex() },
			"a1.2.9": { "name": "", "description": "Addresses Offsite Storage", index: getIndex() },
			"a1.2.10": { "name": "", "description": "Implements Alternate Processing Infrastructure", index: getIndex() },
			"a1.3.1": { "name": "", "description": "Implements Business Continuity Plan Testing", index: getIndex() },
			"a1.3.2": { "name": "", "description": "Tests Integrity and Completeness of Backup Data", index: getIndex() },
			"c1.1.1": { "name": "", "description": "Identifies Confidential information", index: getIndex() },
			"c1.1.2": { "name": "", "description": "Protects Confidential Information From Destruction", index: getIndex() },
			"c1.2.1": { "name": "", "description": "Protects Confidential Information From Destruction", index: getIndex() },
			"c1.2.2": { "name": "", "description": "Identifies Confidential Information for Destruction", index: getIndex() },
			"pi1.2.3": { "name": "", "description": "Creates and Maintains Records of System Inputs", index: getIndex() },
			"pi1.3.4": { "name": "", "description": "Records System Processing Activities", index: getIndex() },
			"pi1.4.1": { "name": "", "description": "Protects Output", index: getIndex() },
			"pi1.4.4": { "name": "", "description": "Creates and Maintains Records of System Output Activities", index: getIndex() },
			"pi1.5.1": { "name": "", "description": "Protects Stored Items", index: getIndex() },
			"pi1.5.2": { "name": "", "description": "Archives and Protects System Records", index: getIndex() },
			"pi1.5.3": { "name": "", "description": "Stores Data Completely and Accurately", index: getIndex() },
			"pi1.5.4": { "name": "", "description": "Creates and Maintains Records of System Storage Activities", index: getIndex() },
			"p5.1.1": { "name": "", "description": "Authenticates Data Subjects’ Identity", index: getIndex() },
			"p5.1.2": { "name": "", "description": "Permits Data Subjects Access to Their Personal Information", index: getIndex() },
			"p5.2.2": { "name": "", "description": "Permits Data Subjects to Update or Correct Personal Information", index: getIndex() },
			"p8.1.4": { "name": "", "description": "Documents and Reports Compliance Review Results", index: getIndex() },
			"p8.1.5": { "name": "", "description": "Documents and Reports Instances of Noncompliance", index: getIndex() },
			"p8.1.6": { "name": "", "description": "Performs Ongoing Monitoring", index: getIndex() },
			"cc6.2.1": { "name": "", "description": "Controls Access Credentials to Protected Assets", index: getIndex() },
			"cc6.2.2": { "name": "", "description": "Removes Access to Protected Assets When Appropriate", index: getIndex() },
			"cc6.2.3": { "name": "", "description": "Reviews Appropriateness of Access Credentials", index: getIndex() },
			"cc6.3.1": { "name": "", "description": "Creates or Modifies Access to Protected Information Assets", index: getIndex() },
			"cc6.3.2": { "name": "", "description": "Removes Access to Protected Information Assets", index: getIndex() },
			"cc6.3.3": { "name": "", "description": "Uses Role-Based Access Controls", index: getIndex() },
			"cc6.3.4": { "name": "", "description": "Reviews Access Roles and Rules", index: getIndex() },
			"cc6.4.1": { "name": "", "description": "Creates or Modifies Physical Access", index: getIndex() },
			"cc6.4.2": { "name": "", "description": "Removes Physical Access", index: getIndex() },
			"cc6.4.3": { "name": "", "description": "Reviews Physical Access", index: getIndex() },
			"cc6.5.1": { "name": "", "description": "Identifies Data and Software for Disposal", index: getIndex() },
			"cc6.5.2": { "name": "", "description": "Removes Data and Software From Entity Control", index: getIndex() },
			"cc6.8.1": { "name": "", "description": "Restricts Application and Software Installation", index: getIndex() },
			"cc6.8.2": { "name": "", "description": "Detects Unauthorized Changes to Software and Configuration Parameters", index: getIndex() },
			"cc6.8.3": { "name": "", "description": "Uses a Defined Change Control Process", index: getIndex() },
			"cc6.8.4": { "name": "", "description": "Uses Antivirus and Anti-Malware Software", index: getIndex() },
			"cc6.8.5": { "name": "", "description": "Scans Information Assets from Outside the Entity for Malware and Other Unauthorized", index: getIndex() },
			"cc7.1.1": { "name": "", "description": "Uses Defined Configuration Standards", index: getIndex() },
			"cc7.1.2": { "name": "", "description": "Monitors Infrastructure and Software", index: getIndex() },
			"cc7.1.3": { "name": "", "description": "Implements Change-Detection Mechanisms", index: getIndex() },
			"cc7.1.4": { "name": "", "description": "Detects Unknown or Unauthorized Components", index: getIndex() },
			"cc7.1.5": { "name": "", "description": "Conducts Vulnerability Scans", index: getIndex() },
			"cc7.2.1": { "name": "", "description": "Implements Detection Policies, Procedures, and Tools", index: getIndex() },
			"cc7.2.2": { "name": "", "description": "Designs Detection Measures", index: getIndex() },
			"cc7.2.3": { "name": "", "description": "Implements Filters to Analyze Anomalies", index: getIndex() },
			"cc7.2.4": { "name": "", "description": "Monitors Detection Tools for Effective Operation", index: getIndex() },
			"cc7.3.1": { "name": "", "description": "Responds to Security Incidents", index: getIndex() },
			"cc7.3.2": { "name": "", "description": "Communicates and Reviews Detected Security Events", index: getIndex() },
			"cc7.3.3": { "name": "", "description": "Develops and Implements Procedures to Analyze Security Incidents", index: getIndex() },
			"cc7.3.4": { "name": "", "description": "Assesses the Impact on Personal Information", index: getIndex() },
			"cc7.4.1": { "name": "", "description": "Assigns Roles and Responsibilities", index: getIndex() },
			"cc7.4.2": { "name": "", "description": "Contains Security Incidents", index: getIndex() },
			"cc7.4.6": { "name": "", "description": "Develops and Implements Communication Protocols for Security Incidents", index: getIndex() },
			"cc7.4.7": { "name": "", "description": "Obtains Understanding of Nature of Incident and Determines Containment Strategy", index: getIndex() },
			"cc7.4.8": { "name": "", "description": "Remediates Identified Vulnerabilities", index: getIndex() },
			"cc7.4.9": { "name": "", "description": "Communicates Remediation Activities", index: getIndex() },
			"cc7.4.10": { "name": "", "description": "Evaluates the Effectiveness of Incident Response", index: getIndex() },
			"cc7.4.11": { "name": "", "description": "Periodically Evaluates Incidents", index: getIndex() },
			"cc7.5.2": { "name": "", "description": "Communicates Information About the Event", index: getIndex() },
			"cc7.5.3": { "name": "", "description": "Determines Root Cause of the Event", index: getIndex() },
			"cc7.5.4": { "name": "", "description": "Implements Changes to Prevent and Detect Recurrences", index: getIndex() },
			"cc7.5.5": { "name": "", "description": "Improves Response and Recovery Procedures", index: getIndex() },
			"cc7.5.6": { "name": "", "description": "Implements Incident-Recovery Plan Testing", index: getIndex() },
			"cc9.1.1": { "name": "", "description": "Considers Mitigation of Risks of Business Disruption", index: getIndex() },
			"cc9.2.1": { "name": "", "description": "Establishes Requirements for Vendor and Business Partner Engagements", index: getIndex() },
			"cc9.2.2": { "name": "", "description": "Assesses Vendor and Business Partner Risks", index: getIndex() },
			"cc9.2.3": { "name": "", "description": "Assigns Responsibility and Accountability for Managing Vendors and Business", index: getIndex() },
			"cc9.2.4": { "name": "", "description": "Establishes Communication Protocols for Vendors and Business Partners", index: getIndex() },
			"cc9.2.5": { "name": "", "description": "Establishes Exception Handling Procedures From Vendors and Business Partners", index: getIndex() },
			"cc9.2.6": { "name": "", "description": "Assesses Vendor and Business Partner Performance", index: getIndex() },
			"cc9.2.7": { "name": "", "description": "Implements Procedures for Addressing Issues Identified During Vendor and Business", index: getIndex() },
			"cc9.2.9": { "name": "", "description": "Obtains Confidentiality Commitments from Vendors and Business Partners", index: getIndex() },
			"cc9.2.10": { "name": "", "description": "Assesses Compliance With Confidentiality Commitments of Vendors and Business Partners", index: getIndex() },
		}
	},
	"headers": [{
		"id": 1,
		"value": "Function",
		"classList": "dont-print col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 2,
		"value": "Category",
		"classList": "col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 3,
		"value": "Subcategory",
		"classList": "print-sub-cat-col col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 4,
		"value": "Result",
		"classList": "print-result-col col col-md-2 text-md-center",
		"style": {
			"marginLeft": "-1rem"
		}
	},
	{
		"id": 5,
		"value": "Severity",
		"classList": "col col-md-1 text-md-left",
		"style": {
			"marginLeft": "3rem"
		}
	},
	{
		"id": 6,
		"value": "Evidence",
		"classList": "col col-md-1 text-md-center",
		"style": {
			"marginLeft": "6rem"
		}
	}
	],
	"childTestCases": {
		"fn-LogicalControls": {
			"id": "fn-LogicalControls",
			"value": "Logical & Physical Access Controls",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": ["Logical & Physical Access Controls"],
			"categories": ["cc6.1", "cc6.2", "cc6.3", "cc6.4", "cc6.5", "cc6.6", "cc6.7", "cc6.8"]
		},
		"fn-ChangeManagement": {
			"id": "fn-ChangeManagement",
			"value": "Change Management",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": ["Change Management"],
			"categories": ["cc8.1"]
		},
		"fn-Availability": {
			"id": "fn-Availability",
			"value": "Availability",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": ["Availability"],
			"categories": ["a1.1", "a1.2", "a1.3"]
		},
		"fn-Confidentiality": {
			"id": "fn-Confidentiality",
			"value": "Confidentiality",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": ["Confidentiality"],
			"categories": ["c1.1", "c1.2"]
		},
		"fn-processingIntegrity": {
			"id": "fn-processingIntegrity",
			"value": "Processing Integrity",
			"fgColor": "#e9ecef",
			"bgColor": "#00b33c",
			"description": ["Processing Integrity"],
			"categories": ["pi1.2", "pi1.3", "pi1.4", "pi1.5"]
		},
		"fn-privacy": {
			"id": "fn-privacy",
			"value": "Privacy",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": ["Privacy"],
			"categories": ["p5.1", "p5.2", "p8.1"]
		},
		"fn-systemoperations": {
			"id": "fn-systemoperations",
			"value": "System Operations",
			"fgColor": "#e9ecef",
			"bgColor": "#00b33c",
			"description": ["System Operations"],
			"categories": ["cc7.1", "cc7.2", "cc7.3", "cc7.4", "cc7.5"]
		},
		"fn-riskmitigation": {
			"id": "fn-riskmitigation",
			"value": "LRisk Mitigation",
			"fgColor": "#e9ecef",
			"bgColor": "#e60000",
			"description": ["Risk Mitigation"],
			"categories": ["cc9.1", "cc9.2"]
		}
	},
	"testCases": [
		{
			"id": "fn-supplementalCriteria",
			"value": "Supplemental Criteria",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"children": ["fn-LogicalControls", "fn-systemoperations", "fn-ChangeManagement", "fn-riskmitigation"]
		},
		{
			"id": "fn-additionalCriteria",
			"value": "Additional Criteria",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"children": ["fn-Availability", "fn-Confidentiality", "fn-processingIntegrity", "fn-privacy"]
		}
	]
};

export default AwsTestsNIST;