import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoSvg } from '../assets/img/logo.svg';
import robot from '../assets/img/robotMess.png'
const Auth = ({ children }) => {
	const { t } = useTranslation();

	return (
		<main>
			<Row className="h-100 m-0">
				<Col className="auth-side ">
					<div className="auth-side__content ">
						<div className="logo-wrap ">
							<LogoSvg className="auth-side__logo" />
							<div className="w-50">
								<h1 className="m-0">{t('mainProjectName')}</h1>
							</div>
						</div>
						<div className='TextWrapperAi'>
						<h2 className='aiTitle'>AI Cybersecurity <br/> Assistant </h2>
						<p className='iatext'>for connected medical devices</p>

						</div>
						<div className='imageRobot'>
							<img src={robot}/>
						</div>
					</div>
					<div className="bottom-info ">
						<p className="bottom-text ">© 2024 - Tala Secure, Inc.</p>
						<a href="mailto:contact@talasecure.com" className="bottom-textContact ">
							Contact us
						</a>
					</div>
				</Col>
				<Col className="p-0 overflow-auto" style={{ height: '100vh' }}>
					<div className="align-middle auth-content">{children}</div>
				</Col>
			</Row>
		</main>
	);
};

export default Auth;
