import * as types from '../constants';

const initialState = {
	user: null,
	isAuthenticated: null,
};

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.SET_USER:
			return {
				...state,
				user: actions.payload,
			};
		case types.SET_AUTHENTICATED: {
			return {
				...state,
				isAuthenticated: actions.payload,
			};
		}

		default:
			return state;
	}
}
