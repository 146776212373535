import {
	resolveRansomwareIssue,
	unresolveCompliance,
} from '../../../apiclients/Compliance';
import { useEffect, useState } from 'react';

import { getTruncatedResourceName } from '../Common/RansomwareUtilites';

const ResolutionType = {
	none: 0,
	success: 1,
	failure: 2,
};

const addressIssue = async (value, fn) => {
	let failCount = 0;

	for (let index = 0; index < value.SecurityTestRule.length; index++) {
		const rule = value.SecurityTestRule[index];
		const issue = { ...value, SecurityTestRule: rule };

		const result = await fn(issue);

		if (!result || !result.success) {
			failCount++;
		}
	}

	return { success: failCount === 0 };
};

const getMessages = (isFix, value) => {
	const rt = value.ResourceType;
	const rn = getTruncatedResourceName(value.ResourceName);

	if (isFix) {
		return {
			startMsg: `Attempting to fix: ${rt} - ${rn}`,
			failMsg: `Resource ${rt} - ${rn} is locked and can't be fixed.`,
			actionMsg: `Fixed: ${rt} - ${rn}`,
		};
	} else {
		return {
			startMsg: `Attempting to rollback: ${rt} - ${rn}`,
			failMsg: `Resource ${rt} - ${rn} is locked and can't be rolled back.`,
			actionMsg: `Rollback successful: ${rt} - ${rn}`,
		};
	}
};

const useResourceRow = ({
	item,
	type,
	showAll,
	index,
	fixStatus,
	saveRollback,
	saveFix,
	logs,
	saveLogs,
}) => {
	const [show, setShow] = useState(false);
	const [fixed, setFixed] = useState(0);
	const [notFixed, setNotFixed] = useState(0);
	const [rollback, setRollback] = useState(0);
	const [fixAttempted, setfixAttempted] = useState(false);
	const [busy, setBusy] = useState(false);
	const [logEvents, setLogEvents] = useState([]);
	const [selectAll, onSelectAll] = useState(false);
	const [noSelection, setNoSelection] = useState(false);

	useEffect(() => setShow(showAll), [showAll]);

	useEffect(() => setShow(index === 0), [index]);

	useEffect(() => {
		if (selectAll) {
			setShow(true);
		}
	}, [selectAll]);

	useEffect(() => {
		if (logs) {
			logs.forEach(l => onLogEvent(l.logEvent, l.type));
		}
	}, []);

	const resources = new Map();

	const onLogEvent = (logEvent, type) => {
		setLogEvents(v => [{ value: `${v.length + 1} : ` + logEvent, type }, ...v]);
	};

	const onCopyLog = () =>
		navigator.clipboard.writeText(logEvents.map(v => v.value).join('\r\n'));

	const onResourceSelected = (selected, item) => {
		if (selected) {
			resources.set(item.ResourceName, item);
		}
		if (!selected && resources.has(item.ResourceName)) {
			resources.delete(item.ResourceName);
		}
	};

	useEffect(() => {
		const setFixedUnfixed = () => {
			const resources = fixStatus.resources;
			const filterFn = f =>
				item.find(i => i.ResourceName === f.ResourceName) !== undefined;
			const fix = resources.fixed.filter(filterFn).length;
			const rollback = resources.rollback.filter(filterFn).length;
			const unfix = item.length - fix;
			setFixed(fix);
			setNotFixed(unfix);
			setRollback(rollback);
		};

		setFixedUnfixed();
	}, [fixStatus, item]);

	const onCloseNoSelection = () => setNoSelection(false);

	const reset = () => {
		setfixAttempted(true);
		resources.clear();
		//calling with true and then false clears the checkboxes
		onSelectAll(true);
		onSelectAll(false);
		setBusy(false);
	};

	const onIssueAction = async isFix => {
		if (resources.size < 1) {
			setNoSelection(true);
			return; //do nothing if we have nothing
		}

		const issueFn = isFix ? resolveRansomwareIssue : unresolveCompliance;
		const saveFn = isFix ? saveFix : saveRollback;

		setBusy(true);

		let notFixedCtr = 0;
		let fixedCtr = 0;

		setNotFixed(notFixedCtr); //reset the not fixed counter

		for (const [, value] of resources.entries()) {
			const result = await addressIssue(value, issueFn);
			const { startMsg, actionMsg, failMsg } = getMessages(isFix, value);

			onLogEvent(startMsg, ResolutionType.none);

			if (!result || !result.success) {
				setNotFixed(++notFixedCtr);
				onLogEvent(failMsg, ResolutionType.failure);
				saveLogs({ logEvent: failMsg, type: ResolutionType.failure }, type);
			} else {
				setFixed(++fixedCtr);
				onLogEvent(actionMsg, ResolutionType.success);
				saveFn(value);
				saveLogs({ logEvent: actionMsg, type: ResolutionType.success }, type);
			}
		}

		reset();
	};

	const onRollback = async () => await onIssueAction(false);
	const onFix = async () => await onIssueAction(true);
	const toggleSelect = value => onSelectAll(value);

	return {
		show,
		fixed,
		notFixed,
		rollback,
		fixAttempted,
		busy,
		logEvents,
		selectAll,
		setShow,
		noSelection,
		onFix,
		onCopyLog,
		onResourceSelected,
		onRollback,
		toggleSelect,
		onCloseNoSelection,
	};
};

export { useResourceRow, ResolutionType };
