let index = 0;

const getIndex = () => index++;

export default {
	"categories": {
	 "164.308(a)(2)": {
		"parent": "fn-adminSafeguards",
		"name": "Assign Security Responsibility - 164.308(a)(2)",
		"description": "Practices are required to identify a security official who is responsible for the development and implementation of the policies and procedures required by HIPAA Security Rule.",
		"subcategories": [
		 "164.308(a)(2)"
		]
	 },
	 "164.308(a)(1)(ii)(A)": {
		"parent": "fn-adminSafeguards",
		"name": "Risk Analysis - 164.308(a)(1)(ii)(A)",
		"description": "Practices are required to conduct an assessment of the potential risks and vulnerabilities to the confidentiality, integrity, and availability of ePHI.  This process is intended to identify current security risks.",
		"subcategories": [
		 "164.308(a)(1)(ii)(A)_1",
		 "164.308(a)(1)(ii)(A)_2",
		 "164.308(a)(1)(ii)(A)_3"
		]
	 },
	 "164.308(a)(1)(ii)(B)": {
		"parent": "fn-adminSafeguards",
		"name": "Risk Management - 164.308(a)(1)(ii)(B)",
		"description": "Practices are required to implement security measures sufficient to reduce risks and vulnerabilities identified during the risk analysis and to stay compliant with HIPAA security standards.  This process is intended to ensure ongoing control of security risks.",
		"subcategories": [
		 "164.308(a)(1)(ii)(B)_1",
		 "164.308(a)(1)(ii)(B)_2"
		]
	 },
	 "164.308(a)(1)(ii)(C)": {
		"parent": "fn-adminSafeguards",
		"name": "Sanction Policy - 164.308(a)(1)(ii)(C)",
		"description": "A practice is required to apply appropriate sanctions against employees who fail to comply with the practice’s security policies and procedures.",
		"subcategories": [
		 "164.308(a)(1)(ii)(C)_1",
		 "164.308(a)(1)(ii)(C)_2"
		]
	 },
	 "164.308(a)(3)(i)": {
		"parent": "fn-adminSafeguards",
		"name": "Authorization and/or Supervision - 164.308(a)(3)(i)",
		"description": "Practices should implement procedures for the authorization and/or supervision of employees who work with ePHI or in locations where it might be accessed.",
		"subcategories": [
		 "164.308(a)(3)(i)_1",
		 "164.308(a)(3)(i)_2"
		]
	 },
	 "164.308(a)(3)(ii)(B)": {
		"parent": "fn-adminSafeguards",
		"name": "Workforce Clearance Procedures - 164.308(a)(3)(ii)(B)",
		"description": "Practices should implement procedures for the authorization and/or supervision of employees who work with ePHI or in locations where it might be accessed.",
		"subcategories": [
		 "164.308(a)(3)(ii)(B)_1"
		]
	 },
	 "164.308(a)(3)(ii)(C)": {
		"parent": "fn-adminSafeguards",
		"name": "Implement procedures for terminating access to ePHI when the employment of a workforce member ends. - 164.308(a)(3)(ii)(C)",
		"description": "The practice has a formal process for screening job candidates and conducting background checks as part of the hiring process.",
		"subcategories": [
		 "164.308(a)(3)(ii)(C)_1",
		 "164.308(a)(3)(ii)(C)_2",
		 "164.308(a)(3)(ii)(C)_3",
		 "164.308(a)(3)(ii)(C)_4",
		 "164.308(a)(3)(ii)(C)_5"
		]
	 },
	 "164.308(a)(5)(i)": {
		"parent": "fn-adminSafeguards",
		"name": "Security Training and Reminders - 164.308(a)(5)(i)",
		"description": "Implement periodic reminders of security and information safety best practices.",
		"subcategories": [
		 "164.308(a)(5)(i)_1",
		 "164.308(a)(5)(i)_2",
		 "164.308(a)(5)(i)_3",
		 "164.308(a)(5)(i)_4"
		]
	 },
	 "164.308(a)(7)(i)": {
		"parent": "fn-adminSafeguards",
		"name": "Testing and Revision Procedures - 164.308(a)(7)(i)",
		"description": "Practices should implement procedures for periodic testing and revision of contingency plans.",
		"subcategories": [
		 "164.308(a)(7)(i)_1",
		 "164.308(a)(7)(i)_2",
		 "164.308(a)(7)(i)_3",
		 "164.308(a)(7)(i)_4",
		 "164.308(a)(7)(i)_5"
		]
	 },
	 "164.308(a)(8)": {
		"parent": "fn-adminSafeguards",
		"name": "Evaluation Policy - 164.308(a)(8)",
		"description": "Perform periodic technical & nontechnical evaluations, to establish how well security policy and procedures meet the requirements of this subpart.",
		"subcategories": [
		 "164.308(a)(8)_1"
		]
	 },
	 "164.308(b)(4)": {
		"parent": "fn-adminSafeguards",
		"name": "Written Contract or Other Arrangements - 164.308(b)(4)",
		"description": "Satisfactory assurances required by the business associate contract standard are documented in a written contract or other arrangement that allows business associates PHI access and confidential access of the practice.",
		"subcategories": [
		 "164.308(b)(4)_1"
		]
	 },
	 "164.314(i)": {
		"parent": "fn-physicalSafeguards",
		"name": "Contingency Operations Procedures - 164.314(i)",
		"description": "Establish (and implement as needed) procedures that ensure facility access to support restoration of lost data in the event of an emergency.",
		"subcategories": [
		 "164.314(i)_1"
		]
	 },
	 "164.310(a)(1)": {
		"parent": "fn-physicalSafeguards",
		"name": "Contingency Operations Procedures - 164.310(a)(1)",
		"description": "Establish (and implement as needed) procedures that ensure facility access to support restoration of lost data in the event of an emergency.",
		"subcategories": [
		 "164.310(a)(1)_1",
		 "164.310(a)(1)_2"
		]
	 },
	 "164.310(a)(2)(iv)": {
		"parent": "fn-physicalSafeguards",
		"name": "Maintenance Records - 164.310(a)(2)(iv)",
		"description": "A practice is required to document repairs and modifications to the physical components of its facility which are related to security.",
		"subcategories": [
		 "164.310(a)(2)(iv)_1"
		]
	 },
	 "164.310(a)(2)(ii)": {
		"parent": "fn-physicalSafeguards",
		"name": "Facility Security Plan - 164.310(a)(2)(ii)",
		"description": "A practice should have policies and procedures to safeguard the facility and their equipment from unauthorized physical access, tampering, and theft.",
		"subcategories": [
		 "164.310(a)(2)(ii)_1",
		 "164.310(a)(2)(ii)_2",
		 "164.310(a)(2)(ii)_3",
		 "164.310(a)(2)(ii)_4",
		 "164.310(a)(2)(ii)_5",
		 "164.310(a)(2)(ii)_6",
		 "164.310(a)(2)(ii)_7",
		 "164.310(a)(2)(ii)_8"
		]
	 },
	 "164.310(a)(2)(iii)": {
		"parent": "fn-physicalSafeguards",
		"name": "Access Control and Validation Procedures - 164.310(a)(2)(iii)",
		"description": "A practice should have procedures to control and validate individual access to facilities based on role or function; including visitor control, and access control for software testing and revision.",
		"subcategories": [
		 "164.310(a)(2)(iii)_1",
		 "164.310(a)(2)(iii)_2",
		 "164.310(a)(2)(iii)_3"
		]
	 },
	 "164.310(b)": {
		"parent": "fn-physicalSafeguards",
		"name": "Workstation location - 164.310(b)",
		"description": "The practice should have guidelines and policies in place to ensure appropriate use of workstations located throughout the practice including in private office areas.",
		"subcategories": [
		 "164.310(b)_1",
		 "164.310(b)_2",
		 "164.310(b)_3",
		 "164.310(b)_4",
		 "164.310(b)_5"
		]
	 },
	 "164.310(c)": {
		"parent": "fn-physicalSafeguards",
		"name": "Workstation location - 164.310(c)",
		"description": "The practice should have guidelines and policies in place to ensure appropriate use of workstations located throughout the practice including in private office areas.",
		"subcategories": [
		 "164.310(c)_1"
		]
	 },
	 "164.310(d)(2)(i),(ii)": {
		"parent": "fn-physicalSafeguards",
		"name": "Media Disposal & Disposition or Re- use - 164.310(d)(2)(i),(ii)",
		"description": "The practice has policies and procedures for removing ePHI from hardware or electronic media on which it is stored prior to disposal or re-use.",
		"subcategories": [
		 "164.310(d)(2)(i),(ii)_1",
		 "164.310(d)(2)(i),(ii)_2"
		]
	 },
	 "164.310(d) (2)(iii)": {
		"parent": "fn-physicalSafeguards",
		"name": "Hardware & Media Accountability - 164.310(d) (2)(iii)",
		"description": "The practice is required to maintain records of the movements of hardware and electronic media, and any person responsible therefore.",
		"subcategories": [
		 "164.310(d) (2)(iii)_1",
		 "164.310(d) (2)(iii)_2",
		 "164.310(d) (2)(iii)_3",
		 "164.310(d) (2)(iii)_4"
		]
	 },
	 "164.312(a)(1)": {
		"parent": "fn-technicalSafeguards",
		"name": "General Specifications regarding email use. - 164.312(a)(1)",
		"description": "This e-mail, including attachments, may include confidential and/or proprietary information, and may be used only by the person or entity to which it is addressed. If the reader of this e mail is not the intended recipient or his or her authorized agent, the reader is hereby notified that any dissemination, distribution or copying of this e- mail is prohibited. If you have received this e-mail in error, please notify the sender by replying to this message and delete this e-mail immediately.",
		"subcategories": [
		 "164.312(a)(1)_1",
		 "164.312(a)(1)_2",
		 "164.312(a)(1)_3",
		 "164.312(a)(1)_4"
		]
	 },
	 "164.312(a)(2)(ii)": {
		"parent": "fn-technicalSafeguards",
		"name": "Emergency Access Procedure - 164.312(a)(2)(ii)",
		"description": "Establish (and implement as needed) procedures for obtaining necessary ePHI during an emergency.",
		"subcategories": [
		 "164.312(a)(2)(ii)_1"
		]
	 },
	 "164.312(a)(2)(iii)": {
		"parent": "fn-technicalSafeguards",
		"name": "Automatic Logoff - 164.312(a)(2)(iii)",
		"description": "Implement electronic procedures that terminate an electronic session after a predetermined time of inactivity.",
		"subcategories": [
		 "164.312(a)(2)(iii)_1"
		]
	 },
	 "164.312(a)(2)(iv)": {
		"parent": "fn-technicalSafeguards",
		"name": "Encryption and Decryption - 164.312(a)(2)(iv)",
		"description": "Implement an appropriate mechanism to encrypt and decrypt ePHI.",
		"subcategories": [
		 "164.312(a)(2)(iv)_1"
		]
	 },
	 "164.312(e)(2)(i)": {
		"parent": "fn-technicalSafeguards",
		"name": "Integrity Controls - 164.312(e)(2)(i)",
		"description": "Implement security measures to ensure that electronically transmitted ePHI is not improperly modified without detection until disposed of.",
		"subcategories": [
		 "164.312(e)(2)(i)_1"
		]
	 },
	 "164.404": {
		"parent": "fn-breachNotification",
		"name": "General Rule - 164.404",
		"description": "Under HIPAA a breach is considered to have occurred when there is a disclosure or use of “unsecured” protected health information that poses a significant risk of financial, reputational or other harm to the affected individual. Unsecured PHI means that the information has not been rendered unusable, unreadable, or indecipherable to someone who has accessed it without authorization.",
		"subcategories": [
		 "164.404_1",
		 "164.404_2",
		 "164.404_3",
		 "164.404_4"
		]
	 }
	},
	"subcategories": {
	 "164.308(a)(2)": {
		"parent": "164.308(a)(2)",
		"description": "The practice has designated a Privacy Security Officer and has appropriate job description and duties documented. (This can be the same person as the HIPAA Compliance Officer).",
		"index": getIndex()
	 },
	 "164.308(a)(1)(ii)(A)_1": {
		"parent": "164.308(a)(1)(ii)(A)",
		"description": "Identify, assess and plan to mitigate potential risks and vulnerabilities to confidentiality of ePHI",
		"index": getIndex()
	 },
	 "164.308(a)(1)(ii)(A)_2": {
		"parent": "164.308(a)(2)",
		"description": "Identify, assess and plan to mitigate potential risks and vulnerabilities to integrity of ePHI",
		"index": getIndex()
	 },
	 "164.308(a)(1)(ii)(A)_3": {
		"parent": "164.308(a)(2)",
		"description": "Identify, assess and plan to mitigate potential risks and vulnerabilities to availability of ePHI",
		"index": getIndex()
	 },
	 "164.308(a)(1)(ii)(B)_1": {
		"parent": "164.308(a)(1)(ii)(B)",
		"description": "Continuous monitoring and mitigation of potential risks and vulnerabilities to confidentiality of ePHI",
		"index": getIndex()
	 },
	 "164.308(a)(1)(ii)(B)_2": {
		"parent": "164.308(a)(1)(ii)(B)",
		"description": "Continuous monitoring and mitigation of potential risks and vulnerabilities to integrity of ePHI",
		"index": getIndex()
	 },
	 "164.308(a)(1)(ii)(C)_1": {
		"parent": "164.308(a)(1)(ii)(C)",
		"description": "The practice has a formal, documented disciplinary policy.",
		"index": getIndex()
	 },
	 "164.308(a)(1)(ii)(C)_2": {
		"parent": "164.308(a)(1)(ii)(C)",
		"description": "Any disciplinary action taken is documented and maintained in the employees file.",
		"index": getIndex()
	 },
	 "164.308(a)(3)(i)_1": {
		"parent": "164.308(a)(3)(i)",
		"description": "Staff members not authorized to access ePHI are supervised when they have an opportunity to obtain or access such information. (e.g. maintenance personnel working on computer).",
		"index": getIndex()
	 },
	 "164.308(a)(3)(i)_2": {
		"parent": "164.308(a)(3)(i)",
		"description": "All employees have signed a confidentiality statement.  The practice maintains a list.",
		"index": getIndex()
	 },
	 "164.308(a)(3)(ii)(B)_1": {
		"parent": "164.308(a)(3)(ii)(B)",
		"description": "The practice has a formal process for screening job candidates and conducting background checks as part of the hiring process.",
		"index": getIndex()
	 },
	 "164.308(a)(3)(ii)(C)_1": {
		"parent": "164.308(a)(3)(ii)(C)",
		"description": "The practice has developed and utilizes a termination check list which includes terminating IT system privileges.",
		"index": getIndex()
	 },
	 "164.308(a)(3)(ii)(C)_2": {
		"parent": "164.308(a)(3)(ii)(C)",
		"description": "Audit Check:  Review termed employee and verify system privileges have been revoked.",
		"index": getIndex()
	 },
	 "164.308(a)(3)(ii)(C)_3": {
		"parent": "164.308(a)(3)(ii)(C)",
		"description": "A process is in place for disabling an employee’s password and access privileges upon termination. This should occur immediately upon notifying the employee of termination.",
		"index": getIndex()
	 },
	 "164.308(a)(3)(ii)(C)_4": {
		"parent": "164.308(a)(3)(ii)(C)",
		"description": "Return of any related equipment, keys, security badges, PDAs is tracked and logged as part of the termination process.",
		"index": getIndex()
	 },
	 "164.308(a)(3)(ii)(C)_5": {
		"parent": "164.308(a)(3)(ii)(C)",
		"description": "A process is in place to ensure employees upon termination do not retain or remove from the clinic any information, computer files or equipment belonging to the clinic.  For example escorting terminated employees out of the practice.",
		"index": getIndex()
	 },
	 "164.308(a)(5)(i)_1": {
		"parent": "164.308(a)(5)(i)",
		"description": "The practice has a formal training program regarding HIPAA security rules.",
		"index": getIndex()
	 },
	 "164.308(a)(5)(i)_2": {
		"parent": "164.308(a)(5)(i)",
		"description": "All employees have received formal training to understand and meet the provisions of the Security Rule (documented training log).",
		"index": getIndex()
	 },
	 "164.308(a)(5)(i)_3": {
		"parent": "164.308(a)(5)(i)",
		"description": "The practice provides periodic updates and reminders to employees through memos, emails or signs in the practice.",
		"index": getIndex()
	 },
	 "164.308(a)(5)(i)_4": {
		"parent": "164.308(a)(5)(i)",
		"description": "A mechanism is in place to notify employees of any changes to IT systems or updates to security policy and procedures.  All updates should be documented / dated.",
		"index": getIndex()
	 },
	 "164.308(a)(7)(i)_1": {
		"parent": "164.308(a)(7)(i)",
		"description": "The practices’ contingency plan has been reviewed and tested within the last 12 months, and updated (if applicable).",
		"index": getIndex()
	 },
	 "164.308(a)(7)(i)_2": {
		"parent": "164.308(a)(7)(i)",
		"description": "Back up data has been tested to ensure accuracy of data and information and that it can be successfully restored / retrieved.",
		"index": getIndex()
	 },
	 "164.308(a)(7)(i)_3": {
		"parent": "164.308(a)(7)(i)",
		"description": "Emergency power supplies are tested on a routine basis.",
		"index": getIndex()
	 },
	 "164.308(a)(7)(i)_4": {
		"parent": "164.308(a)(7)(i)",
		"description": "Fire alarms and suppression equipment has been tested to confirm they function properly.",
		"index": getIndex()
	 },
	 "164.308(a)(7)(i)_5": {
		"parent": "164.308(a)(7)(i)",
		"description": "Staff has reviewed the contingency plan including roles and responsibilities within the last 12 months.",
		"index": getIndex()
	 },
	 "164.308(a)(8)_1": {
		"parent": "164.308(a)(8)",
		"description": "The practice’s compliance with security standards and implementation specifications has been evaluated within the last 12 months.",
		"index": getIndex()
	 },
	 "164.308(b)(4)_1": {
		"parent": "164.308(b)(4)",
		"description": "The practice has identified all individuals or entities that are business associates and required them to sign a business associate agreement.",
		"index": getIndex()
	 },
	 "164.314(i)_1": {
		"parent": "164.314(i)",
		"description": "Staff responsible for implementing contingency plans can physically obtain back-up data sets.",
		"index": getIndex()
	 },
	 "164.314(i)_2": {
		"parent": "164.314(i)",
		"description": "Business associate agreements contain appropriate assurances and termination provisions.",
		"index": getIndex()
	 },
	 "164.310(a)(1)_1": {
		"parent": "164.310(a)(1)",
		"description": "The contract known as the business associate agreement between a practice and its business associate must contain the required information prescribed by the HIPAA Security Rules. If a covered entity is aware or suspects that a business associate is in material breach of the business associate's obligation under the contract, they must take reasonable steps to cure the breach or end the violation. This may involve: \t1. Terminating the contract with the business associate, if feasible. 2. If termination is not feasible, report the problem to the Secretary.",
		"index": getIndex()
	 },
	 "164.310(a)(1)_2": {
		"parent": "164.310(a)(1)",
		"description": "Staff responsible for implementing contingency plans can physically obtain back-up data sets.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(iv)_1": {
		"parent": "164.310(a)(2)(iv)",
		"description": "All repairs and/or modifications made to the building related to security are documented.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_1": {
		"parent": "164.310(a)(2)(ii)",
		"description": "Records or computer equipment other than workstations are kept in locked areas or cabinets.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_2": {
		"parent": "164.310(a)(2)(ii)",
		"description": "Only staff members authorized to use or maintain IT equipment or servers has access to secure areas (e.g. keys to locked areas are only issued to authorized individuals).",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_3": {
		"parent": "164.310(a)(2)(ii)",
		"description": "Back-up media stored off-site are stored in a manner that prevents physical access by anyone lacking proper authorization.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_4": {
		"parent": "164.310(a)(2)(ii)",
		"description": "Contractors and maintenance personnel who are not members of the staff have signed a business associate agreement.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_5": {
		"parent": "164.310(a)(2)(ii)",
		"description": "Contractors and maintenance personnel are given a unique user ID and password that enables the practice to monitor their access to the medical practice's IT resources.  Ideally the system should be able to create a one-time access password.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_6": {
		"parent": "164.310(a)(2)(ii)",
		"description": "Before a user ID is activated, the security official reviews with the contractor the practice's security policies and procedures and the provisions of the business associate agreement related to security.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_7": {
		"parent": "164.310(a)(2)(ii)",
		"description": "The practice has appropriate fire suppression systems in place that are compliant with all safety and building codes.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(ii)_8": {
		"parent": "164.310(a)(2)(ii)",
		"description": "The practice has appropriate security alarm or surveillance systems in place.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(iii)_1": {
		"parent": "164.310(a)(2)(iii)",
		"description": "All visitors to the medical practice register with the receptionist and sign a visitor log and are required to wear a visitor’s badge.",
		"index": getIndex()
	 },
	 "164.310(a)(2)(iii)_2": {
		"parent": "164.310(a)(2)(iii)",
		"description": "Visitors to the medical practice are not left alone (except in public waiting areas).",
		"index": getIndex()
	 },
	 "164.310(a)(2)(iii)_3": {
		"parent": "164.310(a)(2)(iii)",
		"description": "Visitors to the medical practice are not allowed to roam unaccompanied by a staff member.",
		"index": getIndex()
	 },
	 "164.310(b)_1": {
		"parent": "164.310(b)",
		"description": "Work stations located in common but non-public areas are not used to perform security related administrative functions (e.g. adjusting access rights).",
		"index": getIndex()
	 },
	 "164.310(b)_2": {
		"parent": "164.310(b)",
		"description": "Workstations are set up to restrict the functions it can perform based on the level of permissions assigned to each user.",
		"index": getIndex()
	 },
	 "164.310(b)_3": {
		"parent": "164.310(b)",
		"description": "Users are required to log off all workstations rather than leaving them unattended. This includes workstations in private offices.",
		"index": getIndex()
	 },
	 "164.310(b)_4": {
		"parent": "164.310(b)",
		"description": "All workstations and monitors are positioned so that they are visible only to the persons who use them or the practice uses privacy screens.",
		"index": getIndex()
	 },
	 "164.310(b)_5": {
		"parent": "164.310(b)",
		"description": "Workstation areas are kept clean and well organized.  Paper or confidential material is securely kept.",
		"index": getIndex()
	 },
	 "164.310(c)_1": {
		"parent": "164.310(c)",
		"description": "Workstations are located in physically secure areas where it is not vulnerable to theft or unauthorized removal from the office.",
		"index": getIndex()
	 },
	 "164.310(d)(2)(i),(ii)_1": {
		"parent": "164.310(d)(2)(i),(ii)",
		"description": "The practice has process for erasing or purging ePHI on equipment and other media that is either going to be re-used, recycled or disposed of.",
		"index": getIndex()
	 },
	 "164.310(d)(2)(i),(ii)_2": {
		"parent": "164.310(d)(2)(i),(ii)",
		"description": "The Security Officer checks all media or equipment to ensure ePHI has been properly removed prior to any re-use or disposal.",
		"index": getIndex()
	 },
	 "164.310(d) (2)(iii)_1": {
		"parent": "164.310(d) (2)(iii)",
		"description": "The practice maintains an inventory of all equipment and property (e.g. fax, copiers, and computers) by location and person responsible for it.",
		"index": getIndex()
	 },
	 "164.310(d) (2)(iii)_2": {
		"parent": "164.310(d) (2)(iii)",
		"description": "Authorization forms and receipts are required for all major property or equipment transactions.",
		"index": getIndex()
	 },
	 "164.310(d) (2)(iii)_3": {
		"parent": "164.310(d) (2)(iii)",
		"description": "Any personal devices or laptop computers that are allowed to be removed from the clinic are properly managed / monitored.  Authorization is required before any ePHI can be downloaded unto these devices.",
		"index": getIndex()
	 },
	 "164.310(d) (2)(iii)_4": {
		"parent": "164.310(d) (2)(iii)",
		"description": "Fax machines are located in a secure or supervised area.",
		"index": getIndex()
	 },
	 "164.312(a)(1)_1": {
		"parent": "164.312(a)(1)",
		"description": "All emails sent from the practice contain a confidentiality / privacy statement.",
		"index": getIndex()
	 },
	 "164.312(a)(1)_2": {
		"parent": "164.312(a)(1)",
		"description": "Web-based email account such as (but not limited to) yahoo and hotmail are not allowed to be used for transmitting any type of ePHI.",
		"index": getIndex()
	 },
	 "164.312(a)(1)_3": {
		"parent": "164.312(a)(1)",
		"description": "The clinic has a policy restricting or minimizing the use of personal email accounts from work.",
		"index": getIndex()
	 },
	 "164.312(a)(1)_4": {
		"parent": "164.312(a)(1)",
		"description": "The clinic restricts the use of instant messaging, particularly, regarding any transmission of ePHI.",
		"index": getIndex()
	 },
	 "164.312(a)(2)(ii)_1": {
		"parent": "164.312(a)(2)(ii)",
		"description": "The practice has the ability to access user accounts and reset passwords in the event of an emergency.",
		"index": getIndex()
	 },
	 "164.312(a)(2)(iii)_1": {
		"parent": "164.312(a)(2)(iii)",
		"description": "Users are automatically logged off after a period of inactivity and required to log back into the system.",
		"index": getIndex()
	 },
	 "164.312(a)(2)(iv)_1": {
		"parent": "164.312(a)(2)(iv)",
		"description": "ePHI that is transmitted via email is encrypted.",
		"index": getIndex()
	 },
	 "164.312(e)(2)(i)_1": {
		"parent": "164.312(e)(2)(i)",
		"description": "The practice has implemented mechanisms that can be used to confirm ePHI has not been altered.",
		"index": getIndex()
	 },
	 "164.404_1": {
		"parent": "164.404",
		"description": "The practice has a policy and procedure in place outlining notification protocols in case of a breach related to protect health information.",
		"index": getIndex()
	 },
	 "164.404_2": {
		"parent": "164.404",
		"description": "The practice maintains on file all documented incidents using an Incident and Resolution Form.",
		"index": getIndex()
	 },
	 "164.404_3": {
		"parent": "164.404",
		"description": "The media was notified of any breaches entailing 500 or more individuals (if applicable).",
		"index": getIndex()
	 },
	 "164.404_4": {
		"parent": "164.404",
		"description": "The practice maintains a summary log of all documented incidents.  The log is used to provide the required report to HHS annually for all incidents involving less than 500 individuals.",
		"index": getIndex()
	 }
	},
	"testCases": [
	 {
		"id": "fn-adminSafeguards",
		"value": "Administrative Safeguards",
		"fgColor": "#e9ecef",
		"bgColor": "#4da6ff",
		"description": [
		 "Administrative Safeguards:  164.308"
		],
		"categories": [
		 "164.308(a)(2)",
		 "164.308(a)(1)(ii)(A)",
		 "164.308(a)(1)(ii)(B)",
		 "164.308(a)(1)(ii)(C)",
		 "164.308(a)(3)(i)",
		 "164.308(a)(3)(ii)(B)",
		 "164.308(a)(3)(ii)(C)",
		 "164.308(a)(5)(i)",
		 "164.308(a)(7)(i)",
		 "164.308(a)(8)",
		 "164.308(b)(4)",
		 "164.314(i)"
		]
	 },
	 {
		"id": "fn-physicalSafeguards",
		"value": "Physical Safeguards",
		"fgColor": "#e9ecef",
		"bgColor": "#a31aff",
		"description": [
		 "Physical Safeguards:  164.310"
		],
		"categories": [
		 "164.314(i)",
		 "164.310(a)(2)(ii)",
		 "164.310(a)(2)(iii)",
		 "164.310(a)(2)(iv)",
		 "164.310(b)",
		 "164.310(c)",
		 "164.310(d)(2)(i),(ii)",
		 "164.310(d) (2)(iii)",
		 "164.310(a)(1)"
		]
	 },
	 {
		"id": "fn-technicalSafeguards",
		"value": "Technical Safeguards",
		"fgColor": "#e9ecef",
		"bgColor": "#e60000",
		"description": [
		 "Technical Safeguards:  164.312"
		],
		"categories": [
		 "164.312(a)(1)",
		 "164.312(a)(2)(ii)",
		 "164.312(a)(2)(iii)",
		 "164.312(a)(2)(iv)",
		 "164.312(e)(2)(i)"
		]
	 },
	 {
		"id": "fn-breachNotification",
		"value": "Breach Notification",
		"fgColor": "#e9ecef",
		"bgColor": "#e60000",
		"description": [
		 "Breach Notification - 164.404"
		],
		"categories": [
		 "164.404"
		]
	 }
	]
 }