let index = 0;
const getIndex = () => index++;
export default {
	"testCases": [
		{
			"id": "fn-0",
			"value": "Secure Network",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				""
			],
			"children": [
				"fn-req-0",
				"fn-req-1"
			]
		},
		{
			"id": "fn-1",
			"value": "Secure Cardholder Data",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				""
			],
			"children": [
				"fn-req-2",
				"fn-req-3"
			]
		},
		{
			"id": "fn-2",
			"value": "Vulnerability Management",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				""
			],
			"children": [
				"fn-req-4",
				"fn-req-5"
			]
		},
		{
			"id": "fn-3",
			"value": "Access Control",
			"fgColor": "#e60000",
			"bgColor": "#4da6ff",
			"description": [
				""
			],
			"children": [
				"fn-req-6",
				"fn-req-7",
				"fn-req-8"
			]
		},
		{
			"id": "fn-4",
			"value": "Network Monitoring & Testing",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				""
			],
			"children": [
				"fn-req-9",
				"fn-req-10"
			]
		},
		{
			"id": "fn-5",
			"value": "Information Security",
			"fgColor": "#4d4d4d",
			"bgColor": "#a31aff",
			"description": [
				""
			],
			"children": [
				"fn-req-11"
			]
		},
		{
			"id": "fn-6",
			"value": "Appendix A1",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				""
			],
			"children": [
				"fn-req-12",
				"fn-req-13"
			]
		}
	],
	"childTestCases": {
		"fn-req-0": {
			"parent": "fn-0",
			"value": "Requirement 1",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				"Requirement 1 - Install and maintain a firewall configuration to protect cardholder data"
			],
			"categories": [
				"1.1",
				"1.4",
				"1.5"
			],
			"id": "fn-req-0"
		},
		"fn-req-1": {
			"parent": "fn-0",
			"value": "Requirement 2",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				"Requirement 2 - Do not use vendor-supplied defaults for system passwords and other security parameters"
			],
			"categories": [
				"2.2",
				"2.5",
				"2.6"
			],
			"id": "fn-req-1"
		},
		"fn-req-2": {
			"parent": "fn-1",
			"value": "Requirement 3",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 3 - Protect stored cardholder data"
			],
			"categories": [
				"3.1",
				"3.2",
				"3.3",
				"3.5",
				"3.6",
				"3.7"
			],
			"id": "fn-req-2"
		},
		"fn-req-3": {
			"parent": "fn-1",
			"value": "Requirement 4",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 4 - Encrypt transmission of cardholder data across open, public networks"
			],
			"categories": [
				"4.2",
				"4.3"
			],
			"id": "fn-req-3"
		},
		"fn-req-4": {
			"parent": "fn-2",
			"value": "Requirement 5",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 5 - Use and regularly update anti-virus software or programs"
			],
			"categories": [
				"5.1",
				"5.2",
				"5.3",
				"5.4"
			],
			"id": "fn-req-4"
		},
		"fn-req-5": {
			"parent": "fn-2",
			"value": "Requirement 6",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 6 - Develop and maintain secure systems and applications"
			],
			"categories": [
				"6.3",
				"6.4",
				"6.5",
				"6.6",
				"6.7"
			],
			"id": "fn-req-5"
		},
		"fn-req-6": {
			"parent": "fn-3",
			"value": "Requirement 7",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 7 - Restrict access to cardholder data by business need to know"
			],
			"categories": [
				"7.3"
			],
			"id": "fn-req-6"
		},
		"fn-req-7": {
			"parent": "fn-3",
			"value": "Requirement 8",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				"Requirement 8 - Assign a unique ID to each person with computer access"
			],
			"categories": [
				"8.1",
				"8.2",
				"8.4",
				"8.5",
				"8.6",
				"8.7",
				"8.8"
			],
			"id": "fn-req-7"
		},
		"fn-req-8": {
			"parent": "fn-3",
			"value": "Requirement 9",
			"fgColor": "#e9ecef",
			"bgColor": "#a31aff",
			"description": [
				"Requirement 9 - Restrict physical access to cardholder data"
			],
			"categories": [
				"9.1",
				"9.2",
				"9.3",
				"9.4",
				"9.5",
				"9.6",
				"9.7",
				"9.8",
				"9.9",
				"9.10"
			],
			"id": "fn-req-8"
		},
		"fn-req-9": {
			"parent": "fn-4",
			"value": "Requirement 10",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 10 - Track and monitor all access to network resources and cardholder data"
			],
			"categories": [
				"10.4",
				"10.5",
				"10.7",
				"10.8",
				"10.9"
			],
			"id": "fn-req-9"
		},
		"fn-req-10": {
			"parent": "fn-4",
			"value": "Requirement 11",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 11 - Regularly test security systems and processes"
			],
			"categories": [
				"11.1",
				"11.2",
				"11.3",
				"11.5",
				"11.6"
			],
			"id": "fn-req-10"
		},
		"fn-req-11": {
			"parent": "fn-5",
			"value": "Requirement 12",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Requirement 12 - Maintain a policy that addresses information security for all personnel"
			],
			"categories": [
				"12.1",
				"12.2",
				"12.3",
				"12.4",
				"12.5",
				"12.6",
				"12.7",
				"12.8",
				"12.10",
				"12.11"
			],
			"id": "fn-req-11"
		},
		"fn-req-12": {
			"parent": "fn-6",
			"value": "Appendix A1",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Appendix A1 - Additional PCI DSS Requirements for Shared Hosting Providers"
			],
			"categories": [
				"A1"
			],
			"id": "fn-req-12"
		},
		"fn-req-13": {
			"parent": "fn-6",
			"value": "Appendix A2",
			"fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
			"description": [
				"Appendix A2 - Additional PCI DSS Requirements for Entities using SSL/early TLS. Note"
			],
			"categories": [
				"A2.1",
				"A2.2",
				"A2.3"
			],
			"id": "fn-req-13"
		}
	},
	"categories": {
		"1.1": {
		 "parent": "fn-req-0",
		 "description": "Establish and implement firewall and router configuration standards that include the following",
		 "name": "1.1",
		 "subcategories": [
			"1.1.1",
			"1.1.2",
			"1.1.3",
			"1.1.4",
			"1.1.5",
			"1.1.6",
			"1.1.7"
		 ],
		 "id": "1.1"
		},
		"1.4": {
		 "parent": "fn-req-0",
		 "description": "Install personal firewall software or equivalent functionality on any portable computing devices (including company and/or employee-owned) that connect to the Internet when outside the network (for example, laptops used by employees), and which are also used to access the CDE. Firewall (or equivalent) configurations include",
		 "name": "1.4",
		 "subcategories": [
			"1.4.1"
		 ],
		 "id": "1.4"
		},
		"1.5": {
		 "parent": "fn-req-0",
		 "description": "Ensure that security policies and operational procedures for managing firewalls are documented, in use, and known to all affected parties.",
		 "name": "1.5",
		 "subcategories": [
			"1.5.1"
		 ],
		 "id": "1.5"
		},
		"2.2": {
		 "parent": "fn-req-1",
		 "description": "Develop configuration standards for all system components. Assure that these standards address all known security vulnerabilities and are consistent with industry-accepted system hardening standards. Sources of industry-accepted system hardening standards may include, but are not limited to",
		 "name": "2.2",
		 "subcategories": [
			"2.2.1",
			"2.2.3",
			"2.2.4",
			"2.2.5"
		 ],
		 "id": "2.2"
		},
		"2.5": {
		 "parent": "fn-req-1",
		 "description": "Ensure that security policies and operational procedures for managing vendor defaults and other security parameters are documented, in use, and known to all affected parties.",
		 "name": "2.5",
		 "subcategories": [
			"2.5.1"
		 ],
		 "id": "2.5"
		},
		"2.6": {
		 "parent": "fn-req-1",
		 "description": "Shared hosting providers must protect each entity’s hosted environment and cardholder data. These providers must meet specific requirements as detailed in Appendix A1",
		 "name": "2.6",
		 "subcategories": [
			"2.6.1"
		 ],
		 "id": "2.6"
		},
		"3.1": {
		 "parent": "fn-req-2",
		 "description": "Keep cardholder data storage to a minimum by implementing data retention and disposal policies, procedures and processes that include at least the following for all cardholder data (CHD) storage",
		 "name": "3.1",
		 "subcategories": [
			"3.1.1"
		 ],
		 "id": "3.1"
		},
		"3.2": {
		 "parent": "fn-req-2",
		 "description": "Do not store sensitive authentication data after authorization (even if encrypted). If sensitive authentication data is received, render all data unrecoverable upon completion of the authorization process. It is permissible for issuers and companies that support issuing services to store sensitive authentication data if",
		 "name": "3.2",
		 "subcategories": [
			"3.2.1",
			"3.2.2",
			"3.2.3"
		 ],
		 "id": "3.2"
		},
		"3.3": {
		 "parent": "fn-req-2",
		 "description": "Mask PAN when displayed (the first six and last four digits are the maximum number of digits to be displayed), such that only personnel with a legitimate business need can see more than the first six/last four digits of the PAN. Note",
		 "name": "3.3",
		 "subcategories": [
			"3.3.1"
		 ],
		 "id": "3.3"
		},
		"3.5": {
		 "parent": "fn-req-2",
		 "description": "Document and implement procedures to protect keys used to secure stored cardholder data against disclosure and misuse",
		 "name": "3.5",
		 "subcategories": [
			"3.5.1",
			"3.5.2",
			"3.5.3",
			"3.5.4"
		 ],
		 "id": "3.5"
		},
		"3.6": {
		 "parent": "fn-req-2",
		 "description": "Fully document and implement all key-management processes and procedures for cryptographic keys used for encryption of cardholder data, including the following",
		 "name": "3.6",
		 "subcategories": [
			"3.6.1",
			"3.6.2",
			"3.6.3",
			"3.6.5",
			"3.6.6",
			"3.6.7",
			"3.6.8"
		 ],
		 "id": "3.6"
		},
		"3.7": {
		 "parent": "fn-req-2",
		 "description": "Ensure that security policies and operational procedures for protecting stored cardholder data are documented, in use, and known to all affected parties.",
		 "name": "3.7",
		 "subcategories": [
			"3.7.1"
		 ],
		 "id": "3.7"
		},
		"4.2": {
		 "parent": "fn-req-3",
		 "description": "Never send unprotected PANs by end-user messaging technologies (for example, e-mail, instant messaging, SMS, chat, etc.).",
		 "name": "4.2",
		 "subcategories": [
			"4.2.1"
		 ],
		 "id": "4.2"
		},
		"4.3": {
		 "parent": "fn-req-3",
		 "description": "Ensure that security policies and operational procedures for encrypting transmissions of cardholder data are documented, in use, and known to all affected parties.",
		 "name": "4.3",
		 "subcategories": [
			"4.3.1"
		 ],
		 "id": "4.3"
		},
		"5.1": {
		 "parent": "fn-req-4",
		 "description": "Deploy anti-virus software on all systems commonly affected by malicious software (particularly personal computers and servers).",
		 "name": "5.1",
		 "subcategories": [
			"5.1.1",
			"5.1.2"
		 ],
		 "id": "5.1"
		},
		"5.2": {
		 "parent": "fn-req-4",
		 "description": "Ensure that all anti-virus mechanisms are maintained as follows",
		 "name": "5.2",
		 "subcategories": [
			"5.2.1"
		 ],
		 "id": "5.2"
		},
		"5.3": {
		 "parent": "fn-req-4",
		 "description": "Ensure that anti-virus mechanisms are actively running and cannot be disabled or altered by users, unless specifically authorized by management on a case-by-case basis for a limited time period. Note",
		 "name": "5.3",
		 "subcategories": [
			"5.3.1"
		 ],
		 "id": "5.3"
		},
		"5.4": {
		 "parent": "fn-req-4",
		 "description": "Ensure that security policies and operational procedures for protecting systems against malware are documented, in use, and known to all affected parties.",
		 "name": "5.4",
		 "subcategories": [
			"5.4.1"
		 ],
		 "id": "5.4"
		},
		"6.3": {
		 "parent": "fn-req-5",
		 "description": "Develop internal and external software applications (including web-based administrative access to applications) securely, as follows",
		 "name": "6.3",
		 "subcategories": [
			"6.3.2"
		 ],
		 "id": "6.3"
		},
		"6.4": {
		 "parent": "fn-req-5",
		 "description": "Follow change control processes and procedures for all changes to system components. The processes must include the following",
		 "name": "6.4",
		 "subcategories": [
			"6.4.1",
			"6.4.2",
			"6.4.3",
			"6.4.4",
			"6.4.5",
			"6.4.5.1",
			"6.4.5.2",
			"6.4.5.3",
			"6.4.5.4",
			"6.4.6Uponcompletionofasignificantchange,allrelevantPCIDSSrequirementsmustbeimplementedonallneworchangedsystemsandnetworks,anddocumentationupdatedasapplicable.Note"
		 ],
		 "id": "6.4"
		},
		"6.5": {
		 "parent": "fn-req-5",
		 "description": "Address common coding vulnerabilities in software-development processes as follows",
		 "name": "6.5",
		 "subcategories": [
			"6.5.1",
			"6.5.2",
			"6.5.3",
			"6.5.4",
			"6.5.5",
			"6.5.6",
			"6.5.7",
			"6.5.8",
			"6.5.9",
			"6.5.10"
		 ],
		 "id": "6.5"
		},
		"6.6": {
		 "parent": "fn-req-5",
		 "description": "For public-facing web applications, address new threats and vulnerabilities on an ongoing basis and ensure these applications are protected against known attacks by either of the following methods",
		 "name": "6.6",
		 "subcategories": [
			"6.6.1"
		 ],
		 "id": "6.6"
		},
		"6.7": {
		 "parent": "fn-req-5",
		 "description": "Ensure that security policies and operational procedures for developing and maintaining secure systems and applications are documented, in use, and known to all affected parties.",
		 "name": "6.7",
		 "subcategories": [
			"6.7.1"
		 ],
		 "id": "6.7"
		},
		"7.3": {
		 "parent": "fn-req-6",
		 "description": "Ensure that security policies and operational procedures for restricting access to cardholder data are documented, in use, and known to all affected parties.",
		 "name": "7.3",
		 "subcategories": [
			"7.3.1"
		 ],
		 "id": "7.3"
		},
		"8.1": {
		 "parent": "fn-req-7",
		 "description": "Define and implement policies and procedures to ensure proper user identification management for non-consumer users and administrators on all system components as follows",
		 "name": "8.1",
		 "subcategories": [
			"8.1.1",
			"8.1.2",
			"8.1.3",
			"8.1.5",
			"8.1.6",
			"8.1.7",
			"8.1.8"
		 ],
		 "id": "8.1"
		},
		"8.2": {
		 "parent": "fn-req-7",
		 "description": "In addition to assigning a unique ID, ensure proper user-authentication management for non-consumer users and administrators on all system components by employing at least one of the following methods to authenticate all users",
		 "name": "8.2",
		 "subcategories": [
			"8.2.2",
			"8.2.4",
			"8.2.5",
			"8.2.6"
		 ],
		 "id": "8.2"
		},
		"8.4": {
		 "parent": "fn-req-7",
		 "description": "Document and communicate authentication policies and procedures to all users including",
		 "name": "8.4",
		 "subcategories": [
			"8.4.1"
		 ],
		 "id": "8.4"
		},
		"8.5": {
		 "parent": "fn-req-7",
		 "description": "Do not use group, shared, or generic IDs, passwords, or other authentication methods as follows",
		 "name": "8.5",
		 "subcategories": [
			"8.5.1"
		 ],
		 "id": "8.5"
		},
		"8.6": {
		 "parent": "fn-req-7",
		 "description": "Where other authentication mechanisms are used (for example, physical or logical security tokens, smart cards, certificates, etc.), use of these mechanisms must be assigned as follows",
		 "name": "8.6",
		 "subcategories": [
			"8.6.1"
		 ],
		 "id": "8.6"
		},
		"8.7": {
		 "parent": "fn-req-7",
		 "description": "All access to any database containing cardholder data (including access by applications, administrators, and all other users) is restricted as follows",
		 "name": "8.7",
		 "subcategories": [
			"8.7.1"
		 ],
		 "id": "8.7"
		},
		"8.8": {
		 "parent": "fn-req-7",
		 "description": "Ensure that security policies and operational procedures for identification and authentication are documented, in use, and known to all affected parties.",
		 "name": "8.8",
		 "subcategories": [
			"8.8.1"
		 ],
		 "id": "8.8"
		},
		"9.1": {
		 "parent": "fn-req-8",
		 "description": "Use appropriate facility entry controls to limit and monitor physical access to systems in the cardholder data environment.",
		 "name": "9.1",
		 "subcategories": [
			"9.1.1",
			"9.1.2",
			"9.1.3"
		 ],
		 "id": "9.1"
		},
		"9.2": {
		 "parent": "fn-req-8",
		 "description": "Develop procedures to easily distinguish between onsite personnel and visitors, to include",
		 "name": "9.2",
		 "subcategories": [
			"9.2.1"
		 ],
		 "id": "9.2"
		},
		"9.3": {
		 "parent": "fn-req-8",
		 "description": "Control physical access for onsite personnel to sensitive areas as follows",
		 "name": "9.3",
		 "subcategories": [
			"9.3.1"
		 ],
		 "id": "9.3"
		},
		"9.4": {
		 "parent": "fn-req-8",
		 "description": "Implement procedures to identify and authorize visitors. Procedures should include the following",
		 "name": "9.4",
		 "subcategories": [
			"9.4.1",
			"9.4.2",
			"9.4.3",
			"9.4.4"
		 ],
		 "id": "9.4"
		},
		"9.5": {
		 "parent": "fn-req-8",
		 "description": "Physically secure all media.",
		 "name": "9.5",
		 "subcategories": [
			"9.5.1"
		 ],
		 "id": "9.5"
		},
		"9.6": {
		 "parent": "fn-req-8",
		 "description": "Maintain strict control over the internal or external distribution of any kind of media, including the following",
		 "name": "9.6",
		 "subcategories": [
			"9.6.1",
			"9.6.2",
			"9.6.3"
		 ],
		 "id": "9.6"
		},
		"9.7": {
		 "parent": "fn-req-8",
		 "description": "Maintain strict control over the storage and accessibility of media.",
		 "name": "9.7",
		 "subcategories": [
			"9.7.1"
		 ],
		 "id": "9.7"
		},
		"9.8": {
		 "parent": "fn-req-8",
		 "description": "Destroy media when it is no longer needed for business or legal reasons as follows",
		 "name": "9.8",
		 "subcategories": [
			"9.8.1",
			"9.8.2"
		 ],
		 "id": "9.8"
		},
		"9.9": {
		 "parent": "fn-req-8",
		 "description": "Protect devices that capture payment card data via direct physical interaction with the card from tampering and substitution. Note",
		 "name": "9.9",
		 "subcategories": [
			"9.9.1",
			"9.9.2",
			"9.9.3"
		 ],
		 "id": "9.9"
		},
		"9.10": {
		 "parent": "fn-req-8",
		 "description": "Ensure that security policies and operational procedures for restricting physical access to cardholder data are documented, in use, and known to all affected parties.",
		 "name": "9.10",
		 "subcategories": [
			"9.10.1"
		 ],
		 "id": "9.10"
		},
		"10.4": {
		 "parent": "fn-req-9",
		 "description": "Using time-synchronization technology, synchronize all critical system clocks and times and ensure that the following is implemented for acquiring, distributing, and storing time. Note",
		 "name": "10.4",
		 "subcategories": [
			"10.4.1",
			"10.4.2",
			"10.4.3"
		 ],
		 "id": "10.4"
		},
		"10.5": {
		 "parent": "fn-req-9",
		 "description": "Secure audit trails so they cannot be altered.",
		 "name": "10.5",
		 "subcategories": [
			"10.5.4"
		 ],
		 "id": "10.5"
		},
		"10.7": {
		 "parent": "fn-req-9",
		 "description": "Retain audit trail history for at least one year, with a minimum of three months immediately available for analysis (for example, online, archived, or restorable from backup).",
		 "name": "10.7",
		 "subcategories": [
			"10.7.1"
		 ],
		 "id": "10.7"
		},
		"10.8": {
		 "parent": "fn-req-9",
		 "description": "Additional requirement for service providers only",
		 "name": "10.8",
		 "subcategories": [
			"10.8.1"
		 ],
		 "id": "10.8"
		},
		"10.9": {
		 "parent": "fn-req-9",
		 "description": "Ensure that security policies and operational procedures for monitoring all access to network resources and cardholder data are documented, in use, and known to all affected parties.",
		 "name": "10.9",
		 "subcategories": [
			"10.9.1"
		 ],
		 "id": "10.9"
		},
		"11.1": {
		 "parent": "fn-req-10",
		 "description": "Implement processes to test for the presence of wireless access points (802.11), and detect and identify all authorized and unauthorized wireless access points on a quarterly basis. Note",
		 "name": "11.1",
		 "subcategories": [
			"11.1.1",
			"11.1.2"
		 ],
		 "id": "11.1"
		},
		"11.2": {
		 "parent": "fn-req-10",
		 "description": "Run internal and external network vulnerability scans at least quarterly and after any significant change in the network (such as new system component installations, changes in network topology, firewall rule modifications, product upgrades). Note",
		 "name": "11.2",
		 "subcategories": [
			"11.2.1",
			"11.2.2",
			"11.2.3"
		 ],
		 "id": "11.2"
		},
		"11.3": {
		 "parent": "fn-req-10",
		 "description": "Implement a methodology for penetration testing that includes the following",
		 "name": "11.3",
		 "subcategories": [
			"11.3.1",
			"11.3.2",
			"11.3.3",
			"11.3.4",
			"11.3.4"
		 ],
		 "id": "11.3"
		},
		"11.5": {
		 "parent": "fn-req-10",
		 "description": "Deploy a change-detection mechanism (for example, file-integrity monitoring tools) to alert personnel to unauthorized modification (including changes, additions, and deletions) of critical system files, configuration files, or content files; and configure the software to perform critical file comparisons at least weekly. Note",
		 "name": "11.5",
		 "subcategories": [
			"11.5.1"
		 ],
		 "id": "11.5"
		},
		"11.6": {
		 "parent": "fn-req-10",
		 "description": "Ensure that security policies and operational procedures for security monitoring and testing are documented, in use, and known to all affected parties.",
		 "name": "11.6",
		 "subcategories": [
			"11.6.1"
		 ],
		 "id": "11.6"
		},
		"12.1": {
		 "parent": "fn-req-11",
		 "description": "Establish, publish, maintain, and disseminate a security policy.",
		 "name": "12.1",
		 "subcategories": [
			"12.1.1"
		 ],
		 "id": "12.1"
		},
		"12.2": {
		 "parent": "fn-req-11",
		 "description": "Implement a risk-assessment process that",
		 "name": "12.2",
		 "subcategories": [
			"12.2.1"
		 ],
		 "id": "12.2"
		},
		"12.3": {
		 "parent": "fn-req-11",
		 "description": "Develop usage policies for critical technologies and define proper use of these technologies. Note",
		 "name": "12.3",
		 "subcategories": [
			"12.3.1",
			"12.3.2",
			"12.3.3",
			"12.3.4",
			"12.3.5",
			"12.3.6",
			"12.3.7",
			"12.3.8",
			"12.3.9",
			"12.3.10"
		 ],
		 "id": "12.3"
		},
		"12.4": {
		 "parent": "fn-req-11",
		 "description": "Ensure that the security policy and procedures clearly define information security responsibilities for all personnel.",
		 "name": "12.4",
		 "subcategories": [
			"12.4.1"
		 ],
		 "id": "12.4"
		},
		"12.5": {
		 "parent": "fn-req-11",
		 "description": "Assign to an individual or team the following information security management responsibilities",
		 "name": "12.5",
		 "subcategories": [
			"12.5.1",
			"12.5.3",
			"12.5.4",
			"12.5.5"
		 ],
		 "id": "12.5"
		},
		"12.6": {
		 "parent": "fn-req-11",
		 "description": "Implement a formal security awareness program to make all personnel aware of the cardholder data security policy and procedures.",
		 "name": "12.6",
		 "subcategories": [
			"12.6.1",
			"12.6.2"
		 ],
		 "id": "12.6"
		},
		"12.7": {
		 "parent": "fn-req-11",
		 "description": "Screen potential personnel prior to hire to minimize the risk of attacks from internal sources. (Examples of background checks include previous employment history, criminal record, credit history, and reference checks.)  Note",
		 "name": "12.7",
		 "subcategories": [
			"12.7.1"
		 ],
		 "id": "12.7"
		},
		"12.8": {
		 "parent": "fn-req-11",
		 "description": "Maintain and implement policies and procedures to manage service providers, with whom cardholder data is shared, or that could affect the security of cardholder data, as follows",
		 "name": "12.8",
		 "subcategories": [
			"12.8.1",
			"12.8.2",
			"12.8.3",
			"12.8.4",
			"12.8.5"
		 ],
		 "id": "12.8"
		},
		"12.10": {
		 "parent": "fn-req-11",
		 "description": "Implement an incident response plan. Be prepared to respond immediately to a system breach.",
		 "name": "12.10",
		 "subcategories": [
			"12.10.1",
			"12.10.2",
			"12.10.3",
			"12.10.4",
			"12.10.5",
			"12.10.6"
		 ],
		 "id": "12.10"
		},
		"12.11": {
		 "parent": "fn-req-11",
		 "description": "Additional requirement for service providers only",
		 "name": "12.11",
		 "subcategories": [
			"12.11.1"
		 ],
		 "id": "12.11"
		},
		"A1": {
		 "parent": "fn-req-12",
		 "description": "Protect each entity’s (that is, merchant, service provider, or other entity) hosted environment and data, per A1.1 through A1.4",
		 "name": "A1",
		 "subcategories": [
			"A1.1",
			"A1.2",
			"A1.3",
			"A1.4"
		 ],
		 "id": "A1"
		},
		"A2.1": {
		 "parent": "fn-req-13",
		 "description": "Where POS POI terminals (and the SSL/TLS termination points to which they connect) use SSL and/or early TLS, the entity must either * Confirm the devices are not susceptible to any known exploits for those protocols. Or",
		 "name": "A2.1",
		 "subcategories": [
			"A2.1.1"
		 ],
		 "id": "A2.1"
		},
		"A2.2": {
		 "parent": "fn-req-13",
		 "description": "Entities with existing implementations (other than as allowed in A2.1) that use SSL and/or early TLS must have a formal Risk Mitigation and Migration Plan in place.",
		 "name": "A2.2",
		 "subcategories": [
			"A2.2.1"
		 ],
		 "id": "A2.2"
		},
		"A2.3": {
		 "parent": "fn-req-13",
		 "description": "Additional Requirement for Service Providers Only",
		 "name": "A2.3",
		 "subcategories": [
			"A2.3.1"
		 ],
		 "id": "A2.3"
		}
	 },
	"subcategories": {
		"1.1.1": {
			"parent": "1.1",
			"description": "A formal process for approving and testing all network connections and changes to the firewall and router configurations",
			"name": "1.1.1",
			"index": getIndex()
		},
		"1.1.2": {
			"parent": "1.1",
			"description": "Current network diagram that identifies all connections between the cardholder data environment and other networks, including any wireless networks",
			"name": "1.1.2",
			"index": getIndex()
		},
		"1.1.3": {
			"parent": "1.1",
			"description": "Current diagram that shows all cardholder data flows across systems and networks",
			"name": "1.1.3",
			"index": getIndex()
		},
		"1.1.4": {
			"parent": "1.1",
			"description": "Requirements for a firewall at each Internet connection and between any demilitarized zone (DMZ) and the internal network zone",
			"name": "1.1.4",
			"index": getIndex()
		},
		"1.1.5": {
			"parent": "1.1",
			"description": "Description of groups, roles, and responsibilities for management of network components",
			"name": "1.1.5",
			"index": getIndex()
		},
		"1.1.6": {
			"parent": "1.1",
			"description": "Documentation of business justification and approval for use of all services, protocols, and ports allowed, including documentation of security features implemented for those protocols considered to be insecure.",
			"name": "1.1.6",
			"index": getIndex()
		},
		"1.1.7": {
			"parent": "1.1",
			"description": "Requirement to review firewall and router rule sets at least every six months",
			"name": "1.1.7",
			"index": getIndex()
		},
		"1.4.1": {
			"parent": "1.4",
			"description": "",
			"name": "1.4.1",
			"index": getIndex()
		},
		"1.5.1": {
			"parent": "1.5",
			"description": "",
			"name": "1.5.1",
			"index": getIndex()
		},
		"2.2.1": {
			"parent": "2.2",
			"description": "Implement only one primary function per server to prevent functions that require different security levels from co-existing on the same server. (For example, web servers, database servers, and DNS should be implemented on separate servers.) Note",
			"name": "2.2.1",
			"index": getIndex()
		},
		"2.2.3": {
			"parent": "2.2",
			"description": "Implement additional security features for any required services, protocols, or daemons that are considered to be insecure. Note",
			"name": "2.2.3",
			"index": getIndex()
		},
		"2.2.4": {
			"parent": "2.2",
			"description": "Configure system security parameters to prevent misuse.",
			"name": "2.2.4",
			"index": getIndex()
		},
		"2.2.5": {
			"parent": "2.2",
			"description": "Remove all unnecessary functionality, such as scripts, drivers, features, subsystems, file systems, and unnecessary web servers.",
			"name": "2.2.5",
			"index": getIndex()
		},
		"2.5.1": {
			"parent": "2.5",
			"description": "",
			"name": "2.5.1",
			"index": getIndex()
		},
		"2.6.1": {
			"parent": "2.6",
			"description": "",
			"name": "2.6.1",
			"index": getIndex()
		},
		"3.1.1": {
			"parent": "3.1",
			"description": "",
			"name": "3.1.1",
			"index": getIndex()
		},
		"3.2.1": {
			"parent": "3.2",
			"description": "Do not store the full contents of any track (from the magnetic stripe located on the back of a card, equivalent data contained on a chip, or elsewhere) after authorization. This data is alternatively called full track, track, track 1, track 2, and magnetic-stripe data. Note",
			"name": "3.2.1",
			"index": getIndex()
		},
		"3.2.2": {
			"parent": "3.2",
			"description": "Do not store the card verification code or value (three-digit or four-digit number printed on the front or back of a payment card used to verify card-not-present transactions) after authorization.",
			"name": "3.2.2",
			"index": getIndex()
		},
		"3.2.3": {
			"parent": "3.2",
			"description": "Do not store the personal identification number (PIN) or the encrypted PIN block after authorization.",
			"name": "3.2.3",
			"index": getIndex()
		},
		"3.3.1": {
			"parent": "3.3",
			"description": "",
			"name": "3.3.1",
			"index": getIndex()
		},
		"3.5.1": {
			"parent": "3.5",
			"description": "Additional requirement for service providers only",
			"name": "3.5.1",
			"index": getIndex()
		},
		"3.5.2": {
			"parent": "3.5",
			"description": "Restrict access to cryptographic keys to the fewest number of custodians necessary.",
			"name": "3.5.2",
			"index": getIndex()
		},
		"3.5.3": {
			"parent": "3.5",
			"description": "Store secret and private keys used to encrypt/decrypt cardholder data in one (or more) of the following forms at all times",
			"name": "3.5.3",
			"index": getIndex()
		},
		"3.5.4": {
			"parent": "3.5",
			"description": "Store cryptographic keys in the fewest possible locations.",
			"name": "3.5.4",
			"index": getIndex()
		},
		"3.6.1": {
			"parent": "3.6",
			"description": "Generation of strong cryptographic keys",
			"name": "3.6.1",
			"index": getIndex()
		},
		"3.6.2": {
			"parent": "3.6",
			"description": "Secure cryptographic key distribution",
			"name": "3.6.2",
			"index": getIndex()
		},
		"3.6.3": {
			"parent": "3.6",
			"description": "Secure cryptographic key storage",
			"name": "3.6.3",
			"index": getIndex()
		},
		"3.6.5": {
			"parent": "3.6",
			"description": "Retirement or replacement (for example, archiving, destruction, and/or revocation) of keys as deemed necessary when the integrity of the key has been weakened (for example, departure of an employee with knowledge of a clear-text key component), or keys are suspected of being compromised. Note",
			"name": "3.6.5",
			"index": getIndex()
		},
		"3.6.6": {
			"parent": "3.6",
			"description": "If manual clear-text cryptographic key-management operations are used, these operations must be managed using split knowledge and dual control. Note",
			"name": "3.6.6",
			"index": getIndex()
		},
		"3.6.7": {
			"parent": "3.6",
			"description": "Prevention of unauthorized substitution of cryptographic keys.",
			"name": "3.6.7",
			"index": getIndex()
		},
		"3.6.8": {
			"parent": "3.6",
			"description": "Requirement for cryptographic key custodians to formally acknowledge that they understand and accept their key-custodian responsibilities.",
			"name": "3.6.8",
			"index": getIndex()
		},
		"3.7.1": {
			"parent": "3.7",
			"description": "",
			"name": "3.7.1",
			"index": getIndex()
		},
		"4.2.1": {
			"parent": "4.2",
			"description": "",
			"name": "4.2.1",
			"index": getIndex()
		},
		"4.3.1": {
			"parent": "4.3",
			"description": "",
			"name": "4.3.1",
			"index": getIndex()
		},
		"5.1.1": {
			"parent": "5.1",
			"description": "Ensure that anti-virus programs are capable of detecting, removing, and protecting against all known types of malicious software.",
			"name": "5.1.1",
			"index": getIndex()
		},
		"5.1.2": {
			"parent": "5.1",
			"description": "For systems considered to be not commonly affected by malicious software, perform periodic evaluations to identify and evaluate evolving malware threats in order to confirm whether such systems continue to not require anti-virus software.",
			"name": "5.1.2",
			"index": getIndex()
		},
		"5.2.1": {
			"parent": "5.2",
			"description": "",
			"name": "5.2.1",
			"index": getIndex()
		},
		"5.3.1": {
			"parent": "5.3",
			"description": "",
			"name": "5.3.1",
			"index": getIndex()
		},
		"5.4.1": {
			"parent": "5.4",
			"description": "",
			"name": "5.4.1",
			"index": getIndex()
		},
		"6.3.2": {
			"parent": "6.3",
			"description": "Review custom code prior to release to production or customers in order to identify any potential coding vulnerability (using either manual or automated processes) to include at least the following",
			"name": "6.3.2",
			"index": getIndex()
		},
		"6.4.1": {
			"parent": "6.4",
			"description": "Separate development/test environments from production environments, and enforce the separation with access controls.",
			"name": "6.4.1",
			"index": getIndex()
		},
		"6.4.2": {
			"parent": "6.4",
			"description": "Separation of duties between development/test and production environments",
			"name": "6.4.2",
			"index": getIndex()
		},
		"6.4.3": {
			"parent": "6.4",
			"description": "Production data (live PANs) are not used for testing or development",
			"name": "6.4.3",
			"index": getIndex()
		},
		"6.4.4": {
			"parent": "6.4",
			"description": "Removal of test data and accounts from system components before the system becomes active/goes into production.",
			"name": "6.4.4",
			"index": getIndex()
		},
		"6.4.5": {
			"parent": "6.4",
			"description": "Change control procedures must include the following",
			"name": "6.4.5",
			"index": getIndex()
		},
		"6.4.5.1": {
			"parent": "6.4",
			"description": "Documentation of impact.",
			"name": "6.4.5.1",
			"index": getIndex()
		},
		"6.4.5.2": {
			"parent": "6.4",
			"description": "Documented change approval by authorized parties.",
			"name": "6.4.5.2",
			"index": getIndex()
		},
		"6.4.5.3": {
			"parent": "6.4",
			"description": "Functionality testing to verify that the change does not adversely impact the security of the system.",
			"name": "6.4.5.3",
			"index": getIndex()
		},
		"6.4.5.4": {
			"parent": "6.4",
			"description": "Back-out procedures.",
			"name": "6.4.5.4",
			"index": getIndex()
		},
		"6.4.6Uponcompletionofasignificantchange,allrelevantPCIDSSrequirementsmustbeimplementedonallneworchangedsystemsandnetworks,anddocumentationupdatedasapplicable.Note": {
			"parent": "6.4",
			"description": "This requirement is a best practice until January 31, 2018, after which it becomes a requirement.",
			"name": "6.4.6Uponcompletionofasignificantchange,allrelevantPCIDSSrequirementsmustbeimplementedonallneworchangedsystemsandnetworks,anddocumentationupdatedasapplicable.Note",
			"index": getIndex()
		},
		"6.5.1": {
			"parent": "6.5",
			"description": "Injection flaws, particularly SQL injection. Also consider OS Command Injection, LDAP and XPath injection flaws as well as other injection flaws.",
			"name": "6.5.1",
			"index": getIndex()
		},
		"6.5.2": {
			"parent": "6.5",
			"description": "Buffer overflows",
			"name": "6.5.2",
			"index": getIndex()
		},
		"6.5.3": {
			"parent": "6.5",
			"description": "Insecure cryptographic storage",
			"name": "6.5.3",
			"index": getIndex()
		},
		"6.5.4": {
			"parent": "6.5",
			"description": "Insecure communications",
			"name": "6.5.4",
			"index": getIndex()
		},
		"6.5.5": {
			"parent": "6.5",
			"description": "Improper error handling",
			"name": "6.5.5",
			"index": getIndex()
		},
		"6.5.6": {
			"parent": "6.5",
			"description": "All “high risk” vulnerabilities identified in the vulnerability identification process (as defined in PCI DSS Requirement 6.1).",
			"name": "6.5.6",
			"index": getIndex()
		},
		"6.5.7": {
			"parent": "6.5",
			"description": "Cross-site scripting (XSS)",
			"name": "6.5.7",
			"index": getIndex()
		},
		"6.5.8": {
			"parent": "6.5",
			"description": "Improper access control (such as insecure direct object references, failure to restrict URL access, directory traversal, and failure to restrict user access to functions).",
			"name": "6.5.8",
			"index": getIndex()
		},
		"6.5.9": {
			"parent": "6.5",
			"description": "Cross-site request forgery (CSRF)",
			"name": "6.5.9",
			"index": getIndex()
		},
		"6.5.10": {
			"parent": "6.5",
			"description": "Broken authentication and session management",
			"name": "6.5.10",
			"index": getIndex()
		},
		"6.6.1": {
			"parent": "6.6",
			"description": "",
			"name": "6.6.1",
			"index": getIndex()
		},
		"6.7.1": {
			"parent": "6.7",
			"description": "",
			"name": "6.7.1",
			"index": getIndex()
		},
		"7.3.1": {
			"parent": "7.3",
			"description": "",
			"name": "7.3.1",
			"index": getIndex()
		},
		"8.1.1": {
			"parent": "8.1",
			"description": "Assign all users a unique ID before allowing them to access system components or cardholder data.",
			"name": "8.1.1",
			"index": getIndex()
		},
		"8.1.2": {
			"parent": "8.1",
			"description": "Control addition, deletion, and modification of user IDs, credentials, and other identifier objects.",
			"name": "8.1.2",
			"index": getIndex()
		},
		"8.1.3": {
			"parent": "8.1",
			"description": "Immediately revoke access for any terminated users.",
			"name": "8.1.3",
			"index": getIndex()
		},
		"8.1.5": {
			"parent": "8.1",
			"description": "Manage IDs used by third parties to access, support, or maintain system components via remote access as follows",
			"name": "8.1.5",
			"index": getIndex()
		},
		"8.1.6": {
			"parent": "8.1",
			"description": "Limit repeated access attempts by locking out the user ID after not more than six attempts.",
			"name": "8.1.6",
			"index": getIndex()
		},
		"8.1.7": {
			"parent": "8.1",
			"description": "Set the lockout duration to a minimum of 30 minutes or until an administrator enables the user ID.",
			"name": "8.1.7",
			"index": getIndex()
		},
		"8.1.8": {
			"parent": "8.1",
			"description": "If a session has been idle for more than 15 minutes, require the user to re-authenticate to re-activate the terminal or session.",
			"name": "8.1.8",
			"index": getIndex()
		},
		"8.2.2": {
			"parent": "8.2",
			"description": "Verify user identity before modifying any authentication credential—for example, performing password resets, provisioning new tokens, or generating new keys.",
			"name": "8.2.2",
			"index": getIndex()
		},
		"8.2.4": {
			"parent": "8.2",
			"description": "Change user passwords/passphrases at least once every 90 days.",
			"name": "8.2.4",
			"index": getIndex()
		},
		"8.2.5": {
			"parent": "8.2",
			"description": "Do not allow an individual to submit a new password/passphrase that is the same as any of the last four passwords/passphrases he or she has used.",
			"name": "8.2.5",
			"index": getIndex()
		},
		"8.2.6": {
			"parent": "8.2",
			"description": "Set passwords/passphrases for first-time use and upon reset to a unique value for each user, and change immediately after the first use.",
			"name": "8.2.6",
			"index": getIndex()
		},
		"8.4.1": {
			"parent": "8.4",
			"description": "",
			"name": "8.4.1",
			"index": getIndex()
		},
		"8.5.1": {
			"parent": "8.5",
			"description": "Additional requirement for service providers only",
			"name": "8.5.1",
			"index": getIndex()
		},
		"8.6.1": {
			"parent": "8.6",
			"description": "",
			"name": "8.6.1",
			"index": getIndex()
		},
		"8.7.1": {
			"parent": "8.7",
			"description": "",
			"name": "8.7.1",
			"index": getIndex()
		},
		"8.8.1": {
			"parent": "8.8",
			"description": "",
			"name": "8.8.1",
			"index": getIndex()
		},
		"9.1.1": {
			"parent": "9.1",
			"description": "Use either video cameras or access control mechanisms (or both) to monitor individual physical access to sensitive areas. Review collected data and correlate with other entries. Store for at least three months, unless otherwise restricted by law. Note",
			"name": "9.1.1",
			"index": getIndex()
		},
		"9.1.2": {
			"parent": "9.1",
			"description": "Implement physical and/or logical controls to restrict access to publicly accessible network jacks. For example, network jacks located in public areas and areas accessible to visitors could be disabled and only enabled when network access is explicitly authorized. Alternatively, processes could be implemented to ensure that visitors are escorted at all times in areas with active network jacks.",
			"name": "9.1.2",
			"index": getIndex()
		},
		"9.1.3": {
			"parent": "9.1",
			"description": "Restrict physical access to wireless access points, gateways, handheld devices, networking/communications hardware, and telecommunication lines.",
			"name": "9.1.3",
			"index": getIndex()
		},
		"9.2.1": {
			"parent": "9.2",
			"description": "",
			"name": "9.2.1",
			"index": getIndex()
		},
		"9.3.1": {
			"parent": "9.3",
			"description": "",
			"name": "9.3.1",
			"index": getIndex()
		},
		"9.4.1": {
			"parent": "9.4",
			"description": "Visitors are authorized before entering, and escorted at all times within, areas where cardholder data is processed or maintained.",
			"name": "9.4.1",
			"index": getIndex()
		},
		"9.4.2": {
			"parent": "9.4",
			"description": "Visitors are identified and given a badge or other identification that expires and that visibly distinguishes the visitors from onsite personnel.",
			"name": "9.4.2",
			"index": getIndex()
		},
		"9.4.3": {
			"parent": "9.4",
			"description": "Visitors are asked to surrender the badge or identification before leaving the facility or at the date of expiration.",
			"name": "9.4.3",
			"index": getIndex()
		},
		"9.4.4": {
			"parent": "9.4",
			"description": "A visitor log is used to maintain a physical audit trail of visitor activity to the facility as well as computer rooms and data centers where cardholder data is stored or transmitted. Document the visitor’s name, the firm represented, and the onsite personnel authorizing physical access on the log. Retain this log for a minimum of three months, unless otherwise restricted by law.",
			"name": "9.4.4",
			"index": getIndex()
		},
		"9.5.1": {
			"parent": "9.5",
			"description": "Store media backups in a secure location, preferably an off-site facility, such as an alternate or backup site, or a commercial storage facility. Review the location’s security at least annually.",
			"name": "9.5.1",
			"index": getIndex()
		},
		"9.6.1": {
			"parent": "9.6",
			"description": "Classify media so the sensitivity of the data can be determined.",
			"name": "9.6.1",
			"index": getIndex()
		},
		"9.6.2": {
			"parent": "9.6",
			"description": "Send the media by secured courier or other delivery method that can be accurately tracked.",
			"name": "9.6.2",
			"index": getIndex()
		},
		"9.6.3": {
			"parent": "9.6",
			"description": "Ensure management approves any and all media that is moved from a secured area (including when media is distributed to individuals).",
			"name": "9.6.3",
			"index": getIndex()
		},
		"9.7.1": {
			"parent": "9.7",
			"description": "Properly maintain inventory logs of all media and conduct media inventories at least annually.",
			"name": "9.7.1",
			"index": getIndex()
		},
		"9.8.1": {
			"parent": "9.8",
			"description": "Shred, incinerate, or pulp hard-copy materials so that cardholder data cannot be reconstructed. Secure storage containers used for materials that are to be destroyed.",
			"name": "9.8.1",
			"index": getIndex()
		},
		"9.8.2": {
			"parent": "9.8",
			"description": "Render cardholder data on electronic media unrecoverable so that cardholder data cannot be reconstructed.",
			"name": "9.8.2",
			"index": getIndex()
		},
		"9.9.1": {
			"parent": "9.9",
			"description": "Maintain an up-to-date list of devices. The list should include the following",
			"name": "9.9.1",
			"index": getIndex()
		},
		"9.9.2": {
			"parent": "9.9",
			"description": "Periodically inspect device surfaces to detect tampering (for example, addition of card skimmers to devices), or substitution (for example, by checking the serial number or other device characteristics to verify it has not been swapped with a fraudulent device). Note",
			"name": "9.9.2",
			"index": getIndex()
		},
		"9.9.3": {
			"parent": "9.9",
			"description": "Provide training for personnel to be aware of attempted tampering or replacement of devices. Training should include the following",
			"name": "9.9.3",
			"index": getIndex()
		},
		"9.10.1": {
			"parent": "9.10",
			"description": "",
			"name": "9.10.1",
			"index": getIndex()
		},
		"10.4.1": {
			"parent": "10.4",
			"description": "Critical systems have the correct and consistent time.",
			"name": "10.4.1",
			"index": getIndex()
		},
		"10.4.2": {
			"parent": "10.4",
			"description": "Time data is protected.",
			"name": "10.4.2",
			"index": getIndex()
		},
		"10.4.3": {
			"parent": "10.4",
			"description": "Time settings are received from industry-accepted time sources.",
			"name": "10.4.3",
			"index": getIndex()
		},
		"10.5.4": {
			"parent": "10.5",
			"description": "Write logs for external-facing technologies onto a secure, centralized, internal log server or media device.",
			"name": "10.5.4",
			"index": getIndex()
		},
		"10.7.1": {
			"parent": "10.7",
			"description": "",
			"name": "10.7.1",
			"index": getIndex()
		},
		"10.8.1": {
			"parent": "10.8",
			"description": "Additional requirement for service providers only",
			"name": "10.8.1",
			"index": getIndex()
		},
		"10.9.1": {
			"parent": "10.9",
			"description": "",
			"name": "10.9.1",
			"index": getIndex()
		},
		"11.1.1": {
			"parent": "11.1",
			"description": "Maintain an inventory of authorized wireless access points including a documented business justification.",
			"name": "11.1.1",
			"index": getIndex()
		},
		"11.1.2": {
			"parent": "11.1",
			"description": "Implement incident response procedures in the event unauthorized wireless access points are detected.",
			"name": "11.1.2",
			"index": getIndex()
		},
		"11.2.1": {
			"parent": "11.2",
			"description": "Perform quarterly internal vulnerability scans. Address vulnerabilities and perform rescans to verify all “high risk” vulnerabilities are resolved in accordance with the entity’s vulnerability ranking (per Requirement 6.1). Scans must be performed by qualified personnel.",
			"name": "11.2.1",
			"index": getIndex()
		},
		"11.2.2": {
			"parent": "11.2",
			"description": "Perform quarterly external vulnerability scans, via an Approved Scanning Vendor (ASV) approved by the Payment Card Industry Security Standards Council (PCI SSC). Perform rescans as needed, until passing scans are achieved. Note",
			"name": "11.2.2",
			"index": getIndex()
		},
		"11.2.3": {
			"parent": "11.2",
			"description": "Perform internal and external scans, and rescans as needed, after any significant change. Scans must be performed by qualified personnel.",
			"name": "11.2.3",
			"index": getIndex()
		},
		"11.3.1": {
			"parent": "11.3",
			"description": "Perform external penetration testing at least annually and after any significant infrastructure or application upgrade or modification (such as an operating system upgrade, a sub-network added to the environment, or a web server added to the environment).",
			"name": "11.3.1",
			"index": getIndex()
		},
		"11.3.2": {
			"parent": "11.3",
			"description": "Perform internal penetration testing at least annually and after any significant infrastructure or application upgrade or modification (such as an operating system upgrade, a sub-network added to the environment, or a web server added to the environment).",
			"name": "11.3.2",
			"index": getIndex()
		},
		"11.3.3": {
			"parent": "11.3",
			"description": "Exploitable vulnerabilities found during penetration testing are corrected and testing is repeated to verify the corrections.",
			"name": "11.3.3",
			"index": getIndex()
		},
		"11.3.4": {
			"parent": "11.3",
			"description": ".1 Additional requirement for service providers only",
			"name": "11.3.4",
			"index": getIndex()
		},
		"11.5.1": {
			"parent": "11.5",
			"description": "Implement a process to respond to any alerts generated by the change-detection solution.",
			"name": "11.5.1",
			"index": getIndex()
		},
		"11.6.1": {
			"parent": "11.6",
			"description": "",
			"name": "11.6.1",
			"index": getIndex()
		},
		"12.1.1": {
			"parent": "12.1",
			"description": "Review the security policy at least annually and update the policy when the environment changes.",
			"name": "12.1.1",
			"index": getIndex()
		},
		"12.2.1": {
			"parent": "12.2",
			"description": "",
			"name": "12.2.1",
			"index": getIndex()
		},
		"12.3.1": {
			"parent": "12.3",
			"description": "Explicit approval by authorized parties",
			"name": "12.3.1",
			"index": getIndex()
		},
		"12.3.2": {
			"parent": "12.3",
			"description": "Authentication for use of the technology",
			"name": "12.3.2",
			"index": getIndex()
		},
		"12.3.3": {
			"parent": "12.3",
			"description": "A list of all such devices and personnel with access",
			"name": "12.3.3",
			"index": getIndex()
		},
		"12.3.4": {
			"parent": "12.3",
			"description": "A method to accurately and readily determine owner, contact information, and purpose (for example, labeling, coding, and/or inventorying of devices)",
			"name": "12.3.4",
			"index": getIndex()
		},
		"12.3.5": {
			"parent": "12.3",
			"description": "Acceptable uses of the technology",
			"name": "12.3.5",
			"index": getIndex()
		},
		"12.3.6": {
			"parent": "12.3",
			"description": "Acceptable network locations for the technologies",
			"name": "12.3.6",
			"index": getIndex()
		},
		"12.3.7": {
			"parent": "12.3",
			"description": "List of company-approved products",
			"name": "12.3.7",
			"index": getIndex()
		},
		"12.3.8": {
			"parent": "12.3",
			"description": "Automatic disconnect of sessions for remote-access technologies after a specific period of inactivity",
			"name": "12.3.8",
			"index": getIndex()
		},
		"12.3.9": {
			"parent": "12.3",
			"description": "Activation of remote-access technologies for vendors and business partners only when needed by vendors and business partners, with immediate deactivation after use",
			"name": "12.3.9",
			"index": getIndex()
		},
		"12.3.10": {
			"parent": "12.3",
			"description": "For personnel accessing cardholder data via remote-access technologies, prohibit the copying, moving, and storage of cardholder data onto local hard drives and removable electronic media, unless explicitly authorized for a defined business need. Where there is an authorized business need, the usage policies must require the data be protected in accordance with all applicable PCI DSS Requirements.",
			"name": "12.3.10",
			"index": getIndex()
		},
		"12.4.1": {
			"parent": "12.4",
			"description": "Additional requirement for service providers only",
			"name": "12.4.1",
			"index": getIndex()
		},
		"12.5.1": {
			"parent": "12.5",
			"description": "Establish, document, and distribute security policies and procedures.",
			"name": "12.5.1",
			"index": getIndex()
		},
		"12.5.3": {
			"parent": "12.5",
			"description": "Establish, document, and distribute security incident response and escalation procedures to ensure timely and effective handling of all situations.",
			"name": "12.5.3",
			"index": getIndex()
		},
		"12.5.4": {
			"parent": "12.5",
			"description": "Administer user accounts, including additions, deletions, and modifications.",
			"name": "12.5.4",
			"index": getIndex()
		},
		"12.5.5": {
			"parent": "12.5",
			"description": "Monitor and control all access to data.",
			"name": "12.5.5",
			"index": getIndex()
		},
		"12.6.1": {
			"parent": "12.6",
			"description": "Educate personnel upon hire and at least annually. Note",
			"name": "12.6.1",
			"index": getIndex()
		},
		"12.6.2": {
			"parent": "12.6",
			"description": "Require personnel to acknowledge at least annually that they have read and understood the security policy and procedures.",
			"name": "12.6.2",
			"index": getIndex()
		},
		"12.7.1": {
			"parent": "12.7",
			"description": "",
			"name": "12.7.1",
			"index": getIndex()
		},
		"12.8.1": {
			"parent": "12.8",
			"description": "Maintain a list of service providers including a description of the service provided.",
			"name": "12.8.1",
			"index": getIndex()
		},
		"12.8.2": {
			"parent": "12.8",
			"description": "Maintain a written agreement that includes an acknowledgement that the service providers are responsible for the security of cardholder data the service providers possess or otherwise store, process or transmit on behalf of the customer, or to the extent that they could impact the security of the customer’s cardholder data environment. Note",
			"name": "12.8.2",
			"index": getIndex()
		},
		"12.8.3": {
			"parent": "12.8",
			"description": "Ensure there is an established process for engaging service providers including proper due diligence prior to engagement.",
			"name": "12.8.3",
			"index": getIndex()
		},
		"12.8.4": {
			"parent": "12.8",
			"description": "Maintain a program to monitor service providers’ PCI DSS compliance status at least annually.",
			"name": "12.8.4",
			"index": getIndex()
		},
		"12.8.5": {
			"parent": "12.8",
			"description": "Maintain information about which PCI DSS requirements are managed by each service provider, and which are managed by the entity.",
			"name": "12.8.5",
			"index": getIndex()
		},
		"12.10.1": {
			"parent": "12.10",
			"description": "Create the incident response plan to be implemented in the event of system breach. Ensure the plan addresses the following, at a minimum",
			"name": "12.10.1",
			"index": getIndex()
		},
		"12.10.2": {
			"parent": "12.10",
			"description": "Review and test the plan, including all elements listed in Requirement 12.10.1, at least annually.",
			"name": "12.10.2",
			"index": getIndex()
		},
		"12.10.3": {
			"parent": "12.10",
			"description": "Designate specific personnel to be available on a 24/7 basis to respond to alerts.",
			"name": "12.10.3",
			"index": getIndex()
		},
		"12.10.4": {
			"parent": "12.10",
			"description": "Provide appropriate training to staff with security breach response responsibilities.",
			"name": "12.10.4",
			"index": getIndex()
		},
		"12.10.5": {
			"parent": "12.10",
			"description": "Include alerts from security monitoring systems, including but not limited to intrusion-detection, intrusion-prevention, firewalls, and file-integrity monitoring systems.",
			"name": "12.10.5",
			"index": getIndex()
		},
		"12.10.6": {
			"parent": "12.10",
			"description": "Develop a process to modify and evolve the incident response plan according to lessons learned and to incorporate industry developments.",
			"name": "12.10.6",
			"index": getIndex()
		},
		"12.11.1": {
			"parent": "12.11",
			"description": "Additional requirement for service providers only",
			"name": "12.11.1",
			"index": getIndex()
		},
		"A1.1": {
			"parent": "A1",
			"description": "Ensure that each entity only runs processes that have access to that entity’s cardholder data environment.",
			"name": "A1.1",
			"index": getIndex()
		},
		"A1.2": {
			"parent": "A1",
			"description": "Restrict each entity’s access and privileges to its own cardholder data environment only.",
			"name": "A1.2",
			"index": getIndex()
		},
		"A1.3": {
			"parent": "A1",
			"description": "Ensure logging and audit trails are enabled and unique to each entity’s cardholder data environment and consistent with PCI DSS Requirement 10.",
			"name": "A1.3",
			"index": getIndex()
		},
		"A1.4": {
			"parent": "A1",
			"description": "Enable processes to provide for timely forensic investigation in the event of a compromise to any hosted merchant or service provider.",
			"name": "A1.4",
			"index": getIndex()
		},
		"A2.1.1": {
			"parent": "A2.1",
			"description": "",
			"name": "A2.1.1",
			"index": getIndex()
		},
		"A2.2.1": {
			"parent": "A2.2",
			"description": "",
			"name": "A2.2.1",
			"index": getIndex()
		},
		"A2.3.1": {
			"parent": "A2.3",
			"description": "",
			"name": "A2.3.1",
			"index": getIndex()
		}
	}
};