import { Button, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import React, { useState } from 'react';

import ModalHeader from 'reactstrap/lib/ModalHeader';
import { t } from 'i18next';

const InnerDialog = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false),
		toggle = () => setIsOpen(v => !v);

	return (
		<>
			<Row>
				<div className="d-flex align-items-center justify-content-center">
					<Button size="lg" color="primary" onClick={toggle}>
						{t('topology.startComplianceTest')}
					</Button>
				</div>
			</Row>
			<Modal isOpen={isOpen}>
				<ModalHeader>{title}</ModalHeader>
				<ModalBody className="text-center m-3">
					<p className="mb-0">{children}</p>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" className="" onClick={toggle}>
						{t('button.ok')}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const ComingSoonText = () => (
	<>
		{t('tests.additionalFee')}{' '}
		<a href="mailto:support@talasecure.com">support@talasecure.com</a>
	</>
);

const ComingSoonButton = ({ title }) => (
	<InnerDialog title={title}>{t('tests.comingSoon')}</InnerDialog>
);
const DisabledButton = ({ title }) => (
	<InnerDialog title={title}>
		<ComingSoonText />
	</InnerDialog>
);

export { ComingSoonButton, DisabledButton };
