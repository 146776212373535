import Header from "../../../../components/Header";
import HeaderTitle from "../../../../components/HeaderTitle";
import React from "react";

export default ({ title }) => (
  <Header>
    <HeaderTitle>
      {title}
    </HeaderTitle>
  </Header>
)