import React from 'react';
import Button from '../../../components/Form/Button';
import classNames from 'classnames';
import { mkConfig, generateCsv, download } from 'export-to-csv';


const ExportCsv = ({ data, csvFileName, children, className }) => {
	const handleClick = () => {
		const csvConfig = mkConfig({
			fieldSeparator: ',',
			filename: csvFileName, // export file name (without .csv)
			decimalSeparator: '.',
			useKeysAsHeaders: true,
		});
		const csv = generateCsv(csvConfig)(data);

		download(csvConfig)(csv);

	};
	return (
		<Button
			onClick={handleClick}
			className={classNames({ [className]: className })}>
			{children}
		</Button>
	);
};

export default ExportCsv;
