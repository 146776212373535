import { t } from 'i18next';
import React, { useState } from 'react';
import {
	Badge,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Container,
	Col,
	Row,
} from 'reactstrap';

const cardHeadBackgroundStyle = { background: '#80ccff' };
const cardBodyBackgroundStyle = {
	maxHeight: '420px',
	overflowY: 'auto',
	margin: '10px',
};

const Checkbox = ({ label, id, checked, onChange, className, disabled }) => {
	return (
		<div className="form-check cursor-pointer checkbox-wrapper mb-2">
			<input
				type="checkbox"
				id={id}
				className={
					checked
						? `form-check-input checked ${className}`
						: `form-check-input  ${className}`
				}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
			<label className="form-check-label" htmlFor={id}>
				{label}
			</label>
		</div>
	);
};

const LegendItem = ({ index, item, itemUpdate }) => {
	const [show, setShow] = useState(true);
	const onCheckItem = () => {
		setShow(!show);
		itemUpdate(null, 'compliant', item);
		itemUpdate(null, 'noncompliant', item);
	};

	return (
		<div className="d-flex align-items-center">
			<span className="item-checkbox">
				<input type="checkbox" checked={show} onChange={() => onCheckItem()} />
			</span>
			<span className="m-2 ms-0">
				<img
					src={item.image}
					alt={item.label}
					width={65}
					height={65}
					className="rounded"
				/>
			</span>
			<Col>
				<Row>
					<span className="align-middle mt-1 text-body font-weight-bold">
						{item.label}
					</span>
				</Row>
				<Row>
					<Checkbox
						id={`${index}-compliant-checkbox`}
						label={t('topology.compliant')}
						checked={item.showCompliant}
						onChange={() => itemUpdate(null, 'compliant', item)}
						disabled={!show}
					/>
				</Row>
				<Row>
					<Checkbox
						id={`${index}-non-compliant-checkbox`}
						className="non-compliant-checkbox"
						label={t('topology.nonCompliant')}
						checked={item.showNonCompliant}
						onChange={() => itemUpdate(null, 'noncompliant', item)}
						disabled={!show}
					/>
				</Row>
			</Col>
		</div>
	);
};

const Legend = props => {
	return (
		<Card className="d-flex">
			<CardHeader style={cardHeadBackgroundStyle}>
				<CardTitle>
					<h5>{t('tests.legend')}</h5>
				</CardTitle>
				<Badge
					color="secondary"
					role="button"
					className="cursor-pointer col-5 m-1 p-2"
					onClick={() => props.showHideAll('show', 'compliant')}>
					{t('topology.showCompliant')}
				</Badge>
				<Badge
					color="secondary"
					role="button"
					className="cursor-pointer  col-5 m-1  p-2"
					onClick={() => props.showHideAll('hide', 'compliant')}>
					{t('topology.hideCompliant')}{' '}
				</Badge>
				<Badge
					color="danger"
					role="button"
					className="cursor-pointer  col-5 m-1 p-2"
					onClick={() => props.showHideAll('show', 'noncompliant')}>
					{t('topology.showNonCompliant')}
				</Badge>
				<Badge
					color="danger"
					role="button"
					className="cursor-pointer  col-5 m-1  p-2"
					onClick={() => props.showHideAll('hide', 'noncompliant')}>
					{t('topology.hideNonCompliant')}
				</Badge>
			</CardHeader>
			<CardBody style={cardBodyBackgroundStyle}>
				<Container fluid>
					{props.legendItems.map((item, index) => (
						<LegendItem
							key={item.label}
							index={index}
							item={item}
							itemUpdate={props.itemUpdate}
						/>
					))}
				</Container>
			</CardBody>
		</Card>
	);
};

export default Legend;
