let index = 0;

const getIndex = () => index++;

export default {
	referenceData: {
		result: {
			defaultValue: 2,
			values: [
				{
					value: 1,
					label: 'Compliant',
				},
				{
					value: 2,
					label: 'Non-Compliant',
				},
			],
		},
		severity: {
			defaultValue: 3,
			values: [
				{
					value: 1,
					label: 'Low',
				},
				{
					value: 2,
					label: 'Medium',
				},
				{
					value: 3,
					label: 'High',
				},
				{
					value: '4',
					label: 'Critical',
				},
			],
		},
		categories: {
			'1.c': {
				parent: 'fn-6',
				name: 'Privilege Management',
				description: '',
				subcategories: [
					'11180.01c3System.6',
					'1143.01c1System.123',
					'1144.01c1System.4',
					'1145.01c2System.1',
					'1146.01c2System.23',
					'1147.01c2System.456',
					'1148.01c2System.78',
					'1149.01c2System.9',
					'1150.01c2System.10',
					'1151.01c3System.1',
					'1152.01c3System.2',
					'1153.01c3System.35',
					'1154.01c3System.4',
				],
			},
			'1.j': {
				parent: 'fn-6',
				name: 'User Authentication for External Connections',
				description: '',
				subcategories: [
					'1116.01j1Organizational.145',
					'1117.01j1Organizational.23',
					'1118.01j2Organizational.124',
					'1119.01j2Organizational.3',
					'1121.01j3Organizational.2',
					'1173.01j1Organizational.6',
					'1174.01j1Organizational.7',
					'1175.01j1Organizational.8',
					'1176.01j2Organizational.5',
					'1177.01j2Organizational.6',
					'1178.01j2Organizational.7',
					'1179.01j3Organizational.1',
				],
			},
			'1.l': {
				parent: 'fn-6',
				name: 'Remote Diagnostic and Configuration Port Protection.',
				description: '',
				subcategories: [
					'1192.01l1Organizational.1',
					'1193.01l2Organizational.13',
					'1194.01l2Organizational.2',
					'1195.01l3Organizational.1',
					'1196.01l3Organizational.24',
					'1197.01l3Organizational.3',
				],
			},
			'1.m': {
				parent: 'fn-6',
				name: 'Segregation in Networks',
				description: '',
				subcategories: [
					'0805.01m1Organizational.12',
					'0806.01m2Organizational.12356',
					'0894.01m2Organizational.7',
				],
			},
			'1.n': {
				parent: 'fn-6',
				name: 'Network Connection Control',
				description: '',
				subcategories: [
					'0809.01n2Organizational.1234',
					'0810.01n2Organizational.5',
					'0811.01n2Organizational.6',
					'0812.01n2Organizational.8',
					'0814.01n1Organizational.12',
				],
			},
			'1.q': {
				parent: 'fn-6',
				name: 'User Identification and Authentication',
				description: '',
				subcategories: [
					'11109.01q1Organizational.57',
					'11110.01q1Organizational.6',
					'11111.01q2System.4',
					'11112.01q2Organizational.67',
					'11208.01q1Organizational.8',
					'11210.01q2Organizational.10',
					'11211.01q2Organizational.11',
					'1123.01q1System.2',
					'1125.01q2System.1',
					'1127.01q2System.3',
				],
			},
			'5.i': {
				parent: 'fn-1',
				name: 'Identification of Risks Related to External Parties',
				description: '',
				subcategories: [
					'1401.05i1Organizational.1239',
					'1402.05i1Organizational.45',
					'1403.05i1Organizational.67',
					'1418.05i1Organizational.8',
					'1450.05i2Organizational.2',
					'1451.05iCSPOrganizational.2',
				],
			},
			'9.aa': {
				parent: 'fn-1',
				name: 'Audit Logging',
				description: '',
				subcategories: [
					'1202.09aa1System.1',
					'1203.09aa1System.2',
					'1204.09aa1System.3',
					'1205.09aa2System.1',
					'1206.09aa2System.23',
					'1207.09aa2System.4',
					'1208.09aa3System.1',
					'1209.09aa3System.2',
					'1210.09aa3System.3',
					'1211.09aa3System.4',
				],
			},
			'9.ab': {
				parent: 'fn-1',
				name: 'Monitoring System Use.',
				description: '',
				subcategories: [
					'1120.09ab3System.9',
					'12100.09ab2System.15',
					'12101.09ab1Organizational.3',
					'12102.09ab1Organizational.4',
					'1212.09ab1System.1',
					'1213.09ab2System.128',
					'1214.09ab2System.3456',
					'1215.09ab2System.7',
					'1216.09ab3System.12',
					'1217.09ab3System.3',
					'1219.09ab3System.10',
					'1220.09ab3System.56',
				],
			},
			'9.ad': {
				parent: 'fn-1',
				name: 'Administrator and Operator Logs.',
				description: '',
				subcategories: ['1270.09ad1System.12', '1271.09ad1System.1'],
			},
			'9.c': {
				parent: 'fn-1',
				name: 'Segregation of Duties',
				description: '',
				subcategories: [
					'1229.09c1Organizational.1',
					'1230.09c2Organizational.1',
					'1232.09c3Organizational.12',
					'1277.09c2Organizational.4',
				],
			},
			'9.j': {
				parent: 'fn-1',
				name: 'Controls Against Malicious Code',
				description: '',
				subcategories: ['0201.09j1Organizational.124'],
			},
			'9.l': {
				parent: 'fn-1',
				name: 'Back-up',
				description: '',
				subcategories: [
					'1616.09l1Organizational.16',
					'1617.09l1Organizational.23',
					'1618.09l1Organizational.45',
					'1619.09l1Organizational.7',
					'1620.09l1Organizational.8',
					'1621.09l2Organizational.1',
					'1622.09l2Organizational.23',
					'1623.09l2Organizational.4',
					'1624.09l3Organizational.12',
					'1625.09l3Organizational.34',
					'1626.09l3Organizational.5',
					'1627.09l3Organizational.6',
					'1699.09l1Organizational.10',
				],
			},
			'9.m': {
				parent: 'fn-1',
				name: 'Network Controls',
				description: '',
				subcategories: [
					'0858.09m1Organizational.4',
					'0859.09m1Organizational.78',
					'0860.09m1Organizational.9',
					'0861.09m2Organizational.67',
					'0862.09m2Organizational.8',
					'0863.09m2Organizational.910',
					'0864.09m2Organizational.12',
					'0865.09m2Organizational.13',
					'0866.09m3Organizational.1516',
					'0867.09m3Organizational.17',
					'0868.09m3Organizational.18',
					'0869.09m3Organizational.19',
					'0870.09m3Organizational.20',
					'0871.09m3Organizational.22',
				],
			},
			'9.n': {
				parent: 'fn-1',
				name: 'Security of Network Services',
				description: '',
				subcategories: [
					'0835.09n1Organizational.1',
					'0836.09.n2Organizational.1',
					'0837.09.n2Organizational.2',
					'0885.09n2Organizational.3',
					'0886.09n2Organizational.4',
					'0887.09n2Organizational.5',
					'0888.09n2Organizational.6',
				],
			},
			'9.o': {
				parent: 'fn-1',
				name: 'Management of Removable Media',
				description: '',
				subcategories: [
					'0301.09o1Organizational.123',
					'0302.09o2Organizational.1',
					'0304.09o3Organizational.1',
				],
			},
			'9.s': {
				parent: 'fn-1',
				name: 'Information Exchange Policies and Procedures',
				description: '',
				subcategories: [
					'0662.09sCSPOrganizational.2',
					'0901.09s1Organizational.1',
					'0902.09s2Organizational.13',
					'0911.09s1Organizational.2',
					'0912.09s1Organizational.4',
					'0913.09s1Organizational.5',
					'0914.09s1Organizational.6',
					'0915.09s2Organizational.2',
					'0916.09s2Organizational.4',
					'0960.09sCSPOrganizational.1',
					'1325.09s1Organizational.3',
				],
			},
			'9.y': {
				parent: 'fn-1',
				name: 'On-line Transactions',
				description: '',
				subcategories: [
					'0943.09y1Organizational.1',
					'0945.09y1Organizational.3',
					'0946.09y2Organizational.14',
					'0947.09y2Organizational.2',
					'0948.09y2Organizational.3',
					'0949.09y2Organizational.5',
				],
			},
			'10.h': {
				parent: 'fn-3',
				name: 'Control of Operational Software',
				description: '',
				subcategories: [
					'0605.10h1System.12',
					'0606.10h2System.1',
					'0607.10h2System.23',
				],
			},
			'10.k': {
				parent: 'fn-3',
				name: 'Change Control Procedures',
				description: '',
				subcategories: [
					'0635.10k1Organizational.12',
					'0636.10k2Organizational.1',
					'0637.10k2Organizational.2',
					'0638.10k2Organizational.34569',
					'0639.10k2Organizational.78',
					'0640.10k2Organizational.1012',
					'0641.10k2Organizational.11',
					'0642.10k3Organizational.12',
					'0643.10k3Organizational.3',
					'0644.10k3Organizational.4',
				],
			},
			'10.m': {
				parent: 'fn-3',
				name: 'Control of Technical Vulnerabilities',
				description: '',
				subcategories: [
					'0709.10m1Organizational.1',
					'0710.10m2Organizational.1',
					'0711.10m2Organizational.23',
					'0713.10m2Organizational.5',
					'0714.10m2Organizational.7',
					'0715.10m2Organizational.8',
					'0716.10m3Organizational.1',
					'0717.10m3Organizational.2',
					'0718.10m3Organizational.34',
					'0719.10m3Organizational.5',
				],
			},
			'12.b': {
				parent: 'fn-12',
				name: 'Business Continuity and Risk Assessment',
				description: '',
				subcategories: [
					'1634.12b1Organizational.1',
					'1635.12b1Organizational.2',
					'1637.12b2Organizational.2',
					'1638.12b2Organizational.345',
				],
			},
		},
		subcategories: {
			'11180.01c3System.6': {
				parent: '1.c',
				description:
					'Access to management functions or administrative consoles for systems hosting virtualized systems are restricted to personnel based upon the principle of least privilege and supported through technical controls.',
				index: getIndex(),
			},
			'1143.01c1System.123': {
				parent: '1.c',
				description:
					'Privileges are formally authorized and controlled, allocated to users on a need-to-use and event-by-event basis for their functional role (e.g., user or administrator), and documented for each system product/element.',
				index: getIndex(),
			},
			'1144.01c1System.4': {
				parent: '1.c',
				description:
					'The organization explicitly authorizes access to specific security relevant functions (deployed in hardware, software, and firmware) and security-relevant information.',
				index: getIndex(),
			},
			'1145.01c2System.1': {
				parent: '1.c',
				description:
					'Role-based access control is implemented and capable of mapping each user to one or more roles, and each role to one or more system functions.',
				index: getIndex(),
			},
			'1146.01c2System.23': {
				parent: '1.c',
				description:
					'The organization promotes the development and use of programs that avoid the need to run with elevated privileges and system routines to avoid the need to grant privileges to users.',
				index: getIndex(),
			},
			'1147.01c2System.456': {
				parent: '1.c',
				description:
					'Elevated privileges are assigned to a different user ID from those used for normal business use, all users access privileged services in a single role, and such privileged access is minimized.',
				index: getIndex(),
			},
			'1148.01c2System.78': {
				parent: '1.c',
				description:
					'The organization restricts access to privileged functions and all security-relevant information.',
				index: getIndex(),
			},
			'1149.01c2System.9': {
				parent: '1.c',
				description:
					"The organization facilitates information sharing by enabling authorized users to determine a business partner's access when discretion is allowed as defined by the organization and by employing manual processes or automated mechanisms to assist users in making information sharing/collaboration decisions.",
				index: getIndex(),
			},
			'1150.01c2System.10': {
				parent: '1.c',
				description:
					'The access control system for the system components storing, processing or transmitting covered information is set with a default deny-all setting.',
				index: getIndex(),
			},
			'1151.01c3System.1': {
				parent: '1.c',
				description:
					'The organization limits authorization to privileged accounts on information systems to a pre-defined subset of users.',
				index: getIndex(),
			},
			'1152.01c3System.2': {
				parent: '1.c',
				description:
					'The organization audits the execution of privileged functions on information systems and ensures information systems prevent non-privileged users from executing privileged functions.',
				index: getIndex(),
			},
			'1153.01c3System.35': {
				parent: '1.c',
				description:
					"All file system access not explicitly required is disabled, and only authorized users are permitted access to only that which is expressly required for the performance of the users' job duties.",
				index: getIndex(),
			},
			'1154.01c3System.4': {
				parent: '1.c',
				description:
					"Contractors are provided with minimal system and physical access only after the organization assesses the contractor's ability to comply with its security requirements and the contractor agrees to comply.",
				index: getIndex(),
			},
			'1116.01j1Organizational.145': {
				parent: '1.j',
				description:
					'Strong authentication methods such as multi-factor, Radius or Kerberos (for privileged access) and CHAP (for encryption of credentials for dialup methods) are implemented for all external connections to the organizations network.',
				index: getIndex(),
			},
			'1117.01j1Organizational.23': {
				parent: '1.j',
				description:
					'Remote access by vendors and business partners (e.g., for remote maintenance) is disabled/deactivated when not in use.',
				index: getIndex(),
			},
			'1118.01j2Organizational.124': {
				parent: '1.j',
				description:
					"Organizations implement encryption (e.g.  VPN solutions or private lines) and logs remote access to the organization's network by employees, contractors or third party (e.g., vendors).",
				index: getIndex(),
			},
			'1119.01j2Organizational.3': {
				parent: '1.j',
				description:
					'Network equipment is checked for unanticipated dial-up capabilities.',
				index: getIndex(),
			},
			'1121.01j3Organizational.2': {
				parent: '1.j',
				description:
					'Remote administration sessions are authorized, encrypted, and employ increased security measures.',
				index: getIndex(),
			},
			'1173.01j1Organizational.6': {
				parent: '1.j',
				description:
					'If encryption is not used for dial-up connections, the CIO or his/her designated representative provides specific written authorization.',
				index: getIndex(),
			},
			'1174.01j1Organizational.7': {
				parent: '1.j',
				description:
					'The organization protects wireless access to systems containing sensitive information by authenticating both users and devices.',
				index: getIndex(),
			},
			'1175.01j1Organizational.8': {
				parent: '1.j',
				description:
					'Remote access to business information across public networks only takes place after successful identification and authentication.',
				index: getIndex(),
			},
			'1176.01j2Organizational.5': {
				parent: '1.j',
				description:
					'The organization requires a callback capability with re-authentication to verify dial-up connections from authorized locations.',
				index: getIndex(),
			},
			'1177.01j2Organizational.6': {
				parent: '1.j',
				description:
					"User IDs assigned to vendors are reviewed in accordance with the organization's access review policy, at a minimum annually.",
				index: getIndex(),
			},
			'1178.01j2Organizational.7': {
				parent: '1.j',
				description:
					'Node authentication, including cryptographic techniques (e.g., machine certificates), serves as an alternative means of authenticating groups of remote users where they are connected to a secure, shared computer facility.',
				index: getIndex(),
			},
			'1179.01j3Organizational.1': {
				parent: '1.j',
				description:
					'The information system monitors and controls remote access methods.',
				index: getIndex(),
			},
			'1192.01l1Organizational.1': {
				parent: '1.l',
				description: 'Access to network equipment is physically protected.',
				index: getIndex(),
			},
			'1193.01l2Organizational.13': {
				parent: '1.l',
				description:
					'Controls for the access to diagnostic and configuration ports include the use of a key lock and the implementation of supporting procedures to control physical access to the port.',
				index: getIndex(),
			},
			'1194.01l2Organizational.2': {
				parent: '1.l',
				description:
					'Ports, services, and similar applications installed on a computer or network systems, which are not specifically required for business functionality, are disabled or removed.',
				index: getIndex(),
			},
			'1195.01l3Organizational.1': {
				parent: '1.l',
				description:
					'The organization reviews the information system within every three hundred and sixty- five (365) days to identify and disables unnecessary and non-secure functions, ports, protocols, and/or services.',
				index: getIndex(),
			},
			'1196.01l3Organizational.24': {
				parent: '1.l',
				description:
					'The organization identifies unauthorized (blacklisted) software on the information system, prevents program execution in accordance with a list of unauthorized (blacklisted) software programs, employs an allow-all, deny-by exception policy to prohibit execution of known unauthorized (blacklisted) software, and reviews and updates the list of unauthorized (blacklisted) software programs annually.',
				index: getIndex(),
			},
			'1197.01l3Organizational.3': {
				parent: '1.l',
				description:
					'The organization disables Bluetooth and peer-to-peer networking protocols within the information system determined to be unnecessary or non-secure.',
				index: getIndex(),
			},
			'0805.01m1Organizational.12': {
				parent: '1.m',
				description:
					"The organization's security gateways (e.g. firewalls) enforce security policies and are configured to filter traffic between domains, block unauthorized access, and are used to maintain segregation between internal wired, internal wireless, and external network segments (e.g., the Internet) including DMZs and enforce access control policies for each of the domains.",
				index: getIndex(),
			},
			'0806.01m2Organizational.12356': {
				parent: '1.m',
				description:
					'The organizations network is logically and physically segmented with a defined security perimeter and a graduated set of controls, including subnetworks for publicly accessible system components that are logically separated from the internal network, based on organizational requirements; and traffic is controlled based on functionality required and classification of the data/systems based on a risk assessment and their respective security requirements.',
				index: getIndex(),
			},
			'0894.01m2Organizational.7': {
				parent: '1.m',
				description:
					'Networks are segregated from production-level networks when migrating physical servers, applications or data to virtualized servers.',
				index: getIndex(),
			},
			'0809.01n2Organizational.1234': {
				parent: '1.n',
				description:
					"Network traffic is controlled in accordance with the organizations access control policy through firewall and other network-related restrictions for each network access point or external telecommunication service's managed interface.",
				index: getIndex(),
			},
			'0810.01n2Organizational.5': {
				parent: '1.n',
				description:
					'Transmitted information is secured and, at a minimum, encrypted over open, public networks.',
				index: getIndex(),
			},
			'0811.01n2Organizational.6': {
				parent: '1.n',
				description:
					'Exceptions to the traffic flow policy are documented with a supporting mission/business need, duration of the exception, and reviewed at least annually; traffic flow policy exceptions are removed when no longer supported by an explicit mission/business need.',
				index: getIndex(),
			},
			'0812.01n2Organizational.8': {
				parent: '1.n',
				description:
					'Remote devices establishing a non-remote connection are not allowed to communicate with external (remote) resources.',
				index: getIndex(),
			},
			'0814.01n1Organizational.12': {
				parent: '1.n',
				description:
					'The ability of users to connect to the internal network is restricted using a deny-by-default and allow-by-exception policy at managed interfaces according to the access control policy and the requirements of clinical and business applications.',
				index: getIndex(),
			},
			'11109.01q1Organizational.57': {
				parent: '1.q',
				description:
					'The organization ensures that redundant user IDs are not issued to other users and that all users are uniquely identified and authenticated for both local and remote access to information systems.',
				index: getIndex(),
			},
			'11110.01q1Organizational.6': {
				parent: '1.q',
				description:
					"Non-organizational users (all information system users other than organizational users, such as patients, customers, contractors, or foreign nationals), or processes acting on behalf of non-organizational users, determined to need access to information residing on the organization's information systems, are uniquely identified and authenticated.",
				index: getIndex(),
			},
			'11111.01q2System.4': {
				parent: '1.q',
				description:
					'When PKI-based authentication is used, the information system validates certificates by constructing and verifying a certification path to an accepted trust anchor, including checking certificate status information; enforces access to the corresponding private key; maps the identity to the corresponding account of the individual or group; and implements a local cache of revocation data to support path discovery and validation in case of an inability to access revocation information via the network.',
				index: getIndex(),
			},
			'11112.01q2Organizational.67': {
				parent: '1.q',
				description:
					'The information system employs replay-resistant authentication mechanisms such as nonce, one-time passwords, or time stamps to secure network access for privileged accounts; and, for hardware token-based authentication, employs mechanisms that satisfy minimum token requirements discussed in NIST SP 800-63-2, Electronic Authentication Guideline.',
				index: getIndex(),
			},
			'11208.01q1Organizational.8': {
				parent: '1.q',
				description:
					'The organization requires that electronic signatures, unique to one individual, cannot be reused by, or reassigned to, anyone else.',
				index: getIndex(),
			},
			'11210.01q2Organizational.10': {
				parent: '1.q',
				description:
					'Electronic signatures and handwritten signatures executed to electronic records shall be linked to their respective electronic records.',
				index: getIndex(),
			},
			'11211.01q2Organizational.11': {
				parent: '1.q',
				description:
					'Signed electronic records shall contain information associated with the signing in human-readable format.',
				index: getIndex(),
			},
			'1123.01q1System.2': {
				parent: '1.q',
				description:
					'Users who performed privileged functions (e.g., system administration) use separate accounts when performing those privileged functions.',
				index: getIndex(),
			},
			'1125.01q2System.1': {
				parent: '1.q',
				description:
					'Multi-factor authentication methods are used in accordance with organizational policy, (e.g., for remote network access).',
				index: getIndex(),
			},
			'1127.01q2System.3': {
				parent: '1.q',
				description:
					'Where tokens are provided for multi-factor authentication, in-person verification is required prior to granting access.',
				index: getIndex(),
			},
			'1401.05i1Organizational.1239': {
				parent: '5.i',
				description:
					'Access to the organizations information and systems by external parties is not permitted until due diligence has been conducted, the appropriate controls have been implemented, and a contract/agreement reflecting the security requirements is signed acknowledging they understand and accept their obligations.',
				index: getIndex(),
			},
			'1402.05i1Organizational.45': {
				parent: '5.i',
				description:
					'Remote access connections between the organization and external parties are encrypted.',
				index: getIndex(),
			},
			'1403.05i1Organizational.67': {
				parent: '5.i',
				description:
					'Access granted to external parties is limited to the minimum necessary and granted only for the duration required.',
				index: getIndex(),
			},
			'1418.05i1Organizational.8': {
				parent: '5.i',
				description:
					'The identification of risks related to external party access takes into account a minimal set of specifically defined issues.',
				index: getIndex(),
			},
			'1450.05i2Organizational.2': {
				parent: '5.i',
				description:
					'The organization obtains satisfactory assurances that reasonable information security exists across their information supply chain by performing an annual review, which includes all partners/third party-providers upon which their information supply chain depends.',
				index: getIndex(),
			},
			'1451.05iCSPOrganizational.2': {
				parent: '5.i',
				description:
					'Cloud service providers design and implement controls to mitigate and contain data security risks through proper separation of duties, role-based access, and least-privilege access for all personnel within their supply chain.',
				index: getIndex(),
			},
			'1202.09aa1System.1': {
				parent: '9.aa',
				description:
					'A secure audit record is created for all activities on the system (create, read, update, delete) involving covered information.',
				index: getIndex(),
			},
			'1203.09aa1System.2': {
				parent: '9.aa',
				description:
					'Audit records include the unique user ID, unique data subject ID, function performed, and date/time the event was performed.',
				index: getIndex(),
			},
			'1204.09aa1System.3': {
				parent: '9.aa',
				description:
					'The activities of privileged users (administrators, operators, etc.) include the success/failure of the event, time the event occurred, the account involved, the processes involved, and additional information about the event.',
				index: getIndex(),
			},
			'1205.09aa2System.1': {
				parent: '9.aa',
				description:
					'Logs of messages sent and received are maintained including the date, time, origin and destination of the message, but not its contents.',
				index: getIndex(),
			},
			'1206.09aa2System.23': {
				parent: '9.aa',
				description:
					'Auditing is always available while the system is active and tracks key events, success/failed data access, system security configuration changes, privileged or utility use, any alarms raised,  activation and de-activation of protection systems (e.g., A/V and IDS), activation and deactivation of identification and authentication mechanisms, and creation and deletion of system-level objects.',
				index: getIndex(),
			},
			'1207.09aa2System.4': {
				parent: '9.aa',
				description:
					'Audit records are retained for 90 days and older audit records are archived for one year.',
				index: getIndex(),
			},
			'1208.09aa3System.1': {
				parent: '9.aa',
				description:
					'Audit logs are maintained for management activities, system and application startup/shutdown/errors, file changes, and security policy changes.',
				index: getIndex(),
			},
			'1209.09aa3System.2': {
				parent: '9.aa',
				description:
					'The information system generates audit records containing the following detailed information',
				index: getIndex(),
			},
			'1210.09aa3System.3': {
				parent: '9.aa',
				description:
					'All disclosures of covered information within or outside of the organization are logged including type of disclosure, date/time of the event, recipient, and sender.',
				index: getIndex(),
			},
			'1211.09aa3System.4': {
				parent: '9.aa',
				description:
					'The organization verifies every ninety (90) days for each extract of covered information recorded that the data is erased or its use is still required.',
				index: getIndex(),
			},
			'1120.09ab3System.9': {
				parent: '9.ab',
				description:
					'Unauthorized remote connections to the information systems are monitored and reviewed at least quarterly, and appropriate action is taken if an unauthorized connection is discovered.',
				index: getIndex(),
			},
			'12100.09ab2System.15': {
				parent: '9.ab',
				description:
					'The organization monitors the information system to identify irregularities or anomalies that are indicators of a system malfunction or compromise and help confirm the system is functioning in an optimal, resilient and secure state.',
				index: getIndex(),
			},
			'12101.09ab1Organizational.3': {
				parent: '9.ab',
				description:
					'The organization specifies how often audit logs are reviewed, how the reviews are documented, and the specific roles and responsibilities of the personnel conducting the reviews, including the professional certifications or other qualifications required.',
				index: getIndex(),
			},
			'12102.09ab1Organizational.4': {
				parent: '9.ab',
				description:
					'The organization shall periodically test its monitoring and detection processes, remediate deficiencies, and improve its processes.',
				index: getIndex(),
			},
			'1212.09ab1System.1': {
				parent: '9.ab',
				description:
					'All applicable legal requirements related to monitoring authorized access and unauthorized access attempts are met.',
				index: getIndex(),
			},
			'1213.09ab2System.128': {
				parent: '9.ab',
				description:
					"Automated systems deployed throughout the organization's environment are used to monitor key events and anomalous activity, and analyze system logs, the results of which are reviewed regularly.",
				index: getIndex(),
			},
			'1214.09ab2System.3456': {
				parent: '9.ab',
				description:
					'Monitoring includes privileged operations, authorized access or unauthorized access attempts, including attempts to access deactivated accounts, and system alerts or failures.',
				index: getIndex(),
			},
			'1215.09ab2System.7': {
				parent: '9.ab',
				description:
					'Auditing and monitoring systems employed by the organization support audit reduction and report generation.',
				index: getIndex(),
			},
			'1216.09ab3System.12': {
				parent: '9.ab',
				description:
					'Automated systems are used to review monitoring activities of security systems (e.g., IPS/IDS) and system records on a daily basis, and identify and document anomalies.',
				index: getIndex(),
			},
			'1217.09ab3System.3': {
				parent: '9.ab',
				description:
					'Alerts are generated for technical personnel to analyze and investigate suspicious activity or suspected violations.',
				index: getIndex(),
			},
			'1219.09ab3System.10': {
				parent: '9.ab',
				description:
					'The information system is able to automatically process audit records for events of interest based on selectable criteria.',
				index: getIndex(),
			},
			'1220.09ab3System.56': {
				parent: '9.ab',
				description:
					'Monitoring includes inbound and outbound communications and file integrity monitoring.',
				index: getIndex(),
			},
			'1270.09ad1System.12': {
				parent: '9.ad',
				description:
					'The organization ensures proper logging is enabled in order to audit administrator activities; and reviews system administrator and operator logs on a regular basis.',
				index: getIndex(),
			},
			'1271.09ad1System.1': {
				parent: '9.ad',
				description:
					'An intrusion detection system managed outside of the control of system and network administrators is used to monitor system and network administration activities for compliance.',
				index: getIndex(),
			},
			'1229.09c1Organizational.1': {
				parent: '9.c',
				description:
					'Separation of duties is used to limit the risk of unauthorized or unintentional modification of information and systems.',
				index: getIndex(),
			},
			'1230.09c2Organizational.1': {
				parent: '9.c',
				description:
					'No single person is able to access, modify, or use information systems without authorization or detection.',
				index: getIndex(),
			},
			'1232.09c3Organizational.12': {
				parent: '9.c',
				description:
					"Access for individuals responsible for administering  access controls is limited to the minimum necessary based upon each user's role and responsibilities and these individuals cannot access audit functions related to these controls.",
				index: getIndex(),
			},
			'1277.09c2Organizational.4': {
				parent: '9.c',
				description:
					'The initiation of an event is separated from its authorization to reduce the possibility of collusion.',
				index: getIndex(),
			},
			'0201.09j1Organizational.124': {
				parent: '9.j',
				description:
					'Anti-virus and anti-spyware are installed, operating and updated on all end-user devices to conduct periodic scans of the systems to identify and remove unauthorized software. Server environments for which the server software developer specifically recommends not installing host-based anti-virus and anti-spyware software may address the requirement via a network-based malware detection (NBMD) solution.',
				index: getIndex(),
			},
			'1616.09l1Organizational.16': {
				parent: '9.l',
				description:
					'Backup copies of information and software are made and tests of the media and restoration procedures are regularly performed at appropriate intervals.',
				index: getIndex(),
			},
			'1617.09l1Organizational.23': {
				parent: '9.l',
				description:
					'A formal definition of the level of backup required for each system is defined and documented including how each system will be restored, the scope of data to be imaged, frequency of imaging, and duration of retention based on relevant contractual, legal, regulatory and business requirements.',
				index: getIndex(),
			},
			'1618.09l1Organizational.45': {
				parent: '9.l',
				description:
					'The backups are stored in a physically secure remote location, at a sufficient distance to make them reasonably immune from damage to data at the primary site, and reasonable physical and environmental controls are in place to ensure their protection at the remote location.',
				index: getIndex(),
			},
			'1619.09l1Organizational.7': {
				parent: '9.l',
				description:
					'Inventory records for the backup copies, including content and current location, are maintained.',
				index: getIndex(),
			},
			'1620.09l1Organizational.8': {
				parent: '9.l',
				description:
					'When the backup service is delivered by the third party, the service level agreement includes the detailed protections to control confidentiality, integrity and availability of the backup information.',
				index: getIndex(),
			},
			'1621.09l2Organizational.1': {
				parent: '9.l',
				description: 'Automated tools are used to track all backups.',
				index: getIndex(),
			},
			'1622.09l2Organizational.23': {
				parent: '9.l',
				description:
					'The integrity and security of the backup copies are maintained to ensure future availability, and any potential accessibility problems with the backup copies are identified and mitigated in the event of an area-wide disaster.',
				index: getIndex(),
			},
			'1623.09l2Organizational.4': {
				parent: '9.l',
				description:
					'Covered information is backed-up in an encrypted format to ensure confidentiality.',
				index: getIndex(),
			},
			'1624.09l3Organizational.12': {
				parent: '9.l',
				description:
					'The organization performs incremental or differential backups daily and full backups weekly to separate media.',
				index: getIndex(),
			},
			'1625.09l3Organizational.34': {
				parent: '9.l',
				description:
					'Three (3) generations of backups (full plus all related incremental or differential backups) are stored off-site, and both on-site and off-site backups are logged with name, date, time and action.',
				index: getIndex(),
			},
			'1626.09l3Organizational.5': {
				parent: '9.l',
				description:
					'The organization ensures a current, retrievable copy of covered information is available before movement of servers.',
				index: getIndex(),
			},
			'1627.09l3Organizational.6': {
				parent: '9.l',
				description:
					'The organization tests backup information following each backup to verify media reliability and information integrity, and at least annually thereafter.',
				index: getIndex(),
			},
			'1699.09l1Organizational.10': {
				parent: '9.l',
				description:
					'Workforce members roles and responsibilities in the data backup process are identified and communicated to the workforce; in particular, Bring Your Own Device (BYOD) users are required to perform backups of organizational and/or client data on their devices.',
				index: getIndex(),
			},
			'0858.09m1Organizational.4': {
				parent: '9.m',
				description:
					'The organization monitors for all authorized and unauthorized wireless access to the information system and prohibits installation of wireless access points (WAPs) unless explicitly authorized in writing by the CIO or his/her designated representative.',
				index: getIndex(),
			},
			'0859.09m1Organizational.78': {
				parent: '9.m',
				description:
					'The organization ensures the security of information in networks, availability of network services and information services using the network, and the protection of connected services from unauthorized access.',
				index: getIndex(),
			},
			'0860.09m1Organizational.9': {
				parent: '9.m',
				description:
					'The organization formally manages equipment on the network, including equipment in user areas.',
				index: getIndex(),
			},
			'0861.09m2Organizational.67': {
				parent: '9.m',
				description:
					'To identify and authenticate devices on local and/or wide area networks, including wireless networks,  the information system uses either a (i) shared known information solution or (ii) an organizational authentication solution, the exact selection and strength of which is dependent on the security categorization of the information system.',
				index: getIndex(),
			},
			'0862.09m2Organizational.8': {
				parent: '9.m',
				description:
					'The organization ensures information systems protect the confidentiality and integrity of transmitted information, including during preparation for transmission and during reception.',
				index: getIndex(),
			},
			'0863.09m2Organizational.910': {
				parent: '9.m',
				description:
					'The organization builds a firewall configuration that restricts connections between un-trusted networks and any system components in the covered information environment; and any changes to the firewall configuration are updated in the network diagram.',
				index: getIndex(),
			},
			'0864.09m2Organizational.12': {
				parent: '9.m',
				description:
					'Usage restrictions and implementation guidance are formally defined for VoIP, including the authorization and monitoring of the service.',
				index: getIndex(),
			},
			'0865.09m2Organizational.13': {
				parent: '9.m',
				description:
					'The organization (i) authorizes connections from the information system to other information systems outside of the organization through the use of interconnection security agreements or other formal agreement; (ii) documents each connection, the interface characteristics, security requirements, and the nature of the information communicated; (iii) employs a deny all, permit by exception policy for allowing connections from the information system to other information systems outside of the organization; and (iv) applies a default-deny rule that drops all traffic via host-based firewalls or port filtering tools on its endpoints (workstations, servers, etc.), except those services and ports that are explicitly allowed.',
				index: getIndex(),
			},
			'0866.09m3Organizational.1516': {
				parent: '9.m',
				description:
					'The organization describes the groups, roles, and responsibilities for the logical management of network components and ensures coordination of and consistency in the elements of the network infrastructure.',
				index: getIndex(),
			},
			'0867.09m3Organizational.17': {
				parent: '9.m',
				description:
					'Wireless access points are placed in secure areas and shut down when not in use (e.g. nights, weekends).',
				index: getIndex(),
			},
			'0868.09m3Organizational.18': {
				parent: '9.m',
				description:
					'The organization builds a firewall configuration to restrict inbound and outbound traffic to that which is necessary for the covered data environment.',
				index: getIndex(),
			},
			'0869.09m3Organizational.19': {
				parent: '9.m',
				description:
					'The router configuration files are secured and synchronized.',
				index: getIndex(),
			},
			'0870.09m3Organizational.20': {
				parent: '9.m',
				description:
					'Access to all proxies is denied, except for those hosts, ports, and services that are explicitly required.',
				index: getIndex(),
			},
			'0871.09m3Organizational.22': {
				parent: '9.m',
				description:
					'Authoritative DNS servers are segregated into internal and external roles.',
				index: getIndex(),
			},
			'0835.09n1Organizational.1': {
				parent: '9.n',
				description:
					'Agreed services provided by a network service provider/manager are formally managed and monitored to ensure they are provided securely.',
				index: getIndex(),
			},
			'0836.09.n2Organizational.1': {
				parent: '9.n',
				description:
					'The organization formally authorizes and documents the characteristics of each connection from an information system to other information systems outside the organization.',
				index: getIndex(),
			},
			'0837.09.n2Organizational.2': {
				parent: '9.n',
				description:
					'Formal agreements with external information system providers include specific obligations for security and privacy.',
				index: getIndex(),
			},
			'0885.09n2Organizational.3': {
				parent: '9.n',
				description:
					'The organization reviews and updates the interconnection security agreements on an ongoing basis verifying enforcement of security requirements.',
				index: getIndex(),
			},
			'0886.09n2Organizational.4': {
				parent: '9.n',
				description:
					'The organization employs and documents in a formal agreement or other document, either i) allow-all, deny-by-exception, or, ii) deny-all, permit-by-exception (preferred), policy for allowing specific information systems to connect to external information systems.',
				index: getIndex(),
			},
			'0887.09n2Organizational.5': {
				parent: '9.n',
				description:
					'The organization requires external/outsourced service providers to identify the specific functions, ports, and protocols used in the provision of the external/outsourced services.',
				index: getIndex(),
			},
			'0888.09n2Organizational.6': {
				parent: '9.n',
				description:
					'The contract with the external/outsourced service provider includes the specification that the service provider is responsible for the protection of covered information shared.',
				index: getIndex(),
			},
			'0301.09o1Organizational.123': {
				parent: '9.o',
				description:
					'The organization, based on the data classification level, registers media (including laptops) prior to use, places reasonable restrictions on how such media be used, and provides an appropriate level of physical and logical protection (including encryption) for media containing covered information until properly destroyed or sanitized.',
				index: getIndex(),
			},
			'0302.09o2Organizational.1': {
				parent: '9.o',
				description:
					'The organization protects and controls media containing sensitive information during transport outside of controlled areas.',
				index: getIndex(),
			},
			'0304.09o3Organizational.1': {
				parent: '9.o',
				description:
					'The organization restricts the use of writable removable media and personally-owned removable media in organizational systems.',
				index: getIndex(),
			},
			'0662.09sCSPOrganizational.2': {
				parent: '9.s',
				description:
					'Cloud service providers use an industry-recognized virtualization platform and standard virtualization formats (e.g., Open Virtualization Format, OVF) to help ensure interoperability, and has documented custom changes made to any hypervisor in use and all solution-specific virtualization hooks available for customer review.',
				index: getIndex(),
			},
			'0901.09s1Organizational.1': {
				parent: '9.s',
				description:
					'The organization formally addresses multiple safeguards before allowing the use of information systems for information exchange.',
				index: getIndex(),
			},
			'0902.09s2Organizational.13': {
				parent: '9.s',
				description:
					"Remote (external) access to the organization's information assets and access to external information assets (for which the organization has no control) is based on clearly defined terms and conditions.",
				index: getIndex(),
			},
			'0911.09s1Organizational.2': {
				parent: '9.s',
				description:
					'The organization establishes terms and conditions, consistent with any trust relationship established with other organizations owning, operating, and/or maintaining external information systems, allowing authorized individuals to (i) access the information system from external information systems; and (ii) process, store or transmit organization-controlled information using external information systems.',
				index: getIndex(),
			},
			'0912.09s1Organizational.4': {
				parent: '9.s',
				description:
					'Cryptography is used to protect the confidentiality and integrity of remote access sessions to the internal network and to external systems.',
				index: getIndex(),
			},
			'0913.09s1Organizational.5': {
				parent: '9.s',
				description:
					'Strong cryptography protocols are used to safeguard covered information during transmission over less trusted / open public networks.',
				index: getIndex(),
			},
			'0914.09s1Organizational.6': {
				parent: '9.s',
				description:
					'The organization ensures that communication protection requirements, including the security of exchanges of information, is the subject of policy development and compliance audits.',
				index: getIndex(),
			},
			'0915.09s2Organizational.2': {
				parent: '9.s',
				description:
					'The organization limits the use of organization-controlled portable storage media by authorized individuals on external information systems.',
				index: getIndex(),
			},
			'0916.09s2Organizational.4': {
				parent: '9.s',
				description:
					'The information system prohibits remote activation of collaborative computing devices and provides an explicit indication of use to users physically present at the devices.',
				index: getIndex(),
			},
			'0960.09sCSPOrganizational.1': {
				parent: '9.s',
				description:
					'Cloud service providers use secure (e.g., non-clear text and authenticated) standardized network protocols for the import and export of data and to manage the service, and make available a document to consumers (tenants) detailing the relevant interoperability and portability standards that are involved.',
				index: getIndex(),
			},
			'1325.09s1Organizational.3': {
				parent: '9.s',
				description:
					'Personnel are appropriately trained on leading principles and practices for all types of information exchange (oral, paper and electronic).',
				index: getIndex(),
			},
			'0943.09y1Organizational.1': {
				parent: '9.y',
				description:
					'Data involved in electronic commerce and online transactions is checked to determine if it contains covered information.',
				index: getIndex(),
			},
			'0945.09y1Organizational.3': {
				parent: '9.y',
				description:
					'Protocols used to communicate between all involved parties are secured using cryptographic techniques (e.g., SSL).',
				index: getIndex(),
			},
			'0946.09y2Organizational.14': {
				parent: '9.y',
				description:
					'The organization requires the use of encryption between, and the use of electronic signatures by, each of the parties involved in the transaction.',
				index: getIndex(),
			},
			'0947.09y2Organizational.2': {
				parent: '9.y',
				description:
					"The organization ensures the storage of the transaction details are located outside of any publicly accessible environments (e.g., on a storage platform existing on the organization's intranet) and not retained and exposed on a storage medium directly accessible from the Internet.",
				index: getIndex(),
			},
			'0948.09y2Organizational.3': {
				parent: '9.y',
				description:
					'Where a trusted authority is used (e.g., for the purposes of issuing and maintaining digital signatures and/or digital certificates), security is integrated and embedded throughout the entire end-to-end certificate/signature management process.',
				index: getIndex(),
			},
			'0949.09y2Organizational.5': {
				parent: '9.y',
				description:
					'The protocols used for communications are enhanced to address any new vulnerability, and the updated versions of the protocols are adopted as soon as possible.',
				index: getIndex(),
			},
			'0605.10h1System.12': {
				parent: '10.h',
				description:
					'Only authorized administrators are allowed to implement approved upgrades to software, applications, and program libraries, based on business requirements and the security implications of the release.',
				index: getIndex(),
			},
			'0606.10h2System.1': {
				parent: '10.h',
				description:
					'Applications and operating systems are successfully tested for usability, security and impact prior to production.',
				index: getIndex(),
			},
			'0607.10h2System.23': {
				parent: '10.h',
				description:
					'The organization uses its configuration control program to maintain control of all implemented software and its system documentation and archive prior versions of implemented software and associated system documentation.',
				index: getIndex(),
			},
			'0635.10k1Organizational.12': {
				parent: '10.k',
				description:
					'Managers responsible for application systems are also responsible for the strict control (security) of the project or support environment and ensure that all proposed system changes are reviewed to check that they do not compromise the security of either the system or the operating environment.',
				index: getIndex(),
			},
			'0636.10k2Organizational.1': {
				parent: '10.k',
				description:
					'The organization formally addresses purpose, scope, roles, responsibilities, management commitment, coordination among organizational entities, and compliance for configuration management (e.g., through policies, standards, processes).',
				index: getIndex(),
			},
			'0637.10k2Organizational.2': {
				parent: '10.k',
				description:
					'The organization has developed, documented, and implemented a configuration management plan for the information system.',
				index: getIndex(),
			},
			'0638.10k2Organizational.34569': {
				parent: '10.k',
				description:
					'Changes are formally controlled, documented and enforced in order to minimize the corruption of information systems.',
				index: getIndex(),
			},
			'0639.10k2Organizational.78': {
				parent: '10.k',
				description:
					'Installation checklists and vulnerability scans are used to validate the configuration of servers, workstations, devices and appliances and ensure the configuration meets minimum standards.',
				index: getIndex(),
			},
			'0640.10k2Organizational.1012': {
				parent: '10.k',
				description:
					'Where development is outsourced, change control procedures to address security are included in the contract(s) and specifically require the developer to track security flaws and flaw resolution within the system, component, or service and report findings to organization-defined personnel or roles.',
				index: getIndex(),
			},
			'0641.10k2Organizational.11': {
				parent: '10.k',
				description:
					'The organization does not use automated updates on critical systems.',
				index: getIndex(),
			},
			'0642.10k3Organizational.12': {
				parent: '10.k',
				description:
					'The organization develops, documents, and maintains, under configuration control, a current baseline configuration of the information system, and reviews and updates the baseline as required.',
				index: getIndex(),
			},
			'0643.10k3Organizational.3': {
				parent: '10.k',
				description:
					'The organization (i) establishes and documents mandatory configuration settings for information technology products employed within the information system using the latest security configuration baselines; (ii) identifies, documents, and approves exceptions from the mandatory established configuration settings for individual components based on explicit operational requirements; and (iii) monitors and controls changes to the configuration settings in accordance with organizational policies and procedures.',
				index: getIndex(),
			},
			'0644.10k3Organizational.4': {
				parent: '10.k',
				description:
					'The organization employs automated mechanisms to (i) centrally manage, apply, and verify configuration settings; (ii) respond to unauthorized changes to network and system security-related configuration settings; and (iii) enforce access restrictions and auditing of the enforcement actions.',
				index: getIndex(),
			},
			'0709.10m1Organizational.1': {
				parent: '10.m',
				description:
					'Technical vulnerabilities are identified, evaluated for risk and corrected in a timely manner.',
				index: getIndex(),
			},
			'0710.10m2Organizational.1': {
				parent: '10.m',
				description:
					'A hardened configuration standard exists for all system and network components.',
				index: getIndex(),
			},
			'0711.10m2Organizational.23': {
				parent: '10.m',
				description:
					'A technical vulnerability management program is in place to monitor, assess, rank, and remediate vulnerabilities identified in systems.',
				index: getIndex(),
			},
			'0713.10m2Organizational.5': {
				parent: '10.m',
				description:
					'Patches are tested and evaluated before they are installed.',
				index: getIndex(),
			},
			'0714.10m2Organizational.7': {
				parent: '10.m',
				description:
					'The technical vulnerability management program is evaluated on a quarterly basis.',
				index: getIndex(),
			},
			'0715.10m2Organizational.8': {
				parent: '10.m',
				description:
					'Systems are appropriately hardened (e.g., configured with only necessary and secure services, ports and protocols enabled).',
				index: getIndex(),
			},
			'0716.10m3Organizational.1': {
				parent: '10.m',
				description:
					'The organization conducts an enterprise security posture review as needed but no less than once within every three-hundred-sixty-five (365) days, in accordance with organizational IS procedures.',
				index: getIndex(),
			},
			'0717.10m3Organizational.2': {
				parent: '10.m',
				description:
					'Vulnerability scanning tools include the capability to readily update the information system vulnerabilities scanned.',
				index: getIndex(),
			},
			'0718.10m3Organizational.34': {
				parent: '10.m',
				description:
					'The organization scans for vulnerabilities in the information system and hosted applications to determine the state of flaw remediation monthly (automatically) and again (manually or automatically) when new vulnerabilities potentially affecting the systems and networked environments are identified and reported.',
				index: getIndex(),
			},
			'0719.10m3Organizational.5': {
				parent: '10.m',
				description:
					'The organization updates the list of information system vulnerabilities scanned within every thirty (30) days or when new vulnerabilities are identified and reported.',
				index: getIndex(),
			},
			'1634.12b1Organizational.1': {
				parent: '12.b',
				description:
					'The organization identifies the critical business processes requiring business continuity.',
				index: getIndex(),
			},
			'1635.12b1Organizational.2': {
				parent: '12.b',
				description:
					"Information security aspects of business continuity are (i) based on identifying events (or sequence of events) that can cause interruptions to the organization's critical business processes (e.g., equipment failure, human errors, theft, fire, natural disasters acts of terrorism); (ii) followed by a risk assessment to determine the probability and impact of such interruptions, in terms of time, damage scale and recovery period; (iii) based on the results of the risk assessment, a business continuity strategy is developed to identify the overall approach to business continuity; and (iv) once this strategy has been created, endorsement is provided by management, and a plan created and endorsed to implement this strategy.",
				index: getIndex(),
			},
			'1637.12b2Organizational.2': {
				parent: '12.b',
				description:
					'Business impact analysis are used to evaluate the consequences of disasters, security failures, loss of service, and service availability.',
				index: getIndex(),
			},
			'1638.12b2Organizational.345': {
				parent: '12.b',
				description:
					'Business continuity risk assessments (i) are carried out annually with full involvement from owners of business resources and processes; (ii) consider all business processes and is not limited to the information assets, but includes the results specific to information security; and (iii) identifies, quantifies, and prioritizes risks against key business objectives and criteria relevant to the organization, including critical resources, impacts of disruptions, allowable outage times, and recovery priorities.',
				index: getIndex(),
			},
		},
	},
	headers: [
		{
			id: 1,
			value: 'Safeguards',
			classList: 'dont-print col col-md-2 text-md-center',
			style: null,
		},
		{
			id: 2,
			value: 'Implementation Specification',
			classList: 'col col-md-2 text-md-center',
			style: null,
		},
		{
			id: 3,
			value: 'Assessment',
			classList: 'print-sub-cat-col col col-md-2 text-md-center',
			style: null,
		},
		{
			id: 4,
			value: 'Result',
			classList: 'print-result-col col col-md-2 text-md-center',
			style: {
				marginLeft: '-1rem',
			},
		},
		{
			id: 5,
			value: 'Severity',
			classList: 'col col-md-1 text-md-left',
			style: {
				marginLeft: '3rem',
			},
		},
		{
			id: 6,
			value: 'Evidence',
			classList: 'col col-md-1 text-md-center',
			style: {
				marginLeft: '6rem',
			},
		},
	],
	testCases: [
		{
			id: 'fn-6',
			value: '1. Mobile Computing and Communications',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: ['1. Mobile Computing and Communications'],
			categories: ['1.c', '1.j', '1.l', '1.j', '1.l', '1.m', '1.n', '1.q'],
		},
		{
			id: 'fn-1',
			value: '5. Management Commitment to Information Security',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: ['5. Management Commitment to Information Security'],
			categories: ['5.i'],
		},
		{
			id: 'fn-11',
			value: '9. Controls Against Malicious Code',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: ['9. Controls Against Malicious Code'],
			categories: [
				'9.aa',
				'9.ab',
				'9.ad',
				'9.c',
				'9.j',
				'9.l',
				'9.m',
				'9.n',
				'9.o',
				'9.s',
				'9.y',
			],
		},
		{
			id: 'fn-3',
			value: '10. Control of Operational Software',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: ['10. Control of Operational Software'],
			categories: ['10.h', '10.k', '10.m'],
		},
		{
			id: 'fn-12',
			value:
				'12. Developing and Implementing Continuity Plans Including Information Security',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [
				'12. Developing and Implementing Continuity Plans Including Information Security',
			],
			categories: ['12.b'],
		},
	],
};
