import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import CardHeader from 'reactstrap/lib/CardHeader';
import Header from '../../../../components/Header';
import HeaderTitle from '../../../../components/HeaderTitle';
import MagicSvg from '../../../../assets/img/fe_magic.svg';
import Arrow from '../../../../assets/img/Vector.svg';
import Data from '../../../../assets/img/data.svg';
import Review from '../../../../assets/img/review.svg';
import Analysis from '../../../../assets/img/analysic.svg';
import React from 'react';
import { Stages } from '../../Common/AssessmentStage';
import useRender from '../../Hooks/useRender';
import './styles.css';
import { useTranslation } from 'react-i18next';

const Info = ({ stage, onNext }) => {
	const render = useRender(stage, Stages.Info);
	const { t } = useTranslation();

	const iconStyle = {
		width: '60px',
		height: '60px',
		display: 'flex',
		borderRadius: '5px',
		justifyContent: 'center',
		alignItems: 'center',
	};

	const centreStyle = {
		justifyContent: 'center',
		display: 'flex',
		textAlign: 'center',
	};

	const centreStyleTitle = {
		justifyContent: 'center',
		display: 'flex',
		fontSize: '20px',
	};

	return (
		render && (
			<Container fluid>
				<Header>
					<div className="d-flex">
						<h1 className="ransomware-title">{t('riskAssessment.title')}</h1>
						<img src={MagicSvg} alt="magic" />
					</div>
					<p className="subtitle">{t('riskAssessment.listTitle')}</p>
					<ul>
						<li className="riskImpact">{t('riskAssessment.listItem1')}</li>
						<li className="riskImpact">{t('riskAssessment.listItem2')}</li>
						<li className="riskImpact">{t('riskAssessment.listItem3')}</li>
					</ul>
				</Header>

				<div className="row d-flex justify-content-centerflex-nowrap mt-2">
					<CardBody>
						<Container fluid>
							<Row className="d-flex justify-content-between">
								<Card className="card-item">
									<CardHeader style={centreStyle}>
										<div style={iconStyle}>
											<img
												src={Data}
												className="align-middle fa-3x"
												style={{ color: 'white' }}
											/>
										</div>
									</CardHeader>
									<CardBody>
										<h5 style={centreStyle}>
											{t('riskAssessment.assessmentStep1Title')}
										</h5>
										<HeaderTitle style={centreStyleTitle}>
											{t('riskAssessment.subTitle1')}
										</HeaderTitle>
										<p style={centreStyle}>
											{t('riskAssessment.assessmentStep1Description')}
										</p>
									</CardBody>
								</Card>

								<Card className="card-item">
									<CardHeader style={centreStyle}>
										<div style={iconStyle}>
											<img
												src={Review}
												className="align-middle fa-3x"
												style={{ color: 'white' }}
											/>
										</div>
									</CardHeader>
									<CardBody>
										<h5 style={centreStyle}>
											{t('riskAssessment.assessmentStep2Title')}
										</h5>
										<HeaderTitle style={centreStyleTitle}>
											{t('riskAssessment.subTitle2')}
										</HeaderTitle>
										<p style={centreStyle}>
											{t('riskAssessment.assessmentStep2Description')}
										</p>
									</CardBody>
								</Card>

								<Card className="card-item">
									<CardHeader style={centreStyle}>
										<div style={iconStyle}>
											<img
												src={Analysis}
												className="align-middle fa-3x"
												style={{ color: 'white' }}
											/>
										</div>
									</CardHeader>
									<CardBody>
										<h5 style={centreStyle}>
											{t('riskAssessment.assessmentStep3Title')}
										</h5>
										<HeaderTitle style={centreStyleTitle}>
											{t('riskAssessment.subTitle3')}
										</HeaderTitle>
										<p style={centreStyle}>
											{t('riskAssessment.assessmentStep3Description')}
										</p>
									</CardBody>
								</Card>
							</Row>
						</Container>
					</CardBody>
				</div>
				<div className="row d-flex justify-content-center flex-nowrap">
					<Button
						lg="true"
						color="primary"
						onClick={onNext}
						className="p-2"
						style={{ borderRadius: '25px', maxWidth: 315 }}>
						{t('button.startRansomwareRiskAssessment')}
						<img src={Arrow} alt="arrow" style={{ marginLeft: '10px' }} />
					</Button>
				</div>
			</Container>
		)
	);
};

export default Info;
