import {
	asyncRequest,
	getAuthHeaders,
	httpMethods,
	request,
	resolveUri
} from './Common';
import { CloudTypeProps } from '../pages/auth/MultiCloudSignIn/Constants';
import { CloudTypes } from '../constants/CloudInfo';

import { PackKeys } from '../constants/PackKeys';
import { isOrgChildAccount } from '../services/AuthService';

const Headers = {
	[PackKeys.NIST]: { ConformancePackName: 'NISTCSF' },
	[PackKeys.SOC2]: { ConformancePackName: 'SOC2' },
};
class EvaluationApiClient {
	authorizeAws(keys, success, error) {
		const requestOptions = {
			method: 'GET',
			headers: getAuthHeaders(keys),
			redirect: 'follow',
		};
		const apiUri = resolveUri('user-authorization', 'conformance-pack');
		fetch(apiUri, requestOptions)
			.then(response => response.json())
			.then(success)
			.catch(error);
	}

	authorizeOnprem(keys, success, error) {
		const requestOptions = {
			method: 'GET',
			headers: getAuthHeaders(keys),
			redirect: 'follow',
		};
		const apiUri = resolveUri('authenticate', 'api/v1');
		fetch(apiUri, requestOptions)
			.then(response => response.json())
			.then(success)
			.catch(error);
	}

	validateAwsAccount(keys, success, error) {
		const requestOptions = {
			method: 'GET',
			headers: getAuthHeaders(keys),
			redirect: 'follow',
		};
		// TODO move this to retrofit clients
		const apiHostUri = `${process.env.REACT_APP_NEWAUTH_API_HOST}`;
		const awspath = CloudTypeProps[CloudTypes.AWS].title.toLowerCase();
		const apiUri = `${apiHostUri}/${awspath}/validateUser`;

		fetch(apiUri, requestOptions)
			.then(response => response.json())
			.then(success)
			.catch(error);
	}

	validateOnpremAccount(keys, success, error) {
		const requestOptions = {
			method: 'GET',
			headers: keys,
			redirect: 'follow',
		};
		// TODO move this to retrofit clients
		const apiHostUri = `${process.env.REACT_APP_NEWAUTH_API_HOST}`;
		const onpremPath = CloudTypeProps[CloudTypes.Onprem].title.toLowerCase();
		const apiUri = `${apiHostUri}/${onpremPath}/validateUser`;

		fetch(apiUri, requestOptions)
			.then(response => response.json())
			.then(success)
			.catch(error);
	}
	validateK8sAccount(keys, success, error) {
		const requestOptions = {
			method: 'GET',
			headers: keys,
			redirect: 'follow',
		};
		// TODO move this to retrofit clients
		const apiHostUri = `${process.env.REACT_APP_NEWAUTH_API_HOST}`;
		const k8sPath = CloudTypeProps[CloudTypes.K8s].title.toLowerCase();
		const apiUri = `${apiHostUri}/${k8sPath}/validateUser`;

		fetch(apiUri, requestOptions)
			.then(response => response.json())
			.then(success)
			.catch(error);
	}

	authorizeAwsMember(keys, success, error) {
		const requestOptions = {
			method: 'GET',
			headers: { ...getAuthHeaders(), ...keys },
			redirect: 'follow',
		};
		const apiUri = resolveUri('user-authorization', 'conformance-pack');
		fetch(apiUri, requestOptions)
			.then(response => response.json())
			.then(success)
			.catch(error);
	}

	authorizeAzure(keys, success, error) {
		const requestOptions = {
			method: 'POST',
			headers: getAuthHeaders(keys),
			redirect: 'follow',
		};
		const apiUri = resolveUri('user-authorization', 'conformance-pack');
		fetch(apiUri, requestOptions)
			.then(response => (response.ok ? response : response.json()))
			.then(success)
			.catch(error);
	}

	logout(success, error) {
		request(resolveUri('user-logout'), {}, success, error);
	}

	start(pack, reportId, success, error) {
		const uri = 'evaluate' + (isOrgChildAccount() ? 'Org' : '');

		const headers = { ...Headers[pack], ReportId: reportId };

		request(
			resolveUri(uri),
			{
				method: 'POST',
				headers,
				redirect: 'follow',
			},
			success,
			error
		);
	}

	async saveAwsResults(data) {
		return await asyncRequest(resolveUri('evaluation-results'), {
			method: 'PUT',
			headers: {
				ConformancePackName: data.packName,
				ReportId: data.reportId,
				FragmentId: data.fragmentId,
			},
			body: JSON.stringify(data.body),
			redirect: 'follow',
		});
	}

	resultFragment(report, success, error) {
		let headers = Headers[report.PackName];
		headers.ReportId = encodeURIComponent(report.ReportId);
		headers.Fragments = report.FragmentId;

		request(resolveUri('evaluation-results'), {}, success, error);
	}

	history(success, error) {
		request(resolveUri('evaluation-history'), {}, success, error);
	}

	evaluationHistory(success, error) {
		request(
			resolveUri('evaluation-history'),
			{},
			result => (result && result.length > 0 ? success(result[0]) : undefined),
			error
		);
	}

	liveEnvironment(refresh, success, error) {
		const uri = resolveUri(
			'live-environment' + (isOrgChildAccount() ? 'Org' : '')
		);
		request(
			uri,
			{
				headers: { Refresh: refresh },
				redirect: 'follow',
			},
			success,
			error
		);
		//success(mockEnvironmentData);
	}

	getliveEnvironmentCompliance(report, success, error) {
		request(
			resolveUri('live-environment-compliance'),
			{
				headers: {
					ConformancePackName: report.PackName,
					ReportId: report.ReportId,
					...(report.PackName === 'RRA' ? { FragmentCount: 1 } : {}),
				},
				redirect: 'follow',
			},
			success,
			error
		);
		//success(mockEnvironmentComplianceData);
	}

	startLiveEnvironmentCompliance(pack, report, count, success, error) {
		const uri =
			'live-environment-compliance' + (isOrgChildAccount() ? 'Org' : '');
		request(
			resolveUri(uri),
			{
				method: httpMethods.put,
				headers: {
					ConformancePackName: pack,
					ReportId: report,
					FragmentCount: count,
				},
				redirect: 'follow',
			},
			success,
			error
		);
	}

	getAuditResults(audit, success, error) {
		request(
			resolveUri('audit-evaluation-results'),
			{
				headers: {
					ConformancePackName: audit.pack,
					ReportId: audit.reportId,
				},
			},
			success,
			error
		);
	}

	saveAuditResults(audit, success, error) {
		request(
			resolveUri('audit-evaluation-results'),
			{
				method: 'PUT',
				headers: {
					ConformancePackName: audit.pack,
					ReportId: audit.reportId,
				},
				body: JSON.stringify(audit.results),
				redirect: 'follow',
			},
			success,
			error
		);
	}

	getAuditTemplate(packName, success, error) {
		request(
			resolveUri('audit-results-template'),
			{
				headers: { ConformancePackName: packName },
				redirect: 'follow',
			},
			success,
			error
		);
		//mockResponse();
	}

	saveAuditTemplate(template, success, error) {
		request(
			resolveUri('audit-results-template'),
			{
				method: 'PUT',
				headers: { ConformancePackName: template.packName },
				redirect: 'follow',
				body: JSON.stringify(template.data),
			},
			result => success(result.status < 300, result.text()),
			error
		);
		//success();
	}

	finalizeReport(report, success, error) {
		request(
			resolveUri('finalizeReport'),
			{
				method: 'PUT',
				headers: {
					ConformancePackName: report.pack,
					ReportId: report.reportId,
					EvalId: report.evalId,
					FragmentCount: report.fragments,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify([]),
				redirect: 'follow',
			},
			success,
			error
		);
	}

	getFinalizedReport(report, success, error) {
		request(
			resolveUri('finalizeReport'),
			{
				headers: {
					ConformancePackName: report.pack,
					ReportId: report.reportId,
					'Content-Type': 'application/json',
				},
				redirect: 'follow',
			},
			success,
			error
		);
	}

	async summary() {
		const result = await asyncRequest(resolveUri('evaluation-summary'), {}),
			data = result.response,
			totalCount = data.TotalTestsPerMonth,
			completedCount = data.CompletedTestsPerMonth,
			labels = data.TotalTestsPeriod,
			locale = 'en-us',
			parseDate = value => {
				const parts = value.split('-'),
					date = new Date(parts[1], parseInt(parts[0]) - 1, 1),
					name = date.toLocaleString(locale, { month: 'short' });

				return name;
			},
			safeGet = count => (count && count.length > 0 ? count : []),
			getCount = count =>
				safeGet(count)
					.reverse()
					.map(item => item),
			getLabels = count =>
				safeGet(count)
					.reverse()
					.map(item => parseDate(item));

		return {
			testCount: {
				total: parseInt(data.TotalTestCount),
				completed: parseInt(data.CompletedTestCount),
				progress: parseInt(data.ProgressRecordCount),
				cancelled: parseInt(data.CancelledTestCount),
			},
			recentTests: {
				total: getCount(totalCount),
				completed: getCount(completedCount),
				labels: getLabels(labels),
			},
		};
		// TODO: Convert to 'request' from 'asyncRequest'
	}
}

export { EvaluationApiClient };
