import {
	Col,
	Row,
} from "reactstrap";
import React, { useContext } from "react";

import ReportContext from "../Common/ReportContext";
import ResultSubCategoryValues from "./ResultSubCategoryValues";

const ResultSubCategory = ({ subcategories, refSubCategories }) => {

	const context = useContext(ReportContext),
		values = context.values,
		rowStyle = index => ({
			backgroundColor: index % 2 === 0 ? '#e9ecef' : '#fff'
		}),
		render = subcategory => {

			const refSubCat = refSubCategories[subcategory];

			const showName = refSubCat.name && refSubCat.description && refSubCat.description.length > 0;

			return (
				<Row key={subcategory} style={rowStyle(refSubCat.index)} className="align-items-center">
					<Col xs={4} className="p-2">
						<p className="pt-3">
							{showName &&
								<>
									<strong>{refSubCat.name}</strong>
									{" : "}
								</>
							}
							<span>{refSubCat.description}</span>
						</p>
					</Col>
					<Col xs={8} className="pl-1 p-3">
						<ResultSubCategoryValues values={values[subcategory] ?? values[subcategory.toLowerCase()]} />
					</Col>
				</Row>
			)
		};

	return <div className="h-100">{subcategories.map(render)}</div>
}

export default ResultSubCategory;