import {
	Badge,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Input,
	Label,
} from 'reactstrap';
import { Download, ExternalLink } from 'react-feather';
import React, { useEffect, useState } from 'react';
/*
import ToolkitProvider, {
	CSVExport,
	Search,
} from 'react-bootstrap-table-nextgen-toolkit';
import BootstrapTable from 'react-bootstrap-table-nextgen';
import paginationFactory from 'react-bootstrap-table-nextgen-paginator';

*/
import { history, status } from '../../../../../apiclients/PenTesting';

import ErrorInfo from '../../../../../components/ErrorInfo';
import Loader from '../../../../../components/Loader';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
/*
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
*/

const headerStyle15 = { width: '15%', textAlign: 'justify' },
	headerStyle30 = { width: '30%', textAlign: 'justify' },
	actionButtonCss = 'me-2 justify-content-center',
	actionButtonTextCss = 'align-middle text-center ms-2';

const columns = [
	{
		dataField: 'ReportId',
		text: <Trans i18nKey="table.reportId" />,
		sort: true,
		headerStyle: headerStyle15,
	},
	{
		dataField: 'RunDateTime',
		text: <Trans i18nKey="table.runDate" />,
		sort: true,
		headerStyle: headerStyle15,
	},
	{
		dataField: 'Status',
		text: <Trans i18nKey="table.status" />,
		sort: true,
		headerStyle: headerStyle15,
		formatter: cell => {
			const isDone = cell === 'Done';
			const colour = isDone ? 'success' : 'warning';
			return <Badge color={colour}>{cell}</Badge>;
		},
	},
	{
		dataField: 'WebURL',
		text: <Trans i18nKey="table.url" />,
		sort: true,
		headerStyle: headerStyle30,
		formatter: cell => cell.substr(1, cell.length - 2),
	},
	{
		text: '',
		sort: false,
		isDummyField: true,
		dataField: 'actions',
		headerStyle: headerStyle15,
		formatter: (cell, row) => {
			const disableControls = row.Status !== 'Done';
			const viewHref = `./results/${row.ReportUUID}?reportId=${row.ReportId}`;

			return (
				<div>
					<Button
						color="primary"
						size="sm"
						className={actionButtonCss}
						href={viewHref}
						disabled={disableControls}>
						<ExternalLink height={12} width={12} />
						<span className={actionButtonTextCss}>{t('button.view')}</span>
					</Button>
					<Button
						color="primary"
						size="sm"
						className={actionButtonCss}
						href={viewHref}
						disabled={true}>
						<Download height={12} width={12} />
						<span className={actionButtonTextCss}>{t('button.download')}</span>
					</Button>
				</div>
			);
		},
	},
];

const defaultSorted = [
	{
		dataField: 'RunDateTime',
		order: 'desc',
	},
];

const RefreshCheckbox = ({ checked, onCheckChanged }) => {
	const onChange = () => onCheckChanged(!checked);

	return (
		<Label check className="me-2">
			<Input type="checkbox" checked={checked} onChange={onChange} />
			{t('button.autoRefreshStatus')}
		</Label>
	);
};

const HistoryTable = ({
	data,
	onRefresh,
	enableAutoRefresh,
	setEnableAutoRefresh,
}) => {
	/*
	return (
		<ToolkitProvider
			keyField="ReportUUID"
			data={data}
			columns={columns}
			exportCSV
			search={{ defaultSearch: '', searchFormatted: true }}>
			{props => (
				<div id="historyContainer">
					<div className="float-end">
						<RefreshCheckbox
							checked={enableAutoRefresh}
							onCheckChanged={setEnableAutoRefresh}
						/>
						<ButtonGroup>
							<Button
								className="me-1 btn-md btn"
								color="primary"
								onClick={onRefresh}>
								{t('button.refresh')}
							</Button>
							<ExportCSVButton
								{...props.csvProps}
								className="btn-secondary me-1 btn-md btn">
								{t('button.exportScanResults')}
							</ExportCSVButton>
						</ButtonGroup>
						<SearchBar {...props.searchProps} />
					</div>

					<BootstrapTable
						{...props.baseProps}
						bootstrap4
						bordered={false}
						defaultSorted={defaultSorted}
						pagination={paginationFactory({
							// sizePerPage: 5,
							sizePerPageList: [10, 25, 50],
						})}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
	*/
	return (
		<h1>Table Removed</h1>
	);

};
const getInProgressIds = data => {
	let evalIds = [];

	data.forEach(item => {
		if (item.Status === 'In Progress') {
			evalIds.push(item.EvalId);
		}
	});

	return evalIds;
};

const getUpdatedData = async data => {
	let updatedIds = [];

	let updateCount = 0;

	let updatedData = [...data];

	const updateItem = (id, item) => {
		if (item.EvalId === id) {
			item.Status = 'Done';
			updateCount++;
		}
	};

	const updateStatus = id => updatedData.forEach(item => updateItem(id, item));

	const updates = () => {
		updatedIds.forEach(updateStatus);

		return { updatedData, updated: updateCount > 0 };
	};

	const evalIds = getInProgressIds(data);

	console.log('getting updated for ids:', evalIds);

	if (evalIds.length > 0) {
		updatedIds = await status(evalIds);
		return updatedIds.length > 0 ? updates() : data;
	}

	return data;
};

export default function ({ refresh }) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);
	const [enableAutoRefresh, setEnableAutoRefresh] = useState(false);

	const fetchData = async () => {
		const response = await history();

		setLoading(false);
		if (response.failed) {
			setError(true);
		} else {
			setData(response);
		}
	};

	const onRefresh = async () => {
		setLoading(true);
		await fetchData();
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		let refreshInterval;

		const timeoutDuration = 30 * 1000;

		if (enableAutoRefresh) {
			refreshInterval = setInterval(async () => {
				let results = await getUpdatedData(data);
				if (results.updated) {
					setLoading(true);
					setData(results.updatedData);
					setLoading(false);
				}
			}, timeoutDuration);
		} else {
			clearInterval(refreshInterval);
		}

		return () => {
			clearInterval(refreshInterval);
		};
	}, [enableAutoRefresh, data]);

	useEffect(() => {
		const fetchWithDelay = () => {
			setLoading(true);
			setTimeout(() => fetchData(), 500);
		};

		if (refresh) fetchWithDelay();
	}, [refresh]);

	return (
		<Card>
			<CardHeader>
				<CardTitle>
					<h5>{t('tests.history')}</h5>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{error && <ErrorInfo />}
				{!error && loading && <Loader />}
				{!error && !loading && (
					<HistoryTable
						data={data}
						enableAutoRefresh={enableAutoRefresh}
						setEnableAutoRefresh={setEnableAutoRefresh}
						onRefresh={onRefresh}
					/>
				)}
			</CardBody>
		</Card>
	);
}
