/* eslint-disable no-useless-computed-key */

import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Container,
} from "reactstrap";

import { PackKeys } from "../../../../constants/PackKeys";
import React from "react";

const
	auditConstants = {
		[PackKeys.NIST]: [
			{ text: "NIST-CSF Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "Audit Test Results", page: 6 },
			{ text: "AWS Test Results", page: 13 }
		],
		[PackKeys.SOC2]: [
			{ text: "SOC2 Introduction", page: 2 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "Audit Test Results", page: 6 },
			{ text: "AWS Test Results", page: 29 }
		],
		[PackKeys.HIPAASECURITY]: [
			{ text: "HIPAA Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "Audit Test Results", page: 6 },
			{ text: "AWS Test Results", page: 13 }
		],
		[PackKeys.PCIDSS]: [
			{ text: "TODO PCI Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "Audit Test Results", page: 6 },
			{ text: "AWS Test Results", page: 55 }
		],
		[PackKeys.CMMCL3]: [
			{ text: "CMMC Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "Audit Test Results", page: 6 },
			{ text: "AWS Test Results", page: 55 }
		]
	},
	noAuditConstants = {
		[PackKeys.NIST]: [
			{ text: "NIST-CSF Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "AWS Test Results", page: 6 }
		],
		[PackKeys.SOC2]: [
			{ text: "SOC2 Introduction", page: 2 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "AWS Test Results", page: 6 }
		],
		[PackKeys.HIPAASECURITY]: [
			{ text: "HIPAA Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "AWS Test Results", page: 13 }
		],
		[PackKeys.PCIDSS]: [
			{ text: "TODO PCI Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "AWS Test Results", page: 55 }
		],
		[PackKeys.CMMCL3]: [
			{ text: "CMM Introduction", page: 3 },
			{ text: "Network Topology Diagram", page: 4 },
			{ text: "AWS Resource List", page: 5 },
			{ text: "AWS Test Results", page: 55 }
		]
	},

	TableofContents = ({ packName, hasAuditReport }) => {

		const items = hasAuditReport ? auditConstants[packName] : noAuditConstants[packName]

		return (
			<Card className="page-break d-flex">
				<CardHeader>
					<CardTitle><h3>TABLE OF CONTENTS</h3></CardTitle>
				</CardHeader>
				<CardBody>
					<Container fluid>
						<table cellPadding="30">
							<tbody>
								{
									items.map(item =>
										<tr key={item.page}>
											<td>{item.text}</td>
											<td>{item.page}</td>
										</tr>)
								}
							</tbody>
						</table>
					</Container>
				</CardBody>
			</Card>
		);
	}

export default TableofContents;