import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Container,
	Nav,
	Row,
	TabContent
} from "reactstrap";
import React, { useEffect, useState } from "react";

import { EvaluationApiClient } from "../../../apiclients/EvaluationApiClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../../components/Header";
import HeaderTitle from "../../../components/HeaderTitle";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import { PackKeys } from '../../../constants/PackKeys';
import ReportContext from "./Common/ReportContext";
import ReportHeader from "./Controls/ReportHeader";
import ReportTabPane from "./Controls/ReportTabPane";
import SaveAuditTemplateButton from "./Controls/SaveAuditTemplateButton";
import auditNistData from "./Constants/AuditTestsNIST";
import auditSoc2Data from "./Constants/AuditTestsSoC2";
import awsNistData from "./Constants/AwsTestsNIST";
import awsSoC2Data from "./Constants/AwsTestsSoC2";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { getReportData } from "../../../apiclients/AuditResults";
import { mapAwsData } from "./Common/DataMapper";
import { useLocation, useParams } from 'react-router-dom';

const constants = {
	audit: {
		[PackKeys.NIST]: {
			title: 'NIST-CSF Compliance Audit Tests',
			data: auditNistData
		},
		[PackKeys.SOC2]: {
			title: 'SOC2 Compliance Audit Tests',
			data: auditSoc2Data
		}
	},
	aws: {
		[PackKeys.NIST]: {
			title: 'NIST CSF Compliance Report - AWS Test Cases',
			data: awsNistData
		},
		[PackKeys.SOC2]: {
			title: 'SOC2 Compliance Report - AWS Test Cases',
			data: awsSoC2Data
		}
	}
}

const RedirectButton = ({ text, url }) => {
	return (
		<Link to={url}>
			<Button color="primary" size="md">
				{text}
				<FontAwesomeIcon icon={faArrowAltCircleRight} className="ms-2" />
			</Button>
		</Link>
	);
}

const TestReport = () => {
	const location = useLocation();
	const params = useParams();


	const
		pp = params,
		report = { pack: pp.pack, type: pp.type.toLowerCase(), reportId: pp.reportid },
		[auditorName, setAuditorName] = useState(''),
		type = constants[report.type],
		pack = type[report.pack],
		data = pack.data,
		isAudit = report.type === "audit",
		reportIdUrlFragment = `${pp.reportid}${location.search}`,
		printUrl = `/print/${pp.pack}/${reportIdUrlFragment}`,
		awsUrl = `/reports/${pp.pack}/aws/${reportIdUrlFragment}`,
		redirectUrl = isAudit ? awsUrl : printUrl,
		redirectText = isAudit ? "Next" : "	Save as PDF",
		client = new EvaluationApiClient(),
		[values, setValues] = useState({}),
		[loaded, setLoaded] = useState(false),
		setData = d => {
			setAuditorName(d.auditorName ?? '');
			setValues(d.data ?? d);
			setLoaded(true);
		},
		setAwsData = d => { setValues(mapAwsData(d)); setLoaded(true); },
		error = (m, e) => console.error(m, e),
		[activeTabId, setActiveTabId] = useState(data.testCases[0].id),
		toggle = tabId => {
			if (activeTabId !== tabId) setActiveTabId(tabId);
		},
		auditError = e => error("An error occurred while loading audit data", e),
		awsError = e => error("An error occurred while loading aws data", e);

	useEffect(() => {
		const getValues = async () => {
			setLoaded(false);
			if (isAudit) {
				const result = await getReportData(report);
				if(result.succeeded) setData(result);
				else auditError(result);
			} else {
				client.getFinalizedReport(report, setAwsData, awsError);
			}

			setTimeout(setActiveTabId(data.testCases[0].id), 300);
		}

		getValues();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (

		<Container fluid>
			<Header>
				<HeaderTitle>
					{pack.title}
				</HeaderTitle>
			</Header>

			<Card>
				<CardHeader>
					<CardTitle>
						<h3>{report.reportId} {loaded && auditorName.length > 0 && `audited by ${auditorName}`}</h3>
					</CardTitle>
				</CardHeader>
				<CardBody>
					<ReportContext.Provider value={{ report, data, values }} >
						{!loaded && <Loader />}
						{loaded &&
							<div>
								<Nav tabs>
									{data.testCases.map(test => <ReportHeader key={test.id} test={test} activeTabId={activeTabId} handleTabClick={toggle} />)}
								</Nav>
								<TabContent activeTab={activeTabId}>
									{data.testCases.map(test => <ReportTabPane key={test.id} test={test} />)}
								</TabContent>
							</div>
						}
					</ReportContext.Provider>
				</CardBody>
			</Card>

			<Container className="me-1 mb-2">
				<Row className="float-end">
					<div>
						{isAudit && <SaveAuditTemplateButton pack={report.pack} values={values} />}
					</div>
					<div>
						<RedirectButton url={redirectUrl} text={redirectText} />
					</div>
				</Row>
				<div className="clearfix"></div>
			</Container>
		</Container>
	);
};

export default TestReport;