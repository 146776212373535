import { FormFeedback, FormGroup, Input } from 'reactstrap';
import React, { useState } from 'react';
import { t } from 'i18next';

export default function ({ onChange, loading }) {
	const [reportId, setReportId] = useState('');
	const [invalid, setInvalid] = useState('');

	const onBlur = value => {
		const trimmed = value.trim();
		const isValid = trimmed.length > 0;
		setReportId(trimmed);
		setInvalid(!isValid);
		onChange(trimmed, isValid);
	};

	return (
		<FormGroup>
			<h4 className="pt-1">{t('table.reportName')}</h4>
			<Input
				name="scanName"
				className="d-inline ms-3 w-50"
				type="text"
				disabled={loading}
				value={reportId}
				onChange={e => setReportId(e.target.value)}
				onFocus={e => e.target.select()}
				onBlur={e => onBlur(e.target.value)}
				placeholder="Report Name"
				invalid={invalid}
			/>
			<FormFeedback className="ms-3">{t('tests.giveReportName')}</FormFeedback>
		</FormGroup>
	);
}
