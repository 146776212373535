import React from 'react';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import step2 from '../../../assets/img/step2.png';

function Step2() {
	const { t } = useTranslation();
	return (
		<div id="step2" className="instructions-steps">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 2</h5>
				<div>
					<h5 className="steps-title">{t('detailedInstructions.accessIAM')}</h5>
					<div className="d-flex ">
						<img src={icon1} alt="icon1" className="img" />
						{t('detailedInstructions.locateServices')}
					</div>
					<div className="d-flex ">
						<img src={icon2} alt="icon1" className="img" />
						{t('detailedInstructions.IAMSecurity')}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={step2} />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure1')}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Step2;
