import 'react-datepicker/dist/react-datepicker.css';

import { Col, Label, Row } from 'reactstrap';

import DatePicker from 'react-datepicker';
import React from 'react';
import RegionMultiSelect from '../../../../auth/MultiCloudSignIn/AwsTabPane/Controls/RegionMultiSelect';
import ReviewScanDurationInput from '../../Components/ReviewScanDurationInput';
import ReviewScanFrequencyInput from '../../Components/ReviewScanFrequencyInput';
import ReviewScanInput from '../../Components/ReviewScanInput';
import { useTranslation } from 'react-i18next';

const DisasterRecovery = ({ hook }) => {
	const { t } = useTranslation();
	//hack for Azure.
	hook.options.disasterRecovery.lastDrill = undefined;

	const setMultiRegion = (selected, code) => {
		const regions = hook.options.disasterRecovery.regions.split(',');
		if (selected) {
			regions.push(code);
		} else {
			regions.splice(regions.indexOf(code), 1);
		}
		hook.setInput('disasterRecovery', 'regions', regions.join(','));
	};

	return (
		<>
			<Row className="mt-4 ms-3">
				<h4>{t('reviewScan.disasterRecovery')}</h4>
			</Row>
			<Row className="mt-4 ms-2">
				<Col xs="6">
					<Label>{t('reviewScan.implementDisasterRecovery')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="dr.enabled.yes"
							checked={hook.options.disasterRecovery.enabled}
							value="true"
							onChange={() =>
								hook.setInput('disasterRecovery', 'enabled', true)
							}
							name="dr.enabled"></input>
						<label className="me-6" htmlFor="dr.enabled.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="dr.enabled.no"
							checked={!hook.options.disasterRecovery.enabled}
							value="false"
							onChange={() =>
								hook.setInput('disasterRecovery', 'enabled', false)
							}
							name="dr.enabled"></input>
						<label htmlFor="dr.enabled.no">{t('button.no')}</label>
					</div>
				</Col>
				<Col>
					<Col xs="8">
						<div className="p-2 me-1">
							<ReviewScanInput
								labelfor="softwareName"
								value={hook.options.disasterRecovery.softwareName}
								onChange={(n, v) => hook.setInput('disasterRecovery', n, v)}>
								{t('reviewScan.softwareName')}
							</ReviewScanInput>
						</div>
					</Col>
				</Col>
			</Row>
			{hook.options.disasterRecovery.enabled && (
				<>
					<Row className="ms-2">
						<Col xs="6">
							<Label>{t('reviewScan.selectConfiguration')}</Label>
							<div className="mb-3 d-flex">
								<div>
									<input
										className="pl-5 me-4"
										type="radio"
										id="dr.config.0"
										checked={hook.options.disasterRecovery.config === 0}
										value="0"
										onChange={() =>
											hook.setInput('disasterRecovery', 'config', 0)
										}
										name="dr.config"></input>
									<label className="me-6" htmlFor="dr.config.0">
										{t('button.active')} - {t('button.active')}
									</label>
								</div>
								<div>
									<input
										className="pl-5 me-4"
										type="radio"
										id="dr.config.1"
										checked={hook.options.disasterRecovery.config === 1}
										value="1"
										onChange={() =>
											hook.setInput('disasterRecovery', 'config', 1)
										}
										name="dr.config"></input>
									<label className="me-6" htmlFor="dr.config.1">
										{t('button.active')} - {t('button.passive')}
									</label>
								</div>

								<div>
									<input
										className="pl-3 me-4"
										type="radio"
										id="dr.config.2"
										checked={hook.options.disasterRecovery.config === 2}
										value="2"
										onChange={() =>
											hook.setInput('disasterRecovery', 'config', 2)
										}
										name="dr.config"></input>
									<label htmlFor="dr.config.2">{t('button.backup')}</label>
								</div>
							</div>
						</Col>
						<Col>
							<Col xs="8">
								<div className="p-2 me-1">
									<ReviewScanInput
										labelfor="licenseARN"
										value={hook.options.disasterRecovery.licenseARN}
										onChange={(n, v) =>
											hook.setInput('disasterRecovery', n, v)
										}>
										{t('reviewScan.licenseArn')}
									</ReviewScanInput>
								</div>
							</Col>
							<Col xs="8">
								<div className="p-2 me-1">
									<ReviewScanInput
										labelfor="seller"
										value={hook.options.disasterRecovery.seller}
										onChange={(n, v) =>
											hook.setInput('disasterRecovery', n, v)
										}>
										{t('reviewScan.seller')}
									</ReviewScanInput>
								</div>
							</Col>
						</Col>
					</Row>
					<div className="dash"></div>

					<Row className="mt-5 ms-3">
						<h4>{t('reviewScan.additionalInformation')}</h4>
					</Row>
					<Row className="ms-2 mt-4">
						<Col xs="4">
							<div className="me-1">
								<ReviewScanDurationInput
									labelfor="recoveryTime"
									value={hook.options.disasterRecovery.recoveryTime}
									unit={hook.options.disasterRecovery.recoveryTimeUnit}
									onChange={(n, v) => hook.setInput('disasterRecovery', n, v)}>
									{t('reviewScan.recoveryTime')}
								</ReviewScanDurationInput>
							</div>
						</Col>
					</Row>
					<Row className="ms-2 mt-4">
						<Col xs="4">
							<div className="me-1">
								<ReviewScanDurationInput
									labelfor="recoveryPoint"
									value={hook.options.disasterRecovery.recoveryPoint}
									unit={hook.options.disasterRecovery.recoveryPointUnit}
									onChange={(n, v) => hook.setInput('disasterRecovery', n, v)}>
									{t('reviewScan.recoveryPoint')}
								</ReviewScanDurationInput>
							</div>
						</Col>
					</Row>

					<Row className="ms-2 mt-4">
						<Col>
							<Label>{t('reviewScan.regularConduct')}</Label>
							<div className="mb-3">
								<input
									className="pl-5 me-4"
									type="radio"
									id="dr.drill.yes"
									checked={hook.options.disasterRecovery.drill}
									value="true"
									onChange={() =>
										hook.setInput('disasterRecovery', 'drill', true)
									}
									name="dr.drill"></input>
								<label className="me-6" htmlFor="dr.drill.yes">
									{t('button.yes')}
								</label>
								<input
									className="pl-5 me-4"
									type="radio"
									id="dr.drill.no"
									checked={!hook.options.disasterRecovery.drill}
									value="false"
									onChange={() =>
										hook.setInput('disasterRecovery', 'drill', false)
									}
									name="dr.drill"></input>
								<label htmlFor="dr.drill.no">{t('button.no')}</label>
							</div>
						</Col>
					</Row>
					<Row className="ms-2 mt-4">
						<Col>
							<Label>{t('reviewScan.meetDisasterObjectives')}</Label>
							<div className="mb-3">
								<input
									className="pl-5 me-4"
									type="radio"
									id="dr.objective.yes"
									checked={hook.options.disasterRecovery.objective}
									value="true"
									onChange={() =>
										hook.setInput('disasterRecovery', 'objective', true)
									}
									name="dr.objective"></input>
								<label className="me-6" htmlFor="dr.objective.yes">
									{t('button.yes')}
								</label>
								<input
									className="pl-5 me-4"
									type="radio"
									id="dr.objective.no"
									checked={!hook.options.disasterRecovery.objective}
									value="false"
									onChange={() =>
										hook.setInput('disasterRecovery', 'objective', false)
									}
									name="dr.objective"></input>
								<label htmlFor="dr.objective.no">{t('button.no')}</label>
							</div>
						</Col>
					</Row>
					<Row className="ms-2 mt-4">
						{hook.options.disasterRecovery.drill && (
							<Col>
								<ReviewScanFrequencyInput
									style={{ width: '20%' }}
									labelfor="drillFrequency"
									onChange={(n, v) => hook.setInput('disasterRecovery', n, v)}
									value={hook.options.disasterRecovery.drillFrequency}>
									{t('reviewScan.recoveryDrillsTest')}
								</ReviewScanFrequencyInput>
							</Col>
						)}
					</Row>
					{hook.options.disasterRecovery.drill && (
						<>
							<Row className="mt-4 ms-2">
								<Col>
									<Label>{t('reviewScan.dateLastDrill')}</Label>
									<div>
										<DatePicker
											selected={hook.options.disasterRecovery.lastDrill}
											className="custom-datepicker"
											onChange={date =>
												hook.setInput('disasterRecovery', 'lastDrill', date)
											}
										/>
									</div>
								</Col>
							</Row>
						</>
					)}
					<Row className="mt-4 ms-3">
						<Label>{t('reviewScan.otherImplements')}</Label>
					</Row>
					<Row className="ms-2">
						<RegionMultiSelect
							onSelected={(selected, code) => setMultiRegion(selected, code)}
						/>
					</Row>
				</>
			)}
		</>
	);
};

export default DisasterRecovery;
