import { Card, CardBody, Progress } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import { results } from '../../../../apiclients/PenTesting';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';

export default function () {
	const { reportId } = useParams();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [resultClass, setResultClass] = useState('d-none');
	const [reportHtml, setReportHtml] = useState({ __html: '' });

	let timeoutHandle;

	const fetchData = async () => {
		setResultClass('d-none');

		const response = await results(reportId);
		console.log('this is the result', response);

		const mustRetry = response && response.mustRetry;
		const scanComplete = response && response.retryAfterSeconds === 0;

		const showError = () => {
			setError(true);
			clearTimeout(timeoutHandle);
		};

		if (mustRetry) {
			const retryAfter = response.retryAfterSeconds * 1000;
			timeoutHandle = setTimeout(async () => await fetchData(), retryAfter);
		} else if (scanComplete) {
			setReportHtml({ __html: response.html });
			setTimeout(() => {
				setLoading(false);
				setResultClass('');
				//a delay is needed to trim out the non-essential html text
				document.querySelector('.card-body h1').innerHTML = '';
			}, 300);
		} else {
			console.error('unknown scan result response');
			showError();
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	console.log('This is report ID', reportId);

	return (
		<Card>
			<CardBody>
				{error && <span>{t('tests.unableLoadResults')}</span>}
				{!error && loading && (
					<div className="w-25 m-3">
						<span className="ms-3">{t('tests.loadingResults')}</span>
						<Progress className="ms-3" animated striped value={45} />
					</div>
				)}
				{!error && !loading && (
					<div className={resultClass}>
						<div dangerouslySetInnerHTML={reportHtml}></div>
					</div>
				)}
			</CardBody>
		</Card>
	);
}
