import { t } from 'i18next';
import { useState } from 'react';

const useForm = (values, validatorsEnum) => {
	const [errors, setErrors] = useState({});

	const validateField = (name, value) => {
		const validators = validatorsEnum[name];

		if (!validators) return true;
		let isFieldValid = true;

		for (let validator of validators) {
			const isValid = validator.method(value);

			if (isValid) {
				setErrors(prev => ({ ...prev, [name]: '' }));
			} else if (!isValid) {
				setErrors(prev => ({ ...prev, [name]: t(validator.error) }));
				isFieldValid = false;
				break;
			}
		}

		return isFieldValid;
	};

	const validateForm = () => {
		const isValidForm = Object.keys(values).reduce((prev, key) => {
			const isValid = validateField(key, values[key]);

			if (prev === false) return prev;
			return isValid;
		}, true);

		return isValidForm;
	};

	return { validateField, validateForm, errors };
};

export default useForm;
