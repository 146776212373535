import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Network from './Network';

const Topology = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const [pack, setPack] = useState(null);
	const [reportName, setReportName] = useState(null);
	const [evalId, setEvalId] = useState(null);
	const [numFragments, setNumFragments] = useState(null);

	useEffect(() => {
		// load params
		setPack(queryParams.get('pack'));
		setReportName(queryParams.get('reportname'));
		setEvalId(queryParams.get('evalid'));
		setNumFragments(queryParams.get('numfragments'));
	}, [queryParams]);

	return (
		<Network
			key={reportName ? reportName : 'network'}
			pack={pack}
			reportName={reportName}
			evalId={evalId}
			numFragments={numFragments}
		/>
	);
};

export default Topology;
