import { IDLE_TIMEOUT, USER_ACTIVE } from './redux/constants';

import { IdleTimer } from './IdleTimer';
import { Provider } from 'react-redux';
import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import Routes from './routes/Routes';
import store from './redux/store/index';

const idleWarning = 1000 * parseInt(process.env.REACT_APP_IDLE_WARNING_SECONDS);
const userIdleTotal = 1000 * parseInt(process.env.REACT_APP_IDLE_TOTAL_SECONDS);

class App extends React.Component {
	constructor(props) {
		super(props);
		this.idleTimer = null;
		this.handleOnAction = this.handleOnAction.bind(this);
		this.handleOnActive = this.handleOnActive.bind(this);
		this.handleOnIdle = this.handleOnIdle.bind(this);
		this.handleOnPrompt = this.handleOnPrompt.bind(this);
	}

	handleOnAction(event) {
		store.dispatch({ type: USER_ACTIVE });
	}

	handleOnActive(event) {
		store.dispatch({ type: USER_ACTIVE });
	}

	handleOnIdle(event) {
		const path = window.location.pathname;

		if (path === '/' || path.toLowerCase().indexOf('setup') > -1) return;

		const signInPage = '/auth/sign-in';
		const remainingTime = this.idleTimer.getRemainingTime() / 1000;
		const found = path.includes(signInPage);
		const isIdle = !found && remainingTime <= (idleWarning / 1000);

		if (isIdle) {
			store.dispatch({
				type: IDLE_TIMEOUT,
				payload: { remainingTime, redirect: signInPage },
			});
		}
	}
	handleOnPrompt() {
		// TODO
		//console.log("On Prompt!");
	}

	componentDidMount() {
		// Remove `active` className from `.splash` element in `public/index.html`
		!document.querySelector('.splash') ||
			document.querySelector('.splash').classList.remove('active');
		// Auth.currentAuthenticatedUser({ bypassCache: true }).then(console.log);
		this.idleTimer.start();
	}

	// TODO Idle timer is disabled until it can be fixed
	render() {
		return (
			<Provider store={store}>
				<Routes />
				<IdleTimer
					ref={ref => {
						this.idleTimer = ref;
					}}
					timeout={userIdleTotal}
					promptBeforeIdle={idleWarning}
					onActive={this.handleOnActive}
					onIdle={this.handleOnIdle}
					onAction={this.handleOnAction}
					onPrompt={this.handleOnPrompt}
					debounce={250}
				/>
				<ReduxToastr
					timeOut={5000}
					newestOnTop={true}
					position="top-right"
					transitionIn="fadeIn"
					transitionOut="fadeOut"
					progressBar
					closeOnToastrClick
				/>
			</Provider>
		);
	}
}

window.buildInfo = { type: `${process.env.REACT_APP_TYPE}` };

console.log(`Build Info`, window.buildInfo);

export default App;
