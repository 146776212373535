import React from 'react';
import { Trans } from 'react-i18next';
import { PackKeys } from '../../../constants/PackKeys';
import cmmCL3Meta from './CMMCL3Meta';
import fedRampMeta from './FedRampMeta';
import hipaaSecurityMeta from './HipaaMeta';
import nercCipMeta from './NercCipMeta';
import nist800171Meta from './Nist800171Meta';
import nist80053rev4Meta from './Nist80053rev4Meta';
import nistMeta from './NistMeta';
import pciDssMeta from './PciDssMeta';
import soc2Meta from './Soc2Meta';

const awsEnabledDefault = { automated: true, audit: true, disabled: false };
const awsDisabledDefault = { automated: false, audit: false, disabled: true };
const azureDisabledDefault = {
	automated: false,
	audit: false,
	disabled: false,
	comingSoon: true,
};
const azureEnabledDefault = {
	automated: true,
	audit: true,
	disabled: false,
	comingSoon: false,
};
const gcpDefault = {
	automated: false,
	audit: false,
	disabled: false,
	comingSoon: true,
};
const defaultStyle = {};

export default {
	[PackKeys.NIST]: {
		meta: nistMeta,
		style: defaultStyle,
		tests: {
			aws: awsEnabledDefault,
			azure: azureEnabledDefault,
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.hist.title" />,
		titleText: "testReports.testInfo.hist.title",
		description: <Trans i18nKey="testReports.testInfo.hist.description" />,
	},
	[PackKeys.SOC2]: {
		meta: soc2Meta,
		style: defaultStyle,
		tests: {
			aws: awsEnabledDefault,
			azure: azureEnabledDefault,
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.soc2.title" />,
		titleText: "testReports.testInfo.soc2.title",
		description: <Trans i18nKey="testReports.testInfo.soc2.description" />,
	},
	[PackKeys.HIPAASECURITY]: {
		meta: hipaaSecurityMeta,
		style: defaultStyle,
		tests: {
			aws: awsEnabledDefault,
			azure: { ...azureEnabledDefault, audit: false },
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.hipaa.title" />,
		titleText: "testReports.testInfo.hipaa.title",
		description: <Trans i18nKey="testReports.testInfo.hipaa.description" />,
	},
	[PackKeys.PCIDSS]: {
		meta: pciDssMeta,
		style: defaultStyle,
		tests: {
			aws: awsEnabledDefault,
			azure: azureEnabledDefault,
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.pcidss.title" />,
		titleText: "testReports.testInfo.pcidss.title",
		description: <Trans i18nKey="testReports.testInfo.pcidss.description" />,
	},
	[PackKeys.NIST80053REV4]: {
		meta: nist80053rev4Meta,
		style: defaultStyle,
		tests: {
			aws: awsDisabledDefault,
			azure: azureDisabledDefault,
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.nist80054rev4.title" />,
		titleText: "testReports.testInfo.nist80054rev4.title",
		description: (
			<Trans i18nKey="testReports.testInfo.nist80054rev4.description" />
		),
	},
	[PackKeys.NIST800171]: {
		meta: nist800171Meta,
		style: defaultStyle,
		tests: {
			aws: awsDisabledDefault,
			azure: azureDisabledDefault,
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.nist800171.title" />,
		titleText: "testReports.testInfo.nist800171.title",
		description: (
			<Trans i18nKey="testReports.testInfo.nist800171.description" />
		),
	},
	[PackKeys.FEDRAMP]: {
		meta: fedRampMeta,
		style: defaultStyle,
		tests: {
			aws: awsDisabledDefault,
			azure: azureDisabledDefault,
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.fedramp.title" />,
		titleText: "testReports.testInfo.fedramp.title",
		description: <Trans i18nKey="testReports.testInfo.fedramp.description" />,
	},
	[PackKeys.NERCCIP]: {
		meta: nercCipMeta,
		style: defaultStyle,
		tests: {
			aws: awsDisabledDefault,
			azure: azureDisabledDefault,
			gcp: gcpDefault,
		},
		useList: true,
		title: <Trans i18nKey="testReports.testInfo.nerccip.title" />,
		titleText: "testReports.testInfo.nerccip.title",
		description: <Trans i18nKey="testReports.testInfo.nerccip.description" />,
	},
	[PackKeys.CMMCL3]: {
		meta: cmmCL3Meta,
		style: {
			alignItems: 'flex-end',
		},
		tests: {
			aws: awsDisabledDefault,
			azure: { automated: true, audit: true, disabled: false },
			gcp: gcpDefault,
		},
		title: <Trans i18nKey="testReports.testInfo.cmmc.title" />,
		titleText: "testReports.testInfo.cmmc.title",
		description: <Trans i18nKey="testReports.testInfo.cmmc.description" />,
	},
};
