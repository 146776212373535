import {
	Card,
	CardBody,
	Nav,
} from "reactstrap";

import Categories from "../Categories";
import ChildTestCases from "../ChildTestCases";
import React from "react";
import Tab from "./TabHeaders"
import useTestCases from "./useTestCases";

export default function ({ refData, navState }) {

	const vm = useTestCases(refData, navState);

	return <>
		<Card>
			<CardBody>
				<Nav tabs style={{whiteSpace: 'nowrap', overflowX: 'auto', 'overflowY': 'hidden', flexWrap: 'nowrap'}}>
					{vm.testCases.map(tc =>
						<Tab
							key={tc.id}
							testCase={tc}
							onClick={vm.onClick}
							isActive={vm.isSelected(tc.id)}
						/>)
					}
				</Nav>
				<div className="container p-0 pt-3">
					{vm.hasChildTests &&
						<ChildTestCases
							refData={refData}
							parentId={vm.selected}
							navState={navState} />}
					{!vm.hasChildTests &&
						<Categories
							refData={refData}
							testCase={vm.selected}
							navState={navState}
							categories={vm.categories}
							nested={false} />
					}
				</div>
			</CardBody>
		</Card>

	</>;
};