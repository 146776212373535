import { Col, Label, Row } from 'reactstrap';

import React from 'react';
import { useTranslation } from 'react-i18next';

const RiskManagement = ({ hook }) => {
	const { t } = useTranslation();

	return (
		<>
			<Row className="mt-4 ms-3">
				<h4>{t('reviewScan.riskManagement')}</h4>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.performImpactAssessment')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.business.yes"
							checked={hook.options.riskManagement.businessImpact}
							value="true"
							onChange={() =>
								hook.setInput('riskManagement', 'businessImpact', true)
							}
							name="risk.business"></input>
						<label className="me-6" htmlFor="risk.business.yes">
							{t('button.yes')}{' '}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.business.no"
							checked={!hook.options.riskManagement.businessImpact}
							value="false"
							onChange={() =>
								hook.setInput('riskManagement', 'businessImpact', false)
							}
							name="risk.business"></input>
						<label htmlFor="risk.business.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.defineRiskCriteria')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.criteria.yes"
							checked={hook.options.riskManagement.criteria}
							value="true"
							onChange={() => hook.setInput('riskManagement', 'criteria', true)}
							name="risk.criteria"></input>
						<label className="me-6" htmlFor="risk.criteria.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.criteria.no"
							checked={!hook.options.riskManagement.criteria}
							value="false"
							onChange={() =>
								hook.setInput('riskManagement', 'criteria', false)
							}
							name="risk.criteria"></input>
						<label htmlFor="risk.criteria.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="ms-2 mt-4">
				<Col>
					<Label>{t('reviewScan.riskInheritance')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.inheritance.yes"
							checked={hook.options.riskManagement.inheritance}
							value="true"
							onChange={() =>
								hook.setInput('riskManagement', 'inheritance', true)
							}
							name="risk.inheritance"></input>
						<label className="me-6" htmlFor="risk.inheritance.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.inheritance.no"
							checked={!hook.options.riskManagement.inheritance}
							value="false"
							onChange={() =>
								hook.setInput('riskManagement', 'inheritance', false)
							}
							name="risk.inheritance"></input>
						<label htmlFor="risk.inheritance.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="ms-2 mt-4">
				<Col>
					<Label>{t('reviewScan.quantitativeRiskAnalysis')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.remediation.yes"
							checked={hook.options.riskManagement.remediation}
							value="true"
							onChange={() =>
								hook.setInput('riskManagement', 'remediation', true)
							}
							name="risk.remediation"></input>
						<label className="me-6" htmlFor="risk.remediation.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="risk.remediation.no"
							checked={!hook.options.riskManagement.remediation}
							value="false"
							onChange={() =>
								hook.setInput('riskManagement', 'remediation', false)
							}
							name="risk.remediation"></input>
						<label htmlFor="risk.remediation.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default RiskManagement;
