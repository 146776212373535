
/* eslint-disable jsx-a11y/anchor-is-valid */

import "../../AuditTests.css"

import {
	Nav
} from "reactstrap";
import React from "react";

export default function ({ categoryIds, categories, selected, onSelect }) {

	const getClass = id => `nav-link ${selected === id ? "active" : ""}`;

	return <>
		<div className="vertical-gradient h-100">
			<Nav vertical justified pills>
				{categoryIds.map(id =>
					<li key={id}>
						{/* If you set an anchor tag, the browser will jump around if you click the links  */}
						<a className={getClass(id)} data-toggle="pill" href={`#`} onClick={() => onSelect(id)}>
							{categories[id].name}
						</a>
					</li>
				)}
			</Nav>
		</div>
	</>
}