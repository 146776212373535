import { Col, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import React from 'react';

import ReviewScanDropdown from '../../Components/ReviewScanDropdown';
import ReviewScanDurationInput from '../../Components/ReviewScanDurationInput';

const BusinessContinuity = ({ hook }) => {
	const { t } = useTranslation();
	return (
		<>
			<Row className="mt-4 ms-3">
				<h4>{t('reviewScan.businessContinuityBcp')}</h4>
			</Row>
			<Row className="mt-4 ms-2">
				<Col xs="6">
					<Label>{t('reviewScan.wellDefinedBcp')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="bcp.enabled.yes"
							checked={hook.options.businessContinuity.enabled}
							value="true"
							onChange={() =>
								hook.setInput('businessContinuity', 'enabled', true)
							}
							name="bcp.enabled"></input>
						<label className="me-6" htmlFor="bcp.enabled.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="bcp.enabled.no"
							checked={!hook.options.businessContinuity.enabled}
							value="false"
							onChange={() =>
								hook.setInput('businessContinuity', 'enabled', false)
							}
							name="bcp.enabled"></input>
						<label htmlFor="bcp.enabled.no">{t('button.no')}</label>
					</div>
				</Col>
				{hook.options.businessContinuity.backupEnabled && (
					<Col xs="4">
						<div className="p-2 me-1">
							<ReviewScanDropdown
								value={hook.options.businessContinuity.backupFrequencyId}
								name="backupFrequency"
								label="Frequency of backup"
								values={hook.frequency}
								onSelect={v =>
									hook.setInput('businessContinuity', 'backupFrequency', v)
								}
							/>
						</div>
					</Col>
				)}
			</Row>
			{hook.options.businessContinuity.enabled && (
				<>
					<Row className="mt-4 ms-2">
						<Col xs="6">
							<>{t('reviewScan.awareOfPlan')}</>
							<div className="mb-3">
								<input
									className="pl-5 me-4"
									type="radio"
									id="bcp.empAware.yes"
									checked={hook.options.businessContinuity.employeeAware}
									value="true"
									onChange={() =>
										hook.setInput('businessContinuity', 'employeeAware', true)
									}
									name="bcp.empAware"></input>
								<label className="me-6" htmlFor="bcp.empAware.yes">
									{t('button.yes')}
								</label>
								<input
									className="pl-5 me-4"
									type="radio"
									id="bcp.empAware.no"
									checked={!hook.options.businessContinuity.employeeAware}
									value="false"
									onChange={() =>
										hook.setInput('businessContinuity', 'employeeAware', false)
									}
									name="bcp.empAware"></input>
								<label htmlFor="bcp.empAware.no"> {t('button.no')}</label>
							</div>
						</Col>
						{hook.options.businessContinuity.backupEnabled && (
							<Col xs="4">
								<div className="p-2 me-1">
									<ReviewScanDurationInput
										labelfor="retention"
										value={hook.options.businessContinuity.retention}
										unit={hook.options.businessContinuity.retentionUnit}
										onChange={(n, v) =>
											hook.setInput('businessContinuity', n, v)
										}>
										{t('reviewScan.retentionPeriod')}
									</ReviewScanDurationInput>
								</div>
							</Col>
						)}
					</Row>
					<Row className="mt-4 ms-2">
						<Col>
							<Label>{t('reviewScan.backUpSystems')}</Label>
							<div className="mb-3">
								<input
									className="pl-5 me-4"
									type="radio"
									id="bcp.backup.yes"
									checked={hook.options.businessContinuity.backupEnabled}
									value="true"
									onChange={() =>
										hook.setInput('businessContinuity', 'backupEnabled', true)
									}
									name="bcp.backup"></input>
								<label className="me-6" htmlFor="bcp.backup.yes">
									{t('button.yes')}
								</label>
								<input
									className="pl-5 me-4"
									type="radio"
									id="bcp.backup.no"
									checked={!hook.options.businessContinuity.backupEnabled}
									value="false"
									onChange={() =>
										hook.setInput('businessContinuity', 'backupEnabled', false)
									}
									name="bcp.backup"></input>
								<label htmlFor="bcp.backup.no"> {t('button.no')}</label>
							</div>
						</Col>
					</Row>
				</>
			)}
		</>
	);
};

export default BusinessContinuity;
