import { Col, Row } from "reactstrap";

import React from "react";

const ComplianceValue = ({ value }) => {

	const isCompliant = value.toUpperCase() === "COMPLIANT";

	let className = isCompliant ? "text-success" : "text-danger";

	return <span className={className}>{value}</span>
}

const severityColours = {
	"CRITICAL": 'text-danger',
	"HIGH": 'text-warning',
	"MEDIUM": 'text-yellow',
	"LOW": '',
}

const SeverityValue = ({ value }) => {
	const
		upper = value.toUpperCase(),
		clasName = severityColours[upper];

	return <span className={clasName}>{value}</span>
}

const EvidenceValue = ({ value }) => <span>{value}</span>;

const AuditValues = ({ values }) => (
	<Row>
		<Col>
			<ComplianceValue value={values[0]} />
		</Col>
		<Col>
			<SeverityValue value={values[1]} />
		</Col>
		<Col><span>{values[2]}</span></Col>
		<Col><span>{values[3]}</span></Col>
		<Col><span>{values[4]}</span></Col>
	</Row>
);

const AutomatedValues = ({ values }) => (
	<Row className="align-items-center">
		<Col>
			<ComplianceValue value={values[0]} />
		</Col>
		<Col>
			<SeverityValue value={values[1]} />
		</Col>
		<Col><EvidenceValue value={values[2]} /></Col>
	</Row>
);

const ResultSubCategoryValues = ({ values }) => {
	if (!values || values.length < 0) {
		return <></>;
	}

	const isAudit = values.length > 3;
	const isAutomated = values.length === 3;

	if (isAudit) {
		return <AuditValues values={values} />;
	}

	if (isAutomated) {
		return <AutomatedValues values={values} />;
	}

	return <></>;
}

export default ResultSubCategoryValues;