import { TYPES_PAGES_ENUM } from './constants';
import { useParams } from 'react-router-dom';

const Talasmart = props => {
	const params = useParams();
	const type = params.type;

	return TYPES_PAGES_ENUM[type];
};

export default Talasmart;
