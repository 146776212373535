import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";

import React from "react";

export default ({ description }) => (
  <Row>
    <Col>
      <Card>
        <CardBody>
          <h4 style={{ minHeight: '4rem' }} className="text-center">
            {description}
          </h4>
        </CardBody>
      </Card>
    </Col>
  </Row>
)