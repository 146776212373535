export default {
	legend: {
		display: true,
		position: 'bottom',
		align: 'start',
	},
	colors: ['#1E83E0', '#9B2FE0', '#E72538', '#96AF33'],
	stroke: {
		curve: 'smooth',
		width: 2,
	},
	fill: {
		opacity: 0.5,
	},
	tooltip: {
		enabled: true,
		enabledOnSeries: undefined,
		followCursor: true,
		theme: 'light',
		shared: true,
	},
	yaxis: {
		labels: {
			show: false,
		},
	},
	chart: {
		type: 'area',
		height: 350,
		stacked: true,
		toolbar: {
			show: false,
		},
	},
};
