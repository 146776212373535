import { useEffect, useState } from "react";

const useRender = (current, expected) => {
  const [render, setRender] = useState(current === expected);

  useEffect(() => {
    setRender(current === expected);
  }, [current, expected]);

  return render;
};

export default useRender;