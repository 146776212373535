let index = 0;

const getIndex = () => index++;

export default {
	referenceData: {
		result: {
			defaultValue: 2,
			values: [
				{
					value: 1,
					label: 'Compliant',
				},
				{
					value: 2,
					label: 'Non-Compliant',
				},
			],
		},
		severity: {
			defaultValue: 3,
			values: [
				{
					value: 1,
					label: 'Low',
				},
				{
					value: 2,
					label: 'Medium',
				},
				{
					value: 3,
					label: 'High',
				},
				{
					value: '4',
					label: 'Critical',
				},
			],
		},
		categories: {
			1.2: {
				parent: 'fn-req-0',
				description:
					'1.2 : Build firewall and router configurations that restrict connections between untrusted networks and any system components in the cardholder data environment. Note',
				name: '',
				subcategories: ['1.2.1'],
				id: '1.2',
			},
			1.3: {
				parent: 'fn-req-0',
				description:
					'1.3 : Prohibit direct public access between the Internet and any system component in the cardholder data environment.',
				name: '',
				subcategories: ['1.3.2', '1.3.4'],
				id: '1.3',
			},
			2.1: {
				parent: 'fn-req-1',
				description:
					'2.1 : Always change vendor-supplied defaults and remove or disable unnecessary default accounts before installing a system on the network. This applies to ALL default passwords, including but not limited to those used by operating systems, software that provides security services, application and system accounts, point-of-sale (POS) terminals, payment applications, Simple Network Management Protocol (SNMP) community strings, etc.).',
				name: '',
				subcategories: ['2.1.1'],
				id: '2.1',
			},
			2.2: {
				parent: 'fn-req-1',
				description:
					'2.2 : Develop configuration standards for all system components. Assure that these standards address all known security vulnerabilities and are consistent with industry-accepted system hardening standards. Sources of industry-accepted system hardening standards may include, but are not limited to',
				name: '',
				subcategories: ['2.2.2'],
				id: '2.2',
			},
			2.3: {
				parent: 'fn-req-1',
				description:
					'2.3 : Encrypt all non-console administrative access using strong cryptography. Note',
				name: '',
				subcategories: ['2.3.1'],
				id: '2.3',
			},
			2.4: {
				parent: 'fn-req-1',
				description:
					'2.4 : Maintain an inventory of system components that are in scope for PCI DSS.',
				name: '',
				subcategories: ['2.4.1'],
				id: '2.4',
			},
			3.2: {
				parent: 'fn-req-2',
				description:
					'3.2 : Do not store sensitive authentication data after authorization (even if encrypted). If sensitive authentication data is received, render all data unrecoverable upon completion of the authorization process. It is permissible for issuers and companies that support issuing services to store sensitive authentication data if: •  There is a business justification and •  The data is stored securely. Sensitive authentication data includes the data as cited in the following Requirements 3.2.1 through 3.2.3:',
				name: '',
				subcategories: ['3.2.1'],
				id: '3.2',
			},
			3.4: {
				parent: 'fn-req-2',
				description:
					'3.4 : Render PAN unreadable anywhere it is stored (including on portable digital media, backup media, and in logs) by using any of the following approaches',
				name: '',
				subcategories: ['3.4.1'],
				id: '3.4',
			},
			3.6: {
				parent: 'fn-req-2',
				description:
					'3.6 : Fully document and implement all key-management processes and procedures for cryptographic keys used for encryption of cardholder data, including the following',
				name: '',
				subcategories: ['3.6.4'],
				id: '3.6',
			},
			4.1: {
				parent: 'fn-req-3',
				description:
					'4.1 : Use strong cryptography and security protocols to safeguard sensitive cardholder data during transmission over open, public networks, including the following',
				name: '',
				subcategories: ['4.1.1'],
				id: '4.1',
			},
			5.1: {
				parent: 'fn-req-4',
				description:
					'5.1 : Use strong cryptography and security protocols to safeguard sensitive cardholder data during transmission over open, public networks, including the following',
				name: '',
				subcategories: ['5.1.1'],
				id: '5.1',
			},
			6.1: {
				parent: 'fn-req-5',
				description:
					'6.1 : Establish a process to identify security vulnerabilities, using reputable outside sources for security vulnerability information, and assign a risk ranking (for example, as “high,” “medium,” or “low”) to newly discovered security vulnerabilities. Note',
				name: '',
				subcategories: ['6.1.1'],
				id: '6.1',
			},
			6.2: {
				parent: 'fn-req-5',
				description:
					'6.2 : Ensure that all system components and software are protected from known vulnerabilities by installing applicable vendor-supplied security patches. Install critical security patches within one month of release. Note',
				name: '',
				subcategories: ['6.2.1'],
				id: '6.2',
			},
			6.5: {
				parent: 'fn-req-5',
				description:
					'6.5 : Address common coding vulnerabilities in software-development processes as follows: •  Train developers at least annually  in up-to-date secure coding techniques, including how to avoid common coding vulnerabilities. •  Develop applications based on secure coding guidelines. Note: The vulnerabilities listed at 6.5.1 through 6.5.10 were current with industry best practices when this version of PCI DSS was published. However, as industry best practices for vulnerability management are updated (for example, the OWASP Guide, SANS CWE Top 25, CERT Secure Coding, etc.), the current best practices must be used for these requirements.',
				name: '',
				subcategories: ['6.5.3'],
				id: '6.5',
			},
			7.2: {
				parent: 'fn-req-6',
				description:
					'7.2 : Establish an access control system(s) for systems components that restricts access based on a user’s need to know, and is set to “deny all” unless specifically allowed. This access control system(s) must include the following',
				name: '',
				subcategories: ['7.2.1'],
				id: '7.2',
			},
			8.2: {
				parent: 'fn-req-7',
				description:
					'8.2 : In addition to assigning a unique ID, ensure proper user-authentication management for non-consumer users and administrators on all system components by employing at least one of the following methods to authenticate all users',
				name: '',
				subcategories: ['8.2.3', '8.2.5'],
				id: '8.2',
			},
			8.3: {
				parent: 'fn-req-7',
				description:
					'8.3 : Secure all individual non-console administrative access and all remote access to the CDE using multi-factor authentication.  Note',
				name: '',
				subcategories: ['8.3.1'],
				id: '8.3',
			},
		},
		subcategories: {
			'1.2.1': {
				parent: '1.2',
				description:
					'Restrict inbound and outbound traffic to that which is necessary for the cardholder data environment, and specifically deny all other traffic.',
				name: '1.2.1',
				index: getIndex(),
			},
			'1.3.2': {
				parent: '1.3',
				description:
					'Limit inbound Internet traffic to IP addresses within the DMZ.',
				name: '1.3.2',
				index: getIndex(),
			},
			'1.3.4': {
				parent: '1.3',
				description:
					'Do not allow unauthorized outbound traffic from the cardholder data environment to the Internet.',
				name: '1.3.4',
				index: getIndex(),
			},
			'3.2.1': {
				parent: '3.2',
				description:
					'Do not store the full contents of any track (from the magnetic stripe located on the back of a card, equivalent data contained on a chip, or elsewhere) after authorization. This data is alternatively called full track, track, track 1, track 2, and magnetic-stripe data. Note: In the normal course of business, the following data elements from the magnetic stripe may need to be retained: •  The cardholder’s name •  Primary account number (PAN) •  Expiration date •  Service code To minimize risk, store only these data elements as needed for business.',
				name: '3.2.1',
				index: getIndex(),
			},
			'3.4.1': {
				parent: '3.4',
				description:
					'If disk encryption is used (rather than file- or column-level database encryption), logical access must be managed separately and independently of native operating system authentication and access control mechanisms (for example, by not using local user account databases or general network login credentials). Decryption keys must not be associated with user accounts. Note',
				name: '3.4.1',
				index: getIndex(),
			},
			'4.1.1': {
				parent: '4.1',
				description:
					'Ensure wireless networks transmitting cardholder data or connected to the cardholder data environment, use industry best practices to implement strong encryption for authentication and transmission.',
				name: '4.1.1',
				index: getIndex(),
			},
			'5.1.1': {
				parent: '5.1',
				description:
					'Ensure that anti-virus programs are capable of detecting, removing, and protecting against all known types of malicious software.',
				name: '5.1.1',
				index: getIndex(),
			},
			'6.1.1': {
				parent: '6.1',
				description: '',
				name: '6.1.1',
				index: getIndex(),
			},
			'6.2.1': {
				parent: '6.2',
				description: '',
				name: '6.2.1',
				index: getIndex(),
			},
			'6.5.3': {
				parent: '6.5',
				description: 'Insecure cryptographic storage',
				name: '6.5.3',
				index: getIndex(),
			},
			'7.2.1': {
				parent: '7.2',
				description: 'Coverage of all system components',
				name: '7.2.1',
				index: getIndex(),
			},
			'8.2.3': {
				parent: '8.2',
				description: 'Passwords/passphrases must meet the following',
				name: '8.2.3',
				index: getIndex(),
			},
			'8.2.5': {
				parent: '8.2',
				description:
					'Do not allow an individual to submit a new password/passphrase that is the same as any of the last four passwords/passphrases he or she has used.',
				name: '8.2.5',
				index: getIndex(),
			},
			'8.3.1': {
				parent: '8.3',
				description:
					'Incorporate multi-factor authentication for all non-console access into the CDE for personnel with administrative access. Note',
				name: '8.3.1',
				index: getIndex(),
			},
			'10.1.1': {
				parent: '10.1',
				description: '',
				name: '10.1.1',
				index: getIndex(),
			},
			'10.2.1': {
				parent: '10.2',
				description: 'All individual user accesses to cardholder data',
				name: '10.2.1',
				index: getIndex(),
			},
			'10.2.2': {
				parent: '10.2',
				description:
					'All actions taken by any individual with root or administrative privileges',
				name: '10.2.2',
				index: getIndex(),
			},
			'10.2.3': {
				parent: '10.2',
				description: 'Access to all audit trails',
				name: '10.2.3',
				index: getIndex(),
			},
			'10.2.4': {
				parent: '10.2',
				description: 'Invalid logical access attempts',
				name: '10.2.4',
				index: getIndex(),
			},
			'10.2.5': {
				parent: '10.2',
				description:
					'Use of and changes to identification and authentication mechanisms—including but not limited to creation of new accounts and elevation of privileges—and all changes, additions, or deletions to accounts with root or administrative privileges',
				name: '10.2.5',
				index: getIndex(),
			},
			'10.2.6': {
				parent: '10.2',
				description: 'Initialization, stopping, or pausing of the audit logs',
				name: '10.2.6',
				index: getIndex(),
			},
			'10.2.7': {
				parent: '10.2',
				description: 'Creation and deletion of system-level objects',
				name: '10.2.7',
				index: getIndex(),
			},
			'10.3.1': {
				parent: '10.3',
				description: 'User identification',
				name: '10.3.1',
				index: getIndex(),
			},
			'10.3.2': {
				parent: '10.3',
				description: 'Type of event',
				name: '10.3.2',
				index: getIndex(),
			},
			'10.3.3': {
				parent: '10.3',
				description: 'Date and time',
				name: '10.3.3',
				index: getIndex(),
			},
			'10.3.4': {
				parent: '10.3',
				description: 'Success or failure indication',
				name: '10.3.4',
				index: getIndex(),
			},
			'10.3.5': {
				parent: '10.3',
				description: 'Origination of event',
				name: '10.3.5',
				index: getIndex(),
			},
			'10.3.6': {
				parent: '10.3',
				description:
					'Identity or name of affected data, system component, or resource.',
				name: '10.3.6',
				index: getIndex(),
			},
			'10.5.1': {
				parent: '10.5',
				description:
					'Limit viewing of audit trails to those with a job-related need.',
				name: '10.5.1',
				index: getIndex(),
			},
			'10.5.2': {
				parent: '10.5',
				description:
					'Protect audit trail files from unauthorized modifications.',
				name: '10.5.2',
				index: getIndex(),
			},
			'10.5.3': {
				parent: '10.5',
				description:
					'Promptly back up audit trail files to a centralized log server or media that is difficult to alter.',
				name: '10.5.3',
				index: getIndex(),
			},
			'10.5.5': {
				parent: '10.5',
				description:
					'Use file-integrity monitoring or change-detection software on logs to ensure that existing log data cannot be changed without generating alerts (although new data being added should not cause an alert).',
				name: '10.5.5',
				index: getIndex(),
			},
			'10.6.1': {
				parent: '10.6',
				description: 'Review the following at least daily',
				name: '10.6.1',
				index: getIndex(),
			},
			'10.6.2': {
				parent: '10.6',
				description:
					'Review logs of all other system components periodically based on the organization’s policies and risk management strategy, as determined by the organization’s annual risk assessment.',
				name: '10.6.2',
				index: getIndex(),
			},
			'10.6.3': {
				parent: '10.6',
				description:
					'Follow up exceptions and anomalies identified during the review process.',
				name: '10.6.3',
				index: getIndex(),
			},
			'11.4.1': {
				parent: '11.4',
				description: '',
				name: '11.4.1',
				index: getIndex(),
			},
			'12.5.2': {
				parent: '12.5',
				description:
					'Monitor and analyze security alerts and information, and distribute to appropriate personnel.',
				name: '12.5.2',
				index: getIndex(),
			},
			'12.9.1': {
				parent: '12.9',
				description: '',
				name: '12.9.1',
				index: getIndex(),
			},
		},
	},
	headers: [
		{
			id: 1,
			value: 'Requirement',
			classList: 'dont-print col col-md-2 text-md-center',
			style: null,
		},
		{
			id: 2,
			value: 'Sub-Requirement',
			classList: 'col col-md-2 text-md-center',
			style: null,
		},
		{
			id: 3,
			value: 'Test Case',
			classList: 'print-sub-cat-col col col-md-2 text-md-center',
			style: null,
		},
		{
			id: 4,
			value: 'Result',
			classList: 'print-result-col col col-md-2 text-md-center',
			style: {
				marginLeft: '-1rem',
			},
		},
		{
			id: 5,
			value: 'Severity',
			classList: 'col col-md-1 text-md-left',
			style: {
				marginLeft: '3rem',
			},
		},
		{
			id: 6,
			value: 'Evidence',
			classList: 'col col-md-1 text-md-center',
			style: {
				marginLeft: '6rem',
			},
		},
	],
	testCases: [
		{
			id: 'fn-0',
			value: 'SECURE NETWORK',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [''],
			children: ['fn-req-0'],
		},
		{
			id: 'fn-1',
			value: 'SECURE CARDHOLDER DATA',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [''],
			children: ['fn-req-2', 'fn-req-3'],
		},
		{
			id: 'fn-2',
			value: 'VULNERABILITY MANAGEMENT',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [''],
			children: ['fn-req-4', 'fn-req-5'],
		},
		{
			id: 'fn-3',
			value: 'ACCESS CONTROL',
			fgColor: '#e9ecef',
			bgColor: '#a31aff',
			description: [''],
			children: ['fn-req-6', 'fn-req-7'],
		},
	],
	childTestCases: {
		'fn-req-0': {
			parent: 'fn-0',
			value: 'Requirement 1',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [
				'Requirement 1 - Install and maintain a firewall configuration to protect cardholder data',
			],
			categories: ['1.2', '1.3'],
			id: 'fn-req-0',
		},
		'fn-req-1': {
			parent: 'fn-0',
			value: 'Requirement 2',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [
				'Requirement 2 - Do not use vendor-supplied defaults for system passwords and other security parameters',
			],
			categories: ['2.1', '2.2', '2.3', '2.4'],
			id: 'fn-req-1',
		},
		'fn-req-2': {
			parent: 'fn-1',
			value: 'Requirement 3',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: ['Requirement 3 - Protect stored cardholder data'],
			categories: ['3.2', '3.4'],
			id: 'fn-req-2',
		},
		'fn-req-3': {
			parent: 'fn-1',
			value: 'Requirement 4',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [
				'Requirement 4 - Encrypt transmission of cardholder data across open, public networks',
			],
			categories: ['4.1'],
			id: 'fn-req-3',
		},
		'fn-req-4': {
			parent: 'fn-2',
			value: 'Requirement 5',
			fgColor: '#e9ecef',
			bgColor: '#a31aff',
			description: [
				'Requirement 5 - Use and regularly update anti-virus software or programs',
			],
			categories: ['5.1'],
			id: 'fn-req-4',
		},
		'fn-req-5': {
			parent: 'fn-2',
			value: 'Requirement 6',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [
				'Requirement 6 - Develop and maintain secure systems and applications',
			],
			categories: ['6.2', '6.5'],
			id: 'fn-req-5',
		},
		'fn-req-6': {
			parent: 'fn-3',
			value: 'Requirement 7',
			fgColor: '#e9ecef',
			bgColor: '#4da6ff',
			description: [
				'Requirement 7 - Restrict access to cardholder data by business need to know',
			],
			categories: ['7.2'],
			id: 'fn-req-6',
		},
		'fn-req-7': {
			parent: 'fn-3',
			value: 'Requirement 8',
			fgColor: '#e9ecef',
			bgColor: '#a31aff',
			description: [
				'Requirement 8 - Assign a unique ID to each person with computer access',
			],
			categories: ['8.2', '8.3'],
			id: 'fn-req-7',
		},
	},
};
