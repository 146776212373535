import { getIssueList, results } from '../../../../apiclients/Ransomware';
import { useEffect, useState } from 'react';

import { getItem } from '../../../../services/browserStorageService';
import { isAzure } from '../../../../apiclients/Common';

const uniqueNames = issue => {
	var names = new Map();

	issue.forEach(resource => {
		if (isAzure()) {
			resource['ResourceName'] = resource.ResourceId;
		}

		const has = names.has(resource.ResourceName);
		if (!has) {
			names.set(resource.ResourceName, resource);
		}
	});

	let arr = [];

	for (const [key, value] of names.entries()) {
		arr[key] = value;
	}

	return [...names.values()];
};

const mapIssues = issues => {
	const map = [];

	Object.keys(issues).forEach(key => {
		const issue = issues[key];
		map[key] = uniqueNames(issue);
	});

	return map;
};

const useReport = reportId => {
	const [busy, setBusy] = useState(true);
	const [report, setReport] = useState({});
	const [error, setError] = useState(false);
	const [issues, setIssues] = useState([]);
	const vulnerabilities = getItem('dashboard-vulnerabilities') || [];

	const setData = ([report, issues]) => {
		const noErrors = report.success && issues.success;
		setError(!noErrors);

		const reportData = report.success ? report.data : {};
		setReport(reportData);

		const issueList = issues.success ? issues.data : [];
		setIssues(mapIssues(issueList));

		setBusy(false);
	};

	useEffect(() => {
		Promise.all([results(reportId), getIssueList(reportId)]).then(setData);
	}, [reportId]);

	return { busy, report, error, vulnerabilities, issues };
};

export default useReport;
