import { Input } from 'reactstrap';
import { Label } from 'reactstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReviewScanFrequencyInput = ({
	labelfor,
	onChange,
	value,
	style,
	disabled,
	children,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<Label htmlFor={labelfor}>{children}</Label>
			<Input
				style={style}
				disabled={disabled}
				value={value}
				type="select"
				name={labelfor}
				onChange={e => onChange(labelfor, e.target.value)}>
				<option value="1">
					{t('reviewScan.every')} {t('reviewScan.week')}
				</option>
				<option value="2">
					{t('reviewScan.every')} {t('reviewScan.day')}
				</option>
				<option value="3">
					{t('reviewScan.every')} {t('reviewScan.hour')}
				</option>
				<option value="4">
					{t('reviewScan.every')} {t('reviewScan.minute')}
				</option>
			</Input>
		</>
	);
};

export default ReviewScanFrequencyInput;
