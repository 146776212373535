import React, { useState } from 'react';
import { TABS, sortRowsByCriticality } from './constants';
import { Card, CardBody } from 'reactstrap';
import IssuesTable from './IssuesTable';
import ResourcesTable from './ResourcesTable';
import { getReviewUrl } from '../../../services/reportService';

const nodeMapper = nodes => {
	let index = 0;
	return nodes.map(node => {
		const data = node.data;
		const nodeData = {
			key: index++,
			name: data.name,
			resourceId: data.id,
			level: data.level,
			parent: data.parent,
			resourceType: data.resourcetype,
		};
		if (data.meta) nodeData.meta = data.meta;
		return nodeData;
	});
};

const TopologyTable = ({ showModal, info }) => {
	const { selectedReport, remediation, results, nodes, loaded } = info;

	const [activeTab, setActiveTab] = useState(TABS.RESOURCES);

	if (!loaded) return null;

	return (
		<Card className="dashboard-card">
			<CardBody>
				{activeTab === TABS.ISSUES ? (
					<IssuesTable
						headerProps={{
							reviewUrl: getReviewUrl(selectedReport),
							report: selectedReport,
							setActiveTab,
						}}
						remediation={remediation}
						data={sortRowsByCriticality(results)}
					/>
				) : (
					<ResourcesTable
						headerProps={{
							reviewUrl: getReviewUrl(selectedReport),
							report: selectedReport,
							setActiveTab,
						}}
						showModal={showModal}
						data={nodeMapper(nodes)}
					/>
				)}
			</CardBody>
		</Card>
	);
};

export { TopologyTable };