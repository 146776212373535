import React, { useState } from 'react';

import ErrorModal from './ErrorModal';
import { Link } from 'react-router-dom';
import { isDemoAccount, isTrialAccount } from '../services/accountTypeService'

const linkCss = 'btn btn-secondary btn-outline btn-md';

const ConfirmLink = ({ onClick, to, confirmText, children, title }) => {

	const [showModal, setShowModal] = useState(false);

	const onConfirmClick = e => {
		const result = onClick(e);
		if (!(isDemoAccount() || isTrialAccount())) {
			setShowModal(true);
		}
		if (!result) {
			e.preventDefault();
		}
		return result;
	};

	// const onConfirmClick = e => {
	// 	const result = onClick(e);
	// 	setShowModal(true);
	// 	if (!result) {
	// 		e.preventDefault();
	// 	}
	// 	return result;
	// }

	return (
		<>
			<Link
				to={to}
				title={title}
				className={linkCss}
				onClick={onConfirmClick}>
				{children}
			</Link>
			{showModal && <ErrorModal text={confirmText} onClose={() => setShowModal(false)} />}
		</>
	)

};

export default ConfirmLink;