import React, { useState } from 'react';
import { Container, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';

import { createKey } from '../../../../apiclients/EmailLogin';

import { EvaluationApiClient } from '../../../../apiclients/EvaluationApiClient';
import { INITIAL_VALUES, LABELS, VALIDATORS } from '../constants';
import { AwsRegions } from '../../../auth/MultiCloudSignIn/Constants';
import useLoginWithKey from '../../../auth/hooks/useLoginWithKey';
import SuccessIcon from '../../../../assets/img/SuccessIcon.svg';
import FailIcon from '../../../../assets/img/FailIcon.svg';
import ArrowsSpin from '../../../../assets/img/arrows-spin.svg';
import Plus from '../../../../assets/img/plus.svg';
import DisablePlus from '../../../../assets/img/disablePlus.svg';

import '../styles.css';

const AddAccount = ({ data, cloudSystem, setCloudSystem }) => {
	const [values, setValues] = useState(INITIAL_VALUES);
	const [errors, setErrors] = useState({});
	const [disabled, setDisabled] = useState(true);
	const [showValidationPopup, setShowValidationPopup] = useState(false);
	const [isValidation, setIsValidation] = useState(false);
	const [isValid, setIsValid] = useState(false);

	const loginWithKey = useLoginWithKey();
	const { t } = useTranslation();

	const validateField = (name, value) => {
		const validator = VALIDATORS[name];
		if (!validator) return true;

		const isFieldValid = validator.method && validator.method(value);

		const error = isFieldValid ? '' : validator.error || '';

		setErrors(prev => ({
			...prev,
			[cloudSystem]: {
				...prev[cloudSystem],
				[name]: { isError: !isFieldValid, message: error },
			},
		}));
		return isFieldValid;
	};

	const getErrorMessage = key => {
		const errorKey = errors[cloudSystem]?.[key]?.message;

		return t(errorKey);
	};

	const onSubmit = e => {
		e.preventDefault();

		const validFields = Object.keys(values[cloudSystem]).map(key => {
			return validateField(key, values[cloudSystem]?.[key]);
		});

		if (!validFields.every(Boolean)) {
			return;
		}
		setIsValid(false);
		postKeys();
	};

	const onChange = e => {
		e.persist();
		const newValues = {
			...values,
			[cloudSystem]: {
				...values[cloudSystem],
				[e.target.name]: e.target.value,
			},
		};

		setValues(newValues);

		validateField(e.target.name, e.target.value);
	};

	const onRegionChange = value => {
		setValues(prev => ({
			...prev,
			aws: { ...prev.aws, region: value },
		}));
		validateField('region', value);
	};
	const handleClose = () => {
		setValues(INITIAL_VALUES);
		setErrors({});
	};

	const handleError = console.log;

	// const changeDefaultKey = keyId => {
	// 	const keyInfo = {
	// 		keyId,
	// 		region: values[cloudSystem].region,
	// 	};

	// 	setUserDefaults(
	// 		{
	// 			defaultKey: keyInfo,
	// 		},
	// 		() => data.setDefaultKey(keyInfo),
	// 		handleError
	// 	).then(() => {
	// 		onLogin(keyId);
	// 	});
	// };

	const onLogin = keyId => {
		const info = {
			token: data.token,
			keyId,
			domain: data.keyDomain,
			region: values[cloudSystem].region,
			regions: [],
		};
		loginWithKey(info, handleError, false);
	};

	const postKeySuccess = (newKey, keyInfo) => {
		const keyObj = {
			id: newKey.newKeyId,
			name: keyInfo.keyName,
			provider: keyInfo.keyProvider,
			providerInfo: keyInfo.keyProviderInfo,
			domain: data.keyDomain,
		};
		onLogin(newKey.newKeyId);
		data.onSuccess(keyObj);
		handleClose();
		// BRL 2023-08-08 removing this for now for testing purposes
		//changeDefaultKey(newKey.newKeyId);
	};

	const validateAWSSuccess = res => {
		setIsValidation(false);
		if (res.error) {
			console.log('AWS validate error:', res.error);
			setShowValidationPopup(true);
		}
		if (res.Account) {
			setDisabled(false);
			setValues(prev => ({
				...prev,
				aws: { ...prev.aws, accountId: res.Account },
			}));
			setIsValid(true);
			setShowValidationPopup(false);
		}
	};
	const validateOnpremSuccess = res => {
		setIsValidation(false);
		if (res.error) {
			console.log('Onprem validate error:', res.error);
			setShowValidationPopup(true);
		}
		if (res.result) {
			setDisabled(false);
			setValues(prev => ({
				...prev,
				onprem: { ...prev.onprem, accountId: res.result.account_id },
			}));
			setIsValid(true);
			setShowValidationPopup(false);
		}
	};
	const validateK8sSuccess = res => {
		setIsValidation(false);
		if (res.error) {
			console.log('K8S validate error:', res.error);
			setShowValidationPopup(true);
		}
		if (res.result) {
			setDisabled(false);
			setValues(prev => ({
				...prev,
				k8s: { ...prev.k8s, accountId: res.result.account_id },
			}));
			setIsValid(true);
			setShowValidationPopup(false);
		}
	};

	const onValidateAccount = () => {
		const provider = cloudSystemOptions.find(
			option => option.value === cloudSystem
		)?.value;

		console.log('values:', values);
		console.log('cloudSystem:', cloudSystem);
		console.log('provider:', provider);
		console.log('inner values:', values[cloudSystem]);

		setIsValidation(true);
		const systemValues = values[cloudSystem];
		if ('aws' === cloudSystem) {
			const keys = {
				Region: 'us-west-2',
				AccessKey: systemValues.accessKey,
				SecretKey: systemValues.secretKey,
				Regions: [],
			};
			new EvaluationApiClient().validateAwsAccount(
				keys,
				validateAWSSuccess,
				() => setShowValidationPopup(true)
			);
		} else if ('onprem' === cloudSystem) {
			const keys = {
				AccessKey: systemValues.accessKey,
				SecretKey: systemValues.secretKey,
			};
			new EvaluationApiClient().validateOnpremAccount(
				keys,
				validateOnpremSuccess,
				() => setShowValidationPopup(true)
			);
		} else if ('k8s' === cloudSystem) {
			const keys = {
				AccessKey: systemValues.accessKey,
				SecretKey: systemValues.secretKey,
			};
			new EvaluationApiClient().validateK8sAccount(
				keys,
				validateK8sSuccess,
				() => setShowValidationPopup(true)
			);
		} else {
			setIsValidation(false);
		}
	};

	const postKeyFailure = res => {
		// TODO handle error
		handleError('failure:', res);
	};

	const postKeys = () => {
		const provider = cloudSystemOptions.find(
			option => option.value === cloudSystem
		)?.value;

		if ('aws' === provider) {
			const awsValues = values.aws;
			const keyInfo = {
				keyProvider: provider,
				keyName: awsValues.friendlyName,
				domain: data.keyDomain,
				keyInfo: {
					accessKey: awsValues.accessKey,
					secretKey: awsValues.secretKey,
				},
				keyProviderInfo: {
					accessKey: awsValues.accessKey,
					accountNumber: awsValues.accountId,
					regions: [awsValues.region],
				},
			};

			createKey(
				data.token,
				keyInfo,
				newKey => postKeySuccess(newKey, keyInfo),
				postKeyFailure
			);
		} else if ('onprem' === provider) {
			const systemValues = values[cloudSystem];
			const keyInfo = {
				keyProvider: provider,
				keyName: systemValues.friendlyName,
				domain: data.keyDomain,
				keyInfo: {
					accessKey: systemValues.accessKey,
					secretKey: systemValues.secretKey,
				},
				keyProviderInfo: {
					accessKey: systemValues.accessKey,
					accountNumber: systemValues.accountId,
				},
			};

			createKey(
				data.token,
				keyInfo,
				newKey => postKeySuccess(newKey, keyInfo),
				postKeyFailure
			);
		} else if ('k8s' === provider) {
			const systemValues = values[cloudSystem];
			const keyInfo = {
				keyProvider: provider,
				keyName: systemValues.friendlyName,
				domain: data.keyDomain,
				keyInfo: {
					accessKey: systemValues.accessKey,
					secretKey: systemValues.secretKey,
				},
				keyProviderInfo: {
					accessKey: systemValues.accessKey,
					accountNumber: systemValues.accountId,
				},
			};

			createKey(
				data.token,
				keyInfo,
				newKey => postKeySuccess(newKey, keyInfo),
				postKeyFailure
			);
		}
	};

	const regionOptions = AwsRegions.map(item => ({
		value: item.code,
		label: (
			<div className="d-flex justify-content-between">
				<span>{item.name}</span> <span>({item.code})</span>
			</div>
		),
	}));

	const cloudSystemOptions = [
		{ value: 'aws', label: 'AWS' },
		{ value: 'azure', label: 'Azure' },
		...(process.env.REACT_APP_FEAT_SHOW_ONPREM === '1'
			? [{ value: 'onprem', label: 'On Prem' }]
			: []),
		...(process.env.REACT_APP_FEAT_SHOW_K8S === '1'
			? [{ value: 'k8s', label: 'K8S' }]
			: []),
	];

	const hasNecessaryInfo =
		values.aws.accessKey &&
		values.aws.friendlyName &&
		values.aws.region &&
		values.aws.secretKey;

	const btnStyles = {
		marginTop: '20px',
		paddingTop: '10px',
		paddingBottom: '10px',
		borderRadius: '25px',
		width: '250px',
		backgroundColor: isValidation ? '#D4DEF9' : '#2474F3',
		color: isValidation ? '#2474F3' : 'white',
		border: 'none',
	};

	const addBtnStyles = {
		marginTop: '20px',
		paddingTop: '10px',
		paddingBottom: '10px',
		borderRadius: '25px',
		width: '250px',
		backgroundColor: '#2474F3',
		color: 'white',
		border: 'none',
	};

	return (
		<>
			<Container className="p-0" style={{ width: '50%' }}>
				<form
					className="d-flex flex-column pb-4 cloud-form"
					onSubmit={onSubmit}>
					<div>
						<p className="cloud-form__label">{t('form.cloudSystem')}</p>
						<Select
							options={cloudSystemOptions}
							value={cloudSystem}
							onChange={setCloudSystem}
						/>
					</div>
					{LABELS[cloudSystem] &&
						Object.keys(LABELS[cloudSystem]).map(key =>
							cloudSystem === 'aws' && key === 'region' ? (
								<Select
									key={cloudSystem + key + 'select'}
									label={LABELS[cloudSystem]?.[key]}
									options={regionOptions}
									value={values?.[cloudSystem]?.region}
									onChange={onRegionChange}
									valid={
										values[cloudSystem]?.region &&
										!errors[cloudSystem]?.region?.isError
									}
									invalid={errors[cloudSystem]?.region?.isError}
								/>
							) : (
								<Input
									key={cloudSystem + key + 'input'}
									onChange={onChange}
									disabled={key === 'accountId'}
									value={values[cloudSystem]?.[key]}
									name={key}
									password={key === 'secretKey'}
									type={key === 'secretKey' ? 'password' : 'text'}
									label={LABELS[cloudSystem]?.[key]}
									error={getErrorMessage(key)}
								/>
							)
						)}
					{isValid ? (
						<button disabled={disabled} type="submit" style={addBtnStyles}>
							{t('button.addAccount')}
							<img
								src={showValidationPopup ? DisablePlus : Plus}
								className="m-1"
								alt="plus"
							/>
						</button>
					) : (
						<button
							disabled={!hasNecessaryInfo && cloudSystem === 'aws'}
							type="button"
							onClick={onValidateAccount}
							style={btnStyles}>
							{isValidation
								? t('addAccount.isValidating')
								: t('addAccount.validateAccount')}

							{isValidation ? (
								<Spinner color="primary" size="sm" className="m-1" />
							) : (
								<img src={ArrowsSpin} alt="arrowsSpin" />
							)}
						</button>
					)}

					{showValidationPopup ? (
						<div className="validate-account">
							<p className="fail-text">
								{t('addAccount.validationFailed')}
								<img src={FailIcon} alt="fail-icon" className="m-1" />
							</p>
							<p>{t('addAccount.checkKeys')}</p>
						</div>
					) : (
						<>
							{isValid ? (
								<p className="success-text">
									{t('addAccount.accountValidated')}
									<img src={SuccessIcon} alt="successIcon" className="m-1" />
								</p>
							) : (
								<div>
									<p className="validate-account">
										{isValidation
											? t('addAccount.waitCouple')
											: t('addAccount.validateAnAccount')}
									</p>
								</div>
							)}
						</>
					)}
				</form>
			</Container>
		</>
	);
};

export default AddAccount;
