import classNames from 'classnames';
import React from 'react';
import { Button as ButtonComponent } from 'reactstrap';

const Button = ({ children, variant = 'filled', className, ...rest }) => {
	const buttonClassnames = classNames('button-component', {
		'button-component_outlined': variant === 'outlined',
		[className]: className,
	});

	return (
		<ButtonComponent {...rest} className={buttonClassnames}>
			{children}
		</ButtonComponent>
	);
};

export default Button;
