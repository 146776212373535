import * as types from "../constants";

const initialState = {
	logout : false,
	redirect: ''
};

export default function reducer(state = initialState, actions){
	switch (actions.type) {
		case types.IDLE_TIMEOUT:
			return {
			...state,
				logout: actions.payload.remainingTime < 30,
				redirect: actions.payload.redirect
		};
		case types.USER_ACTIVE:{
			return {
				...state,
				logout: false
			}
		} 

		default:
			return state;
	}
}