import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { getCurrentUserKey } from '../../services/AuthService';
import { ReactComponent as Arrow } from '../../assets/img/arrowRightLeft.svg';

const UserDetails = ({ users, onClick }) => {
	const curr = getCurrentUserKey();
	const filteredUsers = useMemo(
		() => (users ? users.filter(user => user.key !== curr) : []),
		[curr, users]
	);

	return filteredUsers.map(user => (
		<DropdownItem
			key={user.key}
			onClick={() => onClick(user)}
			className="border-0 p-0">
			<Link
				style={{ width: '95%' }}
				to="/dashboard"
				title={user?.value?.userInfo?.[1]?.value}>
				<div className="user-dropdown__button">
					<Arrow />
					{user?.value?.user?.UserName}
				</div>
			</Link>
		</DropdownItem>
	));
};

export default UserDetails;
