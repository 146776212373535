import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup, Modal, ModalBody } from 'reactstrap';
import Input from '../../../components/Form/Input';
import PasswordBar from '../../../components/Form/PasswordBar';
import { changePassword } from '../../../apiclients/EmailLogin';
import { getUserAccessToken } from '../../../services/AuthService';
import { ReactComponent as SuccessIcon } from '../../../assets/img/SuccessChangedPassIcon.svg';
import ModalWindow from './ ModalWindow';

const validators = {
	newPassword:
		/^[\S](?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{8,255}$).*[\S]$/,
};

const INITIAL_VALUES = {
	oldPassword: '',
	newPassword: '',
	confirmPassword: '',
};

const INITIAL_ERRORS = {
	oldPassword: false,
	newPassword: false,
	confirmPassword: false,
	unknownError: false,
};
const ChangePassword = () => {
	const { t } = useTranslation();
	const [values, setValues] = useState(INITIAL_VALUES);
	const [errors, setErrors] = useState(INITIAL_ERRORS);
	const [showRateLimitError, setShowRateLimitError] = useState(false);
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	// const [showErrorMessage, setShowErrorMessage] = useState(false);

	const validateField = useCallback(
		(name, value) => {
			const isNotEmpty = !!value?.trim();

			let isValid;
			if (name === 'confirmPassword') {
				isValid = value === values.newPassword;
			} else {
				const validator = validators[name];
				isValid = validator ? validator.test(value) : true;
			}
			if (name === 'newPassword' && isValid) {
				if (value === values.oldPassword) {
					isValid = false;
				}
			}

			return isNotEmpty && isValid;
		},
		[values.newPassword, values.oldPassword]
	);

	const validateForm = useCallback(
		() => Object.keys(values).every(key => validateField(key, values[key])),
		[values, validateField]
	);

	const isValidForm = useMemo(() => validateForm(), [validateForm]);

	const onChange = e => {
		e.persist();
		if (!e?.target) return;
		const value = e.target?.value;
		const name = e.target?.name;
		setValues(prev => ({ ...prev, [name]: value }));
	};

	const onBlur = e => {
		e.persist();

		const isValid = validateField(e.target.name, e.target.value);
		setErrors(prev => ({ ...prev, [e.target.name]: !isValid }));
	};

	const onSuccess = () => {
		setShowSuccessMessage(true);
		setErrors(INITIAL_ERRORS);
	};

	const onFailure = res => {
		// TODO show a real message
		// setShowErrorMessage(true);
		console.log('Password change error:', res);
		if ('Invalid token or Password' === res.responseError?.error) {
			// TODO show correct error to user
			setErrors(prevErrors => ({
				...prevErrors,
				oldPassword: true,
			}));
			console.log('**** most likely old password is incorrect');
			console.log(errors, 'asdsaadsadsadsadsadsadsadsadsadsads');
		} else if (
			'New password must not be the same as old password' ===
			res.responseError?.error
		) {
			// TODO show correct error to user
			setErrors(prevErrors => ({
				...prevErrors,
				newPassword: true,
			}));
			console.log('**** new password is same as old password');
		} else if ('Rate limit exceeded' === res.responseError?.error) {
			// TODO show correct error to user
			setShowRateLimitError(true);
			console.log('**** Rate limited, try again later');
		} else if ('Password failed validation' === res.responseError?.error) {
			// The password should have already been validated but handle this just in case.
			setErrors(prevErrors => ({
				...prevErrors,
				unknownError: true,
			}));
			console.log(
				'Password failed validation on the backend:',
				res.responseError?.message
			);
		} else {
			// TODO show correct error to user
			setErrors(prevErrors => ({
				...prevErrors,
				unknownError: true,
			}));
			console.log('*** Unknown error, try again later');
		}
	};

	const onSubmit = async e => {
		e.preventDefault();
		// setShowErrorMessage(false);
		setShowSuccessMessage(false);

		const idpData = getUserAccessToken();
		if (!idpData || !idpData.AccessToken) {
			// The user shouldn't be here but just in case, check if they have a token.
			// TODO check this on page load
			// TODO show correct error message to the user
			// setShowErrorMessage(true);
			setErrors(prevErrors => ({
				...prevErrors,
				unknownError: true,
			}));
			console.log("User didn't have an access token, maybe SSO user?");
			console.log('idpData:', idpData);
			return;
		}

		const body = {
			old_password: values.oldPassword,
			new_password: values.newPassword,
			access_token: idpData.AccessToken,
		};
		await changePassword(body, onSuccess, onFailure);
	};

	useEffect(() => {
		const idpData = getUserAccessToken();
		if (!idpData || !idpData.AccessToken) {
			setErrors(prevErrors => ({
				...prevErrors,
				unknownError: true,
			}));
			console.log("User didn't have an access token, maybe SSO user?");
			console.log('idpData:', idpData);
		}
	}, []);

	return (
		<div>
			<h1 className="change-password__title ms-4">
				{t('changePassword.title')}
			</h1>
			{showSuccessMessage && (
				<ModalWindow
					isOpen={showSuccessMessage}
					toggle={setShowSuccessMessage}
					isButton={false}
					title={t('forgotPassword.changedSuccessfully')}
					titleStyles={'change-password__sucess-changed-pass mb-4 mt-2'}
					Icon={SuccessIcon}
				/>
			)}
			{showRateLimitError && (
				<ModalWindow
					isOpen={showRateLimitError}
					toggle={setShowRateLimitError}
					isButton={true}
					title={t('forgotPassword.changedManyTimes')}
					subtile={t('forgotPassword.tryAgain')}
					titleStyles={'change-password__modal-title'}
					subtitleStyle={'change-password__modal-subtitle'}
				/>
			)}
			<div className="m-sm-4 reset-form">
				<Form className="form" onSubmit={onSubmit}>
					<FormGroup className="pt-2">
						<Input
							password
							type="password"
							value={values.oldPassword}
							name="oldPassword"
							placeholder={t('changePassword.oldPassword')}
							onChange={onChange}
							onBlur={onBlur}
							valid={!errors.oldPassword && !!values.oldPassword}
							invalid={errors.oldPassword}
						/>
						{errors.oldPassword ? (
							<p className="text-danger text-sm">
								{t('forgotPassword.currentPassNotCorrect')}
							</p>
						) : (
							errors.unknownError && (
								<p className="text-danger text-sm">
									{t('forgotPassword.unknownError')}
								</p>
							)
						)}
					</FormGroup>
					<FormGroup className="pt-2">
						<Input
							password
							type="password"
							value={values.newPassword}
							name="newPassword"
							placeholder={t('changePassword.newPassword')}
							onChange={onChange}
							onBlur={onBlur}
							valid={!errors.newPassword && !!values.newPassword}
							invalid={errors.newPassword}
						/>
						{errors.newPassword ? (
							<p className="text-danger text-sm">
								{t('forgotPassword.diffentPasswords')}
							</p>
						) : (
							errors.unknownError && (
								<p className="text-danger text-sm">
									{t('forgotPassword.unknownError')}
								</p>
							)
						)}

						<PasswordBar value={values.newPassword} />
						<Input
							password
							type="password"
							value={values.confirmPassword}
							name="confirmPassword"
							placeholder={t('changePassword.confirmPassword')}
							onChange={onChange}
							onBlur={onBlur}
							valid={!errors.confirmPassword && !!values.confirmPassword}
							invalid={errors.confirmPassword}
						/>
					</FormGroup>
					<div className=" mt-3">
						<Button
							color="primary"
							size="sm"
							className="change-pass-btn"
							disabled={!isValidForm}>
							{t('button.saveChanges')}
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default ChangePassword;
