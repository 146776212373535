let index = 0;

const getIndex = () => index++;

export default {
	"referenceData": {
		"result": {
			"defaultValue": 2,
			"values": [{
				"value": 1,
				"label": "Compliant"
			},
			{
				"value": 2,
				"label": "Non-Compliant"
			}
			]
		},
		"severity": {
			"defaultValue": 3,
			"values": [{
				"value": 1,
				"label": "Low"
			},
			{
				"value": 2,
				"label": "Medium"
			},
			{
				"value": 3,
				"label": "High"
			},
			{
				"value": "4",
				"label": "Critical"
			}
			]
		},
		"categories": {
			"1.2": {
			 "parent": "fn-req-0",
			 "description": "1.2 : Build firewall and router configurations that restrict connections between untrusted networks and any system components in the cardholder data environment. Note",
			 "name": "",
			 "subcategories": [
				"1.2.1",
				"1.2.2",
				"1.2.3"
			 ],
			 "id": "1.2"
			},
			"1.3": {
			 "parent": "fn-req-0",
			 "description": "1.3 : Prohibit direct public access between the Internet and any system component in the cardholder data environment.",
			 "name": "",
			 "subcategories": [
				"1.3.1",
				"1.3.2",
				"1.3.3",
				"1.3.3",
				"1.3.4",
				"1.3.5",
				"1.3.6",
				"1.3.7"
			 ],
			 "id": "1.3"
			},
			"2.1": {
			 "parent": "fn-req-1",
			 "description": "2.1 : Always change vendor-supplied defaults and remove or disable unnecessary default accounts before installing a system on the network. This applies to ALL default passwords, including but not limited to those used by operating systems, software that provides security services, application and system accounts, point-of-sale (POS) terminals, payment applications, Simple Network Management Protocol (SNMP) community strings, etc.).",
			 "name": "",
			 "subcategories": [
				"2.1.1"
			 ],
			 "id": "2.1"
			},
			"2.2": {
			 "parent": "fn-req-1",
			 "description": "2.2 : Develop configuration standards for all system components. Assure that these standards address all known security vulnerabilities and are consistent with industry-accepted system hardening standards. Sources of industry-accepted system hardening standards may include, but are not limited to",
			 "name": "",
			 "subcategories": [
				"2.2.2"
			 ],
			 "id": "2.2"
			},
			"2.3": {
			 "parent": "fn-req-1",
			 "description": "2.3 : Encrypt all non-console administrative access using strong cryptography. Note",
			 "name": "",
			 "subcategories": [
				"2.3.1"
			 ],
			 "id": "2.3"
			},
			"2.4": {
			 "parent": "fn-req-1",
			 "description": "2.4 : Maintain an inventory of system components that are in scope for PCI DSS.",
			 "name": "",
			 "subcategories": [
				"2.4.1"
			 ],
			 "id": "2.4"
			},
			"3.4": {
			 "parent": "fn-req-2",
			 "description": "3.4 : Render PAN unreadable anywhere it is stored (including on portable digital media, backup media, and in logs) by using any of the following approaches",
			 "name": "",
			 "subcategories": [
				"3.4.1"
			 ],
			 "id": "3.4"
			},
			"3.6": {
			 "parent": "fn-req-2",
			 "description": "3.6 : Fully document and implement all key-management processes and procedures for cryptographic keys used for encryption of cardholder data, including the following",
			 "name": "",
			 "subcategories": [
				"3.6.4"
			 ],
			 "id": "3.6"
			},
			"4.1": {
			 "parent": "fn-req-3",
			 "description": "4.1 : Use strong cryptography and security protocols to safeguard sensitive cardholder data during transmission over open, public networks, including the following",
			 "name": "",
			 "subcategories": [
				"4.1.1"
			 ],
			 "id": "4.1"
			},
			"6.1": {
			 "parent": "fn-req-5",
			 "description": "6.1 : Establish a process to identify security vulnerabilities, using reputable outside sources for security vulnerability information, and assign a risk ranking (for example, as “high,” “medium,” or “low”) to newly discovered security vulnerabilities. Note",
			 "name": "",
			 "subcategories": [
				"6.1.1"
			 ],
			 "id": "6.1"
			},
			"6.2": {
			 "parent": "fn-req-5",
			 "description": "6.2 : Ensure that all system components and software are protected from known vulnerabilities by installing applicable vendor-supplied security patches. Install critical security patches within one month of release. Note",
			 "name": "",
			 "subcategories": [
				"6.2.1"
			 ],
			 "id": "6.2"
			},
			"6.3": {
			 "parent": "fn-req-5",
			 "description": "6.3 : Develop internal and external software applications (including web-based administrative access to applications) securely, as follows",
			 "name": "",
			 "subcategories": [
				"6.3.1"
			 ],
			 "id": "6.3"
			},
			"7.1": {
			 "parent": "fn-req-6",
			 "description": "7.1 : Limit access to system components and cardholder data to only those individuals whose job requires such access.",
			 "name": "",
			 "subcategories": [
				"7.1.1",
				"7.1.2",
				"7.1.3",
				"7.1.4"
			 ],
			 "id": "7.1"
			},
			"7.2": {
			 "parent": "fn-req-6",
			 "description": "7.2 : Establish an access control system(s) for systems components that restricts access based on a user’s need to know, and is set to “deny all” unless specifically allowed. This access control system(s) must include the following",
			 "name": "",
			 "subcategories": [
				"7.2.1",
				"7.2.2",
				"7.2.3"
			 ],
			 "id": "7.2"
			},
			"8.1": {
			 "parent": "fn-req-7",
			 "description": "8.1 : Define and implement policies and procedures to ensure proper user identification management for non-consumer users and administrators on all system components as follows",
			 "name": "",
			 "subcategories": [
				"8.1.4"
			 ],
			 "id": "8.1"
			},
			"8.2": {
			 "parent": "fn-req-7",
			 "description": "8.2 : In addition to assigning a unique ID, ensure proper user-authentication management for non-consumer users and administrators on all system components by employing at least one of the following methods to authenticate all users",
			 "name": "",
			 "subcategories": [
				"8.2.1",
				"8.2.3",
				"8.2.4",
				"8.2.5"
			 ],
			 "id": "8.2"
			},
			"8.3": {
			 "parent": "fn-req-7",
			 "description": "8.3 : Secure all individual non-console administrative access and all remote access to the CDE using multi-factor authentication.  Note",
			 "name": "",
			 "subcategories": [
				"8.3.1",
				"8.3.2"
			 ],
			 "id": "8.3"
			},
			"10.1": {
			 "parent": "fn-req-9",
			 "description": "10.1 : Implement audit trails to link all access to system components to each individual user.",
			 "name": "",
			 "subcategories": [
				"10.1.1"
			 ],
			 "id": "10.1"
			},
			"10.2": {
			 "parent": "fn-req-9",
			 "description": "10.2 : Implement automated audit trails for all system components to reconstruct the following events",
			 "name": "",
			 "subcategories": [
				"10.2.1",
				"10.2.2",
				"10.2.3",
				"10.2.4",
				"10.2.5",
				"10.2.6",
				"10.2.7"
			 ],
			 "id": "10.2"
			},
			"10.3": {
			 "parent": "fn-req-9",
			 "description": "10.3 : Record at least the following audit trail entries for all system components for each event",
			 "name": "",
			 "subcategories": [
				"10.3.1",
				"10.3.2",
				"10.3.3",
				"10.3.4",
				"10.3.5",
				"10.3.6"
			 ],
			 "id": "10.3"
			},
			"10.5": {
			 "parent": "fn-req-9",
			 "description": "10.5 : Secure audit trails so they cannot be altered.",
			 "name": "",
			 "subcategories": [
				"10.5.1",
				"10.5.2",
				"10.5.3",
				"10.5.5"
			 ],
			 "id": "10.5"
			},
			"10.6": {
			 "parent": "fn-req-9",
			 "description": "10.6 : Review logs and security events for all system components to identify anomalies or suspicious activity. Note",
			 "name": "",
			 "subcategories": [
				"10.6.1",
				"10.6.2",
				"10.6.3"
			 ],
			 "id": "10.6"
			},
			"11.4": {
			 "parent": "fn-req-10",
			 "description": "11.4 : Use intrusion-detection and/or intrusion-prevention techniques to detect and/or prevent intrusions into the network. Monitor all traffic at the perimeter of the cardholder data environment as well as at critical points in the cardholder data environment, and alert personnel to suspected compromises. Keep all intrusion-detection and prevention engines, baselines, and signatures up to date.",
			 "name": "",
			 "subcategories": [
				"11.4.1"
			 ],
			 "id": "11.4"
			},
			"12.5": {
			 "parent": "fn-req-11",
			 "description": "12.5 : Assign to an individual or team the following information security management responsibilities",
			 "name": "",
			 "subcategories": [
				"12.5.2"
			 ],
			 "id": "12.5"
			},
			"12.9": {
			 "parent": "fn-req-11",
			 "description": "12.9 : Additional requirement for service providers only",
			 "name": "",
			 "subcategories": [
				"12.9.1"
			 ],
			 "id": "12.9"
			}
		 },
		 "subcategories": {
			"1.2.1": {
			 "parent": "1.2",
			 "description": "Restrict inbound and outbound traffic to that which is necessary for the cardholder data environment, and specifically deny all other traffic.",
			 "name": "1.2.1",
			 "index": getIndex()
			},
			"1.2.2": {
			 "parent": "1.2",
			 "description": "Secure and synchronize router configuration files.",
			 "name": "1.2.2",
			 "index": getIndex()
			},
			"1.2.3": {
			 "parent": "1.2",
			 "description": "Install perimeter firewalls between all wireless networks and the cardholder data environment, and configure these firewalls to deny or, if traffic is necessary for business purposes, permit only authorized traffic between the wireless environment and the cardholder data environment.",
			 "name": "1.2.3",
			 "index": getIndex()
			},
			"1.3.1": {
			 "parent": "1.3",
			 "description": "Implement a DMZ to limit inbound traffic to only system components that provide authorized publicly accessible services, protocols, and ports.",
			 "name": "1.3.1",
			 "index": getIndex()
			},
			"1.3.2": {
			 "parent": "1.3",
			 "description": "Limit inbound Internet traffic to IP addresses within the DMZ.",
			 "name": "1.3.2",
			 "index": getIndex()
			},
			"1.3.3": {
			 "parent": "1.3",
			 "description": "Implement anti-spoofing measures to detect and block forged source IP addresses from entering the network.  (For example, block traffic originating from the Internet with an internal source address.)",
			 "name": "1.3.3",
			 "index": getIndex()
			},
			"1.3.4": {
			 "parent": "1.3",
			 "description": "Do not allow unauthorized outbound traffic from the cardholder data environment to the Internet.",
			 "name": "1.3.4",
			 "index": getIndex()
			},
			"1.3.5": {
			 "parent": "1.3",
			 "description": "Permit only “established” connections into the network.",
			 "name": "1.3.5",
			 "index": getIndex()
			},
			"1.3.6": {
			 "parent": "1.3",
			 "description": "Place system components that store cardholder data (such as a database) in an internal network zone, segregated from the DMZ and other untrusted networks.",
			 "name": "1.3.6",
			 "index": getIndex()
			},
			"1.3.7": {
			 "parent": "1.3",
			 "description": "Do not disclose private IP addresses and routing information to unauthorized parties. Note",
			 "name": "1.3.7",
			 "index": getIndex()
			},
			"2.1.1": {
			 "parent": "2.1",
			 "description": "For wireless environments connected to the cardholder data environment or transmitting cardholder data, change ALL wireless vendor defaults at installation, including but not limited to default wireless encryption keys, passwords, and SNMP community strings.",
			 "name": "2.1.1",
			 "index": getIndex()
			},
			"2.2.2": {
			 "parent": "2.2",
			 "description": "Enable only necessary services, protocols, daemons, etc., as required for the function of the system.",
			 "name": "2.2.2",
			 "index": getIndex()
			},
			"2.3.1": {
			 "parent": "2.3",
			 "description": "",
			 "name": "2.3.1",
			 "index": getIndex()
			},
			"2.4.1": {
			 "parent": "2.4",
			 "description": "",
			 "name": "2.4.1",
			 "index": getIndex()
			},
			"3.4.1": {
			 "parent": "3.4",
			 "description": "If disk encryption is used (rather than file- or column-level database encryption), logical access must be managed separately and independently of native operating system authentication and access control mechanisms (for example, by not using local user account databases or general network login credentials). Decryption keys must not be associated with user accounts. Note",
			 "name": "3.4.1",
			 "index": getIndex()
			},
			"3.6.4": {
			 "parent": "3.6",
			 "description": "Cryptographic key changes for keys that have reached the end of their cryptoperiod (for example, after a defined period of time has passed and/or after a certain amount of cipher-text has been produced by a given key), as defined by the associated application vendor or key owner, and based on industry best practices and guidelines (for example, NIST Special Publication 800-57).",
			 "name": "3.6.4",
			 "index": getIndex()
			},
			"4.1.1": {
			 "parent": "4.1",
			 "description": "Ensure wireless networks transmitting cardholder data or connected to the cardholder data environment, use industry best practices to implement strong encryption for authentication and transmission.",
			 "name": "4.1.1",
			 "index": getIndex()
			},
			"6.1.1": {
			 "parent": "6.1",
			 "description": "",
			 "name": "6.1.1",
			 "index": getIndex()
			},
			"6.2.1": {
			 "parent": "6.2",
			 "description": "",
			 "name": "6.2.1",
			 "index": getIndex()
			},
			"6.3.1": {
			 "parent": "6.3",
			 "description": "Remove development, test and/or custom application accounts, user IDs, and passwords before applications become active or are released to customers.",
			 "name": "6.3.1",
			 "index": getIndex()
			},
			"7.1.1": {
			 "parent": "7.1",
			 "description": "Define access needs for each role, including",
			 "name": "7.1.1",
			 "index": getIndex()
			},
			"7.1.2": {
			 "parent": "7.1",
			 "description": "Restrict access to privileged user IDs to least privileges necessary to perform job responsibilities.",
			 "name": "7.1.2",
			 "index": getIndex()
			},
			"7.1.3": {
			 "parent": "7.1",
			 "description": "Assign access based on individual personnel’s job classification and function.",
			 "name": "7.1.3",
			 "index": getIndex()
			},
			"7.1.4": {
			 "parent": "7.1",
			 "description": "Require documented approval by authorized parties specifying required privileges.",
			 "name": "7.1.4",
			 "index": getIndex()
			},
			"7.2.1": {
			 "parent": "7.2",
			 "description": "Coverage of all system components",
			 "name": "7.2.1",
			 "index": getIndex()
			},
			"7.2.2": {
			 "parent": "7.2",
			 "description": "Assignment of privileges to individuals based on job classification and function.",
			 "name": "7.2.2",
			 "index": getIndex()
			},
			"7.2.3": {
			 "parent": "7.2",
			 "description": "Default “deny-all” setting.",
			 "name": "7.2.3",
			 "index": getIndex()
			},
			"8.1.4": {
			 "parent": "8.1",
			 "description": "Remove/disable inactive user accounts within 90 days.",
			 "name": "8.1.4",
			 "index": getIndex()
			},
			"8.2.1": {
			 "parent": "8.2",
			 "description": "Using strong cryptography, render all authentication credentials (such as passwords/phrases) unreadable during transmission and storage on all system components.",
			 "name": "8.2.1",
			 "index": getIndex()
			},
			"8.2.3": {
			 "parent": "8.2",
			 "description": "Passwords/passphrases must meet the following",
			 "name": "8.2.3",
			 "index": getIndex()
			},
			"8.2.4": {
			 "parent": "8.2",
			 "description": "Change user passwords/passphrases at least once every 90 days.",
			 "name": "8.2.4",
			 "index": getIndex()
			},
			"8.2.5": {
			 "parent": "8.2",
			 "description": "Do not allow an individual to submit a new password/passphrase that is the same as any of the last four passwords/passphrases he or she has used.",
			 "name": "8.2.5",
			 "index": getIndex()
			},
			"8.3.1": {
			 "parent": "8.3",
			 "description": "Incorporate multi-factor authentication for all non-console access into the CDE for personnel with administrative access. Note",
			 "name": "8.3.1",
			 "index": getIndex()
			},
			"8.3.2": {
			 "parent": "8.3",
			 "description": "Incorporate multi-factor authentication for all remote network access (both user and administrator, and including third party access for support or maintenance) originating from outside the entity's network.",
			 "name": "8.3.2",
			 "index": getIndex()
			},
			"10.1.1": {
			 "parent": "10.1",
			 "description": "",
			 "name": "10.1.1",
			 "index": getIndex()
			},
			"10.2.1": {
			 "parent": "10.2",
			 "description": "All individual user accesses to cardholder data",
			 "name": "10.2.1",
			 "index": getIndex()
			},
			"10.2.2": {
			 "parent": "10.2",
			 "description": "All actions taken by any individual with root or administrative privileges",
			 "name": "10.2.2",
			 "index": getIndex()
			},
			"10.2.3": {
			 "parent": "10.2",
			 "description": "Access to all audit trails",
			 "name": "10.2.3",
			 "index": getIndex()
			},
			"10.2.4": {
			 "parent": "10.2",
			 "description": "Invalid logical access attempts",
			 "name": "10.2.4",
			 "index": getIndex()
			},
			"10.2.5": {
			 "parent": "10.2",
			 "description": "Use of and changes to identification and authentication mechanisms—including but not limited to creation of new accounts and elevation of privileges—and all changes, additions, or deletions to accounts with root or administrative privileges",
			 "name": "10.2.5",
			 "index": getIndex()
			},
			"10.2.6": {
			 "parent": "10.2",
			 "description": "Initialization, stopping, or pausing of the audit logs",
			 "name": "10.2.6",
			 "index": getIndex()
			},
			"10.2.7": {
			 "parent": "10.2",
			 "description": "Creation and deletion of system-level objects",
			 "name": "10.2.7",
			 "index": getIndex()
			},
			"10.3.1": {
			 "parent": "10.3",
			 "description": "User identification",
			 "name": "10.3.1",
			 "index": getIndex()
			},
			"10.3.2": {
			 "parent": "10.3",
			 "description": "Type of event",
			 "name": "10.3.2",
			 "index": getIndex()
			},
			"10.3.3": {
			 "parent": "10.3",
			 "description": "Date and time",
			 "name": "10.3.3",
			 "index": getIndex()
			},
			"10.3.4": {
			 "parent": "10.3",
			 "description": "Success or failure indication",
			 "name": "10.3.4",
			 "index": getIndex()
			},
			"10.3.5": {
			 "parent": "10.3",
			 "description": "Origination of event",
			 "name": "10.3.5",
			 "index": getIndex()
			},
			"10.3.6": {
			 "parent": "10.3",
			 "description": "Identity or name of affected data, system component, or resource.",
			 "name": "10.3.6",
			 "index": getIndex()
			},
			"10.5.1": {
			 "parent": "10.5",
			 "description": "Limit viewing of audit trails to those with a job-related need.",
			 "name": "10.5.1",
			 "index": getIndex()
			},
			"10.5.2": {
			 "parent": "10.5",
			 "description": "Protect audit trail files from unauthorized modifications.",
			 "name": "10.5.2",
			 "index": getIndex()
			},
			"10.5.3": {
			 "parent": "10.5",
			 "description": "Promptly back up audit trail files to a centralized log server or media that is difficult to alter.",
			 "name": "10.5.3",
			 "index": getIndex()
			},
			"10.5.5": {
			 "parent": "10.5",
			 "description": "Use file-integrity monitoring or change-detection software on logs to ensure that existing log data cannot be changed without generating alerts (although new data being added should not cause an alert).",
			 "name": "10.5.5",
			 "index": getIndex()
			},
			"10.6.1": {
			 "parent": "10.6",
			 "description": "Review the following at least daily",
			 "name": "10.6.1",
			 "index": getIndex()
			},
			"10.6.2": {
			 "parent": "10.6",
			 "description": "Review logs of all other system components periodically based on the organization’s policies and risk management strategy, as determined by the organization’s annual risk assessment.",
			 "name": "10.6.2",
			 "index": getIndex()
			},
			"10.6.3": {
			 "parent": "10.6",
			 "description": "Follow up exceptions and anomalies identified during the review process.",
			 "name": "10.6.3",
			 "index": getIndex()
			},
			"11.4.1": {
			 "parent": "11.4",
			 "description": "",
			 "name": "11.4.1",
			 "index": getIndex()
			},
			"12.5.2": {
			 "parent": "12.5",
			 "description": "Monitor and analyze security alerts and information, and distribute to appropriate personnel.",
			 "name": "12.5.2",
			 "index": getIndex()
			},
			"12.9.1": {
			 "parent": "12.9",
			 "description": "",
			 "name": "12.9.1",
			 "index": getIndex()
			}
		 }
	},
	"headers": [{
		"id": 1,
		"value": "Requirement",
		"classList": "dont-print col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 2,
		"value": "Sub-Requirement",
		"classList": "col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 3,
		"value": "Test Case",
		"classList": "print-sub-cat-col col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 4,
		"value": "Result",
		"classList": "print-result-col col col-md-2 text-md-center",
		"style": {
			"marginLeft": "-1rem"
		}
	},
	{
		"id": 5,
		"value": "Severity",
		"classList": "col col-md-1 text-md-left",
		"style": {
			"marginLeft": "3rem"
		}
	},
	{
		"id": 6,
		"value": "Evidence",
		"classList": "col col-md-1 text-md-center",
		"style": {
			"marginLeft": "6rem"
		}
	}
	],
	"testCases": [
		{
		 "id": "fn-0",
		 "value": "SECURE NETWORK",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			""
		 ],
		 "children": [
			"fn-req-0",
			"fn-req-1"
		 ]
		},
		{
		 "id": "fn-1",
		 "value": "SECURE CARDHOLDER DATA",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			""
		 ],
		 "children": [
			"fn-req-2",
			"fn-req-3"
		 ]
		},
		{
		 "id": "fn-2",
		 "value": "VULNERABILITY MANAGEMENT",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			""
		 ],
		 "children": [
			"fn-req-4",
			"fn-req-5"
		 ]
		},
		{
		 "id": "fn-3",
		 "value": "ACCESS CONTROL",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			""
		 ],
		 "children": [
			"fn-req-6",
			"fn-req-7",
			"fn-req-8"
		 ]
		},
		{
		 "id": "fn-4",
		 "value": "NETWORK MONITORING & TESTING",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			""
		 ],
		 "children": [
			"fn-req-9",
			"fn-req-10"
		 ]
		},
		{
		 "id": "fn-5",
		 "value": "INFORMATION SECURITY",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			""
		 ],
		 "children": [
			"fn-req-11"
		 ]
		},
		{
		 "id": "fn-6",
		 "value": "APPENDIX A1",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			""
		 ],
		 "children": [
			"fn-req-12",
			"fn-req-13"
		 ]
		}
	 ],
	 "childTestCases": {
		"fn-req-0": {
		 "parent": "fn-0",
		 "value": "Requirement 1",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 1 - Install and maintain a firewall configuration to protect cardholder data"
		 ],
		 "categories": [
			"1.2",
			"1.3"
		 ],
		 "id": "fn-req-0"
		},
		"fn-req-1": {
		 "parent": "fn-0",
		 "value": "Requirement 2",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 2 - Do not use vendor-supplied defaults for system passwords and other security parameters"
		 ],
		 "categories": [
			"2.1",
			"2.2",
			"2.3",
			"2.4"
		 ],
		 "id": "fn-req-1"
		},
		"fn-req-2": {
		 "parent": "fn-1",
		 "value": "Requirement 3",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 3 - Protect stored cardholder data"
		 ],
		 "categories": [
			"3.4",
			"3.6"
		 ],
		 "id": "fn-req-2"
		},
		"fn-req-3": {
		 "parent": "fn-1",
		 "value": "Requirement 4",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 4 - Encrypt transmission of cardholder data across open, public networks"
		 ],
		 "categories": [
			"4.1"
		 ],
		 "id": "fn-req-3"
		},
		"fn-req-4": {
		 "parent": "fn-2",
		 "value": "Requirement 5",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			"Requirement 5 - Use and regularly update anti-virus software or programs"
		 ],
		 "categories": [],
		 "id": "fn-req-4"
		},
		"fn-req-5": {
		 "parent": "fn-2",
		 "value": "Requirement 6",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 6 - Develop and maintain secure systems and applications"
		 ],
		 "categories": [
			"6.1",
			"6.2",
			"6.3"
		 ],
		 "id": "fn-req-5"
		},
		"fn-req-6": {
		 "parent": "fn-3",
		 "value": "Requirement 7",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 7 - Restrict access to cardholder data by business need to know"
		 ],
		 "categories": [
			"7.1",
			"7.2"
		 ],
		 "id": "fn-req-6"
		},
		"fn-req-7": {
		 "parent": "fn-3",
		 "value": "Requirement 8",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			"Requirement 8 - Assign a unique ID to each person with computer access"
		 ],
		 "categories": [
			"8.1",
			"8.2",
			"8.3"
		 ],
		 "id": "fn-req-7"
		},
		"fn-req-8": {
		 "parent": "fn-3",
		 "value": "Requirement 9",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			"Requirement 9 - Restrict physical access to cardholder data"
		 ],
		 "categories": [],
		 "id": "fn-req-8"
		},
		"fn-req-9": {
		 "parent": "fn-4",
		 "value": "Requirement 10",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 10 - Track and monitor all access to network resources and cardholder data"
		 ],
		 "categories": [
			"10.1",
			"10.2",
			"10.3",
			"10.5",
			"10.6"
		 ],
		 "id": "fn-req-9"
		},
		"fn-req-10": {
		 "parent": "fn-4",
		 "value": "Requirement 11",
		 "fgColor": "#e9ecef",
		 "bgColor": "#4da6ff",
		 "description": [
			"Requirement 11 - Regularly test security systems and processes"
		 ],
		 "categories": [
			"11.4"
		 ],
		 "id": "fn-req-10"
		},
		"fn-req-11": {
		 "parent": "fn-5",
		 "value": "Requirement 12",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			"Requirement 12 - Maintain a policy that addresses information security for all personnel"
		 ],
		 "categories": [
			"12.5",
			"12.9"
		 ],
		 "id": "fn-req-11"
		},
		"fn-req-12": {
		 "parent": "fn-6",
		 "value": "Appendix A1",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			"Appendix A1 - Additional PCI DSS Requirements for Shared Hosting Providers"
		 ],
		 "categories": [],
		 "id": "fn-req-12"
		},
		"fn-req-13": {
		 "parent": "fn-6",
		 "value": "Appendix A2",
		 "fgColor": "#e9ecef",
		 "bgColor": "#a31aff",
		 "description": [
			"Appendix A2 - Additional PCI DSS Requirements for Entities using SSL/early TLS. Note"
		 ],
		 "categories": [],
		 "id": "fn-req-13"
		}
	 }
};