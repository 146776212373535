import React, { useState } from 'react';
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Row,
	Col,
} from 'reactstrap';
import '../../styles.css';
import { useTranslation } from 'react-i18next';
import icon1 from './../../../../../assets/img/1.svg';
import icon2 from './../../../../../assets/img/2.svg';
import icon3 from './../../../../../assets/img/3.svg';
import icon4 from './../../../../../assets/img/4.svg';
import icon5 from './../../../../../assets/img/5.svg';
import icon6 from './../../../../../assets/img/6.svg';

import iconA from './../../../../../assets/img/a.svg';
import iconB from './../../../../../assets/img/b.svg';

import iconI from './../../../../../assets/img/Info Icon.svg';

function QuickStartAws() {
	const [activeTab, setActiveTab] = useState('1');
	const { t } = useTranslation();

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	return (
		<>
			<Nav tabs>
				<NavItem>
					<NavLink
						className={activeTab === '1' ? 'activeLink font-weight-bold' : ''}
						onClick={() => {
							toggle('1');
						}}
						style={{ color: activeTab === '1' ? '#1E69E0' : 'black' }}>
						{t('quickStart.aws.intro.title')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={activeTab === '2' ? 'activeLink  font-weight-bold' : ''}
						onClick={() => {
							toggle('2');
						}}
						style={{ color: activeTab === '2' ? '#1E69E0' : 'black' }}>
						{t('quickStart.aws.setupBasics.title')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={activeTab === '3' ? 'activeLink font-weight-bold' : ''}
						onClick={() => {
							toggle('3');
						}}
						style={{ color: activeTab === '3' ? '#1E69E0' : 'black' }}>
						{t('quickStart.aws.setupAws.title')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						className={activeTab === '4' ? 'activeLink font-weight-bold' : ''}
						onClick={() => {
							toggle('4');
						}}
						style={{ color: activeTab === '4' ? '#1E69E0' : 'black' }}>
						{t('quickStart.aws.setupTala.title')}
					</NavLink>
				</NavItem>
			</Nav>
			<TabContent activeTab={activeTab}>
				<TabPane tabId="1">
					<Col
						sm="12"
						className="d-flex justify-content-space-between align-items-center"
						style={{ padding: '20px', gap: '64px' }}>
						<div className="main-text">
							<h4>{t('quickStart.aws.intro.heading')}</h4>
							<p>{t('quickStart.aws.intro.description')}</p>
						</div>
						<div className="items-text">
							<div className="d-flex p-2">
								<img src={icon1} alt="icon1" className="img" />
								{t('quickStart.aws.intro.noInstall')}
							</div>

							<div className="d-flex p-2">
								<img src={icon2} alt="icon2" className="img" />
								{t('quickStart.aws.intro.customerData')}
							</div>
							<div className="d-flex p-2">
								<img src={icon3} alt="icon3" className="img" />
								{t('quickStart.aws.intro.AWSrecording')}
							</div>
						</div>
						<div className="items-text">
							<div className="d-flex p-2">
								<img src={icon4} alt="icon4" className="img" />
								{t('quickStart.aws.intro.AWSConfigPack')}
							</div>

							<div className="d-flex p-2">
								<img src={icon5} alt="icon5" className="img" />
								{t('quickStart.aws.intro.S3buckets')}
							</div>
							<div className="d-flex p-2">
								<img src={icon6} alt="icon6" className="img" />
								{t('quickStart.aws.intro.alwaysCurrent')}
							</div>
						</div>
					</Col>
				</TabPane>
				<TabPane tabId="2">
					<Row>
						<Col
							sm="12"
							className="d-flex justify-content-space-between "
							style={{ padding: '30px', gap: '64px' }}>
							<div className="main-text">
								<h4>{t('quickStart.aws.setupBasics.heading')}</h4>
								<p>
									{t('quickStart.aws.setupBasics.description')}
									<a
										href={t(
											'quickStart.aws.setupBasics.detailedSetupGuideLink'
										)}
										target="_blank"
										rel="noopener noreferrer">
										<u>{t('quickStart.aws.setupBasics.detailedSetupGuide')}</u>
									</a>{' '}
								</p>
							</div>
							<div className="items-text">
								<div className="d-flex p-2">
									<img src={icon1} alt="icon1" className="img" />
									{t('quickStart.aws.setupBasics.awsLogin')}
								</div>

								<div className="d-flex p-2">
									<img src={icon2} alt="icon2" className="img" />
									{t('quickStart.aws.setupBasics.createPolicy')}
								</div>
								<div className="d-flex p-2">
									<img src={icon3} alt="icon3" className="img" />
									{t('quickStart.aws.setupBasics.createGroup')}
								</div>
								<div className="subItems">
									<div className="d-flex p-2">
										<img src={iconA} alt="iconA" className="img" />
										{t('quickStart.aws.setupBasics.groupPolicies')}
									</div>
									<div className="d-flex p-2">
										<img src={iconB} alt="iconB" className="img" />
										{t('quickStart.aws.setupBasics.groupPolicyRemoval')}
									</div>
								</div>
							</div>
							<div className="items-text">
								<div className="d-flex p-2">
									<img src={icon4} alt="icon4" className="img" />
									{t('quickStart.aws.setupBasics.createUser')}
								</div>

								<div className="d-flex p-2">
									<img src={icon5} alt="icon5" className="img" />
									{t('quickStart.aws.setupBasics.createAccessKey')}
								</div>
								<div className="d-flex p-2">
									<img src={icon6} alt="icon6" className="img" />
									{t('quickStart.aws.setupBasics.addKeyToTala')}
								</div>
							</div>
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="3">
					<Row>
						<Col
							sm="12"
							className="d-flex justify-content-space-between "
							style={{ padding: '30px', gap: '64px' }}>
							<div className="main-text">
								<h4>{t('quickStart.aws.setupAws.heading')}</h4>
								<p>
									{t('quickStart.aws.setupAws.description')}
									<a
										href={t('quickStart.aws.setupAws.awsConsoleLink')}
										target="_blank"
										rel="noopener noreferrer">
										<u>{t('quickStart.aws.setupAws.awsConsoleText')}</u>
									</a>{' '}
								</p>
							</div>
							<div className="items-text">
								<div className="d-flex p-2">
									<img src={icon1} alt="icon1" className="img" />
									{t('quickStart.aws.setupAws.navigateIam')}
								</div>

								<div className="d-flex p-2">
									<img src={icon2} alt="icon2" className="img" />
									{t('quickStart.aws.setupAws.createPolicy')}
								</div>
								<div className="subItems">
									<div className="d-flex p-2">
										<img src={iconI} alt="iconI" className="img" />
										<a
											href={t('quickStart.aws.setupAws.policyLink')}
											target="_blank"
											rel="noopener noreferrer">
											<u>{t('quickStart.aws.setupAws.policyText')}</u>
										</a>{' '}
									</div>
								</div>
								<div className="d-flex p-2">
									<img src={icon3} alt="icon3" className="img" />
									{t('quickStart.aws.setupAws.createGroup')}
								</div>
							</div>
							<div className="items-text">
								<div className="d-flex p-2">
									<img src={icon4} alt="icon4" className="img" />
									{t('quickStart.aws.setupAws.createUser')}
								</div>

								<div className="d-flex p-2">
									<img src={icon5} alt="icon5" className="img" />
									{t('quickStart.aws.setupAws.addUserToGroup')}
								</div>
								<div className="d-flex p-2">
									<img src={icon6} alt="icon6" className="img" />
									{t('quickStart.aws.setupAws.createAccessKey')}
								</div>
							</div>
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="4">
					<Row>
						<Col
							sm="12"
							className="d-flex justify-content-space-between "
							style={{ padding: '30px', gap: '64px' }}>
							<div className="main-text">
								<h4>{t('quickStart.aws.setupTala.heading')}</h4>
								<p>{t('quickStart.aws.setupTala.description')}</p>
							</div>
							<div className="items-text">
								<div className="d-flex p-2">
									<img src={icon1} alt="icon1" className="img" />
									{t('quickStart.aws.setupTala.addAccount')}
								</div>

								<div className="d-flex p-2">
									<img src={icon2} alt="icon2" className="img" />
									{t('quickStart.aws.setupTala.systemAndAlias')}
								</div>
								<div className="d-flex p-2">
									<img src={icon3} alt="icon3" className="img" />
									{t('quickStart.aws.setupTala.accessKeys')}
								</div>
							</div>
							<div className="items-text">
								<div className="d-flex p-2">
									<img src={icon4} alt="icon4" className="img" />
									{t('quickStart.aws.setupTala.region')}
								</div>
								<div className="subItems">
									<div className="d-flex p-2">
										<img src={iconI} alt="iconI" className="img" />
										{t('quickStart.aws.setupTala.regionNote')}
									</div>
								</div>
								<div className="d-flex p-2">
									<img src={icon5} alt="icon5" className="img" />
									{t('quickStart.aws.setupTala.validate')}
								</div>
								<div className="d-flex p-2">
									<img src={icon6} alt="icon6" className="img" />
									{t('quickStart.aws.setupTala.add')}
								</div>
							</div>
						</Col>
					</Row>
				</TabPane>
			</TabContent>
		</>
	);
}

export default QuickStartAws;
