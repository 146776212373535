import React from "react";

const getLink = link => link === "#" ? window.location.protocol + "//" + window.location.host : link;

export default function ({ data }) {

	if (!data) {
		return <></>;
	}

	return <>
		<a
			href={getLink(data.link)}
			className="btn btn-primary btn-sm"
			title={data.description}
			target="_blank"
			rel="noopener noreferrer">
			<span>{data.title}</span>
		</a>
	</>;
}