import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const ReportAbout = () => {
	return (
		<>
			<Card className="page-break d-flex">
				<CardHeader>
					<CardTitle>
						<h5>{t('report.aboutTitle')}</h5>
					</CardTitle>
				</CardHeader>
				<CardBody>
					<div>
						<p>{t('report.listHeader')}</p>
						<ul>
							<li>{t('report.listItem1')}</li>
							<li>{t('report.listItem2')}</li>
							<li>{t('report.listItem3')}</li>
						</ul>
					</div>
					<br></br>
					<div>
						<b>{t('report.assessmentStep1Title')}</b>
						<p>{t('report.assessmentStep1Description')}</p>
					</div>
					<div>
						<b>{t('report.assessmentStep2Title')}</b>
						<p>{t('report.assessmentStep2Description')}</p>
						<p>{t('report.assessmentStep2Express')}</p>
						<p></p>
					</div>
					<div>
						<b>{t('report.assessmentStep3Title')}</b>
						<p>{t('report.assessmentStep3Description')}</p>
					</div>
					<div>
						<b>{t('report.assessmentStep4Title')}</b>
						<p>{t('report.assessmentStep4Description')}</p>
					</div>
				</CardBody>
			</Card>
		</>
	);
};

export default ReportAbout;
