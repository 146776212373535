import React from 'react';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';

const Avatar = ({ image, size = 'md', color = 'blue' }) => {
	const className = classNames('user-avatar', {
		'user-avatar_large': size === 'lg',
		'user-avatar_gray': color === 'gray',
	});

	return image ? (
		<img src={image} alt="avatar" className={className} />
	) : (
		<FontAwesomeIcon
			icon={faUserCircle}
			className={'align-middle ' + className}
		/>
	);
};

export default Avatar;
