import { Col, Container, Row } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const Soc2Introduction = () => {
	return (
		<div>
			<div>
				<p className="font-weight-normal">{t('testReports.soc2Title')}</p>
			</div>

			<div>
				<h4>{t('testReports.soc2SecurityTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.soc2SecurityDescription')}
				</p>
				<p className="font-weight-normal">
					{t('testReports.soc2SecurityRefer')}
				</p>
				<Container fluid className="font-weight-normal">
					<Row className="m-1">
						<Col xs="1">i.</Col>
						<Col xs="11">{t('testReports.soc2SecurityRefer1')}</Col>
					</Row>
					<Row className="m-1  mt-2">
						<Col xs="1">ii.</Col>
						<Col xs="11">{t('testReports.soc2SecurityRefer2')}</Col>
					</Row>
				</Container>
			</div>

			<div>
				<h4>{t('testReports.soc2availabilityTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.soc2availabilityDescription1')}
				</p>

				<p className="font-weight-normal">
					{t('testReports.soc2availabilityDescription2')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.soc2ProcessingIntegrity')} </h4>
				<p className="font-weight-normal">
					{t('testReports.soc2ProcessingDescription1')}
				</p>

				<p className="font-weight-normal">
					{t('testReports.soc2ProcessingDescription2')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.soc2ConfidentialityTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.soc2ConfidentialityDescription1')}
				</p>

				<p className="font-weight-normal">
					{t('testReports.soc2ConfidentialityDescription2')}
				</p>

				<p className="font-weight-normal">
					{' '}
					{t('testReports.soc2ConfidentialityDescription3')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.soc2PrivacyTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.soc2PrivacyDescription')}
				</p>
			</div>
		</div>
	);
};

export default Soc2Introduction;
