import classNames from 'classnames';
import React from 'react';

const RadioButton = ({ onChange, id, isSelected, label, value, variant }) => {
	const markClassnames = classNames('radio-button__mark', {
		'radio-button__mark_checked': isSelected,
		'radio-button__mark_dark': variant === 'dark',
	});

	return (
		<label className="radio-button">
			<span className={markClassnames} />
			<input
				id={id}
				onChange={onChange}
				value={value}
				type="radio"
				checked={isSelected}
				hidden
			/>
			{label && <span>{label}</span>}
		</label>
	);
};

export default RadioButton;
