import azureSubnetImage from "../../assets/img/azure-icons/subnet.png";
import blobServicesContainersImage from "../../assets/img/azure-icons/blobServicesContainers.png"
import databasesImage from "../../assets/img/azure-icons/databases.png";
import disksImage from "../../assets/img/azure-icons/disks.png";
import fileServicesSharesImage from "../../assets/img/azure-icons/fileServicesShares.png"
import loadBalancersImage from "../../assets/img/azure-icons/loadBalancers.png";
import locationImage from "../../assets/img/azure-icons/Location.png";
import managedClustersImage from "../../assets/img/azure-icons/managedClusters.png"
import mySqlServersImage from "../../assets/img/azure-icons/mySqlServers.png"
import networkInterfacesImage from "../../assets/img/azure-icons/networkInterfaces.png";
import networkSecurityGroupsImage from "../../assets/img/azure-icons/networkSecurityGroups.png";
import networkWatchersImage from "../../assets/img/azure-icons/networkWatchers.png";
import profileEndpointsImage from "../../assets/img/azure-icons/profileEndpoints.png"
import profilesImage from "../../assets/img/azure-icons/profiles.png"
import publicIPAddressesImage from "../../assets/img/azure-icons/publicIPAddresses.png";
import redisCacheImage from "../../assets/img/azure-icons/redisCache.png"
import registriesImage from "../../assets/img/azure-icons/registries.png";
import resourceGroupImage from "../../assets/img/azure-icons/resourceGroup.png";
import routeTablesImage from "../../assets/img/azure-icons/routeTables.png";
import sqlServersImage from "../../assets/img/azure-icons/sqlServers.png";
import sshPublicKeysImage from "../../assets/img/azure-icons/sshPublicKeys.png";
import storageAccountsImage from "../../assets/img/azure-icons/storageAccounts.png";
import subscriptionImage from "../../assets/img/azure-icons/Subscription.png";
import tenantImage from "../../assets/img/azure-icons/Tenant.png";
import userAssignedIdentitiesImage from "../../assets/img/azure-icons/userAssignedIdentities.png";
import virtualMachineScaleSetsImage from "../../assets/img/azure-icons/virtualMachineScaleSets.png";
import virtualMachinesExtensionsImage from "../../assets/img/azure-icons/virtualMachinesExtensions.png";
import virtualMachinesImage from "../../assets/img/azure-icons/virtualMachines.png";
import virtualNetworksImage from "../../assets/img/azure-icons/virtualNetworks.png";

const defaultDim = {
	height: '48px',
	width: '48px',
}

const defaultColour = 'rgba(255, 255, 255, 0.4)';

const location = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${locationImage}`
}

const storageAccounts = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${storageAccountsImage}`
}

const tenant = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${tenantImage}`
}

const subscription = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${subscriptionImage}`
}

const virtualNetworks = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${virtualNetworksImage}`
}

const virtualMachines = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${virtualMachinesImage}`
};

const sqlServers = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${sqlServersImage}`
};

const resourceGroup = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${resourceGroupImage}`
}

const azureSubnet = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${azureSubnetImage}`
}

const managedClusters = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${managedClustersImage}`
}

const profileEndpoints = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${profileEndpointsImage}`
}

const profiles = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${profilesImage}`
}

const fileServicesShares = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${fileServicesSharesImage}`
}

const redisCache = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${redisCacheImage}`
}

const blobServicesContainers = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${blobServicesContainersImage}`
}

const mySqlServers = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${mySqlServersImage}`
}

const disks = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${disksImage}`
}

const virtualMachinesExtensions = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${virtualMachinesExtensionsImage}`
}

const networkSecurityGroups = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${networkSecurityGroupsImage}`
}

const publicIPAddresses = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${publicIPAddressesImage}`
}

const networkWatchers = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${networkWatchersImage}`
}

const networkInterfaces = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${networkInterfacesImage}`
}

const databases = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${databasesImage}`
}

const sshPublicKeys = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${sshPublicKeysImage}`
}

const registries = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${registriesImage}`
}

const loadBalancers = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${loadBalancersImage}`
}

const userAssignedIdentities = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${userAssignedIdentitiesImage}`
}

const routeTables = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${routeTablesImage}`
}

const virtualMachineScaleSets = {
	shape: 'round-rectangle', dim: defaultDim, colour: defaultColour, image: `${virtualMachineScaleSetsImage}`
}

const azureConfig = {
	"Location": {
		label: 'Location',
		image: location,
		color: '#ba55d3',
	},
	"Microsoft.Storage/storageAccounts": {
		label: 'Storage Accounts',
		image: storageAccounts,
		color: '#5f9ea0',
	},
	"Tenant": {
		label: 'Tenant',
		image: tenant,
		color: '#4b0082',
	},
	"Subscription": {
		label: 'Subscription',
		image: subscription,
		color: '#ffbf00',
	},
	"Microsoft.Network/virtualNetworks": {
		label: 'Virtual Networks',
		image: virtualNetworks,
		color: '#00bfff'
	},
	"Microsoft.Compute/virtualMachines": {
		label: 'Virtual Machines',
		image: virtualMachines,
		color: '#4682b4'
	},
	"Microsoft.Sql/servers": {
		label: 'Sql Servers',
		image: sqlServers,
		color: '#0000cd'
	},
	"ResourceGroup": {
		label: 'Resource Group',
		image: resourceGroup,
		color: '#00ffff'
	},
	"subnet": {
		label: 'Subnet',
		image: azureSubnet,
		color: '#008000',
	},
	"Microsoft.ContainerService/managedClusters": {
		label: 'Managed Clusters',
		image: managedClusters,
		color: '#ff4500',
	},
	"microsoft.cdn/profiles/endpoints": {
		label: 'Profile Endpoints',
		image: profileEndpoints,
		color: '#9370db',
	},
	"microsoft.cdn/profiles": {
		label: 'Profiles',
		image: profiles,
		color: '#4169e1',
	},
	"Microsoft.Storage/storageAccounts/fileServices/shares": {
		label: 'FileServices Shares',
		image: fileServicesShares,
		color: '#0000cd',
	},
	"Microsoft.Cache/Redis": {
		label: 'Redis Cache',
		image: redisCache,
		color: '#00008b',
	},
	"Microsoft.Storage/storageAccounts/blobServices/containers": {
		label: 'BlobServices Containers',
		image: blobServicesContainers,
		color: '#00bfff',
	},
	"Microsoft.DBforMySQL/servers": {
		label: 'MySQL Servers',
		image: mySqlServers,
		color: '#0000ff',
	},
	"Microsoft.Compute/disks": {
		label: 'Disks',
		image: disks,
		color: '#50c878',
	},
	"Microsoft.Compute/virtualMachines/extensions": {
		label: 'Virtual Machines Extensions',
		image: virtualMachinesExtensions,
		color: '#00ffff',
	},
	"Microsoft.Network/networkSecurityGroups": {
		label: 'Network Security Groups',
		image: networkSecurityGroups,
		color: '#7dF9ff',
	},
	"Microsoft.Network/publicIPAddresses": {
		label: 'Public IP Addresses',
		image: publicIPAddresses,
		color: '#7fffD4',
	},
	"Microsoft.Network/networkWatchers": {
		label: 'Network Watchers',
		image: networkWatchers,
		color: '#008080',
	},
	"Microsoft.Network/networkInterfaces": {
		label: 'Network Interfaces',
		image: networkInterfaces,
		color: '#4cbb17',
	},
	"Microsoft.Sql/servers/databases": {
		label: 'Databases',
		image: databases,
		color: '#335bff',
	},
	"Microsoft.Compute/sshPublicKeys": {
		label: 'SSH Public Keys',
		image: sshPublicKeys,
		color: '#33e6ff',
	},
	"Microsoft.ContainerRegistry/registries": {
		label: 'Registries',
		image: registries,
		color: '#485658',
	},
	"Microsoft.Network/loadBalancers": {
		label: 'Load Balancers',
		image: loadBalancers,
		color: '#0ef311',
	},
	"Microsoft.ManagedIdentity/userAssignedIdentities": {
		label: 'User Assigned Identities',
		image: userAssignedIdentities,
		color: '#70fff6',
	},
	"Microsoft.Network/routeTables": {
		label: 'Route Tables',
		image: routeTables,
		color: '#2ec2b9',
	},
	"Microsoft.Compute/virtualMachineScaleSets": {
		label: 'Virtual Machine ScaleSets',
		image: virtualMachineScaleSets,
		color: '#1a0dd9',
	}
};

export default azureConfig;