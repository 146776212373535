import React from 'react';
import { Trans } from 'react-i18next';
const key = 'testReports.testMetaInfo.nist.';

export default [
	{
		title: <Trans i18nKey={key + 'identify.title'} />,
		titleBackground: '#66d9ff',
		text: [
			<Trans i18nKey={key + 'identify.text1'} />,
			<Trans i18nKey={key + 'identify.text2'} />,
			<Trans i18nKey={key + 'identify.text3'} />,
			<Trans i18nKey={key + 'identify.text4'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'protect.title'} />,
		titleBackground: ' #00ace6',
		text: [
			<Trans i18nKey={key + 'protect.text1'} />,
			<Trans i18nKey={key + 'protect.text2'} />,
			<Trans i18nKey={key + 'protect.text3'} />,
			<Trans i18nKey={key + 'protect.text4'} />,
			<Trans i18nKey={key + 'protect.text5'} />,
			<Trans i18nKey={key + 'protect.text6'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'detect.title'} />,
		titleBackground: '#ff6633',
		text: [
			<Trans i18nKey={key + 'detect.text1'} />,
			<Trans i18nKey={key + 'detect.text2'} />,
			<Trans i18nKey={key + 'detect.text3'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'respond.title'} />,
		titleBackground: '#ff8000',
		text: [
			<Trans i18nKey={key + 'respond.text1'} />,
			<Trans i18nKey={key + 'respond.text2'} />,
			<Trans i18nKey={key + 'respond.text3'} />,
			<Trans i18nKey={key + 'respond.text4'} />,
			<Trans i18nKey={key + 'respond.text5'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'recover.title'} />,
		titleBackground: '#66cc00',
		text: [
			<Trans i18nKey={key + 'recover.text1'} />,
			<Trans i18nKey={key + 'recover.text2'} />,
			<Trans i18nKey={key + 'recover.text3'} />,
		],
	},
];
