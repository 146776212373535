import { t } from 'i18next';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import { PackNames } from '../../../constants/PackKeys';
import React from 'react';

export default function ({ report }) {
	return (
		<Header>
			<HeaderTitle>
				{PackNames[report.PackName]} - {t('tests.automatedCloudResults')}
			</HeaderTitle>
		</Header>
	);
}
