import { Links, Stages, Workflow } from '../Common/AssessmentStage';
import { useEffect, useState } from 'react';

import { clearItemsWithKeyStartingWith } from '../../../services/browserStorageService';
import { getReportName } from './Helpers';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

let navigate;

const parse = {
	info: Stages.Info,
	demographicassessment: Stages.DemographicAssessment,
	reviewscan: Stages.ReviewScan,
	analysing: Stages.Analysing,
	reviewscore: Stages.ReviewScore,
};

export default urlStage => {
	navigate = useNavigate();
	const location = useLocation()
	const [stage, setStage] = useState(parse[urlStage.toLowerCase()]);
	const [reportName, setReportName] = useState(getReportName());
	const [exclusion, setExclusion] = useState('None');
	const [assessmentMode, setAssessmentMode] = useState(0);

	const onEvalCompleted = evalReportId => {
		setReportName(evalReportId);
	};

	const safeSetReportName = reportName => {
		if (!reportName || reportName.length < 1) {
			setReportName(getReportName());
		} else {
			setReportName(reportName);
		}
	};

	useEffect(() => {
		const current = parse[urlStage.toLowerCase()];
		const link = Links[current];
		setStage(current);
		// pass search params only on first stage
		const searchParams = (Stages.Info === current) ? location.search : '';
		navigate(link + searchParams);
	}, [urlStage, location.search]);

	useEffect(() => {
		setReportName(getReportName());
		return () => {
			clearItemsWithKeyStartingWith('ransomware_Report_');
		};
	}, []);

	const nextStage = option => {
		const current = Workflow[stage];
		if (option !== undefined && current.children) {
			const stageNext = current.children[option];
			setStage(stageNext.next);
			navigate(Links[stageNext.next]);
		} else if (current.next) {
			setStage(current.next);
			navigate(Links[current.next]);
		}
	};

	return {
		stage,
		reportName,
		setReportName: safeSetReportName,
		nextStage,
		onEvalCompleted,
		exclusion,
		assessmentMode,
		setExclusion,
		setAssessmentMode,
	};
};
