import {
	Button,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Spinner,
} from 'reactstrap';
import { PotentialLoss, RiskScore } from '../../../Controls/Risk';

import Header from '../../../../../components/Header';
import HeaderTitle from '../../../../../components/HeaderTitle';
import Issues from './Issues';
import { Link } from 'react-router-dom';
import RansomwareTopology from '../../../Controls/RansomwareTopology';
import React from 'react';
import { Stages } from '../../../Common/AssessmentStage';
import Vulnerabilities from './Vulnerabilities';
import useRender from '../../../Hooks/useRender';
import useRiskResult from '../../../Hooks/useRiskResult';
import { useTranslation } from 'react-i18next';

const ReviewScore = ({ stage, onNext, reportName }) => {
	const render = useRender(stage, Stages.ReviewScore);
	const { t } = useTranslation();
	const result = useRiskResult(reportName, render);

	const centeredContent =
		'flex-column  align-items-center d-flex justify-content-center m-5';

	let showChangeBubbles = true;

	return (
		render && (
			<Container>
				<Header>
					<HeaderTitle>{t('assessmentResults.title')}</HeaderTitle>
				</Header>
				{result.error && (
					<Card>
						<CardBody>{t('assessment.noResults')}</CardBody>
					</Card>
				)}
				{result.loading && (
					<Card>
						<CardBody>
							<div className={centeredContent}>
								<h4>{t('reviewScan.loadingDetails')}</h4>
								<Spinner color="primary" />
							</div>
						</CardBody>
					</Card>
				)}
				{!result.loading && !result.error && (
					<>
						<Row className="mb-lg-60 mb-4 w-100 gap-4 px-sm">
							<Col className="p-0">
								<RiskScore
									score={result.currentScore}
									previous={result.previousScore}
									showChangeBubbles={showChangeBubbles}
								/>
							</Col>
							<Col className="p-0">
								<PotentialLoss
									value={result.loss}
									previous={result.previousLoss}
									showChangeBubbles={showChangeBubbles}
								/>
							</Col>
							<Col className="p-0">
								<Issues
									color="danger"
									title={t('ransomwareDashboard.critical')}
									system={result.critical.resource}
									issues={result.critical.issues}
								/>
							</Col>
							<Col className="p-0">
								<Issues
									color="warning"
									title={t('ransomwareDashboard.high')}
									system={result.high.resource}
									issues={result.high.issues}
								/>
							</Col>
						</Row>
						<Row className="mb-lg-60 mb-4 w-100">
							<RansomwareTopology
								reportId={reportName || result.reportId}
								title={t('topology.cloudTitle')}
								issues={result.issues}
								chartDim={{ h: 550, w: 700 }}
								hideRules={false}
							/>
						</Row>
						<Row className="mb-lg-60 mb-4 w-100">
							<Col>
								<Vulnerabilities
									data={{
										vulnerabilities: result.vulnerabilities,
										issues: result.issues,
									}}
								/>
							</Col>
						</Row>
						<div className="d-flex align-items-center justify-content-center gap-5">
							<Link
								to="/ransomware/remediation"
								className="btn btn-primary btn-outline btn-md w-30"
							>
								{t('button.autoRemediation')}
							</Link>
							<Link
								to={`/reports/ransomware/${reportName}`}
								title="View Report"
								className="btn btn-primary btn-outline btn-md w-30"
							>
								{t('button.viewReport')}
							</Link>
						</div>
					</>
				)}
			</Container>
		)
	);
};

export default ReviewScore;
