import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CloudTypeProps } from '../../../auth/MultiCloudSignIn/Constants';

const CloudTabs = ({ options, activeTab, onTabChange }) => {
	const { t } = useTranslation();
	const buttonClassnames = active =>
		classNames('cloud-type-button', {
			'cloud-type-button__active': active,
		});

	return (
		<ul className="list-inline cloud-types mb-4">
			{options.map(id => (
				<li className="list-inline-item" key={id}>
					<button
						onClick={() => onTabChange(id)}
						className={buttonClassnames(activeTab === id)}
						disabled={CloudTypeProps[id].disabled}>
						{CloudTypeProps[id].title}
					</button>
				</li>
			))}
			<li className="list-inline-item">
				<button
					className={buttonClassnames(activeTab === 'all')}
					onClick={() => onTabChange('all')}
					disabled>
					{t('adminPanel.allTab')}
				</button>
			</li>
		</ul>
	);
};

export default CloudTabs;
