import {
	getAuditTemplate,
	getReportData,
} from '../../../../apiclients/AuditResults';
import { useEffect, useState } from 'react';

import { allPacks } from '../Common/Constants';
import { createNewAwsEval } from '../../../../apiclients/AutomatedTests';
import { getAwsResultUrl } from '../../Common/ReportService';
import { progressState } from '../../../../apiclients/Common';
import { saveReportData } from '../../../../apiclients//AuditResults';
import { useNavigate } from 'react-router-dom';

export default function (report) {
	const pack = allPacks[report.pack],
		title = pack.title,
		[loading, setLoading] = useState(false),
		[error, setError] = useState(false),
		[data, setData] = useState({}),
		[dataLoaded, setDataLoaded] = useState(false),
		[auditor, setAuditor] = useState(report.auditor ?? ''),
		[saving, setSaving] = useState(false),
		[saveProgress, setSaveProgress] = useState(progressState.none),
		[isDraft, setIsDraft] = useState(false),
		navigate = useNavigate(),
		fetchAuditTemplate = async () => {
			const response = await getAuditTemplate(report.pack);
			if (response.failed) {
				console.error('template fetch error', response);
				setError(true);
				return;
			}

			setData(response);
			setDataLoaded(true);
		},
		setAuditData = response => {
			console.log('got back a report', response);
			if (!response.auditorName || !response.data) {
				setError(true);
			}
			setAuditor(response.auditorName);
			setData(response.data);
			setDataLoaded(true);
		},
		onAuditSaved = async inProgress => {
			console.log('onAuditSaved', inProgress, report);
			setIsDraft(inProgress);
			setSaving(true);

			const auditReport = {
				Pack: report.pack,
				ReportId: report.ReportId,
				EvalId: report.EvalId,
				results: {
					auditorName: auditor ?? report.auditor,
					data: data,
				},
			};

			let result = await saveReportData(auditReport, setSaveProgress);
			if (result.failed) {
				console.error('audit save failed', result);
				return;
			}

			if (inProgress) {
				navigate(`/dashboard/reports`);
				return;
			}

			if (
				report.EvalId &&
				Number(report.EvalId) > 0 &&
				report.Fragments &&
				Number(report.Fragments) > 0
			) {
				const url = getAwsResultUrl(report, report.pack, report.mode);
				navigate(url);
			} else {
				result = await createNewAwsEval(
					report.pack,
					report.ReportId,
					setSaveProgress
				);
				if (!result.success) {
					console.error('aws create failed', result);
				}

				console.log('report: ', report);
				const awsReport = result.results;
				const url = getAwsResultUrl(
					awsReport,
					awsReport.ConformancePack,
					'audit'
				);
				navigate(url);
			}
		};

	useEffect(() => {
		let mounted = true;

		const fetchAuditReport = async () => {
			setLoading(true);

			const response = await getReportData(report);

			if (!mounted) return;

			if (response.succeeded) {
				setAuditData(response);
			} else if (response.is404) {
				console.log('trying to fetch audit template');
				await fetchAuditTemplate();
			} else {
				console.error('an unknown error', response);
				setError(true);
			}

			setLoading(false);
		};

		fetchAuditReport();

		return () => {
			setData({});
			mounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		report,
		data,
		dataLoaded,
		reportId: report.ReportId,
		auditor,
		title,
		loading,
		error,
		saving,
		saveProgress,
		isDraft,
		onAuditSaved,
	};
}
