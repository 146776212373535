import {
	Card,
	CardBody,
	Col,
	Row,
} from "reactstrap";
import React, { useContext } from "react";

import ReportContext from "../Common/ReportContext";
import ResultCategory from "./ResultCategory";

const ReportRow = ({ test }) => {

	const context = useContext(ReportContext),
		referenceData = context.data.referenceData,
		categories = referenceData.categories;

	return (
		<Row className="border border-top-0 p-0 border-bottom-0">
			<Col md={2}
				className="p-0 border border-bottom-0 border-top-0 border-right-1 border-left-0">
				<div
					style={{ backgroundColor: test.bgColor }}
					className="h-100 pb-0 p-1">
					{test.description.map((item, index) => <p key={index} style={{ color: test.fgColor }}>{item}</p>)}
				</div>
			</Col>
			<Col md={10}>
				{test.categories.map((category, index) =>
					<ResultCategory
						key={index}
						data={categories[category]}
					/>
				)}
			</Col>
		</Row>
	);
}

const FunctionHeader = ({ isNested, prefix }) => {

	const
		context = useContext(ReportContext),
		printMode = context.printMode,
		headers = context.data.headers,
		classNames = "bg-dark text-light" + (isNested ? " report-function-header" : "");

	if (!printMode && isNested) return <></>;

	return (
		<Row className={classNames} style={{fontSize: '0.79rem'}}>
			{headers.map(header => <div key={`${prefix}_${header.id}`} className={header.classList} style={header.style}>{header.value}</div>)}
		</Row>
	);
};

const ReportFunction = ({ test }) => {

	const context = useContext(ReportContext),
		childTestCases = context.data.childTestCases,
		renderRow = (child, index) => {
			const childTest = childTestCases[child];
			return (
				<div key={`${childTest.id}_${index}`}>
					<FunctionHeader isNested={index > 0} prefix={childTest.id} />
					<ReportRow test={childTest} />
				</div>
			)
		};

	return (
		<Card>
			<CardBody>
				{!test.children &&
					<div>
						<FunctionHeader isNested={false} prefix={test.id} />
						<ReportRow test={test} />
					</div>
				}
				{test.children && test.children.map(renderRow)}
			</CardBody>
		</Card>
	);
}

export default ReportFunction;