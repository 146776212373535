import React, { useEffect, useState } from 'react';

import { getTruncatedResourceName as resourceName } from '../../../Common/RansomwareUtilites';

const Instance = ({ v, i, onSelect, selectAll }) => {

	const [checked, set] = useState(false);

	const onChecked = checked => {
		set(checked);
		onSelect(checked, v);
	}

	useEffect(() => {
		onChecked(selectAll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAll]);

	return (
		<li key={i}>
			<input
				checked={checked}
				onChange={e => onChecked(e.currentTarget.checked)}
				type="checkbox"
				name={`cb_${i}`}
				id={`cb_${i}`} />
			<label htmlFor={`cb_${i}`} className="ms-1" title={v.ResourceName}>
				{resourceName(v.ResourceName)}
			</label>
		</li>
	);
}

const Instances = ({ hide, instances, onResourceSelected, selectAll }) =>
(
	!hide && (
		<ul style={{ listStyle: 'none' }} className="m-0 p-0">
			{instances.map((v, i) => <Instance key={i} v={v} i={i} selectAll={selectAll} onSelect={onResourceSelected} />)}
		</ul>
	)
);

export default Instances;