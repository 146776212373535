import React from 'react';
import { Trans } from 'react-i18next';

const key = 'testReports.testMetaInfo.pcidss.';

export default [
	{
		title: <Trans i18nKey={key + 'secureNetwork.title'} />,
		titleBackground: '#66d9ff',
		text: [<Trans i18nKey={key + 'secureNetwork.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'secureCardholder.title'} />,
		titleBackground: '#00ace6',
		text: [<Trans i18nKey={key + 'secureCardholder.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'vulnerabilityManagement.title'} />,
		titleBackground: '#ff6633',
		text: [<Trans i18nKey={key + 'vulnerabilityManagement.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'accessControl.title'} />,
		titleBackground: '#ff8000',
		text: [<Trans i18nKey={key + 'accessControl.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'networkMonitoring.title'} />,
		titleBackground: '#66cc00',
		text: [<Trans i18nKey={key + 'networkMonitoring.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'informationSecurity.title'} />,
		titleBackground: '#66d9ff',
		text: [<Trans i18nKey={key + 'informationSecurity.text'} />],
	},
];
