import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';

import { finalizeReport } from '../../../apiclients/AutomatedTests';
import { getChangedValues } from './ChangedValues';
import { saveResults } from './ResultService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Form/Button';
import classNames from 'classnames';

const getKey = item => `${item.key}_${item.fragmentId}`;

const ErrorModal = () => {
	const [isOpen, setIsOpen] = useState(true);
	const { t } = useTranslation();
	return (
		<Modal isOpen={isOpen}>
			<ModalHeader>{t('button.error')}</ModalHeader>
			<ModalBody>
				<span className="text-danger">{t('topology.saveTestError')}</span>
			</ModalBody>
			<ModalFooter>
				<Button size="lg" color="danger" onClick={() => setIsOpen(false)}>
					{t('button.close')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const ReviewButton = ({ data, reviewUrl, report, className }) => {
	const { t } = useTranslation();
	const [isBusy, setIsBusy] = useState(false),
		[error, setError] = useState(false),
		navigate = useNavigate(),
		packName = report.PackName,
		reportId = report.ReportId;

	const onSaveResults = async () => {
		setIsBusy(true);

		const partitionedData = [],
			changedValues = getChangedValues(),
			getChangedValue = item => changedValues[getKey(item)],
			fragmentExists = item => partitionedData.hasOwnProperty(item.fragmentId),
			push = (id, item) => partitionedData[id].push(item),
			set = (id, item) => (partitionedData[id] = [item]),
			dirtyFragments = [],
			isDirty = Object.keys(changedValues).length > 0,
			createPartition = item => {
				const changedValue = getChangedValue(item),
					changedItem = changedValue
						? Object.assign({}, item, changedValue)
						: item,
					add = fragmentExists(item) ? push : set;

				if (!dirtyFragments[item.fragmentId]) {
					dirtyFragments[item.fragmentId] = !!changedValue;
				}

				add(item.fragmentId, changedItem);
			},
			redirect = () => navigate(reviewUrl),
			saveReport = async () => {
				await finalizeReport(report);
				redirect();
			};

		let saved = true;

		if (isDirty) {
			data.forEach(createPartition);
			saved = await saveResults(
				reportId,
				packName,
				partitionedData,
				dirtyFragments
			);
			setIsBusy(false);
			setError(!saved);
		}

		if (saved) {
			await saveReport();
		}
	};

	return (
		<>
			<Button
				className={classNames('me-1', { [className]: className })}
				onClick={onSaveResults}
				disabled={isBusy}>
				{isBusy && (
					<div
						role="status"
						className="spinner-border spinner-border-sm mb-1"
						id="saveBtnLoader">
						<span className="sr-only"></span>
					</div>
				)}
				<span className="ms-1">{t('button.generateReport')}</span>
			</Button>
			{error && <ErrorModal />}
		</>
	);
};

export default ReviewButton;
