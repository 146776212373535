import endpointImage from '../../assets/img/k8s-icons/ep.svg'
import namespaceImage from '../../assets/img/k8s-icons/ns.svg'
import podImage from '../../assets/img/k8s-icons/pod.svg'
import svcImage from '../../assets/img/k8s-icons/svc.svg'
import ingImage from '../../assets/img/k8s-icons/ing.svg'

const defaultDim = {
	height: '48px',
	width: '48px',
};

const defaultColour = 'rgba(255, 255, 255, 0.4)';

const endpoint = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(200, 200, 200, 1)',
	image: `${endpointImage}`,
};

const namespace = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${namespaceImage}`,
};

const pod = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(180, 200, 180, 0.8)',
	image: `${podImage}`,
};

const svc = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(180, 200, 180, 0.8)',
	image: `${svcImage}`,
};
const ing = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(180, 200, 180, 0.8)',
	image: `${ingImage}`,
};


const k8sConfig = {
	'Namespace': {
		label: 'Namespace',
		image: namespace,
		color: '#0039a6',
	},
	'Endpoints': {
		label: 'Endpoints',
		image: endpoint,
		color: '#7a49a5',
	},
	'Pod': {
		label: 'Pod',
		image: pod,
		color: '#ff7b12',
	},
	'Service': {
		label: 'Service',
		image: svc,
		color: '#ff7b12',
	},
	'Ingress': {
		label: 'Ingress',
		image: ing,
		color: '#ff7b12',
	}

};

export default k8sConfig;
