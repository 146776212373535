import { asyncRequest, httpMethods, resolveUri } from './Common';

// hack to call the host directly
// TODO fix this
const apiProxyHostUri = `${process.env.REACT_APP_PROXY_API_HOST}`;


const getLogIssueList = async (success, failure = () => {}) => {
	try {
		const uri = apiProxyHostUri + "/k8s/logissues/list";

		const options = {
			method: httpMethods.get,
		};

		const result = await asyncRequest(uri, options);

		if (!result.success && result.statusCode !== 404) {
			failure(result);
			return;
		}
		const response = result.response;
		success(response);
	} catch (e) {}
};


const getLogIssueDetails = async (logId, success, failure = () => {}) => {
	try {
		const uri = apiProxyHostUri + "/k8s/logissues/" + logId;

		const options = {
			method: httpMethods.get,
		};

		const result = await asyncRequest(uri, options);

		if (!result.success && result.statusCode !== 404) {
			failure(result);
			return;
		}
		const response = result.response;
		success(response);
	} catch (e) {}
};



export { getLogIssueList, getLogIssueDetails};
