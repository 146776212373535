import React from 'react';
import { Trans } from 'react-i18next';

const key = 'testReports.testMetaInfo.hipaa.';

export default [
	{
		title: <Trans i18nKey={key + 'privacy.title'} />,
		titleBackground: '#66d9ff',
		text: [<Trans i18nKey={key + 'privacy.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'security.title'} />,
		titleBackground: ' #00ace6',
		text: [<Trans i18nKey={key + 'security.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'transaction.title'} />,
		titleBackground: '#ff6633',
		text: [<Trans i18nKey={key + 'transaction.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'identifiers.title'} />,
		titleBackground: '#ff8000',
		text: [<Trans i18nKey={key + 'identifiers.text'} />],
	},
	{
		title: <Trans i18nKey={key + 'enforcement.title'} />,
		titleBackground: '#66cc00',
		text: [<Trans i18nKey={key + 'enforcement.text'} />],
	},
];
