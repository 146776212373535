import { Col, Container, Row } from 'reactstrap';
import { PotentialLoss, RiskScore } from '../Controls/Risk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import CustomTooltip from './CustomTooltip';

import DashboardRedirect from './controls/DashBoardRedirect';
import EvaluationHistory from './controls/EvaluationHistory';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import Issues from '../assessment/Controls/ReviewScore/Issues';
import Loader from '../../../components/Loader';
import LossTrend from './controls/LossTrend';
import React, { useRef, useState, useEffect } from 'react';
import VulnerabilityLoss from './controls/VulnerabilityLoss';
import useDashboard from '../Hooks/useDashboard';
import DashboardTopology from '../Controls/DashboardTopology';
import IssueTableInfo from './controls/IssueTableInfo';
import LogIssues from './controls/LogIssues';
import { getUserType } from '../../../services/AuthService';
import { CloudTypes } from '../../../constants/CloudInfo';
import { ArrowUp } from 'react-feather';
import { X } from 'react-feather';

import { ReactComponent as IconRocket } from './svg/Illustration.svg';
const Dashboard = () => {
	const navigate = useNavigate();
	const result = useDashboard();
	const { t } = useTranslation();
	const [report, setReportName] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [openedSeverity, setOpenedSeverity] = useState(null);
	const [filteredIssues, setFilteredIssues] = useState([]);
	const [showDiv, setShowDiv] = useState(false);
	const [selectedScenario, setSelectedScenario] = useState(1);

	const heatMapRef = useRef(null);
	const isK8S = getUserType() === CloudTypes.K8s;

	const showUserJourney = ('1' === process.env?.REACT_APP_FEAT_SHOW_JOURNEY) ? true : false;

	const onOpenModal = severity => {
		try {
			setOpenedSeverity(severity);
			setFilteredIssues([]);
			let severityVulnerabilities;
			if (severity === '*') {
				severityVulnerabilities = result.vulnerabilities;
			} else {
				severityVulnerabilities = result.vulnerabilities.filter(
					item => item.Severity === severity
				);
			}

			const formatIssues = severityVulnerabilities.reduce(
				(acc, vulnerability) => {
					const issues = result.issues[vulnerability.SecurityTestRule];
					if (issues) {
						const formatIssues = issues.map(item => ({
							...item,
							SecurityTestRule: vulnerability.SecurityTestRule,
						}));

						acc.push(...formatIssues);
					}
					return acc;
				},
				[]
			);

			setFilteredIssues(formatIssues);
			setIsOpen(true);
		} catch (e) {
			setIsOpen(false);
			setFilteredIssues([]);
		}
	};

	const onIssueNewTab = (noSeverity = false) => {
		const queryParams = new URLSearchParams();

		if (!noSeverity) {
			queryParams.set('severity', openedSeverity);
		}
		queryParams.set('reportId', result.lastReport?.ReportId);
		queryParams.set('pack', result.lastReport?.ConformancePack);
		queryParams.set('evalId', result.lastReport?.EvalId);

		navigate(`/ransomware/issues?${queryParams.toString()}`);
	};

	const onScrollToHeatMap = () => {
		if (!heatMapRef.current) return;

		heatMapRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
	};

	let showChangeBubbles = false;

	const [run, setRun] = useState(true);

	const stepsScenario1 = [
		{
			target: '.my-plug-step',
			content: 'Potencial cybersecurity ransomeware risk in financial terms. Click on the "i" to know more details.',
			placement: 'left',
		},
	
		{
			target: '.my-1-step',
			content: 'Select a Compliance Pack.',
			placement: 'left',
		},
		{
			target: '.my-2-step',
			content: 'Select a Compliance Pack.',
			placement: 'left',
		},
		{
			target: '.my-3-step',
			content: 'Click for a specific report.',
			placement: 'left',
		},
		{
			target: '.my-4-step',
			content: 'Select a report.',
			placement: 'left',
		},
		{
			target: '.my-5-step',
			content:
				'System compliance is shown. Hover over a resource to get details',
			placement: 'top',
		},
		{
			target: '.my-6-step',
			content: 'Click on a resourse',
		},
		{
			target: '.my-7-step',
			content: 'Display resources and issues',
		},
		{
			target: '.my-8-step',
			content: 'Click to expand issue list',
		},
		{
			target: '.my-9-step',
			content: 'Click "See info" to get the description and solutions',
		},
		{
			target: '.my-10-step',
			content: 'Click "Show full info" for open the next page',
		},

	];
	const stepsScenario2 = [
		{
			target: '.my-1-step',
			content: 'Step 1 for Scenario 2.',
			placement: 'left',
		},
		{
			target: '.my-22-step',
			content: 'Open Dashboard and select Security Compliance. ',
			placement: 'left',
		},
	
		
	];
	const stepsScenario3 = [
		{
			target: '.my-plug-step',
			content: 'Step 1 for Scenario 3.',
			placement: 'left',
		},
		{
			target: '.my-1added-step',
			content: 'Potencial cybersecurity ransomeware risk in financial terms. Click on the "i" to know more details.',
			placement: 'left',
		},
		{
			target: '.my-33-step',
			content: 'Contributors to your potencial cybersecurity losses.Click on a “point” to know more details.',
			placement: 'left',
		},
		{
			target: '.my-34-step',
			content: 'Resources that contribute to your potencial cybersecurity losses.Click on a “+” to know more details ',
			placement: 'left',
		},
		{
			target: '.my-35-step',
			content: 'Resources that contribute to your potencial cybersecurity losses.Click on a “	Resource Name” to know more details ',
			placement: 'left',
		},
		{
			target: '.my-7-step',
			content: 'Display resources and issues',
		},
		{
			target: '.my-8-step',
			content: 'Click to expand issue list',
		},
		{
			target: '.my-9-step',
			content: 'Click "See info" to get the description and solutions',
		},
		{
			target: '.my-10-step',
			content: 'Click "Show full info" for open the next page',
		},
		// Додайте більше кроків для сценарію 3, якщо потрібно
	];

	const handleJoyrideCallback = data => {
		const { status, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

		if (finishedStatuses.includes(status)) {
			setRun(false);
		}
	};
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowDiv(true);
			// setRun(true);
		}, 5000);

		return () => clearTimeout(timer); 
	}, []);
	const joyrideStyles = {
		buttonNext: {
			backgroundColor: '#007bff', // Bootstrap primary blue
			color: '#fff',
		},
		buttonBack: {
			color: '#007bff', // Bootstrap primary blue
		},
		buttonSkip: {
			color: '#007bff', // Bootstrap primary blue
		},
	};
	const handleGetStartedClick = (scenario) => {
		setSelectedScenario(scenario)
		setRun(!run);
		setShowDiv(false);
	};
	const steps = selectedScenario === 1 ? stepsScenario1 : selectedScenario === 2 ? stepsScenario2 : stepsScenario3;
	return (
		<>
			{showUserJourney &&
				<Joyride
					steps={steps}
					run={run}
					continuous
					scrollToFirstStep
					showProgress
					showSkipButton
					callback={handleJoyrideCallback}
					disableOverlay={true}
					disableOverlayClose={true}
					styles={joyrideStyles}
					locale={{
						next: 'Next',
						back: 'Back',
						last: 'Last',
					}}
					tooltipComponent={CustomTooltip}
				/>}

			<Container fluid className="position-relative">
				{showUserJourney && <button onClick={() => setShowDiv(!showDiv)} className="rocket">
					<IconRocket className="RocketSvg" />
					{/* <img src={MySvg} alt="Button Icon" className="" /> */}
				</button>}
				<Header>
					<HeaderTitle>{t('ransomwareDashboard.title')}</HeaderTitle>
				</Header>
				{result.loading && <Loader />}
				{!result.loading && result.error && (
					<DashboardRedirect reason={'reporterror'} />
				)}
				{!result.loading && !result.error && (
					<>
						<Row className="mb-lg-60 mb-4 w-100 gap-4 px-sm position-relative">
							<Col className="p-0">
								<RiskScore
									score={result.riskScore}
									previous={result.previousRiskScore}
									showChangeBubbles={showChangeBubbles}
								/>
							</Col>
							<Col className="p-0">
								<PotentialLoss
									value={result.potentialLoss}
									previous={result.previousLoss}
									current={result.currentLoss}
									info
									onInfoClick={onScrollToHeatMap}
									showChangeBubbles={showChangeBubbles}
								/>
							</Col>
							<Col className="p-0">
								<Issues
									info
									onInfoClick={() => onOpenModal('Critical')}
									color="danger"
									title={t('ransomwareDashboard.critical')}
									system={result.criticalCount.resource}
									issues={result.criticalCount.issues}
								/>
							</Col>
							<Col className="p-0 ">
								<Issues
									info
									onInfoClick={() => onOpenModal('High')}
									color="warning"
									title={t('ransomwareDashboard.high')}
									system={result.highCount.resource}
									issues={result.highCount.issues}
								/>
							</Col>
							{!!isOpen && !!filteredIssues.length && (
								<IssueTableInfo
									onSelectSeverity={onOpenModal}
									data={{
										openedSeverity,
										report: result.lastReport,
										issues: result.issues,
									}}
									onNewTab={onIssueNewTab}
									list={filteredIssues}
									onClose={() => {
										setOpenedSeverity(null);
										setFilteredIssues([]);
										setIsOpen(false);
									}}
								/>
							)}
						</Row>

						{isK8S && (
							<Row className="w-100 ">
								<Col className="p-sm ">
									<LogIssues />
								</Col>
							</Row>
						)}

						<Row className="mb-lg-60 mb-4 w-100 position-relative">
							{showUserJourney && showDiv && (
								<div className="journey">
									{/* <ChevronRight /> */}
									<div className="joyWrap">
										<div className="joyWrapText">
											<h2 className="journeyTitle">Choose your journey</h2>
											<h3 className="journeySubTitle">
												Choose what interests you most on TalaSecure Defender
											</h3>

											<p className="journeyText">
												*you can always change your trip by clicking on the
												rocket icon at the top of the page
											</p>
										</div>
										<div className="arrowRocket">
											<IconRocket className="RocketSvgJourney" />
											<ArrowUp />
										</div>
									</div>
									<div className="JourneyBtnWrap">
										<button
											onClick={() => handleGetStartedClick(1)}
											className="joyStart">
											Ransomware
										</button>
										<button
											onClick={() => handleGetStartedClick(2)}
											className="joyStart">
											Compliance
										</button>
										<button
											onClick={() => handleGetStartedClick(3)}
											className="joyStart">
											Potential Loss
										</button>
										<button
											onClick={() => setShowDiv(false)}
											className="joyFinish">
											Finish journey <X />
										</button>
									</div>
								</div>
							)}
							<DashboardTopology
								report={report || result.lastReport}
								setReport={setReportName}
								issues={result.issues}
								reports={result.evaluationHistory}
							/>
						</Row>
						<div
							ref={heatMapRef}
							className="row mb-lg-60 mb-4 w-100"
							style={{ position: 'relative' }}>
							<Col className="py-0 pb-lg-0 pb-4" lg="6">
								<LossTrend data={result.lineChartData} />
							</Col>
							<Col className="py-0" lg="6" style={{ position: 'static' }}>
								<VulnerabilityLoss
									onIssueNewTab={() => onIssueNewTab(true)}
									data={{
										vulnerabilities: result.vulnerabilities,
										issues: result.issues,
										lastReport: result.lastReport,
									}}
								/>
							</Col>
						</div>
						<Row className="w-100 ">
							<Col className="p-sm">
								<EvaluationHistory data={result.evaluationHistory} />
							</Col>
						</Row>
					</>
				)}
			</Container>
		</>
	);
};

export default Dashboard;
