import React, { useEffect, useState } from 'react';
import TopologyConfig from './Config';

import { Col } from 'reactstrap';
import Legend, { SelectPack, SelectReport } from './Legend';
import { EvaluationApiClient } from '../../apiclients/EvaluationApiClient';
import { getEvaluationHistory } from '../../apiclients/Ransomware';

const NetworkChart = props => {
	const {
		networkTopoGraph,
		setNodeShow,
		modal,
		hidden,
		changeReport,
		selectedPack,
		setSelectedPack,
	} = props;

	const config = new TopologyConfig();
	const [legendItems, setLegendItems] = useState(config.legend());
	const [packList, setPackList] = useState({});
	const [packListLoaded, setPackListLoaded] = useState(false);
	const [reportList, setReportList] = useState({});
	const [reportListLoaded, setReportListLoaded] = useState(false);

	const itemUpdate = (el, compliance, item) => {
		// update settings
		if ('compliant' === compliance) item.showCompliant = !item.showCompliant;
		else if ('noncompliant' === compliance)
			item.showNonCompliant = !item.showNonCompliant;

		var newItems = [];
		var nTypes = {};
		for (const curItem of legendItems) {
			nTypes[curItem.label] = {};
			if (curItem.label === item.label) {
				newItems.push(item);
				nTypes[curItem.label].showCompliant = item.showCompliant;
				nTypes[curItem.label].showNonCompliant = item.showNonCompliant;
			} else {
				newItems.push(curItem);
				nTypes[curItem.label].showCompliant = curItem.showCompliant;
				nTypes[curItem.label].showNonCompliant = curItem.showNonCompliant;
			}
		}

		setLegendItems(newItems);
		setNodeShow(nTypes);
	};

	const showHideAll = (showHide, compliance) => {
		var newItems = [];
		var nTypes = {};
		for (var curItem of legendItems) {
			nTypes[curItem.label] = {};
			if ('compliant' === compliance)
				curItem.showCompliant = 'show' === showHide ? true : false;
			else if ('noncompliant' === compliance)
				curItem.showNonCompliant = 'show' === showHide ? true : false;

			newItems.push(curItem);
			nTypes[curItem.label].showCompliant = curItem.showCompliant;
			nTypes[curItem.label].showNonCompliant = curItem.showNonCompliant;
		}
		setLegendItems(newItems);
		setNodeShow(nTypes);
	};

	const fetchReportList = async () => {
		const client = new EvaluationApiClient();
		const rraItems = await getEvaluationHistory();

		client.history(results => {
			var packs = {};
			for (const reportitem of results) {
				if (!packs[reportitem['ConformancePack']]) {
					packs[reportitem['ConformancePack']] = [];
				}
				packs[reportitem['ConformancePack']].push(reportitem);
			}
			packs.RRA = rraItems.success ? rraItems.data : [];

			setPackList(packs);
			setPackListLoaded(true);
		});
	};

	const changePack = packname => {
		setSelectedPack(packname);
		setReportList(packList[packname]);
		setReportListLoaded(true);
	};

	useEffect(() => {
		fetchReportList();
	}, []);

	return (
		<>
			<Col lg="3" className="pr-lg-3 py-0 pb-lg-0 pb-4">
				<Legend
					legendItems={legendItems}
					itemUpdate={itemUpdate}
					showHideAll={showHideAll}
					hidden={hidden}
					reportComponent={
						<div className="d-flex flex-wrap mb-2">
							{packListLoaded && (
								<>
									<div className="p-1 w-100">
										<SelectPack
											packs={packList}
											changepack={changePack}></SelectPack>
									</div>
									{reportListLoaded && (
										<div className="p-1 w-100">
											<SelectReport
												packName={selectedPack}
												reports={reportList}
												changeReport={changeReport}
											/>
										</div>
									)}
								</>
							)}
						</div>
					}
				/>
			</Col>
			<Col className="py-0 pr-0" lg={modal ? '5' : '9'}>
				{networkTopoGraph}
			</Col>
		</>
	);
};

export default NetworkChart;
