import { useCallback, useState } from 'react';
import { EvaluationApiClient } from '../../../apiclients/EvaluationApiClient';
import { getResults } from '../../tests/AutomatedTests/ResultService';

const useNetworkTopology = ({
	pack,
	reportName,
	nodeShow,
	evalId,
	numFragments,
	updateReportProgress
}) => {
	const [nodes, setNodes] = useState();
	const [loaded, setLoaded] = useState();
	const [reloading, setReloading] = useState();
	const [error, setError] = useState();
	const [results, setResults] = useState();

	const filterNodes = useCallback(
		curNodes => {
			var newNodes = [];

			for (const n of curNodes) {
				//console.log("n:", n);
				if (nodeShow[n.data.resourcetype]) {
					if (!n.data.compliance) {
						// add if compliance data is not known and show compliant is true
						if (nodeShow[n.data.resourcetype].showCompliant) newNodes.push(n);
					} else {
						// we have compliance data, show if compliant and showCompliant or non-compliant and showNonCompliant
						if (
							('Compliant' === n.data.compliance &&
								nodeShow[n.data.resourcetype].showCompliant) ||
							('Non-Compliant' === n.data.compliance &&
								nodeShow[n.data.resourcetype].showNonCompliant)
						) {
							newNodes.push(n);
						}
					}
				} else newNodes.push(n);
			}
			return newNodes;
		},
		[nodeShow]
	);

	const fetchData = useCallback(
		async (refresh, callback, updateReportProgress = null) => {
			const client = new EvaluationApiClient();

			if (pack && pack !== '' && reportName && reportName !== '') {
				if (evalId && evalId !== '' && numFragments && numFragments !== '') {
					const reportInfo = {
						ConformancePack: pack,
						PackName: pack,
						EvalId: evalId,
						Fragments: numFragments,
						ReportId: reportName,
					};
					const results = await getResults(reportInfo, updateReportProgress);
					setResults(results.rows);
				}

				client.getliveEnvironmentCompliance(
					{
						PackName: pack,
						ReportId: reportName,
					},
					data => {
						setNodes(filterNodes(data));
						setLoaded(true);
						setReloading(false);
						if (callback) {
							callback();
						}
					},
					e => {
						console.error('There was an error fetching the topology', e);
						setError(true);
					}
				);
			} else {
				console.log('Using liveEnvironment');
				client.liveEnvironment(
					refresh,
					data => {
						setNodes(filterNodes(data));
						setLoaded(true);
						setReloading(false);
						if (callback) {
							callback();
						}
					},
					e => {
						console.error('There was an error fetching the topology', e);
						setError(true);
					}
				);
			}
		},
		[evalId, filterNodes, numFragments, pack, reportName]
	);

	const refreshTopology = callback => {
		setReloading(true);
		fetchData(true, callback, updateReportProgress);
	};

	return {
		fetchData,
		refreshTopology,
		nodes,
		loaded,
		reloading,
		error,
		results,
	};
};

export default useNetworkTopology;
