import React from 'react';
import '../styles.css';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import icon6 from '../../../assets/img/6.svg';
import figure7 from '../../../assets/img/figure7.png';
import figure8 from '../../../assets/img/figure8..png';
import figure9 from '../../../assets/img/figure9.png';
import figure10 from '../../../assets/img/figure10.png';
import figure11 from '../../../assets/img/figure11.png';
import figure12 from '../../../assets/img/figure12.png';

function Step4() {
	const { t } = useTranslation();
	return (
		<div id="step4" className="instructions-steps mt-4">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 4</h5>

				<div>
					<h5 className="steps-title">{t('detailedInstructions.IAMGroup')}</h5>
				</div>
			</div>
			<p className="m-0 p-0">{t('detailedInstructions.bestPractices')}</p>
			<p className="mb-2 p-0">{t('detailedInstructions.rightPolicies')}</p>
			<div className="d-flex mb-2 ms-6">
				<span role="img" aria-label="Notice">
					❗
				</span>

				<h5 className="steps-title">
					{t('detailedInstructions.standardOperations')}
				</h5>
			</div>
			<div className="d-flex ">
				<img src={icon1} alt="icon1" className="img" />
				{t('detailedInstructions.userGroups')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure7} alt="figure7" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure7')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon2} alt="icon2" className="img" />
				{t('detailedInstructions.IAMdashboard')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure8} alt="figure8" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure8')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon3} alt="icon3" className="img" />
				{t('detailedInstructions.nameGroup')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure9} alt="figure9" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure9')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.talaSecurePolicy')}
			</div>
			<div className="d-flex mb-2">
				<span role="img" aria-label="Notice">
					❗
				</span>

				<h5 className="steps-title">
					{t('detailedInstructions.talaSecureAccessPolicy')}
				</h5>
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure10} alt="figure10" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure10')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.securityAudit')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure11} alt="figure11" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure11')}
					</p>
				</div>
			</div>
			<div className="d-flex ">
				<img src={icon6} alt="icon6" className="img" />
				{t('detailedInstructions.chosenPolicies')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure12} alt="figure12" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure12')}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Step4;
