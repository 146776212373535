const mapAwsData = d => {

	const data = {};

	if(d && d.length > 0){
		d.forEach(item => data[Object.keys(item)[0].toLowerCase()] = Object.values(item)[0]);
	}

	return data;
}

export {mapAwsData};