import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import CustomTooltip from '../../ransomware/dashboard/CustomTooltip';

import ComplianceCharts from './Controls/ComplianceCharts';
import ComplianceCounts from './Controls/ComplianceCounts';
import ComplianceTrend from './Controls/ComplianceTrend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../../../components/Header';
import HeaderTitle from '../../../components/HeaderTitle';
import Loader from '../../../components/Loader';
import { PackNames } from '../../../constants/PackKeys';
import TopRules from './Controls/TopRules';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { getSummary } from '../../../apiclients/Summary';
import { EvaluationApiClient } from '../../../apiclients/EvaluationApiClient';
import { getResults } from '../../tests/AutomatedTests/ResultService';
import { t } from 'i18next';
import { ArrowUp } from 'react-feather';
import { X } from 'react-feather';
import IssueTableInfo from '../../../pages/ransomware/dashboard/controls/IssueTableInfo';
import { ReactComponent as IconRocket } from '../../../pages/ransomware/dashboard/svg/Illustration.svg';
import LoaderTopology from '../../../components/LoaderTopology';

const mapData = (data, keys) =>
	keys.map(key => {
		const value = data[key],
			mapMonth = val => {
				const split = val.split('-'),
					month = parseInt(split[0]) - 1,
					year = parseInt(split[1]),
					locale = 'en-US',
					date = new Date(year, month, 1),
					label = date.toLocaleString(locale, { month: 'short' });

				return label;
			},
			trendKeys = Object.keys(value.MonthlyCompliance),
			trendLabels = trendKeys.map(mapMonth),
			trendData = trendKeys.map(key => value.MonthlyCompliance[key]).reverse(),
			sortedRules = () => {
				const arr = value.CriticalResources,
					comparator = (a, b) => {
						const x = parseInt(arr[a]);
						const y = parseInt(arr[b]);

						if (x < y) return -1;
						if (x > y) return 1;
						if (x === y) return 0;
					},
					keys = () =>
						Object.keys(arr)
							.sort(comparator)
							.map(key => ({ [key]: arr[key] })),
					sorted = () => Object.assign({}, ...keys());

				return sorted();
			};

		return {
			packKey: key,
			packName: PackNames[key],
			top: sortedRules(),
			counts: {
				issues: value.CriticalIssuesCount,
				systems: value.NonCompliantResourcesCount,
			},
			charts: {
				systems: [
					value.PercentResourceCompliance,
					100 - value.PercentResourceCompliance,
				],
				rules: [value.PercentRuleCompliance, 100 - value.PercentRuleCompliance],
			},
			trends: {
				labels: trendLabels,
				data: trendData,
			},
		};
	});

const backFillCompliance = (d, keys) => {
	let max = -1,
		maxKey = '';

	const updateMax = key => {
		const length = Object.keys(d[key].MonthlyCompliance).length;
		if (length >= max) {
			max = length;
			maxKey = key;
		}
	},
		findMaxLength = () => keys.forEach(key => updateMax(key));

	findMaxLength();

	const maxMonthlyKeys = Object.keys(d[maxKey].MonthlyCompliance);

	const backFill = item => {
		maxMonthlyKeys.forEach(key => {
			if (!item[key]) {
				item[key] = 0;
			}
		});
	};

	keys.forEach(key => backFill(d[key].MonthlyCompliance));
};

export default function ComplianceDashboard() {
	const client = new EvaluationApiClient();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [data, setData] = useState({});
	const [isOpen, setIsOpen] = useState(false);
	const [openedSeverity, setOpenedSeverity] = useState(null);
	const [allIssues, setAllIssues] = useState([]);
	const [filteredIssues, setFilteredIssues] = useState([]);
	const [showDiv, setShowDiv] = useState(false);
	const [selectedScenario, setSelectedScenario] = useState(1);
	const showUserJourney = ('1' === process.env?.REACT_APP_FEAT_SHOW_JOURNEY) ? true : false;
	const [reportHistory, setReportHistory] = useState([]);
	const [openedReport, setOpenedReport] = useState(null);
	const [tableIssues, setTableIssues] = useState({});
	const [reportProgress, setReportProgress] = useState(0);
	const [isLoadingIssues, setIsLoadingIssues] = useState(false);

	const updateReportProgress = (progress) => {
		const progressPct = Math.floor(progress * 100)
		setReportProgress(progressPct);
	};

	const getLatestReport = (packKey, reports) => {
		for (let index = 0; index < reports.length; index++) {
			const reportInfo = reports[index];
			// assume the latest one is first
			if (reportInfo.ConformancePack === packKey) {
				return { PackName: reportInfo.ConformancePack, ...reportInfo };
			}
		}
		return false;
	}

	const filterIssues = (issues, severity) => {
		// filter and set the issue list
		const severityVulnerabilities = ('*' !== severity) ? issues.filter(
			item => (item.Severity === severity && item.Result === "NON-COMPLIANT")
		) : issues.filter(
			item => (item.Result === "NON-COMPLIANT")
		);

		// create the issues in the format the table wants (like RRA)
		var tmpTableIssues = {};
		severityVulnerabilities.forEach(element => {
			if (!tmpTableIssues[element.SecurityTestRule]) {
				tmpTableIssues[element.SecurityTestRule] = [];
			}
			tmpTableIssues[element.SecurityTestRule].push(element);
		});
		setTableIssues(tmpTableIssues);
		setFilteredIssues(severityVulnerabilities);
	};

	const onChangeSeverity = (newSeverity) => {
		filterIssues(allIssues, newSeverity);
		setOpenedSeverity(newSeverity);
	};

	const populateIssueList = async (reports, packKey, severity) => {
		// get the latest report

		const latestReportInfo = getLatestReport(packKey, reports);
		if (latestReportInfo) {
			// get the issue list
			const reportResults = await getResults(latestReportInfo, updateReportProgress);
			setIsLoadingIssues(false);

			setAllIssues(reportResults.rows);
			filterIssues(reportResults.rows, severity);
			setOpenedSeverity(severity);
			setOpenedReport(latestReportInfo);
			setIsOpen(true);
		}
		else {
			console.log("error: no report for", packKey);

		}
	};


	const historySuccess = (res, packKey, severity) => {
		setReportHistory(res);
		populateIssueList(res, packKey, severity);
	};
	const historyFailure = (res) => {
		setIsLoadingIssues(false);
		console.log("history failure:", res);
		// TODO show an error to the user
		setReportHistory([]);
		setIsOpen(false);
		setFilteredIssues([]);
	};

	const onOpenModal = (severity, packKey) => {

		// clear existing data
		setIsOpen(false);
		setFilteredIssues([]);
		setReportProgress(0);
		setIsLoadingIssues(true);

		try {
			// get the latest entry for the selected pack
			if (0 !== reportHistory.length) {
				// used cached eval history
				populateIssueList(reportHistory, packKey, severity);
			}
			else {
				// get the eval history
				client.history((res) => { historySuccess(res, packKey, severity); }, historyFailure);
			}


		} catch (e) {
			setIsOpen(false);
			setFilteredIssues([]);
		}
	};

	const onIssueNewTab = (noSeverity = false) => {
		const queryParams = new URLSearchParams();

		queryParams.set('pack', openedReport?.ConformancePack);
		queryParams.set('reportname', openedReport?.ReportId);
		queryParams.set('numfragments', openedReport?.Fragments);
		queryParams.set('evalid', openedReport?.EvalId);

		navigate(`/topology?${queryParams.toString()}`);
	};

	const [run, setRun] = useState(true);

	const stepsScenario1 = [
		{
			target: '.my-plug-step',
			content: 'Potencial cybersecurity ransomeware risk in financial terms. Click on the "i" to know more details.',
			placement: 'left',
		},
		{
			target: '.my-2-step',
			content: 'Find out more by hover on point.',
			placement: 'left',
		},
		{
			target: '.my-3-step',
			content: 'Click on a “More info” to know more details.',
			placement: 'left',
		},
		{
			target: '.my-4-step',
			content: 'Also you can click on a “More info” here to know more details.',
			placement: 'left',
		},
	];

	const handleJoyrideCallback = data => {
		const { status, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

		if (finishedStatuses.includes(status)) {
			setRun(false);
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowDiv(true);
		}, 5000);

		return () => clearTimeout(timer);
	}, []);

	const joyrideStyles = {
		buttonNext: {
			backgroundColor: '#007bff',
			color: '#fff',
		},
		buttonBack: {
			color: '#007bff',
		},
		buttonSkip: {
			color: '#007bff',
		},
	};

	const handleGetStartedClick = scenario => {
		setSelectedScenario(scenario);
		setRun(!run);
		setShowDiv(false);
	};

	const steps = stepsScenario1;

	useEffect(() => {
		let mounted = true;

		getSummary(
			d => {
				if (!mounted) return;
				const keys = Object.keys(d);
				backFillCompliance(d, keys);
				setData(mapData(d, keys));
				setLoading(false);
			},
			() => {
				if (!mounted) return;

				setError(true);
			}
		);

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			{showUserJourney && (
				<Joyride
					steps={steps}
					run={run}
					continuous
					scrollToFirstStep
					showProgress
					showSkipButton
					callback={handleJoyrideCallback}
					disableOverlay={true}
					disableOverlayClose={true}
					styles={joyrideStyles}
					locale={{
						next: 'Next',
						back: 'Back',
						last: 'Last',
					}}
					tooltipComponent={CustomTooltip}
				/>
			)}
			<Container fluid className="position-relative">
				{showUserJourney && (
					<button onClick={() => setShowDiv(!showDiv)} className="rocket">
						<IconRocket className="RocketSvg" />
					</button>
				)}
				<Header>
					<HeaderTitle>{t('complianceDashboard.title')}</HeaderTitle>
				</Header>

				{error && (
					<Card>
						<CardBody>
							<div className="align-items-center d-flex justify-content-center m-5">
								<FontAwesomeIcon
									icon={faExclamationTriangle}
									className="me-1 text-info"
								/>
								<span className="ms-2">{t('complianceDashboard.runTests')}</span>
							</div>
						</CardBody>
					</Card>
				)}

				{!error && loading && <Loader />}
				{!error && !loading && (
					<Container fluid className="me-0 pr-0 ms-0 pl-0">
						<Row>
							<Row className="position-relative">
								{data.map((item, index) => (
									<Col key={index} xs={4}>
										<ComplianceCounts
											loading={loading}
											error={error}
											info
											data={item.counts}
											packKey={item.packKey}
											packName={item.packName}
											onInfoClick={() => onOpenModal('Critical', item.packKey)}
										/>
									</Col>
								))}
								{showUserJourney && showDiv && (
									<div className="journey">
										<div className="joyWrap">
											<div className="joyWrapText">
												<h2 className="journeyTitle">Choose your journey</h2>
												<h3 className="journeySubTitle">
													Choose what interests you most on TalaSecure Defender
												</h3>
												<p className="journeyText">
													*you can always change your trip by clicking on the
													rocket icon at the top of the page
												</p>
											</div>
											<div className="arrowRocket">
												<IconRocket className="RocketSvgJourney" />
												<ArrowUp />
											</div>
										</div>
										<div className="JourneyBtnWrap">
											<button
												onClick={() => handleGetStartedClick(1)}
												className="joyStart"
											>
												Compliance
											</button>
											<button
												onClick={() => setShowDiv(false)}
												className="joyFinish"
											>
												Finish journey <X />
											</button>
										</div>
									</div>
								)}
								{!!isLoadingIssues && (
									<LoaderTopology
										className="vh-100"
										progressPct={reportProgress}
									/>
								)}
								{!!isOpen && !!filteredIssues.length && (
									<IssueTableInfo
									
										onSelectSeverity={onChangeSeverity}
										data={{
											openedSeverity,
											report: openedReport,
											issues: tableIssues,
										}}
										packName={openedReport.ConformancePack} 
										onNewTab={onIssueNewTab}
										list={filteredIssues}
										onClose={() => {
											setOpenedSeverity(null);
											setFilteredIssues([]);
											setIsOpen(false);
										}}
									/>
								)}
							</Row>
							<Row>
								<ComplianceTrend loading={loading} error={error} results={data} />
							</Row>
							<Header>
								<HeaderTitle>{t('complianceDashboard.criticalResource')}</HeaderTitle>
							</Header>
							<Row>
								<TopRules loading={loading} error={error} results={data} />
							</Row>
							<Row>
								<Row>
									{data.map((item, index) => (
										<Col
											xs={4}
											key={index}
											style={{ marginRight: '0', marginLeft: '0' }}
										>
											<ComplianceCharts
												loading={loading}
												error={error}
												packName={item.packName}
												packKey={item.packKey}
												results={item.charts}
												onInfoClick={() => onOpenModal('Critical', item.packName)}
											/>
										</Col>
									))}
								</Row>
							</Row>
						</Row>
					</Container>
				)}
			</Container>
		</>
	);
}
