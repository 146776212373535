import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Input from '../../components/Form/Input';
import PasswordBar from '../../components/Form/PasswordBar';
import { passwordResetCode } from '../../apiclients/EmailLogin';

const validators = {
	password:
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{8,255}$).*$/,
};

const INITIAL_VALUES = {
	code: '',
	password: '',
	confirmPassword: '',
};

const INITIAL_ERRORS = {
	code: false,
	password: false,
	confirmPassword: false,
};

const ResetCode = () => {
	const [values, setValues] = useState(INITIAL_VALUES);
	const [errors, setErrors] = useState(INITIAL_ERRORS);
	const [email, setEmail] = useState('');
	const [showResetCode, setShowResetCode] = useState(false);
	const [codeError, setCodeError] = useState(false);
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();

	const onChange = e => {
		e.persist();
		if (!e?.target) return;
		const value = e.target?.value;
		const name = e.target?.name;
		setValues(prev => ({ ...prev, [name]: value }));
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const email = queryParams.get('email');
		if (email) {
			setEmail(email);
		} else {
			navigate('/auth/sign-in');
		}
	}, []);

	const validateField = useCallback(
		(name, value) => {
			const isNotEmpty = !!value?.trim();

			let isValid;
			if (name === 'confirmPassword') {
				isValid = value === values.password;
			} else {
				const validator = validators[name];
				isValid = validator ? validator.test(value) : true;
			}

			return isNotEmpty && isValid;
		},
		[values.password]
	);

	const validateForm = useCallback(
		() => Object.keys(values).every(key => validateField(key, values[key])),
		[values, validateField]
	);

	const isValidForm = useMemo(() => validateForm(), [validateForm]);

	const onBlur = e => {
		e.persist();

		const isValid = validateField(e.target.name, e.target.value);
		setErrors(prev => ({ ...prev, [e.target.name]: !isValid }));
	};

	const submitForm = async e => {
		e.preventDefault();

		const isValidForm = validateForm();

		if (!isValidForm) {
			return;
		}

		const body = {
			method: 'reset_password',
			identifier: email || '',
			code: values.code,
			password: values.password,
		};
		await passwordResetCode(body, success, failure);
	};

	const onResendCode = async () => {
		const body = {
			method: 'request_code',
			identifier: values.email,
		};
		const onResetSuccess = () => {
			setValues(INITIAL_VALUES);
			setErrors(INITIAL_ERRORS);
		};
		await passwordResetCode(body, onResetSuccess, console.log);
	};

	const success = () => {
		navigate('/auth/sign-in');
	};
	const failure = () => {
		setShowResetCode(true);
		setCodeError(true);
		setErrors({ ...errors, code: true });
	};

	return (
		<div>
			<div className="m-sm-4">
				<Form className="form reset-form" onSubmit={submitForm}>
					<h2 className="sign-in__title mb-5">{t('forgotPassword.code')}</h2>
					<FormGroup className="mb-6">
						<Input
							placeholder={t('forgotPassword.codePlaceholder')}
							value={values.code}
							isInline
							name="code"
							onChange={onChange}
							onBlur={onBlur}
							valid={!errors.code && !!values.code}
							invalid={errors.code}
						/>
						{codeError && (
							<p className="text-danger text-sm">{t('form.codeError')}</p>
						)}
					</FormGroup>
					<h2 className="sign-in__title mb-2">
						{t('forgotPassword.createNewPassword')}
					</h2>
					<FormGroup className="pt-2">
						<Input
							password
							type="password"
							value={values.password}
							name="password"
							placeholder={t('form.password')}
							onChange={onChange}
							onBlur={onBlur}
							valid={!errors.password && !!values.password}
							invalid={errors.password}
						/>
						<PasswordBar value={values.password} />
						<h2 className="sign-in__title mb-2 text-lg">
							{t('forgotPassword.confirmPassword')}
						</h2>
						<Input
							password
							type="password"
							value={values.confirmPassword}
							name="confirmPassword"
							placeholder={t('form.confirmPassword')}
							onChange={onChange}
							onBlur={onBlur}
							valid={!errors.confirmPassword && !!values.confirmPassword}
							invalid={errors.confirmPassword}
						/>
					</FormGroup>
					<div className="text-center mt-3">
						<Button
							color="primary"
							size="lg"
							className="auth-button auth-button_blue"
							disabled={!isValidForm}>
							{t('button.saveChanges')}
						</Button>
						{showResetCode && (
							<Button
								color="primary"
								size="lg"
								className="auth-button mt-3"
								onClick={onResendCode}>
								{t('button.resetCode')}
							</Button>
						)}
					</div>
					<div className="mx-auto mt-3 text-center">
						<Link to="/auth/sign-in">{t('button.signIn')}</Link>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default ResetCode;
