import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Container, Row, Spinner } from 'reactstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const LoaderTopology = ({ className, isFull = true, progressPct }) => {

	return (
		<Container
			fluid
			className={classNames('d-flex', {
				[className]: className,
				'vh-75': isFull,
			})}>
			<Row className="d-flex justify-content-center align-self-center w-200 text-center">
				<div className="w-200" style={{ width: '200px' }}>
					{/* <Spinner color="primary" size={200} /> */}
					<CircularProgressbar
						value={progressPct}
						text={`${progressPct}%`}
						styles={buildStyles({
							textSize: '20px',
							pathTransitionDuration: 0.5, // Анімація швидкості завантаження
							pathColor: `#007bff`, // Колір прогресу
							textColor: '#007bff', // Колір тексту
							trailColor: '#d6d6d6', // Колір фону
							...(progressPct === 100 && {
								pathColor: `#28a745`, // Колір прогресу зелений, коли прогрес 100%
								textColor: '#28a745', // Колір тексту зелений, коли прогрес 100%
							}),
						})}
					/>
				</div>
				<h2
					className="visCont"
					style={{ color: progressPct === 100 ? '#28a745' : '#007bff' }}>
					{progressPct === 100
						? 'Loading completed'
						: 'Loading visualization content...'}
				</h2>

				<h2 className="">Please wait a couple of minutes</h2>
			</Row>
		</Container>
	);
};

LoaderTopology.defaultProps = {
	className: '',
};

export default LoaderTopology;
