import { Alert, Form, FormFeedback, FormGroup } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PurchaseBtn from './PurchaseBtn';
import Input from '../../components/Form/Input';
import VerifyResult from './VerifyResult';
import {
	getNewauthLandingUri,
	login,
	getLoggedInUserToken,
	getUserDefaults,
	verifyEmail,
	resendEmail,
} from '../../apiclients/EmailLogin';

import { ReactComponent as GoogleSvg } from '../../assets/img/google.svg';
import useLoginWithKey from './hooks/useLoginWithKey';
import { useDispatch } from 'react-redux';
import {
	setIsAuthenticated,
	setUser,
} from '../../redux/actions/applicationActions';
import {
	setUserToken,
	clearApplicationStorage,
	clearCurrentUser,
	setUserAccessToken,
} from '../../services/AuthService';
import EmailConfirmationPopup from './EmailConfirmationPopup';
import { setAccountLimits } from '../../services/AuthService';

const SignInResult = ({ color, isOpen, text }) => (
	<Alert color={color} isOpen={isOpen} className="auth-alert">
		{text}
	</Alert>
);

const SignIn = () => {
	const { t } = useTranslation();

	const [values, setValues] = useState({
		email: '',
		password: '',
	});
	const [userVerified, setUserVerified] = useState(false);
	const [userVerifyError, setUserVerifyError] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [showSSOError, setShowSSOError] = useState(false);
	const [ssoErrorMsg, setSSOErrorMsg] = useState('');
	const [showLoginError, setShowLoginError] = useState(false);
	const [loginErrorMsg, setLoginErrorMsg] = useState('');

	const [errors, setErrors] = useState({ email: false, password: false });
	const navigate = useNavigate();
	const loginWithKey = useLoginWithKey();
	const dispatch = useDispatch();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const userEmail = queryParams.get('email');
	const userCode = queryParams.get('code');
	const ssoError = queryParams.get('error');

	clearApplicationStorage();

	const onChange = e => {
		e.persist();
		if (!e?.target) return;
		const value = e.target.value;
		setValues(prev => ({ ...prev, [e.target?.name]: value }));
		const isValid = !!value?.trim();

		setErrors(prev => ({ ...prev, [e.target?.name]: !isValid }));
	};

	const loginSuccess = (res, token, keyId) => {
		if (res.error) {
			// TODO send log error here
			console.log('Login error in success handler:', res.error);
			clearCurrentUser();
			dispatch(setUser('no-key'));
			navigate(`/admin/remote-landing?token=${token}&failedKeyId=${keyId}`);
		}
	};

	const loginError = (res, token, failedKeyId) => {
		console.log('loginError: res=', res);
		navigate(`/admin/remote-landing?token=${token}&failedKeyId=${failedKeyId}`);
	};

	const onDefaultKeySuccess = (res, token) => {
		const defaultKey = res.userInfo?.defaultKey;
		if (res && res.accountInfo) {
			setAccountLimits(res.accountInfo);
		}
		if (defaultKey) {
			const data = {
				token,
				keyId: defaultKey.keyId,
				domain: values.email,
				region: defaultKey.region,
				regions: [],
				provider: defaultKey.provider,
			};
			dispatch(setUser(defaultKey));

			loginWithKey(
				data,
				res => {
					loginError(res, token, defaultKey.keyId);
				},
				true,
				res => {
					loginSuccess(res, token, defaultKey.keyId);
				}
			);
		} else {
			dispatch(setUser('no-key'));

			navigate('/admin/remote-landing?token=' + token);
		}
	};

	const onDefaultKeyError = res => {
		console.log('defaultKeyError:', res);
	};

	const tokenSuccess = values => {
		const token = values.token;
		if (!token) return;
		setUserToken(token);
		console.log('tokenSuccess:', token);
		getUserDefaults(
			token,
			res => onDefaultKeySuccess(res, token),
			onDefaultKeyError
		);
	};
	const tokenFailure = result => {
		// handle unactivated user
		if (
			403 === result.statusCode &&
			'Signup code not valid' === result.responseError.error
		) {
			setLoginErrorMsg(t('form.loginInvalidCode'));
			setShowLoginError(true);
		} else if (
			403 === result.statusCode &&
			'Account has expired' === result.responseError.error
		) {
			setLoginErrorMsg(t('form.accountExpired'));
			setShowLoginError(true);
		} else {
			setLoginErrorMsg(t('form.loginOtherError'));

			console.log('generic signin error:', result, result.response);
			setErrors({ email: true, password: true });
		}
	};

	const signinError = result => {
		// handle unactivated user
		if (
			401 === result.statusCode &&
			'User Not Confirmed' === result.responseError.error
		) {
			console.log(
				'User was not activated. Resending validation for:',
				values.email
			);
			resendEmail(
				{ email: values.email },
				() => {
					setConfirmationOpen(true);
				},
				() => {
					console.log('Error when resending activation email');
				}
			);
		} else {
			console.log('generic signin error:', result, result.response);
			setErrors({ email: true, password: true });
		}
	};

	const signinSuccess = values => {
		dispatch(setIsAuthenticated(true));
		// Store the Cognito auth token
		setUserAccessToken({
			AccessToken: values.AccessToken,
			IdToken: values.IdToken,
		});

		getLoggedInUserToken(
			{
				IdToken: values.IdToken,
			},
			tokenSuccess,
			tokenFailure
		);
	};

	const submitForm = async e => {
		e.preventDefault();

		login(values, signinSuccess, signinError);
	};

	useEffect(() => {
		const verifySuccess = res => {
			if (
				res &&
				res.message &&
				('User Verified' === res.message ||
					'User already verified' === res.message)
			) {
				console.log('verify success:', res);
				setUserVerified(true);
				setUserVerifyError(false);
				setValues({
					email: userEmail,
					password: '',
				});
			} else {
				console.log('verify error:', res);
				setUserVerifyError(true);
				setUserVerified(false);
			}
		};

		const verifyError = () => {
			console.log('verify error');
			setUserVerifyError(true);
			setUserVerified(false);
		};

		if (userEmail && userCode) {
			console.log('Verifying email!');
			const payload = {
				email: userEmail,
				code: userCode,
			};
			verifyEmail(payload, verifySuccess, verifyError);
		}
	}, [userEmail, userCode]);

	useEffect(() => {
		if (ssoError) {
			const errorData = JSON.parse(ssoError);
			const ssoErrorMessage = errorData.error || false;
			//console.log("Error message:", ssoErrorMessage);
			if ('Signup code not valid' === ssoErrorMessage) {
				setShowSSOError(true);
				setSSOErrorMsg(t('form.ssoInvalidCode'));
			} else if ('Account has expired' === ssoErrorMessage) {
				setShowSSOError(true);
				setSSOErrorMsg(t('form.accountExpired'));
			} else {
				console.log('Raw error from identity provider:', ssoErrorMessage);
				setShowSSOError(true);
				setSSOErrorMsg(t('form.ssoOtherError'));
			}
		}
	}, [ssoError, t]);

	const resendConfirmationOnly = e => {
		e.preventDefault();
		setUserVerifyError(false);

		resendEmail(
			{ email: values.email },
			() => {
				setConfirmationOpen(true);
			},
			() => {
				console.log('Error when resending activation email');
			}
		);
		setUserVerifyError(false);
	};

	return (
		<div className="sign-in">
			<div className="text-center mt-4">
				<h2 className="sign-in__title">{t('signIn.title')}</h2>
				<p className="w-75 mx-auto sign-in__subtitle">
					{t('signIn.description')}
				</p>
			</div>

			<div className="m-sm-4">
				<SignInResult color="danger" text={ssoErrorMsg} isOpen={showSSOError} />
				<SignInResult
					color="danger"
					text={loginErrorMsg}
					isOpen={showLoginError}
				/>
				<SignInResult
					color="danger"
					text={t('signIn.errorMessage')}
					isOpen={errors.email || errors.password}
				/>

				<VerifyResult
					color="#59A919"
					title={t('signIn.userVerified')}
					subTitle={t('signIn.logIn')}
					isOpen={userVerified}
				/>

				<VerifyResult
					color="#E72538"
					title={t('signIn.emailVerifyFail')}
					subTitle={t('signIn.resendEmail')}
					isOpen={userVerifyError}
				/>

				<Form onSubmit={submitForm} className="form reset-form">
					<FormGroup>
						<Input
							type="email"
							name="email"
							placeholder={t('form.email')}
							onChange={onChange}
							value={values.email}
							valid={!errors.email && !!values.email}
							invalid={errors.email}
						/>
						{errors.email && (
							<FormFeedback className="auth-feedback">
								{t('form.emailRequired')}
							</FormFeedback>
						)}
					</FormGroup>
					{userVerifyError ? (
						<div className="text-center mt-4">
							<input
								type="submit"
								disabled={!values.email}
								className="btn btn-primary btn-lg auth-button"
								value={t('signUp.popupResendEmail')}
								onClick={resendConfirmationOnly}
							/>
						</div>
					) : (
						<>
							<FormGroup>
								<Input
									type="password"
									onChange={onChange}
									name="password"
									password
									value={values.password}
									valid={!errors.password && !!values.password}
									invalid={errors.password}
									placeholder={t('form.password')}
								/>
								{errors.password && (
									<FormFeedback className="auth-feedback">
										{t('form.passwordRequired')}
									</FormFeedback>
								)}
							</FormGroup>
							<Link className='textForgot' to="/auth/reset-password">{t('signIn.forgotPassword')}</Link>
							<div className="text-center mt-4">
								<input
									type="submit"
									disabled={!values.email || !values.password}
									className="btn btn-primary btn-lg auth-button"
									value={t('button.signIn')}
								/>
							</div>
									{/* <div className='divider'>
							<p className="my-3 mx-auto text-center divider-text">
								{t('button.or')}
							</p>
							</div> */}
							<div class="divider">
  <span class="divider-text">{t('button.or')}</span>
</div>
							<div className="mx-auto">
								<a
									className="google-button"
									href={getNewauthLandingUri('Google')}
								>
									<GoogleSvg /> <span>{t('signIn.google')}</span>
								</a>
							</div>
						</>
					)}
					<div className="d-flex justify-content-between mt-3 text-center">
						<Link to="/auth/live-demo/sign-up">{t('button.demo')}</Link>
						<Link to="/auth/free-trial/sign-up">{t('button.trial')}</Link>
						
					</div>
				</Form>
				<PurchaseBtn />
			</div>
			<EmailConfirmationPopup
				isOpen={confirmationOpen}
				email={values.email}
				onToggle={v => setConfirmationOpen(false)}
			/>
		</div>
	);
};

export default SignIn;
