let index = 0;

const getIndex = () => index++;

export default {
  "testCases": [
    {
      "id": "fn-0",
      "value": "Access Control (AC)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C001",
        "C002",
        "C003",
        "C004"
      ]
    },
    {
      "id": "fn-1",
      "value": "Asset Management (AM)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C005",
        "C006"
      ]
    },
    {
      "id": "fn-2",
      "value": "Audit & Accountability (AU)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C007",
        "C008",
        "C009",
        "C010"
      ]
    },
    {
      "id": "fn-3",
      "value": "Awareness & Training (AT)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C011",
        "C012"
      ]
    },
    {
      "id": "fn-4",
      "value": "Configuration Management (CM)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C014"
      ]
    },
    {
      "id": "fn-5",
      "value": "Identification & Authentication (IA)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C015"
      ]
    },
    {
      "id": "fn-6",
      "value": "Incident Response (IR)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C016",
        "C017",
        "C018",
        "C019",
        "C020"
      ]
    },
    {
      "id": "fn-7",
      "value": "Maintenance (MA)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C021"
      ]
    },
    {
      "id": "fn-8",
      "value": "Media Protection (MP)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C022",
        "C023",
        "C024",
        "C025"
      ]
    },
    {
      "id": "fn-9",
      "value": "Personnel Security (PS)",
      "fgColor": "#e9ecef",
      "bgColor": "#4da6ff",
      "description": [
        ""
      ],
      "categories": [
        "C026",
        "C027"
      ]
    },
    {
      "id": "fn-10",
      "value": "Physical Protection (PE)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C028"
      ]
    },
    {
      "id": "fn-11",
      "value": "Recovery (RE)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C029",
        "C030"
      ]
    },
    {
      "id": "fn-12",
      "value": "Risk Management (RM)",
      "fgColor": "#e9ecef",
      "bgColor": "#4da6ff",
      "description": [
        ""
      ],
      "categories": [
        "C031",
        "C032",
        "C033"
      ]
    },
    {
      "id": "fn-13",
      "value": "Security Assessment (CA)",
      "fgColor": "#e9ecef",
      "bgColor": "#4da6ff",
      "description": [
        ""
      ],
      "categories": [
        "C034",
        "C035",
        "C036"
      ]
    },
    {
      "id": "fn-14",
      "value": "Situational Awareness (SA)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C037"
      ]
    },
    {
      "id": "fn-15",
      "value": "System & Communications Protection (SC)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C038",
        "C039"
      ]
    },
    {
      "id": "fn-16",
      "value": "System & Information Integrity (SI)",
      "fgColor": "#e9ecef",
      "bgColor": "#a31aff",
      "description": [
        ""
      ],
      "categories": [
        "C040",
        "C041",
        "C042",
        "C043"
      ]
    }
  ],
  "categories": {
    "C001": {
      "parent": "fn-0",
      "description": "Establish system access requirements",
      "name": "C001",
      "subcategories": [
        "AC.2.005",
        "AC.2.006"
      ],
      "id": "C001"
    },
    "C002": {
      "parent": "fn-0",
      "description": "Control internal system access",
      "name": "C002",
      "subcategories": [
        "AC.2.009",
        "AC.2.010",
        "AC.2.011",
        "AC.3.012",
        "AC.3.019",
        "AC.3.020",
        "AC.4.023",
        "AC.4.025",
        "AC.5.024"
      ],
      "id": "C002"
    },
    "C003": {
      "parent": "fn-0",
      "description": "Control remote system access",
      "name": "C003",
      "subcategories": [
        "AC.3.014",
        "AC.4.032"
      ],
      "id": "C003"
    },
    "C004": {
      "parent": "fn-0",
      "description": "Limit data access to authorized users and processes",
      "name": "C004",
      "subcategories": [
        "AC.1.004",
        "AC.3.022"
      ],
      "id": "C004"
    },
    "C005": {
      "parent": "fn-1",
      "description": "Identify and document assets",
      "name": "C005",
      "subcategories": [
        "AM.3.036"
      ],
      "id": "C005"
    },
    "C006": {
      "parent": "fn-1",
      "description": "Manage asset inventory",
      "name": "C006",
      "subcategories": [
        "AM.4.226"
      ],
      "id": "C006"
    },
    "C007": {
      "parent": "fn-2",
      "description": "Define audit requirements",
      "name": "C007",
      "subcategories": [
        "AU.3.045"
      ],
      "id": "C007"
    },
    "C008": {
      "parent": "fn-2",
      "description": "Perform auditing",
      "name": "C008",
      "subcategories": [
        "AU.2.043",
        "AU.5.055"
      ],
      "id": "C008"
    },
    "C009": {
      "parent": "fn-2",
      "description": "Identify and protect audit information",
      "name": "C009",
      "subcategories": [
        "AU.3.050"
      ],
      "id": "C009"
    },
    "C010": {
      "parent": "fn-2",
      "description": "Review and manage audit logs",
      "name": "C010",
      "subcategories": [
        "AU.2.044",
        "AU.3.051",
        "AU.3.052",
        "AU.4.053",
        "AU.4.054"
      ],
      "id": "C010"
    },
    "C011": {
      "parent": "fn-3",
      "description": "Conduct security awareness activities",
      "name": "C011",
      "subcategories": [
        "AT.2.056",
        "AT.3.058",
        "AT.4.059",
        "AT.4.060"
      ],
      "id": "C011"
    },
    "C012": {
      "parent": "fn-3",
      "description": "Conduct training",
      "name": "C012",
      "subcategories": [
        "AT.2.057"
      ],
      "id": "C012"
    },
    "C014": {
      "parent": "fn-4",
      "description": "Perform configuration and change management",
      "name": "C014",
      "subcategories": [
        "CM.2.066",
        "CM.3.067",
        "CM.4.073",
        "CM.5.074"
      ],
      "id": "C014"
    },
    "C015": {
      "parent": "fn-5",
      "description": "Grant access to authenticated entities",
      "name": "C015",
      "subcategories": [
        "IA.1.076",
        "IA.2.080",
        "IA.2.082",
        "IA.3.085",
        "IA.3.086"
      ],
      "id": "C015"
    },
    "C016": {
      "parent": "fn-6",
      "description": "Plan incident response",
      "name": "C016",
      "subcategories": [
        "IR.4.100",
        "IR.5.106"
      ],
      "id": "C016"
    },
    "C017": {
      "parent": "fn-6",
      "description": "Detect and report events",
      "name": "C017",
      "subcategories": [
        "IR.2.094"
      ],
      "id": "C017"
    },
    "C018": {
      "parent": "fn-6",
      "description": "Develop and implement a response to a declared incident",
      "name": "C018",
      "subcategories": [
        "IR.2.096",
        "IR.3.098",
        "IR.4.101",
        "IR.5.102",
        "IR.5.108"
      ],
      "id": "C018"
    },
    "C019": {
      "parent": "fn-6",
      "description": "Perform post incident reviews",
      "name": "C019",
      "subcategories": [
        "IR.2.097"
      ],
      "id": "C019"
    },
    "C020": {
      "parent": "fn-6",
      "description": "Test incident response",
      "name": "C020",
      "subcategories": [
        "IR.3.099",
        "IR.5.110"
      ],
      "id": "C020"
    },
    "C021": {
      "parent": "fn-7",
      "description": "Manage maintenance",
      "name": "C021",
      "subcategories": [
        "MA.2.111",
        "MA.2.112",
        "MA.2.113",
        "MA.2.114",
        "MA.3.115",
        "MA.3.116"
      ],
      "id": "C021"
    },
    "C022": {
      "parent": "fn-8",
      "description": "Identify and mark media",
      "name": "C022",
      "subcategories": [
        "MP.3.122"
      ],
      "id": "C022"
    },
    "C023": {
      "parent": "fn-8",
      "description": "Protect and control media",
      "name": "C023",
      "subcategories": [
        "MP.2.119",
        "MP.2.120",
        "MP.2.121",
        "MP.3.123"
      ],
      "id": "C023"
    },
    "C024": {
      "parent": "fn-8",
      "description": "Sanitize media",
      "name": "C024",
      "subcategories": [
        "MP.1.118"
      ],
      "id": "C024"
    },
    "C025": {
      "parent": "fn-8",
      "description": "Protect media during transport",
      "name": "C025",
      "subcategories": [
        "MP.3.124",
        "MP.3.125"
      ],
      "id": "C025"
    },
    "C026": {
      "parent": "fn-9",
      "description": "Screen personnel",
      "name": "C026",
      "subcategories": [
        "PS.2.127"
      ],
      "id": "C026"
    },
    "C027": {
      "parent": "fn-9",
      "description": "Protect federal contract information during personnel actions",
      "name": "C027",
      "subcategories": [
        "PS.2.128"
      ],
      "id": "C027"
    },
    "C028": {
      "parent": "fn-10",
      "description": "Limit physical access",
      "name": "C028",
      "subcategories": [
        "PE.1.131",
        "PE.1.132",
        "PE.1.133",
        "PE.1.134",
        "PE.2.135",
        "PE.3.136"
      ],
      "id": "C028"
    },
    "C029": {
      "parent": "fn-11",
      "description": "Manage back-ups",
      "name": "C029",
      "subcategories": [
        "RE.2.138",
        "RE.5.140"
      ],
      "id": "C029"
    },
    "C030": {
      "parent": "fn-11",
      "description": "Manage information security continuity",
      "name": "C030",
      "subcategories": [],
      "id": "C030"
    },
    "C031": {
      "parent": "fn-12",
      "description": "Identify and evaluate risk",
      "name": "C031",
      "subcategories": [
        "RM.4.149",
        "RM.4.150",
        "RM.4.151"
      ],
      "id": "C031"
    },
    "C032": {
      "parent": "fn-12",
      "description": "Manage risk",
      "name": "C032",
      "subcategories": [
        "RM.3.146",
        "RM.3.147",
        "RM.5.152",
        "RM.5.155"
      ],
      "id": "C032"
    },
    "C033": {
      "parent": "fn-12",
      "description": "Manage supply chain risk",
      "name": "C033",
      "subcategories": [
        "RM.4.148"
      ],
      "id": "C033"
    },
    "C034": {
      "parent": "fn-13",
      "description": "Develop and manage a system security plan",
      "name": "C034",
      "subcategories": [
        "CA.2.157",
        "CA.4.163"
      ],
      "id": "C034"
    },
    "C035": {
      "parent": "fn-13",
      "description": "Define and manage controls",
      "name": "C035",
      "subcategories": [
        "CA.2.159",
        "CA.4.164",
        "CA.4.227"
      ],
      "id": "C035"
    },
    "C036": {
      "parent": "fn-13",
      "description": "Perform code reviews",
      "name": "C036",
      "subcategories": [
        "CA.3.162"
      ],
      "id": "C036"
    },
    "C037": {
      "parent": "fn-14",
      "description": "Implement threat monitoring",
      "name": "C037",
      "subcategories": [
        "SA.3.169",
        "SA.4.171",
        "SA.4.173"
      ],
      "id": "C037"
    },
    "C038": {
      "parent": "fn-15",
      "description": "Define security requirements for systems and communications",
      "name": "C038",
      "subcategories": [
        "SC.2.178",
        "SC.3.182",
        "SC.3.184",
        "SC.3.186",
        "SC.3.188",
        "SC.3.189",
        "SC.4.197",
        "SC.5.198",
        "SC.4.228",
        "SC.5.230"
      ],
      "id": "C038"
    },
    "C039": {
      "parent": "fn-15",
      "description": "Control communications at system boundaries",
      "name": "C039",
      "subcategories": [
        "SC.3.192",
        "SC.3.193",
        "SC.4.199",
        "SC.4.202",
        "SC.5.208",
        "SC.4.229"
      ],
      "id": "C039"
    },
    "C040": {
      "parent": "fn-16",
      "description": "Identify and manage information system flaws",
      "name": "C040",
      "subcategories": [
        "SI.2.214",
        "SI.4.221"
      ],
      "id": "C040"
    },
    "C041": {
      "parent": "fn-16",
      "description": "Identify malicious content",
      "name": "C041",
      "subcategories": [
        "SI.5.222"
      ],
      "id": "C041"
    },
    "C042": {
      "parent": "fn-16",
      "description": "Perform network and system monitoring",
      "name": "C042",
      "subcategories": [
        "SI.3.218",
        "SI.5.223"
      ],
      "id": "C042"
    },
    "C043": {
      "parent": "fn-16",
      "description": "Implement advanced email protections",
      "name": "C043",
      "subcategories": [
        "SI.3.219",
        "SI.3.220"
      ],
      "id": "C043"
    }
  },
  "subcategories": {
    "AC.2.005": {
      "parent": "C001",
      "description": "Provide privacy and security notices consistent with applicable Controlled Unclassified Information (CUI) rules.",
      "name": "AC.2.005",
      "index": getIndex()
    },
    "AC.2.006": {
      "parent": "C001",
      "description": "Limit use of portable storage devices on external systems.",
      "name": "AC.2.006",
      "index": getIndex()
    },
    "AC.2.009": {
      "parent": "C002",
      "description": "Limit unsuccessful logon attempts.",
      "name": "AC.2.009",
      "index": getIndex()
    },
    "AC.2.010": {
      "parent": "C002",
      "description": "Use session lock with pattern-hiding displays to prevent access and viewing of data after a period of inactivity.",
      "name": "AC.2.010",
      "index": getIndex()
    },
    "AC.2.011": {
      "parent": "C002",
      "description": "Authorize wireless access prior to allowing such connections.",
      "name": "AC.2.011",
      "index": getIndex()
    },
    "AC.3.012": {
      "parent": "C002",
      "description": "Protect wireless access using authentication and encryption.",
      "name": "AC.3.012",
      "index": getIndex()
    },
    "AC.3.019": {
      "parent": "C002",
      "description": "Terminate (automatically) user sessions after a defined condition.",
      "name": "AC.3.019",
      "index": getIndex()
    },
    "AC.3.020": {
      "parent": "C002",
      "description": "Control connection of mobile devices.",
      "name": "AC.3.020",
      "index": getIndex()
    },
    "AC.4.023": {
      "parent": "C002",
      "description": "Control information flows between security domains on connected systems.",
      "name": "AC.4.023",
      "index": getIndex()
    },
    "AC.4.025": {
      "parent": "C002",
      "description": "Periodically review and update CUI program access permissions.",
      "name": "AC.4.025",
      "index": getIndex()
    },
    "AC.5.024": {
      "parent": "C002",
      "description": "Identify and mitigate risk associated with unidentified wireless access points connected to the network.",
      "name": "AC.5.024",
      "index": getIndex()
    },
    "AC.3.014": {
      "parent": "C003",
      "description": "Employ cryptographic mechanisms to protect the confidentiality of remote access sessions.",
      "name": "AC.3.014",
      "index": getIndex()
    },
    "AC.4.032": {
      "parent": "C003",
      "description": "Restrict remote network access based on organizational defined risk factors such as time of day, location of access, physical location, network connection state and measured properties of the current user and role.",
      "name": "AC.4.032",
      "index": getIndex()
    },
    "AC.1.004": {
      "parent": "C004",
      "description": "Control information posted or processed on publicly accessible information systems.",
      "name": "AC.1.004",
      "index": getIndex()
    },
    "AC.3.022": {
      "parent": "C004",
      "description": "Encrypt CUI on mobile devices and mobile computing platforms.",
      "name": "AC.3.022",
      "index": getIndex()
    },
    "AM.3.036": {
      "parent": "C005",
      "description": "Define procedures for the handling of CUI data.",
      "name": "AM.3.036",
      "index": getIndex()
    },
    "AM.4.226": {
      "parent": "C006",
      "description": "Employ automated capability to discover and identify systems with specific component attributes (e.g., firmware level, OS type) within your inventory.",
      "name": "AM.4.226",
      "index": getIndex()
    },
    "AU.3.045": {
      "parent": "C007",
      "description": "Review and update logged events.",
      "name": "AU.3.045",
      "index": getIndex()
    },
    "AU.2.043": {
      "parent": "C008",
      "description": "Provide a system capability that compares and synchronizes internal system clocks with an authoritative source to generate time stamps for audit records.",
      "name": "AU.2.043",
      "index": getIndex()
    },
    "AU.5.055": {
      "parent": "C008",
      "description": "Identify assets not reporting audit logs and assure appropriate organizationally defined systems are logging.",
      "name": "AU.5.055",
      "index": getIndex()
    },
    "AU.3.050": {
      "parent": "C009",
      "description": "Limit management of audit logging functionality to a subset of privileged users.",
      "name": "AU.3.050",
      "index": getIndex()
    },
    "AU.2.044": {
      "parent": "C010",
      "description": "Review audit logs.",
      "name": "AU.2.044",
      "index": getIndex()
    },
    "AU.3.051": {
      "parent": "C010",
      "description": "Correlate audit record review, analysis and reporting processes for investigation and response to indications of unlawful, unauthorized, suspicious or unusual activity.",
      "name": "AU.3.051",
      "index": getIndex()
    },
    "AU.3.052": {
      "parent": "C010",
      "description": "Provide audit record reduction and report generation to support on-demand analysis and reporting.",
      "name": "AU.3.052",
      "index": getIndex()
    },
    "AU.4.053": {
      "parent": "C010",
      "description": "Automate analysis of audit logs to identify and act on critical indicators (TTPs) and/or organizationally-defined suspicious activity.",
      "name": "AU.4.053",
      "index": getIndex()
    },
    "AU.4.054": {
      "parent": "C010",
      "description": "Review audit information for broad activity in addition to per-machine activity.",
      "name": "AU.4.054",
      "index": getIndex()
    },
    "AT.2.056": {
      "parent": "C011",
      "description": "Ensure that managers, system administrators and users of organizational systems are made aware of the security risks associated with their activities and of the applicable policies, standards and procedures related to the security of those systems.",
      "name": "AT.2.056",
      "index": getIndex()
    },
    "AT.3.058": {
      "parent": "C011",
      "description": "Provide security awareness training on recognizing and reporting potential indicators of insider threat.",
      "name": "AT.3.058",
      "index": getIndex()
    },
    "AT.4.059": {
      "parent": "C011",
      "description": "Provide awareness training focused on recognizing and responding to threats from social engineering, advanced persistent threat actors, breaches and suspicious behaviors; update the training at least annually or when there are significant changes to the threat.",
      "name": "AT.4.059",
      "index": getIndex()
    },
    "AT.4.060": {
      "parent": "C011",
      "description": "Include practical exercises in awareness training that are aligned with current threat scenarios and provide feedback to individuals involved in the training.",
      "name": "AT.4.060",
      "index": getIndex()
    },
    "AT.2.057": {
      "parent": "C012",
      "description": "Ensure that personnel are trained to carry out their assigned information security- related duties and responsibilities.",
      "name": "AT.2.057",
      "index": getIndex()
    },
    "CM.2.066": {
      "parent": "C014",
      "description": "Analyze the security impact of changes prior to implementation.",
      "name": "CM.2.066",
      "index": getIndex()
    },
    "CM.3.067": {
      "parent": "C014",
      "description": "Define, document, approve and enforce physical and logical access restrictions associated with changes to organizational systems.",
      "name": "CM.3.067",
      "index": getIndex()
    },
    "CM.4.073": {
      "parent": "C014",
      "description": "Employ application whitelisting and an application vetting process for systems identified by the organization.",
      "name": "CM.4.073",
      "index": getIndex()
    },
    "CM.5.074": {
      "parent": "C014",
      "description": "Verify the integrity and correctness of security critical or essential software as defined by the organization (e.g., roots of trust, formal verification or cryptographic signatures).",
      "name": "CM.5.074",
      "index": getIndex()
    },
    "IA.1.076": {
      "parent": "C015",
      "description": "Identify information system users, processes acting on behalf of users or devices.",
      "name": "IA.1.076",
      "index": getIndex()
    },
    "IA.2.080": {
      "parent": "C015",
      "description": "Allow temporary password use for system logons with an immediate change to a permanent password.",
      "name": "IA.2.080",
      "index": getIndex()
    },
    "IA.2.082": {
      "parent": "C015",
      "description": "Obscure feedback of authentication information.",
      "name": "IA.2.082",
      "index": getIndex()
    },
    "IA.3.085": {
      "parent": "C015",
      "description": "Prevent the reuse of identifiers for a defined period.",
      "name": "IA.3.085",
      "index": getIndex()
    },
    "IA.3.086": {
      "parent": "C015",
      "description": "Disable identifiers after a defined period of inactivity.",
      "name": "IA.3.086",
      "index": getIndex()
    },
    "IR.4.100": {
      "parent": "C016",
      "description": "Use knowledge of attacker tactics, techniques and procedures in incident response planning and execution.",
      "name": "IR.4.100",
      "index": getIndex()
    },
    "IR.5.106": {
      "parent": "C016",
      "description": "In response to cyber incidents, utilize forensic data gathering across impacted systems, ensuring the secure transfer and protection of forensic data.",
      "name": "IR.5.106",
      "index": getIndex()
    },
    "IR.2.094": {
      "parent": "C017",
      "description": "Analyze and triage events to support event resolution and incident declaration.",
      "name": "IR.2.094",
      "index": getIndex()
    },
    "IR.2.096": {
      "parent": "C018",
      "description": "Develop and implement responses to declared incidents according to pre- defined procedures.",
      "name": "IR.2.096",
      "index": getIndex()
    },
    "IR.3.098": {
      "parent": "C018",
      "description": "Track, document and report incidents to designated officials and/or authorities both internal and external to the organization.",
      "name": "IR.3.098",
      "index": getIndex()
    },
    "IR.4.101": {
      "parent": "C018",
      "description": "Establish and maintain a Security Operations Center (SOC) capability that facilitates a 24/7 response capability.",
      "name": "IR.4.101",
      "index": getIndex()
    },
    "IR.5.102": {
      "parent": "C018",
      "description": "Use a combination of manual and automated, real-time response to anomalous activities that match incident patterns.",
      "name": "IR.5.102",
      "index": getIndex()
    },
    "IR.5.108": {
      "parent": "C018",
      "description": "Establish and maintain a Cyber Incident Response Team (CIRT) that can investigate an issue physically or virtually at any location within 24 hours.",
      "name": "IR.5.108",
      "index": getIndex()
    },
    "IR.2.097": {
      "parent": "C019",
      "description": "Perform root cause analysis on incidents to determine underlying causes.",
      "name": "IR.2.097",
      "index": getIndex()
    },
    "IR.3.099": {
      "parent": "C020",
      "description": "Test the organizational incident response capability.",
      "name": "IR.3.099",
      "index": getIndex()
    },
    "IR.5.110": {
      "parent": "C020",
      "description": "Perform unannounced operational exercises to demonstrate technical and procedural responses.",
      "name": "IR.5.110",
      "index": getIndex()
    },
    "MA.2.111": {
      "parent": "C021",
      "description": "Perform maintenance on organizational systems.",
      "name": "MA.2.111",
      "index": getIndex()
    },
    "MA.2.112": {
      "parent": "C021",
      "description": "Provide controls on the tools, techniques, mechanisms and personnel used to conduct system maintenance.",
      "name": "MA.2.112",
      "index": getIndex()
    },
    "MA.2.113": {
      "parent": "C021",
      "description": "Require multifactor authentication to establish nonlocal maintenance sessions via external network connections and terminate such connections when nonlocal maintenance is complete.",
      "name": "MA.2.113",
      "index": getIndex()
    },
    "MA.2.114": {
      "parent": "C021",
      "description": "Supervise the maintenance activities of personnel without required access authorization.",
      "name": "MA.2.114",
      "index": getIndex()
    },
    "MA.3.115": {
      "parent": "C021",
      "description": "Ensure equipment removed for off-site maintenance is sanitized of any CUI.",
      "name": "MA.3.115",
      "index": getIndex()
    },
    "MA.3.116": {
      "parent": "C021",
      "description": "Check media containing diagnostic and test programs for malicious code before the media are used in organizational systems.",
      "name": "MA.3.116",
      "index": getIndex()
    },
    "MP.3.122": {
      "parent": "C022",
      "description": "Mark media with necessary CUI markings and distribution limitations.",
      "name": "MP.3.122",
      "index": getIndex()
    },
    "MP.2.119": {
      "parent": "C023",
      "description": "Protect (e.g., physically control and securely store) system media containing Federal Contract Information, both paper and digital.",
      "name": "MP.2.119",
      "index": getIndex()
    },
    "MP.2.120": {
      "parent": "C023",
      "description": "Limit access to CUI on system media to authorized users.",
      "name": "MP.2.120",
      "index": getIndex()
    },
    "MP.2.121": {
      "parent": "C023",
      "description": "Control the use of removable media on system components.",
      "name": "MP.2.121",
      "index": getIndex()
    },
    "MP.3.123": {
      "parent": "C023",
      "description": "Prohibit the use of portable storage devices when such devices have no identifiable owner.",
      "name": "MP.3.123",
      "index": getIndex()
    },
    "MP.1.118": {
      "parent": "C024",
      "description": "Sanitize or destroy information system media containing Federal Contract Information (FCI) before disposal or release for reuse.",
      "name": "MP.1.118",
      "index": getIndex()
    },
    "MP.3.124": {
      "parent": "C025",
      "description": "Control access to media containing CUI and maintain accountability for media during transport outside of controlled areas.",
      "name": "MP.3.124",
      "index": getIndex()
    },
    "MP.3.125": {
      "parent": "C025",
      "description": "Implement cryptographic mechanisms to protect the confidentiality of CUI stored on digital media during transport unless otherwise protected by alternative physical safeguards.",
      "name": "MP.3.125",
      "index": getIndex()
    },
    "PS.2.127": {
      "parent": "C026",
      "description": "Screen individuals prior to authorizing access to organizational systems containing CUI.",
      "name": "PS.2.127",
      "index": getIndex()
    },
    "PS.2.128": {
      "parent": "C027",
      "description": "Ensure that organizational systems containing CUI are protected during and after personnel actions such as terminations and transfers.",
      "name": "PS.2.128",
      "index": getIndex()
    },
    "PE.1.131": {
      "parent": "C028",
      "description": "Limit physical access to organizational information systems, equipment and the respective operating environments to authorized individuals.",
      "name": "PE.1.131",
      "index": getIndex()
    },
    "PE.1.132": {
      "parent": "C028",
      "description": "Escort visitors and monitor visitor activity.",
      "name": "PE.1.132",
      "index": getIndex()
    },
    "PE.1.133": {
      "parent": "C028",
      "description": "Maintain audit logs of physical access.",
      "name": "PE.1.133",
      "index": getIndex()
    },
    "PE.1.134": {
      "parent": "C028",
      "description": "Control and manage physical access devices.",
      "name": "PE.1.134",
      "index": getIndex()
    },
    "PE.2.135": {
      "parent": "C028",
      "description": "Protect and monitor the physical facility and support infrastructure for organizational systems.",
      "name": "PE.2.135",
      "index": getIndex()
    },
    "PE.3.136": {
      "parent": "C028",
      "description": "Enforce safeguarding measures for CUI at alternate work sites.",
      "name": "PE.3.136",
      "index": getIndex()
    },
    "RE.2.138": {
      "parent": "C029",
      "description": "Protect the confidentiality of backup CUI at storage locations.",
      "name": "RE.2.138",
      "index": getIndex()
    },
    "RE.5.140": {
      "parent": "C029",
      "description": "Ensure information processing facilities meet organizationally-defined information security continuity, redundancy and availability requirements.",
      "name": "RE.5.140",
      "index": getIndex()
    },
    "RM.4.149": {
      "parent": "C031",
      "description": "Catalog and periodically update threat profiles and adversary Tactics, Techniques & Procedures (TTPs).",
      "name": "RM.4.149",
      "index": getIndex()
    },
    "RM.4.150": {
      "parent": "C031",
      "description": "Employ threat intelligence to inform the development of the system and security architectures, selection of security solutions, monitoring, threat hunting and response and recovery activities.",
      "name": "RM.4.150",
      "index": getIndex()
    },
    "RM.4.151": {
      "parent": "C031",
      "description": "Perform scans for unauthorized ports available across perimeter network boundaries, over the organization's Internet boundaries and other organization-defined boundaries.",
      "name": "RM.4.151",
      "index": getIndex()
    },
    "RM.3.146": {
      "parent": "C032",
      "description": "Develop and implement risk mitigation plans.",
      "name": "RM.3.146",
      "index": getIndex()
    },
    "RM.3.147": {
      "parent": "C032",
      "description": "Manage non-vendor-supported products (e.g., end of life) separately and restrict as necessary to reduce risk.",
      "name": "RM.3.147",
      "index": getIndex()
    },
    "RM.5.152": {
      "parent": "C032",
      "description": "Utilize an exception process for non-whitelisted software that includes mitigation techniques.",
      "name": "RM.5.152",
      "index": getIndex()
    },
    "RM.5.155": {
      "parent": "C032",
      "description": "Analyze the effectiveness of security solutions at least annually to address anticipated risk to the system and the organization based on current and accumulated threat intelligence.",
      "name": "RM.5.155",
      "index": getIndex()
    },
    "RM.4.148": {
      "parent": "C033",
      "description": "Develop and update as required, a plan for managing supply chain risks associated with the IT supply chain.",
      "name": "RM.4.148",
      "index": getIndex()
    },
    "CA.2.157": {
      "parent": "C034",
      "description": "Develop, document and periodically update System Security Plans (SSPs) that describe system boundaries, system environments of operation, how security requirements are implemented and the relationships with or connections to other systems.",
      "name": "CA.2.157",
      "index": getIndex()
    },
    "CA.4.163": {
      "parent": "C034",
      "description": "Create, maintain and leverage a security strategy and roadmap for organizational cybersecurity improvement.",
      "name": "CA.4.163",
      "index": getIndex()
    },
    "CA.2.159": {
      "parent": "C035",
      "description": "Develop and implement plans of action (e.g., POA&M) designed to correct deficiencies and reduce or eliminate vulnerabilities in organizational systems.",
      "name": "CA.2.159",
      "index": getIndex()
    },
    "CA.4.164": {
      "parent": "C035",
      "description": "Conduct penetration testing periodically, leveraging automated scanning tools and ad hoc tests using human experts.",
      "name": "CA.4.164",
      "index": getIndex()
    },
    "CA.4.227": {
      "parent": "C035",
      "description": "Periodically perform red teaming against organizational assets in order to validate defensive capabilities",
      "name": "CA.4.227",
      "index": getIndex()
    },
    "CA.3.162": {
      "parent": "C036",
      "description": "Employ a security assessment of enterprise software that has been developed internally, for internal use, and that has been organizationally-defined as an area of risk.",
      "name": "CA.3.162",
      "index": getIndex()
    },
    "SA.3.169": {
      "parent": "C037",
      "description": "Receive and respond to cyber threat intelligence from information sharing forums and sources and communicate to stakeholders.",
      "name": "SA.3.169",
      "index": getIndex()
    },
    "SA.4.171": {
      "parent": "C037",
      "description": "Establish and maintain a cyber threat hunting capability to search for Indicators of Compromise (IoC) in organizational systems and detect, track and disrupt threats that evade existing controls.",
      "name": "SA.4.171",
      "index": getIndex()
    },
    "SA.4.173": {
      "parent": "C037",
      "description": "Design network and system security capabilities to leverage, integrate and share Indicators of Compromise (IoC).",
      "name": "SA.4.173",
      "index": getIndex()
    },
    "SC.2.178": {
      "parent": "C038",
      "description": "Prohibit remote activation of collaborative computing devices and provide indication of devices in use to users present at the device.",
      "name": "SC.2.178",
      "index": getIndex()
    },
    "SC.3.182": {
      "parent": "C038",
      "description": "Prevent unauthorized and unintended information transfer via shared system resources.",
      "name": "SC.3.182",
      "index": getIndex()
    },
    "SC.3.184": {
      "parent": "C038",
      "description": "Prevent remote devices from simultaneously establishing non-remote connections with organizational systems and communicating via some other connection to resources in external networks (e.g., split tunneling).",
      "name": "SC.3.184",
      "index": getIndex()
    },
    "SC.3.186": {
      "parent": "C038",
      "description": "Terminate network connections associated with communications sessions at the end of the sessions or after a defined period of inactivity.",
      "name": "SC.3.186",
      "index": getIndex()
    },
    "SC.3.188": {
      "parent": "C038",
      "description": "Control and monitor the use of mobile code.",
      "name": "SC.3.188",
      "index": getIndex()
    },
    "SC.3.189": {
      "parent": "C038",
      "description": "Control and monitor the use of Voice over Internet Protocol (VoIP) technologies.",
      "name": "SC.3.189",
      "index": getIndex()
    },
    "SC.4.197": {
      "parent": "C038",
      "description": "Employ physical and logical isolation techniques in the system and security architecture and/or and where deemed appropriate by the organization.",
      "name": "SC.4.197",
      "index": getIndex()
    },
    "SC.5.198": {
      "parent": "C038",
      "description": "Configure monitoring systems to record packets passing through the organization's Internet network boundaries and other organizational-defined boundaries.",
      "name": "SC.5.198",
      "index": getIndex()
    },
    "SC.4.228": {
      "parent": "C038",
      "description": "Isolate administratrion of organizationally-defined high-value critical network infrastructure components and servers.",
      "name": "SC.4.228",
      "index": getIndex()
    },
    "SC.5.230": {
      "parent": "C038",
      "description": "Enforce port and protocol compliance.",
      "name": "SC.5.230",
      "index": getIndex()
    },
    "SC.3.192": {
      "parent": "C039",
      "description": "Implement Domain Name System (DNS) filtering services.",
      "name": "SC.3.192",
      "index": getIndex()
    },
    "SC.3.193": {
      "parent": "C039",
      "description": "Implement a policy restricting the publication of CUI on externally-owned, publicly-accessible websites (e.g., forums, LinkedIn, Facebook, Twitter, etc.).",
      "name": "SC.3.193",
      "index": getIndex()
    },
    "SC.4.199": {
      "parent": "C039",
      "description": "Utilize threat intelligence to proactively block DNS requests from reaching malicious domains.",
      "name": "SC.4.199",
      "index": getIndex()
    },
    "SC.4.202": {
      "parent": "C039",
      "description": "Employ mechanisms to analyze executable code and scripts (e.g., sandbox) traversing Internet network boundaries or other organizationally-defined boundaries.",
      "name": "SC.4.202",
      "index": getIndex()
    },
    "SC.5.208": {
      "parent": "C039",
      "description": "Employ organizationally-defined and tailored boundary protections in addition to commercially-available solutions.",
      "name": "SC.5.208",
      "index": getIndex()
    },
    "SC.4.229": {
      "parent": "C039",
      "description": "Utilize a URL categorization service and implement techniques to enforce URL filtering of websites that are not approved by the organization.",
      "name": "SC.4.229",
      "index": getIndex()
    },
    "SI.2.214": {
      "parent": "C040",
      "description": "Monitor system security alerts and advisories and take action in response.",
      "name": "SI.2.214",
      "index": getIndex()
    },
    "SI.4.221": {
      "parent": "C040",
      "description": "Use threat indicator information relevant to the information and systems being protected and effective mitigations obtained from external organizations to inform intrusion detection and threat hunting.",
      "name": "SI.4.221",
      "index": getIndex()
    },
    "SI.5.222": {
      "parent": "C041",
      "description": "Analyze system behavior to detect and mitigate execution of normal system commands and scripts that indicate malicious actions.",
      "name": "SI.5.222",
      "index": getIndex()
    },
    "SI.3.218": {
      "parent": "C042",
      "description": "Employ spam protection mechanisms at information system access entry and exit points.",
      "name": "SI.3.218",
      "index": getIndex()
    },
    "SI.5.223": {
      "parent": "C042",
      "description": "Monitor individuals and system components on an ongoing basis for anomalous or suspicious behavior.",
      "name": "SI.5.223",
      "index": getIndex()
    },
    "SI.3.219": {
      "parent": "C043",
      "description": "Implement email forgery protections",
      "name": "SI.3.219",
      "index": getIndex()
    },
    "SI.3.220": {
      "parent": "C043",
      "description": "Utilize email sandboxing to detect or block potentially malicious email.",
      "name": "SI.3.220",
      "index": getIndex()
    }
  }
}