import { combineReducers } from 'redux';
import idle from './idleReducer';
import sidebar from './sidebarReducers';
import theme from './themeReducer';
import topology from './topology';
import application from './application';
import { reducer as toastr } from 'react-redux-toastr';

export default combineReducers({
	sidebar,
	theme,
	toastr,
	idle,
	topology,
	application,
});
