import {
    asyncRequest,
    httpMethods,
    isAzure,
    request,
    resolveUri,
} from './Common';

import { isOrgChildAccount } from '../services/AuthService';

const startCompliance = (pack, report, count, success, error) => {
    const uri =
        'live-environment-compliance' + (isOrgChildAccount() ? 'Org' : '');
    request(
        resolveUri(uri),
        {
            method: httpMethods.put,
            headers: {
                ConformancePackName: pack,
                ReportId: report,
                FragmentCount: count,
            },
            redirect: 'follow',
        },
        success,
        error
    );
};

const resolveNonCompliance = (data, success, error) => {
    request(
        resolveUri('resolve-non-compliances'),
        {
            method: httpMethods.put,
            headers: {
                RuleName: data.SecurityTestRule,
                ResourceType: data.ResourceType,
                ResourceName: data.ResourceId,
            },
            redirect: 'follow',
        },
        success,
        error
    );
};

const resolveRansomwareIssue = async issue => {
    const url = 'resolve-non-compliances'; //+ (isAzure() ? '' : 'I');

    const uri = resolveUri(url);

    const options = {
        method: httpMethods.put,
        headers: {
            RuleName: issue.SecurityTestRule,
            ResourceType: issue.ResourceType,
            ResourceName: issue.ResourceName,
        },
    };

    return await asyncRequest(uri, options);
};

const unresolveCompliance = async issue => {
    const url = 'unresolve-non-compliances'; // + (isAzure() ? '' : 'I');
    const uri = resolveUri(url);

    const options = {
        method: httpMethods.put,
        headers: {
            RuleName: issue.SecurityTestRule,
            ResourceType: issue.ResourceType,
            ResourceName: issue.ResourceName,
        },
    };

    return await asyncRequest(uri, options);
};

export {
    startCompliance,
    resolveNonCompliance,
    resolveRansomwareIssue,
    unresolveCompliance,
};
