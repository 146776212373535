import {
	resolveRansomwareIssue,
	unresolveCompliance,
} from '../../../apiclients/Compliance';

import { getEvaluationComplianceResult } from '../../../apiclients/Ransomware';
import { useState } from 'react';

const RemediationResetStatus = {
	//todo: this needs to have the -1, 0, 1 status
};

const useBulkRemediation = () => {
	//todo: use RemediationResetStatus here
	const [resetStatus, setResetStatus] = useState(-1);
	const [resetTotal, setResetTotal] = useState(0);
	const [resetPassed, setResetPassed] = useState(0);
	const [resetFailed, setResetFailed] = useState(0);

	const rulesFilter = {
		'AWS::S3::Bucket': [
			's3-bucket-public-read-prohibited',
			's3-bucket-public-write-prohibited',
			's3-bucket-ssl-requests-only',
			's3-default-encryption-kms',
		],
		'AWS::EC2::Instance': ['ec2-instance-detailed-monitoring-enabled'],
	};

	const uniqueResources = issues => {
		const uniqueResources = [];

		issues.forEach(issue => {
			if (
				!uniqueResources.find(
					r =>
						r.ResourceName === issue.ResourceName &&
						r.ResourceArn === issue.ResourceArn &&
						r.ResourceType === issue.ResourceType
				)
			) {
				uniqueResources.push({
					ResourceId: issue.ResourceId,
					ResourceName: issue.ResourceName,
					ResourceArn: issue.ResourceArn,
					ResourceType: issue.ResourceType,
				});
			}
		});

		return uniqueResources;
	};

	const onExecuteAll = async (reportId, func) => {
		const data = [];
		let complete = false;
		let fragment = 1;

		//todo: use RemediationResetStatus here
		setResetStatus(0);

		while (!complete) {
			const result = await getEvaluationComplianceResult(reportId, fragment);
			if (result.success) {
				console.log('fetched data for fragment:', fragment);
				data.push(...result.data);
				fragment++;
			} else {
				console.log('failed to fetch data for fragment:', fragment);
				complete = true;
			}
		}

		if (data.length > 0) {
			setResetPassed(0);
			setResetFailed(0);
			let success = 0;
			let fail = 0;

			const resourceFilter = Object.keys(rulesFilter);
			const issuesToProcess = data.filter(
				i => resourceFilter.indexOf(i.ResourceType) > -1
			);

			const resources = uniqueResources(issuesToProcess);

			setResetTotal(resources.length);

			for (const resource of resources) {
				const resourceIssues = rulesFilter[resource.ResourceType];

				let failed = 0;
				for (const issueToFix of resourceIssues) {
					const resourceName =
						resource.ResourceType === 'AWS::EC2::Instance'
							? resource.ResourceId
							: resource.ResourceName; // custom logic decided by Saurav

					const issue = {
						SecurityTestRule: issueToFix,
						ResourceType: resource.ResourceType,
						ResourceName: resourceName,
					};

					const response = await func(issue);
					if (!response.success) {
						failed++;
					}
				}
				if (failed === 0) {
					setResetPassed(++success);
				} else {
					setResetFailed(++fail);
				}
			}
			//todo: use RemediationResetStatus here
			setResetStatus(1);
		} else {
			//todo: use RemediationResetStatus here
			setResetStatus(-1);
		}
	};

	const onRollbackAll = reportId => {
		onExecuteAll(reportId, unresolveCompliance).then(() => {});
	};

	const onFixAll = async reportId => {
		onExecuteAll(reportId, resolveRansomwareIssue).then(() => {});
	};

	return {
		resetStatus,
		resetTotal,
		resetPassed,
		resetFailed,
		setResetStatus,
		onRollbackAll,
		onFixAll,
	};
};

export { RemediationResetStatus };

export default useBulkRemediation;
