import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useScatter } from '../../Hooks/useVulnerabilityLoss';
import VulnerabilityPopup from './VulnerabilityPopup';

import { ReactComponent as ArrowRight } from '../../../../assets/img/arrowright.svg';

import '../dashboard.scss';

const VulnerabilityLoss = ({ data, isPrint, onIssueNewTab }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [openedItem, setOpenedItem] = useState(null);
	const { t } = useTranslation();

	const onOpen = openedItem => {
		const vulnerability = data.vulnerabilities.find(
			item => item.SecurityTestRule === openedItem.rule
		);

		setIsOpen(true);

		setOpenedItem([vulnerability]);
	};

	const onClose = () => {
		setIsOpen(false);
		setOpenedItem(null);
	};

	const scatterOpts = useScatter({
		data: data.vulnerabilities,
		onClick: onOpen,
	});

	const vulnChart = useMemo(() => {
		return (
			<ReactApexChart
				type={scatterOpts.type}
				options={scatterOpts.options}
				series={scatterOpts.series}
				height={scatterOpts.height}
			/>
		);
	}, [data]);


	return (
		<Card className="dashboard-card dashboard-card-chart">
			<CardHeader className="dashboard-card-chart__header">
				<CardTitle>
					<div className="dashboard-chart-header">
						<h5 className="dashboard-chart-title my-33-step">
							{t('ransomwareDashboard.accountRiskExposure')}
						</h5>
						{!isPrint && (
							<Link
								to="/ransomware/dashboard/detail"
								className="dashboard-chart-link">
								<ArrowRight />
							</Link>
						)}
					</div>
				</CardTitle>
			</CardHeader>
			<CardBody>
				{!data && <div>{t('ransomwareDashboard.noDataAvailable')}</div>}
				{data && (
					<div style={{ position: 'relative' }}>
						<p className="vulnerability-label">
							{t('ransomwareDashboard.potentialLoss')} ($)
						</p>
						{vulnChart}
					</div>
				)}
			</CardBody>
			{isOpen && openedItem && (
				<VulnerabilityPopup
					onIssueNewTab={onIssueNewTab}
					data={{
						vulnerabilities: openedItem,
						issues: data.issues,
						lastReport: data.lastReport,
					}}
					onClose={onClose}
				/>
			)}
		</Card>
	);
};

export default VulnerabilityLoss;
