import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { auditorNameRegex, reportNameRegex } from '../Common/Constants';

import React from 'react';
import useValue from '../Common/useValue';
import { t } from 'i18next';

const TextControl = ({
	originalValue,
	onValueChange,
	isReadOnly,
	headerLabel,
	inputName,
	placeHolderText,
	invalidText,
	regex,
}) => {
	const { value, invalid, onFocus, onChange, onBlur } = useValue(
		originalValue,
		onValueChange,
		regex
	);

	return (
		<FormGroup>
			<h2 className="text-info ">{headerLabel}</h2>
			{isReadOnly && <h3 className="ms-3">{originalValue}</h3>}
			{!isReadOnly && (
				<Input
					name={inputName}
					className="d-inline ms-3"
					style={{ width: '90%' }}
					type="text"
					placeholder={placeHolderText}
					value={value}
					onFocus={onFocus}
					onBlur={onBlur}
					onChange={onChange}
					invalid={invalid}
				/>
			)}
			<FormFeedback className="ms-3">{invalidText}</FormFeedback>
		</FormGroup>
	);
};

const Auditor = ({ value, onChange, isReadOnly }) => (
	<TextControl
		originalValue={value}
		onValueChange={onChange}
		isReadOnly={isReadOnly}
		headerLabel={t('form.auditorName')}
		inputName="auditorName"
		placeHolderText={t('form.auditorNamePlaceholder')}
		regex={auditorNameRegex}
	/>
);

const ReportName = ({ value, onChange, isReadOnly }) => (
	<TextControl
		originalValue={value}
		onValueChange={onChange}
		isReadOnly={isReadOnly}
		headerLabel={t('form.reportName')}
		inputName="reportName"
		placeHolderText={t('form.auditorNamePlaceholder')}
		regex={reportNameRegex}
	/>
);

export { Auditor, ReportName };
