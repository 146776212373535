import { t } from 'i18next';
import React from 'react';

const NistIntroduction = () => {
	return (
		<div>
			<div>
				<p className="font-weight-normal">{t('testReports.nistTitle')} </p>
				<p className="font-weight-normal">{t('testReports.nistDescription')}</p>
			</div>

			<div>
				<h4>{t('testReports.cybersecurityFrameworkTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.cybersecurityFrameworkDescription')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.identifyTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.identityDescription')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.protectTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.protectDescription')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.detectTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.detectDescription')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.respondTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.respondDescription')}
				</p>
			</div>

			<div>
				<h4>{t('testReports.recoverTitle')}</h4>
				<p className="font-weight-normal">
					{t('testReports.recoverDescription')}
				</p>
			</div>
		</div>
	);
};

export default NistIntroduction;
