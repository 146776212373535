import availabilityZoneImage from '../../assets/img/aws-icons/availabilityzone.png';
import awsSubnetImage from '../../assets/img/aws-icons/subnet.png';
import cacheclusterImage from '../../assets/img/aws-icons/cache-cluster.png';
import cloudfrontdistributionImage from '../../assets/img/aws-icons/cloudfront-distribution.png';
import ec2Image from '../../assets/img/aws-icons/ec2.png';
import efsImage from '../../assets/img/aws-icons/efs.png';
import eksClusterImage from '../../assets/img/aws-icons/eks-cluster.png';
import eksNodeGroupImage from '../../assets/img/aws-icons/eks-nodegroup.png';
import rdsClusterImage from '../../assets/img/aws-icons/rds-cluster.png';
import rdsImage from '../../assets/img/aws-icons/rds.png';
import regionImage from '../../assets/img/aws-icons/region.png';
import s3BucketImage from '../../assets/img/aws-icons/s3.png';
import vpcImage from '../../assets/img/aws-icons/vpc.png';

const defaultDim = {
	height: '48px',
	width: '48px',
};

const defaultColour = 'rgba(255, 255, 255, 0.4)';

const region = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(200, 200, 200, 1)',
	image: `${regionImage}`,
};

const vpc = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${vpcImage}`,
};

const availabilityZone = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(180, 200, 180, 0.8)',
	image: `${availabilityZoneImage}`,
};

const s3Bucket = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${s3BucketImage}`,
};

const ec2 = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${ec2Image}`,
};

const rds = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${rdsImage}`,
};

const rdsCluster = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${rdsClusterImage}`,
};

const cacheCluster = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${cacheclusterImage}`,
};

const eksCluster = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(200, 100, 180, 0.8)',
	image: `${eksClusterImage}`,
};

const eksNodeGroup = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: 'rgba(100, 200, 180, 0.8)',
	image: `${eksNodeGroupImage}`,
};

const cloudFrontDistribution = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${cloudfrontdistributionImage}`,
};

const efs = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${efsImage}`,
};

const awsSubnet = {
	shape: 'round-rectangle',
	dim: defaultDim,
	colour: defaultColour,
	image: `${awsSubnetImage}`,
};

const awsConfig = {
	Region: {
		label: 'Region',
		image: region,
		color: '#0039a6',
	},
	VPC: {
		label: 'VPC',
		image: vpc,
		color: '#7a49a5',
	},
	'Availability-Zone': {
		label: 'Availability-Zone',
		image: availabilityZone,
		color: '#ff7b12',
	},
	Subnet: {
		label: 'Subnet',
		image: awsSubnet,
		color: '#28a745',
	},
	EC2: {
		label: 'EC2',
		image: ec2,
		color: '#ff4433',
	},
	RDS: {
		label: 'RDS',
		image: rds,
		color: '#00308f',
	},
	'RDS-Cluster': {
		label: 'RDS-Cluster',
		image: rdsCluster,
		color: '#0000FF',
	},
	S3: {
		label: 'S3',
		image: s3Bucket,
		color: '#018749',
	},
	'EKS-Cluster': {
		label: 'EKS-Cluster',
		image: eksCluster,
		color: '#d27d2d',
	},
	'EKS-NodeGroup': {
		label: 'EKS-NodeGroup',
		image: eksNodeGroup,
		color: '#ffaa33',
	},
	'Cache-Cluster': {
		label: 'Cache-Cluster',
		image: cacheCluster,
		color: '#7cB928',
	},
	'CloudFront-Distribution': {
		label: 'CloudFront-Distribution',
		image: cloudFrontDistribution,
		color: '#452c63',
	},
	EFS: {
		label: 'EFS',
		image: efs,
		color: '#03c03c',
	},
};

export default awsConfig;
