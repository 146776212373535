import { asyncRequest, httpMethods } from './Common';

const baseUrl = process.env.REACT_APP_INTEGRATION_API_HOST;

const getIntegrations = async (success, failure = () => {}) => {
	try {
		const uri = baseUrl + '/api/v1/integration/list';

		const options = {
			method: httpMethods.get,
		};

		const result = await asyncRequest(uri, options);

		if (!result.success && result.statusCode !== 404) {
			failure(result);
			return;
		}
		const response = result.response;
		success(response);
	} catch (e) {}
};

const getJiraProviderLink = async (body, success, failure = () => {}) => {
	try {
		const uri = baseUrl + `/api/v1/provider/jira/getLink`;
		const options = {
			method: httpMethods.post,
			body: JSON.stringify(body),
		};

		const result = await asyncRequest(uri, options);

		if (!result.success && result.statusCode !== 404) {
			failure(result);
			return;
		}
		const response = result.response;
		success(response);
	} catch (e) {}
};

export { getIntegrations, getJiraProviderLink };
