import { getAllItems, getItem } from '../../../services/browserStorageService';
import { useEffect, useState } from 'react';

import { getIssueList } from '../../../apiclients/Ransomware';

export const getIssues = async reportId => {
	const { success, data } = await getIssueList(reportId);

	if (!success) {
		console.error('Could not fetch issue list for ', reportId);
		return [];
	}

	return data;
};

const defaultResult = {
	potentialLoss: {
		min: 0,
		max: 0,
	},
	previousLoss: {
		min: 0,
		max: 0,
	},
	score: 0,
	high: {
		resource: 0,
		issues: 0,
	},
	critical: {
		resource: 0,
		issues: 0,
	},
};

const useRiskResult = (reportName, render) => {
	const [vulnerabilities, setVulnerabilities] = useState([]);
	const [currentResult, setCurrentResult] = useState(defaultResult);
	const [error] = useState(false);
	const [issues, setIssues] = useState([]);
	const [loading, setLoading] = useState(true);
	const [reportId, setReportId] = useState(reportName);

	useEffect(() => setLoading(true), []);

	useEffect(() => {
		const setEvaluationData = reportName => {
			const getKey = () => `ransomware_${reportName}`;

			const evaluationData = getItem(getKey());

			if (evaluationData) {
				setVulnerabilities(evaluationData.Vulnerabilities);

				const currentLossRange = {
					min: evaluationData.PotentialLossMin,
					max: evaluationData.PotentialLossMax,
				};
				const previousLossRange = {
					min: evaluationData.previousLossMin,
					max: evaluationData.previousLossMax,
				};

				const result = {
					potentialLoss: currentLossRange,
					score: evaluationData.RiskScore,
					previousScore: evaluationData.previousScore,
					previousLoss: previousLossRange,
					high: {
						resource: evaluationData['High Resources'],
						issues: evaluationData['High Issues'],
					},
					critical: {
						resource: evaluationData['Critical Resources'],
						issues: evaluationData['Critical Issues'],
					},
				};

				setCurrentResult(result);

				return result;
			}
		};

		const trySetEvaluationData = async () => {
			const reportNames = [];
			getAllItems().forEach(item => {
				if (item.key.startsWith('ransomware_Report')) {
					const lastReportName = item.key.replace('ransomware_', '');
					reportNames.push(lastReportName);
				}
			});

			if (reportNames.length > 0) {
				reportNames.sort();
				const reportName = reportNames[reportNames.length - 1];
				setIssues(await getIssues(reportName));
				setReportId(reportName);
				setEvaluationData(reportName);
			}

			setTimeout(() => setLoading(false), 1000);
		};

		setLoading(true);
		trySetEvaluationData();
		//disabled because reportId is only set, nothing inside depends on it.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportName, render]);

	let result = { currentResult, vulnerabilities };

	return {
		previousScore: result.currentResult.previousScore,
		previousLoss: result.currentResult.previousLoss,
		currentScore: result.currentResult.score,
		loss: result.currentResult.potentialLoss,
		critical: result.currentResult.critical,
		high: result.currentResult.high,
		vulnerabilities: result.vulnerabilities,
		error,
		issues,
		loading,
		reportId,
	};
};

export default useRiskResult;
