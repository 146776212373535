let counter = 0;

const stage = {
  Info: ++counter,
  DemographicAssessment: ++counter,
  ReviewScan: ++counter,
  Analysing: ++counter,
  ReviewScore: ++counter
}

const workflow = {
  [stage.Info]: {
    next: stage.DemographicAssessment,
    prev: null
  },
  [stage.DemographicAssessment]: { 
    children: {
      "0": { next: stage.Analysing, prev: stage.Info },
      "1": { next: stage.ReviewScan, prev: stage.Info }
    },
    prev: stage.Info },
  [stage.Analysing]: {
    children: {
      "0": { next: stage.ReviewScore, prev: stage.DemographicAssessment },
      "1": { next: stage.ReviewScan, prev: stage.DemographicAssessment },
      "2": { next: stage.Info, prev: stage.DemographicAssessment }
    },
    prev: stage.DemographicAssessment
  },
  [stage.ReviewScan]: {
    next: stage.ReviewScore,
    prev: stage.DemographicAssessment,
  },
  [stage.ReviewScore]: {
    next: stage.Info,
    prev: stage.Analysing
  },
}

const workflowLinks = {
  [stage.Info]: "/ransomware/assessment/Info",
  [stage.DemographicAssessment]: "/ransomware/assessment/DemographicAssessment",
  [stage.ReviewScan]: "/ransomware/assessment/ReviewScan",
  [stage.Analysing]: "/ransomware/assessment/Analysing",
  [stage.ReviewScore]: "/ransomware/assessment/ReviewScore"
}

export {
  stage as Stages,
  workflow as Workflow,
  workflowLinks as Links
};