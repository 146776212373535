import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormFeedback, FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PasswordBar from '../../components/Form/PasswordBar';

import Input from '../../components/Form/Input';

import { ReactComponent as GoogleSvg } from '../../assets/img/google.svg';
import { getNewauthLandingUri, signup } from '../../apiclients/EmailLogin';
import PurchaseBtn from './PurchaseBtn';
import EmailConfirmationPopup from './EmailConfirmationPopup';

const validators = {
	email: /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/,
	password:
		/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])(?=.{8,255}$).*$/,
};

const INITIAL_VALUES = {
	email: '',
	password: '',
	confirmPassword: '',
	agreeTerms: false,
	agreeMarketing: false,
};

const INITIAL_ERRORS = {
	email: false,
	password: false,
	confirmPassword: false,
};

const FREE_TRIAL_CODE = process.env.REACT_APP_TRIAL_CODE;

function SignUpFreeTrial() {
	const { t } = useTranslation();
	const [values, setValues] = useState(INITIAL_VALUES);
	const [modalData, setModalData] = useState({ isOpen: false, email: '' });

	const [errors, setErrors] = useState(INITIAL_ERRORS);
	const [emailErrorMsg, setEmailErrorMsg] = useState(t('form.emailRequired'));

	const handleAgreeTermsChange = () => {
		setValues(prev => ({ ...prev, agreeTerms: !prev.agreeTerms }));
	};

	const handleAgreeMarketingChange = () => {
		setValues(prev => ({
			...prev,
			agreeMarketing: !prev.agreeMarketing,
		}));
	};

	const onChange = e => {
		e.persist();
		if (!e?.target) return;
		const value = e.target?.value;
		const name = e.target?.name;
		setValues(prev => ({ ...prev, [name]: value }));
		setErrors(prev => ({ ...prev, [name]: !validateField(name, value) }));
	};
	const validateField = useCallback(
		(name, value) => {
			const isNotEmpty = typeof value === 'string' && value.trim() !== '';

			let isValid = true;
			if (name === 'confirmPassword') {
				isValid = value === values.password;
			} else if (name === 'agreeTerms' || name === 'agreeMarketing') {
				isValid = value === true;
			} else if (validators[name]) {
				isValid = validators[name].test(value);
			}

			return isNotEmpty && isValid;
		},
		[validators, values.password]
	);

	// const validateField = useCallback(
	// 	(name, value) => {
	// 		const isNotEmpty = value && !!value.trim();

	// 		let isValid;
	// 		if (name === 'confirmPassword') {
	// 			isValid = value === values.password;
	// 		} else {
	// 			isValid = validators[name].test(value);
	// 		}

	// 		return isNotEmpty && isValid;
	// 	},
	// 	[values.password]
	// );
	const validateForm = useCallback(() => {
		return (
			validateField('email', values.email) &&
			validateField('password', values.password) &&
			validateField('confirmPassword', values.confirmPassword) &&
			values.agreeTerms &&
			values.agreeMarketing
		);
	}, [values, validateField]);

	// const validateForm = useCallback(
	// 	() => Object.keys(values).every(key => validateField(key, values[key])),
	// 	[values, validateField]
	// );

	const isValidForm = useMemo(
		() => validateForm(),
		[validateForm, values, values.agreeTerms, values.agreeMarketing]
	);

	const success = res => {
		setModalData({ isOpen: true, email: values.email });
		setValues(INITIAL_VALUES);
		setErrors(INITIAL_ERRORS);
	};

	const failure = res => {
		console.log('failure, res:', res);
		if (
			res &&
			res.responseError &&
			res.responseError.message &&
			'User already exists' === res.responseError.message
		) {
			console.log('dupe user');
			setEmailErrorMsg(t('form.accountExists'));
		}

		setErrors(prev => ({ ...prev, email: true }));

		setModalData({ isOpen: false });
	};

	const submitForm = async e => {
		e.preventDefault();

		const isValidForm = validateForm();

		if (!isValidForm) {
			return;
		}

		// change logic for success signup for free trial
		console.log(isValidForm);
		await signup(
			{
				email: values.email,
				password: values.password,
				code: FREE_TRIAL_CODE,
				agreeTerms: values.agreeTerms,
				agreeMarketing: values.agreeMarketing,
			},
			success,
			failure
		);
	};

	const onBlur = e => {
		e.persist();
		setEmailErrorMsg(t('form.emailRequired'));

		const isValid = validateField(e.target.name, e.target.value);
		setErrors(prev => ({ ...prev, [e.target.name]: !isValid }));
	};
	return (
		<div className="sign-in">
			<div className="text-center mt-4">
				<h2 className="sign-in__title">{t('signUpFreeTrial.title')}</h2>
				<p className="w-75 mx-auto sign-in__subtitle">
					{t('signUpFreeTrial.description')}
				</p>
			</div>
			<Form onSubmit={submitForm} className="form m-sm-4 reset-form">
				<FormGroup>
					<Input
						type="email"
						name="email"
						placeholder={t('form.email')}
						onChange={onChange}
						value={values.email}
						valid={!errors.email && !!values.email}
						invalid={errors.email}
						onBlur={onBlur}
					/>
					{errors.email && (
						<FormFeedback className="auth-feedback">
							{emailErrorMsg}
						</FormFeedback>
					)}
				</FormGroup>
				<FormGroup>
					<Input
						type="password"
						onChange={onChange}
						name="password"
						password
						value={values.password}
						valid={!errors.password && !!values.password}
						invalid={errors.password}
						placeholder={t('form.password')}
						onBlur={onBlur}
					/>
					{errors.password && !values?.password && (
						<FormFeedback className="auth-feedback">
							{t('form.passwordRequired')}
						</FormFeedback>
					)}
				</FormGroup>
				<FormGroup>
					<Input
						type="password"
						onChange={onChange}
						name="confirmPassword"
						password
						value={values.confirmPassword}
						valid={!errors.confirmPassword && !!values.confirmPassword}
						invalid={errors.confirmPassword}
						placeholder={t('form.confirmPassword')}
						onBlur={onBlur}
					/>
					{errors.confirmPassword && (
						<FormFeedback className="auth-feedback">
							{t('form.confirmPasswordError')}
						</FormFeedback>
					)}
				</FormGroup>
				<PasswordBar value={values.password} />
				<FormGroup check>
					<label className="form-check-label">
						<input
							type="checkbox"
							name="agreeTerms"
							checked={values.agreeTerms}
							onChange={handleAgreeTermsChange}
						/>{' '}
						{t('form.termsLink')}{' '}
						<Link to="/terms">{t('form.termsLinkAdd')}</Link>
					</label>
				</FormGroup>
				<FormGroup check>
					<label className="form-check-label">
						<input
							type="checkbox"
							name="agreeMarketing"
							checked={values.agreeMarketing}
							onChange={handleAgreeMarketingChange}
						/>{' '}
						{t('form.agreeMarketing')}
					</label>
				</FormGroup>
				<div className="text-center mt-4">
					<input
						type="submit"
						disabled={!isValidForm}
						className="btn btn-primary btn-lg auth-button"
						value={t('button.signUp')}
					/>
				</div>
				<div class="divider">
  <span class="divider-text">{t('button.or')}</span>
</div>
				<div className="mx-auto">
					<a
						className={`google-button ${
							!values.agreeTerms || !values.agreeMarketing
								? 'google-disabled'
								: ''
						}`}
						href={
							values.agreeTerms && values.agreeMarketing
								? getNewauthLandingUri('Google', {
										code: FREE_TRIAL_CODE,
										demo: false,
										agreeTerms: values.agreeTerms,
										agreeMarketing: values.agreeMarketing,
								  })
								: '#'
						}>
						<GoogleSvg /> <span>{t('signIn.google')}</span>
					</a>
				</div>

				<div className="d-flex justify-content-between mt-3 text-center ">
					<Link to="/auth/sign-in">{t('button.signIn')}</Link>
					<Link to="/auth/live-demo/sign-up">{t('button.demo')}</Link>
				</div>
				<PurchaseBtn />
			</Form>
			<EmailConfirmationPopup
				isOpen={modalData.isOpen}
				email={modalData.email}
				onToggle={v => setModalData({ isOpen: false, email: '' })}
			/>
		</div>
	);
}

export default SignUpFreeTrial;
