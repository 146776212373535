import {
	change,
	is,
	map
} from "../../Common/AuditItemHelper"
import { useEffect, useState } from "react";

import { errorReasons } from "../../Common/Constants"

export default function ({ refData, nested, parentId, testCase, navState, categories, categoryId, selectedId, onSubCatError }) {

	const formData = map.fromArray(navState.auditData[selectedId]),
		[result, setResult] = useState(map.toResult(formData.result)),
		[error, setError] = useState(formData.error),
		[errorReason, setErrorReason] = useState(''),
		[modified, setModified] = useState(formData.modified),
		[severity, setSeverity] = useState(map.toSeverity(formData.severity)),
		[severityValue, setSeverityValue] = useState(severity.value),
		[disableSeverity, setDisableSeverity] = useState(is.compliantResult(result)),
		[severityRequired, setSeverityRequired] = useState(true),
		[evidence, setEvidence] = useState(formData.evidence),
		[observation, setObservation] = useState(formData.observation),
		[orgControls, setOrgControls] = useState(formData.orgControls),
		selectedTestCase = !nested ? testCase : refData.childTestCases[testCase],
		onResultChange = value => {
			setResult(value);
			const isCompliant = is.compliant(value.label);
			setDisableSeverity(isCompliant);
			if (isCompliant) {
				const select = { value: 0, label: '' };
				setSeverity(select);
				setSeverityValue(0);
			} else {
				setSeverityRequired(is.nonCompliantResult(result, severity.value));
			}
		},
		getCategoryError = item => {
			const orgControlError = item.orgControls.length < 1; // orgcontrol error is required=true and not set
			const observationError = false; // observationError error is false as required=false
			const resultError = item.result.length < 1 || item.result === 'Select'; // result error is required=true and not set
			const isNonCompliant = item.result === "Non-Compliant";
			const severityError = isNonCompliant ? (item.severity.length > 0 && item.severity === 'Select') : false; // severiority error is required=true and not set
			const evidenceError = item.evidence.length < 1;; // evidence error is when not set

			const reasons = [];

			if (orgControlError) reasons.push(errorReasons.orgControlError);
			if (observationError) reasons.push(errorReasons.observationError);
			if (resultError) reasons.push(errorReasons.resultError);
			if (severityError) reasons.push(errorReasons.severityError);
			if (evidenceError) reasons.push(errorReasons.evidenceError);

			setErrorReason(reasons);

			return orgControlError || observationError || resultError || severityError || evidenceError;
		},
		onError = value => {
			const item = map.fromArray(navState.auditData[selectedId]);
			item.error = getCategoryError(item) || value;
			item.modified = true;
			setError(item.error);
			if (selectedId) onSubCatError(selectedId, item.error);
			navState.onItemChange(item, selectedId);
		},
		onSeverityChange = value => {
			setSeverity(value);
			setSeverityValue(value.value);
		},
		isValidItem = () => {
			//if the category is compliant, then the severity shouldn't be set.
			const compliantRule = is.compliantResult(result) && severity.value === 0;

			//if the category is non-compliant, then the severity must be set
			const nonCompliantRule = is.nonCompliantResult(result, severity) && Number(severity.value) > 0

			// organisation controls must be set.
			const orgControlRule = orgControls.length > 0;

			return orgControlRule && (compliantRule || nonCompliantRule);
		},
		update = (source, value) => {

			let changedValue = [
				result.label,
				severity.label,
				evidence,
				observation,
				orgControls,
				false,
				true
			];

			if (change.isResult(source)) {
				changedValue[0] = value.label;
				onResultChange(value);
			}
			if (change.isSeverity(source)) {
				changedValue[1] = value.label;
				onSeverityChange(value);
			}
			if (change.isEvidence(source)) {
				changedValue[2] = value;
				setEvidence(value);
			}
			if (change.isObservation(source)) {
				changedValue[3] = value;
				setObservation(value);
			}
			if (change.isOrg(source)) {
				changedValue[4] = value;
				setOrgControls(value);
			}

			change.isSeverityRequired(source, value, severityValue);

			changedValue[5] = isValidItem();

			return changedValue;
		},
		onChange = (source, value) => {
			const updated = update(source, value);
			const updatedItem = map.fromArray(updated);
			updatedItem.error = getCategoryError(updatedItem);
			if (selectedId) onSubCatError(selectedId, updatedItem.error);
			navState.onItemChange(updatedItem, selectedId);
		};

	useEffect(() => {

		var updatedItem = map.fromArray(navState.auditData[selectedId]);
		let updatedResult = map.toResult(updatedItem.result);
		let updatedSeverity = map.toSeverity(updatedItem.severity);

		setResult(updatedResult);
		setSeverity(updatedSeverity);
		setSeverityValue(updatedSeverity.value);
		setDisableSeverity(is.compliantResult(updatedResult));
		setSeverityRequired(is.nonCompliantResult(updatedResult, updatedSeverity.value));
		setEvidence(updatedItem.evidence);
		setObservation(updatedItem.observation);
		setOrgControls(updatedItem.orgControls);
		setError(updatedItem.error);
		setModified(updatedItem.modified);

		getCategoryError(updatedItem);

		if (!selectedId) onSubCatError(selectedId, updatedItem.error);

	}, [selectedId, navState.auditData]);

	return {
		result,
		severity,
		severityValue,
		disableSeverity,
		severityRequired,
		evidence,
		observation,
		orgControls,
		selectedTestCase,
		error,
		errorReason,
		modified,
		onChange,
		onError
	};
}