import { Container, Row } from 'reactstrap';
import { ResolutionType, useResourceRow } from '../../../Hooks/useResourceRow';

import { Button } from 'reactstrap';
import Expander from './Expander';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Instances from './Instances';
import NoSelectionModal from './NoSelectionModal';
import React from 'react';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

const ResolutionColour = {
	[ResolutionType.none]: 'text-dark',
	[ResolutionType.success]: 'text-success',
	[ResolutionType.failure]: 'text-danger',
};

const EventRow = ({ value, type }) => (
	<Row className="border-bottom border-light" title={value}>
		<code className={ResolutionColour[type]}>{value}</code>
	</Row>
);

const ResourceRow = ({
	item,
	type,
	showAll,
	index,
	fixStatus,
	saveRollback,
	saveFix,
	logs,
	saveLogs,
}) => {
	const {
		show,
		fixed,
		notFixed,
		rollback,
		fixAttempted,
		busy,
		logEvents,
		selectAll,
		setShow,
		noSelection,
		onFix,
		onCopyLog,
		onResourceSelected,
		onRollback,
		toggleSelect,
		onCloseNoSelection,
	} = useResourceRow({
		item,
		type,
		showAll,
		index,
		fixStatus,
		saveRollback,
		saveFix,
		logs,
		saveLogs,
	});

	const getExpandAlignClass = () => (show ? 'align-top' : 'align-middle');

	return (
		<>
			<tr className="font-weight-normal">
				<th scope="row" className={getExpandAlignClass()}>
					<Expander onExpand={setShow} expanded={show} />
				</th>
				<td className={getExpandAlignClass()}>
					<div>
						<input
							type="checkbox"
							name={`expand_row_${index}`}
							id={`expand_row_${index}`}
							onClick={e => toggleSelect(e.currentTarget.checked)}
						/>
						<label htmlFor={`expand_row_${index}`} className="ms-1">
							{type}
						</label>
					</div>
					<div className="ms-2">
						<Instances
							hide={!show}
							instances={item}
							onResourceSelected={onResourceSelected}
							selectAll={selectAll}
						/>
					</div>
				</td>
				<td className="align-top">
					{busy && (
						<div
							className="spinner-border spinner-border-sm ms-3"
							role="status">
							<span className="sr-only">{t('table.loading')}</span>
						</div>
					)}
					{!busy && (
						<Container>
							<Row className="mb-2">
								<Button size="md" color="primary" onClick={onFix}>
									{t('button.fixNow')}
								</Button>
							</Row>
							{fixed > 0 && (
								<Row>
									<Button size="md" color="secondary" onClick={onRollback}>
										{t('button.rollback')}
									</Button>
								</Row>
							)}
						</Container>
					)}
				</td>
				<td className="align-top">
					<span className="d-flex justify-content-center">
						{fixed} / {notFixed} / {rollback}
					</span>
				</td>
				<td className="align-top">
					<Container className={show ? '' : 'd-none'}>
						{fixAttempted && (
							<Row className="float-end mb-2">
								<Button onClick={onCopyLog} size="md" color="primary">
									<FontAwesomeIcon
										icon={faCopy}
										className="me-1"
										title="Copy log to clipboard"
									/>
								</Button>
							</Row>
						)}
						<div className="clearfix"></div>
						<div>
							<Row className={fixAttempted ? `border-top border-light` : ''}>
								<Container
									className="overflow-auto"
									style={{ maxHeight: '400px' }}>
									{logEvents.map((v, i) => (
										<EventRow key={i} value={v.value} type={v.type} />
									))}
								</Container>
							</Row>
						</div>
					</Container>
				</td>
			</tr>
			<NoSelectionModal onClose={onCloseNoSelection} isOpen={noSelection} />
		</>
	);
};

export default ResourceRow;
