import React, { useState } from 'react';
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from 'reactstrap';
import { ReactComponent as ChevronSvg } from '../../assets/img/chevron.svg';
import classNames from 'classnames';

const Select = ({
	options,
	onChange,
	value,
	valid,
	invalid,
	label,
	className,
	inline = false,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	const buttonClassnames = classNames('select-button', {
		'select-button_invalid': invalid,
		'select-button_valid': valid,
		[className]: className,
		'select-button_inline': inline,
	});

	return (
		<div>
			<p
				className={classNames('cloud-form__label', {
					'cloud-form__label_error': invalid,
				})}>
				{label}
			</p>
			<Dropdown isOpen={isOpen} toggle={toggle}>
				<DropdownToggle size="sm" className={buttonClassnames}>
					{options.find(option => option.value === value)?.label || '-'}
					<ChevronSvg className="dropdown-arrow" />
				</DropdownToggle>
				<DropdownMenu style={{ top: 'unset' }} className="select-menu">
					{options.map(item => (
						<DropdownItem
							key={item.value}
							onClick={() => onChange(item.value)}
							className={classNames('select-item', {
								'select-item_active': value === item.value,
							})}>
							{item.label}
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

export default Select;
