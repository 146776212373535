export default {
	id: 'ID',
	name: 'Name',
	parent: 'Parent',
	resourcetype: 'Resource Type',
	level: 'Network Level',
	compliance: 'Compliance Status',
	'non-compliant-rules': 'Non-Compliant Rules',
	issues: 'Issues',
};
