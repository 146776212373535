import { Input } from 'reactstrap';
import { Label } from 'reactstrap';
import React from 'react';

const ReviewScanInput = ({ type, labelfor, onChange, children, value }) => (
	<>
		<Label htmlFor={labelfor}>{children}</Label>
		<Input
			type={type ?? 'text'}
			name={labelfor}
			value={value}
			onChange={e => onChange(labelfor, e.target.value)}
			style={{ borderRadius: '25px' }}
			placeholder={'Type here'}
		/>
	</>
);

export default ReviewScanInput;
