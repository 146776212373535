import { Card, CardBody, Row } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ErrorInfo = props => {
	const { t } = useTranslation();
	return (
		<Card className="d-flex">
			<CardBody>
				<Row>
					<div className="mt-1 ms-3 me-3">
						<h1 className="text-danger">
							<FontAwesomeIcon icon={faExclamationTriangle} className="me-1" />
						</h1>
					</div>
					<div className="ms-2 w-50">
						<p>{t(props.text)}</p>
					</div>
				</Row>
			</CardBody>
		</Card>
	);
};

const defaultProps = {
	text: 'topology.errorLoadInfo',
};

ErrorInfo.defaultProps = defaultProps;

export default ErrorInfo;
