import React from 'react';
import { Alert } from 'reactstrap';
import SuccessVerified from '../../assets/img/sucessVerified.svg';
import FaildVerified from '../../assets/img/fail.svg';

const VerifyResult = ({ color, isOpen, title, subTitle }) => (
	<Alert
		className="auth-alert"
		style={{ backgroundColor: color }}
		isOpen={isOpen}
	>
		<div className="d-flex justify-content-between w-100">
			<div>
				<p className="verify-result-title p-0 m-0">{title}</p>
				<p className="verify-result-subtitle m-0 p-0">{subTitle}</p>
			</div>
			<img
				src={color === '#59A919' ? SuccessVerified : FaildVerified}
				alt="verifiedIcon"
			/>
		</div>
	</Alert>
);

export default VerifyResult;
