import React, { useEffect, useState } from 'react';

import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LogOut, Plus, Settings, Database } from 'react-feather';

import { useNavigate } from 'react-router';
import UserDetails from '../UserDetails';
import { CloudTypes } from '../../../constants/CloudInfo';

import { setItem } from '../../../services/browserStorageService';
import {
	getCurrentUserKey,
	getUsersByType,
	getUserAccessToken
} from '../../../services/AuthService';
import { logout as logoutUser } from '../../../services/AuthService';
import { ReactComponent as ChevronSvg } from '../../../assets/img/chevron.svg';

import Avatar from '../Avatar/Avatar';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
	setIsAuthenticated,
	setUser,
} from '../../../redux/actions/applicationActions';

import { isSuperAdmin } from '../../../services/accountTypeService';
import { getUserToken } from '../../../services/AuthService';

const setCurrentUser = user => {
	if (getCurrentUserKey() !== user.key) {
		setItem('currentUser', user.key);
		window.location.reload(false);
	}
};

const UserDropdown = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [showBackendOps, setShowBackendOps] = useState(false);
	const [backendOpsLink, setBackendOpsLink] = useState('');

	const [showUserManager, setShowUserManager] = useState(false);
	const [userManagerLink, setUserManagerLink] = useState('');

	const [showChangePassword, setShowChangePassword] = useState(false);

	const logoutHandler = () => {
		dispatch(setIsAuthenticated(null));
		dispatch(setUser(null));
		navigate('/auth/sign-in');
	};
	const logout = () => {
		logoutUser(logoutHandler);
	};
	const users = getUsersByType(CloudTypes.AWS.toString());
	const currentUserKey = getCurrentUserKey();
	const currentUser = users
		? users.find(user => user.key === currentUserKey)
		: null;

	const onAdd = () => {
		navigate('/admin/remote-landing');
	};

	useEffect(() => {
		if ('1' === process.env?.REACT_APP_FEAT_SHOW_OPS) {
			// TODO this hardcodes 1 == AWS
			if (
				currentUser &&
				currentUser.value?.authHeaders?.Region &&
				currentUser.value?.authHeaders?.domain &&
				currentUser.value?.authHeaders?.keyid &&
				currentUser.value?.authHeaders?.token &&
				1 === currentUser.value?.type
			) {
				console.log('Current user is AWS and we have valid params');
				setShowBackendOps(true);

				const queryParams = new URLSearchParams();
				const url = '/ops/index.html?';
				queryParams.set('region', currentUser.value.authHeaders.Region);
				queryParams.set('domain', currentUser.value.authHeaders.domain);
				queryParams.set('keyid', currentUser.value.authHeaders.keyid);
				queryParams.set('token', currentUser.value.authHeaders.token);
				setBackendOpsLink(url + queryParams.toString());
			}
		}
		if ( getUserToken() && isSuperAdmin())
		{
			setShowUserManager(true);
			const queryParams = new URLSearchParams();
			const url = '/ops/users.html?';
			queryParams.set('token', getUserToken());
			setUserManagerLink(url + queryParams.toString());
		}

		const userTokens = getUserAccessToken();
		if (userTokens && userTokens.AccessToken)
		{
			// Only show change password for users with an access token (not SSO users)
			setShowChangePassword(true);
		}
	}, []);

	return (
		<UncontrolledDropdown nav inNavbar className="ml-lg-1">
			<DropdownToggle nav caret className="user-toggle">
				{/* TODO: Add avatar path */}
				<div className="user-toggle__name">
					<p>{currentUser?.value?.user?.UserName}</p>
					<ChevronSvg className="dropdown-arrow" />
				</div>
				<Avatar />
			</DropdownToggle>
			<DropdownMenu end className="user-dropdown">
				<div className="user-dropdown__users dropdown-users">
					<p className="dropdown-users__title">{t('sidebar.otherAccount')}</p>
					<UserDetails users={users} onClick={setCurrentUser} />
					<DropdownItem
						className="user-dropdown__button user-dropdown__button_add"
						onClick={onAdd}>
						<Plus />
						{t('button.add')}
					</DropdownItem>
				</div>
				<div />
				{showBackendOps ? (
					<DropdownItem className="user-dropdown__button">
						<Database />
						<a href={backendOpsLink}>{t('button.backendOps')}</a>
					</DropdownItem>
				) : (
					''
				)}
				{showUserManager ? (
					<DropdownItem className="user-dropdown__button">
						<Database />
						<a href={userManagerLink}>{t('button.userManager')}</a>
					</DropdownItem>
				) : (
					''
				)}


				<DropdownItem
					onClick={e => logout(e)}
					className="user-dropdown__button">
					<LogOut />
					<Link to="/auth/sign-in">{t('button.signOut')}</Link>
				</DropdownItem>
				<DropdownItem className="user-dropdown__button">
					<Settings />
					<Link to="/settings">{t('button.settings')}</Link>
				</DropdownItem>
				{showChangePassword ? (
				<DropdownItem className="user-dropdown__button">
					<Settings />
					<Link to="/settings/change-password">{t('button.changePassword')}</Link>
				</DropdownItem>) : ('') }
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

export default UserDropdown;
