import {
	clearAllItems,
	clearItemsWithKeyStartingWith,
	getAllItems,
	getItem,
	removeItem,
	setItem,
} from './browserStorageService';

import { CloudTypes } from '../constants/CloudInfo';
import { EvaluationApiClient } from '../apiclients/EvaluationApiClient';
import { login } from '../apiclients/AwsLogin';

const currentUserKey = 'currentUser';
const currentRegion = 'currentRegion';
const currentChildAccount = 'currentChildAccount';
const loggedUserToken = 'talasecure_user';
const userIdpTokenKey = 'talasecure_idp_token';
const accountLimitsKey = 'talasecure_account_limits';

const setCurrentRegion = region => {
	setItem(currentRegion, region);
};

const setCurrentChildAccount = childAccount => {
	setItem(currentChildAccount, childAccount);
};

const clearCurrentUser = () => {
	const currentUser = getItem(currentUserKey);
	removeItem(currentUser);
	setItem(currentUserKey, null);
	setItem(currentRegion, null);
	setItem(currentChildAccount, null);
};

const clearAllUsers = () => {
	setItem(currentUserKey, null);
	clearItemsWithKeyStartingWith(['aws-user-', 'azure-user-']);
};

const clearSessionItems = () => {
	removeItem('reload_Topology');
};

const clearApplicationStorage = () => {
	clearAllItems();
};

const getUserToken = () => {
	return getItem(loggedUserToken);
};

const setUserToken = token => {
	setItem(loggedUserToken, token);
};

const clearUserToken = () => {
	removeItem(loggedUserToken);
	removeItem(accountLimitsKey);
};

const saveUser = (user, authHeaders, type, userInfo) => {
	user = user || {};
	user.UserName = user.UserName;
	const existingUser = getUsersByType(type.toString()).find(
		u => JSON.stringify(u.value.authHeaders) === JSON.stringify(authHeaders)
	);
	if (!existingUser) {
		const key = `type-${type}-user-${Date.now()}`;
		setItem(key, { type, user, authHeaders, userInfo });
		setItem(currentUserKey, key);
	} else {
		setItem(currentUserKey, existingUser.key);
	}
};

const failure = (e, error) => {
	clearCurrentUser();
	error(e);
};

const completed = (user, success, deploy) => {
	if (!user.Setup) success(user);
	else deploy(user);
};

const loginAws = (keys, success, error, deploy) => {
	const authorized = user => {
		keys.Account = user.Account;

		const userInfo = [
			{ key: '', value: user.UserName },
			{ key: 'Account', value: keys.Account },
			{ key: 'Region', value: keys.Region },
			{ key: 'IsOrgAccount', value: false },
		];
		// clear stub users
		clearCurrentUser();
		saveUser(user, keys, CloudTypes.AWS, userInfo);
		completed(user, success, deploy);
	};
	const client = new EvaluationApiClient();
	// Save stub user so the type is correct
	saveUser(undefined, keys, CloudTypes.AWS, []);
	client.authorizeAws(keys, authorized, e => failure(e, error));
};

const loginAwsOrgAccount = async (keys, success, error, deploy) => {
	const authorized = user => {
		keys.Account = user.Account;

		const userInfo = [
			{ key: '', value: user.UserName },
			{ key: 'Account', value: keys.Account },
			{ key: 'Region', value: keys.Region },
			{ key: 'Regions', value: keys.Regions },
			{ key: 'IsOrgAccount', value: true },
			{ key: 'MemberAccounts', value: user.MemberAccounts },
		];

		saveUser(user, keys, CloudTypes.AWS, userInfo);
		setCurrentRegion(keys.Region);
		setCurrentChildAccount(keys.Account);
		completed(user, success, deploy);
	};

	await login(true, keys, authorized, e => failure(e, error));
};

const loginAzure = (keys, success, error, deploy) => {
	const authorized = user => {
			user = { UserName: 'AzureUser' };
			const userInfo = [
				{ key: '', value: user.UserName },
				{ key: 'Tenant', value: keys.TenantId },
				{ key: 'Subscription', value: keys.SubscriptionId },
			];
			saveUser(user, keys, CloudTypes.Azure, userInfo);
			completed(user, success, () => console.error('org deploy required'));
		},
		client = new EvaluationApiClient();
	client.authorizeAzure(keys, authorized, e => failure(e, error));
};

const logout = success => {
	const client = new EvaluationApiClient();
	const logoutHandler = () => {
		clearCurrentUser();
		clearAllUsers();
		clearUserToken();
		clearSessionItems();
		success();
	};
	client.logout(logoutHandler, logoutHandler);
};

const getUserItem = () => {
	const user = getItem(currentUserKey);
	const isSet = user && user.length > 0;

	return { isSet, item: getItem(user) ?? {} };
};

const getUsersByType = type => {
	const userItems = getAllItems();
	const users = [];
	userItems.forEach(user => {
		const parts = user.key.split('-');
		if (parts[1] === type) {
			users.push(user);
		}
	});
	return users;
};

const getUser = () => {
	const { isSet, item } = getUserItem();
	return isSet ? item.user : undefined;
};

const isOrgAccount = () => {
	const { isSet, item } = getUserItem();
	const isOrgAccount = isSet
		? item.userInfo.find(u => u.key === 'IsOrgAccount')
		: undefined;
	return isOrgAccount ? isOrgAccount.value : false;
};

const isOrgChildAccount = () => {
	const { isSet, item } = getUserItem();
	const isOrgParent = isSet
		? item.userInfo.find(u => u.key === 'IsOrgAccount')
		: undefined;
	const currentChildAccount = getCurrentChildAccount();
	const memberAccounts = item.userInfo.find(i => i.key === 'MemberAccounts');
	const account = memberAccounts
		? memberAccounts.value.find(a => a.account === currentChildAccount)
		: undefined;
	return (
		isOrgParent &&
		isOrgParent.value &&
		account !== undefined &&
		!account.masterAccount
	);
};

const getRegions = () => {
	const { isSet, item } = getUserItem();
	const regions = isSet
		? item.userInfo.find(u => u.key === 'Regions')
		: undefined;
	return regions ? regions.value : [];
};

const getChildAccounts = () => {
	const { isSet, item } = getUserItem();
	const memberAccounts = isSet
		? item.userInfo.find(u => u.key === 'MemberAccounts')
		: undefined;
	return memberAccounts ? memberAccounts.value : [];
};

const getCurrentChildAccount = () => {
	return getItem(currentChildAccount);
};

const getCurrentRegion = () => {
	return getItem(currentRegion) ?? getRegions()[0];
};

const getUserNewauth = () => {
	// Returns true if the user is using newauth (proxy / retrofit), false otherwise.
	const { item } = getUserItem();
	console.log('***BRL in getUserNewauth - item:', item);
	if (
		item.type === CloudTypes.AWS &&
		item.authHeaders &&
		item.authHeaders.token &&
		item.authHeaders.keyid &&
		item.authHeaders.domain
	) {
		console.log('***BRL newauth=true');

		return true;
	}
	console.log('***BRL newauth=false');
	return false;
};

const getKeys = () => {
	const { isSet, item } = getUserItem();
	let authHeaders = item.authHeaders ?? {};

	if (item.type === CloudTypes.AWS && item.authHeaders) {
		if (item.authHeaders.AccessKey && item.authHeaders.SecretKey) {
			// use user-input keys
			authHeaders = {
				Account: item.authHeaders.Account,
				Region: item.authHeaders.Region,
				AccessKey: item.authHeaders.AccessKey,
				SecretKey: item.authHeaders.SecretKey,
			};
		} else if (
			item.authHeaders.token &&
			item.authHeaders.keyid &&
			item.authHeaders.domain
		) {
			// use keys from retrofit. TODO: handle org account.
			authHeaders = {
				Account: item.authHeaders.Account,
				Region: item.authHeaders.Region,
				token: item.authHeaders.token,
				keyid: item.authHeaders.keyid,
				domain: item.authHeaders.domain,
			};
		} else {
			// shouldn't be here
			console.log('have neither token nor accesskey/secretkey');
		}
	}

	if (isOrgAccount()) {
		authHeaders.Region = getCurrentRegion();
		const childAccount = getCurrentChildAccount();
		if (childAccount && childAccount.length > 0) {
			const memberAccounts = item.userInfo.find(
				i => i.key === 'MemberAccounts'
			);
			const secrets = memberAccounts.value.find(
				a => a.account === childAccount && a.userName !== 'All Member Accounts'
			);
			authHeaders.Account = childAccount;
			authHeaders.AccessKey = secrets.accessKey;
			authHeaders.SecretKey = secrets.secretKey;
		}
	}
	return isSet ? authHeaders : {};
};

const getUserType = () => {
	const { isSet, item } = getUserItem();
	return isSet ? item.type : undefined;
};

const getUserView = () => {
	const { isSet, item } = getUserItem();
	return isSet ? item.userInfo : undefined;
};

const getCurrentUserKey = () => {
	return getItem(currentUserKey);
};

const getCurrentAccountId = () => {
	const userInfo = getUserItem().item.userInfo;

	let isOrgAccountCheck = isOrgAccount();
	let isOrgChildAccountCheck = isOrgChildAccount();
	let isSingleUser = !isOrgAccountCheck || !isOrgChildAccountCheck;

	if (isSingleUser || !isOrgChildAccountCheck) {
		return userInfo[1].value;
	} else {
		return getCurrentChildAccount();
	}
};

const loginOnprem = (keys, success, error, deploy) => {
	const authorized = user => {
		console.log('loginOnprem - authorized - user:', user);
		keys.Account = user.result.account_id;

		const userInfo = [
			{ key: '', value: user.UserName },
			{ key: 'Account', value: keys.Account },
			{ key: 'Region', value: 'N/A' },
			{ key: 'IsOrgAccount', value: false },
		];
		// clear stub users
		clearCurrentUser();
		saveUser(user, keys, CloudTypes.Onprem, userInfo);
		completed(user, success, deploy);
	};
	const client = new EvaluationApiClient();
	// Save stub user so the type is correct
	saveUser(undefined, keys, CloudTypes.Onprem, []);
	client.authorizeOnprem(keys, authorized, e => failure(e, error));
};

const loginK8s = (keys, success, error, deploy) => {
	const authorized = user => {
		console.log('loginK8s - authorized - user:', user);
		keys.Account = user.result.account_id;

		const userInfo = [
			{ key: '', value: user.UserName },
			{ key: 'Account', value: keys.Account },
			{ key: 'Region', value: 'N/A' },
			{ key: 'IsOrgAccount', value: false },
		];
		// clear stub users
		clearCurrentUser();
		saveUser(user, keys, CloudTypes.K8s, userInfo);
		completed(user, success, deploy);
	};
	const client = new EvaluationApiClient();
	// Save stub user so the type is correct
	saveUser(undefined, keys, CloudTypes.K8s, []);
	// This reuses the same Onprem method.
	client.authorizeOnprem(keys, authorized, e => failure(e, error));
};

const getAccountLimits = () => {
	const accountLimits = getItem(accountLimitsKey);
	const isSet = accountLimits && Object.keys(accountLimits).length > 0;
	return isSet ? accountLimits : undefined;
};

const setAccountLimits = newLimits => {
	setItem(accountLimitsKey, newLimits);
};

const getUserAccessToken = () => {
	return getItem(userIdpTokenKey);
};

const setUserAccessToken = token => {
	setItem(userIdpTokenKey, token);
};


export {
	loginAws,
	loginAzure,
	loginAwsOrgAccount,
	loginOnprem,
	loginK8s,
	logout,
	getUser,
	getUserNewauth,
	getKeys,
	getUserType,
	getUserView,
	getUsersByType,
	getCurrentUserKey,
	getRegions,
	getChildAccounts,
	setCurrentRegion,
	setCurrentChildAccount,
	isOrgAccount,
	isOrgChildAccount,
	getCurrentChildAccount,
	getCurrentRegion,
	getCurrentAccountId,
	clearAllUsers,
	clearCurrentUser,
	clearSessionItems,
	clearApplicationStorage,
	saveUser,
	setUserToken,
	clearUserToken,
	getUserToken,
	getAccountLimits,
	setAccountLimits,
	getUserAccessToken,
	setUserAccessToken
};
