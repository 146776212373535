import Content from "./Content";
import Horizontal from "./Horizontal";
import React from "react";
import SubCategories from "../SubCategories";
import Vertical from "./Vertical";
import useCategories from "./useCategories";

export default function ({ nested, navState, categories, parentId, testCase, refData }) {

	const vm = useCategories( navState, categories, parentId, testCase);

	return <>
		{nested &&
			<>
				<Horizontal {...vm} />
				<Content {...vm} />
				<div className="row">
					<SubCategories
						refData={refData}
						nested={nested}
						parentId={parentId}
						testCase={testCase}
						navState={navState}
						categoryId={vm.selected}
						categories={vm.categories}
					/>
				</div>
			</>
		}
		{!nested &&
			<>
				<div className="row">
					<div className="col-9">
						<Content {...vm} />
						<div className="row">
							<SubCategories
								refData={refData}
								nested={nested}
								parentId={parentId}
								testCase={testCase}
								navState={navState}
								categoryId={vm.selected}
								categories={vm.categories}
							/>
						</div>
					</div>
					<div className="col-3">
						<Vertical {...vm} />
					</div>
				</div>
			</>
		}
	</>
}