import React from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';

const RemoteAuthRedirect = () => {
	const [searchParams] = useSearchParams();
	const queryParams = new URLSearchParams(searchParams);

	return (
		<Navigate
			to={
				'/admin/remote-landing' +
				(queryParams ? '?' + queryParams.toString() : '')
			}
		/>
	);
};

export default RemoteAuthRedirect;