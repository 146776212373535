import { EventType, EventTypeToProp } from "../../Common/EventType";
import { useEffect, useState } from "react";

const testCaseProp = EventTypeToProp[EventType.ChildTestCaseChanged];

export default function (refData, parentId, navState) {

	const
		parent = refData.testCases.find(item => item.id === parentId),
		allTestCases = refData.childTestCases,
		allCategories = refData.categories,
		testCases = {};

	const init = () => {
		parent.children.forEach(id => {
			testCases[id] = allTestCases[id];
		});
	};

	init();

	const
		getLast = () => {
			const childTestCases = navState.state[testCaseProp];
			return childTestCases ? childTestCases[parentId] : undefined;
		},
		getSelected = () => getLast() ?? parent.children[0],
		[selected, setSelected] = useState(getSelected()),
		onSelect = id => {
			setSelected(id);
			const childTestCases = navState.state[testCaseProp];
			const updated = { ...childTestCases, [parentId]: id };
			setCategories(getCategories(id));
			navState.onNavEvent(EventType.ChildTestCaseChanged, updated);
		};

	const
		getCategories = id => {
			const updated = {};
			allTestCases[id].categories.forEach(catId => {
				updated[catId] = allCategories[catId];
			});
			return updated;
		},
		[categories, setCategories] = useState(getCategories(selected));

	useEffect(() => {
		navState.onNavEvent(EventType.ChildTestCaseChanged, parent.children[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const updatedId = getSelected();
		setSelected(updatedId);
		setCategories(getCategories(updatedId));
		//the logic only depends on the parentId, nothing else.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentId]);

	return {
		testCases,
		categories,
		selected,
		onSelect
	}
}