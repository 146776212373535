import {
	createNewAwsEval,
	progressState,
} from '../../../../apiclients/AutomatedTests';

import { Mode } from '../../../../constants/PackKeys';
import { getAwsResultUrl } from '../../Common/ReportService';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function (mode, pack, reportName) {
	const isAudit = mode === Mode.audit,
		[evalStarted, setEvalStarted] = useState(false),
		[createAwsEval, setCreateAwsEval] = useState(false),
		[url, setUrl] = useState(''),
		[reportId, setReportId] = useState(reportName),
		[progress, setProgress] = useState(progressState.none),
		[failed, setFailed] = useState(false),
		navigate = useNavigate(),
		startAudit = () => navigate(url),
		startAws = async () => {
			setCreateAwsEval(true);
			const results = await createNewAwsEval(pack, reportId, setProgress);
			if (results.success) {
				const reportUrl = getAwsResultUrl(results.results, pack, mode);
				navigate(reportUrl);
			}
			setFailed(results.failed);
		},
		update = (name, auditor) => {
			if (isAudit) setUrl(`/tests/audit/${pack}/${name}/${auditor}`);
			else setReportId(name);
		},
		start = async () => {
			setEvalStarted(true);
			return isAudit ? startAudit() : startAws();
		};

	return { evalStarted, createAwsEval, progress, failed, start, update };
}
