import { Col, Label, Row } from 'reactstrap';

import React from 'react';
import { useTranslation } from 'react-i18next';

const BrowserManagement = ({ hook }) => {
	const { t } = useTranslation();
	return (
		<>
			<Row className="mt-4 ms-3">
				<h4>{t('reviewScan.browserManagement')}</h4>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.manageBrowserManagement')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.security.yes"
							checked={hook.options.browserManagement.security}
							value="true"
							onChange={() =>
								hook.setInput('browserManagement', 'security', true)
							}
							name="browser.security"></input>
						<label className="me-6" htmlFor="browser.security.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.security.no"
							checked={!hook.options.browserManagement.security}
							value="false"
							onChange={() =>
								hook.setInput('browserManagement', 'security', false)
							}
							name="browser.security"></input>
						<label htmlFor="browser.security.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.implementDnsFilter')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.dns.yes"
							checked={hook.options.browserManagement.dns}
							value="true"
							onChange={() => hook.setInput('browserManagement', 'dns', true)}
							name="browser.dns"></input>
						<label className="me-6" htmlFor="browser.dns.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.dns.no"
							checked={!hook.options.browserManagement.dns}
							value="false"
							onChange={() => hook.setInput('browserManagement', 'dns', false)}
							name="browser.dns"></input>
						<label htmlFor="browser.dns.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<div className="dash"></div>
			<Row className="mt-4 ms-3">
				<h4>{t('reviewScan.phishing')}</h4>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.phishingTrained')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.phishing.yes"
							checked={hook.options.browserManagement.phishing}
							value="true"
							onChange={() =>
								hook.setInput('browserManagement', 'phishing', true)
							}
							name="browser.phishing"></input>
						<label className="me-6" htmlFor="browser.phishing.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.phishing.no"
							checked={!hook.options.browserManagement.phishing}
							value="false"
							onChange={() =>
								hook.setInput('browserManagement', 'phishing', false)
							}
							name="browser.phishing"></input>
						<label htmlFor="browser.phishing.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.emailFilters')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.email.yes"
							checked={hook.options.browserManagement.email}
							value="true"
							onChange={() => hook.setInput('browserManagement', 'email', true)}
							name="browser.email"></input>
						<label className="me-6" htmlFor="browser.email.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.email.no"
							checked={!hook.options.browserManagement.email}
							value="false"
							onChange={() =>
								hook.setInput('browserManagement', 'email', false)
							}
							name="browser.email"></input>
						<label htmlFor="browser.email.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
			<Row className="mt-4 ms-2">
				<Col>
					<Label>{t('reviewScan.phishingResponseScenario')}</Label>
					<div className="mb-3">
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.drill.yes"
							checked={hook.options.browserManagement.drill}
							value="true"
							onChange={() => hook.setInput('browserManagement', 'drill', true)}
							name="browser.drill"></input>
						<label className="me-6" htmlFor="browser.drill.yes">
							{t('button.yes')}
						</label>
						<input
							className="pl-5 me-4"
							type="radio"
							id="browser.drill.no"
							checked={!hook.options.browserManagement.drill}
							value="false"
							onChange={() =>
								hook.setInput('browserManagement', 'drill', false)
							}
							name="browser.drill"></input>
						<label htmlFor="browser.drill.no">{t('button.no')}</label>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default BrowserManagement;
