import { Route, BrowserRouter as Router, Routes as ReactRoutes } from 'react-router-dom';
import {
	dashboard as dashboardRoutes,
	landing as landingRoutes,
	page as pageRoutes,
	penTests as penTestingRoutes,
	print as printRoutes,
	ransomwareDetail as ransomwareDetailRoute,
	ransomware as ransomwareRoutes,
	tests as testRoutes,
	topology as topologyRoutes,
	guide as guideRoute,
	admin as adminRoutes,
	oneTimeSetupGuide as oneTimeSetup,
	detailedInstructionsPage as detailedInstructions,
	settings as userSettingsRoutes,
	termsRoutes
} from './index';

import AuthLayout from '../layouts/Auth';
import Landing from '../layouts/Landing';
import DashboardLayout from '../layouts/Dashboard';
import AdminLayout from '../layouts/Admin';
import Page404 from '../pages/auth/Page404';
import PrintLayout from '../layouts/Print';
import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import TestsLayout from '../layouts/Tests';
//import LandingLayout from "../layouts/Landing";

const childRoutes = (Layout, routes) =>
	routes.map(({ children, path, component: Component }, index) =>
		children ? (
			// Route item with children
			children.map(({ path, component: Component }, index) => (
				<Route
					key={index}
					path={path}
					exact
					element={
						<Layout>
							<Component  />
						</Layout>
					}
				/>
			))
		) : (
			// Route item without children
			<Route
				key={index}
				path={path}
				exact
				element={
					<Layout>
						<Component  />
					</Layout>
				}
			/>
		)
	);

const Routes = () => (
	<Router>
		<ScrollToTop>
			<ReactRoutes>
				{childRoutes(AuthLayout, landingRoutes)}
				{childRoutes(DashboardLayout, dashboardRoutes)}
				{childRoutes(AdminLayout, adminRoutes)}
				{childRoutes(TestsLayout, testRoutes)}
				{childRoutes(TestsLayout, topologyRoutes)}
				{childRoutes(AuthLayout, pageRoutes)}
				{childRoutes(PrintLayout, printRoutes)}
				{childRoutes(AdminLayout, guideRoute)}
				{childRoutes(TestsLayout, penTestingRoutes)}
				{childRoutes(TestsLayout, ransomwareRoutes)}
				{childRoutes(DashboardLayout, ransomwareDetailRoute)}
				{childRoutes(Landing, oneTimeSetup)}
				{childRoutes(Landing, detailedInstructions)}
				{childRoutes(AdminLayout, userSettingsRoutes)}
				{childRoutes(Landing, termsRoutes)}
				<Route
					path="*"
					element={
						<AuthLayout>
							<Page404 />
						</AuthLayout>
					}
				/>
			</ReactRoutes>
		</ScrollToTop>
	</Router>
);

export default Routes;
