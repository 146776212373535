import React from 'react';
import { Trans } from 'react-i18next';

const key = 'testReports.testMetaInfo.hipaa.';

export default [
	{
		title: <Trans i18nKey={key + 'status.title'} />,
		titleBackground: '#66d9ff',
		text: [
			<Trans i18nKey={key + 'status.text1'} />,
			<Trans i18nKey={key + 'status.text2'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'knowledge.title'} />,
		titleBackground: ' #00ace6',
		text: [
			<Trans i18nKey={key + 'knowledge.text1'} />,
			<Trans i18nKey={key + 'knowledge.text2'} />,
			<Trans i18nKey={key + 'knowledge.text3'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'certification.title'} />,
		titleBackground: '#ff6633',
		text: [
			<Trans i18nKey={key + 'certification.text1'} />,
			<Trans i18nKey={key + 'certification.text2'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'auditReadiness.title'} />,
		titleBackground: '#ff8000',
		text: [
			<Trans i18nKey={key + 'auditReadiness.text1'} />,
			<Trans i18nKey={key + 'auditReadiness.text2'} />,
			<Trans i18nKey={key + 'auditReadiness.text3'} />,
			<Trans i18nKey={key + 'auditReadiness.text4'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'change.title'} />,
		titleBackground: '#66cc00',
		text: [<Trans i18nKey={key + 'change.text1'} />],
	},
	{
		title: <Trans i18nKey={key + 'evidence.title'} />,
		titleBackground: '#66d9ff',
		text: [
			<Trans i18nKey={key + 'evidence.text1'} />,
			<Trans i18nKey={key + 'evidence.text2'} />,
		],
	},
];
