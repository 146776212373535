import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

const InternalTextArea = ({
	labelText,
	areaName,
	placeHolder,
	value,
	onValueChange,
	required,
	onError,
	isModified,
	rows,
}) => {
	const [text, setText] = useState(value);
	const [error, setError] = useState(false);
	const [isRequired, setIsRequired] = useState(required);

	useEffect(() => {
		setText(value);
		return () => {
			setText('');
		};
	}, [value]);

	useEffect(() => {
		setIsRequired(required);
	}, [required]);

	useEffect(() => {
		const valueSet = value.length > 0;
		setError(isModified && required && !valueSet);
	}, [required, value, isModified]);

	const onBlur = e => {
		const textValue = e.currentTarget.value;
		const valueSet = textValue.length > 0;
		const isError = isRequired && !valueSet;

		onValueChange(areaName, textValue);
		setError(isError);
		onError(isError);
	};

	return (
		<>
			<div className="w-100">
				<label htmlFor={areaName}>{labelText}</label>
				<textarea
					name={areaName}
					placeholder={placeHolder}
					onChange={e => setText(e.target.value)}
					value={text}
					onBlur={onBlur}
					rows={rows}
					className="d-flex w-100 h-100"></textarea>
			</div>
		</>
	);
};

const OrgControls = ({ value, onChange, onError, isModified }) => (
	<InternalTextArea
		labelText={t('form.organisationControls')}
		areaName="orgControls"
		placeHolder={t('form.organisationControlsPlaceholder')}
		value={value}
		onValueChange={onChange}
		onError={onError}
		required={true}
		isModified={isModified}
		rows={4}
	/>
);

const Observation = ({ value, onChange, onError, isModified }) => (
	<InternalTextArea
		labelText={t('form.auditObservation')}
		placeHolder={t('form.auditObservationPlaceholder')}
		areaName="auditObservation"
		value={value}
		onValueChange={onChange}
		onError={onError}
		required={false}
		isModified={isModified}
		rows={4}
	/>
);

export { OrgControls, Observation };
