import React from 'react';

function ProgressCircleForGuide({ cirlce, descp, id }) {
	return (
		<>
			<a href={id} style={{ marginLeft: '14px', marginTop: '35px' }}>
				<div className="d-flex align-items-center">
					<img src={cirlce} alt={cirlce} className="me-2" />
					<p className=" m-0 p-0">{descp}</p>
				</div>
			</a>
		</>
	);
}

export default ProgressCircleForGuide;
