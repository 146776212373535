import { TALASMART_RATE_LIMIT } from '../pages/talasmart/constants';
import { httpMethods } from './Common';
import { getKeys } from '../services/AuthService';

const uri = process.env.REACT_APP_TS_API_URL;
const noop = v => v;

const STATUS_RESPONSE = {
	completed: 'Done',
	queued: 'Queued',
	processing: 'Processing',
	error: 'Error',
};

const RECHECK_STATUS_TIMEOUT = 1000;

const statusHandler = (id, success, error) => {
	const checkStatus = async () => {
		const keys = getKeys();

		const statusResponse = await fetch(
			uri + `/api/talasmartquery/status/${id}`,
			{
				headers: {
					...keys,
				},
			}
		);
		const statusData = await statusResponse.json();
		// console.log("status data for", id, " - ", statusData);
		if (statusData.status === STATUS_RESPONSE.completed) {
			const aiResp = JSON.parse(statusData.response);
			// console.log("got AI response from status:", aiResp.response);
			success(aiResp.response);
			return aiResp.response;
		} else if (
			statusData.status === STATUS_RESPONSE.processing ||
			statusData.status === STATUS_RESPONSE.queued
		) {
			setTimeout(checkStatus, RECHECK_STATUS_TIMEOUT);
			return TALASMART_RATE_LIMIT;
		} else {
			error();
		}
	};

	checkStatus();
};

const talaSmartQuery = async (body, success = noop, error = console.log) => {
	const keys = getKeys();

	//console.log('talaSmartQuery incoming body:', body);
	var queryBody = {};
	if (body.K8SLogQuery === "k8slog")
	{
		queryBody = {
			QueryName: body.Query,
			LogID: body.LogID,
			K8SLogQuery: body.K8SLogQuery
		};

	}
	else
	{
		 queryBody = {
			QueryName: body.Query,
			ResourceInfo: {
				SecurityTestRule: body.SecurityTestRule,
				ResourceType: body.ResourceType,
				ResourceId: body.ResourceID,
				RootCause: body.RootCause,
				RawResourceId: body.RawResourceId,
			},
		};
	}
	//console.log('talaSmartQuery processed queryBody:', queryBody);

	try {
		const response = await fetch(uri + '/api/talasmartquery', {
			method: httpMethods.post,
			headers: {
				reportid: body.reportInfo.ReportId,
				conformancepackname: body.reportInfo.PackName,
				numfrags: body.reportInfo.Fragments,
				...keys,
			},
			body: JSON.stringify(queryBody),
		});

		const data = await response.json();
		// console.log('tsaquery resp body:', data);

		if (data.status === STATUS_RESPONSE.completed) {
			const aiResp = JSON.parse(data.response);
			// console.log('got AI response from query:', aiResp.response);
			success(aiResp.response);
			return aiResp.response;
		}

		if (
			data.status === STATUS_RESPONSE.processing ||
			data.status === STATUS_RESPONSE.queued
		) {
			statusHandler(data.queryid, success, error);
		}

		if (!response.ok) {
			error();
		}
	} catch (e) {
		error(e);
	}
};

const talaSmartGetResourceInfo = async (
	body,
	success = noop,
	error = console.log
) => {
	const { accountId, resourceId, issue } = body;

	// console.log('talaSmartGetResourceInfo body:', body);
	// console.log('account:', accountId, 'resource:', resourceId, 'issue:', issue);
	return;
	try {
		const url =
			uri +
			`/talasmartgetresourceinfo?AccountID=${accountId}&ResourceID=${resourceId}&Issue=${issue}`;
		const response = await fetch(url);

		if (!response.ok) {
			error(response);
		}

		const data = await response.json();
		success(data);
		return;
	} catch (err) {
		error(err.message);
	}
};

const talaSmartSetResourceInfo = async (
	body,
	success = noop,
	error = console.log
) => {
	return;
	try {
		const url = uri + '/talasmartsetresourceinfo';

		const response = await fetch(url, {
			method: httpMethods.post,
			body,
		});

		if (!response.ok) {
			error(response);
		}

		success();
	} catch (err) {
		error(err.message);
	}
};

export { talaSmartQuery, talaSmartGetResourceInfo, talaSmartSetResourceInfo };
