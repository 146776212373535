import { isAzure } from '../../../apiclients/Common';

const providerTruncate = actual => {
	const providers = 'providers';
	const length = providers.length;
	return actual.substring(actual.indexOf(providers) + length, actual.length);
};

const lastSlashTruncate = actual => actual.substr(actual.lastIndexOf('/') + 1);

const tooLong = actual => actual.length > 30;

const azureName = actual =>
	tooLong(actual) ? lastSlashTruncate(actual) : actual;

const getTruncatedResourceName = actual =>
	isAzure() ? azureName(actual) : actual;

const getTruncatedResourceIdOrName = item => {
	if (item.hasOwnProperty('ResourceId')) {
		return getTruncatedResourceName(item.ResourceId);
	} else if (item.hasOwnProperty('ResourceName')) {
		return getTruncatedResourceName(item.ResourceName);
	} else {
		console.error('unknown object type for resource', item);
		return item;
	}
};

const getFullResourceIdOrName = item => {
	if (item.hasOwnProperty('ResourceId')) {
		return item.ResourceId;
	} else if (item.hasOwnProperty('ResourceName')) {
		return item.ResourceName;
	} else {
		console.error('unknown object type for resource', item);
		return item;
	}
};

export {
	getTruncatedResourceName,
	getTruncatedResourceIdOrName,
	getFullResourceIdOrName,
};
