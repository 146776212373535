import { Col, Input, Row } from 'reactstrap';

import { Label } from 'reactstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ReviewScanDurationInput = ({
	labelfor,
	onChange,
	children,
	value,
	unit,
}) => {
	if (undefined === value) {
		value = 0;
	}
	const { t } = useTranslation();
	return (
		<>
			<Label htmlFor={onChange}>{children}</Label>
			<Row>
				<Col>
					<Input
						type="number"
						className="float-start"
						value={value}
						name={labelfor}
						style={{ borderRadius: '25px' }}
						onChange={e =>
							onChange(labelfor, Math.abs(e.target.value))
						}></Input>
				</Col>
				<Col>
					<Input
						value={unit}
						type="select"
						name={labelfor + 'Unit'}
						onChange={e => onChange(labelfor + 'Unit', e.target.value)}>
						<option value="1">{t('reviewScan.week')}</option>
						<option value="2">{t('reviewScan.day')}</option>
						<option value="3">{t('reviewScan.hour')}</option>
						<option value="4">{t('reviewScan.minute')}</option>
						<option value="5">{t('reviewScan.second')}</option>
					</Input>
				</Col>
			</Row>
		</>
	);
};

export default ReviewScanDurationInput;
