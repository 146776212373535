import React from 'react';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/1.svg';
import icon2 from '../../../assets/img/2.svg';
import icon3 from '../../../assets/img/3.svg';
import icon4 from '../../../assets/img/4.svg';
import icon5 from '../../../assets/img/5.svg';
import icon6 from '../../../assets/img/6.svg';
import icon7 from '../../../assets/img/7.svg';
import icon8 from '../../../assets/img/8.svg';
import figure39 from '../../../assets/img/figure9.1.png';
import figure40 from '../../../assets/img/figure9.2.png';
import figure41 from '../../../assets/img/figure9.3.png';
import figure42 from '../../../assets/img/figure9.4.png';
import figure43 from '../../../assets/img/figure9.5.png';
import figure44 from '../../../assets/img/figure9.6.png';

function Step9() {
	const { t } = useTranslation();
	return (
		<div id="step9" className="instructions-steps mt-4">
			<div className="d-flex gap-2">
				<h5 className="steps-number">{t('detailedInstructions.step')} 9</h5>
				<div>
					<h5 className="steps-title">
						{t('detailedInstructions.deleteTalaSecure')}
					</h5>
				</div>
			</div>
			<p>{t('detailedInstructions.24hours')}</p>
			<div className="d-flex ms-6">
				<img src={icon1} alt="icon1" className="img" />
				{t('detailedInstructions.awsConsole')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon2} alt="icon2" className="img" />
				{t('detailedInstructions.goAWS')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon3} alt="icon3" className="img" />
				{t('detailedInstructions.locateServicesConsole')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.complianceSection')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.clickPolicies')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure39} alt="figure9.1" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure9.1')}
					</p>
				</div>
			</div>
			<div className="d-flex ms-6">
				<img src={icon6} alt="icon6" className="img" />
				{t('detailedInstructions.searchBar')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure40} alt="figure9.2" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure9.2')}
					</p>
				</div>
			</div>
			<div className="d-flex ms-6">
				<img src={icon7} alt="icon7" className="img" />
				{t('detailedInstructions.deleteBtn')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure41} alt="figure9.3" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure9.3')}
					</p>
				</div>
			</div>
			<h5 className="steps-number">{t('detailedInstructions.or')}</h5>
			<div className="d-flex ms-6">
				<img src={icon1} alt="icon1" className="img" />
				{t('detailedInstructions.VisitAWS')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon2} alt="icon2" className="img" />
				{t('detailedInstructions.login')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon3} alt="icon3" className="img" />
				{t('detailedInstructions.locateServices')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon4} alt="icon4" className="img" />
				{t('detailedInstructions.IAM')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon5} alt="icon5" className="img" />
				{t('detailedInstructions.clickGroups')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure42} alt="figure9.4" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure9.4')}
					</p>
				</div>
			</div>
			<div className="d-flex ms-6">
				<img src={icon6} alt="icon6" className="img" />
				{t('detailedInstructions.clickTalaSecure')}
			</div>
			<div className="d-flex ms-6">
				<img src={icon7} alt="icon7" className="img" />
				{t('detailedInstructions.permissions')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure43} alt="figure9.5" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure9.5')}
					</p>
				</div>
			</div>
			<div className="d-flex ms-6">
				<img src={icon8} alt="icon8" className="img" />
				{t('detailedInstructions.selectTalaSecure')}
			</div>
			<div className="d-flex justify-content-center align-items-center">
				<div>
					<img src={figure44} alt="figure9.6" />
					<p className="text-center font-italic">
						{t('detailedInstructions.figure9.6')}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Step9;
