import '../Assessment.css';

import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';

import Container from 'reactstrap/lib/Container';
import Loader from '../../../../components/Loader';
import { Stages } from '../../Common/AssessmentStage';
import meterImage from '../../../../assets/img/ransomware-meter-animated.gif';
import meterImageComplete from '../../../../assets/img/ransomware-meter-complete.jpg';
import useAnalysing from '../../Hooks/useAnalysing';
import useRender from '../../Hooks/useRender';
import { useTranslation } from 'react-i18next';

const progress = [
	{ delay: 3, text: 'analysing.wizard' },
	{ delay: 6, text: 'analysing.collecting' },
	{ delay: 7, text: 'analysing.analysingComplexity' },
	{ delay: 8, text: 'analysing.userBase' },
	{ delay: 9, text: 'analysing.accessingVulnerabilities' },
	{ delay: 10, text: 'analysing.accessingExposure' },
	{ delay: 6, text: 'analysing.accessPatterns' },
	{ delay: 8, text: 'analysing.businessContinuityReadiness' },
	{ delay: 9, text: 'analysing.disasterRecovery' },
	{ delay: 7, text: 'analysing.securityStrength' },
	{ delay: 9, text: 'analysing.profillingRisk' },
	{ delay: 10, text: 'analysing.aggregatingResults' },
];

const ProgressItem = ({ index, text, completed }) => {
	const pending = index > completed;
	const run = completed === index;
	let backgroundClass = '';
	let stateTextClass = '';

	let stateText = '';
	if (pending) {
		stateText = 'Pending';
		backgroundClass = 'pending';
		stateTextClass = '';
	} else if (run) {
		stateText = 'Processing';
		backgroundClass = 'pending';
		stateTextClass = 'text-primary';
	} else {
		stateText = 'Done';
		backgroundClass = 'complete';
		stateTextClass = 'text-success';
	}

	return (
		<div id="ransomware-analysis">
			<Row>
				<Col xs="9">
					<Row>
						<Col xs="2" className="me-1">
							<strong
								className={`progress ${backgroundClass} me-2`}
								style={{ padding: '11px 5px 15px 17px' }}>
								{index + 1}
							</strong>
						</Col>
					</Row>
					<Row className="p-0"></Row>
				</Col>
				<Col xs="3" className="pt-2"></Col>
			</Row>
		</div>
	);
};

const Progress = ({ inProgress, setContext }) => {
	const [completed, set] = useState(0);

	useEffect(() => {
		let counter = 0;
		let timeout;

		const repeater = delay => {
			timeout = delay * 100;
			setTimeout(() => {
				counter++;
				set(counter);

				if (progress[counter]) {
					setContext(progress[counter].text);
					repeater(progress[counter].delay);
				} else {
					inProgress(false);
					clearTimeout(timeout);
				}
			}, timeout);
		};

		inProgress(true);
		repeater(progress[counter].delay);

		return () => clearTimeout(timeout);
	}, []);

	return (
		<>
			<i
				className="mb-5"
				style={{
					display: 'flex',
					fontSize: 18,
					fontWeight: 400,
					justifyContent: 'center',
				}}></i>
			<Container style={{ display: 'flex', justifyContent: 'center' }}>
				{progress.map((t, i) => (
					<ProgressItem key={i} index={i} text={t.text} completed={completed} />
				))}
			</Container>
		</>
	);
};

const Analysing = ({
	stage,
	onNext,
	exclusion,
	setExclusion,
	mode,
	reportName,
}) => {
	const [text, setText] = useState('analysing.processing');
	const { t } = useTranslation();
	const render = useRender(stage, Stages.Analysing);
	const hook = useAnalysing(exclusion, setExclusion, mode);

	const moveNext = option => {
		if (option === 0) {
			hook.startEval(reportName, () => onNext(option));
		} else {
			onNext(option);
		}
	};

	return (
		render && (
			<Card>
				<CardHeader>
					<h3>{t('analysing.title')}</h3>
				</CardHeader>
				<CardBody>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						{!hook.evaluating && hook.running && <i>{t(text)}</i>}
						{!hook.evaluating && !hook.running && (
							<div style={{ fontWeight: '800' }}>
								{t(text) + ' - ' + t('analysing.done')}
							</div>
						)}
					</div>
					{hook.evaluating && <Loader />}
					{!hook.evaluating && (
						<>
							<Container>
								<div
									className="pb-4 pt-2 mt-4"
									style={{ display: 'flex', justifyContent: 'center' }}>
									<img
										style={{ width: '20%' }}
										src={hook.running ? meterImage : meterImageComplete}
										alt="credit rating"
									/>
								</div>
								<div className="mt-2">
									<Progress inProgress={hook.setRunning} setContext={setText} />
								</div>
							</Container>
							{!hook.running && (
								<div className="mt-4" style={{ textAlign: 'center' }}>
									<h5>{t('analysing.infoTitle')}</h5>
									<p></p>
									<small>{t('analysing.infoDescription')}</small>
									<p></p>
									<Button
										size="md"
										color="secondary"
										onClick={() => moveNext(0)}>
										<span>{t('analysing.noButton')}</span>
									</Button>
									<Button
										className="ms-4"
										size="md"
										color="primary"
										onClick={() => moveNext(1)}>
										<span>{t('analysing.yesButton')}</span>
									</Button>
								</div>
							)}
						</>
					)}
				</CardBody>
			</Card>
		)
	);
};

export default Analysing;
