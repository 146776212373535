import { failure, getAuthHeaders, httpMethods, progressState, resolveUri } from "./Common";

// import { PackKeys } from "../constants/PackKeys";
// import hipaa from "../pages/tests/AuditData/auditestresultstemplatehipaa.json";
// import nist from "../pages/tests/AuditData/audittestresultstemplatenistcsf.json";
// import pci from "../pages/tests/AuditData/audittestresultstemplatepcidss.json";
// import soc2 from "../pages/tests/AuditData/audittestresultstemplatesoc2.json";

// const mockAuditData = {
// 	[PackKeys.NIST] : nist,
// 	[PackKeys.SOC2] : soc2,
// 	[PackKeys.HIPAASECURITY] : hipaa,
// 	[PackKeys.PCIDSS] : pci,
// }

const getReportData = async audit => {

    //todo: undo mock before pushing to dev
    //const mockData = mockAuditData[audit.pack];

    //return  { is404 : false, succeeded : true, data: mockData, auditorName: "abjbhat@gmail.com" };

    const requestOptions = {
        method: httpMethods.get,
        headers: getAuthHeaders({
            "ConformancePackName": audit.pack,
            "ReportId": audit.reportId,
            "EvalId": audit.EvalId
        })
    };

    const uri = resolveUri('audit-evaluation-results');
    const response = await fetch(uri, requestOptions);
    const is404 = response.status === 404;
    const succeeded = !is404 && response.ok;
    const result = succeeded ? await response.json() : { data: {}, auditorName: "" };

    return { is404, succeeded, ...result };
};

const getAuditTemplate = async packName => {

    const requestOptions = {
        method: httpMethods.get,
        headers: getAuthHeaders({
            "ConformancePackName": packName
        }),
        redirect: 'follow'
    };

    const uri = resolveUri('audit-results-template');
    const results = await fetch(uri, requestOptions);
    if (!results.ok) {
        console.error("Could not get template data", results);
    }
    return results.ok ? await results.json() : failure(results);
}

const saveReportData = async (audit, onProgress) => {

    var requestOptions = {
        method: httpMethods.put,
        headers: getAuthHeaders({
            "ConformancePackName": audit.Pack,
            "ReportId": audit.ReportId,
            "EvalId": audit.EvalId ?? 0
        }),
        body: JSON.stringify(audit.results),
        redirect: 'follow'
    };

    if (onProgress) onProgress(progressState.auditStart);

    try {
        const response = await fetch(resolveUri('audit-evaluation-results'), requestOptions);
        //because the server doesn't return a correctly encoded JS string :rolling_eyes:
        if (response.status === 200) {
            if (onProgress) onProgress(progressState.auditSaved);
            return await response.json();
        }
        return { failed: true };
    } catch (error) {
        console.log("caught an error", error);
        if (onProgress) onProgress(progressState.auditError);
        return false;
    }
}

export { getReportData, getAuditTemplate, saveReportData };