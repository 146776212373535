import React, { useState, useMemo, useEffect } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Table } from 'reactstrap';
import { PlusCircle, MinusCircle } from 'react-feather';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import { t } from 'i18next';
import ResourceDetails from '../../Controls/ResourceDetails';

const VulnerabilityDetails = ({ issues }) => {
    const columns = useMemo(() => [
        {
            accessorKey: 'ResourceType',
            header: () => t('Resource Type'),
            cell: info => info.getValue()
        },
        {
            accessorKey: 'ResourceName',
            header: () => t('Resource Name'),
            cell: info => info.getValue()
        },
        {
            accessorKey: 'SecurityTestRule',
            header: () => 'Rule',
            cell: info => info.getValue()
        },
        {
            accessorKey: 'ResourceArn',
            header: () => 'Resource Arn',
            cell: info => info.getValue()
        }
    ], []);

    const [visibleSections, setVisibleSections] = useState({});

    // Set all sections to be visible initially
    useEffect(() => {
        const allSectionsVisible = Object.keys(issues).reduce((acc, key) => ({
            ...acc,
            [key]: true
        }), {});
        setVisibleSections(allSectionsVisible);
    }, [issues]);

    const toggleSection = (category) => {
        setVisibleSections(prev => ({
            ...prev,
            [category]: !prev[category]
        }));
    };

    return (
        <Card style={{ marginTop: '20px' }}>
            <CardTitle>
                <h5 style={{ paddingLeft: '20px', paddingTop: '10px' }}>{t('report.vulnerabilityDetails')}</h5>
            </CardTitle>
            {Object.entries(issues).map(([category, items]) => (
                <Card key={category}>
                    <CardHeader>
                        <CardTitle style={{ display: "flex" }}>
                            <h5 style={{ paddingTop: '10px' }}>{category}</h5>
                            <button onClick={() => toggleSection(category)} style={{ border: 'none', background: 'transparent' }}>
                                {visibleSections[category] ? <MinusCircle size={20} /> : <PlusCircle size={20} />}
                            </button>
                        </CardTitle>
                    </CardHeader>
                    {visibleSections[category] && (
                        <CardBody>
                            <Table >
                                <thead>
                                    <tr>
                                        {columns.map(column => (
                                            <th key={column.accessorKey}>
                                                {typeof column.header === 'function' ? column.header() : column.header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>
                                            {columns.map(column => (
                                                <td key={column.accessorKey}>
                                                    {column.cell ? column.cell({ getValue: () => item[column.accessorKey] }) : item[column.accessorKey]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    )}
                </Card>
            ))}
        </Card>
    );
};

export default VulnerabilityDetails;
