/* eslint-disable no-useless-computed-key */

import React, { useMemo, useRef, useState } from 'react';

import { EvaluationApiClient } from '../../../../apiclients/EvaluationApiClient';
import { PackKeys } from '../../../../constants/PackKeys';
import PrintButton from './PrintButton';
import PrintContext from '../Common/PrintContext';
import ReportHeader from './ReportHeader';
import ReportTypeIntroduction from './ReportTypeIntroduction';
import TableofContents from './TableofContents';
import TestDetails from './TestDetails';
import TopologyCompliance from './TopologyCompliance';
import TopologyDetails from './TopologyDetails';
import TopologySummary from './TopologySummary';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

const getReport = (params, location) => {
	const sp = new URLSearchParams(location.search),
		pp = params,
		report = {
			reportId: pp.reportid,
			pack: pp.pack,
			PackName: pp.pack,
			ReportId: pp.reportid,
			fragments: sp.get('fragments'),
			evalId: sp.get('evalId'),
			mode: sp.get('mode'),
			hasAuditReport:
				sp.has('mode') && JSON.parse(sp.get('mode').toLowerCase() === 'audit'),
		};

	return report;
};

const InternalPrintReport = React.forwardRef((props, ref) => (
	<div ref={ref}>
		<ReportHeader details={props.headerDetails} />
		<TableofContents
			packName={props.packName}
			hasAuditReport={props.hasAuditReport}
		/>
		<ReportTypeIntroduction packName={props.packName} />
		<TopologySummary />
		<TopologyCompliance />
		<TopologyDetails />
		{props.hasAuditReport && (
			<TestDetails
				title="AUDIT TEST RESULTS"
				audit={true}
				packName={props.packName}
			/>
		)}
		<TestDetails
			title="AUTOMATION TEST RESULTS"
			audit={false}
			packName={props.packName}
			failed={props.failed.value}
			onFail={props.failed.setFailed}
		/>
	</div>
));

const PrintReport = () => {
	const params = useParams();
	const location = useLocation();
	const reportRef = useRef();
	const [ctr, setCtr] = useState(0);
	const [loadFailed, setFailed] = useState(false);
	const [auditorName, setAuditorName] = useState('');
	const topology = useState([]);
	const client = new EvaluationApiClient();
	const report = getReport(params, location);
	const documentTitle = report.title;
	const increment = () => setCtr(v => v + 1);
	const printMode = true;
	const counter = { value: ctr, increment: increment };
	const auditor = { name: auditorName, setName: setAuditorName };
	const failed = { value: loadFailed, setFailed: setFailed };
	const { t } = useTranslation();

	const headerConstants = useMemo(() => {
		return {
			[PackKeys.NIST]: {
				pageTitle: t('testReports.packPages.nist.title'),
				subTitle: t('testReports.packPages.nist.subTitle'),
			},
			[PackKeys.SOC2]: {
				pageTitle: t('testReports.packPages.soc2.title'),
				subTitle: t('testReports.packPages.soc2.subTitle'),
			},
			[PackKeys.HIPAASECURITY]: {
				pageTitle: t('testReports.packPages.hipaa.title'),
				subTitle: t('testReports.packPages.hipaa.subTitle'),
			},
			[PackKeys.PCIDSS]: {
				pageTitle: t('testReports.packPages.pci.title'),
				subTitle: t('testReports.packPages.pci.subTitle'),
			},
			[PackKeys.CMMCL3]: {
				pageTitle: t('testReports.packPages.cmms.title'),
				subTitle: t('testReports.packPages.cmms.subTitle'),
			},
		};
	}, [t]);

	const headerDetails = headerConstants[report.pack];

	const handlePrint = useReactToPrint({
		content: () => reportRef.current,
		onBeforeGetContent: () => {
			const wrapper = document.getElementById('print-topology-wrapper');
			if (wrapper) wrapper.remove();
		},
		documentTitle: documentTitle,
		onAfterPrint: () => setCtr(5),
	});

	return (
		<div className="pt-2">
			<PrintContext.Provider
				value={{
					client,
					report,
					counter,
					printMode,
					auditor,
					topology,
					failed,
				}}>
				<PrintButton handlePrint={handlePrint} failed={failed.value} />
				<InternalPrintReport
					ref={reportRef}
					packName={report.pack}
					hasAuditReport={report.hasAuditReport}
					failed={failed}
					headerDetails={headerDetails}
				/>
			</PrintContext.Provider>
		</div>
	);
};

export default PrintReport;