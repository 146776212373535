import { FormFeedback, FormGroup, Input } from 'reactstrap';
import React, { useState } from 'react';
import { t } from 'i18next';

const expr =
	/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export default function ({ onUrlChanged, loading }) {
	const [name, setName] = useState('');
	const [invalid, setInvalid] = useState('');

	const onBlur = value => {
		const isValid = value.match(expr) !== null;
		setName(value);
		setInvalid(!isValid);
		onUrlChanged(value, isValid);
	};

	return (
		<FormGroup>
			<h4 className="pt-1">{t('tests.scanUrl')}</h4>
			<Input
				name="scanName"
				className="d-inline ms-3 w-50"
				type="text"
				disabled={loading}
				value={name}
				onChange={e => setName(e.target.value)}
				onFocus={e => e.target.select()}
				onBlur={e => onBlur(e.target.value)}
				placeholder="https://yourserver.local"
				invalid={invalid}
			/>
			<FormFeedback className="ms-3">
				{t('tests.enterUrl')} - https://yourserver.local
			</FormFeedback>
		</FormGroup>
	);
}
