import React, { useEffect, useState, useMemo } from 'react';
import { Button, Card, CardBody, CardHeader, Input, Row } from 'reactstrap';
import { ReactComponent as CloseSvg } from '../../../../assets/img/close.svg';
import { ReactComponent as ArrowUpRight } from '../../../../assets/img/arrowUpRight.svg';
import InfoModal from '../../../../components/InfoModal';
import useReportInfo from '../../../../hooks/useReportInfo';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import {
	getFullResourceIdOrName,
	getTruncatedResourceIdOrName,
} from '../../Common/RansomwareUtilites';
import SeveritySelect from './SeveritySelect';
import { BTable } from '../../../../components/BTable';
import {
	useReactTable,
	getCoreRowModel,
	getSortedRowModel,
	getPaginationRowModel,
	getFilteredRowModel,
	flexRender,
} from '@tanstack/react-table';

const cardBackgroundStyle = {
	background: '#F4F7FC',
	minHeight: '3.5rem',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
};

const getColumns = onClick => [
	{
		accessorKey: 'ResourceType',
		header: <Trans i18nKey="vulnerabilities.resourceType" />,
		cell: info => (
			<p className="text-black" onClick={() => onClick(info.row.original)}>
				{info.getValue()}
			</p>
		),
		size: 300,
	},
	{
		accessorKey: 'SecurityTestRule',
		header: <Trans i18nKey="vulnerabilities.issue" />,
		cell: info => (
			<p className="text-danger" onClick={() => onClick(info.row.original)}>
				{info.getValue()}
			</p>
		),
		size: 300,
	},
	{
		accessorKey: 'ResourceName',
		header: <Trans i18nKey="vulnerabilities.resourceName" />,
		cell: info => (
			<div
				title={getFullResourceIdOrName(info.row.original)}
				onClick={() => onClick(info.row.original)}>
				{getTruncatedResourceIdOrName(info.row.original)}
			</div>
		),
		size: 300,
	},
];

const IssueTableInfo = ({
	packName,
	list,
	onClose,
	data,
	onSelectSeverity = () => {},
	onNewTab = () => {},
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [openedItem, setOpenedItem] = useState(null);
	const { formatReport, data: resultData, fetchData } = useReportInfo();
	const dataExists = list && list.length > 0;
	const noData = !dataExists;
	const [initialPackName, setInitialPackName] = useState(packName);

	useEffect(() => {
		setInitialPackName(packName);
	}, [initialPackName]);

	const onReportClick = item => {
		setIsOpen(true);
		setOpenedItem(item);
	};

	const handleSearch = (table, searchval) => {
		table.setGlobalFilter(searchval);
	};

	const columns = getColumns(onReportClick);

	const table = useReactTable({
		data: list,
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		initialState: {
			pagination: {
				pageIndex: 0,
				pageSize: 10,
			},
			sorting: [
				{
					id: 'ResourceType',
					desc: false,
				},
			],
		},
	});

	useEffect(() => {
		fetchData(data.report, n => n, data.issues);
	}, [fetchData, data]);

	const formattedReport = useMemo(() => {
		if (!openedItem) return { data: {}, info: {} };

		const resource = resultData?.nodes?.find(
			item => item?.data?.id === openedItem?.ResourceName
		);

		const formatData = formatReport(resource?.data, data.report);

		if (!Object.keys(formatData.data).length) {
			// Add an entry for an item that is not otherwise in resultData.
			const ruleissues = data.issues[openedItem.SecurityTestRule];

			const resourceissues = ruleissues.filter(
				item =>
					item?.ResourceType === openedItem?.ResourceType &&
					(item?.ResourceName === openedItem?.ResourceName ||
						item?.ResourceId === openedItem?.ResourceId)
			);
			var realissues = [];
			resourceissues.forEach(element => {
				realissues.push({
					RealSecurityTestRule: element.SecurityTestRule,
					SecurityTestRule: openedItem.SecurityTestRule,
					ResourceName: element.ResourceName,
					ResourceId: element.ResourceId,
					ResourceType: element.ResourceType,
					ResourceArn: element.ResourceArn,
					RootCause: element.RootCause,
				});
			});
			const resData = {
				data: {
					compliance: 'Non-Compliant',
					id: openedItem.ResourceId || openedItem.ResourceName,
					name: openedItem.ResourceName,
					resourcetype: openedItem.ResourceType,
					'non-compliant-rules': [openedItem.SecurityTestRule],
				},
				info: realissues,
			};
			return resData;
		}
		return formatData || { data: {}, info: {} };
	}, [data.report, data.issues, formatReport, openedItem, resultData.nodes]);

	return (
		<Card className=" vulnerability-wrap " style={{ maxHeight: '85vh', overflowY: 'overlay', padding:"20px" }}>
			<CardHeader style={cardBackgroundStyle}>
				<h4 className="text-uppercase font-weight-bold">{initialPackName}</h4>
				<SeveritySelect
					value={data?.openedSeverity}
					onChange={onSelectSeverity}
				/>
				<Input
					type="text"
					placeholder={t('vulnerabilities.searchByResourceName')}
					onChange={e => {
						handleSearch(table, e.target.value);
					}}
					style={{ width: '200px', marginLeft: '1rem' }}
				/>
				<Row className="gap-1">
					<Button
						onClick={onNewTab}
						size="sm"
						className="legend-button close-button">
						<ArrowUpRight />
					</Button>
					<Button
						onClick={onClose}
						size="sm"
						className="legend-button close-button">
						<CloseSvg />
					</Button>
				</Row>
			</CardHeader>
			<CardBody style={{
				 minHeight: '500px',
				  padding: 0,
				  }}>
				{noData ? (
					<h5>{t('vulnerabilities.notFound')}</h5>
				) : (
					<BTable
					
					table={table} pageSizes={[10, 20, 50]} />
				)}
			</CardBody>
			{isOpen && !!Object.keys(formattedReport?.data || {}).length && (
				<div style={{ position: 'absolute', right: 0, height: '100%' }}>
					<InfoModal
						data={formattedReport}
						onClose={() => setIsOpen(false)}
						report={data.report}
					/>
				</div>
			)}
		</Card>
	);
};

export default IssueTableInfo;
