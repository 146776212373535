import { Badge, Collapse } from 'reactstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'; // Importing useLocation and useNavigate instead of withRouter
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { faSitemap, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { getAllItems, removeItem } from '../services/browserStorageService';
import {
	getChildAccounts,
	getCurrentChildAccount,
	getCurrentRegion,
	getRegions,
	getUser,
	getUserView,
	isOrgAccount,
	setCurrentChildAccount,
	setCurrentRegion,
} from '../services/AuthService';
import { isDemoAccount, isTrialAccount } from '../services/accountTypeService';

import { EvaluationApiClient } from '../apiclients/EvaluationApiClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { faFlag } from '@fortawesome/free-regular-svg-icons';
import routes from '../routes/index';

import { ReactComponent as LogoSvg } from '../assets/img/logo.svg';

const shorten = (str, len) =>
	str
		? str.length <= len
			? str
			: str.substr(0, len / 2) +
			  '...' +
			  str.substr(str.length - len / 2, str.length)
		: 'UNKNOWN';

const initOpenRoutes = location => {
	/* Open collapse element that matches current url */
	const pathName = location.pathname;

	let _routes = {};

	routes.forEach((route, index) => {
		const isActive = pathName.indexOf(route.path) === 0;
		const isOpen = route.open;
		const isHome = route.containsHome && pathName === '/' ? true : false;

		_routes = Object.assign({}, _routes, {
			[index]: isActive || isOpen || isHome,
		});
	});

	return _routes;
};

const SidebarCategory = ({
	name,
	badgeColor,
	badgeText,
	icon,
	isOpen,
	children,
	onClick,
	location,
	to,
}) => {
	const currentLocation = useLocation(); // Using useLocation hook to get current location

	const getSidebarItemClass = path => {
		console.log(
			currentLocation.pathname.indexOf('remote-landing'),
			'fdnjaldfnakldnakldncmaskl'
		);
		return (currentLocation.pathname
			.toLowerCase()
			.indexOf(path.toLowerCase()) !== -1 ||
			(currentLocation.pathname === '/' &&
				path.toLowerCase() === '/dashboard')) &&
			currentLocation.pathname.indexOf('ransomware') === -1
			? 'active'
			: '';
	};

	return (
		<li className={'sidebar-item sidebar-category my-22-step' + getSidebarItemClass(to)}>
			<span
				data-toggle="collapse"
				className={'sidebar-link ' + (!isOpen ? 'collapsed' : '')}
				onClick={onClick}
				aria-expanded={isOpen ? 'true' : 'false'}
			>
				<FontAwesomeIcon icon={icon} fixedWidth className="align-middle me-2" />{' '}
				<span className="align-middle">{name}</span>
				{badgeColor && badgeText ? (
					<Badge color={badgeColor} size={18} pill className="sidebar-badge">
						{badgeText}
					</Badge>
				) : null}
			</span>
			<Collapse isOpen={isOpen}>
				<ul id="item" className={'sidebar-dropdown list-unstyled'}>
					{children}
				</ul>
			</Collapse>
		</li>
	);
};

const SidebarItem = ({ name, badgeColor, badgeText, icon, location, to }) => {
	const currentLocation = useLocation(); // Using useLocation hook to get current location

	const getSidebarItemClass = path => {
		return currentLocation.pathname === path ? 'active' : '';
	};

	return (
		<li className={'sidebar-item ' + getSidebarItemClass(to)}>
			<NavLink to={to} className="sidebar-link ">
				{icon ? (
					<React.Fragment>
						<FontAwesomeIcon
							icon={icon}
							fixedWidth
							className="align-middle me-2"
						/>{' '}
						<span className="align-middle">{name}</span>
					</React.Fragment>
				) : (
					name
				)}{' '}
				{badgeColor && badgeText ? (
					<Badge color={badgeColor} size={18} pill className="sidebar-badge">
						{badgeText}
					</Badge>
				) : null}
			</NavLink>
		</li>
	);
};

const Sidebar = ({ sidebar }) => {
	const location = useLocation(); // Using useLocation hook to get current location
	const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));
	const navigate = useNavigate();
	const { t } = useTranslation();
	const toggle = index => {
		Object.keys(openRoutes).forEach(route => {
			console.log(route);
		});
		// Collapse all elements
		Object.keys(openRoutes).forEach(
			item =>
				openRoutes[index] ||
				setOpenRoutes(openRoutes =>
					Object.assign({}, openRoutes, { [item]: false })
				)
		);
		// Toggle selected element
		setOpenRoutes(openRoutes =>
			Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
		);
		console.log(openRoutes);
	};

	const flushReports = () => {
		getAllItems().map(item => {
			if (item.key.startsWith('ransomware_Report')) {
				removeItem(item.key);
			}
		});
	};

	const childAccountChanged = childAccount => {
		flushReports();
		setCurrentChildAccount(childAccount);
		navigate('/ransomware/dashboard', { replace: true });
	};

	const regionChanged = region => {
		new EvaluationApiClient().authorizeAwsMember(
			{ Region: region },
			() => {
				flushReports();
				setCurrentRegion(region);
				navigate('/dashboard', { replace: true });
			},
			() => console.error(`Changing region to ${region} failed`)
		);
		// TODO: deployment scenario
	};

	return (
		<nav
			className={classNames(
				'sidebar',
				sidebar.isOpen || 'toggled',
				!sidebar.isOnRight || 'sidebar-right'
			)}
		>
			<div className="sidebar-content">
				<a
					className={classNames(
						'sidebar-brand',
						!sidebar.isOnRight || 'text-end'
					)}
					href="/"
				>
					<LogoSvg className="sidebar-brand-logo" />
					<h1 className=" text-white sidebar-title text-uppercase mt-2">
						{t('sidebar.title')}
					</h1>
				</a>
				<ul className="sidebar-nav">
					<div className="sidebar-user d-flex justify-content-center align-items-center">
						<div>
							{isDemoAccount() && (
								<>
									<small className="d-block">Demo</small>
								</>
							)}
							{isTrialAccount() && (
								<>
									<small className="d-block">Trial</small>
								</>
							)}
						</div>
						{getUser() && (
							<div className="sidebar-user-info">
								{!isOrgAccount() && (
									<>
										<small className="d-block">
											{getUserView()?.[0]?.value}
										</small>
										<small className="d-block">
											{getUserView()?.[1]?.key}:{' '}
											{shorten(getUserView()?.[1]?.value, 16)}
										</small>
										<small className="d-block">
											{getUserView()?.[2]?.key}:{' '}
											{shorten(getUserView()?.[2]?.value, 16)}
										</small>
									</>
								)}
								{isOrgAccount() && (
									<>
										<div>
											<FontAwesomeIcon icon={faSitemap} className="me-1" />
											<select
												className="form-control-sm w-75 border-0 text-dark"
												onMouseDown={e => e.preventDefault()}
												style={{
													outline: 'none',
													MozAppearance: 'none',
													WebkitAppearance: 'none',
												}}
											>
												<option>{getUserView()?.[0]?.value}</option>
											</select>
										</div>
										<div>
											<FontAwesomeIcon icon={faUserShield} className="me-1" />
											<select
												className="form-control-sm w-75 border-1 rounded mt-3 text-dark"
												style={{ outline: 'none' }}
												value={getCurrentChildAccount()}
												onChange={child =>
													childAccountChanged(child.target.value)
												}
											>
												{getChildAccounts().map((child, index) => (
													<option
														className="text-dark"
														key={index}
														disabled={
															(child.accessKey === undefined ||
																child.secretKey === undefined) &&
															!child.masterAccount
														}
														title={child.account}
														value={
															child.userName === 'All Member Accounts'
																? ''
																: child.account
														}
													>
														{child.userName}
													</option>
												))}
											</select>
										</div>
										<div>
											<FontAwesomeIcon icon={faFlag} className="me-2" />
											<select
												className="form-control-sm w-75 border-1 rounded mt-2 text-dark"
												style={{ outline: 'none' }}
												value={getCurrentRegion()}
												onChange={region => regionChanged(region.target.value)}
											>
												{getRegions().map((region, index) => (
													<option
														className="text-dark"
														key={index}
														value={region}
													>
														{region}
													</option>
												))}
											</select>
										</div>
									</>
								)}
							</div>
						)}
					</div>

					{routes.map((category, index) => {
						console.log(category);
						const showDivider =
							category.header && index !== routes.length - 1 && index !== 0;
						return (
							<React.Fragment key={index}>
								{(showDivider || category.isSeparate) && (
									<div className="sidebar-divider" />
								)}
								{category.header ? (
									<li className="sidebar-header">{t(category.header)}</li>
								) : null}

								{category.children ? (
									<SidebarCategory
										name={t(category.name)}
										badgeColor={category.badgeColor}
										badgeText={t(category.badgeText)}
										icon={category.icon}
										to={category.path}
										isOpen={openRoutes[index]}
										onClick={() => toggle(index)}
									>
										{category.children.map((route, index) => (
											<>
												{route.isSeparateItem && (
													<div
														className="sidebar-divider"
														style={{ marginTop: 15, marginBottom: 15 }}
													/>
												)}
												<SidebarItem
													key={index}
													name={t(route.name)}
													to={route.path}
													badgeColor={route.badgeColor}
													badgeText={t(route.badgeText)}
												/>
											</>
										))}
									</SidebarCategory>
								) : (
									<SidebarItem
										name={t(category.name)}
										to={category.path}
										icon={category.icon}
										badgeColor={category.badgeColor}
										badgeText={t(category.badgeText)}
									/>
								)}
							</React.Fragment>
						);
					})}
				</ul>
			</div>
		</nav>
	);
};

export default connect(store => ({
	sidebar: store.sidebar,
}))(Sidebar);
