import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const NoSelectionModal = ({ isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen}>
			<ModalHeader>{t('table.noResourcesSelected')}</ModalHeader>
			<ModalBody>{t('table.pleaseSelectResources')}</ModalBody>
			<ModalFooter>
				<div className="floatRight">
					<Button color="danger" size="md" onClick={onClose}>
						{t('button.close')}
					</Button>
				</div>
			</ModalFooter>
		</Modal>
	);
};

export default NoSelectionModal;
