import React from 'react';
import classNames from 'classnames';

const Tabs = ({ tabs, activeTab, onChange, small, isRemediationLoading }) => {
	return (
		<ul className={classNames('form-tabs', { 'form-tabs_small': small })}>
			{tabs.map(tab => (
				<li
					className={classNames('form-tabs__item', {
						'form-tabs__item_active': activeTab === tab.value,
					})}>
					<button
						className="w-full"
						onClick={() => onChange(tab.value)}
						disabled={isRemediationLoading}>
						{tab.label}
					</button>
				</li>
			))}
		</ul>
	);
};

export default Tabs;
