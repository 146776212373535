import { MinusCircle, PlusCircle } from 'react-feather';

import React from 'react';

const Expander = ({ expanded, onExpand }) => {
	const onClick = () => {
		if (onExpand) onExpand(!expanded);
	};

	return expanded ? (
		<MinusCircle width={16} height={16} onClick={onClick} />
	) : (
		<PlusCircle width={16} height={16} onClick={onClick} />
	);
};

export default Expander;