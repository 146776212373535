import { Col, Row } from 'reactstrap';
import { PotentialLoss, RiskScore } from '../../Controls/Risk';

import Issues from '../../assessment/Controls/ReviewScore/Issues';
import React from 'react';
import { t } from 'i18next';

const Metrics = ({ report }) => (
	<Row className="mb-60">
		<Col>
			<RiskScore score={report.RiskScore} />
		</Col>
		<Col>
			<PotentialLoss
				value={{
					min: report.PotentialLossMin,
					max: report.PotentialLossMax,
				}}
			/>
		</Col>
		<Col>
			<Issues
				color="danger"
				title={t('ransomwareDashboard.critical')}
				system={report['Critical Resources']}
				issues={report['Critical Issues']}
			/>
		</Col>
		<Col>
			<Issues
				color="warning"
				title={t('ransomwareDashboard.high')}
				system={report['High Resources']}
				issues={report['High Issues']}
			/>
		</Col>
	</Row>
);

export default Metrics;
