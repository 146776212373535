import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const useHeader = () => {
	const date = new Date();

	const zeroPad = text => ('0' + text).slice(-2),
		dt = zeroPad(date.getDate()),
		month = zeroPad(date.getMonth() + 1),
		seconds = zeroPad(date.getSeconds()),
		minutes = zeroPad(date.getMinutes()),
		hours = zeroPad(date.getHours());

	return {
		date: `${date.getFullYear()}/${month}/${dt} - (${hours}:${minutes}:${seconds})`,
	};
};

export default ({ reportId }) => {
	const { date } = useHeader();

	return (
		<>
			<Card className="text-center">
				<CardHeader className="mt-5">
					<CardTitle>
						<h1>{t('sidebar.title')}</h1>
					</CardTitle>
				</CardHeader>
				<CardBody className="mt-3 mb-5">
					<div className="mb-3">
						<h3>{t('report.title')}</h3>
					</div>
					<div className="mb-3 pb-7">
						<h3>{date}</h3>
					</div>
				</CardBody>
			</Card>
		</>
	);
};
