import React from 'react';
import {
	faArrowAltCircleLeft,
	faPrint,
} from '@fortawesome/free-solid-svg-icons';

import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

const ReportButtons = ({ onPrint }) => {
	return (
		<>
			<div className="clearfix mb-2 mt-2">
				<Button
					className="dont-print float-end"
					size="md"
					color="primary"
					onClick={onPrint}>
					<FontAwesomeIcon icon={faPrint} className="me-1" />
					{t('button.print')}
				</Button>
				<Button
					onClick={() => window.history.back()}
					className="dont-print"
					size="md"
					color="primary">
					<FontAwesomeIcon icon={faArrowAltCircleLeft} className="me-1" />
					{t('button.back')}
				</Button>
			</div>
		</>
	);
};

export default ReportButtons;
