import { CloudTypes } from "../../../../constants/CloudInfo";
import { getUserType } from "../../../../services/AuthService";

export default (pack) => {

  const cloudType = getUserType();
  const tests = pack.tests;

  const permissions = {
    [CloudTypes.AWS]: tests.aws,
    [CloudTypes.Azure]: tests.azure,
    [CloudTypes.GCP]: tests.gcp
  };

  const permission = permissions[cloudType];

  return {
    disabled: permission.disabled,
    comingSoon: permission.comingSoon,
    enableAudit: permission.audit,
    style: pack.style
  };
};