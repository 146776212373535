import {
	TabContent,
	TabPane
} from "reactstrap";

import React from "react";

export default function ({ categoryIds, categories, selected, onSelect }) {

	return <>
		<TabContent activeTab={selected}>

			{categoryIds.map(id => 
				<TabPane tabId={id} key={id}>
					<h5 className="mt-3">{categories[id].description}</h5>
				</TabPane>
			)}

		</TabContent>
	</>
}