import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { useState } from 'react';
import { t } from 'i18next';

export default function () {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<Modal isOpen={isOpen}>
			<ModalHeader>{t('button.error')}</ModalHeader>
			<ModalBody>
				<span className="text-danger">{t('tests.errorCreateReport')}</span>
			</ModalBody>
			<ModalFooter>
				<Button size="lg" color="danger" onClick={() => setIsOpen(false)}>
					{t('button.close')}
				</Button>
			</ModalFooter>
		</Modal>
	);
}
