import { Auditor, ReportName } from './Controls/TextControls';
import {
	Card,
	CardBody,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';

import AwsEvalProgress from '../Common/Controls/AwsEvalProgress';
import StartTestButton from './Controls/StartTestButton';
import { allPacks } from './Common/Constants';
import useReport from './Common/useReport';
import useTest from './Common/useTest';
import { t } from 'i18next';

export default function ({ pack, mode, onClose }) {
	const packText = allPacks[pack][mode],
		report = useReport(),
		[open, setOpen] = useState(true),
		test = useTest(mode, pack, report.name),
		onCancel = () => {
			setOpen(false);
			onClose();
		};

	useEffect(() => {
		document.title = t(packText.title.props?.i18nKey);
	}, [packText]);

	useEffect(() => test.update(report.name, report.auditor), [test, report]);

	return (
		<>
			<Modal isOpen={open}>
				<ModalHeader className="bg-primary">
					<h3 className="text-white">{packText.title}</h3>
				</ModalHeader>
				<ModalBody>
					<Card>
						<CardBody>
							{test.failed && (
								<span class="badge badge-danger w-50 me-5 mb-2">
									{t('testReports.evaluationFailed')}
								</span>
							)}
							{!test.evalStarted && (
								<>
									<ReportName
										value={report.name}
										onChange={report.setReport}
										isReadOnly={test.evalStarted}
									/>
									<Auditor
										value={report.auditor}
										onChange={report.setAuditor}
										isReadOnly={test.evalStarted}
									/>
								</>
							)}

							{test.createAwsEval && (
								<AwsEvalProgress testProgress={test.progress} />
							)}
						</CardBody>
					</Card>
				</ModalBody>
				<ModalFooter>
					<StartTestButton
						onClick={test.start}
						onCancel={onCancel}
						mode={mode}
						disableAction={report.isInvalid || test.evalStarted}
					/>
				</ModalFooter>
			</Modal>
		</>
	);
}
