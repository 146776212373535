import React from 'react';

const Textarea = ({ value, onChange, type, error, ...rest }) => {
	return (
		<>
			<textarea
				className="textarea"
				value={value}
				onChange={onChange}
				valid={!error}
				invalid={!!error}
				{...rest}
			/>
			{error && <p className="m-0 cloud-form__error">{error}</p>}
		</>
	);
};

export default Textarea;
