import { asyncRequest, httpMethods, resolveUri } from './Common';

import { isOrgChildAccount } from '../services/AuthService';

const ransomwareUri = uri => resolveUri(uri, 'rra');

const getDashboardData = async () => {
    const uri = ransomwareUri('dashboard-summary');

    const result = await asyncRequest(uri, {
        headers: { Refresh: false },
        redirect: 'follow',
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const saveQuestionare = async questionare => {
    const uri = ransomwareUri('rra-questionnaire');
    try {
        const result = await asyncRequest(uri, {
            method: httpMethods.put,
            headers: {
                ConformancePackName: 'RRA',
            },
            body: JSON.stringify(questionare),
        });
        return {
            success: result.success,
            data: result.response,
        };
    } catch (e) {
        return {
            success: false,
        }
    };
}

const getQuestionare = async () => {
    try {
        const uri = ransomwareUri('rra-questionnaire');

        const result = await asyncRequest(uri, {
            method: httpMethods.get,
            headers: {
                ConformancePackName: 'RRA',
            },
        });

        return {
            success: result.success,
            data: result.response,
        };
    } catch (e) {
        return {
            success: false,
        }
    };
};

const evaluate = async (reportName, exclusion) => {
    let previous = await getDashboardData();

    exclusion = exclusion !== 'None' && exclusion !== '' ? exclusion : ' ';

    const uri = ransomwareUri('evaluate');

    const result = await asyncRequest(uri, {
        method: httpMethods.post,
        headers: {
            ConformancePackName: 'RRA',
            ReportId: reportName,
            IsOrgUser: isOrgChildAccount(),
        },
        body: exclusion,
    });

    if (result.success) {
        result.response.previousScore = previous.data?.RRA
            ? previous.data.RRA.RiskScore
            : 0;
        result.response.previousLossMin = previous.data?.RRA
            ? previous.data.RRA.PotentialLossMin
            : 0;
        result.response.previousLossMax = previous.data?.RRA
            ? previous.data.RRA.PotentialLossMax
            : 0;
    }

    return {
        success: result.success,
        data: result.response,
    };
};

const getCompliantResourceList = async reportName => {
    const uri = resolveUri(
        'live-environment-compliance' + (isOrgChildAccount() ? 'Org' : '')
    );

    const headers = {
        ReportId: reportName,
        ConformancePackName: 'RRA',
        FragmentCount: 1,
    };

    const getList = async method => {
        return await asyncRequest(uri, {
            headers,
            method: method,
            redirect: 'follow',
        });
    };

    let result = await getList(httpMethods.get);

    if (!result.success || !result.response) {
        result = await getList(httpMethods.put);
    }

    return {
        success: result.success,
        data: result.response,
    };
};

const getResourceList = async (refresh = false) => {
    const uri = resolveUri(
        'live-environment' + (isOrgChildAccount() ? 'Org' : '')
    );

    const result = await asyncRequest(uri, {
        headers: { Refresh: refresh },
        redirect: 'follow',
    });

    return {
        success: result.success,
        data: !result.response ? [] : result.response,
    };
};

const getDrSoftwareList = async () => {
    try {
        const uri = ransomwareUri('dr-swlist');
        const result = await asyncRequest(uri, {
            headers: { Refresh: false },
            redirect: 'follow',
        });

        return {
            success: result.success,
            data: result.response,
        };
    } catch (e) {
        return {
            success: false
        }
    }
};

const getSiemSoftwareList = async () => {
    const uri = ransomwareUri('siem-swlist');
    const result = await asyncRequest(uri, {
        headers: { Refresh: false },
        redirect: 'follow',
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const getMdmSoftwareList = async () => {
    const uri = ransomwareUri('mdm-swlist');
    const result = await asyncRequest(uri, {
        headers: { Refresh: false },
        redirect: 'follow',
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const getUemSoftwareList = async () => {
    const uri = ransomwareUri('uem-swlist');
    const result = await asyncRequest(uri, {
        headers: { Refresh: false },
        redirect: 'follow',
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const results = async reportName => {
    const uri = ransomwareUri('evaluation-results');

    const result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {
            ConformancePackName: 'RRA',
            ReportId: reportName,
        },
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const getEvaluationComplianceResult = async (reportName, fragment) => {
    //todo?????
    const uri = resolveUri('evaluation-results', 'conformance-pack');

    const result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {
            ConformancePackName: 'RRA',
            ReportId: reportName,
            FragmentId: fragment,
        },
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const getIssueList = async reportId => {
    const uri = ransomwareUri('IssueList');

    const result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {
            ConformancePackName: 'RRA',
            ReportId: reportId,
        },
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const getFixResults = async (reportId, issueTypes) => {
    const uri = ransomwareUri('rra-fix-results');

    let result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {
            ConformancePackName: 'RRA',
            ReportName: reportId,
        },
    });

    if (!result.success || !result.response) {
        const issueRemidiationStatus = [];
        issueTypes.forEach(issueType => {
            issueRemidiationStatus.push({
                key: issueType,
                resources: {
                    fixed: [],
                    unfixed: [],
                    rollback: [],
                },
            });
        });
        result = await asyncRequest(uri, {
            method: httpMethods.put,
            headers: {
                ConformancePackName: 'RRA',
                ReportName: reportId,
            },
            body: JSON.stringify(issueRemidiationStatus),
        });

        result = await asyncRequest(uri, {
            method: httpMethods.get,
            headers: {
                ConformancePackName: 'RRA',
                ReportName: reportId,
            },
        });
    }

    return {
        success: result.success,
        data: result.response,
    };
};

const saveFixResults = async (reportId, data) => {
    const uri = ransomwareUri('rra-fix-results');

    let result = await asyncRequest(uri, {
        method: httpMethods.put,
        headers: {
            ConformancePackName: 'RRA',
            ReportName: reportId,
        },
        body: JSON.stringify(data),
    });

    result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {
            ConformancePackName: 'RRA',
            ReportName: reportId,
        },
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const getFixLogs = async reportId => {
    const uri = ransomwareUri('rra-fix-logs');

    let result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {
            ConformancePackName: 'RRA',
            ReportName: reportId,
        },
    });

    if (!result.success || !result.response) {
        result = await asyncRequest(uri, {
            method: httpMethods.put,
            headers: {
                ConformancePackName: 'RRA',
                ReportName: reportId,
            },
            body: JSON.stringify([]),
        });

        result = await asyncRequest(uri, {
            method: httpMethods.get,
            headers: {
                ConformancePackName: 'RRA',
                ReportName: reportId,
            },
        });
    }

    return {
        success: result.success,
        data: result.response,
    };
};

const saveFixLogs = async (reportId, data) => {
    const uri = ransomwareUri('rra-fix-logs');

    let result = await asyncRequest(uri, {
        method: httpMethods.put,
        headers: {
            ConformancePackName: 'RRA',
            ReportName: reportId,
        },
        body: JSON.stringify(data),
    });

    result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {
            ConformancePackName: 'RRA',
            ReportName: reportId,
        },
    });

    return {
        success: result.success,
        data: result.response,
    };
};

const getEvaluationHistory = async () => {
    const uri = ransomwareUri('evaluation-history');

    const result = await asyncRequest(uri, {
        method: httpMethods.get,
        headers: {},
    });

    return {
        success: result.success,
        data: result.response,
    };
};

export {
    evaluate,
    results,
    getResourceList,
    getCompliantResourceList,
    getDrSoftwareList,
    getSiemSoftwareList,
    getUemSoftwareList,
    getMdmSoftwareList,
    getQuestionare,
    saveQuestionare,
    getDashboardData,
    getIssueList,
    getEvaluationHistory,
    getEvaluationComplianceResult,
    getFixResults,
    saveFixResults,
    getFixLogs,
    saveFixLogs,
};
