import React from 'react';
import { Trans } from 'react-i18next';

import Tabs from '../../../components/Tabs';
import { REMEDIATION_TABS, REMEDIATION_TABS_K8S } from '../constants';
import { getUserType } from '../../../services/AuthService';
import { CloudTypes } from '../../../constants/CloudInfo';

const RemediationSteps = ({ activeStep, onChange, isRemediationLoading }) => {
	// TODO this should handle other types too
	const isK8S = getUserType() === CloudTypes.K8s;
	return (
		<div>
			<h2 className="mb-1 my-7-step">
				<Trans i18nKey="talasmart.resource.remediationSteps" />
			</h2>
			<Tabs
				tabs={isK8S ? REMEDIATION_TABS_K8S : REMEDIATION_TABS}
				activeTab={activeStep}
				onChange={onChange}
				isRemediationLoading={isRemediationLoading}
			/>
		</div>
	);
};

export default RemediationSteps;
