import { Trans } from 'react-i18next';
import React from 'react';

const INITIAL_VALUES = {
	aws: {
		friendlyName: '',
		accessKey: '',
		secretKey: '',
		region: '',
		accountId: '',
	},
	azure: {
		friendlyName: '',
		appId: '',
		password: '',
		tenant: '',
		subscriptionId: '',
	},
	onprem: {
		friendlyName: '',
		accessKey: '',
		secretKey: '',
		accountId: '',
	},
	k8s: {
		friendlyName: '',
		accessKey: '',
		secretKey: '',
		accountId: '',
	},
};

const LABELS = {
	aws: {
		friendlyName: <Trans i18nKey="form.accountAlias" />,
		accessKey: <Trans i18nKey="form.accessKey" />,
		secretKey: <Trans i18nKey="form.secretKey" />,
		region: <Trans i18nKey="form.region" />,
		accountId: <Trans i18nKey="form.awsAccount" />,
	},
	azure: {
		friendlyName: <Trans i18nKey="form.accountAlias" />,
		appId: <Trans i18nKey="form.appId" />,
		password: <Trans i18nKey="form.password" />,
		tenant: <Trans i18nKey="form.tenant" />,
		subscriptionId: <Trans i18nKey="form.subscriptionId" />,
	},
	onprem: {
		friendlyName: <Trans i18nKey="form.accountAlias" />,
		accessKey: <Trans i18nKey="form.accessKey" />,
		secretKey: <Trans i18nKey="form.secretKey" />,
		accountId: <Trans i18nKey="form.onpremAccountId" />,
	},
	k8s: {
		friendlyName: <Trans i18nKey="form.accountAlias" />,
		accessKey: <Trans i18nKey="form.accessKey" />,
		secretKey: <Trans i18nKey="form.secretKey" />,
		accountId: <Trans i18nKey="form.onpremAccountId" />,
	},
};

const isNotEmpty = value => value && value?.trim()?.length > 0;

const VALIDATORS = {
	friendlyName: { method: isNotEmpty, error: 'form.accountAliasBlank' },
	accessKey: { method: isNotEmpty, error: 'form.awsKeyBlank' },
	secretKey: { method: isNotEmpty, error: 'form.awsSecretBlank' },
	// accountId: { method: isNotEmpty, error: 'accountIdBlank' },
	region: { method: isNotEmpty, error: 'form.regionBlank' },
	appId: { method: isNotEmpty, error: 'form.appIdBlank' },
	password: { method: isNotEmpty, error: 'form.passwordBlank' },
	tenant: { method: isNotEmpty, error: 'form.tenantBlank' },
	subscriptionId: { method: isNotEmpty, error: 'form.subsriptionIdBlank' },
};


export { INITIAL_VALUES, LABELS, VALIDATORS };
