import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Form/Input';
import PhoneInput from '../../../components/Form/PhoneInput';
import Button from '../../../components/Form/Button';
import { VALIDATORS } from './constants';
import useForm from '../hooks/useForm';
import SavePopup from '../../../components/SavePopup/SavePopup';
import DeletePopup from '../../../components/DeletePopup/DeletePopup';
import AvatarInput from '../../../components/AvatarInput/AvatarInput';

const INITIAL_VALUES = {
	userName: '',
	phoneNumber: '',
	email: '',
	alternateEmail: '',
	avatar: null,
};

const Settings = () => {
	const { t } = useTranslation();
	const [values, setValues] = useState(INITIAL_VALUES);
	const [savePopup, setSavePopup] = useState(false);
	const [deletePopupOpen, setDeletePopupOpen] = useState(false);
	const { validateField, validateForm, errors } = useForm(values, VALIDATORS);

	const onSavePopupClose = () => {
		setSavePopup(false);
	};

	const onSavePopupOpen = e => {
		e.preventDefault();
		const isValid = validateForm();

		if (!isValid) {
			return;
		}

		setSavePopup(true);
	};

	const handleChange = e => {
		e.persist();
		setValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
		validateField(e.target.name, e.target.value);
	};

	const onSubmit = () => {
		onSavePopupClose();
	};

	const onAvatarChange = avatar => setValues(prev => ({ ...prev, avatar }));
	const onPhoneChange = phoneNumber => {
		setValues(prev => ({ ...prev, phoneNumber }));
		validateField('phoneNumber', phoneNumber);
	};

	const onDeleteAvatar = () => {
		onAvatarChange(null);
		setDeletePopupOpen(false);
	};

	return (
		<>
			<div>
				<h1>{t('settings.title')}</h1>
				<form onSubmit={onSavePopupOpen} className="d-flex flex-column gap-3">
					<AvatarInput
						value={values.avatar}
						onChange={onAvatarChange}
						onDelete={() => setDeletePopupOpen(true)}
					/>
					<div
						className="my-4"
						style={{ height: '1px', background: '#CFCCCC' }}
					/>
					<fieldset className="w-50 d-flex flex-column gap-3">
						<Input
							onChange={handleChange}
							name="userName"
							error={errors.userName}
							label={t('form.userName')}
						/>
						<PhoneInput
							label={t('form.phoneNumber')}
							error={errors.phoneNumber}
							onChange={onPhoneChange}
						/>
						<Input
							onChange={handleChange}
							name="email"
							label={t('form.email')}
							error={errors.email}
						/>
						<Input
							onChange={handleChange}
							name="alternateEmail"
							error={errors.alternateEmail}
							label={t('form.alternateEmail')}
						/>
						<Button className="align-self-end">
							{t('button.saveChanges')}
						</Button>
					</fieldset>
				</form>
			</div>
			<SavePopup
				isOpen={savePopup}
				onToggle={onSavePopupClose}
				onSubmit={onSubmit}
			/>
			<DeletePopup
				isOpen={deletePopupOpen}
				onToggle={() => setDeletePopupOpen(false)}
				onSubmit={onDeleteAvatar}
			/>
		</>
	);
};

export default Settings;
