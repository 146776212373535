const convertToInternationalCurrencySystem = value => {

	//note: add toFixed(2) for decimal places
	//so ..(Math.abs(Number(value)) / 1.0e+9) becomes (Math.abs(Number(value)) / 1.0e+9).toFixed(2) and so on
	//see original at https://stackoverflow.com/a/36734774
	//that's right. i'm lazy. 

	// Nine Zeroes for Billions
	return Math.abs(Number(value)) >= 1.0e+9

		? Math.round((Math.abs(Number(value)) / 1.0e+9)) + "B"
		// Six Zeroes for Millions 
		: Math.abs(Number(value)) >= 1.0e+6

			? Math.round((Math.abs(Number(value)) / 1.0e+6)) + "M"
			// Three Zeroes for Thousands
			: Math.abs(Number(value)) >= 1.0e+3

				? Math.round((Math.abs(Number(value)) / 1.0e+3)) + "K"

				: Math.abs(Number(value));

}

export { convertToInternationalCurrencySystem  }