import { Card, CardBody, CardHeader, CardTitle, Col, Table } from 'reactstrap';

import { Colours } from '../../../constants/Colours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pie } from 'react-chartjs-2';
import React from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';

const ComplianceChart = props => {
	const data = {
		labels: [t('topology.nonCompliant'), t('topology.compliant')],
		datasets: [
			{
				data: [props.data.nonCompliantCount, props.data.compliantCount],
				backgroundColor: [Colours.Red, Colours.Green],
				borderColor: 'transparent',
			},
		],
	};

	const options = {
		responsive: !window.MSInputMethodContext,
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		cutoutPercentage: 75,
	};

	const compliantCount = Math.ceil(
		(props.data.compliantCount / props.data.total) * 100
	);
	const nonCompliantCount = 100 - compliantCount;

	return (
		<Col xs="3">
			<Card className="flex-fill w-100 top-row-card">
				<CardHeader>
					<CardTitle tag="h5" className="mb-0">
						{t('dashboards.compliance')} % - {props.name}
					</CardTitle>
				</CardHeader>
				<CardBody className="d-flex">
					<div className="align-self-center w-100">
						<div className="py-3">
							<div className="chart chart-xs">
								<Pie data={data} options={options} />
							</div>
						</div>

						<Table className="mb-0">
							<tbody>
								<tr>
									<td>
										<FontAwesomeIcon
											icon={faCircle}
											style={{ color: Colours.Green }}
										/>{' '}
										{t('topology.compliant')}
									</td>
									<td className="text-end">{compliantCount}%</td>
								</tr>
								<tr>
									<td>
										<FontAwesomeIcon
											icon={faCircle}
											style={{ color: Colours.Red }}
										/>{' '}
										{t('topology.nonCompliant')}
									</td>
									<td className="text-end">{nonCompliantCount}%</td>
								</tr>
							</tbody>
						</Table>
					</div>
				</CardBody>
			</Card>
		</Col>
	);
};

export default ComplianceChart;
