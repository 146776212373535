import { getQuestionare, saveQuestionare } from "../../../apiclients/Ransomware";
import { useEffect, useState } from "react";

import data from "./DropdownData";

const useAssessment = (setUserQuestionnaire) => {

	const defaultOptions = {
		demographics: {
		isdept: false,
		sector: 0,
		grossValue: 0,
		orgName: '',
		cashflow: 0,
		empNum: 0,
		deptname: 0,
		assessorName: '',
		assessorEmail: '',
		pocName: '',
		pocEmail: '',
		}
	};

	const [loading, setLoading] = useState(false);
	const [options, setOptions] = useState(defaultOptions);
	const [questionare, setQuestionare] = useState({});
	const [orgFormValid, setOrgFormValid] = useState(false);
	const [contactFormValid, setContactFormValid] = useState(false);
	const [reviewValid, setReviewValid] = useState(false);

	const setFormValid = (options) => {
		setOrgFormValid(options.demographics.sector?.length > 0 &&
			options.demographics.grossValueId > 0 &&
			options.demographics.cashflowId > 0 && 
			options.demographics.empNumId > 0 && options.demographics.orgName.length > 0);
		setContactFormValid(options.demographics.assessorName.length > 0 &&
			options.demographics.assessorEmail.length > 0 );
	}
	
	const save = async (onComplete) => {
		setLoading(true);
		setUserQuestionnaire({...questionare, organization: options});
		saveQuestionare({...questionare, organization: options}).then(() => {
			setLoading(false);
			onComplete();
		});		
	}

	const setInput = (parent, key, value) => {
		const parentObj = options[parent] ?? {};
		if (value.id !== undefined) {
			parentObj[key + 'Id'] = value.id;	
			parentObj[key] = value.value;	
		} else {
			parentObj[key] = value;
		}
		options[parent] = {...parentObj};
		const newOptions = {...options};
		setOptions(newOptions);
		setFormValid(newOptions);
	}

	useEffect(() => {
		setLoading(true);
		getQuestionare().then(response => {
			if (!response.data) {
				response.data = {};
			}
			if (!response.data.organization) {
				response.data.organization = { 
					demographics: {
						isdept: false,
						sectorId: 0,
						grossValueId: 0,
						orgName: '',
						cashflowId: 0,
						empNumId: 0,
						deptnameId: 0,
						assessorName: '',
						assessorEmail: '',
						pocName: '',
						pocEmail: '',
					}
				}
			}
			setQuestionare(response.data);
			setUserQuestionnaire(response.data);
			setOptions(response.data.organization);
			setFormValid(response.data.organization);
			setLoading(false);
			if(response.data.review)
			{
				setReviewValid(true);
			}
		});
	}, []);

	return {
		sectors: data.sectors,
		cashflow: data.cashflow,
		grossValueRange: data.grossValueOfAssets,
		numberOfEmployees: data.numberOfEmployees,
		buNames: data.buNames,
		setInput,
		options,
		orgFormValid,
		contactFormValid,
		reviewValid,
		loading,
		save
	}
};

export default useAssessment;