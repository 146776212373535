import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Header from '../../components/Header';
import HeaderTitle from '../../components/HeaderTitle';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';

import ErrorInfo from './ErrorInfo';

import { getRemediationData } from '../tests/AutomatedTests/ResultService';

import NetworkTopologyAndResource from './TopologyAndResource';
import useNetworkTopology from '../ransomware/Hooks/useNetworkTopology';
import NetworkChart from './NetworkChart';
import InfoModal from '../../components/InfoModal';
import { useTranslation } from 'react-i18next';
import { TopologyTable } from './TopologyTableWrap/TopologyTable';
import { getReviewUrl } from '../../services/reportService';
import LoaderTopology from '../../components/LoaderTopology';

const Network = props => {
	const { pack, reportName, evalId, numFragments } = props;
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [title, setTitle] = useState(props.title);

	const [nodeShow, setNodeShow] = useState({});

	const [modal, setModal] = useState(false);
	const [modalData, setModalData] = useState(null);

	const [selectedReport, setSelectedReport] = useState({});
	const [selectedPack, setSelectedPack] = useState('');

	// const [isResources, setIsResources] = useState(true);
	const [remediation, setRemediation] = useState({});
	const [reportButtonData, setReportButtonData] = useState({});

	const [reportProgress, setReportProgress] = useState(0);
	const updateReportProgress = (progress) => {
		const progressPct = Math.floor(progress * 100)
		console.log("*** update report:", progressPct)
		setReportProgress(progressPct);
	};

	const {
		loaded,
		error,
		reloading,
		nodes,
		fetchData,
		refreshTopology,
		results,
	} = useNetworkTopology({
		pack,
		reportName,
		nodeShow,
		evalId,
		numFragments,
		updateReportProgress
	});

	useEffect(() => {
		if (
			pack &&
			pack !== '' &&
			reportName &&
			reportName !== '' &&
			evalId &&
			evalId !== '' &&
			numFragments &&
			numFragments !== ''
		) {
			const title = { path: 'topology.packTitle', variables: { pack } };
			setTitle(title);
			/*
			 * BRL 2023-06-26 Both ConformancePack and PackName are set to the same value intentionally.
			 * getResults() expects PackName, other functions expect ConformancePack.
			 */
			const reportInfo = {
				ConformancePack: pack,
				PackName: pack,
				EvalId: evalId,
				Fragments: numFragments,
				ReportId: reportName,
			};
			setSelectedReport(reportInfo);
			setReportButtonData({
				reviewUrl: getReviewUrl(reportInfo),
				report: reportInfo,
			});

			const fetchResult = async () => {
				const data = await getRemediationData();
				setRemediation(data);
			};

			fetchResult();
		} else setTitle({ path: 'topology.title', variables: {} });
	}, [pack, reportName, evalId, numFragments]);

	const showModal = useCallback(
		data => {
			const { issues, ...node } = data;
			const info = data['non-compliant-rules']?.map(rule => {
				const ruleObj = results?.find(item => item.SecurityTestRule === rule);
				return ruleObj;
			});
			setModalData({ data: node, info });
			setModal(true);
		},
		[results]
	);

	useEffect(() => {
		if (!!Object.keys(selectedReport).length) {
			fetchData(false, false, updateReportProgress);
		}
	}, [fetchData, selectedReport]);

	useEffect(() => {
		document.title = t(title.path, title.variables || {});
		fetchData(false, false, updateReportProgress);
	}, [fetchData, nodeShow, title, t]);

	const changeReport = reportInfo => {
		const {
			ConformancePack,
			PackName = 'RRA',
			EvalId,
			Fragments = 1,
			ReportId,
		} = reportInfo;
		const formatReport = {
			ConformancePack,
			PackName,
			EvalId,
			Fragments,
			ReportId,
		};
		setSelectedReport(formatReport);
	};

	const formatDate = (reportId) => {
		if (!reportId) return '';
		const datePart = reportId.slice(7, 15);
		const timePart = reportId.slice(16, 22);
		const formattedDate = `${datePart.slice(0, 4)}-${datePart.slice(4, 6)}-${datePart.slice(6, 8)}`;
		const formattedTime = `${timePart.slice(0, 2)}:${timePart.slice(2, 4)}:${timePart.slice(4, 6)}`;
		return `${formattedDate} ${formattedTime}`;
	};

	const networkTopoGraph = useMemo(
		() => (<>
			<div>
				{/* {console.log("qqq",reportButtonData.report.ReportId)} */}
			</div>
			<NetworkTopologyAndResource
				key={reportName ? reportName : 'live'}
				reloading={reloading}
				nodes={nodes}
				refreshTopology={refreshTopology}
				showModal={showModal}
				info={{
					title:  t(title.path, title.variables || {}),
					date: reportButtonData?.report?.ReportId || "",
					date: formatDate(reportButtonData?.report?.ReportId) || "",
					
				}}
				showReviewButton={reportName ? true : false}
				report={reportButtonData}
				reportProgress={reportProgress}
			/>
			</>
		),
		[reportName, reloading, nodes, refreshTopology, showModal, reportButtonData, reportProgress]
	);

	useEffect(() => {
		if (
			selectedPack !== '' &&
			selectedReport &&
			selectedReport.ReportId &&
			selectedReport.EvalId &&
			selectedReport.Fragments
		) {
			console.log('===================');

			const queryParams = new URLSearchParams();
			queryParams.set('pack', selectedPack);
			queryParams.set('reportname', selectedReport.ReportId);
			queryParams.set('evalid', selectedReport.EvalId);
			queryParams.set('numfragments', selectedReport.Fragments);
			navigate(`?${queryParams.toString()}`);
		}
	}, [selectedReport, navigate]);

	return (
		<Container fluid className="me-0 ms-0">
			<Header>
				<HeaderTitle>{t(title.path, title.variables || {})}</HeaderTitle>
			</Header>
			{/* {!loaded && !error && <Loader />} */}
			{!loaded && !error && <LoaderTopology className="vh-100" progressPct={reportProgress} />}

			{error && <ErrorInfo />}
			{loaded && (
				<React.Fragment>
					<div
						className="d-flex mb-4 mb-lg-60   "
						style={{ position: 'relative', flexGrow: 1, gap: '12px' }}>
						<NetworkChart
							changeReport={changeReport}
							hidden={!reportName && !pack}
							selectedPack={selectedPack}
							setSelectedPack={setSelectedPack}
							setNodeShow={setNodeShow}
							networkTopoGraph={networkTopoGraph}
						/>
						{modal && (
							<Col lg="4" className="p-lg-0 pt-lg-0 pt-4  topology-info-modal">
								<InfoModal
									data={modalData}
									onClose={() => setModal(false)}
									report={selectedReport}
								/>
							</Col>
						)}
					</div>
					<Row>
						<TopologyTable
							showModal={showModal}
							info={{
								selectedReport,
								remediation,
								results,
								nodes,
								loaded,
							}}
						/>
					</Row>
				</React.Fragment>
			)}
		</Container>
	);
};

Network.defaultProps = {
	title: { path: 'topology.title', variables: {} },
	btnText: 'topology.startComplianceTest',
};
export default Network;
