import { getKeys, getUserType } from '../services/AuthService';

import { CloudTypeProps } from '../pages/auth/MultiCloudSignIn/Constants';
import { CloudTypes } from '../constants/CloudInfo';

const loginHostUri = `${process.env.REACT_APP_LOGIN_API_URL}`;
const apiProxyHostUri = `${process.env.REACT_APP_PROXY_API_HOST}`;

const getApiHostUri = () => {
	/**
	 * Returns the appropriate base URI.
	 * Only the proxy host is supported.
	 */
	return apiProxyHostUri;
};

const resolveUri = (
	apiUrl,
	extension = 'conformance-pack',
) => {
	extension = extension.length > 0 ? `/${extension}` : extension;
	return `${getApiHostUri()}${getCloudApi()}${extension}/${apiUrl}`;
};

const getAuthHeaders = headers => {
	const keys = getKeys();
	const authHeaders = Object.assign({}, headers, { ...keys });

	return authHeaders;
};

const getCloudApi = () => {
	const usertype = getUserType();
	const cloudUserType = CloudTypeProps?.[usertype];
	return `/${cloudUserType?.title?.toLowerCase()}`;
};

const isAzure = () => getUserType() === CloudTypes.Azure;

const isAws = () => getUserType() === CloudTypes.AWS;

const failure = response => ({
	failed: true,
	status: response.status,
	message: response.statusText,
});

const httpMethods = {
	get: 'GET',
	post: 'POST',
	put: 'PUT',
	delete: 'DELETE',
};

const progressState = {
	none: 0,
	evalError: 1,
	topologyError: 2,
	evalStart: 3,
	topologyStart: 4,
	evalSuccess: 5,
	topologySuccess: 6,
	auditStart: 7,
	auditSaved: 8,
	auditError: 9,
};

const request = (url, requestOptions, success, error) => {
	requestOptions.headers = getAuthHeaders(requestOptions.headers ?? {});
	requestOptions.method = requestOptions.method ?? httpMethods.get;
	fetch(url, requestOptions)
		.then(response => {
			if (response.ok) {
				return response.json();
			} else {
				throw response;
			}
		})
		.then(success)
		.catch(error);
};

const safeParseJson = async response => {
	try {
		return await response.json();
	} catch (e) {
		return undefined;
	}
};

const asyncRequest = async (url, requestOptions, authHeaders = true) => {
	try {
		requestOptions.headers = authHeaders
			? getAuthHeaders(requestOptions.headers ?? {})
			: requestOptions.headers;
		requestOptions.method = requestOptions.method ?? httpMethods.get;
		const response = await fetch(url, requestOptions).catch(console.log);
		return {
			success: response.ok,
			response: response.ok ? await safeParseJson(response) : undefined,
			responseError: response.ok ? undefined : await safeParseJson(response),
			statusCode: response.status,
		};
	} catch (e) {
		return {
			success: false,
			response: undefined,
			statusCode: 500,
		};
	}
};

const asyncRequestWithTimeout = async (
	url,
	requestOptions,
	timeout = 30000
) => {
	let controller = new AbortController();
	let id = setTimeout(() => controller.abort(), timeout);

	requestOptions.headers = getAuthHeaders(requestOptions.headers ?? {});
	requestOptions.method = requestOptions.method ?? httpMethods.get;
	requestOptions.signal = controller.signal;

	let response = undefined;

	try {
		response = await fetch(url, requestOptions);
	} catch (err) {
		console.error(
			'Unhandled exception when requesting async resource with a configured timeout',
			err
		);
	}

	clearTimeout(id);

	if (response) {
		return {
			success: response.ok,
			response: response.ok ? await safeParseJson(response) : undefined,
			statusCode: response.status,
		};
	}

	return {
		success: false,
		response: undefined,
		statusCode: -1,
	};
};

export {
	request,
	asyncRequest,
	asyncRequestWithTimeout,
	getAuthHeaders,
	failure,
	resolveUri,
	getApiHostUri,
	httpMethods,
	progressState,
	isAzure,
	isAws,
	loginHostUri,
};
