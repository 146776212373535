import React, { createRef } from "react";

import { Button } from "reactstrap";

const useFileName = (onSelect) => {
	const fileInput = createRef();

	const onChange = e => {
		const name = e.currentTarget.files[0].name;
		onSelect(name);
	};

	const onClick = () => fileInput.current.click();

	return { fileInput, onClick, onChange };
}

export default function ({ onSelect }) {

	const { fileInput, onClick, onChange } = useFileName(onSelect);

	return <>
		<Button size="sm" color="primary" className="mb-1 ms-1" onClick={onClick}>
			<span>...</span>
		</Button>
		<input className="d-none" type="file" onChange={onChange} ref={fileInput} />
	</>;
}