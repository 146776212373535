import React, { useRef } from 'react';
import Avatar from '../Navbar/Avatar/Avatar';

import { ReactComponent as EditSvg } from '../../assets/img/edit.svg';
import { ReactComponent as DeleteSvg } from '../../assets/img/profiledelete.svg';
import { t } from 'i18next';

const AvatarInput = ({ value, onChange, onDelete }) => {
	const fileInputRef = useRef(null);

	const handleImageChange = event => {
		const file = event.target.files[0];
		event.target.value = '';

		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				onChange({ file, src: reader.result });
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<div className="d-flex gap-4 align-items-center">
			<Avatar image={value} size="lg" color="gray" />
			<div className="d-flex flex-column gap-2">
				{value ? (
					<>
						<button
							type="button"
							onClick={() => fileInputRef.current.click()}
							className="profile-button">
							<EditSvg /> {t('button.changePhoto')}
						</button>
						<button type="button" onClick={onDelete} className="profile-button">
							<DeleteSvg />
							{t('button.deletePhoto')}{' '}
						</button>
					</>
				) : (
					<button
						type="button"
						onClick={() => fileInputRef.current.click()}
						className="profile-button">
						<EditSvg /> {t('button.addPhoto')}
					</button>
				)}
			</div>

			<input
				hidden
				ref={fileInputRef}
				type="file"
				accept="image/*"
				onChange={handleImageChange}
			/>
		</div>
	);
};

export default AvatarInput;
