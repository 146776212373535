import React from 'react';
import { Trans } from 'react-i18next';
import { Mode, PackKeys } from '../../../../constants/PackKeys';

const automatedButtonText = <Trans i18nKey="button.runAutomationTest" />;
const auditButtonText = <Trans i18nKey="button.runAuditTest" />;

const allPacks = {
	[PackKeys.NIST]: {
		[Mode.automated]: {
			title: <Trans i18nKey="testReports.packPages.nist.automationTitle" />,
			buttonText: automatedButtonText,
		},
		[Mode.audit]: {
			title: <Trans i18nKey="testReports.packPages.nist.auditTitle" />,
			buttonText: auditButtonText,
		},
	},
	[PackKeys.SOC2]: {
		[Mode.automated]: {
			title: <Trans i18nKey="testReports.packPages.soc2.automationTitle" />,
			uttonText: automatedButtonText,
		},
		[Mode.audit]: {
			title: <Trans i18nKey="testReports.packPages.soc2.auditTitle" />,
			buttonText: auditButtonText,
		},
	},
	[PackKeys.HIPAASECURITY]: {
		[Mode.automated]: {
			title: <Trans i18nKey="testReports.packPages.hipaa.automationTitle" />,
			uttonText: automatedButtonText,
		},
		[Mode.audit]: {
			title: <Trans i18nKey="testReports.packPages.hipaa.auditTitle" />,
			buttonText: auditButtonText,
		},
	},
	[PackKeys.PCIDSS]: {
		[Mode.automated]: {
			title: <Trans i18nKey="testReports.packPages.pci.automationTitle" />,
			buttonText: automatedButtonText,
		},
		[Mode.audit]: {
			title: <Trans i18nKey="testReports.packPages.pci.auditTitle" />,
			buttonText: auditButtonText,
		},
	},
	[PackKeys.CMMCL3]: {
		[Mode.automated]: {
			title: <Trans i18nKey="testReports.packPages.cmms.automationTitle" />,
			buttonText: automatedButtonText,
		},
		[Mode.audit]: {
			title: <Trans i18nKey="testReports.packPages.cmms.auditTitle" />,
			buttonText: auditButtonText,
		},
	},
};

const reportNameRegex = /^([a-zA-Z0-9_-]){1,30}$/;
const auditorNameRegex = /\S/;

export { allPacks, auditorNameRegex, reportNameRegex };
