/* eslint-disable no-useless-computed-key */

import { Card, CardBody, CardHeader, CardTitle, Container } from 'reactstrap';

import CmmCl3Introduction from './CmmCl3Introduction';
import HipaaIntroduction from './HipaaIntroduction';
import NistIntroduction from './NistIntroduction';
import { PackKeys } from '../../../../constants/PackKeys';
import PciDssIntroduction from './PciDssIntroduction';
import React from 'react';
import Soc2Introduction from './Soc2Introduction';

const constants = {
		[PackKeys.NIST]: {
			title: 'NIST-CSF Introduction',
			details:
				'This report contains NIST-CSF compliance report of AWS cloud environment',
			control: () => <NistIntroduction />,
		},
		[PackKeys.SOC2]: {
			title: 'SOC2 Introduction',
			details:
				'This report contains SOC2 compliance report of AWS cloud environment',
			control: () => <Soc2Introduction />,
		},
		[PackKeys.HIPAASECURITY]: {
			title: 'HIPAA Introduction',
			details:
				'This report contains HIPAA Security report of AWS cloud environment',
			control: () => <HipaaIntroduction />,
		},
		[PackKeys.PCIDSS]: {
			title: 'PCI DSS Introduction',
			details: 'This report contains PCI DSS report of AWS cloud environment',
			control: () => <PciDssIntroduction />,
		},
		[PackKeys.CMMCL3]: {
			title: 'CMMC Introduction',
			details: 'This report contains CMMC report of AWS cloud environment',
			control: () => <CmmCl3Introduction />,
		},
	},
	ReportTypeIntroduction = ({ packName }) => {
		const data = constants[packName];

		return (
			<Card className="page-break d-flex">
				<CardHeader>
					<CardTitle>
						<h3>{data.title}</h3>
					</CardTitle>
				</CardHeader>
				<CardBody>
					<Container fluid>
						<h5>{data.control()}</h5>
					</Container>
				</CardBody>
			</Card>
		);
	};

export default ReportTypeIntroduction;
