import React from 'react';
import './styles.css';
import { Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import emptyCircle from '../../../../assets/img/emptyCircle.svg';
import doneCircle from '../../../../assets/img/doneCircle.svg';
import activeCircle from '../../../../assets/img/activeCircle.svg';
import ProgressCircle from './ProgressCircle';

function Steps({ activeTab, activeTabSecond, showStep3 }) {
	const { t } = useTranslation();
	return (
		<div style={{ marginBottom: '25px' }}>
			<div className="d-flex gap-3">
				<div className="d-flex align-items-start">
					<ProgressCircle
						cirlce={activeTabSecond ? doneCircle : activeCircle}
						step={1}
						descp={t('reviewScan.step1')}
					/>
					<div
						style={{ height: '32px' }}
						className="d-flex align-items-center justify-item-center">
						<Progress
							value={
								activeTabSecond
									? 100
									: activeTab === '2' || activeTab === '3'
									? 50
									: 0
							}
							className="progress"
							color={activeTabSecond && 'success'}
						/>
					</div>
				</div>
				<div className="d-flex align-items-start gap-3">
					<ProgressCircle
						cirlce={
							showStep3
								? doneCircle
								: activeTabSecond
								? activeCircle
								: emptyCircle
						}
						step={2}
						descp={t('reviewScan.step2')}
					/>

					<div
						style={{ height: '32px' }}
						className="d-flex align-items-center justify-item-center">
						{showStep3 ? (
							<Progress value={100} color="success" />
						) : (
							<Progress
								value={
									activeTabSecond === '1'
										? 15
										: activeTabSecond === '2'
										? 25
										: activeTabSecond === '3'
										? 35
										: activeTabSecond === '4'
										? 45
										: activeTabSecond === '5'
										? 55
										: activeTabSecond === '6'
										? 65
										: activeTabSecond === '7'
										? 75
										: 0
								}
								className="progress"
							/>
						)}
					</div>
				</div>
				<div className="d-flex align-items-start gap-3">
					<ProgressCircle
						cirlce={showStep3 ? activeCircle : emptyCircle}
						step={3}
						descp={t('reviewScan.step3')}
					/>
				</div>
			</div>
		</div>
	);
}

export default Steps;
