let index = 0;

const getIndex = () => index++;

export default {
	"referenceData": {
		"result": {
			"defaultValue": 2,
			"values": [{
				"value": 1,
				"label": "Compliant"
			},
			{
				"value": 2,
				"label": "Non-Compliant"
			}
			]
		},
		"severity": {
			"defaultValue": 3,
			"values": [{
				"value": 1,
				"label": "Low"
			},
			{
				"value": 2,
				"label": "Medium"
			},
			{
				"value": 3,
				"label": "High"
			},
			{
				"value": "4",
				"label": "Critical"
			}
			]
		},
		"categories": {
			"164.308.a.1.ii": {
				"parent": "fn-adminSafeguards",
				"name": "Security management - 164.308",
				"description": "The Security Management standard is intended to establish within a practice the implementation of appropriate policies and procedures to prevent, detect, contain, and correct security violations.",
				"subcategories": [
					"164.308.a.1.ii.1",
					"164.308.a.1.ii.2",
					"164.308.a.1.ii.3"
				]
			},
			"164.308.a.3": {
				"parent": "fn-adminSafeguards",
				"name": "Workforce Security - 164.308(a)(3)(i)",
				"description": "The workforce security standard is intended to establish policies and procedures to ensure that all employees have appropriate access to ePHI and to prevent those who do not/should not have access from obtaining access to ePHI.  Only those staff members or workforce members who need access to particular information should be able to view and/or modify ePHI.",
				"subcategories": [
					"164.308.a.3.ii.1"
				]
			},
			"164.308.a.4": {
				"parent": "fn-adminSafeguards",
				"name": "Information Access Management - 164.308(a)(4)",
				"description": "The information access management standard is intended to establish within practices policies and procedures for authorizing access to electronic PHI that are consistent with HIPAA security requirements.  The purpose is to minimize any risk of inappropriate disclosure, destruction or altercation of ePHI.",
				"subcategories": [
					"164.308.a.4.ii.1",
					"164.308.a.4.ii.2",
					"164.308.a.4.ii.3"
				]
			},
			"164.308.a.5": {
				"parent": "fn-adminSafeguards",
				"name": "Security Awareness and Training - 164.308(a)(5)(i)",
				"description": "Practices are required to implement a security awareness and training program for all members of its workforce, including management.",
				"subcategories": [
					"164.308.a.5.ii.1",
					"164.308.a.5.ii.2",
					"164.308.a.5.ii.3"
				]
			},
			"164.308.a.6": {
				"parent": "fn-adminSafeguards",
				"name": "Security Incident Procedures - 164.308(a)(6)",
				"description": "Practices are required to identify and respond to suspected or known security incidents and mitigate, if possible any harmful effects, and document such incidents and their outcomes.",
				"subcategories": [
					"164.308.a.6.ii.1"
				]
			},
			"164.308.a.7": {
				"parent": "fn-adminSafeguards",
				"name": "Contingency Plan - 164.308(a)(7)",
				"description": "Establish (and implement as needed) policies and procedures for responding to an emergency or other occurrence (for example, fire, vandalism, system failure, and natural disaster) that damages systems that contain electronic protected health information.",
				"subcategories": [
					"164.308.a.7.ii.1",
					"164.308.a.7.ii.2",
					"164.308.a.7.ii.3",
					"164.308.a.7.ii.4"
				]
			},
			"164.310.d.1": {
				"parent": "fn-physicalSafeguards",
				"name": "Device and Media Controls - 164.310(d)(1)",
				"description": "Implement policies and procedures that govern the receipt and removal of hardware and electronic media that contain electronic protected health information into and out of a facility, and the movement of these items within the facility.",
				"subcategories": [
					"164.310.d.2.1.1",
					"164.310.d.2.1.2",
					"164.310.d.2.1.3"
				]
			},
			"164.312.a.1": {
				"parent": "fn-technicalSafeguards",
				"name": "Access Control - 164.312(a)(1)",
				"description": "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity’s objectives.",
				"subcategories": [
					"164.312.a.2.i.1",
					"164.312.a.2.iv.2"
				]
			},
			"164.312.b": {
				"parent": "fn-technicalSafeguards",
				"name": "Audit Controls - 164.312(b)",
				"description": "Implement hardware, software, and/or procedural mechanisms that record and examine activity in information systems that contain or use ePHI.",
				"subcategories": [
					"164.312.b.1.1.1"
				]
			},
			"164.312.c.1": {
				"parent": "fn-technicalSafeguards",
				"name": "Integrity - 164.312(c)(1)",
				"description": "Implement policies and procedures to protect electronic protected health information from improper alteration or destruction.",
				"subcategories": [
					"164.312.c.2.1.1"
				]
			},
			"164.312.d": {
				"parent": "fn-technicalSafeguards",
				"name": "Person or Entity Authentication - 164.312(d)",
				"description": "Implement procedures to verify that a person or entity seeking access to electronic protected health information is the one claimed.",
				"subcategories": [
					"164.312.d.1.1.1"
				]
			},
			"164.312.e.1": {
				"parent": "fn-technicalSafeguards",
				"name": "Transmission Security",
				"description": "Implement technical security measures to guard against unauthorized access to electronic protected health information that is being transmitted over an electronic communications network.",
				"subcategories": [
					"164.312.e.2.ii.1"
				]
			}
		},
		"subcategories": {
			"164.308.a.1.ii.1": {
			 "parent": "164.308.a.1.ii",
			 "description": "Risk Analysis - Practices are required to conduct an assessment of the potential risks and vulnerabilities to the confidentiality, integrity, and availability of ePHI.  This process is intended to identify current security risks.",
			 "index": getIndex()
			},
			"164.308.a.1.ii.2": {
			 "parent": "164.308.a.1.ii",
			 "description": "Risk Management - Practices are required to implement security measures sufficient to reduce risks and vulnerabilities identified during the risk analysis and to stay compliant with HIPAA security standards.  This process is intended to ensure ongoing control of security risks.",
			 "index": getIndex()
			},
			"164.308.a.1.ii.3": {
			 "parent": "164.308.a.1.ii",
			 "description": "Information System Activity Review - Practices are required to implement procedures to regularly review records of information system activity, such as audit logs, access reports, and security incident tracking reports.",
			 "index": getIndex()
			},
			"164.308.a.3.ii.1": {
			 "parent": "164.308.a.3",
			 "description": "Authorization and/or Supervision - Practices should implement procedures for the authorization and/or supervision of employees who work with ePHI or in locations where it might be accessed.",
			 "index": getIndex()
			},
			"164.308.a.4.ii.1": {
			 "parent": "164.308.a.4",
			 "description": "Isolating Health care Clearinghouse Function - Requires clearinghouses that are part of larger organizations to implement policies and procedures that protect ePHI of the clearinghouse from unauthorized access by the larger organization.",
			 "index": getIndex()
			},
			"164.308.a.4.ii.2": {
			 "parent": "164.308.a.4",
			 "description": "Access Authorization - Implement policies and procedures for granting access to ePHI, for workstations, transactions, programs, processes, or other mechanisms.",
			 "index": getIndex()
			},
			"164.308.a.4.ii.3": {
			 "parent": "164.308.a.4",
			 "description": "Access Establishment and Modification - Implement policy and procedures, based on access authorization policies, to establish, document, review, and modify user's rights of access to workstations, transactions, programs, or processes.",
			 "index": getIndex()
			},
			"164.308.a.5.ii.1": {
			 "parent": "164.308.a.5",
			 "description": "Protection from Malicious Software - Implement procedures for guarding against, detecting, and reporting malicious software.",
			 "index": getIndex()
			},
			"164.308.a.5.ii.2": {
			 "parent": "164.308.a.5",
			 "description": "Log-in Monitoring - Implement procedures for monitoring and reporting log-in attempts and discrepancies.",
			 "index": getIndex()
			},
			"164.308.a.5.ii.3": {
			 "parent": "164.308.a.5",
			 "description": "Password Management - Implement procedures for creating, changing, and safeguarding appropriate passwords.",
			 "index": getIndex()
			},
			"164.308.a.6.ii.1": {
			 "parent": "164.308.a.6",
			 "description": "Response and Reporting - Identify and respond to suspected or known security incidents; mitigate, to the extent practicable, harmful effects of security incidents that are known to the covered entity; and document security incidents and their outcomes.",
			 "index": getIndex()
			},
			"164.308.a.7.ii.1": {
			 "parent": "164.308.a.7",
			 "description": "Data Backup Plan - Establish and (implement as needed) procedures to create and maintain retrievable, exact copies of ePHI during unexpected negative events.",
			 "index": getIndex()
			},
			"164.308.a.7.ii.2": {
			 "parent": "164.308.a.7",
			 "description": "Disaster Recovery Plan - Establish (and implement as needed) procedures to restore any loss of data.",
			 "index": getIndex()
			},
			"164.308.a.7.ii.3": {
			 "parent": "164.308.a.7",
			 "description": "Emergency Mode Operation Plan - Establish (and implement as needed) procedures to enable continuation of critical business processes for protection of ePHI while operating in emergency mode.",
			 "index": getIndex()
			},
			"164.308.a.7.ii.4": {
			 "parent": "164.308.a.7",
			 "description": "Applications and Data Criticality Analysis - If applicable, practices should assess the relative criticality of specific applications and data in support of other contingency plan components.",
			 "index": getIndex()
			},
			"164.310.d.2.1.1": {
			 "parent": "164.310.d.1",
			 "description": "Media Disposal & Disposition - The practice has policies and procedures for removing ePHI from hardware or electronic media on which it is stored prior to disposal or re-use.",
			 "index": getIndex()
			},
			"164.310.d.2.1.2": {
			 "parent": "164.310.d.1",
			 "description": "Hardware & Media Accountability - The practice is required to maintain records of the movements of hardware and electronic media, and any person responsible therefore.",
			 "index": getIndex()
			},
			"164.310.d.2.1.3": {
			 "parent": "164.310.d.1",
			 "description": "Data Backup and Storage - Create a retrievable, exact copy of electronic protected health information, when needed, before movement of equipment.",
			 "index": getIndex()
			},
			"164.312.a.2.i.1": {
			 "parent": "164.312.a.1",
			 "description": "Unique User Identification - Assign a unique name and/or number for identifying and tracking user identity.",
			 "index": getIndex()
			},
			"164.312.a.2.iv.2": {
			 "parent": "164.312.a.1",
			 "description": "Encryption and Decryption - Implement an appropriate mechanism to encrypt and decrypt ePHI.",
			 "index": getIndex()
			},
			"164.312.b.1.1.1": {
			 "parent": "164.312.b",
			 "description": "Audit Controls - Implement hardware, software, and/or procedural mechanisms that record and examine activity in information systems that contain or use ePHI.",
			 "index": getIndex()
			},
			"164.312.c.2.1.1": {
			 "parent": "164.312.c.1",
			 "description": "Mechanism to Authenticate Electronic PHI - Implement electronic mechanisms to corroborate that ePHI not been altered or destroyed in an unauthorized manner.",
			 "index": getIndex()
			},
			"164.312.d.1.1.1": {
			 "parent": "164.312.d",
			 "description": "Person Or Entity Authentication - Implement procedures to verify that a person or entity seeking access to electronic protected health information is the one claimed.",
			 "index": getIndex()
			},
			"164.312.e.2.ii.1": {
			 "parent": "164.312.e.1",
			 "description": "Encryption - Implement technical security measures to guard against unauthorized access to electronic protected health information that is being transmitted over an electronic communications network.",
			 "index": getIndex()
			}
		 },
	},
	"headers": [{
		"id": 1,
		"value": "Safeguards",
		"classList": "dont-print col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 2,
		"value": "Implementation Specification",
		"classList": "col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 3,
		"value": "Assessment",
		"classList": "print-sub-cat-col col col-md-2 text-md-center",
		"style": null
	},
	{
		"id": 4,
		"value": "Result",
		"classList": "print-result-col col col-md-2 text-md-center",
		"style": {
			"marginLeft": "-1rem"
		}
	},
	{
		"id": 5,
		"value": "Severity",
		"classList": "col col-md-1 text-md-left",
		"style": {
			"marginLeft": "3rem"
		}
	},
	{
		"id": 6,
		"value": "Evidence",
		"classList": "col col-md-1 text-md-center",
		"style": {
			"marginLeft": "6rem"
		}
	}
	],
  "testCases": [{
      "id": "fn-adminSafeguards",
      "value": "Administrative Safeguards",
      "fgColor": "#e9ecef",
			"bgColor": "#4da6ff",
      "description": [
        "Administrative Safeguards:  164.308"
      ],
      "categories": ["164.308.a.1.ii", "164.308.a.3", "164.308.a.4", "164.308.a.5", "164.308.a.6", "164.308.a.7"]
    },
    {
      "id": "fn-physicalSafeguards",
      "value": "Physical Safeguards",
      "fgColor": "#e9ecef",
			"bgColor": "#a31aff",
      "description": [
        "Physical Safeguards:  164.310"
      ],
      "categories": ["164.310.d.1"]
    },
    {
      "id": "fn-technicalSafeguards",
      "value": "Technical Safeguards",
      "fgColor": "#e9ecef",
			"bgColor": "#e60000",
      "description": [
        "Technical Safeguards:  164.312"
      ],
      "categories": ["164.312.a.1", "164.312.b", "164.312.c.1", "164.312.d","164.312.e.1"]
    }
  ]
};