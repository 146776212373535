import React from 'react';
import { Trans } from 'react-i18next';

const key = 'testReports.testMetaInfo.cmms.';

export default [
	{
		title: <Trans i18nKey={key + 'level1.title'} />,
		titleBackground: '#66d9ff',
		text: [
			<Trans i18nKey={key + 'level1.text1'} />,
			<Trans i18nKey={key + 'level1.text2'} />,
			<Trans i18nKey={key + 'level1.text3'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'level2.title'} />,
		titleBackground: ' #00ace6',
		text: [
			<Trans i18nKey={key + 'level2.text1'} />,
			<Trans i18nKey={key + 'level2.text2'} />,
			<Trans i18nKey={key + 'level2.text3'} />,
			<Trans i18nKey={key + 'level2.text3'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'level3.title'} />,
		titleBackground: '#ff6633',
		text: [
			<Trans i18nKey={key + 'level3.text1'} />,
			<Trans i18nKey={key + 'level3.text2'} />,
			<Trans i18nKey={key + 'level3.text3'} />,
			<Trans i18nKey={key + 'level3.text4'} />,
			<Trans i18nKey={key + 'level3.text5'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'level4.title'} />,
		titleBackground: '#ff8000',
		text: [
			<Trans i18nKey={key + 'level4.text1'} />,
			<Trans i18nKey={key + 'level4.text2'} />,
			<Trans i18nKey={key + 'level4.text3'} />,
			<Trans i18nKey={key + 'level4.text4'} />,
			<Trans i18nKey={key + 'level4.text5'} />,
			<Trans i18nKey={key + 'level4.text6'} />,
		],
	},
	{
		title: <Trans i18nKey={key + 'level5.title'} />,
		titleBackground: '#66cc00',
		text: [
			<Trans i18nKey={key + 'level5.text1'} />,
			<Trans i18nKey={key + 'level5.text2'} />,
			<Trans i18nKey={key + 'level5.text3'} />,
			<Trans i18nKey={key + 'level5.text4'} />,
			<Trans i18nKey={key + 'level5.text5'} />,
			<Trans i18nKey={key + 'level5.text6'} />,
			<Trans i18nKey={key + 'level5.text7'} />,
		],
	},
];
