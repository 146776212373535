// Sidebar
export const SIDEBAR_VISIBILITY_TOGGLE = 'SIDEBAR_VISIBILITY_TOGGLE';
export const SIDEBAR_VISIBILITY_SHOW = 'SIDEBAR_VISIBILITY_SHOW';
export const SIDEBAR_VISIBILITY_HIDE = 'SIDEBAR_VISIBILITY_HIDE';
export const SIDEBAR_RIGHT_ENABLE = 'SIDEBAR_RIGHT_ENABLE';
export const SIDEBAR_RIGHT_DISABLE = 'SIDEBAR_RIGHT_DISABLE';

// Theme
export const THEME_TOGGLE = 'THEME_TOGGLE';

// Idle timer

export const IDLE_TIMEOUT = 'IDLE_TIMEOUT';
export const USER_ACTIVE = 'USER_ACTIVE';

// Topology
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
// Application
export const SET_USER = 'SET_USER';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
