import React, { useMemo, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getFilteredRowModel } from '@tanstack/react-table';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import {
	getFullResourceIdOrName as resourceId,
	getTruncatedResourceIdOrName as resourceName,
} from '../Common/RansomwareUtilites';

const ResourceDetails = ({ issues, onReportClick }) => {
	const { t } = useTranslation();
	const dataExists = issues && issues.length > 0;
	const noData = !dataExists;

	const columns = useMemo(() => [
		{
			accessorKey: 'ResourceType',
			header: () => <Trans i18nKey="vulnerabilities.resourceType" />,
			cell: info => info.getValue(),
		},
		{
			accessorKey: 'ResourceName',
			header: () => <Trans i18nKey="vulnerabilities.resourceName" />,
			cell: info => (
				<div className='my-35-step'
					title={resourceId(info.row.original)}
					onClick={() => onReportClick(info.row.original)}
				>
					{resourceName(info.row.original)}
				</div>
			),
		},
	], [onReportClick]);

	const [globalFilter, setGlobalFilter] = useState('');

	const table = useReactTable({
		data: issues || [],
		columns,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			globalFilter,
			rowSelection: {},
		},
		onGlobalFilterChange: setGlobalFilter,
	});

	return (
		<>
			{noData ? (
				<h5>{t('vulnerabilities.notFound')}</h5>
			) : (
				<>
					<Input
						type="text"
						placeholder="Search"
						value={globalFilter}
						onChange={(e) => setGlobalFilter(e.target.value)}
						style={{ width: '200px', marginBottom: '10px' }}
					/>
					<table className="table">
						<thead>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map(column => (
										<th key={column.id}>
											{flexRender(column.column.columnDef.header, column.getContext())}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map(row => (
								<tr key={row.id}>
									{row.getVisibleCells().map(cell => (
										<td key={cell.id}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</>
			)}
		</>
	);
};

ResourceDetails.defaultProps = {
	onReportClick: () => {},
};

export default ResourceDetails;
