import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap';
import { ExternalLink, MoreVertical, RefreshCw } from 'react-feather';
import React, { useEffect, useRef, useState } from 'react';

import { EvaluationApiClient } from '../../../apiclients/EvaluationApiClient';
import IssueType from '../../../constants/IssueType';
import Loader from '../../../components/Loader';
import NetworkTopology from '../../Topology/Controls/TopologyControl';
import ResourceInfo from './ResourceInfo';
import TopologyPopup from '../../Topology/Controls/TopologyPopup';
import Legend from './Legend';
import TopologyConfig from '../../Topology/Config';
import InfoModal from '../../../components/InfoModal';
import { t } from 'i18next';

const config = new TopologyConfig();

const ComplianceTopology = ({ report, name }) => {
	const [nodes, setNodes] = useState([]);
	const [data, setData] = useState({ loaded: false, nodes: [], error: false });
	const [selectedNode, setSelectedNode] = useState({});
	const [nodeShow, setNodeShow] = useState({});
	const [popupSelectedNode, setPopupSelectedNode] = useState({});
	const topologyRef = useRef();
	const [showPopup, setShowPopup] = useState(false);
	const [legendItems, setLegendItems] = useState(config.legend());
	const [graphKey, setGraphKey] = useState('0000');
	const [infoModal, setInfoModal] = useState(false);
	const [modalData, setModalData] = useState({});

	const onShowPopup = value => setShowPopup(value);

	const client = new EvaluationApiClient();

	const onMouseOver = e => {
		const ele = e.target,
			data = ele.data();
		data.nonCompliantRules = true;
		if (ele.isNode()) {
			setSelectedNode(data);
		}
		setModalData(data);
		setInfoModal(true);
	};

	const onMouseOut = e => {
		const ele = e.target;
		if (ele.isNode()) {
			setSelectedNode({});
		}
	};

	const resetLayout = () => {
		topologyRef.current.resetLayout();
	};

	useEffect(() => {
		client.getliveEnvironmentCompliance(
			report,
			nodes => {
				setNodes(nodes);
				setData({ loaded: true, nodes, error: false });
			},
			e => {
				console.error('Error loading topology data', e);
				setData({ loaded: false, nodes: [], error: true });
			}
		);
	}, [client, report]);

	const topoTitle = `Cloud Compliance Topology - ${name}`;

	const itemUpdate = (el, compliance, item) => {
		// update settings
		if ('compliant' === compliance) {
			item.showCompliant = !item.showCompliant;
		} else if ('noncompliant' === compliance) {
			item.showNonCompliant = !item.showNonCompliant;
		}

		var newItems = [];
		var nTypes = {};
		for (const curItem of legendItems) {
			nTypes[curItem.label] = {};
			if (curItem.label === item.label) {
				newItems.push(item);
				nTypes[curItem.label].showCompliant = item.showCompliant;
				nTypes[curItem.label].showNonCompliant = item.showNonCompliant;
			} else {
				newItems.push(curItem);
				nTypes[curItem.label].showCompliant = curItem.showCompliant;
				nTypes[curItem.label].showNonCompliant = curItem.showNonCompliant;
			}
		}
		setLegendItems(newItems);
		setNodeShow(nTypes);
	};

	const showHideAll = () => {};

	useEffect(() => {
		if (!nodeShow || Object.keys(nodeShow).length === 0) return;
		const newNodes = nodes.filter(node => {
			if (node.data.compliance === 'Compliant')
				return nodeShow[node.data.resourcetype].showCompliant;
			else return nodeShow[node.data.resourcetype].showNonCompliant;
		});
		setData({ loaded: true, nodes: newNodes, error: false });
		setGraphKey(Math.floor(1000 + Math.random() * 9000));
	}, [nodeShow, nodes]);

	return (
		<>
			<Col xs="6">
				<Card className="top-row-card">
					<CardHeader>
						<h5 className="float-start">
							{t('tests.cloudCompliance')} - {name}
						</h5>
						<div className="card-actions float-end">
							<ExternalLink
								className="me-3"
								title="Popout"
								onClick={onShowPopup}
							/>
							<UncontrolledDropdown className="d-inline-block">
								<DropdownToggle tag="a">
									<MoreVertical />
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem onClick={resetLayout}>
										<RefreshCw className="me-3" />
										<span>{t('button.resetLayout')}</span>
									</DropdownItem>
									<DropdownItem onClick={onShowPopup}>
										<ExternalLink className="me-3" />
										<span>{t('button.popout')}</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					</CardHeader>
					<CardBody>
						{!data.loaded && !data.error && <Loader className="h-25" />}
						{data.loaded && !data.error && (
							<>
								{!showPopup && (
									<NetworkTopology
										key={graphKey}
										ref={topologyRef}
										nodes={data.nodes}
										chartDim={{ h: 490, w: 670 }}
										onMouseOut={onMouseOut}
										onMouseOver={onMouseOver}
									/>
								)}
								{showPopup && (
									<TopologyPopup
										title={topoTitle}
										nodes={data.nodes}
										onClose={setShowPopup}
										onNodeSelect={setPopupSelectedNode}>
										<ResourceInfo
											node={popupSelectedNode}
											issueLabel="Resource Compliant"
											infoType={IssueType.Compliance}
										/>
									</TopologyPopup>
								)}
							</>
						)}
						{data.error && <h5>{t('tests.errorLoading')}</h5>}
					</CardBody>
				</Card>
			</Col>
			<Col xs="3">
				<Legend
					legendItems={legendItems}
					itemUpdate={itemUpdate}
					showHideAll={showHideAll}
				/>
			</Col>
			{infoModal && (
				<InfoModal
					report={report}
					data={modalData || {}}
					isOpen={infoModal}
					onClose={() => setInfoModal(false)}
				/>
			)}
		</>
	);
};

export default ComplianceTopology;
