import { getAccountLimits } from './AuthService';

const isDemoAccount = () => {
    const limits = getAccountLimits();
    return limits && limits.accountType && 'DEMO' === limits.accountType
        ? true
        : false;
};

const isTrialAccount = () => {
    const limits = getAccountLimits();
    return limits && limits.accountType && 'TRIAL' === limits.accountType
        ? true
        : false;
};

const isNormalAccount = () => {
    const limits = getAccountLimits();
    return limits && limits.accountType && 'NORMAL' === limits.accountType
        ? true
        : false;
};

const isSuperAdmin = () => {
    const limits = getAccountLimits();
    return limits && limits.superAdmin && true === limits.superAdmin
        ? true
        : false;
};

export { isDemoAccount, isTrialAccount, isNormalAccount, isSuperAdmin };
