import { getItem, setItem } from '../../../services/browserStorageService';

let reportKey = '';

const getChangedValues = () => {

	let values = {};

	if (reportKey.length > 0) {
		const raw = getItem(reportKey);
		values = JSON.parse(raw);
	}

	return values;
}

const setChangedValues = (values) => {
	const serialized = JSON.stringify(values);
	if (reportKey.length > 0) setItem(reportKey, serialized);
}

const setKey = (key) => {
	reportKey = key;
}

const clearKey = () => {
	if (reportKey.length > 0) setItem(reportKey, '');
	reportKey = '';
}

export { setKey, setChangedValues, getChangedValues, clearKey };