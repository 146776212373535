import React from 'react';

const Checkbox = ({ label, id, checked, onChange, className, disabled }) => {
	return (
		<div className="form-check cursor-pointer checkbox-wrapper mb-2">
			<input
				type="checkbox"
				id={id}
				className={
					checked
						? `form-check-input checked ${className}`
						: `form-check-input  ${className}`
				}
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
			<label className="form-check-label" htmlFor={id}>
				{label}
			</label>
		</div>
	);
};

export default Checkbox;
