import { Card, CardBody, CardHeader, CardTitle, Row, Col } from 'reactstrap';
import React from 'react';
import Error from './Error';
import { Bar } from 'react-chartjs-2';
import LoadingSpinner from './LoadingSpinner';
import { ChevronRight } from 'react-feather';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { BarElement, Tooltip, Chart } from 'chart.js';
import { Colours } from '../Common/Constants';
Chart.register(BarElement);
Chart.register(Tooltip);

const getChartData = (packName, packKey, result, theme) => {
	const keys = Object.keys(result);

	const backgroundColors = keys.map(key => Colours[packKey]);

	const data = {
		labels: keys,
		datasets: [
			{
				label: packName,
				backgroundColor: backgroundColors,
				borderColor: theme.danger,
				hoverBackgroundColor: backgroundColors,
				hoverBorderColor: theme.danger,
				barPercentage: 0.75,
				borderRadius: { topLeft: 10, topRight: 10 },
				data: keys.map(key => result[key]),
			},
		],
	};

	return data;
};

const options = {
	maintainAspectRatio: false,
	indexAxis: 'x',
	legend: {
		display: false,
	},
	scales: {
		y: {
			gridLines: {
				display: true,
			},
			stacked: false,
			ticks: {
				stepSize: 5,
			},
		},
		x: {
			stacked: false,
			gridLines: {
				color: 'transparent',
			},
		},
	},
};

const Details = ({ theme, packName, packKey, result }) => (
	<Card>
		<div className="d-flex justify-content-center">
			<h5>{packName}</h5>
		</div>
		<CardBody className="chart chart-xs">
			<Bar
				data={getChartData(packName, packKey, result, theme)}
				options={options}
			/>
		</CardBody>
	</Card>
);
const TopRules = ({ theme, loading, error, results }) => (
	<Row>
		{results.map((item, index) => (
			<Col key={index} xs={4}>
				{error && <Error text={t('complianceDashboard.trendError')} />}
				{!error && loading && <LoadingSpinner />}
				{!error && !loading && (
					<Details
						packName={item.packName}
						result={item.top}
						theme={theme}
						packKey={item.packKey}
					/>
				)}
			</Col>
		))}
	</Row>
);

export default connect(store => ({
	theme: store.theme.currentTheme,
}))(TopRules);
