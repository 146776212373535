import { CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import {
	ComplianceType,
	ComplianceTypes,
} from '../../../constants/ComplianceType';
import React, { useEffect, useRef, useState } from 'react';

import Card from 'reactstrap/lib/Card';
import { ExternalLink } from 'react-feather';
import IssueType from '../../../constants/IssueType';
import Loader from '../../../components/Loader';
import { getCompliantResourceList } from '../../../apiclients/Ransomware';
import NetworkTopology from '../../Topology/Controls/TopologyControl';
import ResourceInfo from '../../tests/AutomatedTests/ResourceInfo';
import TopologyPopup from '../../Topology/Controls/TopologyPopup';

const type = new ComplianceType();

const getDeviceIssueMap = (issues, nodes) => {
	if (!issues) {
		return new Map();
	}

	const mapped = new Map();

	const update = (issue, rules) => {
		rules.forEach(x => {
			var name = x.ResourceName;

			if (mapped.has(name)) {
				let r = mapped.get(name);
				if (r.indexOf(issue) < 0) {
					r.push(issue);
				}
			} else {
				mapped.set(x.ResourceName, [issue]);
			}
		});
	};

	for (const [name, rules] of Object.entries(issues)) {
		update(name, rules);
	}

	for (const index in nodes) {
		const node = nodes[index];
		const nodeId = node.data.id;
		if (mapped.has(nodeId)) {
			node.data.issues = mapped.get(nodeId);
		}
	}

	return mapped;
};

const isVulnerable = data => {
	const isCompliant = type.isCompliant(data.compliance);
	const isNonCompliant = type.isNonCompliant(data.compliance);
	const hasRules = data['non-compliant-rules'].length > 0;

	if (!hasRules && isCompliant) {
		return true;
	}

	if (isNonCompliant) {
		return true;
	}
};

const useTopology = ({ reportId, issues }) => {
	const [data, setData] = useState({ loaded: false, nodes: [], error: false });
	const [selectedNode, setSelectedNode] = useState({});
	const [showPopup, setShowPopup] = useState(false);
	const [popupSelectedNode, setPopupSelectedNode] = useState({});
	const [deviceIssueMap, setDeviceIssueMap] = useState(new Map());

	useEffect(() => {
		if (reportId) {
			const getResourceList = async () => {
				const response = await getCompliantResourceList(reportId);
				if (response.success && response.data) {
					setDeviceIssueMap(getDeviceIssueMap(issues, response.data));
					setData({ loaded: true, nodes: response.data, error: false });
				} else {
					setData({ loaded: false, nodes: [], error: true });
				}
			};

			getResourceList();
		} else {
			setData({ loaded: true, nodes: [], error: true });
		}
	}, [issues, reportId]);

	const onMouseOver = e => {
		const ele = e.target,
			data = ele.data();

		data.nonCompliantRules = true;

		if (deviceIssueMap.has(data.id)) {
			if (isVulnerable(data)) {
				data.compliance = ComplianceTypes.NonCompliant;
			}

			const rules = data['non-compliant-rules'];
			const mapped = deviceIssueMap.get(data.id);
			const combined = mapped.concat(rules);
			data['non-compliant-rules'] = combined;
		}

		if (ele.isNode()) {
			setSelectedNode(data);
		}
	};

	const onMouseOut = e => {
		const ele = e.target;
		if (ele.isNode()) {
			setSelectedNode({});
		}
	};

	return {
		showPopup,
		data,
		selectedNode,
		popupSelectedNode,
		onMouseOver,
		onMouseOut,
		setShowPopup,
		setPopupSelectedNode,
	};
};

const RansomwareTopology = ({
	reportId,
	title,
	issues,
	hideRules,
	chartDim,
}) => {
	const topologyRef = useRef();
	const {
		showPopup,
		data,
		selectedNode,
		popupSelectedNode,
		onMouseOver,
		onMouseOut,
		setShowPopup,
		setPopupSelectedNode,
	} = useTopology({ reportId, issues });

	

	return (
		<Card className="dashboard-card">
			<CardHeader>
				<h5 className="float-start">{title}</h5>
				<div className="card-actions float-end">
					<ExternalLink
						className="me-3"
						onClick={() => setShowPopup(true)}
						title="Popout"
					/>
				</div>
			</CardHeader>
			<CardBody>
				{!data.loaded && !data.error && <Loader className="h-25" />}
				{data.error && (
					<Container>
						<Row>
							<Col>
								<h3 className="text-danger">
									An error occurred when trying to load the topology.
								</h3>
							</Col>
						</Row>
					</Container>
				)}
				{data.loaded && !data.error && (
					<Container>
						<Row >
							<Col xs={9} className="px-1">
								{!showPopup && (
									<NetworkTopology
										ref={topologyRef}
										nodes={data.nodes}
										chartDim={chartDim}
										onMouseOut={onMouseOut}
										onMouseOver={onMouseOver}
									/>
								)}
								{showPopup && (
									<TopologyPopup
										title={title}
										nodes={data.nodes}
										onClose={setShowPopup}
										onNodeSelect={setPopupSelectedNode}>
										<ResourceInfo
											node={popupSelectedNode}
											hideRules={hideRules}
											rulesLabel="Issues"
											issueLabel="Resource Vulnerable"
											infoType={IssueType.Vulnerabilities}
										/>
									</TopologyPopup>
								)}
							</Col>

							<Col xs="3" style={{ height: '200px' }} className="px-1">
								<ResourceInfo
									node={selectedNode}
									hideRules={hideRules}
									rulesLabel="Issues"
									issueLabel="Resource Vulnerable"
									infoType={IssueType.Vulnerabilities}
								/>
							</Col>
						</Row>
					</Container>
				)}
			</CardBody>
		</Card>
	);
};

export default RansomwareTopology;
