import './AutomatedTests.css';

import { Card, CardBody, Container, Row } from 'reactstrap';

import { ComplianceType } from '../../../constants/ComplianceType';
import Config from '../../Topology/Config';
import IssueType from '../../../constants/IssueType';
import React from 'react';
import classNames from 'classnames';
import { t } from 'i18next';

const config = new Config();
const safeText = value => (value ? value : '-');

const leftEllipses = {
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	direction: 'rtl',
	textAlign: 'left',
};

const PropertyInfo = ({ title, value, isLower, extraHeight, filter }) => {
	const className = classNames({
		'font-weight-light': true,
		'text-lowercase': isLower,
		'extra-height': extraHeight,
	});

	return (
		<Row>
			<Container fluid>
				<Row>
					<h5 className="d-block mt-1">{title}</h5>
				</Row>
				<Row>
					<span style={leftEllipses} className={className}>
						{filter && value ? filter(value) : safeText(value)}
					</span>
				</Row>
			</Container>
		</Row>
	);
};

const successBadgeCss = 'badge badge-success';
const dangerBadgeCss = 'badge badge-danger';

const complianceType = new ComplianceType();

const getTextAndCss = (infoType, text, issues) => {
	if (text === '-') {
		return { statusText: '-', textCss: '' };
	}

	if (infoType === IssueType.Compliance) {
		const isCompliant = complianceType.isCompliant(text);

		return {
			statusText: isCompliant ? t('button.yes') : t('button.no'),
			textCss: isCompliant ? successBadgeCss : dangerBadgeCss,
		};
	} else if (infoType === IssueType.Vulnerabilities) {
		const hasIssues = issues && issues.length > 0;

		return {
			statusText: hasIssues ? t('button.yes') : t('button.no'),
			textCss: hasIssues ? dangerBadgeCss : successBadgeCss,
		};
	} else {
		return { statusText: '-', textCss: '' };
	}
};

const ResultInfo = ({
	title,
	value,
	isLower,
	extraHeight,
	issues,
	infoType,
}) => {
	const text = safeText(value);

	const { statusText, textCss } = getTextAndCss(infoType, text, issues);

	const className = classNames(
		{
			'font-weight-light': true,
			'text-lowercase': isLower,
			'extra-height': extraHeight,
		},
		textCss
	);

	return (
		<Row>
			<Container fluid>
				<Row>
					<h5 className="d-block mt-1">{title}</h5>
				</Row>
				<Row>
					<span className={className}>{statusText}</span>
				</Row>
			</Container>
		</Row>
	);
};

const ComplianceRules = ({ title, values, isLower }) => {
	const className = classNames({
		'font-weight-light': true,
		'mt-1': true,
		'text-danger': true,
		'text-lowercase': isLower,
	});

	const rulesExist = values && values.length > 0;

	return (
		<Row>
			<Container fluid>
				<Row>
					<h5 className="d-block mt-2">{title}</h5>
				</Row>
				<Row>
					{!rulesExist && <span>-</span>}
					{rulesExist && (
						<div className="compliance-list-container">
							<ul className="compliance-list">
								{values.map((value, index) => (
									<li key={index} className={className}>
										{safeText(value)}
									</li>
								))}
							</ul>
						</div>
					)}
				</Row>
			</Container>
		</Row>
	);
};

const ResourceInfo = ({
	node,
	hideRules,
	rulesLabel,
	issueLabel,
	infoType,
}) => {
	return (
		<Card className="w-100 top-row-card">
			<CardBody>
				<Container fluid className="property-container">
					<PropertyInfo
						title={t('table.resourceType')}
						value={node.resourcetype}
						filter={v => config.getLabel(v)}
						isLower={true}
						extraHeight={false}
					/>
					<PropertyInfo
						title={t('table.resourceId')}
						value={node.id}
						isLower={true}
						extraHeight={true}
					/>
					<PropertyInfo
						title={t('table.resourceName')}
						value={node.name}
						isLower={true}
						extraHeight={true}
					/>
					<ResultInfo
						title={issueLabel}
						value={node.compliance}
						issues={node.issues}
						isLower={false}
						extraHeight={false}
						infoType={infoType}
					/>
					{!hideRules && (
						<ComplianceRules
							title={rulesLabel ?? t('table.nonCompliantRules')}
							values={node['non-compliant-rules']}
							isLower={false}
						/>
					)}
				</Container>
			</CardBody>
		</Card>
	);
};

export default ResourceInfo;
