import React, { useEffect, useState } from 'react';

import FileSelector from './FileSelector';
import { t } from 'i18next';

const useEvidence = (value, onChange, onError, isModified) => {
	const isValid = value && value.length > 0;
	const [error, setError] = useState(!isValid);
	const [text, setText] = useState(value ?? '');

	useEffect(() => {
		setText(value ?? '');
		const isValid = value && value.length > 0;
		setError(isModified && !isValid);
		return () => {
			setText('');
		};
	}, [isModified, value]);

	const onSelect = name => {
		setText(name);
		const isValid = name && name.length > 0;
		onChange('evidence', name);
		setError(!isValid);
		onError(isModified && !isValid);
	};

	return { text, error, onSelect };
};

export default function ({ value, onChange, onError, isModified }) {
	const { text, onSelect } = useEvidence(value, onChange, onError, isModified);

	return (
		<div className="container">
			<div className="row">
				<label htmlFor="evidence">{t('form.evidence')}</label>
			</div>
			<div className="row">
				<input
					name="evidence"
					value={text}
					disabled
					type="text"
					style={{ width: '90%' }}
				/>
				<FileSelector onSelect={onSelect} />
			</div>
		</div>
	);
}
