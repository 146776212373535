import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Copy, Download } from 'react-feather';
import Joyride, { STATUS } from 'react-joyride';
import CustomTooltip from '../ransomware/dashboard/CustomTooltip';

import Textarea from '../../components/Form/Textarea';
import Select from '../../components/Form/Select';
import Loader from '../../components/Loader';
import Button from '../../components/Form/Button';
import { ReactComponent as ChevronSvg } from '../../assets/img/chevron.svg';
import DashBoardError from '../ransomware/dashboard/controls/DashBoardError';
import RemediationSteps from './components/RemediationSteps';
import useTalasmartResource from './hooks/useTalasmartResource';
import ResourceList from './components/ResourceList';
import copyBtn from '../../assets/img/copyBtn.svg';

import {
	REMEDIATION_STEPS,
	SEVERITY_OPTIONS,
	TALASMART_WAIT_TEXT,
} from './constants';
import IssueTracking from './components/IssueTracking';
import NotAvailablePopup from './components/NotAvailablePopup';
import { ArrowUp } from 'react-feather';
import { X } from 'react-feather';
import { ReactComponent as IconRocket } from './images/Illustration.svg';

const TalasmartResource = () => {
	const { t } = useTranslation();
	const [isPopupShown, setIsPopupShown] = useState(false);
	const [showDiv, setShowDiv] = useState(false);
	const [run, setRun] = useState(true);
	const showUserJourney = ('1' === process.env?.REACT_APP_FEAT_SHOW_JOURNEY) ? true : false;

	const copyToClipBoard = async text => {
		try {
			await navigator.clipboard.writeText(text);
		} catch (err) {
			throw new Error(err);
		}
	};

	const copyTextStyle = {
		color: '#2474F3',
		fontFamily: 'Inter',
		fontSize: 16,
	};
	const [activeRemediation, setActiveRemediation] = useState(
		REMEDIATION_STEPS.console
	);

	const {
		info,
		loaded,
		error,
		results,
		pageData,
		resourceInfo,
		remediationInfo,
		resourceId,
		report,
		onChangeField,
		onBlurField,
		onChangeResource,
		onCopy,
		onDownload,
		onChangeRemediationTab,
		isRemediationLoading,
	} = useTalasmartResource(activeRemediation);

	const [isResourceListOpen, setIsResourceListOpen] = useState(false);

	const onChangeTab = async tab => {
		setActiveRemediation(tab);
		onChangeRemediationTab(tab);
	};

	const filteredResources = useMemo(() => {
		if('RRA' === report.PackName && results.rraIssues && info?.SecurityTestRule && results.rraIssues[info?.SecurityTestRule])
		{
			// swap security test rule and root cause, add ResourceId == name
			return results.rraIssues[info?.SecurityTestRule].filter(item => item.ResourceName !== resourceId).map((item) => {
				return {
					ResourceArn: item.ResourceArn,
					ResourceName: item.ResourceName,
					ResourceId: item.ResourceName,
					ResourceType: item.ResourceType,
					RootCause: item.SecurityTestRule,
					SecurityTestRule: info?.SecurityTestRule
				}
			});
		}
		return results.rows.filter(item => item.ResourceId !== resourceId && item.SecurityTestRule === info?.SecurityTestRule);
	}, [resourceId, results.rows, results.rraIssues, info, report]);

	const steps = [
		{
			target: '.my-1-step',
			content: 'Select a Compliance Pack.',
			placement: 'left',
		},
		{
			target: '.my-2-step',
			content: 'Resource Details.',
			placement: 'right',
		},
		{
			target: '.my-3-step',
			content: 'Status and ticket for Issue',
			// placement: 'left',
		},
		{
			target: '.my-4-step',
			content: 'Description of the issue',
			placement: 'left',
		},
		{
			target: '.my-5-step',
			content: 'The technical impact of the issue',
			placement: 'top',
		},
		{
			target: '.my-6-step',
			content: 'The system impact of the issue',
		},
		{
			target: '.my-7-step',
			content: 'Click to get the Terraform code',
		},
		{
			target: '.my-8-step',
			content:
				'AI Generated Terraform code to remediate the issue. Click on the "Copy to clipboard" button to save the steps',
		},
	];

	const handleJoyrideCallback = data => {
		const { status, type } = data;
		const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

		if (finishedStatuses.includes(status)) {
			setRun(false);
		}
	};
	useEffect(() => {
		const timer = setTimeout(() => {
			setShowDiv(true);
			// setRun(true);
		}, 5000);

		return () => clearTimeout(timer); // Очищення таймера при розмонтуванні компонента
	}, []);
	const joyrideStyles = {
		buttonNext: {
			backgroundColor: '#007bff', // Bootstrap primary blue
			color: '#fff',
		},
		buttonBack: {
			color: '#007bff', // Bootstrap primary blue
		},
		buttonSkip: {
			color: '#007bff', // Bootstrap primary blue
		},
	};
	const handleGetStartedClick = () => {
		setRun(!run);
		setShowDiv(false);
	};

	return (
		<>
			{showUserJourney &&
				<Joyride
					steps={steps}
					run={run}
					continuous
					scrollToFirstStep
					showProgress
					showSkipButton
					callback={handleJoyrideCallback}
					disableOverlay={true}
					disableOverlayClose={true}
					styles={joyrideStyles}
					locale={{
						next: 'Next',
						back: 'Back',
						last: 'Last',
					}}
					tooltipComponent={CustomTooltip}
				/>
			}
			{!loaded && <Loader />}
			{loaded && error && <DashBoardError />}
			{loaded && !error && (
				<section className="d-flex gap-2 talasmart-resource pb-3 position-relative">
					{showUserJourney &&
						<button onClick={() => setShowDiv(!showDiv)} className="rocket">
							<IconRocket className="RocketSvg" />
							{/* <img src={MySvg} alt="Button Icon" className="" /> */}
						</button>}
					<Col
						md="6"
						className="d-flex flex-column gap-4 border-right px-4 position-relative">
						<Row className="talasmart-resource__item justify-content-between">
							<button
								className="talasmart-resource__back"
								onClick={() => {
									window.history.back();
								}}>
								<ChevronSvg />
								{t('button.back')}
							</button>
						</Row>
						{showUserJourney && showDiv && (
							<div className="journey">
								{/* <ChevronRight /> */}
								<div className="joyWrap">
									<div className="joyWrapText">
										<h2 className="journeyTitle">Choose your journey</h2>
										<h3 className="journeySubTitle">
											Choose what interests you most on TalaSecure Defender
										</h3>

										<p className="journeyText">
											*you can always change your trip by clicking on the rocket
											icon at the top of the page
										</p>
									</div>
									<div className="arrowRocket">
										<IconRocket className="RocketSvgJourney" />
										<ArrowUp />
									</div>
								</div>
								<div className="JourneyBtnWrap">
									<button
										onClick={() => setShowDiv(false)}
										className="joyFinish">
										Finish journey <X />
									</button>
									<button onClick={handleGetStartedClick} className="joyStart">
										Get started
									</button>
								</div>
							</div>
						)}
						<Row className="talasmart-resource__item justify-content-between">
							<div className="d-flex gap-2">
								<h2>{t('talasmart.resource.resourceId')}:</h2>{' '}
								<span>{info?.ResourceId}</span>
							</div>
							<button
								className="p-0 bg-transparent border-0 text-blue text-md d-flex align-items-center gap-1"
								onClick={() => setIsResourceListOpen(v => !v)}>
								{t(
									isResourceListOpen
										? 'talasmart.resource.close'
										: 'talasmart.resource.showMoreIds'
								)}
								<ChevronSvg
									className={classNames('talasmart-resource__arrow', {
										'talasmart-resource__arrow_opened': isResourceListOpen,
									})}
								/>
							</button>
						</Row>
						{isResourceListOpen && (
							<ResourceList
								list={filteredResources || []}
								total={results?.counts || 0}
								onChangeResource={onChangeResource}
							/>
						)}
						<Row className="talasmart-resource__item talasmart-resource__item_col my-2-step">
							<h2>{t('talasmart.resource.resourceName')}:</h2>{' '}
							<p>{info?.ResourceName || info?.ResourceId}</p>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.resourceType')}:</h2>{' '}
							<p>{info?.ResourceType}</p>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.issue')}:</h2>{' '}
							<p>{info?.SecurityTestRule}</p>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.issueStatus')}:</h2>
							<IssueTracking onCreate={() => setIsPopupShown(true)} />
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col ">
							<h2>{t('talasmart.resource.description')}:</h2>{' '}
							<pre className="my-4-step">{pageData.description}</pre>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.recommendedSeverity')}:</h2>{' '}
							<p>{info?.Severity}</p>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.severityOverride')}:</h2>{' '}
							<Select
								options={SEVERITY_OPTIONS}
								value={resourceInfo.SeverityOverride || info?.Severity}
								className="talasmart-resource__severity"
								onChange={v => {
									onChangeField('SeverityOverride', v);
									onBlurField();
								}}
							/>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.justification')}:</h2>
							<Textarea
								rows="4"
								placeholder={t('form.rationalePlaceholder')}
								value={resourceInfo.Justification}
								onChange={e => onChangeField('Justification', e.target.value)}
								onBlur={onBlurField}
							/>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.potentialRisk')}:</h2>
							<h3>{t('talasmart.resource.risk')}:</h3>
							<h3>{t('talasmart.resource.loss')}:</h3>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.technicalImpact')}:</h2>
							<pre className="my-5-step">{pageData.technicalImpact}</pre>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.systemImpact')}:</h2>
							<pre className="my-6-step">{pageData.systemImpact}</pre>
						</Row>
						<Row className="talasmart-resource__item talasmart-resource__item_col">
							<h2>{t('talasmart.resource.notes')}:</h2>
							<Textarea
								rows="5"
								placeholder={t('form.notesPlaceholder')}
								value={resourceInfo.Notes}
								onChange={e => onChangeField('Notes', e.target.value)}
								onBlur={onBlurField}
							/>
						</Row>
						<Row className="talasmart-resource__item  justify-content-between">
							<div className="bts-Clip-Print">
								<Button
									className="d-flex justify-content-center gap-1 align-items-center newDes"
									onClick={onCopy}>
									{t('button.copyToClipboard')}
									<Copy />
								</Button>
								<Button
									className="d-flex justify-content-center gap-1 align-items-center newDes"
									onClick={onDownload}>
									{t('button.download')}
									<Download />
								</Button>
							</div>
						</Row>
					</Col>
					<Col md="6 ">
						<div className="mb-3">
							<h2>{/*t('talasmart.resource.remediationDescription')*/}</h2>
							<p></p>
						</div>

						<RemediationSteps
							activeStep={activeRemediation}
							onChange={onChangeTab}
							isRemediationLoading={isRemediationLoading}
						/>
						<div className="mt-3 mb-3">
							<button
								onClick={() => copyToClipBoard(remediationInfo)}
								disabled={isRemediationLoading}
								className={`my-8-step d-flex gap-1 justify-content-center align-items-center p-1 mb-2 ${isRemediationLoading ? 'disabledBtn' : 'copyBtn'
									}`}>
								<img src={copyBtn} alt="copyBtn" />
								<p style={copyTextStyle} className="m-0 p-0">
									{t('talasmart.resource.copyBtn')}
								</p>
							</button>
						</div>
						{isRemediationLoading ? (
							<div>
								<Loader isFull={false} className="mt-5" />
								<span className="text-center pt-2 d-block">
									{TALASMART_WAIT_TEXT}
								</span>
							</div>
						) : (
							<pre>{remediationInfo}</pre>
						)}
					</Col>
				</section>
			)}
			<NotAvailablePopup
				isOpen={isPopupShown}
				onClose={() => setIsPopupShown(false)}
			/>
		</>
	);
};

export default TalasmartResource;
