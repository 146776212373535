import { Card, CardBody, Spinner } from 'reactstrap';

import React from 'react';
import { t } from 'i18next';

const ReportLoading = () => {
	const centeredContent =
		'flex-column align-items-center d-flex justify-content-center m-5';

	return (
		<Card>
			<CardBody>
				<div className={centeredContent}>
					<h4>{t('report.loading')}</h4>
					<Spinner color="primary" />
				</div>
				
			</CardBody>
		</Card>
	);
};

export default ReportLoading;
