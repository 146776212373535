let index = 0;

const getIndex = () => index++;

const data = {
	"categories": {
		"id.am": {
			"description": "The data, personnel, devices, systems, and facilities that enable the organization to achieve business purposes are identified and managed consistent with their relative importance to organizational objectives and the organization's risk strategy.",
			"name": "Asset Management (ID.AM)",
			"subcategories": ["id.am-1", "id.am-4", "id.am-5"]
		},
		"id.be": {
			"description": "The organization's mission, objectives, stakeholders, and activities are understood and prioritized; this information is used to inform cybersecurity roles, responsibilities, and risk management decisions.",
			"name": "Business Environment (ID.BE)",
			"subcategories": ["id.be-1", "id.be-2", "id.be-3", "id.be-4"]
		},
		"id.gv": {
			"description": "The policies, procedures, and processes to manage and monitor the organization's regulatory, legal, risk, environmental, and operational requirements are understood and inform the management of cybersecurity risk.",
			"name": "Governance (ID.GV)",
			"subcategories": ["id.gv-1", "id.gv-2", "id.gv-3", "id.gv-4"]
		},
		"id.ra": {
			"description": "The organization understands the cybersecurity risk to organizational operations (including mission, functions, image, or reputation), organizational assets, and individuals.",
			"name": "Risk Assessment (ID.RA)",
			"subcategories": ["id.ra-4", "id.ra-5", "id.ra-6"]
		},
		"id.rm": {
			"description": "The organization's priorities, constraints, risk tolerances, and assumptions are established and used to support operational risk decisions.",
			"name": "Risk Management Strategy (ID.RM)",
			"subcategories": ["id.rm-1", "id.rm-2", "id.rm-3"]
		},
		"id.sc": {
			"description": "The organization's priorities, constraints, risk tolerances, and assumptions are established and used to support risk decisions associated with managing supply chain risk.The organization has established and implemented the processes to identify, assess and manage supply chain risks.",
			"name": "Supply Chain Risk Management(ID.SC)",
			"subcategories": ["id.sc-1", "id.sc-2", "id.sc-3", "id.sc-4", "id.sc-5"]
		},
		"pr.ac": {
			"description": "Access to physical and logical assets and associated facilities is limited to authorized users, processes, and devices, and is managed consistent with the assessed risk of unauthorized access to authorized activities and transactions.",
			"name": "Identity Management, Authentication and Access Control (PR.AC)",
			"subcategories": ["pr.ac-2"]
		},
		"pr.at": {
			"description": "The organization's personnel and partners are provided cybersecurity awareness education and are trained to perform their cybersecurity-related duties and responsibilities consistent with related policies, procedures, and agreements.",
			"name": "Awareness and Training (PR.AT)",
			"subcategories": ["pr.at-1", "pr.at-2", "pr.at-3", "pr.at-4", "pr.at-5"]
		},
		"pr.ds": {
			"description": "Information and records (data) are managed consistent with the organization's risk strategy to protect the confidentiality, integrity, and availability of information.",
			"name": "Data Security (PR.DS)",
			"subcategories": ["pr.ds-7", "pr.ds-8"]
		},
		"pr.ip": {
			"description": "Security policies (that address purpose, scope, roles, responsibilities, management commitment, and coordination among organizational entities), processes, and procedures are maintained and used to manage protection of information systems and assets.",
			"name": "Information Protection Processes and Procedures (PR.IP)",
			"subcategories": ["pr.ip-2", "pr.ip-5", "pr.ip-6", "pr.ip-8", "pr.ip-9", "pr.ip-10", "pr.ip-11", "pr.ip-12"]
		},
		"pr.ma": {
			"description": "Maintenance and repairs of industrial control and information system components are performed consistent with policies and procedures.",
			"name": "Maintenance (PR.MA)",
			"subcategories": ["pr.ma-1"]
		},
		"pr.pt": {
			"description": "Technical security solutions are managed to ensure the security and resilience of systems and assets, consistent with related policies, procedures, and agreements.",
			"name": "Protective Technology (PR.PT)",
			"subcategories": ["pr.pt-2"]
		},
		"de.ae": {
			"description": "Anomalous activity is detected and the potential impact of events is understood.",
			"name": "Anomalies and Events (DE.AE)",
			"subcategories": ["de.ae-1", "de.ae-2", "de.ae-3", "de.ae-4", "de.ae-5"]
		},
		"de.cm": {
			"description": "The information system and assets are monitored to identify cybersecurity events and verify the effectiveness of protective measures.",
			"name": "Security Continuous Monitoring (DE.CM)",
			"subcategories": ["de.cm-1", "de.cm-2", "de.cm-3", "de.cm-4", "de.cm-5", "de.cm-6", "de.cm-7", "de.cm-8"]
		},
		"de.dp": {
			"description": "Detection processes and procedures are maintained and tested to ensure awareness of anomalous events.",
			"name": "Detection Processes (DE.DP)",
			"subcategories": ["de.dp-1", "de.dp-2", "de.dp-3", "de.dp-4", "de.dp-5"]
		},
		"rs.rp": {
			"description": "Response processes and procedures are executed and maintained, to ensure response to detected cybersecurity incidents.",
			"name": "Response Planning (RS.RP)",
			"subcategories": ["rs.rp-1"]
		},
		"rs.co": {
			"description": "Response activities are coordinated with internal and external stakeholders (e.g. external support from law enforcement agencies).",
			"name": "Communications (RS.CO)",
			"subcategories": ["rs.co-1", "rs.co-2", "rs.co-3", "rs.co-4", "rs.co-5"]
		},
		"rs.an": {
			"description": "Analysis is conducted to ensure effective response and support recovery activities.",
			"name": "Analysis (RS.AN)",
			"subcategories": ["rs.an-1", "rs.an-3", "rs.an-4", "rs.an-5"]
		},
		"rs.mi": {
			"description": "Activities are performed to prevent expansion of an event, mitigate its effects, and resolve the incident.",
			"name": "Mitigation (RS.MI)",
			"subcategories": ["rs.mi-1", "rs.mi-2", "rs.mi-3"]
		},
		"rs.im": {
			"description": "Organizational response activities are improved by incorporating lessons learned from current and previous detection/response activities.",
			"name": "Improvements (RS.IM)",
			"subcategories": ["rs.im-1", "rs.im-2"]
		},
		"rc.rp": {
			"description": "Recovery processes and procedures are executed and maintained to ensure restoration of systems or assets affected by cybersecurity incidents.",
			"name": "Recovery Planning (RC.RP)",
			"subcategories": ["rc.rp-1"]
		},
		"rc.im": {
			"description": "Recovery planning and processes are improved by incorporating lessons learned into future activities.",
			"name": "Improvements (RC.IM)",
			"subcategories": ["rc.im-1", "rc.im-2"]
		},
		"rc.co": {
			"description": "Restoration activities are coordinated with internal and external parties (e.g.  coordinating centers, Internet Service Providers, owners of attacking systems, victims, other CSIRTs, and vendors).",
			"name": "Communications (RC.CO)",
			"subcategories": ["rc.co-1", "rc.co-2", "rc.co-3"]
		}
	},
	"subcategories": {
		"id.am-1": {
			"name": "ID.AM-1",
			"description": "Physical devices and systems within the organization are inventoried",
			index: getIndex()
		},
		"id.am-4": {
			"name": "ID.AM-4",
			"description": "External information systems are catalogued",
			index: getIndex()
		},
		"id.am-5": {
			"name": "ID.AM-5",
			"description": "Resources (e.g., hardware, devices, data, time, personnel, and software) are prioritized based on their classification, criticality, and business value",
			index: getIndex()
		},
		"id.be-1": {
			"name": "ID.BE-1",
			"description": "The organization's role in the supply chain is identified and communicated",
			index: getIndex()
		},
		"id.be-2": {
			"name": "ID.BE-2",
			"description": "The organization's place in critical infrastructure and its industry sector is identified and communicated",
			index: getIndex()
		},
		"id.be-3": {
			"name": "ID.BE-3",
			"description": "Priorities for organizational mission, objectives, and activities are established and communicated",
			index: getIndex()
		},
		"id.be-4": {
			"name": "ID.BE-4",
			"description": "Dependencies and critical functions for delivery of critical services are established",
			index: getIndex()
		},
		"id.gv-1": {
			"name": "ID.GV-1",
			"description": "Organizational cybersecurity policy is established and communicated",
			index: getIndex()
		},
		"id.gv-2": {
			"name": "ID.GV-2",
			"description": "Cybersecurity roles and responsibilities are coordinated and aligned with internal roles and external partners",
			index: getIndex()
		},
		"id.gv-3": {
			"name": "ID.GV-3",
			"description": "Legal and regulatory requirements regarding cybersecurity, including privacy and civil liberties obligations, are understood and managed",
			index: getIndex()
		},
		"id.gv-4": {
			"name": "ID.GV-4",
			"description": "Governance and risk management processes address cybersecurity risks",
			index: getIndex()
		},
		"id.ra-4": {
			"name": "ID.RA-4",
			"description": "Potential business impacts and likelihoods are identified",
			index: getIndex()
		},
		"id.ra-5": {
			"name": "ID.RA-5",
			"description": "Threats, vulnerabilities, likelihoods, and impacts are used to determine risk",
			index: getIndex()
		},
		"id.ra-6": {
			"name": "ID.RA-6",
			"description": "Risk responses are identified and prioritized",
			index: getIndex()
		},
		"id.rm-1": {
			"name": "ID.RM-1",
			"description": "Risk management processes are established, managed, and agreed to by organizational stakeholders",
			index: getIndex()
		},
		"id.rm-2": {
			"name": "ID.RM-2",
			"description": "Organizational risk tolerance is determined and clearly expressed",
			index: getIndex()
		},
		"id.rm-3": {
			"name": "ID.RM-3",
			"description": "The organization's determination of risk tolerance is informed by its role in critical infrastructure and sector specific risk analysis",
			index: getIndex()
		},
		"id.sc-1": {
			"name": "ID.SC-1",
			"description": "Cyber supply chain risk management processes are identified, established, assessed, managed, and agreed to by organizational stakeholders",
			index: getIndex()
		},
		"id.sc-2": {
			"name": "ID.SC-2",
			"description": "Suppliers and third party partners of information systems, components, and services are identified, prioritized, and assessed using a cyber supply chain risk assessment process",
			index: getIndex()
		},
		"id.sc-3": {
			"name": "ID.SC-3",
			"description": "Contracts with suppliers and third-party partners are used to implement appropriate measures designed to meet the objectives of an organization's cybersecurity program and Cyber Supply Chain Risk Management Plan.",
			index: getIndex()
		},
		"id.sc-4": {
			"name": "ID.SC-4",
			"description": "Suppliers and third-party partners are routinely assessed using audits, test results, or other forms of evaluations to confirm they are meeting their contractual obligations.",
			index: getIndex()
		},
		"id.sc-5": {
			"name": "ID.SC-5",
			"description": "Response and recovery planning and testing are conducted with suppliers and third-party providers",
			index: getIndex()
		},
		"pr.ac-2": {
			"name": "PR.AC-2",
			"description": "Physical access to assets is managed and protected",
			index: getIndex()
		},
		"pr.at-1": {
			"name": "PR.AT-1",
			"description": "All users are informed and trained",
			index: getIndex()
		},
		"pr.at-2": {
			"name": "PR.AT-2",
			"description": "Privileged users understand their roles and responsibilities",
			index: getIndex()
		},
		"pr.at-3": {
			"name": "PR.AT-3",
			"description": "Third-party stakeholders (e.g., suppliers, customers, partners) understand their roles and responsibilities",
			index: getIndex()
		},
		"pr.at-4": {
			"name": "PR.AT-4",
			"description": "Senior executives understand their roles and responsibilities",
			index: getIndex()
		},
		"pr.at-5": {
			"name": "PR.AT-5",
			"description": "Physical and cybersecurity personnel understand their roles and responsibilities",
			index: getIndex()
		},
		"pr.ds-7": {
			"name": "PR.DS-7",
			"description": "The development and testing environment(s) are separate from the production environment",
			index: getIndex()
		},
		"pr.ds-8": {
			"name": "PR.DS-8",
			"description": "Integrity checking mechanisms are used to verify hardware integrity",
			index: getIndex()
		},
		"pr.ip-2": {
			"name": "PR.IP-2",
			"description": "A System Development Life Cycle to manage systems is implemented",
			index: getIndex()
		},
		"pr.ip-5": {
			"name": "PR.IP-5",
			"description": "Policy and regulations regarding the physical operating environment for organizational assets are met",
			index: getIndex()
		},
		"pr.ip-6": {
			"name": "PR.IP-6",
			"description": "Data is destroyed according to policy",
			index: getIndex()
		},
		"pr.ip-8": {
			"name": "PR.IP-8",
			"description": "Effectiveness of protection technologies is shared",
			index: getIndex()
		},
		"pr.ip-9": {
			"name": "PR.IP-9",
			"description": "Response plans (Incident Response and Business Continuity) and recovery plans (Incident Recovery and Disaster Recovery) are in place and managed",
			index: getIndex()
		},
		"pr.ip-10": {
			"name": "PR.IP-10",
			"description": "Response and recovery plans are tested",
			index: getIndex()
		},
		"pr.ip-11": {
			"name": "PR.IP-11",
			"description": "Cybersecurity is included in human resources practices (e.g., deprovisioning, personnel screening)",
			index: getIndex()
		},
		"pr.ip-12": {
			"name": "PR.IP-12",
			"description": "A vulnerability management plan is developed and implemented",
			index: getIndex()
		},
		"pr.ma-1": {
			"name": "PR.MA-1",
			"description": "Maintenance and repair of organizational assets are performed and logged, with approved and controlled tools",
			index: getIndex()
		},
		"pr.pt-2": {
			"name": "PR.PT-2",
			"description": "Removable media is protected and its use restricted according to policy",
			index: getIndex()
		},
		"de.ae-1": {
			"name": "DE.AE-1",
			"description": "A baseline of network operations and expected data flows for users and systems is established and managed",
			index: getIndex()
		},
		"de.ae-2": {
			"name": "DE.AE-2",
			"description": "Detected events are analyzed to understand attack targets and methods",
			index: getIndex()
		},
		"de.ae-3": {
			"name": "DE.AE-3",
			"description": "Event data are collected and correlated from multiple sources and sensors",
			index: getIndex()
		},
		"de.ae-4": {
			"name": "DE.AE-4",
			"description": "Impact of events is determined",
			index: getIndex()
		},
		"de.ae-5": {
			"name": "DE.AE-5",
			"description": "Incident alert thresholds are established",
			index: getIndex()
		},
		"de.cm-1": {
			"name": "DE.CM-1",
			"description": "The network is monitored to detect potential cybersecurity events",
			index: getIndex()
		},
		"de.cm-2": {
			"name": "DE.CM-2",
			"description": "The physical environment is monitored to detect potential cybersecurity events",
			index: getIndex()
		},
		"de.cm-3": {
			"name": "DE.CM-3",
			"description": "Personnel activity is monitored to detect potential cybersecurity events",
			index: getIndex()
		},
		"de.cm-4": {
			"name": "DE.CM-4",
			"description": "Malicious code is detected",
			index: getIndex()
		},
		"de.cm-5": {
			"name": "DE.CM-5",
			"description": "Unauthorized mobile code is detected",
			index: getIndex()
		},
		"de.cm-6": {
			"name": "DE.CM-6",
			"description": "External service provider activity is monitored to detect potential cybersecurity events",
			index: getIndex()
		},
		"de.cm-7": {
			"name": "DE.CM-7",
			"description": "Monitoring for unauthorized personnel, connections, devices, and software is performed",
			index: getIndex()
		},
		"de.cm-8": {
			"name": "DE.CM-8",
			"description": "Vulnerability scans are performed",
			index: getIndex()
		},
		"de.dp-1": {
			"name": "DE.DP-1",
			"description": "Roles and responsibilities for detection are well defined to ensure accountability",
			index: getIndex()
		},
		"de.dp-2": {
			"name": "DE.DP-2",
			"description": "Detection activities comply with all applicable requirements",
			index: getIndex()
		},
		"de.dp-3": {
			"name": "DE.DP-3",
			"description": "Detection processes are tested",
			index: getIndex()
		},
		"de.dp-4": {
			"name": "DE.DP-4",
			"description": "Event detection information is communicated",
			index: getIndex()
		},
		"de.dp-5": {
			"name": "DE.DP-5",
			"description": "Detection processes are continuously improved",
			index: getIndex()
		},
		"rs.rp-1": {
			"name": "RS.RP-1",
			"description": "Response plan is executed during or after an incident",
			index: getIndex()
		},
		"rs.co-1": {
			"name": "RS.CO-1",
			"description": "Personnel know their roles and order of operations when a response is needed",
			index: getIndex()
		},
		"rs.co-2": {
			"name": "RS.CO-2",
			"description": "Incidents are reported consistent with established criteria",
			index: getIndex()
		},
		"rs.co-3": {
			"name": "RS.CO-3",
			"description": "Information is shared consistent with response plans",
			index: getIndex()
		},
		"rs.co-4": {
			"name": "RS.CO-4",
			"description": "Coordination with stakeholders occurs consistent with response plans",
			index: getIndex()
		},
		"rs.co-5": {
			"name": "RS.CO-5",
			"description": "Voluntary information sharing occurs with external stakeholders to achieve broader cybersecurity situational awareness",
			index: getIndex()
		},
		"rs.an-1": {
			"name": "RS.AN-1",
			"description": "Notifications from detection systems are investigated",
			index: getIndex()
		},
		"rs.an-3": {
			"name": "RS.AN-3",
			"description": "Forensics are performed",
			index: getIndex()
		},
		"rs.an-4": {
			"name": "RS.AN-4",
			"description": "Incidents are categorized consistent with response plans",
			index: getIndex()
		},
		"rs.an-5": {
			"name": "RS.AN-5",
			"description": "Processes are established to receive, analyze and respond to vulnerabilities disclosed to the organization from internal and external sources (e.g. internal testing, security bulletins, or security researchers)",
			index: getIndex()
		},
		"rs.mi-1": {
			"name": "RS.MI-1",
			"description": "Incidents are contained",
			index: getIndex()
		},
		"rs.mi-2": {
			"name": "RS.MI-2",
			"description": "Incidents are mitigated",
			index: getIndex()
		},
		"rs.mi-3": {
			"name": "RS.MI-3",
			"description": "Newly identified vulnerabilities are mitigated or documented as accepted risks",
			index: getIndex()
		},
		"rs.im-1": {
			"name": "RS.IM-1",
			"description": "Response plans incorporate lessons learned",
			index: getIndex()
		},
		"rs.im-2": {
			"name": "RS.IM-2",
			"description": "Response strategies are updated",
			index: getIndex()
		},
		"rc.rp-1": {
			"name": "RC.RP-1",
			"description": "Recovery plan is executed during or after a cybersecurity incident",
			index: getIndex()
		},
		"rc.im-1": {
			"name": "RC.IM-1",
			"description": "Recovery plans incorporate lessons learned",
			index: getIndex()
		},
		"rc.im-2": {
			"name": "RC.IM-2",
			"description": "Recovery strategies are updated",
			index: getIndex()
		},
		"rc.co-1": {
			"name": "RC.CO-1",
			"description": "Public relations are managed",
			index: getIndex()
		},
		"rc.co-2": {
			"name": "RC.CO-2",
			"description": "Reputation is repaired after an incident",
			index: getIndex()
		},
		"rc.co-3": {
			"name": "RC.CO-3",
			"description": "Recovery activities are communicated to internal and external stakeholders as well as executive and management teams",
			index: getIndex()
		}
	},
	"testCases": [{
		"id": "fn-identify",
		"value": "Identify",
		"fgColor": "#e9ecef",
		"bgColor": "#4da6ff",
		"description": [
			"IDENTIFY (ID)",
			"Asset Management",
			"Business Environment",
			"Risk Assessment",
			"Risk Management Strategy"
		],
		"categories": ["id.am", "id.be", "id.gv", "id.ra", "id.rm", "id.sc"]
	},
	{
		"id": "fn-protect",
		"value": "Protect",
		"fgColor": "#e9ecef",
		"bgColor": "#a31aff",
		"description": [
			"PROTECT (PR)",
			"Awareness Control",
			"Awareness and Training",
			"Data Security",
			"Info Protection and Procedures",
			"Maintenance",
			"Protective Technology"
		],
		"categories": ["pr.ac", "pr.at", "pr.ds", "pr.ip", "pr.ma", "pr.pt"]
	},
	{
		"id": "fn-detect",
		"value": "Detect",
		"fgColor": "#4d4d4d",
		"bgColor": "#ffff33",
		"description": [
			"DETECT (DE)",
			"Anomalies and Events",
			"Security Continuous Monitoring",
			"Detection Process"
		],
		"categories": ["de.ae", "de.cm", "de.dp"]
	},
	{
		"id": "fn-Respond",
		"value": "Respond",
		"fgColor": "#e9ecef",
		"bgColor": "#e60000",
		"description": [
			"RESPOND (RS)",
			"Response Planning",
			"Communications",
			"Analysis",
			"Mitigation",
			"Improvements"
		],
		"categories": ["rs.rp", "rs.co", "rs.an", "rs.mi", "rs.im"]
	},
	{
		"id": "fn-recover",
		"value": "Recover",
		"fgColor": "#e9ecef",
		"bgColor": "#00b33c",
		"description": [
			"RECOVER (RC)",
			"Recovery Planning",
			"Improvements"
		],
		"categories": ["rc.rp", "rc.im", "rc.co"]
	}
	]
}

export default data;