import {
	Button,
	Card,
	CardBody,
	Container,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import '../styles.css';

import BringYourOwnDevice from './BringYourOwnDevice';
import BrowserManagement from './BrowserManagement';
import BusinessContinuity from './BusinessContinuity';
import DisasterRecovery from './DisasterRecovery';
import Header from '../../../../../components/Header';
import HeaderTitle from '../../../../../components/HeaderTitle';
import IncidentMonitoring from './IncidentMonitoring';
import React from 'react';
import ReviewResources from './ReviewResources';
import RiskManagement from './RiskManagement';
import Spinner from 'reactstrap/lib/Spinner';
import { Stages } from '../../../Common/AssessmentStage';
import classnames from 'classnames';
import useRender from '../../../Hooks/useRender';
import useReviewScan from '../../../Hooks/useReviewScan';
import { useTranslation } from 'react-i18next';
import Next from '../../../../../assets/img/Next.svg';
import ArrowBackDisable from '../../../../../assets/img/ArrowBackDisable.svg';
import ArrowBack from '../../../../../assets/img/ArrowBack.svg';
import MagicSvg from '../../../../../assets/img/fe_magic.svg';
import Steps from '../Steps';

const  centeredContent =
	'flex-column align-items-center d-flex justify-content-center m-5';

const ReviewScan = ({
	stage,
	onNext,
	setExclusion,
	mode,
	reportName,
	userQuestionnaire,
}) => {
	const { t } = useTranslation();
	const cursor = {
		cursor: 'pointer',
		color: 'black'
	};

	const activeTabColor = {
		...cursor,
		color: '#3B7DDD',
	};

	const render = useRender(stage, Stages.ReviewScan);
	const hook = useReviewScan(mode === 0, reportName, userQuestionnaire);

	const [activeTabSecond, setActiveTaSecond] = useState('1');
	const [step3, setStep3] = useState(false);

	const toggle = tab => {
		if (activeTabSecond !== tab) setActiveTaSecond(tab);
	};

	const nextClicked = () => {
		if (activeTabSecond === '7') {
			setActiveTaSecond('1');
			setStep3(true);
			hook.save(() => moveNext());
		} else {
			setActiveTaSecond((Number(activeTabSecond) + 1).toString());
		}
	};

	const previousClicked = () => {
		if (activeTabSecond !== '1') {
			setActiveTaSecond((Number(activeTabSecond) - 1).toString());
		}
	};

	

	useEffect(() => {
		setActiveTaSecond('1');
		hook.setLoading(true);
		hook.resetSelection(mode === 0);
		hook.filterResources(hook.resourceTypes[0]);
		setTimeout(() => {
			hook.setLoading(false);
		}, 500);
	}, [render]);

	const moveNext = () => {
		const excludedResourceIds = [];
		hook.resourceTypes.forEach(type => {
			const bucket = hook.resourceBucket.find(r => r.key === type).value;
			const resourceIds = bucket.filter(r => !r.selected).map(r => r.id);
			if (resourceIds.length > 0) {
				excludedResourceIds.push(...resourceIds);
			}
		});

		const onComplete = () => {
			setExclusion('None');
			onNext();
		};
		hook.evaluateResources(excludedResourceIds.join(','), onComplete);
	};

	return (
		render && (
			<Container fluid>
				<Header>
					<HeaderTitle>
						{t('demographicAssessment.title')}
						<img src={MagicSvg} alt="magic" />
					</HeaderTitle>
					<p>{t('demographicAssessment.collectData')}</p>
				</Header>
				<Steps activeTabSecond={activeTabSecond} showStep3={step3} />

				{hook.loading && ( 
					
					<Card>
						<CardBody>
							<h4>{t('reviewScan.analysis')}</h4>
							<p>{t('reviewScan.wait')}</p>
							<div className={centeredContent}>
								
									<Spinner color="primary "/>
									<h4 className='mt-2 text-primary fs-5 fw-normal'>
									{activeTabSecond === '7'
										? t('reviewScan.scanCloudEnvironment')
										: t('reviewScan.analysingAccess')}
								</h4>
							
								
							</div>
					
						</CardBody>
					</Card>
				)}
				{!hook.loading && (
					<Card>
						<CardBody>
							<h5></h5>
							<p></p>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTabSecond === '1' })}
										onClick={() => {
											toggle('1');
										}}>
										<b
											style={activeTabSecond === '1' ? activeTabColor : cursor}>
											{t('reviewScan.resources')}
										</b>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTabSecond === '2' })}
										onClick={() => {
											toggle('2');
										}}>
										<b
											style={activeTabSecond === '2' ? activeTabColor : cursor}>
											{t('reviewScan.disasterRecovery')}{' '}
										</b>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTabSecond === '3' })}
										onClick={() => {
											toggle('3');
										}}>
										<b
											style={activeTabSecond === '3' ? activeTabColor : cursor}>
											{t('reviewScan.businessContinuity')}
										</b>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTabSecond === '4' })}
										onClick={() => {
											toggle('4');
										}}>
										<b
											style={activeTabSecond === '4' ? activeTabColor : cursor}>
											{t('reviewScan.byod')}
										</b>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTabSecond === '5' })}
										onClick={() => {
											toggle('5');
										}}>
										<b
											style={activeTabSecond === '5' ? activeTabColor : cursor}>
											{t('reviewScan.browser')}
										</b>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTabSecond === '6' })}
										onClick={() => {
											toggle('6');
										}}>
										<b
											style={activeTabSecond === '6' ? activeTabColor : cursor}>
											{t('reviewScan.siem')}
										</b>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTabSecond === '7' })}
										onClick={() => {
											toggle('7');
										}}>
										<b
											style={activeTabSecond === '7' ? activeTabColor : cursor}>
											{t('reviewScan.riskManagement')}
										</b>
									</NavLink>
								</NavItem>
								<div className="arrows">
									<img
										src={ArrowBack}
										onClick={() => previousClicked()}
										className="cursor-pointer"
									/>
									<img src={ArrowBackDisable} className="cursor-pointer" />
								</div>
							</Nav>
							<TabContent activeTab={activeTabSecond}>
								<TabPane tabId="1">
									<ReviewResources hook={hook} mode={mode} />
								</TabPane>
								<TabPane tabId="2">
									<DisasterRecovery hook={hook} mode={mode} />
								</TabPane>
								<TabPane tabId="3">
									<BusinessContinuity hook={hook} mode={mode} />
								</TabPane>
								<TabPane tabId="4">
									<BringYourOwnDevice hook={hook} mode={mode} />
								</TabPane>
								<TabPane tabId="5">
									<BrowserManagement hook={hook} mode={mode} />
								</TabPane>
								<TabPane tabId="6">
									<IncidentMonitoring hook={hook} mode={mode} />
								</TabPane>
								<TabPane tabId="7">
									<RiskManagement hook={hook} mode={mode} />
								</TabPane>
							</TabContent>
							<div className="row d-flex float-end flex-nowrap">
								{/* <Button
									lg="true"
									disabled={activeTab === '1'}
									color="secondary"
									onClick={() => previousClicked()}>
									{'<< ' + t('button.previous')}
								</Button> */}
								<Button
									className="ms-4 p-2"
									lg="true"
									style={{ borderRadius: '25px' }}
									color="primary"
									onClick={() => nextClicked()}>
									{activeTabSecond === '7'
										? t('button.runAssessment')
										: t('button.next')}
									<img
										src={Next}
										style={{ marginLeft: '10px' }}
										alt="arrowNext"
									/>
								</Button>
							</div>
						</CardBody>
					</Card>
				)}
			</Container>
		)
	);
};

export default ReviewScan;
